import { useRef, useEffect } from "react";
// import "./Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Sidebar from "../CSideBar";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const CC6 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };

  const code1 = `#include <stdio.h>

int main() {
  int num = 10;
  return 0;
}`;
  const print = `#include <stdio.h>

int main() {
  int num = 10;
  printf("%d", num);     
  return 0;
}`;
  const code2 = `#include <stdio.h>

int main() {
  int num;
  num = 10;
  printf("%d", num); 
  return 0;
}`;
  const code3 = `#include <stdio.h>

int main() {
  int num = 10;
  num = 20;    
  printf("%d", num); 
  return 0;
}`;
  const code4 = `#include <stdio.h>

int main() {
  int age = 22;
  printf("My age is %d", age);
  return 0;
}`;
  const code5 = `#include <stdio.h>

int main() {
  int x  = 20, y = 5;      
  int sum = x + y;
  printf("%d", sum);
  return 0;
}`;
  const code6 = `#include <stdio.h>

int main() {
  const int age = 22;
    
  printf("%d", age);
  return 0;
}`;
  const code7 = `#include <stdio.h>

int main() {
  int age = 42;
  float pi = 3.14;
  char grade = 'A';
  char greetings[] = "Hello, World!";
      
  
  printf("Integer: %d\\n", age);
  printf("Float: %f\\n", pi);
  printf("Character: %c\\n", grade);
  printf("String: %s\\n", greetings);
  
  return 0;
}`;

  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/C/comments">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/C/input">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Variables:
              </h2>
              <div class="ui clearing divider"></div>
              <p>
                Variables are used to store data which can be used later for
                doing computation / calculation.
                <br />
              </p>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Syntax:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                In this example, we will initialize a variable and store some
                integer value in it.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>variables.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code1}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Printing Values of Variables:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                For printing integer variable, we write "%d" inside printf() and
                then "," then name of the variable.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>variables.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {print}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Rules for assigning names to variables:
              </h2>
              <div class="ui clearing divider"></div>
              Names of variables are called identifiers. Use descriptive names
              for your variables to make your code readable. <br />
              <ol className="ui ordered list">
                <li>
                  Variable names in C consist of letters (both uppercase and
                  lowercase), digits, or underscores.{" "}
                </li>
                <li>Variable name starts with a letter or an underscore.</li>
                <li>
                  C is case-sensitive, so variable names like "myVar", and
                  "MYVAR" are considered different variables.
                </li>
                <li>No space is allowed in the name of the variable</li>
                <li>Keep the names of variables descriptive and meaningful.</li>

                <li>
                  Should not be a reserved keyword like "if", "int", "main" etc.
                </li>
              </ol>
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Scope:
              </h2>
              <div class="ui clearing divider"></div>
              <ul class="ui unordered list">
                <li>Local Variables - declared inside the function.</li>
                <li>Global Variables - declared outside the function.</li>
              </ul>
              We can also declare variable without assigning value and assign
              value later, like <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>variables.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code2}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              You can also change the value of variable later in your code.
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>variables.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code3}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Another Example:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                In this example, we will see how to print text and variable
                together.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>variables.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code4}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Operations on variables:
              </h2>
              <div class="ui clearing divider"></div>
              You can apply any mathematical operation on variables, but
              condition is that the data types of these variables must be
              compatible. You can not add int type variable to string. You can
              only add those variables whose data types are compatible.
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>variables.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code5}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Other types of variables:
              </h2>
              <div class="ui clearing divider"></div>
              Like integer there are other different datatypes of variables.{" "}
              <br />
              <ul class="ui unordered list">
                <li>
                  Int stores numbers without a decimal point like 10, 20, 3000.
                </li>
                <li>
                  Float and double stores numbers with a decimal point like 2.1,
                  30.12 etc. <br />
                  The difference is that precision of double in 2x than float.
                </li>
                <li>Char stores single character</li>
                <li>
                  String stores text (collection of characters) inside double
                  quotes like, "My age is 20".
                </li>
                <li>
                  Boolean stores true or false. <br />
                  bool answer = true;
                </li>
                <li>
                  Void is used when you do not want to assign any type to
                  variable.{" "}
                </li>
              </ul>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Using other data types:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                In this section, we will discuss about how we can print
                variables with different data types.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>variables.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code7}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Constant Variables:
              </h2>
              <div class="ui clearing divider"></div>
              You can not chnage value of a constant variable after
              initialization. You will have to initialize the variable at the
              time of declaration.
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>variables.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code6}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Styles for naming variables:
              </h2>
              <div class="ui clearing divider"></div>
              <b>Camel Case:</b>
              <br />
              In camel case convention, we start with small letter and if there
              are more than one words, we convert first letter of other words to
              capital. For example, myAge, myName, johnMarks. <br />
              <b>Pascal Case:</b>
              <br />
              In pascal case, we capitalize the first letter of every word. For
              example, MyAge, MyName. <br />
              <b>Snake Case:</b>
              <br />
              In snake case, we separate words using underscore (_). For
              example, my_age, my_name. <br />
              <br />
              <p style={{ backgroundColor: "#FBEEAC", padding: "10px" }}>
                We have learnt about primitive datatypes like int, double,
                float, string, char and bool etc. There are other datatypes
                which include derived datatypes like array, pointer and user
                defined datatypes like class, structure, enum etc which are not
                in scope of this chapter.
              </p>
              <br />
            </div>
            <Link to="/C/comments">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/C/input">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
        <ToastContainer />
      </div>
    </>
  );
};
export default CC6;
