import { useRef, useEffect } from "react";
// import "./Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Sidebar from "../CSideBar";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const CC3 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };

  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };
  const cc2code0 = `#include <stdio.h>

int main() {
  /* write your code here */
  return 0;
}`;
  const cc2code1 = `#include <stdio.h>

  int main() {
    printf("Hello World!");
    return 0;
}`;

  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/C/getting_started">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/C/output">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body ">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Boiler Plate code:
              </h2>
              <div class="ui clearing divider"></div>

              <div style={containerStyle}>
                <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                  <b>helloworld.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>

                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {cc2code0}
                  </SyntaxHighlighter>
                </div>
              </div>
            </div>
            <br />
            <p style={{ backgroundColor: "#FBEEAC", padding: "10px" }}>
              <b>Note:</b> This is the basic code, you have to write everytime
              while writing C program.
            </p>
            <br />

            <span>
              Now we will understand the example from the previous chapter.
            </span>
            <br />
            <br />
            <div class="main-body ">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Understanding the pervious example:
              </h2>
              <div class="ui clearing divider"></div>
              <div style={containerStyle}>
                <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                  <b>helloworld.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {cc2code1}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Components:
              </h2>
              <div class="ui clearing divider"></div>
              Basically, there are three components of C code there: <br />
              <ol className="ui ordered list">
                <li>Header Files</li>
                <li>Main Function</li>
                <li>Return Statement</li>
              </ol>
              
              <p style={{ backgroundColor: "#FBEEAC", padding: "10px" }}>
                <b>Note : </b> This partitioning has no technical basis. This is
                done just for the purpose for understanding the code easily for
                new comers.
              </p>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Code Description:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                <ul className="ui unordered list">
                  <li>
                    <b>Line1 : </b>stdio.h is a header file in C which provides
                    functionality for input and output and many other functions.
                    It is included in the header file section.
                  </li>
                  <li>
                    <b>Line2 : </b>int main() this is the starting point for the
                    execution of your program. Any code which is written inside
                    its curly brackets will be executed. It is included in the
                    main function section.
                  </li>
                  <li>
                    <b>Line3 : </b> printf("Hello World!") is printing "Hello
                    World!" on screen. It is included in the main function
                    section.
                  </li>
                  <li>
                    <b>Line4 : </b>return 0 means that we are returning from
                    main function there. This is the last line of the main
                    function which is executed. Everything written after it is
                    not executed. It is the exit point of our main function. It
                    is the return statement.
                  </li>
                </ul>
               </span>
              <br />
              
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                What does return 0 mean:
              </h2>
              <div class="ui clearing divider"></div>
              Whenever we write a function in C language, it returns some value. The type of value is 
              the return type of the function. In our case, return type of our function is int as you 
              can see. That is why it is returning 0. If our function has return type void, it will not 
              return anything.

              <br /><br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Some important points regarding syntax of C:
              </h2>
              <div class="ui clearing divider"></div>
              <p style={{ backgroundColor: "#FBEEAC", padding: "10px" }}>
                <div className="">
                  <ul class="ui list">
                    <li>
                      You need to put a semicolon (;) after every line of code.
                    </li>
                    <li>
                      C compiler ignores white spaces. You can write all code in
                      one line but this is not advisable.
                    </li>
                    <li>
                      C is case sensitive language. E.g. Sam is not same as sam.
                    </li>
                    <li>
                      If you have any confusion, you can read this tutorial
                      again.
                    </li>
                  </ul>
                </div>
              </p>
            </div>

            <br />
            <Link to="/C/getting_started">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/C/output">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default CC3;
