import { useRef, useEffect } from "react";
import "../../Components/Chapters/Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Sidebar from "../JavaSideBar";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const JavaC14 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };

  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };
  const jc14code1 = `public class Main {
    public static void main(String[] args) {
        if (10 > 5) {
            System.out.println("10 > 5");
        } 
    }
}`;
  const jc14code2 = `public class Main {
    public static void main(String[] args) {
        if (5 > 10) {
            System.out.println("5 > 10");
        } 
        else{
            System.out.println("5 < 10");
        }
    }
}`;
  const jc14code3 = `public class Main {
  public static void main(String[] args) {

    int num1 = 100;
    int num2 = 200;
    int num3 = 300;
    if (num1 > num3 && num1 > num2) {
      System.out.println("num1 > num2 and num3");
    } 
    else if (num2 > num1 && num2 > num3){
      System.out.println("num2 > num1 and num3");
    }
    else{
      System.out.println("num3 > num1 and num2");            
    }
  }
}`;
  const jc14code4 = `public class Main {
    public static void main(String[] args) {

        int a = 100, b = 20, c = 30;
        if (a > b) {
            if(a > c){
            System.out.print("a > b and c");
            }
        } 
    }
}`;
  const jc14code5 = `public class Main {
    public static void main(String[] args) {

        int a = 10;
        String res = a == 10 ? "a == 10" : "a != 10";
        System.out.println(res);
    }
}`;
  const jc14code6 = `if(condition){...}
if(condition){...}
Else{...}`;
  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/java/booleans">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/java/switch">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body ">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Conditional Statement:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                Conditional Statement checks the condition or compares two
                values using comparison operators and performs action. <br />
                We use if, if-else, else-if statements as conditional statements.
              </span>
              <br />
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                If Statement:
              </h2>
              <div class="ui clearing divider"></div>
              <p style={{ backgroundColor: "", padding: "" }}>
                If statement checks a condition and if it is true, it executes
                the block of code.
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>if-else.java</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="java" style={darcula}>
                      {jc14code1}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
              </p>

              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                If-else Statement:
              </h2>
              <div class="ui clearing divider"></div>
              <p style={{ backgroundColor: "", padding: "" }}>
                In if else statement, if one condition is true, the respective
                block of code is executed and all others are ignored.
                If all conditions are false, code block of else is 
                executed. If-else is used as conditional statement.
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>if-else.java</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="java" style={darcula}>
                      {jc14code2}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
              </p>

              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Else-if Statement:
              </h2>
              <div class="ui clearing divider"></div>
              <p style={{ backgroundColor: "", padding: "" }}>
                If you want to check conditions more than one time then use else
                if. If the condition is true, the respective block of code is
                executed and all others are ignored.
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>if-else.java</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="java" style={darcula}>
                      {jc14code3}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
              </p>

              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Nested if Statement:
              </h2>
              <div class="ui clearing divider"></div>
              <p style={{ backgroundColor: "", padding: "" }}>
                When we use one if statement within other if statement, it is
                called nested if statement.
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>if-else.java</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="java" style={darcula}>
                      {jc14code4}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
              </p>

              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Ternary Operator:
              </h2>
              <div class="ui clearing divider"></div>
              <p style={{ backgroundColor: "", padding: "" }}>
                Ternary operator is used as short hand if-else statement. It is
                also called conditional operator.
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>if-else.java</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="java" style={darcula}>
                      {jc14code5}
                    </SyntaxHighlighter>
                  </div>
                </div>
              </p>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Dangling else Grammar:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                If you are using multiple if statements and single else
                statement, then there is dangling else problem.
              </span>
              <div ref={divRef}>
                <SyntaxHighlighter language="cpp" style={darcula}>
                  {jc14code6}
                </SyntaxHighlighter>
              </div>
              <span>
                Compiler does not know that with which if, else will go.
              </span>
            </div>

            <br />
            <Link to="/java/booleans">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/java/switch">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default JavaC14;
