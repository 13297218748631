import { useRef, useEffect } from "react";
import "../../Components/Chapters/Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "../PythonSideBar";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

const PythonC23 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };

  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor:'#D8D9DA'
  };
  const pc23code1 = `global_var = 42

def print_global():
    print(global_var)
  
print_global()  # Output: 42`;
  const pc23code2 = `def my_function():
local_var = 10
print(local_var)

my_function()  # Output: 10`;
  const pc23code3 = `global_var = 42

def outer_function():
    local_var = 10

    def inner_function():
        # Accessing the global variable
        print(global_var)  
        # Accessing variable from the outer scope
        print(local_var)   

    inner_function()

outer_function()`;
  const pc23code4 = `x = 10  # Global variable

def outer_function():
    x = 5  # Enclosing scope variable

    def inner_function():
        x = 2  
        # Accesses the local variable
        print(x)  

    inner_function()
    # Accessing the enclosing scope variable
    print(x)  

outer_function()
print(x)  # Accesses the global variable`;
  const pc23code5 = `global_variable = 42  

def my_function():
    global global_variable  
    global_variable = 10    
    print(global_variable)

my_function()  # Output: 10
print(global_variable)  # Output: 10`;
const pc23code6 = `x = 300

def my_function():
    x = 10  
    print(x)
    
print(x)  
my_function()`
  return (
    <div class="testing">
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/python/classes_objects">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/python/decorators">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Scope of a Variable:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                In Python, scope refers to the region of a program where a
                particular variable or name is accessible and can be used.
              </span>
              <br />
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Global Scope:
              </h2>
              <div className="ui clearing divider"></div>
              <span>
                Variables defined at the top level of a Python script or module
                have global scope. They can be accessed from anywhere in the
                script or module, including within functions and classes.
              </span><br /><br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>scope.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc23code1}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />

                
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Local Scope:
                </h2>
                <div className="ui clearing divider"></div>
                <span>
                  Variables defined within a function, method, or a code block
                  (such as a loop or conditional statement) have local scope.
                  They are only accessible within the block where they are
                  defined.
                </span><br /><br />

                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>scope.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc23code2}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <p style={{ backgroundColor: "#FBEEAC", padding: "15px" }}>
                  <b>Note: </b>Local variables are temporary and exist only for
                  the duration of the code block in which they are defined.
                </p>

                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Nested Scope:
                </h2>
                <div className="ui clearing divider"></div>
                <span>
                  Python allows for nested scopes, where you have access to
                  variables from outer (enclosing) scopes within inner scopes,
                  but not vice versa. If a variable is not found in the local
                  scope, Python will look in the nearest enclosing scopes,
                  including the global scope, until it finds the variable or
                  reaches the built-in scope.
                </span><br /><br />

                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>scope.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc23code3}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />

                
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  LEGB Rule:
                </h2>
                <div className="ui clearing divider"></div>
                <span>
                  Python follows the LEGB rule to determine the order in which
                  it looks for a variable name: <br />
                  <ul className="ui unordered list">
                    <li>
                      <b>Local (L): </b> The innermost scope, which is the
                      current function or code block.
                    </li>
                    <li>
                      <b>Enclosing (E): </b> The scope of any enclosing
                      functions, going outwards.
                    </li>
                    <li>
                      <b>Global (G): </b> The global scope, which includes
                      variables defined at the module level.
                    </li>
                    <li>
                      <b>Built-in (B): </b> The built-in scope, which contains
                      Python's built-in functions and objects.
                    </li>
                  </ul>
                  <br />
                  Python searches for a variable name in this order until it
                  finds a match.
                </span>
                <br />
                <br />

                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>scope.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc23code4}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  The global Keyword:
                </h2>
                <div className="ui clearing divider"></div>
                <span>
                  In Python, the global keyword is used to indicate that a
                  variable declared inside a function should have a global scope
                  rather than a local scope. This means that the variable can be
                  accessed and modified from anywhere in the code, including
                  both inside and outside the function where it was originally
                  defined.
                </span>
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>scope.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc23code5}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Local and Global Variables with the same name:
                </h2>
                <div className="ui clearing divider"></div>
                <span>
                  If local and global variables have same name, then they are
                  different variables. A local variable is created when the
                  function or code block is started and is destroyed (or goes
                  out of scope) when the function or code block exits.
                </span>
                <br /><br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>scope.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc23code6}
                    </SyntaxHighlighter>
                  </div>
                </div>
              
            </div><br />
            <Link to="/python/classes_objects">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/python/decorators">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </div>
  );
};
export default PythonC23;
