import React, { useState, useRef, useEffect } from "react";
import Sidebar from "./SideBar";
import "../../Components/SideBar/App.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const CodeAccordion = ({ questions, code, toggleAccordion, changeCodeLanguage }) => {
    const divRef = useRef(null);
    const copyContent = () => {
        const divContent = divRef.current.innerText;
        navigator.clipboard
          .writeText(divContent)
          .then(() => {
            toast.success("Content copied!", { position: "top-center" });
          })
          .catch((error) => {
            toast.error("Error copying content:", error, {
              position: "top-center",
            });
          });
      };
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };

  return (
    <div>
      {questions.map((item, index) => (
        <div key={index} className="border p-2 rounded-md">
          <div className="flex justify-between">
            <span className="text-lg font-semibold">
              {item.question}
            </span>
            <button
              className="bg-gray-200 px-2 py-1 rounded-md"
              onClick={() => toggleAccordion(index)}
            >
              {item.isOpen ? "-" : "+"}
            </button>
          </div>
          <div className={`mt-2 ${item.isOpen ? "block" : "hidden"}`}>
            <div className="mt-2 flex space-x-4">
              {Object.keys(code).map((language) => (
                <button
                  key={language}
                  onClick={() => changeCodeLanguage(index, language)}
                  className={`${
                    item.codeLanguage === language
                      ? "bg-blue-500 hover:bg-blue-600 text-white"
                      : "bg-gray-200 hover:bg-gray-300 text-gray-700"
                  } px-3 py-2 rounded-md transition duration-300 ease-in-out transform hover:scale-105`}
                >
                  {language}
                </button>
              ))}
            </div>
            <p className="text-gray-800">
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>output.cpp</b>
                  <i
                    className="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language={`${item.codeLanguage}`} style={darcula}>
                    {code[item.codeLanguage]}
                  </SyntaxHighlighter>
                </div>
              </div>
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

const Question17 = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
    const code = {
        python: `# Python code
# Create sets with various data types
set1 = {1, 2, 3, 'apple', 'banana', 4.5}
set2 = {2, 3, 'cherry', 4.5, 5.0}

# Union of sets
union_result = set1.union(set2)

# Intersection of sets
intersection_result = set1.intersection(set2)

# Difference between sets
difference_result = set1.difference(set2)

# Print the results
print("Set 1:", set1)
print("Set 2:", set2)
print("Union of sets:", union_result)
print("Intersection of sets:", intersection_result)
print("Difference between sets:", difference_result)`,
    };
    const code1 = {
        python: `# Python code
def find_intersection(set1, set2):
    return set1.intersection(set2)

# Example usage:
set1 = {1, 2, 3, 4, 5}
set2 = {3, 4, 5, 6, 7}

intersection_result = find_intersection(set1, set2)
print("Intersection:", intersection_result)`,
    };
    const code2 = {
        python: `# Python code
def is_subset(set1, set2):
    return set1.issubset(set2)

# Example usage:
set1 = {1, 2, 3}
set2 = {1, 2, 3, 4, 5}

is_subset_result = is_subset(set1, set2)
print("Is set1 a subset of set2?", is_subset_result)`,
    };
    const code3 = {
        python: `# Python code
def find_symmetric_difference(set1, set2):
    return set1.symmetric_difference(set2)

# Example usage:
set1 = {1, 2, 3, 4, 5}
set2 = {3, 4, 5, 6, 7}

symmetric_difference_result = find_symmetric_difference(set1, set2)
print("Symmetric Difference:", symmetric_difference_result)`,
    };
    const code4 = {
        python: `# Python code
original_list = [1, 2, 2, 3, 4, 4, 5, 6]

# Convert the list to a set to remove duplicates
unique_set = set(original_list)

# Convert the set back to a list if needed
unique_list = list(unique_set)

print("Original List:", original_list)
print("List with Duplicates Removed:", unique_list)`,
    };
    const code5 = {
        python: `# Python code
my_set = {1, 2, 3, 4, 5}

# Remove element 3 using the remove() method
try:
    my_set.remove(3)
except KeyError:
    print("Element 3 not found in the set.")

# Remove element 2 using the discard() method
my_set.discard(2)

# Try to remove element 6 that doesn't exist using the remove() method
try:
    my_set.remove(6)
except KeyError:
    print("Element 6 not found in the set.")

# Try to remove element 5 that doesn't exist using the discard() method
my_set.discard(5)

print("Updated Set:", my_set)`,
    };
    const code6 = {
        python: `# Python code
# Using set comprehension to create a set of squares
squares_set = {x ** 2 for x in range(1, 11)}

# Print the set of squares
print("Set of Squares:", squares_set)`,
    };
    const code7 = {
        python: `# Python code
input_string = "hello world"
vowels_set = {char for char in input_string if char in "aeiouAEIOU"}

print("Set of Unique Vowels:", vowels_set)`,
    };
    const code8 = {
        python: `# Python code
list1 = [1, 2, 3, 4, 5]
list2 = [3, 4, 5, 6, 7]

# Convert the lists to sets
set1 = set(list1)
set2 = set(list2)

# Find the common elements using the intersection method
common_elements = set1.intersection(set2)

# Convert the result back to a list if needed
common_elements_list = list(common_elements)

print("List 1:", list1)
print("List 2:", list2)
print("Common Elements:", common_elements_list)`,
    };
    const code9 = {
        python: `# Python code
list1 = [1, 2, 3, 4, 5]
list2 = [3, 4, 5, 6, 7]

# Convert the lists to sets
set1 = set(list1)
set2 = set(list2)

# Find elements unique to each list using symmetric difference
unique_to_list1 = set1.symmetric_difference(set2)
unique_to_list2 = set2.symmetric_difference(set1)

# Convert the results back to lists if needed
unique_to_list1_list = list(unique_to_list1)
unique_to_list2_list = list(unique_to_list2)

print("List 1:", list1)
print("List 2:", list2)
print("Elements Unique to List 1:", unique_to_list1_list)
print("Elements Unique to List 2:", unique_to_list2_list)`,
    };
    const code10 = {
        python: `# Python code
set1 = {"apple", "banana", "cherry", "date"}
set2 = {"banana", "cherry", "fig", "grape"}

# Common strings in both sets
common_strings = set1.intersection(set2)

# Unique strings in set1
unique_to_set1 = set1.difference(set2)

# Unique strings in set2
unique_to_set2 = set2.difference(set1)

# Total count of unique strings
total_unique_count = len(unique_to_set1) + len(unique_to_set2)

print("Set 1:", set1)
print("Set 2:", set2)
print("Common Strings:", common_strings)
print("Unique Strings in Set 1:", unique_to_set1)
print("Unique Strings in Set 2:", unique_to_set2)
print("Total Count of Unique Strings:", total_unique_count)`,
    };
    const code11 = {
        python: `# Python code
def remove_duplicates_preserve_order(input_list):
    seen = set()
    output_list = []
    for item in input_list:
        if item not in seen:
            seen.add(item)
            output_list.append(item)
    return output_list

original_list = [1, 2, 2, 3, 4, 4, 5, 1]

# Remove duplicates while preserving the original order
unique_list = remove_duplicates_preserve_order(original_list)

print("Original List:", original_list)
print("List with Duplicates Removed (Preserving Order):", unique_list)`,
    };
    const code12 = {
        python: `# Python code
def is_element_in_set(element, my_set):
    return {element}.issubset(my_set)

# Example usage:
my_set = {1, 2, 3, 4, 5}
element_to_check = 3

is_present = is_element_in_set(element_to_check, my_set)
print(f"Is {element_to_check} in the set? {is_present}")`,
    };
    const code13 = {
        python: `# Python code
list_of_sets = [
    {1, 2, 3, 4, 5},
    {3, 4, 5, 6, 7},
    {5, 6, 7, 8, 9}
]

# Initialize the results with the first set
union_result = list_of_sets[0].copy()
intersection_result = list_of_sets[0].copy()
difference_result = list_of_sets[0].copy()

# Iterate through the list of sets starting from the second set
for s in list_of_sets[1:]:
    union_result.update(s)
    intersection_result.intersection_update(s)
    difference_result.difference_update(s)

print("List of Sets:")
for s in list_of_sets:
    print(s)

print("Union of Sets:", union_result)
print("Intersection of Sets:", intersection_result)
print("Difference of Sets:", difference_result)`,
    };


  const [questions, setQuestions] = useState([
    {
      question: "Q1: Create a set with various data types (integers, strings, floats) and perform basic set operations like union, intersection, and difference and print their results on screen.",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  const [questions1, setQuestions1] = useState([
    {
      question: "Q2: Write a function to find the intersection of two sets.",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  const [questions2, setQuestions2] = useState([
    {
      question: "Q3: Create a function to check if one set is a subset of another set.",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  const [questions3, setQuestions3] = useState([
    {
      question: "Q4: Find the symmetric difference of two sets.",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  const [questions4, setQuestions4] = useState([
    {
      question: "Q5: Remove duplicates from a list by converting it to a set.",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  const [questions5, setQuestions5] = useState([
    {
      question: "Q6: Remove specific elements from a set.",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  const [questions6, setQuestions6] = useState([
    {
      question: "Q7: Create a set of squares of numbers from 1 to 10 using a set comprehension.",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  const [questions7, setQuestions7] = useState([
    {
      question: "Q8: Generate a set of unique vowels from a given string using a set comprehension.",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  const [questions8, setQuestions8] = useState([
    {
      question: "Q9: Find common elements between two lists using sets.",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  const [questions9, setQuestions9] = useState([
    {
      question: "Q10: Find the elements that are unique to each of two lists using sets.",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  const [questions10, setQuestions10] = useState([
    {
      question: "Q11: Given two sets of strings, find common strings, unique strings in each set, and the total count of unique strings.",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  const [questions11, setQuestions11] = useState([
    {
      question: "Q12: Convert a list to a set and then back to a list to remove duplicate items while preserving the original order.",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  const [questions12, setQuestions12] = useState([
    {
      question: "Q13: Write a function to check if an element is present in a set.",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  const [questions13, setQuestions13] = useState([
    {
      question: "Q14: Given a list of sets, find the union, intersection, and difference of all sets in the list.",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  

  const toggleAccordion = (index, questions, setQuestions) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].isOpen = !updatedQuestions[index].isOpen;
    setQuestions(updatedQuestions);
  };

  const changeCodeLanguage = (index, language, questions, setQuestions) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].codeLanguage = language;
    setQuestions(updatedQuestions);
  };

  

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };
  const divRef = useRef(null);
  return (
    <div className="testing">
      <Sidebar>
        <div className="containera">
          <div className="ch-content">
            <h2 className="ui header">
            <Link to="/ques16">
              <button
                className="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
              </Link>
              <Link to="/ques18">
                <button
                  className="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>
            <div className="main-body ui">
              <h2 className="ui left floated header" style={{ color: "#001C30" }}>
                Set:
              </h2>
              <div className="ui clearing divider"></div>
              <CodeAccordion
                questions={questions}
                code={code}
                toggleAccordion={(index) => toggleAccordion(index, questions, setQuestions)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions, setQuestions)
                }
              /><br/>
              <CodeAccordion
                questions={questions1}
                code={code1}
                toggleAccordion={(index) => toggleAccordion(index, questions1, setQuestions1)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions1, setQuestions1)
                }
              /><br/>
              <CodeAccordion
                questions={questions2}
                code={code2}
                toggleAccordion={(index) => toggleAccordion(index, questions2, setQuestions2)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions2, setQuestions2)
                }
              /><br/>
              <CodeAccordion
                questions={questions3}
                code={code3}
                toggleAccordion={(index) => toggleAccordion(index, questions3, setQuestions3)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions3, setQuestions3)
                }
              /><br/>
              <CodeAccordion
                questions={questions4}
                code={code4}
                toggleAccordion={(index) => toggleAccordion(index, questions4, setQuestions4)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions4, setQuestions4)
                }
              /><br/>
              <CodeAccordion
                questions={questions5}
                code={code5}
                toggleAccordion={(index) => toggleAccordion(index, questions5, setQuestions5)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions5, setQuestions5)
                }
              /><br/>
              <CodeAccordion
                questions={questions6}
                code={code6}
                toggleAccordion={(index) => toggleAccordion(index, questions6, setQuestions6)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions6, setQuestions6)
                }
              /><br/>
              <CodeAccordion
                questions={questions7}
                code={code7}
                toggleAccordion={(index) => toggleAccordion(index, questions7, setQuestions7)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions7, setQuestions7)
                }
              /><br/>
              <CodeAccordion
                questions={questions8}
                code={code8}
                toggleAccordion={(index) => toggleAccordion(index, questions8, setQuestions8)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions8, setQuestions8)
                }
              /><br/>
              <CodeAccordion
                questions={questions9}
                code={code9}
                toggleAccordion={(index) => toggleAccordion(index, questions9, setQuestions9)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions9, setQuestions9)
                }
              /><br/>
              <CodeAccordion
                questions={questions10}
                code={code10}
                toggleAccordion={(index) => toggleAccordion(index, questions10, setQuestions10)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions10, setQuestions10)
                }
              /><br/>
              <CodeAccordion
                questions={questions11}
                code={code11}
                toggleAccordion={(index) => toggleAccordion(index, questions11, setQuestions11)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions11, setQuestions11)
                }
              /><br/>
              <CodeAccordion
                questions={questions12}
                code={code12}
                toggleAccordion={(index) => toggleAccordion(index, questions12, setQuestions12)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions12, setQuestions12)
                }
              /><br/>
              <CodeAccordion
                questions={questions13}
                code={code13}
                toggleAccordion={(index) => toggleAccordion(index, questions13, setQuestions13)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions13, setQuestions13)
                }
              /><br/>
              
            </div>
            <Link to="/ques16">
              <button
                className="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
              </Link>
              <Link to="/ques18">
                <button
                  className="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default Question17;
