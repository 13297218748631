import { useRef, useEffect } from "react";
import "../../Components/Chapters/Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "../PythonSideBar";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

const PythonC11 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };

  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };
  const pc11code1 = `a = 10
b = 3
  
addition = a + b         
subtraction = a - b      
multiplication = a * b   
division = a / b         
floor_division = a // b  
modulus = a % b          
exponentiation = a ** b`;
  const pc9code2 = `a = 10

a += 5   # Equivalent to: a = a + 5
a -= 3   # Equivalent to: a = a - 3
a *= 3   # Equivalent to: a = a * 3
a /= 4   # Equivalent to: a = a / 4
a //= 9  # Equivalent to: a = a // 9
a %= 3   # Equivalent to: a = a % 3
a **= 2  # Equivalent to: a = a ** 2
a |= 8   # Equivalent to: a = a | 8
a &= 3   # Equivalent to: a = a & 3
a ^= 5   # Equivalent to: a = a ^ 5
a <<= 1  # Equivalent to: a = a << 1
a >>= 2  # Equivalent to: a = a >> 2`;
  const pc9code3 = `age = 25
  
if age > 18 and age < 60:
    print("Both conditions are true")
else:
    print("At least one of the conditions is false")

if age < 18 or age >= 65:
    print("Your age is out of range.")
else:
    print("You are eligible for this activity")`;
  const pc9code4 = `# Bitwise AND
result_and = 0b1010 & 0b1100
  
# Bitwise OR
result_or = 0b1010 | 0b1100   
  
# Bitwise XOR
result_xor = 0b1010 ^ 0b1100  
  
# Bitwise NOT
result_not = ~0b1010         
  
# Bitwise Left Shift
result_left_shift = 0b1010 << 2  
  
# Bitwise Right Shift
result_right_shift = 0b1010 >> 1`;
  const pc9code5 = `# Creating two lists with the same content
list1 = [1, 2, 3]
list2 = [1, 2, 3]

# Assigning list1 to another variable
list3 = list1

# Using identity operators
print(list1 is list2)      
print(list1 is list3)      
print(list1 is not list2)  
print(list1 is not list3)`;
  const pc9code6 = `# Using membership operators with strings
text = "Hello, world!"
print("world" in text)        
print("Python" not in text)   

# Using membership operators with lists
numbers = [1, 2, 3, 4, 5]
print(3 in numbers)           
print(6 not in numbers)       

# Using membership operators with dictionaries
person = {"name": "Alice", "age": 30}
print("name" in person)       
print("gender" not in person)`;
  return (
    <div class="testing">
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/python/strings">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/python/maths">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Operators:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                Operators are used to perform arithmetic or other operations on variables and data.{" "}
                <br />
                <ul class="ui unordered list">
                  <li>Arithmetic operators</li>
                  <li>Assignment operators</li>
                  <li>Comparison operators</li>
                  <li>Logical operators</li>
                  <li>Bitwise operators</li>
                  <li>Identity operators</li>
                  <li>Membership operators</li>
                </ul>
              </span>
              <br />
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Arithmetic Operators:
              </h2>
              <div className="ui clearing divider"></div>
              <span>
                Arithmetic operators are used to perform arithmetic operations
                on variables or data.
              </span>
              <br />
              <table class="ui very padded table">
                <thead>
                  <tr>
                    <th>Operator</th>
                    <th>Name</th>
                    <th>Syntax</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <b>+</b>
                    </td>
                    <td>Addition</td>
                    <td>a + b</td>
                    <td>Adds two data values.</td>
                  </tr>
                  <tr>
                    <td>
                      <b>-</b>
                    </td>
                    <td>Subtraction</td>
                    <td>a - b</td>
                    <td>Subtracts two data values.</td>
                  </tr>
                  <tr>
                    <td>
                      <b>*</b>
                    </td>
                    <td>Multiplication</td>
                    <td>a * b</td>
                    <td>Multiply two data values.</td>
                  </tr>
                  <tr>
                    <td>
                      <b>/</b>
                    </td>
                    <td>Division</td>
                    <td>a / b</td>
                    <td>Divides two data values.</td>
                  </tr>
                  <tr>
                    <td>
                      <b>%</b>
                    </td>
                    <td>Modulus</td>
                    <td>a % b</td>
                    <td>Returns remainder.</td>
                  </tr>
                  <tr>
                    <td>
                      <b>//</b>
                    </td>
                    <td>Floor Division</td>
                    <td>a // b</td>
                    <td>Return answer of floor division.</td>
                  </tr>
                  <tr>
                    <td>
                      <b>**</b>
                    </td>
                    <td>Exponentiation</td>
                    <td>a ** b</td>
                    <td>
                      Raises the first operand to the power of the second
                      operand.
                    </td>
                  </tr>
                </tbody>
              </table>

              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>operator.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc11code1}
                  </SyntaxHighlighter>
                </div>
              </div>

              <br />

              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Assignment Operators:
              </h2>
              <div className="ui clearing divider"></div>
              <span>
                Assignment operators are used to assign values (data values or other variables) to the variables.
              </span>
              <br />
              <table class="ui very padded table">
                <thead>
                  <tr>
                    <th>Operator</th>
                    <th>Name</th>
                    <th>Syntax</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <b>=</b>
                    </td>
                    <td>Assignment</td>
                    <td>a = 5</td>
                    <td>Assign value to a variable.</td>
                  </tr>
                  <tr>
                    <td>
                      <b>+=</b>
                    </td>
                    <td>Addition Assignment</td>
                    <td>a += b</td>
                    <td>adds a and b and stores result in a.</td>
                  </tr>
                  <tr>
                    <td>
                      <b>-=</b>
                    </td>
                    <td>Subtraction Assignment</td>
                    <td>a -= b</td>
                    <td>subtracts a and b and stores result in a.</td>
                  </tr>
                  <tr>
                    <td>
                      <b>*=</b>
                    </td>
                    <td>Multiplication Assignment</td>
                    <td>a *= b</td>
                    <td>multiplies a and b and stores result in a.</td>
                  </tr>
                  <tr>
                    <td>
                      <b>/=</b>
                    </td>
                    <td>Division Assignment</td>
                    <td>a /= b</td>
                    <td>divides a and b and stores result in a.</td>
                  </tr>
                  <tr>
                    <td>
                      <b>//=</b>
                    </td>
                    <td>Floor Division Assignment </td>
                    <td>a //= b</td>
                    <td>
                      applies floor division on a and b and stores result in a.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>%=</b>
                    </td>
                    <td>Modulus Assignment</td>
                    <td>a %= b</td>
                    <td>takes modulus of a and b and stores result in a.</td>
                  </tr>
                  <tr>
                    <td>
                      <b>**=</b>
                    </td>
                    <td>Exponentiation Assignment </td>
                    <td>a **= b</td>
                    <td>
                      raises a to the power of b and assigns the result to a.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>|=</b>
                    </td>
                    <td>Bitwise OR Assignment</td>
                    <td>a |= b</td>
                    <td>
                      performs bitwise OR operation between a and b, and assigns
                      the result to a.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>&=</b>
                    </td>
                    <td>Bitwise AND Assignment </td>
                    <td>a &= b</td>
                    <td>
                      performs bitwise AND operation between a and b, and
                      assigns the result to a.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>^=</b>
                    </td>
                    <td>Bitwise XOR Assignment </td>
                    <td>a ^= b</td>
                    <td>
                      performs bitwise XOR operation between a and b, and
                      assigns the result to a.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>&lt;&lt;=</b>
                    </td>
                    <td>Left Shift Assignment</td>
                    <td>a &lt;&lt;= b</td>
                    <td>
                      Shifts the bits of a to the left by the number of
                      positions specified on b, and assigns the result to a.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>&gt;&gt;=</b>
                    </td>
                    <td>Right Shift Assignment </td>
                    <td>a &gt;&gt;= b</td>
                    <td>
                      Shifts the bits of a to the right by the number of
                      positions specified on b, and assigns the result to a.
                    </td>
                  </tr>
                </tbody>
              </table>

              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>operator.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc9code2}
                  </SyntaxHighlighter>
                </div>
              </div>

              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Comparison Operators:
              </h2>
              <div className="ui clearing divider"></div>
              <span>
                Comparison operators are used to compare two variables or data
                values.
              </span>

              <br />
              <table class="ui very padded table">
                <thead>
                  <tr>
                    <th>Operator</th>
                    <th>Name</th>
                    <th>Syntax</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <b>==</b>
                    </td>
                    <td>Equal to</td>
                    <td>a == b</td>
                    <td>check if a is equal to b.</td>
                  </tr>
                  <tr>
                    <td>
                      <b>!=</b>
                    </td>
                    <td>Not equal to</td>
                    <td>a != b</td>
                    <td>check if a is not equal to b.</td>
                  </tr>
                  <tr>
                    <td>
                      <b>&gt;</b>
                    </td>
                    <td>greater than</td>
                    <td>a &gt; b</td>
                    <td>checks whether a is greater than b.</td>
                  </tr>
                  <tr>
                    <td>
                      <b>&lt;</b>
                    </td>
                    <td>less than</td>
                    <td>a &lt; b</td>
                    <td>checks whether a is less than b.</td>
                  </tr>
                  <tr>
                    <td>
                      <b>&gt;=</b>
                    </td>
                    <td>greater or equal to than</td>
                    <td>a &gt;= b</td>
                    <td>checks whether a is greater than or equal to b.</td>
                  </tr>
                  <tr>
                    <td>
                      <b>&lt;=</b>
                    </td>
                    <td>less or equal to than</td>
                    <td>a &lt;= b</td>
                    <td>checks whether a is less than or equal to b.</td>
                  </tr>
                </tbody>
              </table>

              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Logical Operators:
              </h2>
              <div className="ui clearing divider"></div>
              <span>
                Logical operators are used alongwith conditional statements.
              </span>

              <br />
              <table class="ui very padded table">
                <thead>
                  <tr>
                    <th>Operator</th>
                    <th>Name</th>
                    <th>Syntax</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <b>and</b>
                    </td>
                    <td>Logical AND</td>
                    <td>condition1 and condition2</td>
                    <td>check if both conditions are true.</td>
                  </tr>
                  <tr>
                    <td>
                      <b>or</b>
                    </td>
                    <td>Logical OR</td>
                    <td>condition1 or condition2</td>
                    <td>check if condition1 or condition2 is true.</td>
                  </tr>
                  <tr>
                    <td>
                      <b>not</b>
                    </td>
                    <td>Logical NOT</td>
                    <td>not condition</td>
                    <td>return the opposite of the given condition.</td>
                  </tr>
                </tbody>
              </table>
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>operator.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc9code3}
                  </SyntaxHighlighter>
                </div>
              </div>

              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Bitwise Operators:
              </h2>
              <div className="ui clearing divider"></div>
              <span>
                Bitwise operators perform operations on bit level on numbers.
              </span>
              <br />

              <table class="ui very padded table">
                <thead>
                  <tr>
                    <th>Operator</th>
                    <th>Name</th>
                    <th>Syntax</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <b>&</b>
                    </td>
                    <td>Bitwise AND</td>
                    <td>num1 & num2</td>
                    <td>
                      Performs a bitwise AND operation on the corresponding bits
                      of two integers.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>|</b>
                    </td>
                    <td>Logical OR</td>
                    <td>num1 | num2</td>
                    <td>
                      Performs a bitwise OR operation on the corresponding bits
                      of two integers.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>^</b>
                    </td>
                    <td>Biteise XOR</td>
                    <td>num1 ^ num2</td>
                    <td>
                      Performs a bitwise exclusive OR (XOR) operation on the
                      corresponding bits of two integers.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>~</b>
                    </td>
                    <td>Bitwise NOT</td>
                    <td>num1 ~ num2</td>
                    <td>
                      Performs a bitwise NOT operation, which inverts the bits
                      of the integer.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>&ls</b>
                    </td>
                    <td>Bitwise Left Shift</td>
                    <td>num1 &ls;&ls; num2</td>
                    <td>
                      Shifts the bits of an integer to the left by a specified
                      number of positions.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>&rs</b>
                    </td>
                    <td>Bitwise Right Shift</td>
                    <td>num1 &rs;&rs; num2</td>
                    <td>
                      Shifts the bits of an integer to the right by a specified
                      number of positions.
                    </td>
                  </tr>
                </tbody>
              </table>

              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>operator.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc9code4}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
            </div>

            <h2 class="ui left floated header" style={{ color: "#001C30" }}>
              Identity Operators:
            </h2>
            <div className="ui clearing divider"></div>
            <span>
              Identity operators are used to compare the memory locations of two
              objects to determine whether they are the same object or
              different.
            </span>

            <br />
            <table class="ui very padded table">
              <thead>
                <tr>
                  <th>Operator</th>
                  <th>Name</th>
                  <th>Syntax</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <b>is</b>
                  </td>
                  <td>is</td>
                  <td>x is y</td>
                  <td>
                    returns True if both operands point to the same object in
                    memory,
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>is not</b>
                  </td>
                  <td>is not</td>
                  <td>x is not y</td>
                  <td>
                    returns True if both operands do not point to the same
                    object in memory,
                  </td>
                </tr>
              </tbody>
            </table>
            <div style={containerStyle}>
              <div className="ml-3 mr-3">
                <b style={{ color: "black" }}>operator.py</b>

                <i
                  class="copy icon"
                  onClick={copyContent}
                  style={{
                    cursor: "pointer",
                    float: "right",
                    fontSize: "20px",
                  }}
                ></i>
              </div>
              <div ref={divRef}>
                <SyntaxHighlighter language="python" style={darcula}>
                  {pc9code5}
                </SyntaxHighlighter>
              </div>
            </div>

            <br />
            <h2 class="ui left floated header" style={{ color: "#001C30" }}>
              Membership Operators:
            </h2>
            <div className="ui clearing divider"></div>
            <span>
              Membership operators are used to test whether a value is a member
              of a sequence (like a string, list, or tuple) or a collection
              (like a dictionary or a set).
            </span>
            <br />

            <table class="ui very padded table">
              <thead>
                <tr>
                  <th>Operator</th>
                  <th>Name</th>
                  <th>Syntax</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <b>in</b>
                  </td>
                  <td>in</td>
                  <td>value in sequence</td>
                  <td>
                    returns True if the specified value is found in the sequence
                    or collection
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>not in</b>
                  </td>
                  <td>not in</td>
                  <td>value not in sequence</td>
                  <td>
                    returns True if the specified value is not found in the
                    sequence or collection
                  </td>
                </tr>
              </tbody>
            </table>
            <div style={containerStyle}>
              <div className="ml-3 mr-3">
                <b style={{ color: "black" }}>operator.py</b>

                <i
                  class="copy icon"
                  onClick={copyContent}
                  style={{
                    cursor: "pointer",
                    float: "right",
                    fontSize: "20px",
                  }}
                ></i>
              </div>
              <div ref={divRef}>
                <SyntaxHighlighter language="python" style={darcula}>
                  {pc9code6}
                </SyntaxHighlighter>
              </div>
            </div>
            <p style={{ backgroundColor: "#FBEEAC", padding: "15px" }}>
              <b>Note : </b>In this tutorial, we are providing explanations of
              different kinds of operators such as arithmetic operators,
              assignment operators, comparison operators, logical operators,
              bitwise operators, identity operators, and membership operators.
            </p>
            <br />

            <Link to="/python/strings">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/python/maths">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </div>
  );
};
export default PythonC11;
