import { useRef, useEffect } from "react";
import "./Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Q10 from "../Questions/Q10";
import Sidebar from "../SideBar/Sidebar";
import Footer from "../Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const Ch10 = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  const divRef = useRef(null);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };
  const code1 = `string name = "Joseph";`;
  const code2 = `#include <string>`;
  const code3 = `#include<iostream>
#include<string>
using namespace std;

int main(){
    string name = "Joseph";
    cout<<name;
    return 0;
}`;
  const code4 = `#include<iostream>
#include<string>
using namespace std;

int main(){
    //Method 1:
    string street = "Street no 14";
    string house = "House no 1";
    string address = street + house; 
    cout << address <<endl;

    //Method 2:
    string address ="Street 14 " + "House 1";
    cout<<address <<endl;

    //Method 3:
    //Using append function
    string address = street.append(house);
    cout<<address<<endl;
    return 0;
}`;
  const code5 = `#include<iostream>
#include<string>
using namespace std;

int main(){
    string nums = "10" + "20";
    cout<<nums<<endl;
    return 0;
}`;
  const code6 = `#include<iostream>
#include<string>
using namespace std;

    int main(){
    string name = "Joseph";
    cout<<"Length of name: " << name.length();
    //You can also use name.size() 
    return 0;
}`;
  const code7 = `#include<iostream>
#include<string>
using namespace std;

int main(){
    string name = "Joseph";
    cout<<name[0]<<endl;
    cout<<name[1]<<endl;
    cout<<name[2]<<endl;
    cout<<name[3]<<endl;
    cout<<name[4]<<endl;
    cout<<name[5]<<endl;
    //reassigning value
    name[0] = "H";
    cout<<name<<endl;
    //output will be Hoseph
}`;
  const code8 = `#include<iostream>
#include<string>
using namespace std;

int main(){
    cout<<"My name is \\"Joseph\\".";
    return 0;
}`;
  const code9 = `#include<iostream>
#include<string>
using namespace std;

int main(){
    const myName;
    //method 1: 
    cout<<"Enter full name: \\n";
    cin>>myName;   
    cout<<"Your full name is: "<<myName<<endl;

    //method 2:
    cout<<"Enter full name: \\n";
    getline (cin, myName);   //ignores spaces
    cout<<"Your full name is "<<myName<<endl;
    return 0;
}`;
  const code10 = `int length = text.length();`;
  const code11 = `str1.append(str2);`;
  const code12 = `string sub = text.substr(0, 5);`;
  const code13 = `text.replace(7, 5, "Universe");`;
  const code14 = `text.erase(7, 4);`;
  const code15 = `size_t pos = text.find("abc");`;

  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/Cpp/operators">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/Cpp/if_else">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Strings and Methods of Strings:
              </h2>
              <div class="ui clearing divider"></div>
              <p>
                String is a primitive data type which is used to store text or
                sequence of characters. Strings are always enclosed in double
                quotation marks. String is an array of characters. We will
                discuss arrays later on.
              </p>
              <SyntaxHighlighter language="cpp" style={darcula}>
                {code1}
              </SyntaxHighlighter>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Header file of string:
              </h2>
              <div class="ui clearing divider"></div>
              <SyntaxHighlighter language="cpp" style={darcula}>
                {code2}
              </SyntaxHighlighter>
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>string.cpp</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="cpp" style={darcula}>
                    {code3}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Concatenation:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                Concatenation means joining of two strings and it is achieved by
                using addition "+" operator.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>string.cpp</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="cpp" style={darcula}>
                    {code4}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <p style={{ backgroundColor: "#FBEEAC", padding: "10px" }}>
                <b>Note : </b>If you have numbers in your string, they will not
                sum up, rather they will be concatenated.
              </p>
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>string.cpp</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="cpp" style={darcula}>
                    {code5}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                String Length:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                Length of string can be found by using ".length()" or ".size()"
                method.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>string.cpp</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="cpp" style={darcula}>
                    {code6}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Accessing Characters in string:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                As we have previously discussed that string is a sequence of
                characters. So we can access individual character and we can
                also reassign them some other values.
              </span>{" "}
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>string.cpp</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="cpp" style={darcula}>
                    {code7}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <p style={{ backgroundColor: "#FBEEAC", padding: "10px" }}>
                <b>Note: </b>If you want to print double quotes inside string,
                use \"
              </p>
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>string.cpp</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="cpp" style={darcula}>
                    {code8}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Inputs in String:
              </h2>
              <div class="ui clearing divider"></div>
              <span>There are two methods of taking inputs in string.</span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>string.cpp</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="cpp" style={darcula}>
                    {code9}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Methods of Strings:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                In C++, we have to include a header file of string,
                #include&lt;string&gt;
              </span>
              <table className="ui very padded table">
                <thead>
                  <tr>
                    <th>Method</th>
                    <th>Description</th>
                    <th>Code</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>length()</td>
                    <td>
                      It is used to calculate the length of a string variable.
                    </td>
                    <td>
                      <SyntaxHighlighter language="cpp" style={darcula}>
                        {code10}
                      </SyntaxHighlighter>
                    </td>
                  </tr>
                  <tr>
                    <td>append(x)</td>
                    <td>It is used to append strings.</td>
                    <td>
                      <SyntaxHighlighter language="cpp" style={darcula}>
                        {code11}
                      </SyntaxHighlighter>
                    </td>
                  </tr>
                  <tr>
                    <td>substr(x, y)</td>
                    <td>It is used to find a substring in a string.</td>
                    <td>
                      <SyntaxHighlighter language="cpp" style={darcula}>
                        {code12}
                      </SyntaxHighlighter>
                    </td>
                  </tr>
                  <tr>
                    <td>replace(x, y, z)</td>
                    <td>
                      It will replace characters from starting index to ending
                      index with the given string.
                    </td>
                    <td>
                      <SyntaxHighlighter language="cpp" style={darcula}>
                        {code13}
                      </SyntaxHighlighter>
                    </td>
                  </tr>
                  <tr>
                    <td>erase(x, y)</td>
                    <td>
                      It will remove characters from starting index to ending
                      index.
                    </td>
                    <td>
                      <SyntaxHighlighter language="cpp" style={darcula}>
                        {code14}
                      </SyntaxHighlighter>
                    </td>
                  </tr>
                  <tr>
                    <td>find(x, y)</td>
                    <td>
                      It will search for the first occurrence of the specified
                      substring within the string, starting from the given
                      position.
                    </td>
                    <td>
                      <SyntaxHighlighter language="cpp" style={darcula}>
                        {code14}
                      </SyntaxHighlighter>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <br />
            <Link to="/Cpp/operators">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/Cpp/if_else">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default Ch10;
