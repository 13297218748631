import { useEffect } from "react";
import { FaGithub, FaInstagram, FaFacebook } from "react-icons/fa";
import Footer from "./Components/Home/Footer";
import { Link } from "react-router-dom";

function ContactUs() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  const whatsappNumber = "+923019209337";

  const openWhatsAppChat = () => {
    const whatsappUrl = `https://wa.me/${whatsappNumber}`;
    window.open(whatsappUrl, "_blank");
  };
  return (
    <>
      <section class="text-gray-600 body-font">
        <div class="container flex flex-wrap px-5 py-24 mx-auto items-center">
          <div className="md:w-1/2 md:pr-12 md:py-8 md:border-r md:border-b-0 mt-10 md:mb-0 pb-10 border-b border-gray-500 ml-12 md:ml-0 text-center">
            <img
              src="images/p1.webp"
              alt="img"
              style={{ borderRadius: "50%", width: "70%" }}
              className="mx-auto"
            />
          </div>

          <div class="flex flex-col md:w-1/2 ml-20 md:ml-0">
            <div className="container mx-auto pt-32 text-center ">
              <h1 className="text-3xl font-semibold mb-4">Contact Us</h1>
              <div className="mb-4">
                <div
                  rel="noopener noreferrer"
                  className="block text-blue-500 font-medium text-center"
                >
                  <div className="flex items-center justify-center">
                    <span>sajeelahmad@codeprogs.com</span>
                  </div>
                </div>
              </div>
              <div className="mb-4">
                <Link
                  to="https://instagram.com/_sajeel_ahmad_?igshid=MWY0cXF5aGJvNzh2YQ=="
                  target="_blank"
                  rel="noopener noreferrer"
                  className="block text-pink-500 font-medium hover:underline text-center cursor-pointer"
                  style={{ color: "red" }}
                >
                  <div className="flex items-center justify-center">
                    <FaInstagram className="inline-block mr-2" />
                    <span>Instagram</span>
                  </div>
                </Link>
              </div>
              <div className="p-2 w-full">
                <button
                  onClick={openWhatsAppChat}
                  className="flex mx-auto text-white bg-green-500 border-0 py-2 px-8 focus:outline-none hover:bg-green-600 rounded text-lg"
                >
                  Chat on WhatsApp
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default ContactUs;
