import React, { useState, useRef, useEffect } from "react";
import Sidebar from "./SideBar";
import "../../Components/SideBar/App.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const CodeAccordion = ({ questions, code, toggleAccordion, changeCodeLanguage }) => {
    const divRef = useRef(null);
    const copyContent = () => {
        const divContent = divRef.current.innerText;
        navigator.clipboard
          .writeText(divContent)
          .then(() => {
            toast.success("Content copied!", { position: "top-center" });
          })
          .catch((error) => {
            toast.error("Error copying content:", error, {
              position: "top-center",
            });
          });
      };
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };

  return (
    <div>
      {questions.map((item, index) => (
        <div key={index} className="border p-2 rounded-md">
          <div className="flex justify-between">
            <span className="text-lg font-semibold">
              {item.question}
            </span>
            <button
              className="bg-gray-200 px-2 py-1 rounded-md"
              onClick={() => toggleAccordion(index)}
            >
              {item.isOpen ? "-" : "+"}
            </button>
          </div>
          <div className={`mt-2 ${item.isOpen ? "block" : "hidden"}`}>
            <div className="mt-2 flex space-x-4">
              {Object.keys(code).map((language) => (
                <button
                  key={language}
                  onClick={() => changeCodeLanguage(index, language)}
                  className={`${
                    item.codeLanguage === language
                      ? "bg-blue-500 hover:bg-blue-600 text-white"
                      : "bg-gray-200 hover:bg-gray-300 text-gray-700"
                  } px-3 py-2 rounded-md transition duration-300 ease-in-out transform hover:scale-105`}
                >
                  {language}
                </button>
              ))}
            </div>
            <p className="text-gray-800">
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>output.cpp</b>
                  <i
                    className="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language={`${item.codeLanguage}`} style={darcula}>
                    {code[item.codeLanguage]}
                  </SyntaxHighlighter>
                </div>
              </div>
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

const Question21 = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when the component mounts
      }, []);
    const code = {
        cpp: `// C++ code
#include <iostream>
#include <string>
using namespace std;

class Animal {
public:
    virtual void makeSound() {
        Question21cout << "The animal makes a generic sound." << Question21endl;
    }
};

class Dog : public Animal {
public:
    void makeSound() override {
        Question21cout << "The dog barks." << Question21endl;
    }
};

class Cat : public Animal {
public:
    void makeSound() override {
        Question21cout << "The cat meows." << Question21endl;
    }
};

int main() {
    Animal* genericAnimal = new Animal();
    Animal* dog = new Dog();
    Animal* cat = new Cat();

    genericAnimal->makeSound();
    dog->makeSound();
    cat->makeSound();

    delete genericAnimal;
    delete dog;
    delete cat;

    return 0;
}`,
        java: `// Java code
class Animal {
    public void makeSound() {
        System.out.println("The animal makes a generic sound.");
    }
}

class Dog extends Animal {
    public void makeSound() {
        System.out.println("The dog barks.");
    }
}

class Cat extends Animal {
    public void makeSound() {
        System.out.println("The cat meows.");
    }
}

public class Main {
    public static void main(String[] args) {
        Animal genericAnimal = new Animal();
        Animal dog = new Dog();
        Animal cat = new Cat();

        genericAnimal.makeSound();
        dog.makeSound();
        cat.makeSound();
    }
}`,
        python: `# Python code
class Animal:
    def makeSound(self):
        print("The animal makes a generic sound.")

class Dog(Animal):
    def makeSound(self):
        print("The dog barks.")

class Cat(Animal):
    def makeSound(self):
        print("The cat meows.")

generic_animal = Animal()
dog = Dog()
cat = Cat()

generic_animal.makeSound()
dog.makeSound()
cat.makeSound()`,
    };
    const code1 = {
        cpp: `// C++ code
#include <iostream>
#include <vector>
#include <cmath>
using namespace std;

class Shape {
public:
    virtual double area() const = 0;
};

class Circle : public Shape {
private:
    double radius;

public:
    Circle(double r) : radius(r) {}

    double area() const override {
        return M_PI * radius * radius;
    }
};

class Rectangle : public Shape {
private:
    double width;
    double height;

public:
    Rectangle(double w, double h) : width(w), height(h) {}

    double area() const override {
        return width * height;
    }
};

double totalArea(const Question21vector<Shape*>& shapes) {
    double total = 0.0;
    for (const Shape* shape : shapes) {
        total += shape->area();
    }
    return total;
}

int main() {
    Circle circle(5.0);
    Rectangle rectangle(4.0, 6.0);

    Question21vector<Shape*> shapes;
    shapes.push_back(&circle);
    shapes.push_back(&rectangle);

    double total = totalArea(shapes);

    Question21cout << "Total area of shapes: " << total << Question21endl;

    return 0;
}`,
        java: `// Java code
import java.util.ArrayList;
import java.util.List;

abstract class Shape {
    public abstract double area();
}

class Circle extends Shape {
    private double radius;

    public Circle(double r) {
        radius = r;
    }

    public double area() {
        return Math.PI * radius * radius;
    }
}

class Rectangle extends Shape {
    private double width;
    private double height;

    public Rectangle(double w, double h) {
        width = w;
        height = h;
    }

    public double area() {
        return width * height;
    }
}

public class Main {
    public static double totalArea(List<Shape> shapes) {
        double total = 0.0;
        for (Shape shape : shapes) {
            total += shape.area();
        }
        return total;
    }

    public static void main(String[] args) {
        Circle circle = new Circle(5.0);
        Rectangle rectangle = new Rectangle(4.0, 6.0);

        List<Shape> shapes = new ArrayList<>();
        shapes.add(circle);
        shapes.add(rectangle);

        double total = totalArea(shapes);

        System.out.println("Total area of shapes: " + total);
    }
}`,
        python: `# Python code
import math

class Shape:
    def area(self):
        pass

class Circle(Shape):
    def __init__(self, r):
        self.radius = r

    def area(self):
        return math.pi * self.radius**2

class Rectangle(Shape):
    def __init__(self, w, h):
        self.width = w
        self.height = h

    def area(self):
        return self.width * self.height

def total_area(shapes):
    total = 0.0
    for shape in shapes:
        total += shape.area()
    return total

circle = Circle(5.0)
rectangle = Rectangle(4.0, 6.0)

shapes = [circle, rectangle]

total = total_area(shapes)

print(f"Total area of shapes: {total}")`,
    };
    const code2 = {
        cpp: `// C++ code
#include <iostream>
#include <string>
using namespace std;

class Vehicle {
public:
    virtual void displayInfo() {
        cout << "This is a generic vehicle." << endl;
    }
};

class Car : public Vehicle {
public:
    void displayInfo() override {
        cout << "This is a car. It can carry passengers." << endl;
    }
};

class Bike : public Vehicle {
public:
    void displayInfo() override {
        cout << "This is a bike. It is for individual use." << endl;
    }
};

int main() {
    Vehicle* genericVehicle = new Vehicle();
    Vehicle* car = new Car();
    Vehicle* bike = new Bike();

    genericVehicle->displayInfo();
    car->displayInfo();
    bike->displayInfo();

    delete genericVehicle;
    delete car;
    delete bike;

    return 0;
}`,
        java: `// Java code
class Vehicle {
    public void displayInfo() {
        System.out.println("This is a generic vehicle.");
    }
}

class Car extends Vehicle {
    public void displayInfo() {
        System.out.println("This is a car. It can carry passengers.");
    }
}

class Bike extends Vehicle {
    public void displayInfo() {
        System.out.println("This is a bike. It is for individual use.");
    }
}

public class Main {
    public static void main(String[] args) {
        Vehicle genericVehicle = new Vehicle();
        Vehicle car = new Car();
        Vehicle bike = new Bike();

        genericVehicle.displayInfo();
        car.displayInfo();
        bike.displayInfo();
    }
}`,
        python: `# Python code
class Vehicle:
    def display_info(self):
        print("This is a generic vehicle.")

class Car(Vehicle):
    def display_info(self):
        print("This is a car. It can carry passengers.")

class Bike(Vehicle):
    def display_info(self):
        print("This is a bike. It is for individual use.")

generic_vehicle = Vehicle()
car = Car()
bike = Bike()

generic_vehicle.display_info()
car.display_info()
bike.display_info()`,
    };
    const code3 = {
        cpp: `// C++ code
#include <iostream>
#include <string>
using namespace std;

class Animal {
public:
    virtual void makeSound() {
        cout << "The animal makes a generic sound." << endl;
    }
};

class Dog : public Animal {
public:
    void makeSound() override {
        cout << "The dog barks." << endl;
    }
};

class Cat : public Animal {
public:
    void makeSound() override {
        cout << "The cat meows." << endl;
    }
};

class Cow : public Animal {
public:
    void makeSound() override {
        cout << "The cow moos." << endl;
    }
};

int main() {
    Animal* genericAnimal = new Animal();
    Animal* dog = new Dog();
    Animal* cat = new Cat();
    Animal* cow = new Cow();

    genericAnimal->makeSound();
    dog->makeSound();
    cat->makeSound();
    cow->makeSound();

    delete genericAnimal;
    delete dog;
    delete cat;
    delete cow;

    return 0;
}`,
        java: `// Java code
class Animal {
    public void makeSound() {
        System.out.println("The animal makes a generic sound.");
    }
}

class Dog extends Animal {
    public void makeSound() {
        System.out.println("The dog barks.");
    }
}

class Cat extends Animal {
    public void makeSound() {
        System.out.println("The cat meows.");
    }
}

class Cow extends Animal {
    public void makeSound() {
        System.out.println("The cow moos.");
    }
}

public class Main {
    public static void main(String[] args) {
        Animal genericAnimal = new Animal();
        Animal dog = new Dog();
        Animal cat = new Cat();
        Animal cow = new Cow();

        genericAnimal.makeSound();
        dog.makeSound();
        cat.makeSound();
        cow.makeSound();
    }
}`,
        python: `# Python code
class Animal:
    def makeSound(self):
        print("The animal makes a generic sound.")

class Dog(Animal):
    def makeSound(self):
        print("The dog barks.")

class Cat(Animal):
    def makeSound(self):
        print("The cat meows.")

class Cow(Animal):
    def makeSound(self):
        print("The cow moos.")

generic_animal = Animal()
dog = Dog()
cat = Cat()
cow = Cow()

generic_animal.makeSound()
dog.makeSound()
cat.makeSound()
cow.makeSound()`,
    };
    const code4 = {
        cpp: `// C++ code
#include <iostream>
#include <vector>
#include <cmath>
using namespace std;

class Shape {
public:
    virtual void draw() {
        // Default implementation
    }
};

class Circle : public Shape {
private:
    double radius;
    int x, y;

public:
    Circle(double r, int cx, int cy) : radius(r), x(cx), y(cy) {}

    void draw() override {
        cout << "Drawing a circle at (" << x << ", " << y << ") with radius " << radius << endl;
    }
};

class Rectangle : public Shape {
private:
    int width, height;
    int x, y;

public:
    Rectangle(int w, int h, int cx, int cy) : width(w), height(h), x(cx), y(cy) {}

    void draw() override {
        cout << "Drawing a rectangle at (" << x << ", " << y << ") with width " << width << " and height " << height << endl;
    }
};

int main() {
    vector<Shape*> canvas;

    Circle circle1(5.0, 10, 10);
    Rectangle rectangle1(8, 6, 20, 20);

    canvas.push_back(&circle1);
    canvas.push_back(&rectangle1);

    for (Shape* shape : canvas) {
        shape->draw();
    }

    return 0;
}`,
        java: `// Java code
import java.util.ArrayList;
import java.util.List;

class Shape {
    public void draw() {
        // Default implementation
    }
}

class Circle extends Shape {
    private double radius;
    private int x, y;

    public Circle(double r, int cx, int cy) {
        radius = r;
        x = cx;
        y = cy;
    }

    public void draw() {
        System.out.println("Drawing a circle at (" + x + ", " + y + ") with radius " + radius);
    }
}

class Rectangle extends Shape {
    private int width, height;
    private int x, y;

    public Rectangle(int w, int h, int cx, int cy) {
        width = w;
        height = h;
        x = cx;
        y = cy;
    }

    public void draw() {
        System.out.println("Drawing a rectangle at (" + x + ", " + y + ") with width " + width + " and height " + height);
    }
}

public class Main {
    public static void main(String[] args) {
        List<Shape> canvas = new ArrayList<>();

        Circle circle1 = new Circle(5.0, 10, 10);
        Rectangle rectangle1 = new Rectangle(8, 6, 20, 20);

        canvas.add(circle1);
        canvas.add(rectangle1);

        for (Shape shape : canvas) {
            shape.draw();
        }
    }
}`,
        python: `# Python code
class Shape:
    def draw(self):
        # Default implementation
        pass

class Circle(Shape):
    def __init__(self, r, cx, cy):
        self.radius = r
        self.x = cx
        self.y = cy

    def draw(self):
        print(f"Drawing a circle at ({self.x}, {self.y}) with radius {self.radius}")

class Rectangle(Shape):
    def __init__(self, w, h, cx, cy):
        self.width = w
        self.height = h
        self.x = cx
        self.y = cy

    def draw(self):
        print(f"Drawing a rectangle at ({self.x}, {self.y}) with width {self.width} and height {self.height}")

canvas = []

circle1 = Circle(5.0, 10, 10)
rectangle1 = Rectangle(8, 6, 20, 20)

canvas.append(circle1)
canvas.append(rectangle1)

for shape in canvas:
    shape.draw()`,
    };
    const code5 = {
        cpp: `// C++ code
#include <iostream>
#include <vector>
#include <chrono>
#include <thread>
using namespace std;

class Vehicle {
public:
    virtual void move() {
        cout << "Generic vehicle is moving." << endl;
    }
};

class Car : public Vehicle {
public:
    void move() override {
        cout << "Car is moving on the road." << endl;
    }
};

class Bicycle : public Vehicle {
public:
    void move() override {
        cout << "Bicycle is pedaling on the bike lane." << endl;
    }
};

class TrafficSignal {
public:
    void simulate(const vector<Vehicle*>& vehicles, int duration) {
        for (int i = 0; i < duration; i++) {
            cout << "Green light - Go!" << endl;
            for (Vehicle* vehicle : vehicles) {
                vehicle->move();
            }
            this_thread::sleep_for(chrono::seconds(2));

            cout << "Red light - Stop!" << endl;
            this_thread::sleep_for(chrono::seconds(1));
        }
    }
};

int main() {
    Car car;
    Bicycle bike;

    vector<Vehicle*> vehicles = {&car, &bike};
    TrafficSignal signal;

    signal.simulate(5, vehicles);

    return 0;
}`,
        java: `// Java code
import java.util.ArrayList;
import java.util.List;

class Vehicle {
    public void move() {
        System.out.println("Generic vehicle is moving.");
    }
}

class Car extends Vehicle {
    public void move() {
        System.out.println("Car is moving on the road.");
    }
}

class Bicycle extends Vehicle {
    public void move() {
        System.out.println("Bicycle is pedaling on the bike lane.");
    }
}

class TrafficSignal {
    public void simulate(List<Vehicle> vehicles, int duration) {
        for (int i = 0; i < duration; i++) {
            System.out.println("Green light - Go!");
            for (Vehicle vehicle : vehicles) {
                vehicle.move();
            }
            try {
                Thread.sleep(2000);
            } catch (InterruptedException e) {
                e.printStackTrace();
            }

            System.out.println("Red light - Stop!");
            try {
                Thread.sleep(1000);
            } catch (InterruptedException e) {
                e.printStackTrace();
            }
        }
    }
}

public class Main {
    public static void main(String[] args) {
        Car car = new Car();
        Bicycle bike = new Bicycle();

        List<Vehicle> vehicles = new ArrayList<>();
        vehicles.add(car);
        vehicles.add(bike);

        TrafficSignal signal = new TrafficSignal();

        signal.simulate(5, vehicles);
    }
}`,
        python: `# Python code
import time

class Vehicle:
    def move(self):
        print("Generic vehicle is moving.")

class Car(Vehicle):
    def move(self):
        print("Car is moving on the road.")

class Bicycle(Vehicle):
    def move(self):
        print("Bicycle is pedaling on the bike lane.")

class TrafficSignal:
    def simulate(self, vehicles, duration):
        for i in range(duration):
            print("Green light - Go!")
            for vehicle in vehicles:
                vehicle.move()
            time.sleep(2)

            print("Red light - Stop!")
            time.sleep(1)

car = Car()
bike = Bicycle()

vehicles = [car, bike]
signal = TrafficSignal()

signal.simulate(5, vehicles)`,
    };
    const code6 = {
        cpp: `// C++ code
#include <iostream>
#include <string>
using namespace std;

class AudioFile {
public:
    virtual void play() {
        cout << "Playing audio." << endl;
    }
};

class MP3File : public AudioFile {
public:
    void play() override {
        cout << "Playing MP3 audio." << endl;
    }
};

class WAVFile : public AudioFile {
public:
    void play() override {
        cout << "Playing WAV audio." << endl;
    }
};

int main() {
    MP3File mp3;
    WAVFile wav;

    AudioFile* currentTrack = &mp3;
    currentTrack->play();

    currentTrack = &wav;
    currentTrack->play();

    return 0;
}`,
        java: `// Java code
class AudioFile {
    public void play() {
        System.out.println("Playing audio.");
    }
}

class MP3File extends AudioFile {
    public void play() {
        System.out.println("Playing MP3 audio.");
    }
}

class WAVFile extends AudioFile {
    public void play() {
        System.out.println("Playing WAV audio.");
    }
}

public class Main {
    public static void main(String[] args) {
        MP3File mp3 = new MP3File();
        WAVFile wav = new WAVFile();

        AudioFile currentTrack = mp3;
        currentTrack.play();

        currentTrack = wav;
        currentTrack.play();
    }
}`,
        python: `# Python code
class AudioFile:
    def play(self):
        print("Playing audio.")

class MP3File(AudioFile):
    def play(self):
        print("Playing MP3 audio.")

class WAVFile(AudioFile):
    def play(self):
        print("Playing WAV audio.")

mp3 = MP3File()
wav = WAVFile()

current_track = mp3
current_track.play()

current_track = wav
current_track.play()`,
    };
    const code7 = {
        cpp: `// C++ code
#include <iostream>
using namespace std;

// Base class for UI elements
class UIElement {
public:
    virtual void render() {
        cout << "Rendering a generic UI element." << endl;
    }
};

// Subclass for rendering buttons
class Button : public UIElement {
public:
    void render() override {
        cout << "Rendering a button." << endl;
    }
};

// Subclass for rendering text fields
class TextField : public UIElement {
public:
    void render() override {
        cout << "Rendering a text field." << endl;
    }
};

int main() {
    UIElement* elements[] = {new Button(), new TextField()};

    for (UIElement* element : elements) {
        element->render();
        delete element;
    }

    return 0;
}`,
        java: `// Java code
class UIElement {
    public void render() {
        System.out.println("Rendering a generic UI element.");
    }
}

class Button extends UIElement {
    public void render() {
        System.out.println("Rendering a button.");
    }
}

class TextField extends UIElement {
    public void render() {
        System.out.println("Rendering a text field.");
    }
}

public class Main {
    public static void main(String[] args) {
        UIElement[] elements = {new Button(), new TextField()};

        for (UIElement element : elements) {
            element.render();
        }
    }
}`,
        python: `# Python code
class UIElement:
    def render(self):
        print("Rendering a generic UI element.")

class Button(UIElement):
    def render(self):
        print("Rendering a button.")

class TextField(UIElement):
    def render(self):
        print("Rendering a text field.")

elements = [Button(), TextField()]

for element in elements:
    element.render()`,
    };
    const code8 = {
        cpp: `// C++ code
#include <iostream>
#include <vector>
#include <map>
using namespace std;

// Base class for syntax highlight plugins
class SyntaxHighlightPlugin {
public:
    virtual void highlightSyntax(const string& code) {
        // Default implementation
        cout << "No syntax highlighting for this language." << endl;
    }
};

// Concrete plugins for different programming languages
class CppSyntaxHighlightPlugin : public SyntaxHighlightPlugin {
public:
    void highlightSyntax(const string& code) override {
        cout << "Syntax highlighting for C++ code." << endl;
        // Actual syntax highlighting logic for C++ here
    }
};

class PythonSyntaxHighlightPlugin : public SyntaxHighlightPlugin {
public:
    void highlightSyntax(const string& code) override {
        cout << "Syntax highlighting for Python code." << endl;
        // Actual syntax highlighting logic for Python here
    }
};

int main() {
    // Simulating a text editor with plugins
    vector<SyntaxHighlightPlugin*> plugins;
    plugins.push_back(new CppSyntaxHighlightPlugin());
    plugins.push_back(new PythonSyntaxHighlightPlugin());

    string code = "int main() {\\n    // Your code here\\n}";

    for (SyntaxHighlightPlugin* plugin : plugins) {
        plugin->highlightSyntax(code);
    }

    for (SyntaxHighlightPlugin* plugin : plugins) {
        delete plugin;
    }

    return 0;
}`,
        java: `// Java code
import java.util.ArrayList;
import java.util.List;

// Base class for syntax highlight plugins
class SyntaxHighlightPlugin {
    public void highlightSyntax(String code) {
        // Default implementation
        System.out.println("No syntax highlighting for this language.");
    }
}

// Concrete plugins for different programming languages
class JavaSyntaxHighlightPlugin extends SyntaxHighlightPlugin {
    public void highlightSyntax(String code) {
        System.out.println("Syntax highlighting for Java code.");
        // Actual syntax highlighting logic for Java here
    }
}

class PythonSyntaxHighlightPlugin extends SyntaxHighlightPlugin {
    public void highlightSyntax(String code) {
        System.out.println("Syntax highlighting for Python code.");
        // Actual syntax highlighting logic for Python here
    }
}

public class Main {
    public static void main(String[] args) {
        // Simulating a text editor with plugins
        List<SyntaxHighlightPlugin> plugins = new ArrayList<>();
        plugins.add(new JavaSyntaxHighlightPlugin());
        plugins.add(new PythonSyntaxHighlightPlugin());

        String code = "public class Main {\\n    // Your code here\\n}";

        for (SyntaxHighlightPlugin plugin : plugins) {
            plugin.highlightSyntax(code);
        }
    }
}`,
        python: `# Python code
# Base class for syntax highlight plugins
class SyntaxHighlightPlugin:
    def highlight_syntax(self, code):
        # Default implementation
        print("No syntax highlighting for this language.")

# Concrete plugins for different programming languages
class PythonSyntaxHighlightPlugin(SyntaxHighlightPlugin):
    def highlight_syntax(self, code):
        print("Syntax highlighting for Python code.")
        # Actual syntax highlighting logic for Python here

class JavaSyntaxHighlightPlugin(SyntaxHighlightPlugin):
    def highlight_syntax(self, code):
        print("Syntax highlighting for Java code.")
        # Actual syntax highlighting logic for Java here

# Simulating a text editor with plugins
plugins = [JavaSyntaxHighlightPlugin(), PythonSyntaxHighlightPlugin()]
code = "public class Main:\\n    # Your code here"

for plugin in plugins:
    plugin.highlight_syntax(code)`,
    };


  const [questions, setQuestions] = useState([
    {
      question: "Q1: Create a class \"Animal\" with a method \"makeSound.\" Create instances of \"Dog\" and \"Cat\" and call the \"makeSound\" method.",
      codeLanguage: "cpp",
      isOpen: false,
    },
  ]);
  const [questions1, setQuestions1] = useState([
    {
      question: "Q2: Implement a function that takes a list of shapes (circles and rectangles) and calculates their areas using polymorphism.",
      codeLanguage: "cpp",
      isOpen: false,
    },
  ]);
  const [questions2, setQuestions2] = useState([
    {
      question: "Q3: Create a program that displays different messages depending on the type of vehicle (car, bike) using polymorphism.",
      codeLanguage: "cpp",
      isOpen: false,
    },
  ]);
  const [questions3, setQuestions3] = useState([
    {
      question: "Q4: Extend the \"Animal\" hierarchy with more animal types and override the \"makeSound\" method for each.",
      codeLanguage: "cpp",
      isOpen: false,
    },
  ]);
  const [questions4, setQuestions4] = useState([
    {
      question: "Q5: Build a drawing application where different shapes can be drawn on a canvas using polymorphism.",
      codeLanguage: "cpp",
      isOpen: false,
    },
  ]);
  const [questions5, setQuestions5] = useState([
    {
      question: "Q6: Implement a program that simulates a traffic signal with different vehicle types using polymorphism.",
      codeLanguage: "cpp",
      isOpen: false,
    },
  ]);
  const [questions6, setQuestions6] = useState([
    {
      question: "Q7: Design a music player application that can play different audio formats (mp3, wav) using polymorphism.",
      codeLanguage: "cpp",
      isOpen: false,
    },
  ]);
  const [questions7, setQuestions7] = useState([
    {
      question: "Q8: Create a graphical user interface (GUI) library that can render various UI elements (buttons, text fields) with polymorphic rendering functions.",
      codeLanguage: "cpp",
      isOpen: false,
    },
  ]);
  const [questions8, setQuestions8] = useState([
    {
      question: "Q9: Implement a plugin system for a text editor where different plugins provide syntax highlighting for various programming languages using polymorphism.",
      codeLanguage: "cpp",
      isOpen: false,
    },
  ]);
  

  const toggleAccordion = (index, questions, setQuestions) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].isOpen = !updatedQuestions[index].isOpen;
    setQuestions(updatedQuestions);
  };

  const changeCodeLanguage = (index, language, questions, setQuestions) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].codeLanguage = language;
    setQuestions(updatedQuestions);
  };

  

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };
  const divRef = useRef(null);
  return (
    <div className="testing">
      <Sidebar>
        <div className="containera">
          <div className="ch-content">
            <h2 className="ui header">
            <Link to="/ques20">
              <button
                className="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
              </Link>
              <Link to="/ques22">
                <button
                  className="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>
            <div className="main-body ui">
              <h2 className="ui left floated header" style={{ color: "#001C30" }}>
                Polymorphism:
              </h2>
              <div className="ui clearing divider"></div>
              <CodeAccordion
                questions={questions}
                code={code}
                toggleAccordion={(index) => toggleAccordion(index, questions, setQuestions)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions, setQuestions)
                }
              /><br/>
              <CodeAccordion
                questions={questions1}
                code={code1}
                toggleAccordion={(index) => toggleAccordion(index, questions1, setQuestions1)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions1, setQuestions1)
                }
              /><br/>
              <CodeAccordion
                questions={questions2}
                code={code2}
                toggleAccordion={(index) => toggleAccordion(index, questions2, setQuestions2)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions2, setQuestions2)
                }
              /><br/>
              <CodeAccordion
                questions={questions3}
                code={code3}
                toggleAccordion={(index) => toggleAccordion(index, questions3, setQuestions3)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions3, setQuestions3)
                }
              /><br/>
              <CodeAccordion
                questions={questions4}
                code={code4}
                toggleAccordion={(index) => toggleAccordion(index, questions4, setQuestions4)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions4, setQuestions4)
                }
              /><br/>
              <CodeAccordion
                questions={questions5}
                code={code5}
                toggleAccordion={(index) => toggleAccordion(index, questions5, setQuestions5)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions5, setQuestions5)
                }
              /><br/>
              <CodeAccordion
                questions={questions6}
                code={code6}
                toggleAccordion={(index) => toggleAccordion(index, questions6, setQuestions6)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions6, setQuestions6)
                }
              /><br/>
              <CodeAccordion
                questions={questions7}
                code={code7}
                toggleAccordion={(index) => toggleAccordion(index, questions7, setQuestions7)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions7, setQuestions7)
                }
              /><br/>
              <CodeAccordion
                questions={questions8}
                code={code8}
                toggleAccordion={(index) => toggleAccordion(index, questions8, setQuestions8)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions8, setQuestions8)
                }
              /><br/>
              
            </div>
            <Link to="/ques20">
              <button
                className="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
              </Link>
              <Link to="/ques22">
                <button
                  className="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default Question21;
