import { useRef, useEffect } from "react";
import "../../Components/Chapters/Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "../PythonSideBar";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

const PythonC7 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };

  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };
  const pc7code1 = `name = input("Enter your name: ")
print("Your name is " + name) 
#here we are doing concatenation using "+".`;
  const pc7code2 = `num = input("Enter a number: ")
#data type before conversion
print(type(num))

number = int(num)
#data type after conversion
print(type(number))`;
const pc7code3 = `# Taking input two numbers from the user
num1 = input("Enter first number: ")
num2 = input("Enter second number: ")

# Type casting
num1 = int(num1)
num2 = int(num2)

# Performing operations on input data
sum = num1 + num2
diff = num1 - num2
mul = num1 * num2
div = num1 / num2

# Printing the results on screen
print("Sum of two numbers is ", sum)
print("Difference of two numbers is ", diff)
print("Multiplication of two numbers is ", mul)
print("Division of two numbers is ", div)`
  return (
    <div class="testing">
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/python/variables">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/python/data_types">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Taking User's Input:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                We can take input from the user at run time and can store that
                input in variable. After giving input, press enter.
              </span>
              <br />
              <br />

              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>input.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc7code1}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <span>
                <ul className="ui unordered list">
                  <li>
                  Whatever input you give will be stored in the variable <b>name</b> and we will use this variable when we will have to print the name.
                  </li>
                  <li>
                    We can also perform some mathematical operation on our
                    input.{" "}
                  </li>
                  <li>
                    By default our input has string data type and for doing
                    mathematical operations, we have to convert it into number.
                    We will discus this concept in{" "}
                    <Link to="/python/strings" style={{ color: "red" }}>
                      string
                    </Link>{" "}
                    section.
                  </li>
                  <li>
                    The type of data which is given by the user at run time is string type, 
                    no matter if he enters a number. The reason is that the data type of input 
                    entered by the user is string in Python.
                  </li>
                </ul>
              </span>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Type Casting:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                When we want to specify data type for our variable, we use type
                casting. The type of data which we are taking from the user as input is string. But to perform mathematical operations, we
                need numbers. That is why, we need type conversion.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>numbers.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc7code2}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <p style={{ backgroundColor: "#FBEEAC", padding: "15px" }}>
                <b>Note: </b>
                We have converted our string type data into int type data. In
                the same way, we can convert any type of data into our desired
                data type to perform specific functions.
              </p>
            </div>
            <br />
            <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Building a Simple Calculator:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                In the following example, we will build a simple calculator which can do basic operations like 
                addition, subtraction, multiplication and division.
              </span><br /><br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>numbers.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc7code3}
                  </SyntaxHighlighter>
                </div>
              </div>


            <br />
            <Link to="/python/variables">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/python/data_types">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </div>
  );
};
export default PythonC7;
