import { useRef, useEffect } from "react";
import "../../Components/Chapters/Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Sidebar from "../JavaSideBar";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const JavaC31 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };

  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };
  const jc31code1 = `import java.io.*;`;
  const jc31code2 = `import java.io.File;
import java.io.FileOutputStream;
import java.io.IOException;
  
public class CreateFileExample {
  public static void main(String[] args) {
    // Specify the file path
    String filePath = "newfile.txt";
  
    try {
      // Create a File object
      File file = new File(filePath);
  
      // Check if the file already exists
      if (file.exists()) {
        System.out.println("File already exists.");
      } else {
        // Create a new file
        if (file.createNewFile()) {
          System.out.println("File created.");
        } else {
          System.out.println("Failed.");
        }
      }
    } catch (IOException e) {
        e.printStackTrace();
    }
  }
}`;
  const jc31code3 = `import java.io.BufferedReader;
import java.io.FileReader;
import java.io.IOException;

public class ReadFromFileExample {
  public static void main(String[] args) {
    // Specify the file path
    String filePath = "sample.txt";

    try(BufferedReader r=new BufferedReader
      (new FileReader(filePath))) {
      String line;
      while ((line = r.readLine()) != null) {
        // Process each line (e.g., print it)
        System.out.println(line);
      }
    } catch (IOException e) {
      e.printStackTrace();
    }
  }
}`;
  const jc31code4 = `import java.io.BufferedWriter;
import java.io.FileWriter;
import java.io.IOException;

public class WriteToFileExample {
  public static void main(String[] args) {
    // Specify the file path
    String filePath = "output.txt";

    try(BufferedWriter w=new BufferedWriter(
      new FileWriter(filePath))) {
      String content = "Hello, World!";
      w.write(content);
    } catch (IOException e) {
      e.printStackTrace();
    }
  }
}`;
  const jc31code5 = `import java.io.File;

public class DeleteFileExample {
  public static void main(String[] args) {
    // Specify the file path
    String filePath = "fileToDelete.txt";

    // Create object for the file to be deleted
    File fileToDelete = new File(filePath);

    // Check if the file exists
    if (fileToDelete.exists()) {
      // Attempt to delete the file
      if (fileToDelete.delete()) {
        System.out.println("File deleted.");
      } else {
        System.err.println("Failed.");
      }
    } else {
      System.err.println("File does not exist.");
    }
  }
}`;
  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/java/exceptions">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              {/* <Link to="/java/Chapter3"> */}
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
              {/* </Link> */}
              <br />
            </h2>

            <div class="main-body ">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                File Handling:
              </h2>
              <div class="ui clearing divider"></div>
              <p style={{ backgroundColor: "", padding: "" }}>
                File handling in Java allows reading from and writing to
                files. You can perform various file operations like creating,
                reading, writing, and deleting on files.
                <br />
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Importing Necessary Package:
                </h2>
                <div class="ui clearing divider"></div>
                <span>
                  You need to import the java.io package to work with file
                  handling in Java.
                </span><br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>file.java</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="java" style={darcula}>
                      {jc31code1}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
              </p>
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Creating a File:
              </h2>
              <div class="ui clearing divider"></div>
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>file.java</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="java" style={darcula}>
                    {jc31code2}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Reading from a File:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                To read data from a file, you can use FileInputStream or other
                stream classes like BufferedReader.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>file.java</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="java" style={darcula}>
                    {jc31code3}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Writing to a File:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                To write data to a file, you can use FileOutputStream or other
                stream classes like BufferedWriter.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>file.java</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="java" style={darcula}>
                    {jc31code4}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Deleting Files:
              </h2>
              <div class="ui clearing divider"></div>
              <span>You can use the File class to delete files in Java.</span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>file.java</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="java" style={darcula}>
                    {jc31code5}
                  </SyntaxHighlighter>
                </div>
              </div>
            </div>
            <p style={{ backgroundColor: "#FBEEAC", padding: "15px" }}>
              <b>Note : </b>File handling in java allows seamless interaction with files on your computer's file system. The fundamental operations include creating new files, opening existing files, reading from files, and writing to files.
            </p>
            <br />
            <Link to="/java/exceptions">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            {/* <Link to="/java/Chapter3"> */}
            <button
              class="ui primary button"
              style={{
                flex: 1,
                minWidth: "100px",
                height: "40px",
                float: "right",
              }}
            >
              <span> Next</span>
            </button>
            {/* </Link> */}
            <br />
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default JavaC31;
