import { useRef, useEffect } from "react";
import "./Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Q24 from "../Questions/Q24";
import Sidebar from "../SideBar/Sidebar";
import Footer from "../Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const Ch24 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };
  const code1 = `#include <iostream>
using namespace std;

class Employee{
  private:
    int salary;

  public:
    void setter(int salary) {
      this->salary = salary;
    }
    int getter() {
    	return salary;
    }
};

int main() {
  Employee emp1;
  emp1.setter(50000);
  cout << emp1.getter();
  
  return 0;
}`;

  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/Cpp/friend_functions_classes">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/Cpp/inheritance">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Encapsulation
              </h2>
              <div class="ui clearing divider"></div>
              <p>
                Encapsulation is wrapping up of class members under a single
                unit. In Object Oriented Programming, we say that encapsulation
                is the binding of data and functions that manipulates them. It
                is used to hide the sensitive data from users. If you want to
                access private data, you will use getter and setter functions.{" "}
                <br />
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Getter and Setter Functions:
                </h2>
                <div class="ui clearing divider"></div>
                These are public functions which are used to get and set the
                value of private data. They are not something special and
                implemented like simple functions. Getter or setter are not the
                keywords. These are just simple terms used for the functions.
                The function which sets the value of data members is called
                setter, and the function with the help of which we can access
                values in the main function is called getter function.
                <br />
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Encapsulation and real life example:
                </h2>
                <div class="ui clearing divider"></div>
                There is a library in a school. A librarian keeps the records of
                books. A student takes a book and does not return even after the
                issued due date. The librarian needs the data(can be private
                data) of student to contact him. He will contact the information
                section of school which will provide him data (getter function)
                to get data of the student. He does not has direct access to the
                data.
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>encapsulation.cpp</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="cpp" style={darcula}>
                      {code1}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <p style={{ backgroundColor: "#FBEEAC", padding: "15px" }}>
                  <b>Note: </b>This is encapsulation. We have declared the data member
                  private and get it with the help of getter function. We are also
                  setting its value with some function(Setting data with the
                  help of some function is not the condition of encapsulation.
                  We can also set the value in the class by simple expression,
                  int salary = 20,000;)
                </p>
                <br />
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Fully Encapsulated Class:
                </h2>
                <div class="ui clearing divider"></div>
                If a class has all its data members private, it is called fully
                encapsulated class.
                <br />
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Why Encapsulation:
                </h2>
                <div class="ui clearing divider"></div>
                <ul class="ui unordered list">
                  <li>You can hide your data.</li>
                  <li>Guarantees security.</li>
                  <li>
                    If you want the class members unchanged, encapsulation will
                    help you.
                  </li>
                </ul>
              </p>
            </div>
            <br />
            <Link to="/Cpp/friend_functions_classes">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/Cpp/inheritance">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default Ch24;
