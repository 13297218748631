import { useRef, useEffect } from "react";
import "../../Components/Chapters/Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Sidebar from "../JavaSideBar";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const JavaC23 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };

  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };
  const jc23code1 = `public class Main {

  String name = "John";
  int age = 30;

  public static void main(String[] args) {

    Main obj = new Main();     

    System.out.println("Name: " + obj.name);
    System.out.println("Age: " + obj.age);
  }
}`;
  const jc23code2 = `public class Main {

  String name = "John";
  int age = 30;

  public static void main(String[] args) {

    Main obj1 = new Main();     
    System.out.println("Name: " + obj1.name);
    System.out.println("Age: " + obj1.age);


    Main obj2 = new Main();     
    System.out.println("Name: " + obj2.name);
    System.out.println("Age: " + obj2.age);
  }
}`;
  const jc23code3 = `public class Main {
  private String name;
  private int age;

  public Main(String name, int age) {
    this.name = name;
    this.age = age;
  }

  public void introduce() {
    System.out.println("Name: " + name);
    System.out.println("Age: " + age);
  }
}`;
  const jc23code4 = `public class MainApp {
    public static void main(String[] args) {
        // Create two Person objects
        Person person1 = new Person("Leah", 20);
        Person person2 = new Person("Gotti", 22);

        person1.introduce();
        person2.introduce();
    }
}`;
  const jc23code5 = `public class Main {

  String name = "John";
  int age = 30;

  public static void main(String[] args) {

    Main obj = new Main();     
    obj.name = "Andrew";
    obj.age = 20;
    System.out.println("Name: " + obj.name);
    System.out.println("Age: " + obj.age);

  }
}`;
  const jc23code6 = `public class Main {

  final String name = "John";
  final int age = 30;

  public static void main(String[] args) {

    Main obj = new Main();     
    obj.name = "Andrew";
    obj.age = 20;
    System.out.println("Name: " + obj.name);
    System.out.println("Age: " + obj.age);

  }
}`;
  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/java/oop">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/java/constructors">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body ">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Classes and Objects:
              </h2>
              <div class="ui clearing divider"></div>
              <p style={{ backgroundColor: "", padding: "" }}>
                Classes and Objects are the fundamental concepts in Object Oriented
                Programming. They are the building blocks for creating and
                organizing code in a structured and modular way.
                <br />
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Class:
                </h2>
                <div class="ui clearing divider"></div>
                <span>
                  Class is a template / blueprint which stores the
                  propeties/attributes and behaviors of objects. Class is a
                  user-defined data type.
                </span>
                <br />
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Object:
                </h2>
                <div class="ui clearing divider"></div>
                <span>
                  Object is an entity which has its properties and behavior. For
                  example, car is an object. It has properties like its color,
                  weight, number etc. and behavior like it can stop, it
                  can accelerate etc.
                  <br />
                  <ul className="ui unordered list">
                    <li>
                      <b>Properties of objects : </b>data members declared
                      inside the class.
                    </li>
                    <li>
                      <b>Behavior of objects : </b>members functions declared
                      inside the class.
                    </li>
                  </ul>
                  <br />
                  So, properties and behaviors are members of class and declared
                  inside the class.
                </span>
                <br />
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  The Dot Operator:
                </h2>
                <div class="ui clearing divider"></div>
                <span>
                  The dot operator (.) is used to access properties of object.
                  When we create an object of a class and we want to access
                  variables or methods of the class, we can access them using
                  the dot operator. We will see the usage of dot operator in the
                  example given below.
                </span>
                <br />
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Creating Object:
                </h2>
                <div class="ui clearing divider"></div>
                <span>
                  Objects are created using the new keyword followed by the
                  class constructor. The constructor initializes the object's
                  state.
                </span>
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>class.java</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="java" style={darcula}>
                      {jc23code1}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <p style={{ backgroundColor: "#FBEEAC", padding: "15px" }}>
                  <b>Note : </b>
                  <ul className="ui unordered list">
                    <li>
                      The name of the Java class should start with a capital
                      letter.
                    </li>
                    <li>
                      The name of the java class should match the file name.
                    </li>
                  </ul>
                </p>
                
              </p>

              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Multiple Objects:
              </h2>
              <div class="ui clearing divider"></div>
              <span>We can create multiple objects of the same class.</span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>class.java</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="java" style={darcula}>
                    {jc23code2}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />

             
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Multiple Classes:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                When working with multiple classes in Java, you typically define
                each class in its own ".java" file. <br />
                <br />
                Let's create two classes:
                <ul className="ui unordered list">
                  <li>
                    <b>Person - </b> represents a person with some name and age.
                  </li>
                  <li>
                    <b>MainApp - </b> entry point of the program and uses the
                    Person class.
                  </li>
                </ul>
              </span>
              <br />
             
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Person.java:
              </h2>
              <div class="ui clearing divider"></div>
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>class.java</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="java" style={darcula}>
                    {jc23code3}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                MainApp.java
              </h2>
              <div class="ui clearing divider"></div>

              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>class.java</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="java" style={darcula}>
                    {jc23code4}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              
              <h1>To compile and run this program:</h1>
              <span>
                <ul className="ui unordered list">
                  <li>
                    Compile the both classes by running "javac Person.java
                    MainApp.java".
                  </li>
                  <li>Run the program, "java MainApp".</li>
                </ul>
              </span>
              <br />

              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Changing the values of attributes:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                We can change the value of variables in our main method by
                accessing it using dot operator.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>class.java</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="java" style={darcula}>
                    {jc23code5}
                  </SyntaxHighlighter>
                </div>
              </div>

              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Unchangeable values of attributes:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                If we do not want to change the values of our attributes, we
                declared them using final keyword.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>class.java</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="java" style={darcula}>
                    {jc23code6}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <span>This code will give you error.</span>
            </div>

            <br />
            <Link to="/java/oop">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/java/constructors">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default JavaC23;
