import React, { useState } from "react";
import {
  FaTh,
  FaBars,
  FaUserAlt,
  FaRegChartBar,
  FaCommentAlt,
  FaShoppingBag,
  FaThList,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import "../../Components/SideBar/App.css";

const Sidebar = ({ children }) => {
  const [isOpenn, setisOpenn] = useState(true);
  const toggle = () => setisOpenn(!isOpenn);
  const menuItem = [
    {
      path: "/quizes/python_quiz1",
      name: "Intro to Python",
    },
    {
      path: "/quizes/python_quiz2",
      name: "Basic Syntax",
    },
    {
      path: "/quizes/python_quiz3",
      name: "Printing Output",
    },
    {
      path: "/quizes/python_quiz4",
      name: "Comments",
    },
    {
      path: "/quizes/python_quiz5",
      name: "Variables",
    },
    {
      path: "/quizes/python_quiz6",
      name: "Taking Input",
    },
    {
      path: "/quizes/python_quiz7",
      name: "Data Types",
    },
    {
      path: "/quizes/python_quiz8",
      name: "Numbers",
    },
    {
      path: "/quizes/python_quiz9",
      name: "Strings",
    },
    {
      path: "/quizes/python_quiz10",
      name: "Operators",
    },
    {
      path: "/quizes/python_quiz11",
      name: "Python Maths",
    },
    {
      path: "/quizes/python_quiz12",
      name: "List",
    },
    {
      path: "/quizes/python_quiz13",
      name: "Tuple",
    },
    {
      path: "/quizes/python_quiz14",
      name: "Sets",
    },
    {
      path: "/quizes/python_quiz15",
      name: "Dictionaries",
    },
    {
      path: "/quizes/python_quiz16",
      name: "Conditional Statements",
    },
    {
      path: "/quizes/python_quiz17",
      name: "For Loop",
    },
    {
      path: "/quizes/python_quiz18",
      name: "While Loop",
    },
    {
      path: "/quizes/python_quiz19",
      name: "Booleans",
    },
    {
      path: "/quizes/python_quiz20",
      name: "Control Statements",
    },
    {
      path: "/quizes/python_quiz21",
      name: "Functions",
    },
    {
      path: "/quizes/python_quiz22",
      name: "Classes and Objects",
    },
    {
      path: "/quizes/python_quiz23",
      name: "Variable Scope",
    },
    {
      path: "/quizes/python_quiz24",
      name: "Decorators",
    },
    {
      path: "/quizes/python_quiz25",
      name: "Inheritance",
    },
    {
      path: "/quizes/python_quiz26",
      name: "Polymorphism",
    },
    {
      path: "/quizes/python_quiz27",
      name: "Modules",
    },
    {
      path: "/quizes/python_quiz28",
      name: "JSON",
    },
    {
      path: "/quizes/python_quiz29",
      name: "Exceptions",
    },
    {
      path: "/quizes/python_quiz30",
      name: "File Handling",
    },
    {
      path: "/quizes/python_quiz31",
      name: "Iterators",
    },
  ];
  const handleClick = (e) => {
    e.preventDefault();
    setisOpenn(!isOpenn);
  };
  const handleClicked = () => {
    setisOpenn(!isOpenn);
  };
  return (
    <div className={`${isOpenn === true ? "flex" : "sidebar_"}`}>
      <input type="checkbox" id="click" />
      <label htmlFor="click" className="menu-btn" style={{ marginTop: "20%" }}>
        <i
          onClick={handleClick}
          className="fas fa-bars"
          style={{ marginTop: "20%" }}
        ></i>
      </label>
      <div
        style={{ display: "block", width: "200px" }}
        className={`${isOpenn === true ? "sidebar" : "nosidebar"}`}
      >
        <div className="top_section">
          <h1 style={{ display: "block" }} className="logo">
            <span style={{ color: "black", fontSize: "22px" }}>PythonQuiz</span>
          </h1>
        </div>
        {menuItem.map((item, index) => (
          <ul>
            <li>
              <Link
                to={item.path}
                key={index}
                className="link"
                activeclassName="active"
              >
                <div
                  style={{
                    display: isOpenn ? "block" : "block",
                    color: "black",
                    fontSize: "14px",
                  }}
                  className="link_text"
                  onClick={handleClicked}
                >
                  {item.name}
                </div>
              </Link>
            </li>
          </ul>
        ))}
      </div>
      <div className={`${isOpenn === true ? "" : "closemain"}`}>
        <main>{children}</main>
      </div>
    </div>
  );
};

export default Sidebar;
