import { useRef, useEffect } from "react";
import "./Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Sidebar from "../SideBar/Sidebar";
import Footer from "../Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const Ch3 = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  const divRef = useRef(null);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };

  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };

  const code1 = `#include <iostream>
using namespace std;
int main(){
    return 0;
}`;
  const code2 = `#include <iostream>
using namespace std;
int main(){
    cout<<"Hello World!";
    return 0;
}`;
  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/Cpp/getting_started">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/Cpp/output">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Basic Syntax:
              </h2>
              <div class="ui clearing divider"></div>
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>helloworld.cpp</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="cpp" style={darcula}>
                    {code2}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <p style={{ backgroundColor: "#FBEEAC", padding: "10px" }}>
                <b>Note:</b> This is the base of C++ code. We have to write this
                code everytime we are writing C++ program.
              </p>
              <br />
              <p>
                Now we will understand the example from the previous chapter.{" "}
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>helloworld.cpp</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="cpp" style={darcula}>
                      {code2}
                    </SyntaxHighlighter>
                  </div>
                </div>
              </p>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Description of Code:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                <b>Line 1 : </b>
                iostream is a library in C++ that provides input and output
                functionality to interact with the console or other input/output
                devices.{" "}
              </span>
              <br />
              <span>
                <b>Line 2 : </b>
                using namespace std; means that we can use names for variables
                and objects from the standard library.{" "}
              </span>
              <br />
              <span>
                <b>Line 3 : </b>
                int main(){} this is the starting point for the execution of
                your program. Any code written inside the curly brackets {} will
                be executed.{" "}
              </span>
              <br />
              <span>
                <b>Line 4 : </b>
                cout &lt; &lt; "Hello World!" - it will print Hello World!
              </span>
              <br />
              <span>
                <b>Line 5 : </b>
                return 0; execution of main function stops here. This is the
                last line of the main function which is executed. Everything
                written after it is not executed.
              </span>
              <br />
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Components:
              </h2>
              <div class="ui clearing divider"></div>
              Basically, there are three components of C code there: <br />
              <ol className="ui ordered list">
                <li>
                  <b>Header Files </b>provides functionality for many functions.
                </li>
                <li>
                  <b>Main Function </b> is the entry poiny of the code.
                </li>
                <li>
                  <b>Return Statement </b> is the exit point of the code.
                </li>
              </ol>
              <p style={{ backgroundColor: "#FBEEAC", padding: "10px" }}>
                <b>Note : </b> This partitioning has no technical basis. This is
                done just for the purpose for understanding the code easily for
                new developers.
              </p>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                What does return 0 mean:
              </h2>
              <div class="ui clearing divider"></div>
              Whenever we write a function in C++, it returns some value. The
              type of value is the return type of the function (can be a number,
              text etc.). In our case, return type of our function is int as you
              can see. That is why it is returning 0. If the function has return
              type void, it will not return anything.
              <br />
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Some important points regarding syntax of C:
              </h2>
              <div class="ui clearing divider"></div>
              <p style={{ backgroundColor: "#FBEEAC", padding: "20px" }}>
                <div className="list">
                  <ul class="ui list">
                    <li>
                      You need to put a semicolon (;) after every line of code
                      or more specifically after every instruction.
                    </li>
                    <li>
                      C++ compiler ignores white spaces. You can write all the
                      code in one line but multiple lines make the code
                      readable.{" "}
                    </li>
                    <li>
                      C++ is case sensitive language. E.g. John is not same as
                      john.
                    </li>
                    <li>
                      If you have any confusion, you can read this tutorial
                      again and do not worry about the header files. If you do
                      not understand them, just ignore and move ahead. You will
                      understand them later on.
                    </li>
                  </ul>
                </div>
              </p>
            </div>
            <br />
            <Link to="/Cpp/getting_started">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/Cpp/output">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default Ch3;
