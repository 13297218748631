import { useRef , useEffect} from "react";
import "../../Components/Chapters/Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Sidebar from "../JavaSideBar";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const JavaC20 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };

  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };
  const jc20code1 = `type methodName(type paramName1, ...) {
    // Method body
}`;
  const jc20code2 = `public class Main {

    // This is a method names "hello"
    static void hello() {
      System.out.println("Hello World!");
    }
    // "hello" method ends

    
    // Main method starts
    public static void main(String[] args) {

      //Calling "hello" method 
      hello();
    }
  }`;
  const jc20code3 = `public class Main {

    public int add() {
    	int a = 4, b = 1;
        return a + b;
    }

    // Main method starts
    public static void main(String[] args) {

        Main main = new Main(); 

        int result = main.add(); 
        System.out.println(result);
    }
}`;
  const jc20code4 = `public class Main {

    public int add(int a, int b) {
        return a + b;
    }

    // Main method starts
    public static void main(String[] args) {
        Main main = new Main(); 
        int result = main.add(1, 2); 
        System.out.println(result);
    }
}`;
  const jc20code5 = `public class Main {

    public void printName(String name) {
        System.out.println("My name is " + name);
    }

    // Main method starts
    public static void main(String[] args) {
    
        Main main = new Main();
        main.printName("John"); 
        main.printName("Ammy");
        main.printName("Sarah");
        
    }
}`;
  const jc20code6 = `public class Main {

    public int add(int a, int b) {
        return a + b;
    }

    public int add(int num1, int num2, int num3) {
        return num1 + num2 + num3;
    }

    // Main method starts
    public static void main(String[] args) {

        Main main = new Main(); 
        int result1 = main.add(1, 2); 
        System.out.println("Result: " + result1);

        int result2 = main.add(1, 2, 3); 
        System.out.println("Result: " + result2);


    }
}`;
  const jc20code7 = `public class Main {

    public int add(int a, int b) {
        return a + b;
    }

    public double add(double a, double b) {
        return a + b;
    }

    // Main method starts
    public static void main(String[] args) {

        Main main = new Main(); 
        int result1 = main.add(1, 2); 
        System.out.println("Result: " + result1);

        double result2 = main.add(1.3, 2.6); 
        System.out.println("Result: " + result2);


    }
}`;
  const jc20code8 = `public class Factorial {

    // Recursive function to calculate factorial
    public static int factorial(int n) {
        // Base case: if n is 0 or 1, return 1
        if (n == 0 || n == 1) {
            return 1;
        } else {
            // Recursive case: n! = n * (n-1)!
            return n * factorial(n - 1);
        }
    }

    public static void main(String[] args) {
        int n = 5;
        int result = factorial(n);
        System.out.println(n + "! = " + result);
    }
}`;
  const jc20code9 = `public class Main {
  // Static method to add two numbers
  public static int add(int a, int b) {
      return a + b;
  }

  public static void main(String[] args) {
      int result = Main.add(5, 3); 
      System.out.println("Sum: " + result);
  }
}`;
  const jc20code10 = `public class Main {
  // Static method to add two numbers
  public int add(int a, int b) {
      return a + b;
  }

  public static void main(String[] args) {
  
    Main main = new Main(); 
      int result = main.add(5, 3); 
      
      System.out.println("Sum: " + result);
  }
}`;
  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/java/arrays">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/java/scope">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body ">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Methods in Java:
              </h2>
              <div class="ui clearing divider"></div>
              <p style={{ backgroundColor: "", padding: "" }}>
                A method is a block of code which is separated from the main
                method and performs specific operations or tasks. They are
                defined within the classes and can be called (invoked) to execute
                their code. Methods are used to encapsulate functionality and
                promote code reusability.
                <br />
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Syntax:
                </h2>
                <div class="ui clearing divider"></div>
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>helloworld.java</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="java" style={darcula}>
                      {jc20code1}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
              </p>
              <ul className="ui unordered list">
                <li>
                  <b>returnType - </b>Specifies the type of data value the method
                  is returning.
                </li>
                <li>
                  <b>methodName - </b>The name of the method, which must be a
                  valid identifier.
                </li>
                <li>
                  <b>parameterTypeX - </b>The data type of each parameter.
                </li>
                <li>
                  <b>parameterNameX - </b>The name of each parameter.
                </li>
              </ul>
              <br />
              <p style={{ backgroundColor: "#FBEEAC", padding: "15px" }}>
                <b>Note : </b> If we do not need parameters, it is not necessary
                to write anything inside parentheses.
              </p>

              <br />

              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Method with return type void:
              </h2>
              <div class="ui clearing divider"></div>

              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>helloworld.java</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="java" style={darcula}>
                    {jc20code2}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <span>
                This hello() method will print "Hello World!". We can call
                methods multiple times. <br />
                <br />
                Execution of our code starts from Main method. Everything inside
                main method will be executed by the compiler. If we write
                 a method outside the main method, it will not
                be executed until we call it in our main method.
              </span>
              <br />
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Method with return type int:
              </h2>
              <div class="ui clearing divider"></div>

              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>helloworld.java</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="java" style={darcula}>
                    {jc20code3}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />

              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Parameterized Methods:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                Till now, we have studied about unparameterized methods. Now we
                will talk about parameterized methods. <br />
                <br />
                Parameterized methods are the methods which take variables as
                parameters and perform operations on them. These methods are
                designed to take input values, process them, and possibly return
                a result or perform some action based on the provided
                parameters.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>helloworld.java</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="java" style={darcula}>
                    {jc20code4}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />

              <p style={{ backgroundColor: "#FBEEAC", padding: "15px" }}>
                <b>Note : </b>The return value of a method can be stored in a
                variable.
              </p>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Parameters and Arguments:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                When there is a variable in the main function and we need it in
                the other function then we pass it to other function when we
                call it(inside parentheses). We can pass multiple arguments to a method but they 
                should be equal to the number of parameters.
                <br />
                <br />
                <b>Parameters : </b>variables in parenthesis when we declare
                function. <br />
                <b>Arguments : </b>variables in parenthesis when we call
                function. <br />
                <br />
              </span>
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>helloworld.java</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="java" style={darcula}>
                    {jc20code5}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <span>
                In this example, we are calling the same function with different
                parameter values. The method printName will accept the
                parameters which we provide as arguments and use this value to
                execute the code inside the function.
              </span>
              <br />
              <br />

              <p style={{ backgroundColor: "#FBEEAC", padding: "15px" }}>
                <b>Note : </b>We will pass as many arguments as we want. The
                number of arguments and parameters must be same.
              </p>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Method Overloading:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                Method overloading allows us to define multiple methods  with the same name in the
                same class but; <br />
                <ul className="ui unordered list">
                  <li>number of parameters will be different, Or</li>
                  <li>if same, their data types will be different</li>
                </ul>
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>helloworld.java</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="java" style={darcula}>
                    {jc20code6}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />

              <span>
                This add method is overloaded as number of parameters is
                different.
              </span>
              <br />
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Method Overloading with different data types of Parameters:
              </h2>
              <div class="ui clearing divider"></div>

            
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>helloworld.java</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="java" style={darcula}>
                    {jc20code7}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />

              <span>
                This add method is overloaded as parameters have different data
                types.
              </span>
              <br />
              <br />

              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Recursion:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                Recursion means calling the method within the same method. This
                is done to break the one complicated problem into many simpler
                problems and then solving the simpler problems. <br />
                <br />
                Let us consider an array of ten numbers and we want to sort it
                inascending order. It will be difficult. If we split our array
                into pairs of two and then try to sort it, it will be easy.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>helloworld.java</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="java" style={darcula}>
                    {jc20code8}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              
              <h1>In this Example;</h1>
              <ul className="ui unordered list">
                <li>
                  The factorial function calculates the factorial of n
                  recursively.
                </li>
                <li>
                  The base case is when n is 0 or 1; in this case, the function
                  returns 1.
                </li>
                <li>
                  In the recursive case, the function calls itself with n-1,
                  which is a smaller problem, until it reaches the base case.
                </li>
                <li>The result is calculated as n * factorial(n - 1).</li>
              </ul>
              <span>
                When we run this program with n = 5, it calculates 5! by
                breaking it down into smaller multiplications and returns the
                result of 120. This is a simple example of how recursion works
                in Java to solve a problem by breaking it down into smaller,
                similar sub-problems.
              </span>
              <br />
              <br />

              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Static VS Public Methods:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                The difference between static and public methods is that static
                methods can be called without the creation of an object while
                public methods can only be accessed using object of the class.
                <br />
                <br />
                As the concept of static and public method invloves object, you
                will have to learn about Object Oriented Programming.
              </span>
              <br />
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Static Method:
              </h2>
              <div class="ui clearing divider"></div>
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>helloworld.java</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="java" style={darcula}>
                    {jc20code9}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              

              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Public Method:
              </h2>
              <div class="ui clearing divider"></div>

              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>helloworld.java</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="java" style={darcula}>
                    {jc20code10}
                  </SyntaxHighlighter>
                </div>
              </div>
            </div>

            <br />
            <Link to="/java/arrays">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/java/scope">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default JavaC20;
