import { useRef, useEffect } from "react";
import "../../Components/Chapters/Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "../PythonSideBar";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

const PythonC20 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };

  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor:'#D8D9DA'
  };
  const pc20code1 = `def function_name( parameters, if any):
    #code
    
#calling a function
function_name( arguments, if any )`;
  const pc20code2 = `#function starts
def printFunc():
    print("Hello World!")
#function ends

#function call
printFunc()`;
  const pc20code3 = `def show_data( name, rollNo, marks ):
print("Name is: ", name)
print("Roll no is: ", rollNo)
print("Marks are: ", marks)

name = input("Enter your name: ")
rollNo = input("Enter your roll no: ")
marks = input("Enter your marks: ")

show_data( name, rollNo, marks )`;
  const pc20code4 = `def show_data( *data ):
print("Name is: ", data[0])
print("Roll no is: ", data[1])
print("Marks are: ", data[2])

name = input("Enter your name: ")
rollNo = input("Enter your roll no: ")
marks = input("Enter your marks: ")

show_data( name, rollNo, marks )`;
  const pc20code5 = `def show_data( p3, p2, p1 ):      
print("Person 1 :", p1)
print("Person 2 :", p2)
print("Person 3 :", p3)


show_data( p1 = "Amy", p2 = "Lauren", p3 = "Jo" )`;
  const pc20code6 = `def show_data( **persons ):
print("Person 1 :", persons["p1"])
print("Person 2 :", persons["p2"])
print("Person 3 :", persons["p3"])


show_data( p1 = "Amy", p2 = "Lauren", p3 = "Jo" )`;
  const pc20code7 = `def persons(name = "Andrew"):
print("Person " + name)

persons()
persons("Sarah")`;
  const pc20code8 = `def persons(num):
return(num * 10)

print(persons(10))`;
const pc20code9 = `def factorial(num):
if num == 1 or num == 0:
    return 1
else:
    return n * factorial(n - 1)

print("Factorial of 3 is ", factorial(3))`
const pc20code10 = `def myfunction():
pass`
  return (
    <div class="testing">
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/python/control_statements">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/python/oop">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Functions:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                A function is a block of code written separately that performs a specific task. The
                code written in the function is not executed until we call it.
                The function is separated from the main function and when we
                need to perform the task, we call the function.
              </span>
              <br /><br />
              
              <span>
                There are two types of functions: <br />
                <ul className="ui unordered list">
                  <li>
                    <b>built-in functions</b> - already coded in python. We just have
                    to call them. For example, len(), dict(), list(), tuple()
                    and many more.
                  </li>
                  <li>
                    <b>User defined functions</b> - defined by users (us). We will
                    study user-defined functions in this section.
                  </li>
                </ul>
              </span>
              <br />
              
              
              <span>In python, functions are declared using def keyword.</span><br /><br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>input.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc20code1}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <span>
                  If we make a calculator in our main function and we need the
                  calculator 10 times. We will have to write code for it 10
                  times. If we declare a function and write code for calculator
                  there. And then if we need calculator 10 times, then we just
                  need to call calculator function, which will be a single line
                  of code. Furthermore, if error occurs in the code, we just
                  have to check our function.
                </span>
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>function.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc20code2}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Difference between Arguments and Parameters:
                </h2>
                <div className="ui clearing divider"></div>
                <span>
                  Arguments and parameters are not the same things. <br />
                  <br />
                  <b>Parameters</b>The variables passed in the parenthesis when
                  we define a function are called parameters. <br />
                  <b>Arguments</b>The variables that we pass in the parenthesis when
                  we call a function are called arguments. <br />
                  <br />
                  They are used to send data to a function.
                </span><br /><br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>function.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc20code3}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  A simple explanation:
                </h2>
                <div className="ui clearing divider"></div>
                <span>
                  <ul className="ui unordered list">
                    <li>nameOfStd, rollNoOfStd, marksOfStd are parameters.</li>
                    <li>name, rollNo, marks are arguments.</li>
                  </ul>
                </span><br />
                <p style={{ backgroundColor: "#FBEEAC", padding: "10px" }}>
                  <b>Note: </b>We can add as many arguments as we want. And for
                  now, keep in your mind that the number of arguments and
                  parameters must be same.
                </p><br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Arbitrary Arguments:
                </h2>
                <div className="ui clearing divider"></div>
                <span>
                  If we do not know the number of arguments, which will be
                  passed to a function, use a single parameter and add an
                  asterisk sign before its name in the function definitin.
                </span><br /><br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>function.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc20code4}
                    </SyntaxHighlighter>
                  </div>
                </div><br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  A simple explanation:
                </h2>
                <div className="ui clearing divider"></div>
                <span>
                  This *data variable acts as a tuple and recieves a collection
                  of variables at the time of function call. The items that are present in the
                  tuple are ordered in the same way as they have been passed to
                  function as arguments.
                </span>
                <br />
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Keyword Arguments:
                </h2>
                <div className="ui clearing divider"></div>
                <span>
                  The keyword arguments are the arguments which are passed to
                  the function alongwith a key. We can access the variables in
                  the function using key. Therefore, the order of passing
                  arguments does not matter.
                </span><br /><br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>function.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc20code5}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Arbitrary Keyword Arguments:
                </h2>
                <div className="ui clearing divider"></div>
                <span>
                  If we do not know the number of keyword arguments, which will
                  be passed to a function, use a single parameter and add a
                  double asterisk sign before its name in the function
                  definitin.
                </span><br /><br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>function.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc20code6}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <span>
                  This **persons variable acts as a dictionary and recieves a
                  collection of key value pairs at the time of function call.
                </span>
                <br />
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Default Parameters:
                </h2>
                <div className="ui clearing divider"></div>
                <span>
                  If we call the function without any value, we can set the
                  default value for our parameters. We have studied that if the
                  number of arguments and parameters are not same, error will
                  occur, but if we set the default value of our parameters and
                  do not pass any argument, no error will occur.
                </span><br /><br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>function.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc20code7}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Return Values:
                </h2>
                <div className="ui clearing divider"></div>
                <span>
                  In Python, functions return some values in the main function.
                  We have to use return statement for this purpose.
                </span><br /><br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>function.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc20code8}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Recursion:
                </h2>
                <div className="ui clearing divider"></div>
                <span>
                  Recursion refers to a technique where a function calls itself
                  to solve a problem by breaking it down into smaller
                  subproblems of the same type.
                </span><br /><br />
                <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>function.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc20code9}
                  </SyntaxHighlighter>
                </div>
              </div>

              <br />
                
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  The Pass Statement:
                </h2>
                <div className="ui clearing divider"></div>
                    <span>
                        We have studied about pass statement in if-statements. As the definition of 
                        if-statements can not be empty, in the same way function definition can not 
                        be empty. If we we want to declare a function and do not want to define (write code inside function body) then we have 
                        to write pass statement. We can not leave the body empty.
                    </span><br /><br />
                    <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>function.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc20code10}
                  </SyntaxHighlighter>
                </div>
              </div><br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Why functions:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                <ul className="ui unordered list">
                  <li><b>Modularity and Abstraction:</b> Functions allow you to break
                down your code into smaller and manageable chunks, each responsible
                for a specific task.</li>
                <li><b>Code Reusability:</b> Once you have written a function to
                perform a specific task, you can reuse that function in multiple places
                throughout your code.</li>
                <li><b>Testing:</b> Functions can be tested individually, making it
                easier to identify and fix issues in isolation.</li>

                </ul>
                 
                There are many other advantages of using functions. <br />{" "}
                
              </span>
              
            </div><br />
            <Link to="/python/control_statements">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/python/oop">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </div>
  );
};
export default PythonC20;
