import { useState, useEffect } from "react";
import Sidebar from "./SideBar";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
// import "./Quiz.css";
import "../../Components/Chapters/Chapters.css";
import Modal from "react-modal";

const JQuiz3 = () => {
  const [answers, setAnswers] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const handleQuizSubmit = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const questions = [
    {
      id: 1,
      question: "  In the given Java program, what is the purpose of the line public class Main:",
      options: ["It defines a public class named \"Main.\"", "It defines a private class named \"Main.\"", "It creates an instance of the \"Main\" class.", "It declares a variable of type \"Main.\""],
      correctAnswer: "It defines a public class named \"Main.\"",
    },
    {
      id: 2,
      question: " What is the significance of the \"public\" access modifier in public static void main(String[] args):",
      options: ["It means the \"main\" method can only be called from other classes within the same package.", "It means the \"main\" method can be accessed from any class.", "It means the \"main\" method is a private method.", "It has no impact on the access to the \"main\" method."],
      correctAnswer: "It means the \"main\" method can be accessed from any class.",
    },
    {
      id: 3,
      question: "  In the line public static void main(String[] args), what does \"static\" indicate:",
      options: [
        "It indicates that the method is a non-static member of the class.",
        "It indicates that the method is a private method.",
        "It indicates that the method belongs to the class itself and not to instances of the class.",
        "It has no specific meaning in this context.",
      ],
      correctAnswer: "It indicates that the method belongs to the class itself and not to instances of the class.",
    },
    {
        id: 4,
        question: "  Semi-colon indicates:",
        options: [
          "End of Class",
          "End of Expression",
          "Semi-colons are not allowed in Java",
          "Semi-colon has no impact on Java code",
        ],
        correctAnswer: "End of Expression",
      },
      {
        id: 5,
        question: "  What is the purpose of the \"String[] args\" parameter in the \"main\" method:",
        options: [
          "It represents the standard output.",
          "It defines a local variable.",
          "It allows passing command line arguments to the program.",
          "It is used for error handling.",
        ],
        correctAnswer: "It allows passing command line arguments to the program.",
      },
      {
        id: 6,
        question: "  In Java, which method serves as the entry point for a program's execution:",
        options: [
          "start",
          "initialize",
          "run",
          "main",
        ],
        correctAnswer: "main",
      },
      {
        id: 7,
        question: "  In Java, System.out.println(\"Hello World!\") does:",
        options: [
          "print \"Hello Woeld!\"",
          "gives run time error",
          "gives compile time error",
          "print \"Hello Woeld!\" and a new line",
        ],
        correctAnswer: "print \"Hello Woeld!\" and a new line",
      }

  ];
  const handleAnswerChange = (questionId, selectedAnswer) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: selectedAnswer,
    }));
  };

  const calculateScore = () => {
    let correctCount = 0;
    let incorrectCount = 0;

    questions.forEach((question) => {
      const userAnswer = answers[question.id];
      if (userAnswer === question.correctAnswer) {
        correctCount++;
      } else {
        incorrectCount++;
      }
    });

    return { correctCount, incorrectCount };
  };

  const { correctCount, incorrectCount } = calculateScore();
  return (
    <>
      <Sidebar>
        <div class="container">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/quizes/java_quiz2">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                  }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/quizes/java_quiz4">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              </h2>
              <h1 class="start content ui left floated header">
                Basic Syntax
              </h1>
              <div className="ui clearing divider"></div>
            

            <div class="main-body">
              <h2 class="ui left floated header">
                Quiz # 3
              </h2>
              <span class="tut-btn"><Link to="/java/syntax">
                  <button class="ui secondary button">Read Tutorial</button>
                </Link></span>
              <div class="ui clearing divider"></div>
              <div>
                {questions.map((question, i) => (
                  <div key={question.id}>
                    <div class="con">
                    <div class="que">Q{i+1}:<span class="one-per-left-margin">{question.question}</span></div>
                      {question.options.map((option) => (
                        <label class="label" key={option}>
                          <input
                            type="radio"
                            name={`question_${question.id}`}
                            value={option}
                            checked={answers[question.id] === option}
                            onChange={(e) =>
                              handleAnswerChange(question.id, e.target.value)
                            }
                          />
                          <span className="one-per-left-margin">{option}</span>
                          <br />
                        </label>
                      ))}
                    </div>
                    <hr />
                  </div>
                ))}<br/>
                <center><button class="ui primary button" onClick={handleQuizSubmit}>Submit Quiz</button></center>

                {/* Modal for displaying results */}
                <Modal class="modal" isOpen={isModalOpen} onRequestClose={closeModal}>
                  <b style={{fontSize:'20px'}}>Quiz Results</b>
                  <b><p>Your score is {correctCount}/{questions.length} </p></b>
                  {questions.map((question,i) => (
                    <div key={question.id}>
                      <p>Q{i+1}: {question.question}</p>
                      <p><b>Correct Answer:</b> {question.correctAnswer}</p>
                      <p className={`${question.correctAnswer}` === `${answers[question.id]}` ? "correct" : "wrong"}>
                        <b>Your Answer:</b> {answers[question.id] || "Not answered"}
                      </p>
                      <hr />
                    </div>
                  ))}
                  <button class="ui secondary button" onClick={closeModal}>Close</button><br /><br />
                </Modal>
              </div>

              <br />
            </div>
            <Link to="/quizes/java_quiz2">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                  }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/quizes/java_quiz4">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
            <br />
          </div>
        </div>
      </Sidebar>

      <div class="sideBarBody">
        <Footer />
      </div>
    </>
  );
};
export default JQuiz3;
