import { useRef, useEffect } from "react";
import "./Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Q13 from "../Questions/Q13";
import Sidebar from "../SideBar/Sidebar";
import Footer from "../Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const Ch13 = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  const divRef = useRef(null);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };
  const code1 = `for(statement-1 ; statement-2 ; statement-3)
{
  ...
}`;
  const code2 = `#include <iostream>
using namespace std;

int main(){
    int i;
    for(i=0; i<5; i++){
        cout<<”My name is Joseph”<<endl;
    }
    return 0;
}`;
  const code3 = `#include <iostream>
using namespace std;

int main(){
    int i;
    for(i=0; i<5; i++){
        cout<<i<<endl;
    }
    return 0;
}`;
  const code4 = `#include <iostream>
using namespace std;

int main(){
    for(int i=0 ; i<5 ; i++){
        cout<<"Outer loop running"
        for(int j=0 ; j<5 ; j++){
            cout<<"Inner loop running";
        }
    }
    return 0;
}`;
  const code5 = `// infinite for loop
for(;;){
  // code
}`;
  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/Cpp/switch">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/Cpp/while_loop">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                For Loop
              </h2>
              <div class="ui clearing divider"></div>
              <p>
                Loop executes the same block of code again and again until the
                given condition is true.
              </p>
              <SyntaxHighlighter language="cpp" style={darcula}>
                {code1}
              </SyntaxHighlighter>
              <br />
              <span>
                <b>Statement-1</b> – assign value to a variable. <br />
                <b>Statement-2</b> – check condition. <br />
                <b>Statement-3</b> – change the value of variable. <br />
              </span>
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>forLoop.cpp</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="cpp" style={darcula}>
                    {code2}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <span>This will print name 5 times.</span>
              <br />
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Dry Run:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                <ol className="ui ordered list">
                  <li>
                    {" "}
                    Statement-1 shows i=0. Statement-2 checks the condition, i is
                    less than 5(true), move inside loop and print name.{" "}
                  </li>

                  <li>
                    Statement-3 will increment i, now i=1. Statement-2 will check the
                    condition, i is less than 5(true), move inside loop and
                    print name.
                  </li>
                  <li>
                    Statement-3 will increment i, now i=2. Statement-2 checks the
                    condition i is less than 5(true), move inside loop and print
                    name.
                  </li>
                  <li>
                    Statement-3 will increment i, now i=3. Statement-2 checks the
                    condition i is less than 5(true), move inside loop and print
                    name.
                  </li>
                  <li>
                    Statement-3 will increment i, now i=4. Statement-2 will check the
                    condition i is less than 5(true), move inside loop and print
                    name.
                  </li>
                  <li>
                    Statement-3 will increment i, now i=5. Statement-2 will check the
                    condition i is less than 5(false), loop will terminate and
                    control moves to next line.
                  </li>
                </ol>
              </span>
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>forLoop.cpp</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="cpp" style={darcula}>
                    {code3}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Nested Loops:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                Nested loops are loops within loops. When we have a nested loops
                then, when outer loop runs one-time, inner loops complete its
                all iterations. After that control moves to the outer loop.
                Again, the outer loop runs a second time and inner loop
                completes its all iterations. This process will continue until
                the outer loop completes its all iterations.
              </span>{" "}
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>forLoop.cpp</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="cpp" style={darcula}>
                    {code4}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Infinite for loop:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                If you put the double semicolon inside paranthesis, it will
                become infinite for loop.
              </span>{" "}
              <br />
              <div ref={divRef}>
                <SyntaxHighlighter language="cpp" style={darcula}>
                  {code5}
                </SyntaxHighlighter>
              </div>
            </div>
            <br />
            <Link to="/Cpp/switch">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/Cpp/while_loop">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default Ch13;
