import { useRef, useEffect } from "react";
import "../../Components/Chapters/Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Sidebar from "../JavaSideBar";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const JavaC25 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };

  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };
  const jc25code1 = `public class MyClass {
    public int publicField;
    public void publicMethod() {
        // Code here
    }
}`;
  const jc25code2 = `public class MyClass {
    private int privateField;
    private void privateMethod() {
        // Code here
    }
}`;
  const jc25code3 = `public class MyClass {
    protected int protectedField;
    protected void protectedMethod() {
        // Code here
    }
}`;
  const jc25code4 = `class MyClass {
    int defaultField;
    void defaultMethod() {
        // Code here
    }
}`;

  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/java/constructors">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/java/encapsulation">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body ">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Access Modifiers:
              </h2>
              <div class="ui clearing divider"></div>
              <p style={{ backgroundColor: "", padding: "" }}>
                Access modifiersare the keywords which controls the
                accessibility of classes, methods, fields or other members in a
                program.
                <br />
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Public:
                </h2>
                <div class="ui clearing divider"></div>
                <span>The public members are accessible everywhere.</span>
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>helloworld.java</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="java" style={darcula}>
                      {jc25code1}
                    </SyntaxHighlighter>
                  </div>
                </div>
              </p>
              <br />

              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Private:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                The private members are accessible only within their own class.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>helloworld.java</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="java" style={darcula}>
                    {jc25code2}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />

              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Protected:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                The protected members are accessible are accessible within the
                same class, subclasses, and within the same package. They are
                not accessible from outside the package if there is no
                inheritance relationship.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>helloworld.java</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="java" style={darcula}>
                    {jc25code3}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />

              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Default (Package-Private):
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                When no access modifier is specified (i.e., no public, private,
                or protected keyword), the member has "package-private" or
                "default" access. Members with the default access are accessible
                only within the same package.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>helloworld.java</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="java" style={darcula}>
                    {jc25code4}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />

              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Some Special Modifiers in Java:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                In addition to access modifiers, java also has a few special
                modifiers: <br />
                <br />
                <ul className="ui unordered list">
                  <li>
                    <b>Static (static) : </b>Used to declare class-level members
                    (fields and methods) that belong to the class itself rather
                    than to instances of the class.{" "}
                  </li>

                  <li>
                    <b>Final (final) : </b>Used to make a member (field, method,
                    or class) unchangeable or prevent method overriding (for
                    methods) or class extension (for classes).
                  </li>

                  <li>
                    <b>Abstract (abstract) : </b>Used with classes to declare
                    abstract classes, which cannot be instantiated and may have
                    abstract methods (methods without implementations). Abstract
                    methods are meant to be implemented by subclasses.
                  </li>

                  <li>
                    <b>Transitive (transient) : </b>Used with fields to indicate
                    that they should not be serialized when an object is
                    serialized. It is used for managing object persistence.
                  </li>

                  <li>
                    <b>Volatile (volatile) : </b>Used with fields to indicate
                    that they may be modified by multiple threads, ensuring
                    proper synchronization when accessing the field.
                  </li>
                </ul>
              </span>

              <br />

              <span>
                Access modifiers play a vital role in encapsulation and
                maintaining the security and integrity of your Java code. They
                allow you to manage the accessibility of class members,
                controlling which parts of your classes can be accessed and
                modified. This practice is fundamental in adhering to sound
                object-oriented programming principles.
              </span>
            </div>

            <br />
            <Link to="/java/constructors">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/java/encapsulation">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default JavaC25;
