import { useRef, useEffect } from "react";
import "./Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
// import Q32 from "../Questions/Q32";
import Sidebar from "../SideBar/Sidebar";
import Footer from "../Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const Ch32 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor:'#D8D9DA'
  };

 
  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/Cpp/templates">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/Cpp/containers">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
              </Link>
              <br />
            </h2>

            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Standard Template Library
              </h2>
              <div class="ui clearing divider"></div>
              <p>
              Standard Template Library (STL) is a collection of template classes and functions ( discussed in the previous section ) 
              that provide common data structures and algorithms. The STL is designed to provide generic and efficient implementations of common data structures (like vectors, lists, queues, and stacks) and algorithms (like sorting, searching, and manipulating sequences). 
              It enables a C++ programmer to use these data structures without writing them from scratch. 
              These are more optimized and save development time. That is why it is a better decision to use STL. They are 
              mostly used in competitive programming where time is short.
                <br />
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Components of STL:
              </h2>
              <div class="ui clearing divider"></div>
            STL has three components.
            <ul class="ui unordered list">
                <li>Containers</li>
                <li>Algorithms</li>
                <li>Iterators</li>
            </ul>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Containers:
              </h2>
              <div class="ui clearing divider"></div>
                <p>Containers are data structures that store and manage collection of objects.</p>
                <br />Containers are further: <br />
                <ul class="ui unordered list">
                    <li>Sequence containers - Array, vectors, deque etc.</li>
                    <li>Container Adaptors - Stacks, Queues, Priority Queues</li>
                    <li>Associative Containers - Set, Map, Multiset, Multimap</li>
                    <li>Unordered Associative - Unordered Set, Unordered Map, Unordered Multiset, Unordered Multimap</li>
                </ul>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Algorithms:
              </h2>
              <div class="ui clearing divider"></div>
                <p>Algorithms are the set of instructions ( not code ) that perform various operations on 
                    sequences of elements. Some commonly used algorithms are:</p>
                    <ul class="ui unordered list">
                        <li>Sort</li>    
                        <li>Find</li>
                        <li>Accumulate</li>
                        <li>Reverse</li>
                        <li>Binary Search</li>
                    </ul><br />

                    <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                    Iterators:
              </h2>
              <div class="ui clearing divider"></div>
                <p> Iterators provide a way to access the elements of a container in a uniform and generic manner. They act like pointers to elements in the container and allow the traversal through the elements of container.</p>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                    Types of Iterators in C++:
              </h2>
              <div class="ui clearing divider"></div>
              <span>

                <b>Input Iterator:  </b>It allows traversal in forward direction and supports read only operations. It 
                is used in algorithms that support sequential access like finding an element in an array. <br />
                <b>Output Iterator: </b>It allows the assignment of values in a container and support write only operations. 
                It is used in algorithms where modifying the elements in a container is required. <br />
                <b>Random Access Iterator: </b>It supports all the operations of bidirectional iterators and additional arithmetic operations like increment, decrement, and subscripting.
                it is used in algorithms that require direct access to elements, such as sorting algorithms.

              </span>
              </p>
              
              
            </div><br />
            <Link to="/Cpp/templates">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/Cpp/containers">
            <button
              class="ui primary button"
              style={{
                flex: 1,
                minWidth: "100px",
                height: "40px",
                float: "right",
              }}
            >
              <span> Next</span>
            </button>
            </Link>
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default Ch32;
