import {useEffect} from "react";
// import './styles.css';
import Footer from "./Components/Home/Footer.jsx";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  return (
    <>
      <div className="bg-gray-100 pt-24 md:pt-28 pl-5 md:pl-28 pr-5 md:pr-28 pb-5 md:pb-24">
      {/* <br /><br /> */}
        <h1 className="text-4xl font-bold  mb-8 justify-items text-left underline">
          About Us
        </h1>
        <h2 className="text-2xl font-bold mb-2">Welcome to CodeProgs</h2>
        <p className="text-lg mb-8">
          At CodeProgs, our mission is to make programming accessible,
          engaging, and easy to learn for everyone. We're passionate about
          empowering individuals to master the art of coding, whether you're a
          complete beginner or a seasoned developer looking to intensify your
          skills.
        </p>
        <h2 className="text-2xl font-bold mb-2">Our Story</h2>
        <p className="text-lg mb-8">
          CodeProgs was founded in 2023 to provide high-quality programming
          education that's both informative and enjoyable. We believe that programming is the key to unlock limitless possibilities in this digital age. Our journey began with a simple idea: to make the world of programming accessible to everyone, regardless of their experience or background.
        </p>
        <h2 className="text-2xl font-bold mb-2">What we offer?</h2>
        <p className="text-lg mb-8">
          Our website offers a wide range of programming resources, including:{" "}
          <br />
          <ul className="ui unordered list">
            <li>
              <b>Interactive Tutorials : </b>We provide hands-on, step-by-step
              tutorials on various programming languages and technologies.
            </li>
            <li>
              <b>Coding Challenges : </b>Test and enhance your coding skills
              with our collection of coding challenges and exercises.
            </li>
           
            <li>
              <b>Cheat Sheets : </b>Discover our Cheat Sheet Section, serving as
              an essential reference for the compact language summaries. Whether
              you're just starting or an experienced coder, our succinct guides
              simplify intricate concepts, enhancing your learning journey.
            </li>
          </ul>
        </p>

        <h2 className="text-2xl font-bold mb-2">Our Commitment</h2>
        <p className="text-lg mb-4">
          At CodeProgs, we are committed to: <br />
          <ul className="ui unordered list">
            <li>
              <b>Quality Education : </b>We strive to provide the highest
              quality programming education, ensuring that our content is
              accurate, up-to-date, and relevant to the industry's needs.
            </li>
            <li>
              <b>Accessibility : </b>Our resources are designed to be accessible
              to learners of all backgrounds and experience levels.
            </li>
            <li>
              <b>Continuous Improvement : </b>We are continually improving and upgrading
              our educational materials, remaining at the forefront of
              educational technology and methodologies to ensure you have the
              most exceptional learning experience.
            </li>
            <li>
              <b>Empowering You : </b>Our mission is simple: to empower people to master programming languages and become confident developers. We are committed to provide high-quality programming tutorials, courses, and resources that are not only educational but also engaging and fun. Our goal is to bridge the learning gap and make programming accessible to everyone.
            </li>
          </ul>
        </p>
      </div>
      <Footer />
    </>
  );
};

export default AboutUs;
