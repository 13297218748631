import { useEffect } from "react";
import Footer from "./Footer";
import Intro from "./Intro";
import "./Home.css";
import { Link } from "react-router-dom";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  return (
    <div style={{ backgroundColor: "#fff" }}>
      <div className="buttons pt-24 md:pt-32">
        <Link to="/C/introduction">
          <button class="ui  button">
            <div className="btn-content">C</div>
          </button>
        </Link>

        <Link to="/Cpp/intro">
          <button class="ui  button">
            <div className="btn-content">C++</div>
          </button>
        </Link>

        <Link to="/java/introduction">
          <button class="ui  button">
            <div className="btn-space btn-content">Java</div>
          </button>
        </Link>

        <Link to="/python/introduction">
          <button class="ui  button">
            <div className="btn-content">Python</div>
          </button>
        </Link>
      </div>
      <Intro />
      <Footer />
    </div>
  );
};
export default Home;
