import { useRef, useEffect } from "react";
import "./Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Q22 from "../Questions/Q22";
import Sidebar from "../SideBar/Sidebar";
import Footer from "../Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const Ch22 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };

  const code1 = `#include <iostream>
using namespace std;

class Student {
	private: 
	//Members declared here are private
	public:
	//Members declared here are public
	protected:
	//Members declared here are protected
	
};

int main() {
    Student std;
}`;

  const code2 = `#include <iostream>
using namespace std;

class Student {
	private:
		//we can not access private members
    	int marks, rollNo;
	public:
		Student(int marks, int rollNo){
			this->marks = marks;
			this->rollNo = rollNo;
		}
		//only way to get private members
		void getData(){
			cout<<"Marks = "<<marks<<endl;
			cout<<"Roll no = "<<rollNo<<endl;
		}
	
};

int main() {
    Student std(100, 1234);
    //we can not access marks directly
    std.getData();
    return 0;
}`;
  const code3 = `#include <iostream>
using namespace std;

class Student {
	int marks;  
	public:
		void getData(){
			cout<<marks<<endl;
		}
};

int main() {
    Student std;
}`
const code4 = `#include <iostream>
using namespace std;

class Student {
	
	public:
	//Members declared here are public
	int age = 22;
	
};

int main() {
    Student std;

    //accessing public data member
    cout << std.age;
}`;
const code5 = `#include <iostream>
using namespace std;

class Student {
	
	private:
	//Members declared here are private
	int age = 22;

  //accessing private data member in same class
  int myAge = age;
	
};

int main() {
    Student std;

    // We can not access private data member.
    // The following line will give error.
    // cout << std.age;
}`;
  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/Cpp/constructors">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/Cpp/friend_functions_classes">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Access Specifiers
              </h2>
              <div class="ui clearing divider"></div>
              <p>
                Access Specifiers control the accessibility of class members
                outside the class. They are used usually with data members and
                member functions. They are also used in inheritance to access
                the members of parent class in child class. <br /> <br />
                There are three types of access specifiers:
                <ul class="ui unordered list">
                  <li>public</li>
                  <li>private</li>
                  <li>protected</li>
                </ul>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Syntax:
                </h2>
                <div class="ui clearing divider"></div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="cpp" style={darcula}>
                    {code1}
                  </SyntaxHighlighter>
                </div>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Public:
                </h2>
                <div class="ui clearing divider"></div>
                <span>
                  When we declare our members public, we can access them
                  anywhere outside of the class after their declaration. <br /><br />
                  <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>specifiers.cpp</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="cpp" style={darcula}>
                      {code4}
                    </SyntaxHighlighter>
                  </div>
                </div>
                </span>
                <br />
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Private:
                </h2>
                <div class="ui clearing divider"></div>
                <span>
                  When we declare our members private, we can access them only
                  inside of the class. When we try to access the private data 
                  members outside the class, we will get error. But when we access the private 
                  data members inside the class, we can do that.
                </span><br /><br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>specifiers.cpp</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="cpp" style={darcula}>
                      {code5}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <p style={{ backgroundColor: "#FBEEAC", padding: "15px" }}>
                 
                  <b>public members</b> can be modified directly or indirectly
                  from outside the class. <br />
                  <b>private members</b> can not be changed directly from
                  outside the class. We have to get their address in main
                  function with the help of some public function and then at
                  that address, we can change the value.
                </p>
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>specifiers.cpp</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="cpp" style={darcula}>
                      {code2}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <p style={{ backgroundColor: "#FBEEAC", padding: "15px" }}>
                  <b>Note: </b>By default members of class are private. If class
                  has data members they will be private if do not specify access
                  specifier.
                </p>
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>specifiers.cpp</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="cpp" style={darcula}>
                      {code3}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Protected Access Specifier:
                </h2>
                <div class="ui clearing divider"></div>
                Protected data members are accessed within the same class or 
                in the child class. But when we try to access them outside 
                the same class or child class, we will get an error.
                We will discuss this in inheritance.
              </p>

              <br />
            </div>
            <Link to="/Cpp/constructors">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/Cpp/friend_functions_classes">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default Ch22;
