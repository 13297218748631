import React, { useState, useRef, useEffect } from "react";
import Sidebar from "./SideBar";
import "../../Components/SideBar/App.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const CodeAccordion = ({ questions, code, toggleAccordion, changeCodeLanguage }) => {
    const divRef = useRef(null);
    const copyContent = () => {
        const divContent = divRef.current.innerText;
        navigator.clipboard
          .writeText(divContent)
          .then(() => {
            toast.success("Content copied!", { position: "top-center" });
          })
          .catch((error) => {
            toast.error("Error copying content:", error, {
              position: "top-center",
            });
          });
      };
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };

  return (
    <div>
      {questions.map((item, index) => (
        <div key={index} className="border p-2 rounded-md">
          <div className="flex justify-between">
            <span className="text-lg font-semibold">
               {item.question}
            </span>
            <button
              className="bg-gray-200 px-2 py-1 rounded-md"
              onClick={() => toggleAccordion(index)}
            >
              {item.isOpen ? "-" : "+"}
            </button>
          </div>
          <div className={`mt-2 ${item.isOpen ? "block" : "hidden"}`}>
            <div className="mt-2 flex space-x-4">
              {Object.keys(code).map((language) => (
                <button
                  key={language}
                  onClick={() => changeCodeLanguage(index, language)}
                  className={`${
                    item.codeLanguage === language
                      ? "bg-blue-500 hover:bg-blue-600 text-white"
                      : "bg-gray-200 hover:bg-gray-300 text-gray-700"
                  } px-3 py-2 rounded-md transition duration-300 ease-in-out transform hover:scale-105`}
                >
                  {language}
                </button>
              ))}
            </div>
            <p className="text-gray-800">
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>output.cpp</b>
                  <i
                    className="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language={`${item.codeLanguage}`} style={darcula}>
                    {code[item.codeLanguage]}
                  </SyntaxHighlighter>
                </div>
              </div>
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

const Question8 = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
    const code = {
        c: `// C code
#include <stdio.h>

int main() {
    int N, i = 1, sum = 0;

    printf("Enter the value of N: ");
    scanf("%d", &N);

    while (i <= N) {
        if (i % 2 == 0) {
            sum += i;
        }
        i++;
    }

    printf("The sum of even numbers from 1 to %d is %d\\n", N, sum);

    return 0;
}`,
        cpp: `// C++ code
#include <iostream>
using namespace std;

int main() {
    int N, i = 1, sum = 0;

    cout << "Enter the value of N: ";
    cin >> N;

    while (i <= N) {
        if (i % 2 == 0) {
            sum += i;
        }
        i++;
    }

    cout << "The sum of even numbers from 1 to " << N << " is " << sum << endl;

    return 0;
}`,
        java: `// Java code
import java.util.Scanner;

public class SumOfEvenNumbers {
    public static void main(String[] args) {
        Scanner scanner = new Scanner(System.in);
        int N, i = 1, sum = 0;

        System.out.print("Enter the value of N: ");
        N = scanner.nextInt();

        while (i <= N) {
            if (i % 2 == 0) {
                sum += i;
            }
            i++;
        }

        System.out.println("The sum of even numbers from 1 to " + N + " is " + sum);
    }
}`,
        python: `# Python code
N = int(input("Enter the value of N: "))
i = 1
sum_even = 0

while i <= N:
    if i % 2 == 0:
        sum_even += i
    i += 1

print(f"The sum of even numbers from 1 to {N} is {sum_even}")`,
      };
      const code1 = {
        c: `// C code
#include <stdio.h>

int main() {
    int count = 10;

    while (count >= 1) {
        printf("%d\\n", count);
        count--;
    }

    return 0;
}`,
        cpp: `// C++ code
#include <iostream>
using namespace std;

int main() {
    int count = 10;

    while (count >= 1) {
        cout << count << endl;
        count--;
    }

    return 0;
}`,
        java: `// Java code
public class CountDown {
    public static void main(String[] args) {
        int count = 10;

        while (count >= 1) {
            System.out.println(count);
            count--;
        }
    }
}`,
        python: `# Python code
count = 10

while count >= 1:
    print(count)
    count -= 1`,
      };
      const code2 = {
        c: `// C code
#include <stdio.h>

int main() {
    int number, count = 0;

    printf("Enter an integer: ");
    scanf("%d", &number);

    while (number != 0) {
        number /= 10;
        count++;
    }

    printf("Number of digits: %d\\n", count);

    return 0;
}`,
        cpp: `// C++ code
#include <iostream>
using namespace std;

int main() {
    int number, count = 0;

    cout << "Enter an integer: ";
    cin >> number;

    while (number != 0) {
        number /= 10;
        count++;
    }

    cout << "Number of digits: " << count << endl;

    return 0;
}`,
        java: `// Java code
import java.util.Scanner;

public class CountDigits {
    public static void main(String[] args) {
        Scanner scanner = new Scanner(System.in);
        int number, count = 0;

        System.out.print("Enter an integer: ");
        number = scanner.nextInt();

        while (number != 0) {
            number /= 10;
            count++;
        }

        System.out.println("Number of digits: " + count);
    }
}`,
        python: `# Python code
number = int(input("Enter an integer: "))
count = 0

while number != 0:
    number //= 10
    count += 1

print("Number of digits:", count)`,
      };
      const code3 = {
        c: `// C code
#include <stdio.h>

int main() {
    int secretNumber = 42; // You can choose any secret number
    int guess;
    int attempts = 0;

    printf("Welcome to the Number Guessing Game!\\n");

    while (1) {
        printf("Enter your guess (between 1 and 100): ");
        scanf("%d", &guess);
        attempts++;

        if (guess < secretNumber) {
            printf("Higher! Try again.\\n");
        } else if (guess > secretNumber) {
            printf("Lower! Try again.\\n");
        } else {
            printf("Congratulations! You guessed the secret number %d in %d attempts.\\n", secretNumber, attempts);
            break;
        }
    }

    return 0;
}`,
        cpp: `// C++ code
#include <iostream>
using namespace std;

int main() {
    int secretNumber = 42; // You can choose any secret number
    int guess;
    int attempts = 0;

    cout << "Welcome to the Number Guessing Game!" << endl;

    while (true) {
        cout << "Enter your guess (between 1 and 100): ";
        cin >> guess;
        attempts++;

        if (guess < secretNumber) {
            cout << "Higher! Try again." << endl;
        } else if (guess > secretNumber) {
            cout << "Lower! Try again." << endl;
        } else {
            cout << "Congratulations! You guessed the secret number " << secretNumber << " in " << attempts << " attempts." << endl;
            break;
        }
    }

    return 0;
}`,
        java: `// Java code
import java.util.Scanner;

public class NumberGuessingGame {
    public static void main(String[] args) {
        int secretNumber = 42; // You can choose any secret number
        int guess;
        int attempts = 0;

        System.out.println("Welcome to the Number Guessing Game!");

        Scanner scanner = new Scanner(System.in);

        while (true) {
            System.out.print("Enter your guess (between 1 and 100): ");
            guess = scanner.nextInt();
            attempts++;

            if (guess < secretNumber) {
                System.out.println("Higher! Try again.");
            } else if (guess > secretNumber) {
                System.out.println("Lower! Try again.");
            } else {
                System.out.println("Congratulations! You guessed the secret number " + secretNumber + " in " + attempts + " attempts.");
                break;
            }
        }
    }
}`,
        python: `# Python code
import random

secret_number = random.randint(1, 100)  # Generates a random secret number between 1 and 100
attempts = 0

print("Welcome to the Number Guessing Game!")

while True:
    guess = int(input("Enter your guess (between 1 and 100): "))
    attempts += 1

    if guess < secret_number:
        print("Higher! Try again.")
    elif guess > secret_number:
        print("Lower! Try again.")
    else:
        print(f"Congratulations! You guessed the secret number {secret_number} in {attempts} attempts.")
        break`,
      };
      const code4 = {
        c: `// C code
#include <stdio.h>

int main() {
    int number, factorial = 1;

    printf("Enter a number: ");
    scanf("%d", &number);

    if (number < 0) {
        printf("Factorial is not defined for negative numbers.\\n");
    } else {
        while (number > 0) {
            factorial *= number;
            number--;
        }

        printf("Factorial: %d\\n", factorial);
    }

    return 0;
}`,
        cpp: `// C++ code
#include <iostream>
using namespace std;

int main() {
    int number, factorial = 1;

    cout << "Enter a number: ";
    cin >> number;

    if (number < 0) {
        cout << "Factorial is not defined for negative numbers." << endl;
    } else {
        while (number > 0) {
            factorial *= number;
            number--;
        }

        cout << "Factorial: " << factorial << endl;
    }

    return 0;
}`,
        java: `// Java code
import java.util.Scanner;

public class FactorialCalculator {
    public static void main(String[] args) {
        Scanner scanner = new Scanner(System.in);
        int number, factorial = 1;

        System.out.print("Enter a number: ");
        number = scanner.nextInt();

        if (number < 0) {
            System.out.println("Factorial is not defined for negative numbers.");
        } else {
            while (number > 0) {
                factorial *= number;
                number--;
            }

            System.out.println("Factorial: " + factorial);
        }
    }
}`,
        python: `# Python code
number = int(input("Enter a number: "))
factorial = 1

if number < 0:
    print("Factorial is not defined for negative numbers.")
else:
    while number > 0:
        factorial *= number
        number -= 1

    print("Factorial:", factorial)`,
      };
      const code5 = {
        c: `// C code
#include <stdio.h>
#include <string.h>
#include <stdbool>

int main() {
    char str[100];
    printf("Enter a string: ");
    scanf("%s", str);

    int left = 0;
    int right = strlen(str) - 1;
    bool isPalindrome = true;

    while (left < right) {
        if (str[left] != str[right]) {
            isPalindrome = false;
            break;
        }
        left++;
        right--;
    }

    if (isPalindrome) {
        printf("%s is a palindrome.\\n", str);
    } else {
        printf("%s is not a palindrome.\\n", str);
    }

    return 0;
}`,
        cpp: `// C++ code
#include <iostream>
#include <string>
using namespace std;

int main() {
    string str;
    cout << "Enter a string: ";
    cin >> str;

    int left = 0;
    int right = str.length() - 1;
    bool isPalindrome = true;

    while (left < right) {
        if (str[left] != str[right]) {
            isPalindrome = false;
            break;
        }
        left++;
        right--;
    }

    if (isPalindrome) {
        cout << str << " is a palindrome." << endl;
    } else {
        cout << str << " is not a palindrome." << endl;
    }

    return 0;
}`,
        java: `// Java code
import java.util.Scanner;

public class PalindromeChecker {
    public static void main(String[] args) {
        Scanner scanner = new Scanner(System.in);
        System.out.print("Enter a string: ");
        String str = scanner.next();

        int left = 0;
        int right = str.length() - 1;
        boolean isPalindrome = true;

        while (left < right) {
            if (str.charAt(left) != str.charAt(right)) {
                isPalindrome = false;
                break;
            }
            left++;
            right--;
        }

        if (isPalindrome) {
            System.out.println(str + " is a palindrome.");
        } else {
            System.out.println(str + " is not a palindrome.");
        }
    }
}`,
        python: `# Python code
s = input("Enter a string: ")
left = 0
right = len(s) - 1
is_palindrome = True

while left < right:
    if s[left] != s[right]:
        is_palindrome = False
        break
    left += 1
    right -= 1

if is_palindrome:
    print(f"{s} is a palindrome.")
else:
    print(f"{s} is not a palindrome.")`,
      };
      const code6 = {
        c: `// C code
#include <stdio.h>

int main() {
    char operator;
    double num1, num2, result;

    printf("Enter an operator (+, -, *, /): ");
    scanf(" %c", &operator);

    printf("Enter two numbers: ");
    scanf("%lf %lf", &num1, &num2);

    while (1) {
        switch (operator) {
            case '+':
                result = num1 + num2;
                break;
            case '-':
                result = num1 - num2;
                break;
            case '*':
                result = num1 * num2;
                break;
            case '/':
                if (num2 == 0) {
                    printf("Error! Division by zero is not allowed.\\n");
                    return 1;
                }
                result = num1 / num2;
                break;
            default:
                printf("Invalid operator. Please enter +, -, *, or /.\\n");
                return 1;
        }
        printf("Result: %.2lf\\n", result);
        break;
    }

    return 0;
}`,
        cpp: `// C++ code
#include <iostream>
using namespace std;

int main() {
    char operator;
    double num1, num2, result;

    cout << "Enter an operator (+, -, *, /): ";
    cin >> operator;

    cout << "Enter two numbers: ";
    cin >> num1 >> num2;

    while (true) {
        switch (operator) {
            case '+':
                result = num1 + num2;
                break;
            case '-':
                result = num1 - num2;
                break;
            case '*':
                result = num1 * num2;
                break;
            case '/':
                if (num2 == 0) {
                    cout << "Error! Division by zero is not allowed." << endl;
                    return 1;
                }
                result = num1 / num2;
                break;
            default:
                cout << "Invalid operator. Please enter +, -, *, or /." << endl;
                return 1;
        }
        cout << "Result: " << result << endl;
        break;
    }

    return 0;
}`,
        java: `// Java code
import java.util.Scanner;

public class SimpleCalculator {
    public static void main(String[] args) {
        Scanner scanner = new Scanner(System.in);
        char operator;
        double num1, num2, result;

        System.out.print("Enter an operator (+, -, *, /): ");
        operator = scanner.next().charAt(0);

        System.out.print("Enter two numbers: ");
        num1 = scanner.nextDouble();
        num2 = scanner.nextDouble();

        while (true) {
            switch (operator) {
                case '+':
                    result = num1 + num2;
                    break;
                case '-':
                    result = num1 - num2;
                    break;
                case '*':
                    result = num1 * num2;
                    break;
                case '/':
                    if (num2 == 0) {
                        System.out.println("Error! Division by zero is not allowed.");
                        return;
                    }
                    result = num1 / num2;
                    break;
                default:
                    System.out.println("Invalid operator. Please enter +, -, *, or /.");
                    return;
            }
            System.out.println("Result: " + result);
            break;
        }
    }
}`,
        python: `# Python code
while True:
    operator = input("Enter an operator (+, -, *, /): ")

    if operator not in ['+', '-', '*', '/']:
        print("Invalid operator. Please enter +, -, *, or /.")
        continue

    num1 = float(input("Enter the first number: "))
    num2 = float(input("Enter the second number: "))

    if operator == '+':
        result = num1 + num2
    elif operator == '-':
        result = num1 - num2
    elif operator == '*':
        result = num1 * num2
    else:
        if num2 == 0:
            print("Error! Division by zero is not allowed.")
            continue
        result = num1 / num2

    print(f"Result: {result}")
    break`,
      };
      const code7 = {
        c: `// C code
#include <stdio.h>

int main() {
    int N;
    printf("Enter the value of N: ");
    scanf("%d", &N);

    int a = 0, b = 1;
    printf("Fibonacci Sequence up to %d: %d, %d, ", N, a, b);

    while (a + b <= N) {
        int c = a + b;
        printf("%d, ", c);
        a = b;
        b = c;
    }

    printf("\\n");
    return 0;
}`,
        cpp: `// C++ code
#include <iostream>
using namespace std;

int main() {
    int N;
    cout << "Enter the value of N: ";
    cin >> N;

    int a = 0, b = 1;
    cout << "Fibonacci Sequence up to " << N << ": " << a << ", " << b << ", ";

    while (a + b <= N) {
        int c = a + b;
        cout << c << ", ";
        a = b;
        b = c;
    }

    cout << endl;
    return 0;
}`,
        java: `// Java code
import java.util.Scanner;

public class FibonacciSequence {
    public static void main(String[] args) {
        Scanner scanner = new Scanner(System.in);
        System.out.print("Enter the value of N: ");
        int N = scanner.nextInt();

        int a = 0, b = 1;
        System.out.print("Fibonacci Sequence up to " + N + ": " + a + ", " + b + ", ");

        while (a + b <= N) {
            int c = a + b;
            System.out.print(c + ", ");
            a = b;
            b = c;
        }

        System.out.println();
    }
}`,
        python: `# Python code
N = int(input("Enter the value of N: "))
a, b = 0, 1
print(f"Fibonacci Sequence up to {N}: {a}, {b}", end=", ")

while a + b <= N:
    c = a + b
    print(c, end=", ")
    a, b = b, c

print()`,
      };

  const [questions, setQuestions] = useState([
    {
      question: "Q1: Write a program to find the sum of all even numbers from 1 to N using a while loop.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions1, setQuestions1] = useState([
    {
      question: "Q2: Declare an integer variable named count and initialize it to 10. Create a loop to count down from 10 to 1 and print each value.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions2, setQuestions2] = useState([
    {
      question: "Q3: Implement a program that counts the number of digits in a given integer using a while loop.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions3, setQuestions3] = useState([
    {
      question: "Q4: Create a program that prompts the user to guess a secret number between 1 and 100 using a while loop. Provide hints (higher/lower) until the correct number is guessed.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions4, setQuestions4] = useState([
    {
      question: "Q5: Write a program that calculates the factorial of a given number using a while loop.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions5, setQuestions5] = useState([
    {
      question: "Q6: Develop a program that checks if a given string is a palindrome using a while loop.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions6, setQuestions6] = useState([
    {
      question: "Q7: Implement a simple calculator program that can perform addition, subtraction, multiplication, and division operations using a while loop.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions7, setQuestions7] = useState([
    {
      question: "Q8: Create a program that generates the Fibonacci sequence up to a given N using a while loop.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
 

  const toggleAccordion = (index, questions, setQuestions) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].isOpen = !updatedQuestions[index].isOpen;
    setQuestions(updatedQuestions);
  };

  const changeCodeLanguage = (index, language, questions, setQuestions) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].codeLanguage = language;
    setQuestions(updatedQuestions);
  };

  

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };
  const divRef = useRef(null);
  return (
    <div className="testing">
      <Sidebar>
        <div className="containera">
          <div className="ch-content">
            <h2 className="ui header">
            <Link to="/ques7">
              <button
                className="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
              </Link>
              <Link to="/ques9">
                <button
                  className="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>
            <div className="main-body ui">
              <h2 className="ui left floated header" style={{ color: "#001C30" }}>
                While Loop:
              </h2>
              <div className="ui clearing divider"></div>
              <CodeAccordion
                questions={questions}
                code={code}
                toggleAccordion={(index) => toggleAccordion(index, questions, setQuestions)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions, setQuestions)
                }
              /><br/>
              <CodeAccordion
                questions={questions1}
                code={code1}
                toggleAccordion={(index) => toggleAccordion(index, questions1, setQuestions1)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions1, setQuestions1)
                }
              /><br/>
              <CodeAccordion
                questions={questions2}
                code={code2}
                toggleAccordion={(index) => toggleAccordion(index, questions2, setQuestions2)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions2, setQuestions2)
                }
              /><br/>
              <CodeAccordion
                questions={questions3}
                code={code3}
                toggleAccordion={(index) => toggleAccordion(index, questions3, setQuestions3)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions3, setQuestions3)
                }
              /><br/>
              <CodeAccordion
                questions={questions4}
                code={code4}
                toggleAccordion={(index) => toggleAccordion(index, questions4, setQuestions4)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions4, setQuestions4)
                }
              /><br/>
              <CodeAccordion
                questions={questions5}
                code={code5}
                toggleAccordion={(index) => toggleAccordion(index, questions5, setQuestions5)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions5, setQuestions5)
                }
              /><br/>
              <CodeAccordion
                questions={questions6}
                code={code6}
                toggleAccordion={(index) => toggleAccordion(index, questions6, setQuestions6)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions6, setQuestions6)
                }
              /><br/>
              <CodeAccordion
                questions={questions7}
                code={code7}
                toggleAccordion={(index) => toggleAccordion(index, questions7, setQuestions7)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions7, setQuestions7)
                }
              /><br/>
              
            </div>
            <Link to="/ques7">
              <button
                className="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
              </Link>
              <Link to="/ques9">
                <button
                  className="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default Question8;
