import React, { useState } from "react";
import {
  FaTh,
  FaBars,
  FaUserAlt,
  FaRegChartBar,
  FaCommentAlt,
  FaShoppingBag,
  FaThList,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import "./App.css";

const Sidebar = ({ children }) => {
  const [isOpenn, setisOpenn] = useState(true);
  const toggle = () => setisOpenn(!isOpenn);
  const menuItem = [
    {
      path: "/Cpp/intro",
      name: "C++ Introduction",
    },
    {
      path: "/Cpp/getting_started",
      name: "C++ Getting Started",
    },
    {
      path: "/Cpp/syntax",
      name: "C++ Basic Syntax",
    },
    {
      path: "/Cpp/output",
      name: "C++ Printing Output",
    },
    {
      path: "/Cpp/comments",
      name: "C++ Comments",
    },
    {
      path: "/Cpp/variables",
      name: "C++ Variables",
    },
    {
      path: "/Cpp/input",
      name: "C++ Taking Input",
    },
    {
      path: "/Cpp/data_types",
      name: "C++ Data types",
    },
    {
      path: "/Cpp/operators",
      name: "C++ Operators",
    },
    {
      path: "/Cpp/strings",
      name: "C++ Strings",
    },
    {
      path: "/Cpp/if_else",
      name: "C++ Conditional Statements",
    },
    {
      path: "/Cpp/switch",
      name: "C++ Switch Statements",
    },
    {
      path: "/Cpp/for_loop",
      name: "C++ For Loop",
    },
    {
      path: "/Cpp/while_loop",
      name: "C++ While and do-while Loop",
    },
    {
      path: "/Cpp/continue_break",
      name: "C++ Continue and Break Statements",
    },
    {
      path: "/Cpp/arrays",
      name: "C++ Arrays",
    },
    {
      path: "/Cpp/struct",
      name: "C++ Structures and Unions",
    },
    {
      path: "/Cpp/pointers",
      name: "C++ Pointers",
    },
    {
      path: "/Cpp/functions",
      name: "C++ Functions",
    },
    {
      path: "/Cpp/builtin_functions",
      name: "C++ Built-in Functions",
    },
    {
      path: "/Cpp/oop",
      name: "C++ OOP",
    },
    {
      path: "/Cpp/classes_objects",
      name: "C++ Classes and Objects",
    },
    {
      path: "/Cpp/constructors",
      name: "C++ Constructors and Destructors",
    },
    {
      path: "/Cpp/access_specifiers",
      name: "C++ Access Specifiers",
    },
    {
      path: "/Cpp/friend_functions_classes",
      name: "C++ Friend Functions and Friend Classes",
    },
    {
      path: "/Cpp/encapsulation",
      name: "C++ Encapsulation",
    },
    {
      path: "/Cpp/inheritance",
      name: "C++ Inheritance",
    },
    {
      path: "/Cpp/polymorphism",
      name: "C++ Polymorphism",
    },
    {
      path: "/Cpp/abstraction",
      name: "C++ Abstraction",
    },
    {
      path: "/Cpp/exceptions",
      name: "C++ Exceptions",
    },
    {
      path: "/Cpp/file_handling",
      name: "C++ File Handling",
    },
    {
      path: "/Cpp/operator_overloading",
      name: "C++ Operator Overloading",
    },
    {
      path: "/Cpp/templates",
      name: "C++ Templates",
    },
    {
      path: "/Cpp/stl",
      name: "C++ STL",
    },
    {
      path: "/Cpp/containers",
      name: "C++ Containers",
    },
  ];
  const handleClick = (e) => {
    e.preventDefault();
    setisOpenn(!isOpenn);
  };
  const handleClicked = () => {
    setisOpenn(!isOpenn);
  };

  return (
    <div className={`${isOpenn === true ? "flex" : "sidebar_"}`}>
      <input type="checkbox" id="click" />
      <label htmlFor="click" className="menu-btn" style={{ marginTop: "16%" }}><br/>
        <i
          onClick={handleClick}
          className="fas fa-bars"
          style={{ marginTop: "0%" }}
        ></i>
      </label>
      <div
        style={{ display: "block", width: "200px" }}
        className={`${isOpenn === true ? "sidebar" : "nosidebar"}`}
      >
        <div className="top_section">
          <h1 style={{ display: "block" }} className="logo">
            <span style={{ color: "black", fontSize: "22px" }}>
              CppTutorial
            </span>
          </h1>
        </div>
        {menuItem.map((item, index) => (
          <ul>
            <li>
              <Link
                to={item.path}
                key={index}
                className="link"
                activeclassName="active"
              >
                {/* here */}
                <div
                  onClick={handleClicked}
                  style={{
                    display: isOpenn ? "block" : "block",
                    color: "black",
                    fontSize: "14px",
                  }}
                  className="link_text"
                >
                  {item.name}
                  
                </div>
              </Link>
            </li>
          </ul>
        ))}
      </div>
      <div className={`${isOpenn === true ? "" : "closemain"}`}>
        <main>{children}</main>
      </div>
    </div>
  );
};

export default Sidebar;
