import { useRef, useEffect } from "react";
import "../../Components/Chapters/Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Sidebar from "../JavaSideBar";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const JavaC4 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };

  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };
  const javaCode = `public class Main {
    public static void main(String[] args) {
        System.out.println("Hi");
        System.out.println("I am good.");
    }
}`;
  const jc4code1 = `public class Main {
    public static void main(String[] args) {
        System.out.println("Hello \\nWorld!");
    }
}`;
  const jc4code2 = `System.out.println("Hello\\nWorld");`;
  const jc4code3 = `System.out.println("Name:\\tJohn");`;
  const jc4code4 = `System.out.println("Hello\\bWorld");`;
  const jc4code5 = `System.out.println("Hello\\fWorld");`;
  const jc4code6 = `System.out.println("Hello\\rWorld");`;
  const jc4code7 = `System.out.println("Backslash: \\");`;
  const jc4code8 = `System.out.println("Single Quote: " + " \' ");`;
  const jc4code9 = `System.out.println("Double Quote: " + " \" ");`;
  const jc4code10 = `public class Main {
    public static void main(String[] args) {
        System.out.println(100);
        System.out.println(200);
    }
}`;

  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/java/syntax">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/java/comments">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body ">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Printing Output:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                The System.out.println() and System.out.print() functions are
                used to print on screen. The data to be printed on screen is
                text, variable or number.
                <br />
              </span>

              <br />
              <ul className="ui unordered list">
                <li>If it is text, it is enclosed in quotation marks.</li>
                <li>
                  If it is a variable, name of variable is written without
                  quotation marks.
                </li>
                <li>
                  If it is a number, it is written without double quotation
                  marks.
                </li>
              </ul>
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>output.java</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="java" style={darcula}>
                    {javaCode}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <span>
                We can add multiple print statements. The difference between
                System.out.println() and System.out.print() is that the
                println() statement print the text and after that prints a new
                line while in case of print statement, the next statement will
                be printed on the same line. <br />
              </span>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Line Breaks:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                We can use "\n" inside quotation marks to print a new line.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>output.java</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="java" style={darcula}>
                    {jc4code1}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Escape Sequences:
              </h2>
              <div class="ui clearing divider"></div>
              <table class="ui celled table">
                <thead>
                  <tr>
                    <th>Escape Sequence</th>
                    <th>Description</th>
                    <th>Code</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>\n</td>
                    <td>New line</td>
                    <td>
                      <SyntaxHighlighter language="java" style={darcula}>
                        {jc4code2}
                      </SyntaxHighlighter>
                    </td>
                  </tr>
                  <tr>
                    <td>\t</td>
                    <td>Tab</td>
                    <td>
                      <SyntaxHighlighter language="java" style={darcula}>
                        {jc4code3}
                      </SyntaxHighlighter>
                    </td>
                  </tr>

                  <tr>
                    <td>\b</td>
                    <td>Backspace</td>
                    <td>
                      <SyntaxHighlighter language="java" style={darcula}>
                        {jc4code4}
                      </SyntaxHighlighter>
                    </td>
                  </tr>
                  <tr>
                    <td>\f</td>
                    <td>Form feed</td>
                    <td>
                      <SyntaxHighlighter language="java" style={darcula}>
                        {jc4code5}
                      </SyntaxHighlighter>
                    </td>
                  </tr>
                  <tr>
                    <td>\r</td>
                    <td>Carriage return</td>
                    <td>
                      <SyntaxHighlighter language="java" style={darcula}>
                        {jc4code6}
                      </SyntaxHighlighter>
                    </td>
                  </tr>
                  <tr>
                    <td>\\</td>
                    <td>It adds backslash</td>
                    <td>
                      <SyntaxHighlighter language="java" style={darcula}>
                        {jc4code7}
                      </SyntaxHighlighter>
                    </td>
                  </tr>
                  <tr>
                    <td>\'</td>
                    <td>Single quote</td>
                    <td>
                      <SyntaxHighlighter language="java" style={darcula}>
                        {jc4code8}
                      </SyntaxHighlighter>
                    </td>
                  </tr>
                  <tr>
                    <td>\"</td>
                    <td>Double quote</td>
                    <td>
                      <SyntaxHighlighter language="java" style={darcula}>
                        {jc4code9}
                      </SyntaxHighlighter>
                    </td>
                  </tr>
                </tbody>
              </table>
              <br />

              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Printing Numbers:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                If we want to print numbers on screen, we do not enclose them in
                double quotation marks.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>output.java</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="java" style={darcula}>
                    {jc4code10}
                  </SyntaxHighlighter>
                </div>
              </div>
              <span>
                We can print data stored in a variable also. For this purpose,
                we have to write the name of the variable without double
                quotation marks. We will discuss this in variable section.
              </span>
            </div>
            <br />

            <Link to="/java/syntax">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/java/comments">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default JavaC4;
