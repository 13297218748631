import React from 'react'
import createRoot from "react-dom"
import App from './App'
import "./index.css"
import { BrowserRouter } from 'react-router-dom'
import Navbar from './Navbar'
import { hydrate, render } from "react-dom";


const Index = () => {
  return (

    <>

      <BrowserRouter>
        <Navbar />
        <App />

      </BrowserRouter>



    </>
  )
}
const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<Index />, rootElement);
} else {
  render(<Index />, rootElement);
}
createRoot.render(
  <Index />,
  document.querySelector('#root')
)
