import React, { useState, useRef, useEffect } from "react";
import Sidebar from "./SideBar";
import "../../Components/SideBar/App.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const CodeAccordion = ({ questions, code, toggleAccordion, changeCodeLanguage }) => {
    const divRef = useRef(null);
    const copyContent = () => {
        const divContent = divRef.current.innerText;
        navigator.clipboard
          .writeText(divContent)
          .then(() => {
            toast.success("Content copied!", { position: "top-center" });
          })
          .catch((error) => {
            toast.error("Error copying content:", error, {
              position: "top-center",
            });
          });
      };
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };

  return (
    <div>
      {questions.map((item, index) => (
        <div key={index} className="border p-2 rounded-md">
          <div className="flex justify-between">
            <span className="text-lg font-semibold">
              {item.question}
            </span>
            <button
              className="bg-gray-200 px-2 py-1 rounded-md"
              onClick={() => toggleAccordion(index)}
            >
              {item.isOpen ? "-" : "+"}
            </button>
          </div>
          <div className={`mt-2 ${item.isOpen ? "block" : "hidden"}`}>
            <div className="mt-2 flex space-x-4">
              {Object.keys(code).map((language) => (
                <button
                  key={language}
                  onClick={() => changeCodeLanguage(index, language)}
                  className={`${
                    item.codeLanguage === language
                      ? "bg-blue-500 hover:bg-blue-600 text-white"
                      : "bg-gray-200 hover:bg-gray-300 text-gray-700"
                  } px-3 py-2 rounded-md transition duration-300 ease-in-out transform hover:scale-105`}
                >
                  {language}
                </button>
              ))}
            </div>
            <p className="text-gray-800">
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>output.cpp</b>
                  <i
                    className="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language={`${item.codeLanguage}`} style={darcula}>
                    {code[item.codeLanguage]}
                  </SyntaxHighlighter>
                </div>
              </div>
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

const Question10 = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
    const code = {
        java: `// Java code
public class LargestElementInArray {
    public static void main(String[] args) {
        int[] arr = {12, 45, 78, 33, 95, 56, 23, 67};

        int largest = arr[0]; // Assume the first element is the largest

        for (int element : arr) {
            if (element > largest) {
                largest = element;
            }
        }

        System.out.println("The largest element in the array is: " + largest);
    }
}`,
        python: `# Python code
arr = [12, 45, 78, 33, 95, 56, 23, 67]

largest = arr[0]  # Assume the first element is the largest

for element in arr:
    if element > largest:
        largest = element

print("The largest element in the array is:", largest)`,
      };
      const code1 = {
        python: `# Python code
list = [12, 45, 78, 33, 95, 56, 23, 67]

sum = 0

for element in list:
    sum += element

average = sum / len(list)
print("The average of the list elements is:", average)`,
      };
      const code2 = {
        java: `// Java code
import java.util.ArrayList;
import java.util.Scanner;

public class UserInputList {
    public static void main(String[] args) {
        Scanner scanner = new Scanner(System.in);
        ArrayList<Integer> numList = new ArrayList<>();
        
        System.out.print("Enter a list of numbers separated by spaces: ");
        String userInput = scanner.nextLine();
        String[] inputArr = userInput.split(" ");

        for (String numStr : inputArr) {
            try {
                int num = Integer.parseInt(numStr);
                numList.add(num);
            } catch (NumberFormatException e) {
                System.out.println("Skipping invalid input: " + numStr);
            }
        }

        if (!numList.isEmpty()) {
            System.out.println("You entered the list: " + numList);
        } else {
            System.out.println("No valid numbers were entered.");
        }
    }
}`,
        python: `# Python code
# Take a list as user input
user_input = input("Enter a list of numbers separated by spaces: ")

# Split the input into a list of strings
input_list = user_input.split()

num_list = []

# Convert the list of strings to a list of integers (or float if needed)
for num_str in input_list:
    try:
        num = int(num_str)
        num_list.append(num)
    except ValueError:
        print(f"Skipping invalid input: {num_str}")

if num_list:
    print("You entered the list:", num_list)
else:
    print("No valid numbers were entered.")`,
      };
      const code3 = {
        java: `// Java code
import java.util.Scanner;

public class VowelsInString {
    public static void main(String[] args) {
        Scanner scanner = new Scanner(System.in);
        System.out.print("Enter a string: ");
        String input = scanner.nextLine();

        System.out.print("Vowels in the string: ");
        for (char c : input.toCharArray()) {
            if ("AEIOUaeiou".indexOf(c) != -1) {
                System.out.print(c + " ");
            }
        }
        System.out.println();
    }
}`,
        python: `# Python code
# Take a string as user input
input_str = input("Enter a string: ")

print("Vowels in the string:", end=" ")
for char in input_str:
    if char in "AEIOUaeiou":
        print(char, end=" ")
print()`,
      };
      const code4 = {
        java: `// Java code
import java.util.ArrayList;

public class SumOfEvenNumbers {
    public static void main(String[] args) {
        ArrayList<Integer> numbers = new ArrayList<>();
        numbers.add(2);
        numbers.add(5);
        numbers.add(8);
        numbers.add(12);
        numbers.add(16);

        int sum = 0;

        for (int number : numbers) {
            if (number % 2 == 0) {
                sum += number;
            }
        }

        System.out.println("Sum of even numbers: " + sum);
    }
}`,
        python: `# Python code
numbers = [2, 5, 8, 12, 16]

sum_even = 0

for number in numbers:
    if number % 2 == 0:
        sum_even += number

print("Sum of even numbers:", sum_even)`,
      };
      const code5 = {
        java: `// Java code
import java.util.ArrayList;

public class ProductOfListElements {
    public static void main(String[] args) {
        ArrayList<Integer> numbers = new ArrayList<>();
        numbers.add(2);
        numbers.add(5);
        numbers.add(8);
        numbers.add(12);
        numbers.add(16);

        int product = 1; // Initialize to 1 (the multiplicative identity)

        for (int number : numbers) {
            product *= number;
        }

        System.out.println("Product of list elements: " + product);
    }
}`,
        python: `# Python code
numbers = [2, 5, 8, 12, 16]

product = 1  # Initialize to 1 (the multiplicative identity)

for number in numbers:
    product *= number

print("Product of list elements:", product)`,
      };

  const [questions, setQuestions] = useState([
    {
      question: "Q1: Write a program to find the largest element in an array and print on screen using a for-each loop.",
      codeLanguage: "java",
      isOpen: false,
    },
  ]);
  const [questions1, setQuestions1] = useState([
    {
      question: "Q2: Implement a program to calculate the average of all the elements present in a list using a for-each loop.",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  const [questions2, setQuestions2] = useState([
    {
      question: "Q3: Create a program to find all the prime numbers in an array of integers. Also print the results on screen.",
      codeLanguage: "java",
      isOpen: false,
    },
  ]);
  const [questions3, setQuestions3] = useState([
    {
      question: "Q4: Write a program to find and print all the vowels in a given string using a for-each loop.",
      codeLanguage: "java",
      isOpen: false,
    },
  ]);
  const [questions4, setQuestions4] = useState([
    {
      question: "Q5: Develop a program that calculates the sum of all even numbers in an ArrayList using for-each loop.",
      codeLanguage: "java",
      isOpen: false,
    },
  ]);
  const [questions5, setQuestions5] = useState([
    {
      question: "Q6: Implement a program to calculate the product of all the elements in a list using a for-each loop. Also print the result on screen. (Hint: Use for-each loop for this purpose).",
      codeLanguage: "java",
      isOpen: false,
    },
  ]);
  

  const toggleAccordion = (index, questions, setQuestions) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].isOpen = !updatedQuestions[index].isOpen;
    setQuestions(updatedQuestions);
  };

  const changeCodeLanguage = (index, language, questions, setQuestions) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].codeLanguage = language;
    setQuestions(updatedQuestions);
  };

  

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };
  const divRef = useRef(null);
  return (
    <div className="testing">
      <Sidebar>
        <div className="containera">
          <div className="ch-content">
            <h2 className="ui header">
            <Link to="/ques9">
              <button
                className="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
              </Link>
              <Link to="/ques11">
                <button
                  className="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>
            <div className="main-body ui">
              <h2 className="ui left floated header" style={{ color: "#001C30" }}>
                For Each Loop:
              </h2>
              <div className="ui clearing divider"></div>
              <CodeAccordion
                questions={questions}
                code={code}
                toggleAccordion={(index) => toggleAccordion(index, questions, setQuestions)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions, setQuestions)
                }
              /><br/>
              <CodeAccordion
                questions={questions1}
                code={code1}
                toggleAccordion={(index) => toggleAccordion(index, questions1, setQuestions1)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions1, setQuestions1)
                }
              /><br/>
              <CodeAccordion
                questions={questions2}
                code={code2}
                toggleAccordion={(index) => toggleAccordion(index, questions2, setQuestions2)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions2, setQuestions2)
                }
              /><br/>
              <CodeAccordion
                questions={questions3}
                code={code3}
                toggleAccordion={(index) => toggleAccordion(index, questions3, setQuestions3)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions3, setQuestions3)
                }
              /><br/>
              <CodeAccordion
                questions={questions4}
                code={code4}
                toggleAccordion={(index) => toggleAccordion(index, questions4, setQuestions4)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions4, setQuestions4)
                }
              /><br/>
              <CodeAccordion
                questions={questions5}
                code={code5}
                toggleAccordion={(index) => toggleAccordion(index, questions5, setQuestions5)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions5, setQuestions5)
                }
              /><br/>
              
            </div>
            <Link to="/ques9">
              <button
                className="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
              </Link>
              <Link to="/ques11">
                <button
                  className="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default Question10;
