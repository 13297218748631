import { useState, useEffect } from "react";
import Sidebar from "./SideBar";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
// import "./Quiz.css";
import "../../Components/Chapters/Chapters.css";
import Modal from "react-modal";

const PQuiz25 = () => {
  const [answers, setAnswers] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const handleQuizSubmit = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const questions = [
    {
        id: 1,
        question: "  What is inheritance in Python:",
        options: ["A way to create new instances of a class", "A way to define new classes", "A way to create a modified version of an existing class by inheriting its attributes and methods", "A way to access built-in functions"],
        correctAnswer: "A way to create a modified version of an existing class by inheriting its attributes and methods",
      },
      {
        id: 2,
        question: "  What is a parent class (superclass) in inheritance:",
        options: ["A class that inherits from another class", "A class that is modified", "A class that is used to create instances", "A class that is inherited from"],
        correctAnswer: "A class that is inherited from",
      },
      {
        id: 3,
        question: "  Inheritance allows a child class to:",
        options: ["Delete attributes and methods of the parent class", "Create entirely new attributes and methods", "Modify or inherit attributes and methods from the parent class", "Define its own attributes and methods unrelated to the parent class"],
        correctAnswer: "Modify or inherit attributes and methods from the parent class",
      },
      {
        id: 4,
        question: "  Inheritance in Python can be achieved using:",
        options: ["The @ symbol", "The -> symbol", "The \"extends\" keyword", "The class name in parentheses"],
        correctAnswer: "The \"extends\" keyword",
      },
      {
        id: 5,
        question: "  What is the primary advantage of inheritance in Python:",
        options: ["Reducing the number of classes in a program", "Creating entirely new classes", "Reusing attributes and methods from existing classes", "Hiding attributes and methods from other classes"],
        correctAnswer: "Reusing attributes and methods from existing classes",
      },
      {
        id: 6,
        question: "  In inheritance, the super() function is used to:",
        options: ["Call methods from the child class", "Create new instances of classes", "Define attributes in a class", "Call methods from the parent class"],
        correctAnswer: "Call methods from the parent class",
      },
      {
        id: 7,
        question: "  Which type of inheritance allows a class to inherit from multiple parent classes:",
        options: ["Single Inheritance", "Multiple Inheritance", "Multilevel Inheritance", "Single-Level Inheritance"],
        correctAnswer: "Multiple Inheritance",
      },
      {
        id: 8,
        question: "  In multiple inheritance, a class can inherit attributes and methods from:",
        options: ["Only one parent class", "Multiple parent classes", "Any random class", "None of the parent classes"],
        correctAnswer: "Multiple parent classes",
      }
  ];
  const handleAnswerChange = (questionId, selectedAnswer) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: selectedAnswer,
    }));
  };

  const calculateScore = () => {
    let correctCount = 0;
    let incorrectCount = 0;

    questions.forEach((question) => {
      const userAnswer = answers[question.id];
      if (userAnswer === question.correctAnswer) {
        correctCount++;
      } else {
        incorrectCount++;
      }
    });

    return { correctCount, incorrectCount };
  };

  const { correctCount, incorrectCount } = calculateScore();
  return (
    <>
      <Sidebar>
        <div class="container">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/quizes/python_quiz24">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                  }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/quizes/python_quiz26">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              </h2>
              <h1 class="start content ui left floated header">
                Inheritance
              </h1>
              <div className="ui clearing divider"></div>
            

            <div class="main-body">
              <h2 class="ui left floated header">
                Quiz # 25
              </h2>
              <span class="tut-btn"><Link to="/python/inheritance">
                  <button class="ui secondary button">Read Tutorial</button>
                </Link></span>
              <div class="ui clearing divider"></div>
              <div>
                {questions.map((question, i) => (
                  <div key={question.id}>
                    <div class="con">
                    <div class="que">Q{i+1}:<span class="one-per-left-margin">{question.question}</span></div>
                      {question.options.map((option) => (
                        <label class="label" key={option}>
                          <input
                            type="radio"
                            name={`question_${question.id}`}
                            value={option}
                            checked={answers[question.id] === option}
                            onChange={(e) =>
                              handleAnswerChange(question.id, e.target.value)
                            }
                          />
                          <span className="one-per-left-margin">{option}</span>
                          <br />
                        </label>
                      ))}
                    </div>
                    <hr />
                  </div>
                ))}<br/>
                <center><button class="ui primary button" onClick={handleQuizSubmit}>Submit Quiz</button></center>

                {/* Modal for displaying results */}
                <Modal class="modal" isOpen={isModalOpen} onRequestClose={closeModal}>
                  <b style={{fontSize:'20px'}}>Quiz Results</b>
                  <b><p>Your score is {correctCount}/{questions.length} </p></b>
                  {questions.map((question,i) => (
                    <div key={question.id}>
                      <p>Q{i+1}: {question.question}</p>
                      <p><b>Correct Answer:</b> {question.correctAnswer}</p>
                      <p className={`${question.correctAnswer}` === `${answers[question.id]}` ? "correct" : "wrong"}>
                        <b>Your Answer:</b> {answers[question.id] || "Not answered"}
                      </p>
                      <hr />
                    </div>
                  ))}
                  <button class="ui secondary button" onClick={closeModal}>Close</button><br /><br />
                </Modal>
              </div>

              <br />
            </div>
            <Link to="/quizes/python_quiz24">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                  }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/quizes/python_quiz26">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
            <br />
          </div>
        </div>
      </Sidebar>

      <div class="sideBarBody">
        <Footer />
      </div>
    </>
  );
};
export default PQuiz25;
