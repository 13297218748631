import { useRef, useEffect } from "react";
import "../../Components/Chapters/Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "../PythonSideBar";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

const PythonC16 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };

  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor:'#D8D9DA'
  };

  const pc16code1 = `a = 30
b = 20
if a > b:
    print("a is greater than b")`;
  const pc16code2 = `age = int(input("Enter your age: "))
if age > 18:
    print("You are adult")
else:
    print("You are under age")`;
  const pc16code3 = `marks = int(input("Enter your marks: "))
if marks >= 30:    
    print("You have passed the exam")
elif marks < 30:   #checking condition again
    print("You are failed the exam")
else:
    print("You have written wrong marks")`;
  const pc16code4 = `marks = int(input("Enter your marks: "))
if marks >= 80 and marks <= 100:
    print("Grade is \\"A\\"")
elif marks >=70 and marks < 80:
    print("Grade is \\"B\\"")
elif marks >=60 and marks < 70:
    print("Grade is \\"C\\"")
elif marks >=50 and marks < 60:
    print("Grade is \\"D\\"")
else:
    print("You have failed the exam")`;
  const pc16code5 = `num = int(input("Enter a number: "))

if num % 3 == 0 or num % 2 == 0:
    print(f"{num} is either divisible by 3 or even.")
else:
    print(f"{num} is not even, not divisible by 3.")`;
  const pc16code6 = `a = 10
b = 20
if not a > b:
    print("a is less than b")`;
  const pc16code7 = `print("Give unique numbers as input")
num1 = int(input("Enter 1st number: "))
num2 = int(input("Enter 2nd number: "))
num3 = int(input("Enter 3rd number: "))
if num1 > num2:
    if num1 > num3:
        print("1st number > 2nd and 3rd number")
if num2 > num1:
    if num2 > num3:
        print("2nd number > 3rd and 1st number")
if num3 > num1:
    if num3 > num2:
        print("3rd > 2nd and 1st number")`;
  const pc16code8 = `choice = int(input("Press 1 to pass."))
if choice == 1:
    pass
else:
    print("You do not want to pass it")`;
  const pc16code9 = `x = int(input("Enter a number : "))
result = "Even" if x % 2 == 0 else "Odd"
print(result)`;
  const pc16code10 = `#Checking multiple conditions
a = int(input("Enter number to store in a: "))
b = int(input("Enter number to store in b: "))

print("a>b") if a>b else print("b>a") if a<b else print("a==b")`;
  const pc16code11 = `if condition1:
if condition2:
    # Code block A
else:
# Code block B`;
  return (
    <div class="testing">
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/python/dictionaries">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/python/for_loop">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Conditional Statements:
              </h2>
              <div class="ui clearing divider"></div>
                In Python, we can check the conditions by comparing variables or
                data values. We can compare two variables or data values by
                using comparison operators: <br />
                <ul className="ui unordered list">
                  <li>a == b</li>
                  <li>a != b</li>
                  <li>a &gt; b</li>
                  <li>a &gt;= b</li>
                  <li>a &lt; b</li>
                  <li>a &lt;= b</li>
                </ul>
              <br />
              <span>
                In Python, we have three types of conditional statements: <br />
                <ul className="ui unordered list">
                  <li>If statement</li>
                  <li>If-else statement</li>
                  <li>Elif statement</li>
                </ul>
              </span>

              <br />
             

              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                If Statement:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                If statement is a conditional statement in python which is used
                to check condition using logical operators. If the condition is
                true, the code is executed.
              </span>
              <br />
              <br />
              

                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>input.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc16code1}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />

                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  If-else Statement:
                </h2>
                <div class="ui clearing divider"></div>
                <span>
                  If-else statement is a conditional statement in python which
                  is used to check condition using logical operators. If the
                  condition is true, the code in the if block will be executed. But
                  if the condition is false, the condition in the else block is
                  executed.
                </span>
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>if-else.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc16code2}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Elif Statement:
                </h2>
                <div class="ui clearing divider"></div>
                <span>
                  Elif is basically else-if. It is used to check condition more
                  than one time. In case of if statement, we were checking the condition
                  only one time. On the other hand, in else statement, we were
                  not checking any condition. But in elif, we can check multiple
                  conditions.
                </span>
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>if-else.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc16code3}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />

                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Using Logical Operators:
                </h2>
                <div class="ui clearing divider"></div>
                <span>
                  We can also use logical operators alongwith conditional
                  statements. <br />
                  There are three logical operators. They are available not only
                  in Python but also in other languages.
                  <ul className="ui unordered list">
                    <li>and</li>
                    <li>or</li>
                    <li>not</li>
                  </ul>
                </span>
                <br />
                
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Logical AND Operator:
                </h2>
                <div class="ui clearing divider"></div>
                <span>
                  Logical AND is used to check two conditions on the same
                  variable. If the two conditions are true, only then it returns
                  true otherwise false and the code block is not executed.
                </span><br /><br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>if-else.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc16code4}
                    </SyntaxHighlighter>
                  </div>
                </div>
<br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Logical OR Operator:
                </h2>
                <div class="ui clearing divider"></div>
                <span>
                  Logical OR is also used to check two conditions on the same
                  variable. If only one condition is true, it returns true. If
                  both conditions are false, the code block is not executed.
                </span><br /><br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>if-else.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc16code5}
                    </SyntaxHighlighter>
                  </div>
                </div>
<br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Logical NOT Operator:
                </h2>
                <div class="ui clearing divider"></div>
                <span>
                  We use not operator to reverse the result of condition. If the
                  result is true, not will return false. If the result is false, it will 
                  return true.
                </span><br /><b></b>
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>if-else.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc16code6}
                    </SyntaxHighlighter>
                  </div>
                </div>
<br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Nested if Statements:
                </h2>
                <div class="ui clearing divider"></div>
                <span>
                  Nested if statements are if statements within if statement. If
                  the given condition is true, control moves inside the if statement.
                  There another if statement is present and checks the condition and
                  so on. In this way, we can nest multiple if statements.
                </span><br /><br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>if-else.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc16code7}
                    </SyntaxHighlighter>
                  </div>
                </div><br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Pass Statement:
                </h2>
                <div class="ui clearing divider"></div>
                <span>
                  The pass statement is a placeholder that does not do anything. It is
                  used when you need a statement for syntactical reasons, but
                  you don't want to execute any code.
                </span><br /><br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>if-else.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc16code8}
                    </SyntaxHighlighter>
                  </div>
                </div>
<br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Ternary Operators:
                </h2>
                <div class="ui clearing divider"></div>
                <span>
                  A ternary operator also known as a conditional expression is a
                  concise way to write a simple conditional statement that
                  results in a value
                </span><br /><br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>if-else.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc16code9}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                {/* <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>if-else.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc16code10}
                    </SyntaxHighlighter>
                  </div>
                </div><br /> */}

                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Dangling Else Grammar:
                </h2>
                <div class="ui clearing divider"></div>
                <span>
                  The "dangling else" problem occurs when an else statement is
                  associated with the wrong if statement due to improper
                  indentation. However, in Python, the "dangling else" problem
                  is not an issue because of Python's strict indentation rules.
                </span><br /><br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>if-else.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc16code11}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <span>
                  In this example, we paired the else statement with the inner
                  if statement because of the proper indentation. If first condition
                  is true but second condition is false, only code block B will be
                  executed.
                </span>
              
            </div><br />
            <Link to="/python/dictionaries">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/python/for_loop">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </div>
  );
};
export default PythonC16;
