import { useRef, useEffect } from "react";
import "../../Components/Chapters/Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Sidebar from "../JavaSideBar";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const JavaC2 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };

  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };
  const javaCode = `public class Main {
    public static void main(String[] args) {
        System.out.println("Hello World");
    }
}`;
  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/java/introduction">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/java/syntax">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body ">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Environment to write code:
              </h2>
              <div class="ui clearing divider"></div>
              <p style={{ backgroundColor: "", padding: "" }}>
                By environment, we means the setup where we can write and compile our code.
                To write code, we need a text editor. To compile our code, we
                need a compiler which will convert our code to machine
                understandable code. <br /><br />
                We need two things to write and compile our code: 
                <ol className="ui ordered list">
                  <li>Integrated Development Environment (IDE)</li>
                  <li>Compiler</li>
                </ol>
               
                
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Compiler:
                </h2>
                <div class="ui clearing divider"></div>
                Compiler is a program that translates instructions written in a high-level
                programming language into the equivalent machine code.
                The main purpose of Java compiler is to translate the source code 
                written in High Level Language like Java into bytecode which is then send to Java Virtual Machine for 
                execution. 
                
                <br />
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Flowchart for Java code Compilation:
                </h2>
                <div class="ui clearing divider"></div>
                The flowchart for compilation of Java coe is given below.
                <img src="/images/javaflowchart.png" alt="flowchart" style={{ width: "60%", margin: "auto" }} />
                <br /><br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Integrated Development Environment (IDE):
                </h2>
                <div class="ui clearing divider"></div>
                <span>
                  IDE functions as an editor as well as a compiler. There are
                  different IDEs available like:
                </span>
                <div className="margin">
                  <ul class="ui list">
                    <li>IntelliJ IDEA</li>
                    <li>Eclipse</li>
                    <li>NetBeans</li>
                    <li>Visual Studio Code</li>
                    <li>Oracle JDeveloper</li>
                    and more.
                  </ul>
                </div>
                <br />
                These are all free and you can use any of these to run your
                code. <br />
                <br />
              </p>
              <p style={{ backgroundColor: "#FBEEAC", padding: "10px" }}>
                <b>Note:</b> There are many Integrated Development Environments
                (IDEs) which are available online but they have limited
                functionality. For better experience, download the IDEs
                listed above.
              </p>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Writing first Java Code:
                </h2>
                <div class="ui clearing divider"></div>


              <p>
                After setting up the environment, you have to create a new project
                or open an existing one by selecting "File" -&gt;"New Project"
                or "File" -&gt; "Open" from the IntelliJ IDEA menu (if you have a project already). Choose the
                appropriate project type and follow the prompts. After setting
                up the environment, you can write the code.
              </p>
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>helloworld.java</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="java" style={darcula}>
                    {javaCode}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              This code will print "Hello World!" on screen.
              <br /><br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Components of Java code:
                </h2>
                <div class="ui clearing divider"></div>
                    <span>
                      The key components of Java code are given below: <br />
                      <ol className="ui ordered list">
                        <li>Package</li>
                        <li>Import Statements</li>
                        <li>Class declaration</li>
                        <li>Methods</li>


                      </ol>
                    </span><br />
              <p>
                We will understand this code in the next chapter.
                <br />
                Your code should be free of errors so that it would be executed without
                any difficulty.
              </p>
            </div>

            <br />
            <Link to="/java/introduction">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/java/syntax">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default JavaC2;
