import { useRef, useEffect } from "react";
import "./Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Q7 from "../Questions/Q7";
import Sidebar from "../SideBar/Sidebar";
import Footer from "../Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const Ch7 = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  const divRef = useRef(null);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };
  const code1 = `>>`;
  const code2 = `#include <iostream>
using namespace std;

int main(){
    int num; 
    cout<<"Write a number\\n"<<endl; 
    //after writing value press enter
    cin >> num;
    cout << "Value of a is " << num << endl;
    return 0;
}`;
  const code3 = `#include <iostream>
using namespace std;
int main(){
  string name;
  int age;
  double height;

  // Taking input in string type variable
  cout << "Enter your name "<<endl;
  cin>>name;

  // Taking input in int type variable
  cout << "Enter your age "<<endl;
  cin>>age;

  // Taking input in double type variable
  cout << "Enter your height "<<endl;
  cin>>height;

  cout << "Name is "<< name << endl;
  cout << "Age is "<< age << endl;
  cout << "Height is "<< height << endl;

  return 0;
}`;
  const code4 = `#include <iostream>
using namespace std;

int main(){
    int num1, num2;
    cout<<"Enter first number \\n";
    cin>>num1;
    cout<<"Enter second number \\n";
    cin>>num2;
    int sum, diff, quotient, product;
    sum = num1+num2;
    diff = num1-num2;
    quotient = num1/num2;
    product = num1*num2;
    cout<<"Sum is "<<sum<<endl;
    cout<<"Difference is "<<diff<<endl;
    cout<<"Quotient is "<<quotient <<endl;
    cout<<"Product is "<<product<<endl;
    return 0;
}`;
  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/Cpp/variables">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/Cpp/data_types">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body-color">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Taking User Input:
              </h2>
              <div class="ui clearing divider"></div>
              <p>
                We have already studied about the use of cout object along with
                the insertion operator. It is used to print string/text
                (sequence of characters like "My age is 20.") as well as
                variables on screen.
                <br />
                <br />
                For taking input from user, we use cin object along with
                extraction operator. <br />
                <br />
                <SyntaxHighlighter language="cpp" style={darcula}>
                  {code1}
                </SyntaxHighlighter>
                <br />
                cin is pronounced as "see-in"
                <br />
                <br />
              </p>
              <p style={{ backgroundColor: "#FBEEAC", padding: "10px" }}>
                <b>Point : </b>When we are taking input from the user, we store
                it in a variable.
              </p>
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>input.cpp</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="cpp" style={darcula}>
                    {code2}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <p style={{ backgroundColor: "#FBEEAC", padding: "20px" }}>
                <ul class="ui unordered list">
                  <li>We use insertion operator after cout.</li>
                  <li>cout is used for printing.</li>
                  <li>We use extraction operator after cin.</li>
                  <li>cin is used to take user input.</li>
                </ul>
              </p>
              <br />
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Taking Input from the User:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                In the given C++ program, we wre taking input in different types
                of variables.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>input.cpp</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="cpp" style={darcula}>
                    {code3}
                  </SyntaxHighlighter>
                </div>
              </div>
              In this way, we can take input in any type of variable using cin
              alongwith insertion operator (&lt;&lt;). The input we take in
              variable can also be processed. In the next example, we will
              process the input and then process it and print the result in
              screen.
              <br />
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Building a simple calculator:
              </h2>
              <div class="ui clearing divider"></div>
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>input.cpp</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="cpp" style={darcula}>
                    {code4}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
            </div>
            <br />
            <Link to="/Cpp/variables">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/Cpp/data_types">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default Ch7;
