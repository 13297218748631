import { useRef, useEffect } from "react";
import "../../Components/Chapters/Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "../PythonSideBar";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

const PythonC12 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };

  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };
  const pc12code1 = `fruits = ["apple", "banana", "cherry"]
print(fruits)`;
  const pc12code2 = `data = ["apple", "banana", "cherry"]    
print(data)

#calculating number of items in a list
print("Length of tuple : ",len(data))`;
  const pc12code2_ = `#with same data types
cars = ["Volvo", "Ferrari", "Gazoo"]
print(cars)

#with different data types
person = [1, "John", "age : 35", True, 5.5]
print(person)`;
  const pc12code2__ = `names = ["John", "Myke", "Andrew", "John"]
print(names)`;
  const pc12code3 = `animals = ["Lion", "Monkey", "Zebra"]
print(animals[0])    #first item is at 0 index
print(animals[1])    #second item is at 1 index
print(animals[2])    #third item is at 2 index`;
  const pc12code4 = `animals = ["Lion", "Monkey", "Zebra"]
animals[0] = "Snake"
print(animals)`;
  const pc12code5 = `animals = ["Lion", "Monkey", "Zebra"]
print(animals[-1])`;
  const pc12code6 = `animals = ["Lion", "Monkey", "Zebra"]
print(animals[0:2])    

#it will print to the last element
print(animals[0:])

#it will print from the first element
print(animals[:3])`;
  const pc12code7 = `fruits = ["apple", "cherry", "banana", "orange"]
fruits[0:3] = ["Kiwi", "Grapes", "Mango"]
print(fruits)`;
  const pc12code8 = `fruits = ["apple", "cherry", "banana", "orange"]
fruits[0:3] = ["Kiwi", "Grapes", "Mango"]
print(fruits)

#Replacing multiple items with a single item
fruits[0:3] = ["Kiwi"]
print(fruits)


fruits = ["apple", "cherry", "banana", "orange"]
#Replacing a single items with multiple item
fruits[0] = ["Kiwi", "Grapes", "Mango"]
print(fruits)`;
  const pc12code9 = `fruits = ["Orange"]
fruits.insert(1, "Mango")
print(fruits)`;
  const pc12code10 = `cars = ["Volvo"]
cars.append("Gazoo")
print(cars)`;
  const pc12code11 = `femaleStds = ["Ammy", "Sarah", "Leah"]
maleStds = ["Daniel", "John", "Alexander"]
femaleStds.extend(maleStds)
print(femaleStds)`;
  const pc12code12 = `nums = [1, 2, 3, 4, 5]
nums.remove(5)
print(nums)`;
  const pc12code13 = `names = ["Daniel", "Sarah", "Leah", "Daniel"]
print(names.count("Daniel"))`;
  const pc12code14 = `nums = [5, 2, 8, 1, 3]
nums.sort()

print(nums)`;
  const pc12code15 = `nums = [1, 2, 9, 2, 5]
print("Orignal numbers : ", nums)
nums.reverse()
print("Reversed numbers : ", nums)`;
  const pc12code16 = `cars = ["Ferrari", "Ford", "Mustang"]
favCars = cars.copy()
print(favCars)`;
  const pc12code17 = `fruits = ["apple", "cherry", "mango"]
print("length : ",len(fruits))`;
  const pc12code18 = `nums = [1, 2, 3, 4, 5]
poppedItem = nums.pop(2)
print("List : ",nums)
print("Popped Item : ",poppedItem)`;
  const pc12code19 = `nums = [1, 2, 3, 4, 5]
del nums[2]  
print(nums)`;
  const pc12code20 = `nums = [1, 2, 3, 4, 5]
nums.clear()  
print(nums)`;
  const pc12code21 = `nums = [1, 2, 3, 4, 5] 
print("Min number = ", min(nums))`;
  const pc12code22 = `nums = [1, 2, 3, 4, 5] 
print("Max number = ", max(nums))`;
  const pc12code23 = `nums = [1, 2, 3, 4, 5]
print("Sum = ", sum(nums))`;
  const pc12code24 = `nums = [1, 2, 3, 4, 5]
rev_nums = list(reversed(nums))

print("Original List:", nums)      
print("Reversed List:", rev_nums) `;
  const pc12code25 = `items = [True, False, True, True]

if all(items):
    print("All elements are true.")
else:
    print("Not all elements are true.")`;
  const pc12code26 = `items = [False, True, False, False]

if any(items):
    print("At least one element is true.")
else:
    print("No elements are true.")`;
  const pc12code27 = `cars = ["Ferrari", "Gazoo", "Audi"]

for index, value in enumerate(cars):
    print(f"Index: {index}, Value: {value}")`;
  const pc12code28 = `nums = [5, 2, 8, 1, 3]
nums.sort(reverse=True)

print(nums)`;
  const pc12code29 = `fruits = ["banana", "Apple", "Date", "cherry"]
fruits.sort(key=str.lower)
  
print(fruits)  `;
  const pc12code30 = `fruits = ["banana", "Apple", "Date", "cherry"]
fruits.sort(key=str.lower, reverse = True)

print(fruits) `;
  const pc12code31 = `nums = [1, 32, 51, 76, 90, 11, 13, 15, 17, 19]

# Creating an object using list
mySlice = slice(2, 8, 2)

# Using the object to get a sublist from the list
subList = nums[mySlice]

print(subList)


print(subList)`;
  const pc12code32 = `fruits = ["banana", "Apple", "Date", "cherry"]
fav_fruits = list(fruits)

print(fav_fruits) `;
  const pc12code33 = `nums = [10, 20, 30, 20, 40, 50]

index1 = nums.index(20, 0, 4)
print("Index of 20:", index1)

#also
index2 = nums.index(30)
print("Index of 30:", index2)`;
  const pc12code34 = `nums1 = [1, 2, 3]
nums2 = [4, 5, 6]
nums = nums1 + nums2

print(nums)`;
  const pc12code35 = `cars = ["Ford","Mustang","Ferrari","Mercedes"]
new_cars = [x for x in cars]
print(new_cars)

#we can also apply conditions
fav_car = [x for x in cars if x == "Mustang"]
print(fav_car)`;
  return (
    <div class="testing">
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/python/maths">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/python/tuples">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                List:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                Lists are a fundamental data structure in Python used to store
                multiple items in a single variable. These multiple items can be
                of same or different data type.
                <br />
                <ul className="ui unordered list">
                  <li>
                    <b>Changeable</b> - we can change, add, or remove the items
                    of a list.
                  </li>
                  <li>
                    <b>Ordered</b> - items in a list have a definite order. If
                    we add items to list, they will be added to the end.
                  </li>
                  <li>
                    <b>Redundant Data</b> - items of a list can be repeated.
                  </li>
                  <li>
                    <b>Indexing</b> - list items have indexes. First element has
                    0 index, second has 1 and so on.
                  </li>
                </ul>
              </span>
              <br />

              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>list.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc12code1}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Members of a List:
              </h2>
              <div className="ui clearing divider"></div>
              <span>
                Members of a list are changeable and can be of any data type. We
                can also calculate the number of elements in our list.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>list.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc12code2}
                  </SyntaxHighlighter>
                </div>
              </div>

              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Data Types of List Members:
              </h2>
              <div className="ui clearing divider"></div>
              <span>
                Members of a list can be of any data type. They can have same data types or
                different data types.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>list.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc12code2_}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Repetition:
              </h2>
              <div className="ui clearing divider"></div>
              <span>Members of a list can be repeated multiple times.</span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>list.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc12code2__}
                  </SyntaxHighlighter>
                </div>
              </div>

              <br />

              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Indexes in a List:
              </h2>
              <div className="ui clearing divider"></div>
              <span>Items in a list start from 0 index.</span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>list.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc12code3}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />

              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Range of Index:
              </h2>
              <div className="ui clearing divider"></div>
              <span>You can also specify the range of index.</span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>list.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc12code6}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Negative Index:
              </h2>
              <div className="ui clearing divider"></div>
              <span>
                Negative indexes start from the end of the list and the last
                element has -1 index. You can access the last element of list by
                giving -1 index.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>list.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc12code5}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Changing Elements:
              </h2>
              <div className="ui clearing divider"></div>
              <span>We can also change elements in a list.</span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>list.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc12code4}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Changing Elements in Range:
              </h2>
              <div className="ui clearing divider"></div>
              <span>We can change elements in range.</span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>list.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc12code7}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Adding Elements:
              </h2>
              <div className="ui clearing divider"></div>
              <span>We can add elements in list at specified index.</span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>list.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc12code8}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                List Comprehension:
              </h2>
              <div className="ui clearing divider"></div>
              <span>
                List comprehension is a powerful and concise way to create lists
                in Python. It provides us a shorter syntax to create a new list
                based on the existing list.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>list.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc12code35}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Join two lists:
              </h2>
              <div className="ui clearing divider"></div>
              <span>
                There are many ways to join two lists in Python. We can use "+"
                operator to join two lists.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>list.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc12code34}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <span>
                We can also use append or extend methods to join two lists.
                These methods are given in methods section.
              </span>
              <br />
              <br />

              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                List Methods:
              </h2>
              <div className="ui clearing divider"></div>
              <span>We can apply different methods on list.</span>
              <table class="ui very padded table">
                <thead>
                  <tr>
                    <th>Operator</th>
                    <th>Example</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <b>.insert(index, item)</b>
                    </td>
                    <td>
                      <div style={containerStyle}>
                        <div className="ml-3 mr-3">
                          <b style={{ color: "black" }}>list.py</b>

                          <i
                            class="copy icon"
                            onClick={copyContent}
                            style={{
                              cursor: "pointer",
                              float: "right",
                              fontSize: "20px",
                            }}
                          ></i>
                        </div>
                        <div ref={divRef}>
                          <SyntaxHighlighter language="python" style={darcula}>
                            {pc12code9}
                          </SyntaxHighlighter>
                        </div>
                      </div>
                    </td>

                    <td>insert items at specified index.</td>
                  </tr>

                  <tr>
                    <td>
                      <b>.append(item)</b>
                    </td>
                    <td>
                      <div style={containerStyle}>
                        <div className="ml-3 mr-3">
                          <b style={{ color: "black" }}>list.py</b>

                          <i
                            class="copy icon"
                            onClick={copyContent}
                            style={{
                              cursor: "pointer",
                              float: "right",
                              fontSize: "20px",
                            }}
                          ></i>
                        </div>
                        <div ref={divRef}>
                          <SyntaxHighlighter language="python" style={darcula}>
                            {pc12code10}
                          </SyntaxHighlighter>
                        </div>
                      </div>
                    </td>

                    <td>adds new elements at the end of the list.</td>
                  </tr>
                  <tr>
                    <td>
                      <b>.extend(iterable)</b>
                    </td>
                    <td>
                      <div style={containerStyle}>
                        <div className="ml-3 mr-3">
                          <b style={{ color: "black" }}>list.py</b>

                          <i
                            class="copy icon"
                            onClick={copyContent}
                            style={{
                              cursor: "pointer",
                              float: "right",
                              fontSize: "20px",
                            }}
                          ></i>
                        </div>
                        <div ref={divRef}>
                          <SyntaxHighlighter language="python" style={darcula}>
                            {pc12code11}
                          </SyntaxHighlighter>
                        </div>
                      </div>
                    </td>

                    <td>append another list in current list.</td>
                  </tr>
                  <tr>
                    <td>
                      <b>.remove(item)</b>
                    </td>
                    <td>
                      <div style={containerStyle}>
                        <div className="ml-3 mr-3">
                          <b style={{ color: "black" }}>list.py</b>

                          <i
                            class="copy icon"
                            onClick={copyContent}
                            style={{
                              cursor: "pointer",
                              float: "right",
                              fontSize: "20px",
                            }}
                          ></i>
                        </div>
                        <div ref={divRef}>
                          <SyntaxHighlighter language="python" style={darcula}>
                            {pc12code12}
                          </SyntaxHighlighter>
                        </div>
                      </div>
                    </td>

                    <td>remove specific item.</td>
                  </tr>
                  <tr>
                    <td>
                      <b>.pop(index)</b>
                    </td>
                    <td>
                      <div style={containerStyle}>
                        <div className="ml-3 mr-3">
                          <b style={{ color: "black" }}>list.py</b>

                          <i
                            class="copy icon"
                            onClick={copyContent}
                            style={{
                              cursor: "pointer",
                              float: "right",
                              fontSize: "20px",
                            }}
                          ></i>
                        </div>
                        <div ref={divRef}>
                          <SyntaxHighlighter language="python" style={darcula}>
                            {pc12code18}
                          </SyntaxHighlighter>
                        </div>
                      </div>
                    </td>

                    <td>remove item at specific index.</td>
                  </tr>
                  <tr>
                    <td>
                      <b>del()</b>
                    </td>
                    <td>
                      <div style={containerStyle}>
                        <div className="ml-3 mr-3">
                          <b style={{ color: "black" }}>list.py</b>

                          <i
                            class="copy icon"
                            onClick={copyContent}
                            style={{
                              cursor: "pointer",
                              float: "right",
                              fontSize: "20px",
                            }}
                          ></i>
                        </div>
                        <div ref={divRef}>
                          <SyntaxHighlighter language="python" style={darcula}>
                            {pc12code19}
                          </SyntaxHighlighter>
                        </div>
                      </div>
                    </td>

                    <td>
                      remove specific index. Also delete the list as a whole.
                      List does not exists.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>.clear()</b>
                    </td>
                    <td>
                      <div style={containerStyle}>
                        <div className="ml-3 mr-3">
                          <b style={{ color: "black" }}>list.py</b>

                          <i
                            class="copy icon"
                            onClick={copyContent}
                            style={{
                              cursor: "pointer",
                              float: "right",
                              fontSize: "20px",
                            }}
                          ></i>
                        </div>
                        <div ref={divRef}>
                          <SyntaxHighlighter language="python" style={darcula}>
                            {pc12code20}
                          </SyntaxHighlighter>
                        </div>
                      </div>
                    </td>

                    <td>empties the list. List exists with no element.</td>
                  </tr>
                  <tr>
                    <td>
                      <b>.sort()</b>
                    </td>
                    <td>
                      <div style={containerStyle}>
                        <div className="ml-3 mr-3">
                          <b style={{ color: "black" }}>list.py</b>

                          <i
                            class="copy icon"
                            onClick={copyContent}
                            style={{
                              cursor: "pointer",
                              float: "right",
                              fontSize: "20px",
                            }}
                          ></i>
                        </div>
                        <div ref={divRef}>
                          <SyntaxHighlighter language="python" style={darcula}>
                            {pc12code14}
                          </SyntaxHighlighter>
                        </div>
                      </div>
                    </td>

                    <td>sort list in ascending order.</td>
                  </tr>
                  <tr>
                    <td>
                      <b>.sort(reverse = True)</b>
                    </td>
                    <td>
                      <div style={containerStyle}>
                        <div className="ml-3 mr-3">
                          <b style={{ color: "black" }}>list.py</b>

                          <i
                            class="copy icon"
                            onClick={copyContent}
                            style={{
                              cursor: "pointer",
                              float: "right",
                              fontSize: "20px",
                            }}
                          ></i>
                        </div>
                        <div ref={divRef}>
                          <SyntaxHighlighter language="python" style={darcula}>
                            {pc12code28}
                          </SyntaxHighlighter>
                        </div>
                      </div>
                    </td>

                    <td>sort list in descending order.</td>
                  </tr>
                  <tr>
                    <td>
                      <b>.sort(key = str.lower)</b>
                    </td>
                    <td>
                      <div style={containerStyle}>
                        <div className="ml-3 mr-3">
                          <b style={{ color: "black" }}>list.py</b>

                          <i
                            class="copy icon"
                            onClick={copyContent}
                            style={{
                              cursor: "pointer",
                              float: "right",
                              fontSize: "20px",
                            }}
                          ></i>
                        </div>
                        <div ref={divRef}>
                          <SyntaxHighlighter language="python" style={darcula}>
                            {pc12code29}
                          </SyntaxHighlighter>
                        </div>
                      </div>
                    </td>
                    <td>perform case insensitive sorting.</td>
                  </tr>
                  <tr>
                    <td>
                      <b>.sort(key = str.lower, reverse)</b>
                    </td>
                    <td>
                      <div style={containerStyle}>
                        <div className="ml-3 mr-3">
                          <b style={{ color: "black" }}>list.py</b>

                          <i
                            class="copy icon"
                            onClick={copyContent}
                            style={{
                              cursor: "pointer",
                              float: "right",
                              fontSize: "20px",
                            }}
                          ></i>
                        </div>
                        <div ref={divRef}>
                          <SyntaxHighlighter language="python" style={darcula}>
                            {pc12code30}
                          </SyntaxHighlighter>
                        </div>
                      </div>
                    </td>
                    <td>
                      sort list in descending order regardless of case of
                      alphabets.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>.reverse()</b>
                    </td>
                    <td>
                      <div style={containerStyle}>
                        <div className="ml-3 mr-3">
                          <b style={{ color: "black" }}>list.py</b>

                          <i
                            class="copy icon"
                            onClick={copyContent}
                            style={{
                              cursor: "pointer",
                              float: "right",
                              fontSize: "20px",
                            }}
                          ></i>
                        </div>
                        <div ref={divRef}>
                          <SyntaxHighlighter language="python" style={darcula}>
                            {pc12code15}
                          </SyntaxHighlighter>
                        </div>
                      </div>
                    </td>

                    <td>reverse the order of the list.</td>
                  </tr>
                  <tr>
                    <td>
                      <b>.index(item, start, end)</b>
                    </td>
                    <td>
                      <div style={containerStyle}>
                        <div className="ml-3 mr-3">
                          <b style={{ color: "black" }}>list.py</b>

                          <i
                            class="copy icon"
                            onClick={copyContent}
                            style={{
                              cursor: "pointer",
                              float: "right",
                              fontSize: "20px",
                            }}
                          ></i>
                        </div>
                        <div ref={divRef}>
                          <SyntaxHighlighter language="python" style={darcula}>
                            {pc12code33}
                          </SyntaxHighlighter>
                        </div>
                      </div>
                    </td>

                    <td>returns the index of the element.</td>
                  </tr>
                  <tr>
                    <td>
                      <b>.count(item)</b>
                    </td>
                    <td>
                      <div style={containerStyle}>
                        <div className="ml-3 mr-3">
                          <b style={{ color: "black" }}>list.py</b>

                          <i
                            class="copy icon"
                            onClick={copyContent}
                            style={{
                              cursor: "pointer",
                              float: "right",
                              fontSize: "20px",
                            }}
                          ></i>
                        </div>
                        <div ref={divRef}>
                          <SyntaxHighlighter language="python" style={darcula}>
                            {pc12code13}
                          </SyntaxHighlighter>
                        </div>
                      </div>
                    </td>

                    <td>count number of items in the list.</td>
                  </tr>
                  <tr>
                    <td>
                      <b>.copy()</b>
                    </td>
                    <td>
                      <div style={containerStyle}>
                        <div className="ml-3 mr-3">
                          <b style={{ color: "black" }}>list.py</b>

                          <i
                            class="copy icon"
                            onClick={copyContent}
                            style={{
                              cursor: "pointer",
                              float: "right",
                              fontSize: "20px",
                            }}
                          ></i>
                        </div>
                        <div ref={divRef}>
                          <SyntaxHighlighter language="python" style={darcula}>
                            {pc12code16}
                          </SyntaxHighlighter>
                        </div>
                      </div>
                    </td>

                    <td>returns the copy of the list.</td>
                  </tr>
                  <tr>
                    <td>
                      <b>list()</b>
                    </td>
                    <td>
                      <div style={containerStyle}>
                        <div className="ml-3 mr-3">
                          <b style={{ color: "black" }}>list.py</b>

                          <i
                            class="copy icon"
                            onClick={copyContent}
                            style={{
                              cursor: "pointer",
                              float: "right",
                              fontSize: "20px",
                            }}
                          ></i>
                        </div>
                        <div ref={divRef}>
                          <SyntaxHighlighter language="python" style={darcula}>
                            {pc12code32}
                          </SyntaxHighlighter>
                        </div>
                      </div>
                    </td>

                    <td>returns the copy of the list.</td>
                  </tr>
                  <tr>
                    <td>
                      <b>len()</b>
                    </td>
                    <td>
                      <div style={containerStyle}>
                        <div className="ml-3 mr-3">
                          <b style={{ color: "black" }}>list.py</b>

                          <i
                            class="copy icon"
                            onClick={copyContent}
                            style={{
                              cursor: "pointer",
                              float: "right",
                              fontSize: "20px",
                            }}
                          ></i>
                        </div>
                        <div ref={divRef}>
                          <SyntaxHighlighter language="python" style={darcula}>
                            {pc12code17}
                          </SyntaxHighlighter>
                        </div>
                      </div>
                    </td>

                    <td>returns the number of elements in a list.</td>
                  </tr>
                  <tr>
                    <td>
                      <b>slice(start, stop, step)</b>
                    </td>
                    <td>
                      <div style={containerStyle}>
                        <div className="ml-3 mr-3">
                          <b style={{ color: "black" }}>list.py</b>

                          <i
                            class="copy icon"
                            onClick={copyContent}
                            style={{
                              cursor: "pointer",
                              float: "right",
                              fontSize: "20px",
                            }}
                          ></i>
                        </div>
                        <div ref={divRef}>
                          <SyntaxHighlighter language="python" style={darcula}>
                            {pc12code31}
                          </SyntaxHighlighter>
                        </div>
                      </div>
                    </td>

                    <td>
                      returns a slice of the list from start index to stop index
                      with a given step.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>min()</b>
                    </td>
                    <td>
                      <div style={containerStyle}>
                        <div className="ml-3 mr-3">
                          <b style={{ color: "black" }}>list.py</b>

                          <i
                            class="copy icon"
                            onClick={copyContent}
                            style={{
                              cursor: "pointer",
                              float: "right",
                              fontSize: "20px",
                            }}
                          ></i>
                        </div>
                        <div ref={divRef}>
                          <SyntaxHighlighter language="python" style={darcula}>
                            {pc12code21}
                          </SyntaxHighlighter>
                        </div>
                      </div>
                    </td>

                    <td>
                      returns the smallest item in the list (only works if the
                      items are comparable).
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>max()</b>
                    </td>
                    <td>
                      <div style={containerStyle}>
                        <div className="ml-3 mr-3">
                          <b style={{ color: "black" }}>list.py</b>

                          <i
                            class="copy icon"
                            onClick={copyContent}
                            style={{
                              cursor: "pointer",
                              float: "right",
                              fontSize: "20px",
                            }}
                          ></i>
                        </div>
                        <div ref={divRef}>
                          <SyntaxHighlighter language="python" style={darcula}>
                            {pc12code22}
                          </SyntaxHighlighter>
                        </div>
                      </div>
                    </td>

                    <td>
                      returns the largest item in the list (only works if the
                      items are comparable).
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>sum()</b>
                    </td>
                    <td>
                      <div style={containerStyle}>
                        <div className="ml-3 mr-3">
                          <b style={{ color: "black" }}>list.py</b>

                          <i
                            class="copy icon"
                            onClick={copyContent}
                            style={{
                              cursor: "pointer",
                              float: "right",
                              fontSize: "20px",
                            }}
                          ></i>
                        </div>
                        <div ref={divRef}>
                          <SyntaxHighlighter language="python" style={darcula}>
                            {pc12code23}
                          </SyntaxHighlighter>
                        </div>
                      </div>
                    </td>

                    <td>
                      returns the sum item in the list (only works for numeric
                      type data).
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>all()</b>
                    </td>
                    <td>
                      <div style={containerStyle}>
                        <div className="ml-3 mr-3">
                          <b style={{ color: "black" }}>list.py</b>

                          <i
                            class="copy icon"
                            onClick={copyContent}
                            style={{
                              cursor: "pointer",
                              float: "right",
                              fontSize: "20px",
                            }}
                          ></i>
                        </div>
                        <div ref={divRef}>
                          <SyntaxHighlighter language="python" style={darcula}>
                            {pc12code25}
                          </SyntaxHighlighter>
                        </div>
                      </div>
                    </td>

                    <td>
                      returns true if the list is empty or all the elements of the list are true.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>any()</b>
                    </td>
                    <td>
                      <div style={containerStyle}>
                        <div className="ml-3 mr-3">
                          <b style={{ color: "black" }}>list.py</b>

                          <i
                            class="copy icon"
                            onClick={copyContent}
                            style={{
                              cursor: "pointer",
                              float: "right",
                              fontSize: "20px",
                            }}
                          ></i>
                        </div>
                        <div ref={divRef}>
                          <SyntaxHighlighter language="python" style={darcula}>
                            {pc12code26}
                          </SyntaxHighlighter>
                        </div>
                      </div>
                    </td>

                    <td>
                      returns true if any one of the elements of the list are
                      true or if the list is not empty.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>enumerate()</b>
                    </td>
                    <td>
                      <div style={containerStyle}>
                        <div className="ml-3 mr-3">
                          <b style={{ color: "black" }}>list.py</b>

                          <i
                            class="copy icon"
                            onClick={copyContent}
                            style={{
                              cursor: "pointer",
                              float: "right",
                              fontSize: "20px",
                            }}
                          ></i>
                        </div>
                        <div ref={divRef}>
                          <SyntaxHighlighter language="python" style={darcula}>
                            {pc12code27}
                          </SyntaxHighlighter>
                        </div>
                      </div>
                    </td>

                    <td>
                      returns an iterator that generates pairs of index and
                      value for each item in the list.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>reversed()</b>
                    </td>
                    <td>
                      <div style={containerStyle}>
                        <div className="ml-3 mr-3">
                          <b style={{ color: "black" }}>list.py</b>

                          <i
                            class="copy icon"
                            onClick={copyContent}
                            style={{
                              cursor: "pointer",
                              float: "right",
                              fontSize: "20px",
                            }}
                          ></i>
                        </div>
                        <div ref={divRef}>
                          <SyntaxHighlighter language="python" style={darcula}>
                            {pc12code24}
                          </SyntaxHighlighter>
                        </div>
                      </div>
                    </td>

                    <td>
                      returns a reverse iterator over the list's elements.
                    </td>
                  </tr>
                </tbody>
              </table>
              <p style={{ backgroundColor: "#FBEEAC", padding: "15px" }}>
                <b>Note : </b>In this tutorial, we have studied about lists and we have also discussed about 
                various methods of list and their implementation. Each method is discussed with name, code and 
                its description. You can practice different questions of lists in question section.
              </p>
              <br />
            </div>
            <Link to="/python/maths">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/python/tuples">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </div>
  );
};
export default PythonC12;
