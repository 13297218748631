import React, { useState } from "react";
import {
  FaTh,
  FaBars,
  FaUserAlt,
  FaRegChartBar,
  FaCommentAlt,
  FaShoppingBag,
  FaThList,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import "../Components/SideBar/App.css";

const Sidebar = ({ children }) => {
  const [isOpenn, setisOpenn] = useState(true);
  const toggle = () => setisOpenn(!isOpenn);
  const menuItem = [
    {
      path: "/java/introduction",
      name: "Java Introduction",
    },
    {
      path: "/java/getting_started",
      name: "Java Getting Started",
    },
    {
      path: "/java/syntax",
      name: "Java Basic Syntax",
    },
    {
      path: "/java/output",
      name: "Java Printing Output",
    },
    {
      path: "/java/comments",
      name: "Java Comments",
    },
    {
      path: "/java/variables",
      name: "Java Variables",
    },
    {
      path: "/java/input",
      name: "Java Taking Input",
    },
    {
      path: "/java/data_types",
      name: "Java Data Types",
    },
    {
      path: "/java/type_casting",
      name: "Java Type Casting",
    },
    {
      path: "/java/strings",
      name: "Java Strings",
    },
    {
      path: "/java/operators",
      name: "Java Operators",
    },
    {
      path: "/java/maths",
      name: "Java Java Maths",
    },
    {
      path: "/java/booleans",
      name: "Java Booleans",
    },

    {
      path: "/java/if_else",
      name: "Java Conditional Statements",
    },
    {
      path: "/java/switch",
      name: "Java Switch Statements",
    },
    {
      path: "/java/for_loop",
      name: "Java For Loop",
    },
    {
      path: "/java/while_loop",
      name: "Java While Loops",
    },

    {
      path: "/java/continue_break",
      name: "Java Break and Continue Statements",
    },
    {
      path: "/java/arrays",
      name: "Java Arrays",
    },
    {
      path: "/java/methods",
      name: "Java Methods",
    },
    {
      path: "/java/scope",
      name: "Java Scope",
    },
    {
      path: "/java/oop",
      name: "Java OOP",
    },
    {
      path: "/java/classes_objects",
      name: "Java Classes and Objects",
    },
    {
      path: "/java/constructors",
      name: "Java Constructors",
    },
    {
      path: "/java/modifiers",
      name: "Java Access Modifiers",
    },
    {
      path: "/java/encapsulation",
      name: "Java Encapsulation",
    },
    {
      path: "/java/inheritance",
      name: "Java Inheritance",
    },
    {
      path: "/java/interfaces",
      name: "Java Interfaces",
    },
    {
      path: "/java/abstraction",
      name: "Java Abstraction",
    },
    {
      path: "/java/polymorphism",
      name: "Java Polymorphism",
    },

    {
      path: "/java/exceptions",
      name: "Java Try-Except",
    },
    {
      path: "/java/file_handling",
      name: "Java File Handling",
    },
  ];
  const handleClick = (e) => {
    e.preventDefault();
    setisOpenn(!isOpenn);
  };
  const handleClicked = () => {
    setisOpenn(!isOpenn);
  };
  return (
    <div className={`${isOpenn === true ? "flex" : "sidebar_"}`}>
      <input type="checkbox" id="click" />
      <label htmlFor="click" className="menu-btn" style={{ marginTop: "16%" }}><br/>
        <i
          onClick={handleClick}
          className="fas fa-bars"
          style={{ marginTop: "0%" }}
        ></i>
      </label>
      <div
        style={{ display: "block", width: "200px" }}
        className={`${isOpenn === true ? "sidebar" : "nosidebar"}`}
      >
        <div className="top_section">
          <h1 style={{ display: "block" }} className="logo">
            <span style={{ color: "black", fontSize: "22px" }}>
              JavaTutorial
            </span>
          </h1>
        </div>
        {menuItem.map((item, index) => (
          <ul>
            <li>
              <Link
                to={item.path}
                key={index}
                className="link"
                activeclassName="active"
              >
                {/* here */}
                <div
                  onClick={handleClicked}
                  style={{
                    display: isOpenn ? "block" : "block",
                    color: "black",
                    fontSize: "14px",
                  }}
                  className="link_text"
                >
                  {item.name}
                </div>
              </Link>
            </li>
          </ul>
        ))}
      </div>
      <div className={`${isOpenn === true ? "" : "closemain"}`}>
        <main>{children}</main>
      </div>
    </div>
  );
};

export default Sidebar;
