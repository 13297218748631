import { useRef, useEffect } from "react";
import "../../Components/Chapters/Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "../PythonSideBar";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

const PythonC27 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };

  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };
  const pc27code1 = `import math

x = 2.0

sqrt_x = math.sqrt(x)
sin_x = math.sin(x)
log_x = math.log(x)
exp_x = math.exp(x)

print("Square Root:", sqrt_x)
print("Sine:", sin_x)
print("Natural Logarithm:", log_x)
print("Exponential:", exp_x)


y = 10.34
print(y.floor())
print(y.ceil())`;
  const pc27code2 = `import numpy as np

arr = np.array([1, 2, 3, 4, 5])

mean = np.mean(arr)
median = np.median(arr)
std_deviation = np.std(arr)

print("Mean:", mean)
print("Median:", median)
print("Standard Deviation:", std_deviation)`;
  const pc27code3 = `# rounding off a float
per = 90.34
print(round(per))

# getting absolute value
per = -32
print(abs(per))`;
  const pc27code4 = `import math

# using the floor function
x = 3.1
print(math.floor(x)) 

# using the ceil function
x = 3.1
print(math.ceil(x)) `;
const pc27code5 = `import math

x = 90
# Convert the angle to radians
angle = math.radians(x)
sin_res = math.sin(angle)
cos_res = math.cos(angle)
tan_res = math.tan(angle)

# Printing answers
print(f"{x} degrees in radians = {angle}")
print(f"sin({angle}) = {sin_res}")
print(f"cos({angle}) = {cos_res}")
print(f"tan({angle}) = {tan_res}")

# Logarithmic function
y = 2
log_res = math.log(y)
print(f"Log({y}) is {log_res}")

# Exponential function
z = 2
exp_res = math.exp(z)
print(f"Exp({z}) is {exp_res}")`
  return (
    <div class="testing">
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/python/operators">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/python/lists">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Python Maths:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                Python provides a wide range of mathematical functions and
                libraries that enable you to perform various mathematical
                operations. We have already studied about arithmetic operators
                in Python which perform basic arithmetic operations like
                addition, subtraction, multiplication, division and other basic
                operations. We will discuss about built-in math functions in
                Python in this tutorial.
              </span>
              <br />
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Mathematical Functions:
              </h2>
              <div className="ui clearing divider"></div>
              <span>
                Python's standard library (math module) provides a wide range of
                mathematical functions, including trigonometric, logarithmic,
                exponential, and more. You need to import the math module to use
                these functions:
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>maths.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc27code1}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />

              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Round and Absolute Functions:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                In Python, we have built-in functions in math library for rounding and finding 
                absolute value of a number. In the following code, we have illustrated that how 
                we can find absolute value and round a number.
              </span>
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>maths.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc27code3}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Trigonometric, Logarithmic and Exponential Functions:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                We have provided with functions in math library which can perform 
                trigonometric, logarithmic and exponential functions on numbers.
              </span>
              <div class="ui clearing divider"></div>
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>maths.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc27code5}
                  </SyntaxHighlighter>
                </div>
              </div>


              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                External Libraries:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                For more advanced mathematical tasks, you can use external
                libraries like NumPy and SciPy. These libraries provide us a
                wide range of functions for numerical computing, linear algebra,
                optimization, statistics, and more.
                <br />
                <br />
                To use NumPy, SciPy etc, you need to install them first (usually
                using pip). Here is a simple example of using NumPy library for basic
                mathematical operations:
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>maths.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc27code2}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <span>
                These are just some basic concepts related to mathematical
                operators in Python. Depending on your needs, you can explore
                more advanced mathematical concepts and libraries in Python to perform
                more complex computations.
              </span>
              <br />
              <br />
            </div>
            <Link to="/python/Chapter2">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/python/lists">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </div>
  );
};
export default PythonC27;
