import React, { useState, useRef, useEffect } from "react";
import Sidebar from "./SideBar";
import "../../Components/SideBar/App.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const CodeAccordion = ({ questions, code, toggleAccordion, changeCodeLanguage }) => {
    const divRef = useRef(null);
    useEffect(() => {
      window.scrollTo(0, 0); // Scroll to the top when the component mounts
    }, []);
    const copyContent = () => {
        const divContent = divRef.current.innerText;
        navigator.clipboard
          .writeText(divContent)
          .then(() => {
            toast.success("Content copied!", { position: "top-center" });
          })
          .catch((error) => {
            toast.error("Error copying content:", error, {
              position: "top-center",
            });
          });
      };
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };

  return (
    <div>
      {questions.map((item, index) => (
        <div key={index} className="border p-2 rounded-md">
          <div className="flex justify-between">
            <span className="text-lg font-semibold">
              {item.question}
            </span>
            <button
              className="bg-gray-200 px-2 py-1 rounded-md"
              onClick={() => toggleAccordion(index)}
            >
              {item.isOpen ? "-" : "+"}
            </button>
          </div>
          <div className={`mt-2 ${item.isOpen ? "block" : "hidden"}`}>
            <div className="mt-2 flex space-x-4">
              {Object.keys(code).map((language) => (
                <button
                  key={language}
                  onClick={() => changeCodeLanguage(index, language)}
                  className={`${
                    item.codeLanguage === language
                      ? "bg-blue-500 hover:bg-blue-600 text-white"
                      : "bg-gray-200 hover:bg-gray-300 text-gray-700"
                  } px-3 py-2 rounded-md transition duration-300 ease-in-out transform hover:scale-105`}
                >
                  {language}
                </button>
              ))}
            </div>
            <p className="text-gray-800">
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>output.cpp</b>
                  <i
                    className="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language={`${item.codeLanguage}`} style={darcula}>
                    {code[item.codeLanguage]}
                  </SyntaxHighlighter>
                </div>
              </div>
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

const Question4 = () => {
  const code = {
    c: `// C code\n#include <stdio.h>\n\nint main() {\n\tfloat temperatureCelsius = 25.0;\n\tfloat temperatureFahrenheit;\n\n\ttemperatureFahrenheit = (temperatureCelsius * 9/5) + 32;\n\n\tprintf("Temperature in Fahrenheit: %f\\n", temperatureFahrenheit);\n\n\treturn 0;\n}`,
    cpp: `// C++ code\n#include <iostream>\nusing namespace std;\n\tint main() {\n\tfloat temperatureCelsius = 25.0;\n\tfloat temperatureFahrenheit;\n\n\ttemperatureFahrenheit = (temperatureCelsius * 9/5) + 32;\n\n\tcout << "Temperature in Fahrenheit: " << temperatureFahrenheit << endl;\n\n\treturn 0;\n}`,
    java: `// Java code\npublic class Main {\n\tpublic static void main(String[] args) {\n\t\tfloat temperatureCelsius = 25.0;\n\t\tfloat temperatureFahrenheit;\n\n\t\ttemperatureFahrenheit = (temperatureCelsius * 9/5) + 32;\n\n\t\tSystem.out.println("Temperature in Fahrenheit: " + temperatureFahrenheit);\n\t}\n}`,
    python: `# Python code\ntemperatureCelsius = 25.0\ntemperatureFahrenheit = (temperatureCelsius * 9/5) + 32\n\nprint("Temperature in Fahrenheit:", temperatureFahrenheit)`,
  };

  const code1 = {
    c: `// C code\n#include <stdio.h>\n\nint main() {\n\tdouble radius, area;\n\tconst double pi = 3.14159;\n\n\tprintf("Enter the radius of the circle: ");\n\tscanf("%lf", &radius);\n\n\tarea = pi * radius * radius;\n\n\tprintf("The area of the circle is: %lf\\n", area);\n\n\treturn 0;\n}`,
    cpp: `// C++ code\n#include <iostream>\nusing namespace std;\n\nint main() {\n\tdouble radius, area;\n\tconst double pi = 3.14159;\n\n\tcout << "Enter the radius of the circle: ";\n\tcin >> radius;\n\n\tarea = pi * radius * radius;\n\n\tcout << "The area of the circle is: " << area << endl;\n\n\treturn 0;\n}`,
    java: `// Java code\nimport java.util.Scanner;\n\npublic class Main {\n\tpubli\n\tstatic void main(String[] args) {\n\t\tdouble radius, area;\n\t\tfinal double pi = 3.14159;\n\n\t\tScanner input = new Scanner(System.in);\n\n\t\tSystem.out.print("Enter the radius of the circle: ");\n\t\tradius = input.nextDouble();\n\n\t\tarea = pi * radius * radius;\n\n\t\tSystem.out.println("The area of the circle is: " + area);\n\t}\n}`,
    python: `# Python code\nimport math\n\nradius = float(input("Enter the radius of the circle: "))\narea = math.pi * radius ** 2\n\nprint("The area of the circle is:", area)`,
  };

  const code2 = {
    c: `// C code\n#include <stdio.h>\n\nint main() {\n\tchar operator;\n\tdouble num1, num2;\n\n\tprintf("Enter an operator (+, -, *, /): ");\n\n\tscanf(" %c", &operator);\n\n\tprintf("Enter two numbers: ");\n\tscanf("%lf %lf", &num1, &num2);\n\n\tdouble result;\n\n\tswitch (operator) {\n\t\tcase '+':\n\t\t\tresult = num1 + num2;\n\t\t\tbreak;\n\t\tcase '-':\n\t\t\tresult = num1 - num2;\n\t\t\tbreak;\n\t\tcase '*':\n\t\t\tresult = num1 * num2;\n\t\t\tbreak;\n\t\tcase '/':\n\t\t\tif (num2 != 0) {\n\t\t\t\tresult = num1 / num2;\n\t\t\t} else {\n\t\t\t\tprintf("Division by zero is not allowed.\\n");\n\t\t\t\treturn 1;  // Exit with an error code\n\t\t\t}\n\t\t\tbreak;\n\t\tdefault:\n\t\t\tprintf("Invalid operator\\n");\n\t\t\treturn 1;  // Exit with an error code\n\t}\n\n\tprintf("Result: %lf\\n", result);\n\n\treturn 0;\n}`,
    cpp: `// C++ code\n#include <iostream>\nusing namespace std;\n\nint main() {\n\tdouble radius, area;\n\tconst double pi = 3.14159;\n\n\tcout << "Enter the radius of the circle: ";\n\tcin >> radius;\n\n\tarea = pi * radius * radius;\n\n\tcout << "The area of the circle is: " << area << endl;\n\n\treturn 0;\n}`,
    java: `// Java code\nimport java.util.Scanner;\n\npublic class Main {\n\tpubli\n\tstatic void main(String[] args) {\n\t\tdouble radius, area;\n\t\tfinal double pi = 3.14159;\n\n\t\tScanner input = new Scanner(System.in);\n\n\t\tSystem.out.print("Enter the radius of the circle: ");\n\t\tradius = input.nextDouble();\n\n\t\tarea = pi * radius * radius;\n\n\t\tSystem.out.println("The area of the circle is: " + area);\n\t}\n}`,
    python: `# Python code\nimport math\n\nradius = float(input("Enter the radius of the circle: "))\narea = math.pi * radius ** 2\n\nprint("The area of the circle is:", area)`,
  };
  const code3 = {
    c: `// C code\n#include <stdio.h>\n\nint main() {\n\tint year;\n\n\tprintf("Enter a year: ");\n\tscanf("%d", &year);\n\n\tif ((year % 4 == 0 && year % 100 != 0) || (year % 400 == 0)) {\n\t\tprintf("%d is a leap year.\\n", year);\n\t} else {\n\t\tprintf("%d is not a leap year.\\n", year);\\t}\n\n\treturn 0;\n}`,
    cpp: `// C++ code\n#include <iostream>\nusing namespace std;\n\nint main() {\n\tint year;\n\n\tcout << "Enter a year: ";\n\tcin >> year;\n\n\tif ((year % 4 == 0 && year % 100 != 0) || (year % 400 == 0)) {\n\t\tcout << year << " is a leap year." << endl;\n\t} else {\n\t\tcout << year << " is not a leap year." << endl;\n\t}\n\n\treturn 0;\n}`,
    java: `// Java code\nimport java.util.Scanner;\n\npublic class Main {\n\tpublic static\n\tvoid main(String[] args) {\n\t\tint year;\n\n\t\tScanner input = new Scanner(System.in);\n\n\t\tSystem.out.print("Enter a year: ");\n\t\tyear = input.nextInt();\n\n\t\tif ((year % 4 == 0 && year % 100 != 0) || (year % 400 == 0)) {\n\t\t\tSystem.out.println(year + " is a leap year.");\n\t\t} else {\n\t\t\tSystem.out.println(year + " is not a leap year.");\n\t\t}\n\t}\n}`,
    python: `# Python code\nyear = int(input("Enter a year: "))\n\nif (year % 4 == 0 and year % 100 != 0) or (year % 400 == 0):\n\tprint(year, "is a leap year.")\nelse:\n\tprint(year, "is not a leap year.")`,
  };
  const code4 = {
    c: `// C code\n#include <stdio.h>\n\nint main() {\n\tint num;\n\tlong square = 0;\n\n\tprintf("Enter a number: ");\n\tscanf("%d", &num);\n\n\t// Calculate the square using addition and subtraction\n\tfor (int i = 0; i < num; i++) {\n\t\tsquare += num;\n\t}\n\tsquare = square - num;\n\n\tprintf("The square of %d is: %ld\n", num, square);\n\n\treturn 0;\n}`,
    cpp: `// C++ code\n#include <iostream>\nusing namespace std;\n\nint main() {\n\tint num;\n\tlong square = 0;\n\n\tcout << "Enter a number: ";\n\tcin >> num;\n\n\t// Calculate the square using addition and subtraction\n\tfor (int i = 0; i < num; i++) {\n\t\tsquare += num;\n\t}\n\tsquare = square - num;\n\n\tcout << "The square of " << num << " is: " << square << endl;\n\n\treturn 0;\n}`,
    java: `// Java code\nimport java.util.Scanner;\n\npublic class Main {\n\tpubli\n\tstatic void main(String[] args) {\n\t\tint num;\n\t\tlong square = 0;\n\n\t\tScanner input = new Scanner(System.in);\n\n\t\tSystem.out.print("Enter a number: ");\n\t\tnum = input.nextInt();\n\n\t\t// Calculate the square using addition and subtraction\n\t\tfor (int i = 0; i < num; i++) {\n\t\t\tsquare += num;\n\t\t}\n\t\tsquare = square - num;\n\n\t\tSystem.out.println("The square of " + num + " is: " + square);\n\t}\n}`,
    python: `# Python code\nnum = int(input("Enter a number: "))\nsquare = 0\n\n# Calculate the square using addition and subtraction\nfor i in range(num):\n\tsquare += num\nsquare = square - num\n\nprint("The square of", num, "is:", square)`,
  };
  const code5 = {
    c: `// C code\n#include <stdio.h>\n\nint main() {\n\tint num1, num2;\n\n\tprintf("Enter two integers: ");\n\tscanf("%d %d", &num1, &num2);\n\n\tint bitwise_and = num1 & num2;\n\tint bitwise_or = num1 | num2;\n\tint bitwise_xor = num1 ^ num2;\n\n\tprintf("Bitwise AND: %d\\n", bitwise_and);\n\tprintf("Bitwise OR: %d\\n", bitwise_or);\n\tprintf("Bitwise XOR: %d\\n", bitwise_xor);\n\n\treturn 0;\n}`,
    cpp: `// C++ code\n#include <iostream>\nusing namespace std;\n\nint main() {\n\tint num1, num2;\n\n\tcout << "Enter two integers: ";\n\tcin >> num1 >> num2;\n\n\tint bitwise_and = num1 & num2;\n\tint bitwise_or = num1 | num2;\n\tint bitwise_xor = num1 ^ num2;\n\n\tcout << "Bitwise AND: " << bitwise_and << endl;\n\tcout << "Bitwise OR: " << bitwise_or << endl;\n\tcout << "Bitwise XOR: " << bitwise_xor << endl;\n\n\treturn 0;\n}`,
    java: `// Java code\nimport java.util.Scanner;\n\npublic class Main {\n\tpubli\n\tstatic void main(String[] args) {\n\t\tScanner input = new Scanner(System.in);\n\n\t\tSystem.out.print("Enter two integers: ");\n\t\tint num1 = input.nextInt();\n\t\tint num2 = input.nextInt();\n\n\t\tint bitwise_and = num1 & num2;\n\t\tint bitwise_or = num1 | num2;\n\n\tint bitwise_xor = num1 ^ num2;\n\n\t\tSystem.out.println("Bitwise AND: " + bitwise_and);\n\t\tSystem.out.println("Bitwise OR: " + bitwise_or);\n\n\tSystem.out.println("Bitwise XOR: " + bitwise_xor);\n\t}\n}`,
    python: `# Python code\nnum1 = int(input("Enter the first integer: "))\nnum2 = int(input("Enter the second integer: "))\n\nbitwise_and = num1 & num2\nbitwise_or = num1 | num2\nbitwise_xor = num1 ^ num2\n\nprint("Bitwise AND:", bitwise_and)\nprint("Bitwise OR:", bitwise_or)\nprint("Bitwise XOR:", bitwise_xor)`,
  };
  const code6 = {
    c: `// C code\n#include <stdio.h>\n\nint main() {\n\tint num;\n\tprintf("Enter a number: ");\n\tscanf("%d", &num);\n\n\tif (num <= 1) {\n\t\tprintf("%d is not a prime number.\\n", num);\n\t\treturn 0;\n\t}\n\n\tint isPrime = 1;\n\tfor (int i = 2; i * i <= num; i++) {\n\t\tif (num % i == 0) {\n\t\t\tisPrime = 0;\n\t\t\tbreak;\n\t\t}\n\t}\n\n\tif (isPrime) {\n\t\tprintf("%d is a prime number.\\n", num);\n\t} else {\n\t\tprintf("%d is not a prime number.\\n", num);\n\t}\n\n\treturn 0;\n}`,
    cpp: `// C++ code\n#include <iostream>\nusing namespace std;\n\nint main() {\n\tint num;\n\tcout << "Enter a number: ";\n\tcin >> num;\n\n\tif (num <= 1) {\n\t\tcout << num << " is not a prime number." << endl;\n\t\treturn 0;\n\t}\n\n\tbool isPrime = true;\n\tfor (int i = 2; i * i <= num; i++) {\n\t\tif (num % i == 0) {\n\t\t\tisPrime = false;\n\t\t\tbreak;\n\t\t}\n\t}\n\n\tif (isPrime) {\n\t\tcout << num << " is a prime number." << endl;\n\t} else {\n\t\tcout << num << " is not a prime number." << endl;\n\t}\n\n\treturn 0;\n}`,
    java: `// Java code\nimport java.util.Scanner;\n\npublic class PrimeCheck {\n\tpublic static void main(String[] args) {\n\t\tScanner scanner = new Scanner(System.in);\n\t\tSystem.out.print("Enter a number: ");\n\t\tint num = scanner.nextInt();\n\t\tscanner.close();\n\n\t\tif (num <= 1) {\n\t\t\tSystem.out.println(num + " is not a prime number.");\n\t\t\treturn;\n\t\t}\n\n\t\tboolean isPrime = true;\n\t\tfor (int i = 2; i * i <= num; i++) {\n\t\t\tif (num % i == 0) {\n\t\t\t\tisPrime = false;\n\t\t\t\tbreak;\n\t\t\t}\n\t\t}\n\n\t\tif (isPrime) {\n\t\t\tSystem.out.println(num + " is a prime number.");\n\t\t} else {\n\t\t\tSystem.out.println(num + " is not a prime number.");\n\t\t}\n\t}\n}`,
    python: `# Python code\nnum = int(input("Enter a number: "))\n\nif num <= 1:\n\tprint(f"{num} is not a prime number.")\nelse:\n\tis_prime = True\n\tfor i in range(2, int(num**0.5) + 1):\n\t\tif num % i == 0:\n\t\t\tis_prime = False\n\t\t\tbreak\n\n\tif is_prime:\n\t\tprint(f"{num} is a prime number.")\n\telse:\n\t\tprint(f"{num} is not a prime number.")`,
  };
  const code7 = {
    c: `// C code\n#include <stdio.h>\n\nint main() {\n\tdouble principal, rate, time, simple_interest;\n\n\tprintf("Enter principal amount: ");\n\tscanf("%lf", &principal);\n\tprintf("Enter annual interest rate (in percentage): ");\n\tscanf("%lf", &rate);\n\tprintf("Enter time period (in years): ");\n\tscanf("%lf", &time);\n\n\trate /= 100; // Convert rate from percentage to decimal\n\n\tsimple_interest = (principal * rate * time);\n\n\tprintf("Simple Interest: %lf\\n", simple_interest);\n\n\treturn 0;\n}`,
    cpp: `// C++ code\n#include <iostream>\nusing namespace std;\n\nint main() {\n\tdouble principal, rate, time, simple_interest;\n\n\tcout << "Enter principal amount: ";\n\tcin >> principal;\n\tcout << "Enter annual interest rate (in percentage): ";\n\tcin >> rate;\n\tcout << "Enter time period (in years): ";\n\tcin >> time;\n\n\trate /= 100; // Convert rate from percentage to decimal\n\n\tsimple_interest = principal * rate * time;\n\n\tcout << "Simple Interest: " << simple_interest << endl;\n\n\treturn 0;\n}`,
    java: `// Java code\nimport java.util.Scanner;\n\npublic class SimpleInterest {\n\tpublic static void main(String[] args) {\n\t\tScanner scanner = new Scanner(System.in);\n\t\tdouble principal, rate, time, simpleInterest;\n\n\t\tSystem.out.print("Enter principal amount: ");\n\t\tprincipal = scanner.nextDouble();\n\t\tSystem.out.print("Enter annual interest rate (in percentage): ");\n\t\trate = scanner.nextDouble();\n\t\tSystem.out.print("Enter time period (in years): ");\n\t\ttime = scanner.nextDouble();\n\n\t\trate /= 100; // Convert rate from percentage to decimal\n\n\t\tsimpleInterest = principal * rate * time;\n\n\t\tSystem.out.println("Simple Interest: " + simpleInterest);\n\n\t\tscanner.close();\n\t}\n}`,
    python: `# Python code\nprincipal = float(input("Enter principal amount: "))\nrate = float(input("Enter annual interest rate (in percentage): "))\ntime = float(input("Enter time period (in years): "))\n\nrate /= 100  # Convert rate from percentage to decimal\n\nsimple_interest = principal * rate * time\n\nprint("Simple Interest:", simple_interest)`,
  };
  const code8 = {
    c: `// C code\n#include <stdio.h>\n\nint main() {\n\tint num;\n\tprintf("Enter an integer: ");\n\tscanf("%d", &num);\n\n\tint left_shift_result = num << 1; // Left shift by 1 bit\n\tint right_shift_result = num >> 1; // Right shift by 1 bit\n\n\tprintf("Left Shift Result: %d\\n", left_shift_result);\n\tprintf("Right Shift Result: %d\\n", right_shift_result);\n\n\treturn 0;\n}`,
    cpp: `// C++ code\n#include <iostream>\nusing namespace std;\n\nint main() {\n\tint num;\n\tcout << "Enter an integer: ";\n\tcin >> num;\n\n\tint left_shift_result = num << 1; // Left shift by 1 bit\n\tint right_shift_result = num >> 1; // Right shift by 1 bit\n\n\tcout << "Left Shift Result: " << left_shift_result << endl;\n\tcout << "Right Shift Result: " << right_shift_result << endl;\n\n\treturn 0;\n}`,
    java: `// Java code\nimport java.util.Scanner;\n\npublic class BitwiseShift {\n\tpublic static void main(String[] args) {\n\t\tScanner scanner = new Scanner(System.in);\n\t\tSystem.out.print("Enter an integer: ");\n\t\tint num = scanner.nextInt();\n\n\t\tint leftShiftResult = num << 1; // Left shift by 1 bit\n\t\tint rightShiftResult = num >> 1; // Right shift by 1 bit\n\n\t\tSystem.out.println("Left Shift Result: " + leftShiftResult);\n\t\tSystem.out.println("Right Shift Result: " + rightShiftResult);\n\n\t\tscanner.close();\n\t}\n}`,
    python: `# Python code\nnum = int(input("Enter an integer: "))\n\nleft_shift_result = num << 1  # Left shift by 1 bit\nright_shift_result = num >> 1  # Right shift by 1 bit\n\nprint("Left Shift Result:", left_shift_result)\nprint("Right Shift Result:", right_shift_result)`,
  };



  const [questions, setQuestions] = useState([
    {
      question: "Q1: Declare a float variable named temperature and initialize it with a temperature in Celsius. Convert and print the temperature in Fahrenheit.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions1, setQuestions1] = useState([
    {
      question: "Q2: Write a program that calculates the area of a circle given its radius using the appropriate arithmetic operators.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions2, setQuestions2] = useState([
    {
      question: "Q3: Implement a simple calculator program that can perform basic arithmetic operations (addition, subtraction, multiplication, division) on two numbers based on the user's input.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions3, setQuestions3] = useState([
    {
      question: "Q4: Write a program that checks if a given year is a leap year using logical operators.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions4, setQuestions4] = useState([
    {
      question: "Q5: Develop a program that calculates the square of a number using only addition and subtraction operators.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions5, setQuestions5] = useState([
    {
      question: "Q6: Write a program to calculate the bitwise AND, OR, and XOR of two integers.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions6, setQuestions6] = useState([
    {
      question: "Q7: Implement a program that checks if a given number is a prime number using the modulo operator.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions7, setQuestions7] = useState([
    {
      question: "Q8: Implement a program that calculates the simple interest on a loan using arithmetic operators.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions8, setQuestions8] = useState([
    {
      question: "Q9: Implement a program to perform bitwise left shift and bitwise right shift operations on an integer.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);


  const toggleAccordion = (index, questions, setQuestions) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].isOpen = !updatedQuestions[index].isOpen;
    setQuestions(updatedQuestions);
  };

  const changeCodeLanguage = (index, language, questions, setQuestions) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].codeLanguage = language;
    setQuestions(updatedQuestions);
  };

  

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };
  const divRef = useRef(null);
  return (
    <div className="testing">
      <Sidebar>
        <div className="containera">
          <div className="ch-content">
            <h2 className="ui header">
            <Link to="/ques3">
              <button
                className="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
              </Link>
              <Link to="/ques5">
                <button
                  className="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>
            <div className="main-body ui ">
              <h2 className="ui left floated header" style={{ color: "#001C30" }}>
                Operators:
              </h2>
              <div className="ui clearing divider"></div>
              <CodeAccordion
                questions={questions}
                code={code}
                toggleAccordion={(index) => toggleAccordion(index, questions, setQuestions)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions, setQuestions)
                }
              /><br/>
              <CodeAccordion
                questions={questions1}
                code={code1}
                toggleAccordion={(index) => toggleAccordion(index, questions1, setQuestions1)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions1, setQuestions1)
                }
              /><br/>
              <CodeAccordion
                questions={questions2}
                code={code2}
                toggleAccordion={(index) => toggleAccordion(index, questions2, setQuestions2)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions2, setQuestions1)
                }
              />
              <br/>
              <CodeAccordion
                questions={questions3}
                code={code3}
                toggleAccordion={(index) => toggleAccordion(index, questions3, setQuestions3)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions3, setQuestions1)
                }
              />
              <br/>
              <CodeAccordion
                questions={questions4}
                code={code4}
                toggleAccordion={(index) => toggleAccordion(index, questions4, setQuestions4)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions4, setQuestions4)
                }
              />
              <br/>
              <CodeAccordion
                questions={questions5}
                code={code5}
                toggleAccordion={(index) => toggleAccordion(index, questions5, setQuestions5)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions5, setQuestions5)
                }
              />
              <br/>
              <CodeAccordion
                questions={questions6}
                code={code6}
                toggleAccordion={(index) => toggleAccordion(index, questions6, setQuestions6)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions6, setQuestions6)
                }
              />
              <br/>
              <CodeAccordion
                questions={questions7}
                code={code7}
                toggleAccordion={(index) => toggleAccordion(index, questions7, setQuestions7)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions7, setQuestions7)
                }
              />
              <br/>
              <CodeAccordion
                questions={questions8}
                code={code8}
                toggleAccordion={(index) => toggleAccordion(index, questions8, setQuestions8)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions8, setQuestions8)
                }
              />
              
            </div><br />
            <Link to="/ques3">
              <button
                className="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
              </Link>
              <Link to="/ques5">
                <button
                  className="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default Question4;
