import { useRef, useEffect } from "react";
import "./Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Q11 from "../Questions/Q11";
import Sidebar from "../SideBar/Sidebar";
import Footer from "../Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const Ch11 = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  const divRef = useRef(null);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };
  const codeif = `if(condition){...}`;
  const code1 = `if ( condition ){
    //code
}
else{
    //code
}`;
  const code2 = `#include<iostream>
using namespace std;
int main(){
    int a=10;
    int b=20;
    if(a>b){
        cout<<"a is greater than b.\\n";
    }
    else{
        cout<<"b is greater than a.\\n";
    }
    return 0;
}`;
  const code3 = `#include<iostream>
using namespace std;
int main(){
    int a=10;
    int b=20;
    if(a>b){
        a++;
        cout<<a<<endl;
    }
    else if(b>a){
        b++;
        cout<<b<<endl;
    }
    else{
        //in this condition a will be equal to b
        cout<<"a is equal to b";
    }
    return 0;
}`;
  const code4 = `#include<iostream>
using namespace std;
int main(){
    int a=10, b=20, c=30;
    if( a<b && a<c ){
        cout<<"a is less than b and c";
    }
    else{
        cout<<"a is not the smallest";
    }
    if( a==10 || b==10 ){
        cout<<"a = 10 or b =  10";
    }
    return 0;
}`;
  const code5 = `#include<iostream>
using namespace std;
int main(){
    int a = 100, b = 20, c = 30;
    if( a>b ){
	    if(a>c ){
		    cout<<"a is the largest";
	    }
    }
    else{
        cout<<"a is not the largest";
    }
    return 0;
}`;
  const code6 = `if(condition){...}
if(condition){...}
Else{...}`;
  const code7 = `type var_name = Condition ? if true : if false`;
  const code8 = `#include<iostream>
using namespace std;
int main(){
    string res = a > b ? "a > b" : "a < b";
    cout<<res<<endl;
    return 0;
}`;
  const code9 = `int main() {
  label:
    //code
  goto label;
  //code
}`;
  const code10 = `#include <iostream>
using namespace std;


int main() {
    int age;
    age:
    cout << "Enter your age";
    cin >> age;
    if(age >= 18){
    	cout<<"You are an adult\\n";
	}
	else{
		cout<<"You are not an adult\\n";
		goto age;
	}
}`;
  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/Cpp/strings">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/Cpp/switch">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Conditional Statements:
              </h2>
              <div class="ui clearing divider"></div>
              <p>
                Conditional Statements checks the condition or compares two
                variables using comparison operators and performs action.
                <br />
                We use <b>if statement</b>, <b>if-else statement</b>, <b>else-if statement</b> as conditional
                statements.
              </p>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                If statement:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                If statement checks the condition and execute the specific code.
              </span>{" "}
              <br />
              <SyntaxHighlighter language="cpp" style={darcula}>
                {codeif}
              </SyntaxHighlighter>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                If-else:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                If-else is used as conditional statement. In if else statements,
                if one condition is true, it will be executed and all others
                will be ignored.
              </span>
              <br />
              <SyntaxHighlighter language="cpp" style={darcula}>
                {code1}
              </SyntaxHighlighter>
              <br />
              <p style={{ backgroundColor: "#FBEEAC", padding: "10px" }}>
                <b>Note: </b>
                <br />
                <ul class="ui unordered list">
                  <li>If requires condition.</li>
                  <li>Else does not require condition.</li>
                </ul>
              </p>
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>if-else.cpp</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="cpp" style={darcula}>
                    {code2}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Else if:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                If you want to check conditions more than one time then use else
                if.
              </span>{" "}
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>if-else.cpp</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="cpp" style={darcula}>
                    {code3}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                If else with logical operators:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                If you want to check two condition in single if statement, you
                will use logical operators.
              </span>{" "}
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>if-else.cpp</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="cpp" style={darcula}>
                    {code4}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Nested if else:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                In nested if else, we use if statement within if statement.
              </span>{" "}
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>if-else.cpp</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="cpp" style={darcula}>
                    {code5}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Dangling else Grammar:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                If you are using multiple if statements and single else
                statement, then there is dangling else problem.
              </span>
              <br /> <br />
              <SyntaxHighlighter language="cpp" style={darcula}>
                {code6}
              </SyntaxHighlighter>
              Compiler does not know that with which if, else will go. <br />
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Ternary Operator:
              </h2>
              <div class="ui clearing divider"></div>
              It is also used to check condition like if else. 
              <br />
              <SyntaxHighlighter language="cpp" style={darcula}>
                {code7}
              </SyntaxHighlighter>
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>if-else.cpp</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="cpp" style={darcula}>
                    {code8}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <p style={{ backgroundColor: "#FBEEAC", padding: "10px" }}>
                <b>Note : </b>You can use ternary operator when logic is simple.
                It makes your code more compact and easy to use. We can also use 
                nested ternary operator. Ternary operator is also called conditional operator. <br />
              </p>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Goto Statement:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                As name indicates goto statemet is used to go to a particular
                line of code. We labelled a line of code and at some point, we
                write "goto label" and our control jumps to that label.
              </span>
              <br />
              <br />
              <div ref={divRef}>
                <SyntaxHighlighter language="cpp" style={darcula}>
                  {code9}
                </SyntaxHighlighter>
              </div>
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>goto.cpp</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="cpp" style={darcula}>
                    {code10}
                  </SyntaxHighlighter>
                </div>
              </div>
            </div>
            <br />
            <Link to="/Cpp/strings">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/Cpp/switch">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default Ch11;
