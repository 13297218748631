import { useState, useEffect } from "react";
import Sidebar from "./SideBar";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
// import "./Quiz.css";
import "../../Components/Chapters/Chapters.css";
import Modal from "react-modal";

const PQuiz17 = () => {
  const [answers, setAnswers] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const handleQuizSubmit = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const questions = [
    {
      id: 1,
      question: "  What is the primary purpose of a for loop in Python:",
      options: ["To declare variables", "To create nested functions", "To iterate over a sequence of items", "To define conditional statements"],
      correctAnswer: "To iterate over a sequence of items",
    },
    {
      id: 2,
      question: "  Which of the following is an iterable object in Python:",
      options: ["Integer", "String", "Function", "Module"],
      correctAnswer: "String",
    },
    {
      id: 3,
      question: "  In a for loop, what does the iteration variable represent:",
      options: [
        "The loop's block of code",
        "The number of iterations",
        "The current item in the sequence",
        "The condition to continue looping",
      ],
      correctAnswer: "The current item in the sequence",
    },
    {
        id: 4,
        question: "  What is the purpose of the \"range()\" function in a for loop:",
        options: [
          "To specify the start of the loop",
          "To define conditional statements",
          "To create an iterable sequence of numbers",
          "To stop the loop from running",
        ],
        correctAnswer: "To create an iterable sequence of numbers",
      },{
        id: 5,
        question: "  In a for loop, what is the role of the else statement:",
        options: [
          "To indicate the end of the loop",
          "To execute a block of code after the loop",
          "To check the condition for the loop",
          "To create nested loops",
        ],
        correctAnswer: "To execute a block of code after the loop",
      },{
        id: 6,
        question: "  How can you specify both the start and end values in the \"range()\" function of a for loop:",
        options: [
          "range(5)",
          "range(3, 9)",
          "range(0, 3, 9)",
          "range(start=3, end=9)",
        ],
        correctAnswer: "range(3, 9)",
      },{
        id: 7,
        question: "  Which statement is true regarding the else block in a for loop:",
        options: [
          "It is always executed after each iteration",
          "It is only executed if the loop is nested",
          "It is executed when the condition becomes false",
          "It is used to define variables within the loop",
        ],
        correctAnswer: "It is executed when the condition becomes false",
      }
  ];
  const handleAnswerChange = (questionId, selectedAnswer) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: selectedAnswer,
    }));
  };

  const calculateScore = () => {
    let correctCount = 0;
    let incorrectCount = 0;

    questions.forEach((question) => {
      const userAnswer = answers[question.id];
      if (userAnswer === question.correctAnswer) {
        correctCount++;
      } else {
        incorrectCount++;
      }
    });

    return { correctCount, incorrectCount };
  };

  const { correctCount, incorrectCount } = calculateScore();
  return (
    <>
      <Sidebar>
        <div class="container">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/quizes/python_quiz16">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                  }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/quizes/python_quiz18">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              </h2>
              <h1 class="start content ui left floated header">
                For Loop
              </h1>
              <div className="ui clearing divider"></div>
            

            <div class="main-body">
              <h2 class="ui left floated header">
                Quiz # 17
              </h2>
              <span class="tut-btn"><Link to="/python/for_loop">
                  <button class="ui secondary button">Read Tutorial</button>
                </Link></span>
              <div class="ui clearing divider"></div>
              <div>
                {questions.map((question, i) => (
                  <div key={question.id}>
                    <div class="con">
                    <div class="que">Q{i+1}:<span class="one-per-left-margin">{question.question}</span></div>
                      {question.options.map((option) => (
                        <label class="label" key={option}>
                          <input
                            type="radio"
                            name={`question_${question.id}`}
                            value={option}
                            checked={answers[question.id] === option}
                            onChange={(e) =>
                              handleAnswerChange(question.id, e.target.value)
                            }
                          />
                          <span className="one-per-left-margin">{option}</span>
                          <br />
                        </label>
                      ))}
                    </div>
                    <hr />
                  </div>
                ))}<br/>
                <center><button class="ui primary button" onClick={handleQuizSubmit}>Submit Quiz</button></center>

                {/* Modal for displaying results */}
                <Modal class="modal" isOpen={isModalOpen} onRequestClose={closeModal}>
                  <b style={{fontSize:'20px'}}>Quiz Results</b>
                  <b><p>Your score is {correctCount}/{questions.length} </p></b>
                  {questions.map((question,i) => (
                    <div key={question.id}>
                      <p>Q{i+1}: {question.question}</p>
                      <p><b>Correct Answer:</b> {question.correctAnswer}</p>
                      <p className={`${question.correctAnswer}` === `${answers[question.id]}` ? "correct" : "wrong"}>
                        <b>Your Answer:</b> {answers[question.id] || "Not answered"}
                      </p>
                      <hr />
                    </div>
                  ))}
                  <button class="ui secondary button" onClick={closeModal}>Close</button><br /><br />
                </Modal>
              </div>

              <br />
            </div>
            <Link to="/quizes/python_quiz16">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                  }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/quizes/python_quiz18">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
            <br />
          </div>
        </div>
      </Sidebar>

      <div class="sideBarBody">
        <Footer />
      </div>
    </>
  );
};
export default PQuiz17;
