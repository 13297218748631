import { useRef, useEffect } from "react";
import "../../Components/Chapters/Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "../PythonSideBar";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

const PythonC16a = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };

  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor:'#D8D9DA'
  };
  const pc16acode1 = `# It will return true
print(10 > 5)

# It will return false
print(5 > 10)`;
  const pc16acode2 = `#strings
print(bool("I am 22 years old."))
print(bool(""))

#numbers
print(bool(123))
print(bool(0))
print(bool())

#lists
print(bool(["apple", "cherry", "banana"]))
print(bool([]))

#dictionaries ans sets
print(bool({"name" : "John", "age" : 20}))
print(bool({"Apple", "Banana", "Mango"}))
print(bool({}))

#None, True, False
print(bool(None))
print(True)
print(False)`;
  const pc16acode3 = `if 10 > 5: 
    print("10 is greater than 5")
else:
    print("10 is less than 5")`;
const pc16acode4 = `# Representing true values
print(bool(5))
print(bool([1, 2, 3]))

# Representing false values
print(bool())
print(bool([]))`
  return (
    <div class="testing">
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/python/while_loop">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/python/control_statements">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Booleans:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                Boolean is a data type in python which can have one of the two
                values: <br />
                <ul className="ui unordered list">
                  <li>True</li>
                  <li>False</li>
                </ul>
                <br />A logical expression in python returns True or False. This
                True or False can be stored in a boolean variable. 
              </span>
              <br />
              <br />
              

                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>input.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc16acode1}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  True / False:
                </h2>
                <div className="ui clearing divider"></div>
                <span>
                  <ul className="ui unordered list">
                    <li>If the variable has some content and it is not empty, then it
                  is True.</li>
                  <li>If the variable is empty, then it
                  is False.</li>
                  <li>1 is True and 0 is False.</li>
                  </ul>
                   
                </span><br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>booleans.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc16acode2}
                    </SyntaxHighlighter>
                  </div>
                </div>
                
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Why Booleans:
                </h2>
                <div className="ui clearing divider"></div>
                <span>
                In Python we use booleans for making decisions or evaluating certain conditions.
                  There are some conditions when we need to execute some piece
                  of code and skip some other part of code. In this situation,
                  we use boolean variables. If they are True, we execute a piece
                  of code. And if flase, we skip that code.
                </span>
                <br /><br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>booleans.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc16acode3}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <span>
                  We know that 10 is always greater than 5. So, the expression
                  "10 &gt; 5" is true and the code written in if condition is
                  executed. Else block will not be executed, because 10 is not
                  less than 5.
                </span>

                <br /><br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Built-in bool() function:
                </h2>
                <div className="ui clearing divider"></div>
                <span>
                  In Python, we have a built-in <b>bool</b> function for explicitly converting a 
                  value to its boolean representation. <br />
                  <ul className="ui unordered list">
                    <li>
                      "Zero", "None", and "Empty objects" are treated as false.
                    </li>
                    <li>
                      "Non-zero numbers" and "Non-empty objects" are treated as true.
                    </li>
                  </ul>
                </span><br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>booleans.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc16acode4}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                In this way, we can implement booleans in Python. Booleans provide the basis for decision making and control 
                the flow of a program. As in case of conditional statements and control flow 
                statements, booleans are used to control the flow of program and decision making. If  the 
                boolean in true, a task is done. Otherwise control moves out.
          
              
            </div><br />
            <Link to="/python/while_loop">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/python/control_statements">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </div>
  );
};
export default PythonC16a;
