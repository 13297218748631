import { useRef, useEffect } from "react";
import "./CChapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Sidebar from "../CSideBar";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const CC1 = () => {
  const divRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };

  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };
  const cc2code1 = `#include <stdio.h>

int main() {
  printf("Hello World!");
  return 0;
}`;

  return (
    <div class="testing">
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
              <Link to="/C/getting_started">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body ui ">
              <h1 class="ui left floated header" style={{ color: "#001C30" }}>
                Introduction to C:
              </h1>
              <div class="ui clearing divider"></div>
              <p style={{ backgroundColor: "#FBEEAC", padding: "15px" }}>
                <ol class="ui ordered list">
                  <li>
                    C is a general-purpose programming language known for its
                    simplicity, efficiency, and versatility. It was developed in
                    1970s by Dennis Ritchie at Bell Labs. C is famous for its
                    low level programming capabilities.
                  </li>

                  <li>
                    C is a procedural language. The programs are organized into
                    functions that perform specific tasks.
                  </li>
                  <li>C does not support Object Oriented Programming.</li>
                  <li>
                    C is called the Mother of Languages as many programming
                    languages are derived from C.
                  </li>
                  <li>
                    If you are starting coding in 2024, C can be a good choice
                    for you.
                  </li>
                </ol>
              </p>
              <br />
              <h2
                className="ui left floated header"
                style={{ color: "#001C30" }}
              >
                Applications
              </h2>
              <div class="ui clearing divider"></div>
              <p>
                C is most widely used in :<br />
                <div className="margin">
                  <ul class="ui list">
                    <li>Game Development.</li>
                    <li>Image Processing.</li>
                    <li>
                      Writing operating systems and low-level system softwares.
                    </li>
                    <li>Programming embedded systems.</li>

                    <li>Networking and communication.</li>
                    <li>DBMS components are written in C.</li>
                    <li>Many Operating Systems are written in C.</li>
                  </ul>
                </div>
              </p>
              <br />

              <h2
                className="ui left floated header"
                style={{ color: "#001C30" }}
              >
                Why to learn C?
              </h2>
              <div class="ui clearing divider"></div>
              <p>
                <div className="margin">
                  <ul class="ui list">
                    <li>
                      <b>Simple : </b>
                      <span>
                        Syntax of C is simple which makes it relatively easy to
                        read and understand.
                      </span>
                    </li>
                    <li>
                      <b>Access to hardware : </b>
                      <span>
                        C provides direct access to hardware, making it suitable
                        for systems programming and developing operating
                        systems.
                      </span>
                    </li>
                    <li>
                      <b>Portability : </b>
                      <span>
                        C programs can run on various platforms with minimal
                        modifications.
                      </span>
                    </li>

                    <li>
                      <b>Efficiency : </b>
                      <span>
                        C allows for fine-grained control over memory and
                        resources, making it highly efficient.
                      </span>
                    </li>
                    <li>
                      <b>Extensibility : </b>
                      <span>
                        You can extend C's functionality by creating your own
                        libraries or using existing ones.
                      </span>
                    </li>
                    <li>
                      <b>Modularity : </b>
                      <span>
                        Programs can be divided into modules or functions,
                        making it easier to maintain and debug code.
                      </span>
                    </li>
                  </ul>
                  <br />
                </div>
              </p>
              C is one of the oldest programming languages but if you
                  are learning programming even in 2024, you need to learn C
                  because it is essential to learn C language to understand
                  Operating Systems, Networks and Computer Architecture.
              <br />

              <h2
                className="ui left floated header"
                style={{ color: "#001C30" }}
              >
                Syntax of C:
              </h2>

              <div class="ui clearing divider"></div>
              <p>
                Syntax of C language is simple which makes it relatively easy to
                learn and read.
              </p>
              <br />
              <div style={containerStyle}>
                <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                  <b>helloworld.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {cc2code1}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <p style={{ backgroundColor: "#FBEEAC", padding: "10px" }}>
                We will teach you of C language from basic to advance in this C
                tutorial.
                <br />
                You do not require any prior programming experience.
              </p>

              <br />
            </div>
            <button
              class="ui primary button"
              style={{ flex: 1, minWidth: "100px", height: "40px" }}
            >
              Previous
            </button>
            <Link to="/C/getting_started">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
            <br />
          </div>
        </div>
      </Sidebar>

      <Footer />
    </div>
  );
};
export default CC1;
