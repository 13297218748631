import { useState, useEffect } from "react";
import Sidebar from "./SideBar";
import Footer from "../Home/Footer";
import { Link } from "react-router-dom";
import "./Quiz.css";
import "../Chapters/Chapters.css";
import Modal from "react-modal";

const Quiz32 = () => {
  const [answers, setAnswers] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const handleQuizSubmit = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const questions = [
    {
      id: 1,
      question: 'Operator overloadng is:',
      options: [
        "changing sign of operator", "giving a special meaning to operator",  "operator breaking", "overloading public functions"
      ],
      correctAnswer: "giving a special meaning to operator",
    },
    {
        id: 2,
        question: 'The built-in definition of "<<" operator is :',
        options: [
          "taking input", "giving output",  "shifting bits", "used for all"
        ],
        correctAnswer: "shifting bits",
      },
      {
        id: 3,
        question: 'Operator function should be a:',
        options: [
          "member function of class", "friend function",  "Both A or B", "private member of class"
        ],
        correctAnswer: "Both A or B",
      },
      {
        id: 4,
        question: 'For overloading unary operator, number of arguments required are:',
        options: [
          "1", "2",  "3", "0"
        ],
        correctAnswer: "0",
      },
      {
        id: 5,
        question: 'For overloading binary operator, number of arguments required are:',
        options: [
          "1", "2",  "3", "0"
        ],
        correctAnswer: "1",
      },
      {
        id: 6,
        question: 'For overloading operators, class definition is necessary:',
        options: [
          "Yes", "No"
        ],
        correctAnswer: "Yes",
      },
      {
        id: 7,
        question: 'For overloading scope resolution operators, we require:',
        options: [
          "1 argument", "2 arguments", "3 arguments", "it can not be overloaded"
        ],
        correctAnswer: "it can not be overloaded",
      },
      {
        id: 8,
        question: 'For overloading "+" resolution operators, we require:',
        options: [
          "1 argument", "2 arguments", "3 arguments", "it can not be overloaded"
        ],
        correctAnswer: "1 argument",
      },
    
    
  ];
  const handleAnswerChange = (questionId, selectedAnswer) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: selectedAnswer,
    }));
  };

  const calculateScore = () => {
    let correctCount = 0;
    let incorrectCount = 0;

    questions.forEach((question) => {
      const userAnswer = answers[question.id];
      if (userAnswer === question.correctAnswer) {
        correctCount++;
      } else {
        incorrectCount++;
      }
    });

    return { correctCount, incorrectCount };
  };

  const { correctCount, incorrectCount } = calculateScore();
  return (
    <>
      <Sidebar>
        <div class="container">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/quizes/cpp_quiz31">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                  }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/quizes/cpp_quiz33">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
            </h2>
            <h1 class="start content ui left floated header">Operator Overloading</h1>
            <div className="ui clearing divider"></div>

            <div class="main-body ">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Quiz # 32
              </h2>
              <span class="tut-btn">
                <Link to="/Cpp/operator_overloading">
                  <button class="ui secondary button">Read Tutorial</button>
                </Link>
              </span>
              <div class="ui clearing divider"></div>
              <div>
                {questions.map((question, i) => (
                  <div key={question.id}>
                    <div class="con">
                      <div class="que">
                        Q{i + 1}:
                        <span class="one-per-left-margin">
                          {question.question}
                        </span>
                      </div>
                      {question.options.map((option) => (
                        <label class="label" key={option}>
                          <input
                            type="radio"
                            name={`question_${question.id}`}
                            value={option}
                            checked={answers[question.id] === option}
                            onChange={(e) =>
                              handleAnswerChange(question.id, e.target.value)
                            }
                          />
                          <span className="one-per-left-margin">{option}</span>
                          <br />
                        </label>
                      ))}
                    </div>
                    <hr />
                  </div>
                ))}
                <br />
                <center>
                  <button class="ui primary button" onClick={handleQuizSubmit}>
                    Submit Quiz
                  </button>
                </center>

                {/* Modal for displaying results */}
                <Modal
                  class="modal"
                  isOpen={isModalOpen}
                  onRequestClose={closeModal}
                >
                  <b style={{ fontSize: "20px" }}>Quiz Results</b>
                  <b>
                    <p>
                      Your score is {correctCount}/{questions.length}{" "}
                    </p>
                  </b>
                  {questions.map((question, i) => (
                    <div key={question.id}>
                      <p>
                        Q{i + 1}: {question.question}
                      </p>
                      <p>
                        <b>Correct Answer:</b> {question.correctAnswer}
                      </p>
                      <p
                        className={
                          `${question.correctAnswer}` ===
                          `${answers[question.id]}`
                            ? "correct"
                            : "wrong"
                        }
                      >
                        <b>Your Answer:</b>{" "}
                        {answers[question.id] || "Not answered"}
                      </p>
                      <hr />
                    </div>
                  ))}
                  <button class="ui secondary button" onClick={closeModal}>
                    Close
                  </button>
                  <br />
                  <br />
                </Modal>
              </div>

              <br />
            </div>
            <Link to="/quizes/cpp_quiz31">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                }}
              >
                Previous
              </button>
            </Link>
            <Link to="/quizes/cpp_quiz33">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
            <br />
          </div>
        </div>
      </Sidebar>

      <div class="sideBarBody">
        <Footer />
      </div>
    </>
  );
};
export default Quiz32;
