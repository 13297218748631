import { useRef, useEffect } from "react";
import "../../Components/Chapters/Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "../PythonSideBar";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

const PythonC29 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };

  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor:'#D8D9DA'
  };
  const pc29code1 = `import json

data = {
    "name": "John",
    "age": 30,
    "city": "New York"
}

json_string = json.dumps(data)
print(json_string)`;
  const pc29code2 = `import json

json_string = '{"name": "Amy","age": 30}'

data = json.loads(json_string)
print(data)`;
  const pc29code3 = `import json 

data = {
    "name": "John",
    "age": 30,
    "city": "New York"
}

with open("data.json", "w") as json_file:
    json.dump(data, json_file)`;
  const pc29code4 = `import json 

with open("data.json", "r") as json_file:
    data = json.load(json_file)
    print(data)`;
const pc29code5 = `import requests

# Getting data from a URL
response = requests.get("URL with query string parameters")
json_data = response.json()

# Printing JSON data
print(json_data)`
  return (
    <div class="testing">
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/python/modules">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/python/exceptions">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Python JSON:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                JSON stands for JavaScript Object Notation. It is a lightweight
                data interchange format that is easy for humans to read and
                write, and easy for machines to parse and generate. <br />
                <br /> In Python, you can work with JSON data using the built-in
                json module. This module provides methods for encoding Python
                data structures into JSON format and decoding JSON data into
                Python data structures.
              </span>
              <br />
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Python to JSON (Encoding):
              </h2>
              <div className="ui clearing divider"></div>
              <span>
                To convert a Python data structure (e.g., a dictionary, list, or
                tuple) into a JSON-formatted string, you can use the
                json.dumps() function (short for "dump string").
              </span>
              <br />
              <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>json.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc29code1}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />

                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  JSON Python (Decoding):
                </h2>
                <div className="ui clearing divider"></div>
                <span>
                  To convert a JSON string into a Python data structure, you can
                  use the json.loads() function (short for "load string").
                </span>
                <br />
                <br />
                  <div style={containerStyle}>
                    <div className="ml-3 mr-3">
                      <b style={{ color: "black" }}>json.py</b>

                      <i
                        class="copy icon"
                        onClick={copyContent}
                        style={{
                          cursor: "pointer",
                          float: "right",
                          fontSize: "20px",
                        }}
                      ></i>
                    </div>
                    <div ref={divRef}>
                      <SyntaxHighlighter language="python" style={darcula}>
                        {pc29code2}
                      </SyntaxHighlighter>
                    </div>
                  </div>

                  <br />

                  <h2
                    class="ui left floated header"
                    style={{ color: "#001C30" }}
                  >
                    Working with JSON files:
                  </h2>
                  <div className="ui clearing divider"></div>
                  <span>
                    You can also read and write JSON data to/from files using
                    the json.dump() and json.load() functions:
                    <br />
                    <ul className="ui unordered list">
                      <li>
                        <b>json.dump(data, file_object)</b> writes JSON data to
                        a file.
                      </li>
                      <br />
                      <div style={containerStyle}>
                        <div className="ml-3 mr-3">
                          <b style={{ color: "black" }}>json.py</b>

                          <i
                            class="copy icon"
                            onClick={copyContent}
                            style={{
                              cursor: "pointer",
                              float: "right",
                              fontSize: "20px",
                            }}
                          ></i>
                        </div>
                        <div ref={divRef}>
                          <SyntaxHighlighter language="python" style={darcula}>
                            {pc29code3}
                          </SyntaxHighlighter>
                        </div>
                      </div>
                      <br />

                      <li>
                        <b>json.load(file_object)</b> reads JSON data from a
                        file.
                      </li>
                      <br />
                      <div style={containerStyle}>
                        <div className="ml-3 mr-3">
                          <b style={{ color: "black" }}>json.py</b>

                          <i
                            class="copy icon"
                            onClick={copyContent}
                            style={{
                              cursor: "pointer",
                              float: "right",
                              fontSize: "20px",
                            }}
                          ></i>
                        </div>
                        <div ref={divRef}>
                          <SyntaxHighlighter language="python" style={darcula}>
                            {pc29code4}
                          </SyntaxHighlighter>
                        </div>
                      </div>
                    </ul>
                  </span>
                  <br />
                  <h2
                    class="ui left floated header"
                    style={{ color: "#001C30" }}
                  >
                    JSON in APIs:
                  </h2>
                  <div className="ui clearing divider"></div>
                  <span>
                            JSON is actually Javascript Object Notation and we can use it in web APIs to 
                            interchange data. In Python, we can use <b>requests</b> library to interact with 
                            APIs and handle data.
                  </span><br /><br />
                  <div style={containerStyle}>
                        <div className="ml-3 mr-3">
                          <b style={{ color: "black" }}>json.py</b>

                          <i
                            class="copy icon"
                            onClick={copyContent}
                            style={{
                              cursor: "pointer",
                              float: "right",
                              fontSize: "20px",
                            }}
                          ></i>
                        </div>
                        <div ref={divRef}>
                          <SyntaxHighlighter language="python" style={darcula}>
                            {pc29code5}
                          </SyntaxHighlighter>
                        </div>
                      </div>
                      <p style={{ backgroundColor: "#FBEEAC", padding: "15px" }}>
                        <b>Note: </b>Do not forget to import requests library before using it.
                      </p>

                  <br />
                  <h2
                    class="ui left floated header"
                    style={{ color: "#001C30" }}
                  >
                    Data Types After Conversion:
                  </h2>
                  <div className="ui clearing divider"></div>
                  <span>
                    When you convert data from Python to JSON using the
                    json.dumps() function, the data types of the variables in
                    the resulting JSON data will be converted to other
                    compatible type. <br />
                    <ul className="ui ordered list">
                      <li>dict to object</li>
                      <li>array to list</li>
                      <li>int / float / double to numbers</li>
                      <li>bool to bool</li>
                      <li>none to null</li>
                    </ul>
                  </span>
              
            </div><br />
            <Link to="/python/modules">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/python/exceptions">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </div>
  );
};
export default PythonC29;
