import { useRef, useEffect } from "react";
import "../../Components/Chapters/Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Sidebar from "../JavaSideBar";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const JavaC26 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };

  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };
  const jc26code1 = `public int getAge() {
    return age; 
}`;
  const jc5code2 = `public class Main {
  public static void main(String[] args) {
      /* I am printing Hello World
      and this is a comment */
      System.out.println("Hello World");
  }
}`;
  const jc26code2 = `public void setAge(int newAge) {
    age = newAge; 
}`;
  const jc26code3 = `public class Person {
    private String name; // Private field

    // Constructor
    public Person(String name) {
        this.name = name;
    }

    // Getter method to access the name field
    public String getName() {
        return name;
    }

    // Setter method to modify the name field
    public void setName(String name) {
        this.name = name;
    }
}

public class Main {
  public static void main(String[] args) {
    // Create a Person object
    Person p1 = new Person("Bob");

    // Access the name field 
    String name = p1.getName();
    System.out.println("Name: " + name);

    // Modify the name field 
    p1.setName("Bob");
    System.out.println("Name: " + p1.getName());
  }
}`;
  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/java/modifiers">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/java/inheritance">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body ">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Encapsulation:
              </h2>
              <div class="ui clearing divider"></div>
              <p style={{ backgroundColor: "", padding: "" }}>
                In Java, encapsulation refers to the bundling of data and
                methods that operate on that data into a single unit known as
                class. It provides a way to control access to the data within
                those objects.
                <br />
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Getter Methods:
                </h2>
                <div class="ui clearing divider"></div>
                <span>
                  <ul className="ui unordered list">
                    <li>
                      A getter method is used to retrieve the value of a private
                      data member.
                    </li>
                    <li>
                      It provides controlled access to the data by returning the
                      value.
                    </li>
                    <li>
                      The name of a getter method usually starts with "get"
                      followed by the name of the data member it accesses (not a rule).
                    </li>
                  </ul>
                </span>
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>encapsulation.java</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="java" style={darcula}>
                      {jc26code1}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Setter Methods:
                </h2>
                <div class="ui clearing divider"></div>
                <span>
                  <ul className="ui unordered list">
                    <li>
                      A setter method is used to modify the value of a private
                      data member.
                    </li>
                    <li>
                      It provides a controlled access to the data by accepting a
                      new value as a parameter.
                    </li>
                    <li>
                      The name of a setter method typically starts with "set"
                      followed by the name of the data member it modifies (not a rule).
                    </li>
                  </ul>
                </span>
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>encapsulation.java</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="java" style={darcula}>
                      {jc26code2}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Encapsulation Example:
                </h2>
                <div class="ui clearing divider"></div>
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>encapsulation.java</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="java" style={darcula}>
                      {jc26code3}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
              </p>

              <h1>In this example;</h1>
              <ul className="ui unordered list">
                <li>
                  The Person class has a private data member "name" which is
                  encapsulated.
                </li>
                <li>The constructor is initializing the value of "name".</li>
                <li>
                  In main class, we create a Person object which can access public
                  data members.
                </li>
                <li>
                  The getName method allows you to access "name" using dot
                  operator in main function.
                </li>
                <li>The setName method is used to set the value of "name".</li>
              </ul>

              <span>
                This demonstrates how encapsulation allows you to control access
                to class fields and maintain data integrity.
              </span>
            </div>

            <br />
            <Link to="/java/modifiers">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/java/inheritance">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default JavaC26;
