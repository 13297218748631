import { useRef, useEffect } from "react";
import "../../Components/Chapters/Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Sidebar from "../JavaSideBar";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const JavaC3 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };

  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor:'#D8D9DA'
  };
  const javaCode = `public class Main {
    public static void main(String[] args) {
        System.out.println("Hello World");
    }
}`;
  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/java/getting_started">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/java/output">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body ">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Basic Syntax:
              </h2>
              <div class="ui clearing divider"></div>

              
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>helloworld.java</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="java" style={darcula}>
                    {javaCode}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <p>
                This is a basic program which prints "Hello World!" on screen. <br />
                Let us, split the code and understand it line by line. <br />
                <ul className="ui unordered list">
                  <li>
                    <b>public class Main{} </b>- defines a class names Main
                    which is public. In Java, we have to work with classes. The
                    Main class is the entry point for our program.
                  </li>
                  <br />
                  <li>
                    <b>public static void main(String[] args) {} </b>- it is a
                    special method inside main class. The execution of our code
                    starts from main method. <br />
                    <ol className="ui ordered list">
                      <li>
                        <b>public : </b>access modifier written before the main
                        function shows that it is a public function which means
                        that we can access this function anywhere within our java
                        program.
                      </li>
                      <li>
                        <b>static : </b>indicated that the method belongs to
                        class itself.
                      </li>
                      <li>
                        <b>void : </b>means that the function is not returning
                        any value.
                      </li>
                      <li>
                        <b>main : </b>name of the method.
                      </li>
                    </ol>
                    <br />
                    <li>
                      <b>String[] args :</b>This is the parameter to the main
                      function which allows us to pass command line argumentsto
                      our program when we run it.
                    </li>
                  </li>
                  <br />
                  <li>
                    <b>System.out.println("Hello World"); : </b>This is the
                    actual line of code which is getting executed.
                    <ol className="ui ordered list">
                      <li>
                        <b>System : </b>buil-in class in Java.
                      </li>
                      <li>
                        <b>out : </b>static field with System class which
                        represents standard output stream.
                      </li>
                      <li>
                        <b>println("Hello World"); : </b>This is the method call
                        on out object. It prints text "Hello World!" on screen.
                      </li>
                    </ol>
                  </li>
                </ul>
                
              </p>
              <br />
              <p style={{ backgroundColor: "#FBEEAC", padding: "15px" }}>
                <b>Note : </b>We have to take care about semi-colon. We have to put semi-colon at the end of 
                every expression. If you do not put semi-colon at the end of every expression, you are going to get an error.
              </p>


              
            </div>
            
            <br />
            <Link to="/java/getting_started">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/java/output">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default JavaC3;
