import { useRef, useEffect } from "react";
import "./Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Q24 from "../Questions/Q24";
import Sidebar from "../SideBar/Sidebar";
import Footer from "../Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const Ch29 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };
  const code1 = `#include <fstream>
  using namespace std;

int main() {
  ofstream outputFile("output.txt"); 

  if (outputFile.is_open()) {
    outputFile<<"Write this to the file." << endl;
    outputFile<<42<< " is an integer." << endl;
    outputFile.close();
    cout << "Written successfully." << endl;
  } else {
    cout << "Error opening the file." << endl;
  }

  return 0;
}`;
  const code2 = `#include <fstream>
#include <string>
using namespace std;

int main() {
    ifstream inputFile("input.txt");

    if (inputFile.is_open()) {
        string line;
        while (getline(inputFile, line)) {
            cout << line << endl;
        }
        inputFile.close();
    } else {
        cout << "Error opening the file." << endl;
    }

    return 0;
}`;
  const code3 = `#include <fstream>
  using namespace std;

int main() {
    ofstream outputFile("output.txt", ios::app);

    if (outputFile.is_open()) {
        outputFile << "Appended to the file." << endl;
        outputFile.close();
        cout << "Appended successfully." << endl;
    } else {
        cout << "Error opening the file." << endl;
    }

    return 0;
}`;
  const code4 = `#include <iostream>
#include<fstream>  

using namespace std;
int main()
{
	int num;
	cout<<"Enter a number\\n";
	cin>>num;
	fstream txt;
		txt.open("inp.txt", ios::out);
		txt << "Number: ";
		
		txt<<num;
		
		txt.close();
}`;

  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/Cpp/exceptions">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/Cpp/operator_overloading">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                File Handling
              </h2>
              <div class="ui clearing divider"></div>
              <p>
                File Handling allows you to work with files. Using file
                handling, you can write into files and you can also read from
                files. You need to include <b>fstream</b> header file to work
                with files. <br />
                <br />
                <p style={{ backgroundColor: "#FBEEAC", padding: "15px" }}>
                 <b>Note: </b> Whenever you use file handling in code, a file is created and
                  any input user enters saves in the file.
                </p>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Writing to a file:
                </h2>
                <div class="ui clearing divider"></div>
                To write data to a file you can use ofstream class from fstream
                header file. Use .open() function to create an object and open a
                file.
                <br />
                <br />
                <div ref={divRef}>
                  <SyntaxHighlighter language="cpp" style={darcula}>
                    {code1}
                  </SyntaxHighlighter>
                </div>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Reading from a file:
                </h2>
                <div class="ui clearing divider"></div>
                 To read data from a file you can use
                ifstream class from fstream header file. Use .open() function to
                create an object and open a file.
                <br />
                <br />
                <div ref={divRef}>
                  <SyntaxHighlighter language="cpp" style={darcula}>
                    {code2}
                  </SyntaxHighlighter>
                </div>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Appending to a file:
                </h2>
                <div class="ui clearing divider"></div>
                To append data to an existing file you can use ofstream class
                with append function. Use .open() function to create an object
                and open a file.
                <br />
                <br />
                <div ref={divRef}>
                  <SyntaxHighlighter language="cpp" style={darcula}>
                    {code3}
                  </SyntaxHighlighter>
                </div>
                <br />
                In the following code, we are creating a file, and when we
                execute the program a file is created in the directory where
                your program is located. When you give input, the input will be
                saved in that file. <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>file.cpp</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="cpp" style={darcula}>
                      {code4}
                    </SyntaxHighlighter>
                  </div>
                </div>
              </p>
              <br />
            </div>
            <br />
            <Link to="/Cpp/exceptions">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/Cpp/operator_overloading">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default Ch29;
