import { useRef, useEffect } from "react";
import "../../Components/Chapters/Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "../PythonSideBar";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

const PythonC19 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };

  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };
  const pc19code1 = `#pass statement using for loop
for x in range(10):
    pass

#pass statement using while loop
i = 1
while (i<5):
    pass`;
  const pc19code2 = `# PART1 Continue statement using for loop
for num in range(1, 6):
    if num == 3:
        continue
    print(num)


# PART2 Continue statement using for loop
count = 0
while count < 5:
    count += 1    #count = count + 1
    if count == 2:
        continue
    print(count)`;
  const pc19code3 = `# PART1 Break statement using for loop
for num in range(1, 6):
    if num == 3:
        break
    print(num)


#PART2 Break statement using while loop
count = 0
while count < 5:
    count += 1    #count = count + 1
    if count == 2:
        break
    print(count)`;
  return (
    <div class="testing">
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/python/booleans">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/python/functions">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Control Statements:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                Control statements in Python are constructs designed to manage
                the flow of our program's execution. They empower us to make
                choices, iterate actions, and bypass specific sections of our
                code contingent on particular conditions. <br />
                <br />
                In python, there are three control statements given below:
                <ul className="ui unordered list">
                  <li>pass</li>
                  <li>continue</li>
                  <li>break</li>
                </ul>
              </span>

              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Pass Statement:
              </h2>
              <div className="ui clearing divider"></div>
              <span>
                When you write a loop, it can not be empty. You have to write
                some code in this loop. But if you do not want to write any code
                and still need for loop, you can use pass statement to pass all
                the iterations to avoid getting the error.
              </span>
              <br />
              <br />

              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>pass.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc19code1}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />

              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Continue Statement:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                In loops, continue statement is used to skip the current
                iteration. For example, we say that if counter variable has some
                specified value, then continue. It means that skip the iteration
                at specified value. The continue statements are executed
                alongwith if statement.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>continue.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc19code2}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Output:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                <ul className="ui unordered list">
                  <li>We are using for loop in the first part of code. We are using range function which will cause the 
                    loop to start from 1 to 5. When we move inside the loop, we can see that when num is equal to 3, a 
                    continue statement is encountered. After that we are printing <b>num</b>. So the output will be 1 2 4 5.
                  </li>
                  <li>
                    In second part of our code, we are using while loop to explain the functionality of continue statement. 
                    A variable <b>count</b> is initialized with 0 and when while loop is encountered, the condition is checked. 
                    If the condition is true, the control is moved inside the loop. The value of <b>count</b> is
                     incremented by 1. If the value of <b>count</b> is 2, a continue 
                    statement is encountered. Otherwise print its value. So the output will be 1 3 4 5.
                  </li>
                </ul>
              </span><br />

              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Break Statement:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                In loops, break statement is used to skip all iterations after
                the variable has a specified value. For example, we say that if
                counter variable has some specified value, then break. It means
                that skip all the iterations after that specified value. The
                break statements are executed alongwith if statement.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>break.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc19code3}
                  </SyntaxHighlighter>
                </div>
              </div>
            </div>
            <br />
            <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Output:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                <ul className="ui unordered list">
                  <li>
                    In first part of our code, we are using for loop to explain the functionality of break statement. Inside for 
                    loop, if the value of num is equal to 3, a break statement is encountered and the control moves out of the loop. 
                    So the output will be 1 2.
                  </li>
                  <li>
                    In the second part, we are using while loop. If the value of count inside the loop is equal to 2, a break statement is encountered and 
                    the control will move out of the loop and the loop exits. So the output will be 1.
                  </li>
                </ul>
              </span>
            <br />
            <Link to="/python/booleans">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/python/functions">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </div>
  );
};
export default PythonC19;
