import { useState, useEffect } from "react";
import Sidebar from "./SideBar";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
// import "./Quiz.css";
import "../../Components/Chapters/Chapters.css";
import Modal from "react-modal";

const JQuiz28 = () => {
  const [answers, setAnswers] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const handleQuizSubmit = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const questions = [
    {
      id: 1,
      question: "  What does polymorphism refer to in object-oriented programming:",
      options: ["The ability of objects to change their data types at runtime", "The ability of objects to take on multiple forms and behave differently based on their actual class", "The ability of objects to hide their implementation details from other objects", "The ability of objects to inherit properties and behaviors from a superclass"],
      correctAnswer: "The ability of objects to take on multiple forms and behave differently based on their actual class",
    },
    {
      id: 2,
      question: "  How is polymorphism achieved in Java through method overriding:",
      options: ["By creating abstract classes with no concrete methods", "By providing multiple constructors in a class", "By using the @Override annotation to indicate that a subclass method is intended to override a superclass method", "By using the new keyword to create instances of subclasses"],
      correctAnswer: "By using the @Override annotation to indicate that a subclass method is intended to override a superclass method",
    },
    {
      id: 3,
      question: "  What is the purpose of implementing an interface in Java with regard to polymorphism:",
      options: [
        "To allow multiple inheritance of classes",
        "To provide a common set of properties to all classes",
        "To enable classes to change their data types dynamically",
        "To specify a contract for methods that must be implemented by implementing classes",
      ],
      correctAnswer: "To specify a contract for methods that must be implemented by implementing classes",
    },
    {
      id: 4,
      question: "  In Java, which of the following is NOT a way to achieve polymorphism:",
      options: ["Method Overloading", "Method Overriding", "Interface Implementation", "Constructor Overloading"],
      correctAnswer: "Method Overriding",
    },
    {
        id: 5,
        question: "  In the context of polymorphism with method overriding, what is the term for the process by which a subclass provides a specific implementation for a method that is already defined in its superclass:",
        options: ["Overloading", "Overriding", "Inheritance", "Encapsulation"],
        correctAnswer: "Inheritance",
      },
      {
        id: 6,
        question: "  Which of the following statements about interfaces and polymorphism is true:",
        options: ["A class can implement multiple interfaces, but only override methods from one interface", "An interface can extend multiple interfaces, inheriting their methods and polymorphic behavior", "A class implementing an interface cannot use polymorphism", "Polymorphism in Java is only achieved through class inheritance, not interfaces"],
        correctAnswer: "An interface can extend multiple interfaces, inheriting their methods and polymorphic behavior",
      },
  ];
  const handleAnswerChange = (questionId, selectedAnswer) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: selectedAnswer,
    }));
  };

  const calculateScore = () => {
    let correctCount = 0;
    let incorrectCount = 0;

    questions.forEach((question) => {
      const userAnswer = answers[question.id];
      if (userAnswer === question.correctAnswer) {
        correctCount++;
      } else {
        incorrectCount++;
      }
    });

    return { correctCount, incorrectCount };
  };

  const { correctCount, incorrectCount } = calculateScore();
  return (
    <>
      <Sidebar>
        <div class="container">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/quizes/java_quiz27">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                  }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/quizes/java_quiz29">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              </h2>
              <h1 class="start content ui left floated header">
                Polymorphism
              </h1>
              <div className="ui clearing divider"></div>
            

            <div class="main-body">
              <h2 class="ui left floated header">
                Quiz # 28
              </h2>
              <span class="tut-btn"><Link to="/java/polymorphism">
                  <button class="ui secondary button">Read Tutorial</button>
                </Link></span>
              <div class="ui clearing divider"></div>
              <div>
                {questions.map((question, i) => (
                  <div key={question.id}>
                    <div class="con">
                    <div class="que">Q{i+1}:<span class="one-per-left-margin">{question.question}</span></div>
                      {question.options.map((option) => (
                        <label class="label" key={option}>
                          <input
                            type="radio"
                            name={`question_${question.id}`}
                            value={option}
                            checked={answers[question.id] === option}
                            onChange={(e) =>
                              handleAnswerChange(question.id, e.target.value)
                            }
                          />
                          <span className="one-per-left-margin">{option}</span>
                          <br />
                        </label>
                      ))}
                    </div>
                    <hr />
                  </div>
                ))}<br/>
                <center><button class="ui primary button" onClick={handleQuizSubmit}>Submit Quiz</button></center>

                {/* Modal for displaying results */}
                <Modal class="modal" isOpen={isModalOpen} onRequestClose={closeModal}>
                  <b style={{fontSize:'20px'}}>Quiz Results</b>
                  <b><p>Your score is {correctCount}/{questions.length} </p></b>
                  {questions.map((question,i) => (
                    <div key={question.id}>
                      <p>Q{i+1}: {question.question}</p>
                      <p><b>Correct Answer:</b> {question.correctAnswer}</p>
                      <p className={`${question.correctAnswer}` === `${answers[question.id]}` ? "correct" : "wrong"}>
                        <b>Your Answer:</b> {answers[question.id] || "Not answered"}
                      </p>
                      <hr />
                    </div>
                  ))}
                  <button class="ui secondary button" onClick={closeModal}>Close</button><br /><br />
                </Modal>
              </div>

              <br />
            </div>
            <Link to="/quizes/java_quiz27">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                  }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/quizes/java_quiz29">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
            <br />
          </div>
        </div>
      </Sidebar>

      <div class="sideBarBody">
        <Footer />
      </div>
    </>
  );
};
export default JQuiz28;
