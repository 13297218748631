import { useRef, useEffect } from "react";
import "../../Components/Chapters/Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Sidebar from "../JavaSideBar";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const JavaC10 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };

  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor:'#D8D9DA'
  };

  const jc10code1 = `public class Main {
    public static void main(String[] args) {
        
        int a = 10;
        System.out.println(a + 10);

        int b = 10 + 10;
        System.out.println(b);

        int c = a + b;
        System.out.println(c);

    }
}`;
  const code2 = `#include <iostream>
using namespace std;
int main(){
    int a=10;
    int b=5;
    bool ans = a>b;
    cout<<ans; 
    return 0;
}`;
  const jc10code3 = `a < b`;
  const jc10code4 = `a <= b`;
  const jc10code5 = `a > b`;
  const jc10code6 = `a >= b`;
  const jc10code7 = `(a > b) && (b > c)`;
  const jc10code8 = `(a > b) || (b > c)`;
  const jc10code9 = `!(a > b)`;
  const jc10code10 = `a >> 2`;
  const jc10code11 = `>>`;
  const jc10code12 = `<<`;
  const jc10code13 = `a << 2`;
  const icode = `int a = 10;  
a++;`;
  const dcode = `int a = 10;  
a--;`;
  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/java/strings">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/java/maths">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body ">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Operators in Java:
              </h2>
              <div class="ui clearing divider"></div>
              <p style={{ backgroundColor: "", padding: "" }}>
              Operators are used to perform arithmetic or other operations on data or variables.
                <br />
                There are two types of operators on the basis of number of operands:
                <ul className="ui unordered list">
                  <li>
                    <b>Unary Operators - </b>it works on only one operand, e.g. a++
                  </li>
                  <li>
                    <b>Binary Operators - </b>it works on two operands, e.g. a+b
                  </li>
                </ul>
                <p style={{ backgroundColor: "#FBEEAC", padding: "15px" }}>
                  <b>Note:</b> You can add constant values to a variable.
                </p>
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>operator.java</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="java" style={darcula}>
                      {jc10code1}
                    </SyntaxHighlighter>
                  </div>
                </div>
                
                <br />
                <p style={{ backgroundColor: "#FBEEAC", padding: "10px" }}>
                  <b>Note : </b>For applying operation on variables, their data
                  types must be compatible for that operation. For example, if
                  you try to add number in a string, it will give you an error.
                </p>
                <br />
                
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Types of Operators:
                </h2>
                <div class="ui clearing divider"></div>
                <ul className="ui unordered list">
                  <li>Arithmetic Operators</li>
                  <li>Assignment Operators</li>
                  <li>Comparison Operators</li>
                  <li>Logical Operators</li>
                  <li>Bitwise Operators</li>
                </ul>
                <br />
                We are going to discuss about these operators in detail. <br />
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Arithmetic Operators:
                </h2>
                <div class="ui clearing divider"></div>
                They are used to do Arithmetic Operations on data.
                <table class="ui very padded table">
                  <thead>
                    <tr>
                      <th>Operator</th>
                      <th>Name</th>
                      <th>Example</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <b>+</b>
                      </td>
                      <td>Addition</td>
                      <td>
                        <div ref={divRef}>
                          <SyntaxHighlighter
                            language="java"
                            style={{ backgroundColor: "#fff" }}
                          >
                            int a = 10 + 20;
                          </SyntaxHighlighter>
                        </div>
                      </td>
                      <td>Adds two data values.</td>
                    </tr>
                    <tr>
                      <td>
                        <b>-</b>
                      </td>
                      <td>Subtraction</td>
                      <td>
                        <SyntaxHighlighter
                          language="java"
                          style={{ backgroundColor: "#fff" }}
                        >
                          int a = 100 - 20;
                        </SyntaxHighlighter>
                      </td>
                      <td>Subtracts two data values.</td>
                    </tr>
                    <tr>
                      <td>
                        <b>*</b>
                      </td>
                      <td>Multiplication</td>
                      <td>
                        <SyntaxHighlighter
                          language="java"
                          style={{ backgroundColor: "#fff" }}
                        >
                          int a = 3 * 2;
                        </SyntaxHighlighter>
                      </td>
                      <td>Multiply two data values.</td>
                    </tr>
                    <tr>
                      <td>
                        <b>/</b>
                      </td>
                      <td>Division</td>
                      <td>
                        <SyntaxHighlighter
                          language="java"
                          style={{ backgroundColor: "#fff" }}
                        >
                          int a = 10 / 5;
                        </SyntaxHighlighter>
                      </td>
                      <td>Divides two data values.</td>
                    </tr>
                    <tr>
                      <td>
                        <b>%</b>
                      </td>
                      <td>Modulus</td>
                      <td>
                        <SyntaxHighlighter
                          language="java"
                          style={{ backgroundColor: "#fff" }}
                        >
                          int a = 23 % 5;
                        </SyntaxHighlighter>
                      </td>
                      <td>
                        Return the remainder when two data values are divided.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>++</b>
                      </td>
                      <td>Increment</td>
                      <td>
                        <SyntaxHighlighter
                          language="java"
                          style={{ backgroundColor: "#fff" }}
                        >
                          {icode}
                        </SyntaxHighlighter>
                      </td>
                      <td>Increment value of a variable by 1.</td>
                    </tr>
                    <tr>
                      <td>
                        <b>--</b>
                      </td>
                      <td>Decrement</td>
                      <td>
                        <SyntaxHighlighter
                          language="java"
                          style={{ backgroundColor: "#fff" }}
                        >
                          {dcode}
                        </SyntaxHighlighter>
                      </td>
                      <td>Decrement value of a variable by 1.</td>
                    </tr>
                  </tbody>
                </table>
                <br />{" "}
                <p style={{ backgroundColor: "#FBEEAC", padding: "10px" }}>
                  <b>Increment: </b>
                  <br />
                  <ul className="ui unordered list">
                    <li>
                      Post-increment - it increments value by 1 at the end of instruction.
                    </li>
                    <li>
                      Pre-increment - it increments value by 1 at the start of instruction.
                    </li>
                  </ul>
                </p>
                <br />
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Assignment Operators:
                </h2>
                <div class="ui clearing divider"></div>
                They assign values to variables. For example, int a =10;
                <table class="ui very padded table">
                  <thead>
                    <tr>
                      <th>Operator</th>
                      <th>Example</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <b>=</b>
                      </td>
                      <td>int a = 10;</td>
                      <td>Assigning 10 to int type variable a.</td>
                    </tr>
                    <tr>
                      <td>
                        <b>+=</b>
                      </td>
                      <td>a+=10;</td>
                      <td>It is short form of "a=a+10".</td>
                    </tr>
                    <tr>
                      <td>
                        <b>-=</b>
                      </td>
                      <td>a-=10;</td>
                      <td>It is short form of "a=a-10".</td>
                    </tr>
                    <tr>
                      <td>
                        <b>*=</b>
                      </td>
                      <td>a*=10;</td>
                      <td>It is short form of "a=a*10".</td>
                    </tr>
                    <tr>
                      <td>
                        <b>/=</b>
                      </td>
                      <td>a/=10;</td>
                      <td>It is short form of "a=a/10".</td>
                    </tr>
                    <tr>
                      <td>
                        <b>%=</b>
                      </td>
                      <td>a%=10;</td>
                      <td>It is short form of "a=a%10".</td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Comparison Operators:
                </h2>
                <div class="ui clearing divider"></div>
                They are used to compare values and make decisions. They are
                most widely used in conditional statements. They either return 
                true or false (Boolean), on the basis of which we proceed
                further. <br />
                e.g, For example, if 20 is greater than 10, then add a and b
                otherwise subtract a and b. <br />
                <br />
                <table class="ui very padded table">
                  <thead>
                    <tr>
                      <th>Operator</th>
                      <th>Example</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <b>==</b>
                      </td>
                      <td> a == b;</td>
                      <td>
                        <b>Double equal </b>checks if a is equal to b.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>!=</b>
                      </td>
                      <td> a != b;</td>
                      <td>
                        <b>Not equal </b>checks if a is not equal to b.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>
                          <SyntaxHighlighter
                            language="java"
                            style={{ backgroundColor: "#ffff" }}
                          >
                            {jc10code3}
                          </SyntaxHighlighter>
                        </b>
                      </td>
                      <td>
                        {" "}
                        <SyntaxHighlighter
                          language="java"
                          style={{ backgroundColor: "#ffff" }}
                        >
                          {jc10code3}
                        </SyntaxHighlighter>{" "}
                      </td>
                      <td>
                        <b>Less than operator</b>checks if a is less than b.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>
                          <SyntaxHighlighter
                            language="java"
                            style={{ backgroundColor: "#ffff" }}
                          >
                            {jc10code4}
                          </SyntaxHighlighter>
                        </b>
                      </td>
                      <td>
                        {" "}
                        <SyntaxHighlighter
                          language="java"
                          style={{ backgroundColor: "#ffff" }}
                        >
                          {jc10code4}
                        </SyntaxHighlighter>{" "}
                      </td>
                      <td>
                        <b>Less than or equal </b>checks if a is less than or
                        equal b.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>
                          <SyntaxHighlighter
                            language="java"
                            style={{ backgroundColor: "#ffff" }}
                          >
                            {jc10code5}
                          </SyntaxHighlighter>
                        </b>
                      </td>
                      <td>
                        {" "}
                        <SyntaxHighlighter
                          language="java"
                          style={{ backgroundColor: "#ffff" }}
                        >
                          {jc10code5}
                        </SyntaxHighlighter>{" "}
                      </td>
                      <td>
                        <b>Greater than operator</b>checks if a is greater than b.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>
                          <SyntaxHighlighter
                            language="java"
                            style={{ backgroundColor: "#ffff" }}
                          >
                            {jc10code6}
                          </SyntaxHighlighter>
                        </b>
                      </td>
                      <td>
                        {" "}
                        <SyntaxHighlighter
                          language="java"
                          style={{ backgroundColor: "#ffff" }}
                        >
                          {jc10code6}
                        </SyntaxHighlighter>{" "}
                      </td>
                      <td>
                        <b>Greater than or equal </b>checks if a is greater than
                        or equal b.
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Logical Operators:
                </h2>
                <div class="ui clearing divider"></div>
                Like comparison operators, they also return true (1) or false
                (0). <br />
                There are three types of logical operators: <br />
                <table class="ui very padded table">
                  <thead>
                    <tr>
                      <th>Operator</th>
                      <th>Example</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <b>&&</b>
                      </td>
                      <td>
                        {" "}
                        <SyntaxHighlighter
                          language="java"
                          style={{ backgroundColor: "#ffff" }}
                        >
                          {jc10code7}
                        </SyntaxHighlighter>{" "}
                      </td>
                      <td>
                        <b>AND </b>- it says that both conditions need to be
                        true. a should be greater than b and b should be greater
                        than c.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>||</b>
                      </td>
                      <td>
                        {" "}
                        <SyntaxHighlighter
                          language="java"
                          style={{ backgroundColor: "#ffff" }}
                        >
                          {jc10code8}
                        </SyntaxHighlighter>{" "}
                      </td>
                      <td>
                        <b>OR </b>- it says that only one condition needs to be
                        true. a should be greater than b or b should be greater
                        than c.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>!</b>
                      </td>
                      <td>
                        {" "}
                        <SyntaxHighlighter
                          language="java"
                          style={{ backgroundColor: "#ffff" }}
                        >
                          {jc10code9}
                        </SyntaxHighlighter>{" "}
                      </td>
                      <td>
                        <b>NOT </b>- inverts the result. If a is greater than b,
                        it will return false.
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Bitwise Operators:
                </h2>
                <div class="ui clearing divider"></div>
                Bitwise operators perform operation on bit level. We deal with the
                decimal values with base 10, but for using bitwise operators,
                computer convert base 10 to base 2 (binary format) and perform
                operations on bit and again convert then to base 10 and return 
                the output in decimal form.
                <table class="ui very padded table">
                  <thead>
                    <tr>
                      <th>Operator</th>
                      <th>Name</th>
                      <th>Example</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <b>&</b>
                      </td>
                      <td> bitwise AND</td>
                      <td>a & b</td>
                      <td>
                        Binary of 2 is 10 and of 3 is 11 and their and will be
                        10 in binary and computer gives the answer 2.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>|</b>
                      </td>
                      <td> bitwise OR</td>
                      <td>a | b</td>
                      <td>
                        By taking bitwise OR of 10 and 11 we will get 11 in
                        binary and output will be 3.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>~</b>
                      </td>
                      <td> bitwise NOT</td>
                      <td>~a (uniary operator)</td>
                      <td>
                        Binary of a (a=2) is 10 and bitwise not will be 01 and
                        output will be 1.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>
                          <SyntaxHighlighter
                            language="java"
                            style={{ backgroundColor: "#ffff" }}
                          >
                            {jc10code11}
                          </SyntaxHighlighter>
                        </b>
                      </td>
                      <td> Shift Right</td>
                      <td>
                        <SyntaxHighlighter
                          language="java"
                          style={{ backgroundColor: "#ffff" }}
                        >
                          {jc10code10}
                        </SyntaxHighlighter>
                      </td>
                      <td>
                        a=8; binary is 1000.
                        <SyntaxHighlighter
                          language="java"
                          style={{ backgroundColor: "#ffff" }}
                        >
                          {jc10code13}
                        </SyntaxHighlighter>{" "}
                        will be 10 and output will be 2.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>
                          <SyntaxHighlighter
                            language="java"
                            style={{ backgroundColor: "#ffff" }}
                          >
                            {jc10code12}
                          </SyntaxHighlighter>
                        </b>
                      </td>
                      <td> Shift Left</td>
                      <td>
                        <SyntaxHighlighter
                          language="java"
                          style={{ backgroundColor: "#ffff" }}
                        >
                          {jc10code13}
                        </SyntaxHighlighter>
                      </td>
                      <td>
                        a=8; binary is 1000.
                        <SyntaxHighlighter
                          language="java"
                          style={{ backgroundColor: "#ffff" }}
                        >
                          {jc10code10}
                        </SyntaxHighlighter>{" "}
                        will be 100000 and the output will be 32.
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Operator Precedence:
                </h2>
                <div class="ui clearing divider"></div>
                If we have multiple operators in an expression, then compiler
                will have to decide which operator will be evaluated first and
                which will be evaluated in the last. <br />
                The order in which the arithmetic expression is evaluated is
                called the order of precedence. It is also known as hierarchy of
                operation. <br />
                When an arithmetic expression is evaluated, the computer
                performs only one operation at one time. In an expression in C++
                the operations are performed in the following order
                <ul className="ui unordered list">
                  <li>
                    All multiplications and divisions are performed first starting from
                    left to right.
                  </li>
                  <li>
                    All additions and subtractions are then performed starting from left
                    to right.
                  </li>
                  <li>
                    If the parenthesis are used in an expression, the expression
                    within parenthesis are first computed from left to right.
                  </li>
                  <li>
                    When parenthesis are used within parenthesis, the expression
                    within innermost parenthesis will be evaluated first.
                  </li>
                </ul>
                You can check out precedence of operators at: <br />
                <br />
                <div className="btn">
                  <center>
                    <button class=" ui primary button">
                      <a href="https://en.javareference.com/w/java/language/operator_precedence">
                        Operator Precedence
                      </a>
                    </button>
                  </center>
                </div>
                <br />
                <br />
                <b>Example:</b>
                <br />
                <br />
                (4-(3*5))+2 <br />
                <b>1.</b> (3*5) is computed and returns 15. <br />
                <b>2.</b> 4-15 is computed and then return a value of -11.{" "}
                <br />
                <b>3.</b> -11+2 is computed and returns value of -9.
              </p>

              
            </div>
           
            <br />
            <Link to="/java/strings">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/java/maths">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default JavaC10;
