import { useRef, useEffect } from "react";
import "../../Components/Chapters/Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "../PythonSideBar";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

const PythonC28 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };

  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor:'#D8D9DA'
  };
  const pc28code1 = `import math

# Calculate the square root
x = 16
sqrt_x = math.sqrt(x)
print(f"Square root of {x} is {sqrt_x}")

# Access the value of pi
pi_value = math.pi
print(f"The value of pi is ~ {pi_value}")`;
  const pc28code2 = `import random

# Generating an integer number between 1 and 10
random_int = random.randint(1, 10)
print(f"Random integer: {random_int}")

# Generating a float number between 0 and 1
random_float = random.random()
print(f"Random float : {random_float}")`;
  const pc28code3 = `import os

# Getting a list of files in the current directory
current_directory = os.getcwd()
file_list = os.listdir(current_directory)

print(f"Files in {current_directory}:")
for file in file_list:
    print(file)`;
  const pc28code4 = `import datetime

# Get the current date and time
currentTime = datetime.datetime.now()

print(f"Current date and time: {currentTime}")`;
  const pc28code5 = `import numpy as np

# Creating a NumPy array
arr = np.array([1, 2, 3, 4, 5])

# Performing operations on the array
mean = np.mean(arr)
sum_of_elements = np.sum(arr)
square_root = np.sqrt(arr)

print(f"Array: {arr}")
print(f"Mean: {mean}")
print(f"Sum: {sum_of_elements}")
print(f"Square Root: {square_root}")`;
  const pc28code6 = `import matplotlib.pyplot as plt

# Data for plotting
x = [1, 2, 3, 4, 5]
y = [1, 4, 9, 16, 25]

# Create a line plot
plt.plot(x, y)

# Add labels and title
plt.xlabel('X-axis')
plt.ylabel('Y-axis')
plt.title('Simple Line Plot')

# Display the plot
plt.show()`;
  const pc28code7 = `# A variable defined in the module
my_variable = "Hello from my_module"

# A function defined in the module
def my_function():
    return "This is my_function from my_module"`;
  const pc28code8 = `# Import the user-defined module
import my_module

# Access the variable from the module
print(my_module.my_variable)

# Call the function from the module
result = my_module.my_function()
print(result)`;
  return (
    <div class="testing">
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/python/polymorphism">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/python/json">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Modules in Python:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                In Python, a module is a file containing Python code. Modules
                can define functions, classes, and variables, and they can also
                include runnable code. Modules are used to organize and reuse our
                code in a structured way, making it easier to manage and
                maintain large Python programs.
              </span>
              <br />
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Types of Modules:
              </h2>
              <div className="ui clearing divider"></div>
              <span>
                The types of modules, we are going to discuss here are as
                follows: <br />
                <ul className="ui unordered list">
                  <li>Built-in Modules</li>
                  <li>Third-Party Modules</li>
                  <li>User-Defined Modules</li>
                </ul>
              </span>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Built-in Modules:
              </h2>
              <div className="ui clearing divider"></div>
              <span>
                Python comes with a rich standard library that includes many
                built-in modules for various tasks. These modules are part of
                the Python language itself, so you can use them without
                installing any additional packages.
              </span>
              <br />
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                "math" Module:
              </h2>
              <div className="ui clearing divider"></div>
              <span>
                The math module provides us with mathematical functions and constants.
              </span><br /><br />

              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>modules.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc28code1}
                  </SyntaxHighlighter>
                </div>
              </div>

              
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                "random" Module:
              </h2>
              <div className="ui clearing divider"></div>
              <span>
                The random module allows us to work with random numbers.
              </span><br /><br />

              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>modules.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc28code2}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                "os" Module:
              </h2>
              <div className="ui clearing divider"></div>
              <span>
                The os module provides functions for interacting with the
                operating system.
              </span><br /><br />

              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>modules.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc28code3}
                  </SyntaxHighlighter>
                </div>
              </div>

              
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                "datetime" Module:
              </h2>
              <div className="ui clearing divider"></div>
              <span>
                The datetime module facilitates working with dates and times.
              </span><br /><br />

              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>modules.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc28code4}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Third Party Modules:
              </h2>
              <div className="ui clearing divider"></div>
              <span>
                There is also use of third-party Python modules, specifically
                NumPy and Matplotlib.
              </span>
              <br /><br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                NumPy Module:
              </h2>
              <div className="ui clearing divider"></div>
              <span>
                NumPy is a popular third-party library for numerical and
                scientific computing in Python. It provides support for working
                with arrays, matrices, and mathematical functions. You'll need
                to install NumPy using pip if you haven't already:
              </span>
              <br /><br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                PIP:
              </h2>
              <div className="ui clearing divider"></div>
              <span>
              PIP is a package manager for installing and managing Python packages, or modules. If you have Python version 3.4 or later, you do not need to install PIP.
              </span><br />
              <br />


              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>modules.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    pip install numpy
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>modules.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc28code5}
                  </SyntaxHighlighter>
                </div>
              </div>

              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Matplotlib Module:
              </h2>
              <div className="ui clearing divider"></div>
              <span>
                Matplotlib is another popular third-party library used for
                creating high-quality plots and visualizations in Python.
              </span><br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>modules.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    pip install matplotlib
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>modules.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc28code6}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />

              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                User-defined Modules:
              </h2>
              <div className="ui clearing divider"></div>
              <span>
                <ul className="ui unordered list">
                  <li> User defined modules are created by the user itself. When he/she
                wants to import specific functions or variables from that
                module, it will be easy for him/her.</li>
                <li>Suppose you want to create a simple module named my_module.py
                that contains a function and a variable,</li>
                </ul>
                
                
              </span>
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>my_module.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc28code7}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <span>
                Now, you can create another Python script in the same directory
                and import and use the my_module module:
              </span>
              <br /><br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>main.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc28code8}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <span>
                
                In this example,
                <ul className="ui unordered list">
                  <li>
                    my_module.py contains a variable named "my_variable" and a function named
                    "my_function".
                  </li>
                  <li>
                    In main.py file, we import my_module using the import statement.
                  </li>
                  <li>
                    We access the variable "my_variable" and call the function
                    "my_function" from the imported module.{" "}
                  </li>
                </ul>
              </span>
              <br />
              <span>
                User-defined modules are a great way to organize and reuse the code
                in our Python projects. We can create modules to encapsulate
                related functionality, making our code more modular and
                maintainable.
              </span><br /><br />

              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Python Packages:
              </h2>
              <div className="ui clearing divider"></div>
                    <span>
                    Python packages are essentially folders that contain many python modules. As such, the packages help us to import modules from different folders.
                    </span>
            </div>
            <br />
            <Link to="/python/polymorphism">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/python/json">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </div>
  );
};
export default PythonC28;
