import { useState, useEffect } from "react";
import Sidebar from "./SideBar";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
// import "./Quiz.css";
import "../../Components/Chapters/Chapters.css";
import Modal from "react-modal";

const PQuiz15 = () => {
  const [answers, setAnswers] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const handleQuizSubmit = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const questions = [
    {
      id: 1,
      question: "  What is a dictionary in Python:",
      options: ["A collection of values with indexes", "A collection of key-value pairs", "A list of unique items", "A sequence of values"],
      correctAnswer: "A collection of key-value pairs",
    },
    {
      id: 2,
      question: "  How can you access items in a dictionary:",
      options: ["Using indexes", "Using a loop", "Using the \"get\" method", "Using both keys and values"],
      correctAnswer: "Using indexes",
    },
    {
      id: 3,
      question: "  What happens when you add duplicate keys to a dictionary in Python:",
      options: [
        "The new key replaces the old key",
        "An error occurs",
        "Both keys are kept with their respective values",
        "The dictionary becomes empty",
      ],
      correctAnswer: "Both keys are kept with their respective values",
    },
    {
        id: 4,
        question: "  Which data types can items in a dictionary have:",
        options: [
          "Only integers",
          "Only strings",
          "Only tuples",
          "Items can have different data types",
        ],
        correctAnswer: "Items can have different data types",
      },
      {
        id: 5,
        question: "  How do you change the value associated with a key in a dictionary:",
        options: [
          "Using the \"set\" method",
          "Using the \"update\" method",
          "Direct assignment to the key",
          "Using the \"change\" method",
        ],
        correctAnswer: "Direct assignment to the key",
      },
      {
        id: 6,
        question: "  What is the result of checking for the existence of a key in a dictionary using the in keyword:",
        options: [
          "True if the key exists, otherwise False",
          "An error",
          "Always True",
          "Always False",
        ],
        correctAnswer: "True if the key exists, otherwise False",
      },
      {
        id: 7,
        question: "  What is a nested dictionary:",
        options: [
          "A dictionary with only one key",
          "A dictionary with duplicate keys",
          "A dictionary that contains another dictionary",
          "A dictionary that is empty",
        ],
        correctAnswer: "A dictionary that contains another dictionary",
      },
      {
        id: 8,
        question: "  Which method is used to retrieve a view of the dictionary's key-value pairs as tuples:",
        options: [
          "items()",
          "viewitems()",
          "key_values()",
          "get_items()",
        ],
        correctAnswer: "items()",
      },
      {
        id: 9,
        question: "  What does the \"popitem()\" method do:",
        options: [
          "Removes the last item from the dictionary",
          "Removes and returns an arbitrary (random) key-value pair",
          "Removes a specific key-value pair",
          "Adds a new key-value pair to the dictionary",
        ],
        correctAnswer: "Removes and returns an arbitrary (random) key-value pair",
      },
      {
        id: 10,
        question: "  Which method can be used to update a dictionary with key-value pairs from another dictionary or iterable:",
        options: [
          "modify()",
          "change()",
          "merge()",
          "update()",
        ],
        correctAnswer: "update()",
      },
  ];
  const handleAnswerChange = (questionId, selectedAnswer) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: selectedAnswer,
    }));
  };

  const calculateScore = () => {
    let correctCount = 0;
    let incorrectCount = 0;

    questions.forEach((question) => {
      const userAnswer = answers[question.id];
      if (userAnswer === question.correctAnswer) {
        correctCount++;
      } else {
        incorrectCount++;
      }
    });

    return { correctCount, incorrectCount };
  };

  const { correctCount, incorrectCount } = calculateScore();
  return (
    <>
      <Sidebar>
        <div class="container">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/quizes/python_quiz14">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                  }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/quizes/python_quiz16">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              </h2>
              <h1 class="start content ui left floated header">
                Dictionaries
              </h1>
              <div className="ui clearing divider"></div>
            

            <div class="main-body">
              <h2 class="ui left floated header">
                Quiz # 15
              </h2>
              <span class="tut-btn"><Link to="/python/dictionaries">
                  <button class="ui secondary button">Read Tutorial</button>
                </Link></span>
              <div class="ui clearing divider"></div>
              <div>
                {questions.map((question, i) => (
                  <div key={question.id}>
                    <div class="con">
                    <div class="que">Q{i+1}:<span class="one-per-left-margin">{question.question}</span></div>
                      {question.options.map((option) => (
                        <label class="label" key={option}>
                          <input
                            type="radio"
                            name={`question_${question.id}`}
                            value={option}
                            checked={answers[question.id] === option}
                            onChange={(e) =>
                              handleAnswerChange(question.id, e.target.value)
                            }
                          />
                          <span className="one-per-left-margin">{option}</span>
                          <br />
                        </label>
                      ))}
                    </div>
                    <hr />
                  </div>
                ))}<br/>
                <center><button class="ui primary button" onClick={handleQuizSubmit}>Submit Quiz</button></center>

                {/* Modal for displaying results */}
                <Modal class="modal" isOpen={isModalOpen} onRequestClose={closeModal}>
                  <b style={{fontSize:'20px'}}>Quiz Results</b>
                  <b><p>Your score is {correctCount}/{questions.length} </p></b>
                  {questions.map((question,i) => (
                    <div key={question.id}>
                      <p>Q{i+1}: {question.question}</p>
                      <p><b>Correct Answer:</b> {question.correctAnswer}</p>
                      <p className={`${question.correctAnswer}` === `${answers[question.id]}` ? "correct" : "wrong"}>
                        <b>Your Answer:</b> {answers[question.id] || "Not answered"}
                      </p>
                      <hr />
                    </div>
                  ))}
                  <button class="ui secondary button" onClick={closeModal}>Close</button><br /><br />
                </Modal>
              </div>

              <br />
            </div>
            <Link to="/quizes/python_quiz14">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                  }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/quizes/python_quiz16">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
            <br />
          </div>
        </div>
      </Sidebar>

      <div class="sideBarBody">
        <Footer />
      </div>
    </>
  );
};
export default PQuiz15;
