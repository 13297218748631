import { useRef, useEffect } from "react";
// import "./Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Sidebar from "../CSideBar";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const CC14 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };
  const code0 = `#include <stdio.h>

int main() {
  int age = 20;
  if( age > 18 ){
    printf("You are above 18\\n");
  }
    
  return 0;
}`;
  const codeif = `if(condition){...}`;
  const code1 = `if ( condition ){
  //code
}
else{
  //code
}`;
  const code2 = `#include <stdio.h>

int main() {
  int num = 15;
  
  if (num % 2 == 0) {
    printf("%d is even.\\n", num);
  } else {
    printf("%d is odd.\\n", num);
  }
  
  return 0;
}`;
  const code3 = `#include <stdio.h>

int main() {
  int num = 15;
  
  if (num == 15) {
    printf("%d = 15.\\n", num);
  } 
  else if (num < 15){
    printf("%d < 15.\\n", num);
  }
  else{
    printf("%d > 15.\\n", num);
  }
  
  return 0;
}`;
  const code4 = `#include <stdio.h>

int main() {
  int a=10, b=20, c=30;

  if( a<b && a<c ){
    printf("a is less than b and c\\n");
  }
  else{
    printf("a is not the smallest\\n");
  }
  if( a==10 || b==10 ){
    printf("a = 10 or b =  10\\n");
  }
  
  return 0;
}`;
  const code5 = `#include <stdio.h>

int main() {
  int a = 100, b = 20, c = 30;

  if (a > b) {
    if (a > c) {
      printf("a is the largest\\n");
    }
  } else {
    printf("a is not the largest\\n");
  }

  return 0;
}`;
  const code6 = `if(condition){...}
if(condition){...}
else{...}`;
  const code7 = `type var_name = Condition ? if true : if false`;
  const code8 = `#include <stdio.h>

int main() {
  int a = 200, b = 20;
  const char* res = a > b ? "a > b" : "a < b";
  printf("%s\\n", res);
  return 0;
}`;
  const code9 = `int main() {
  marker:
    //code
  goto marker;
    //code
}`;
  const code10 = `#include <stdio.h>

int main() {
  int age;

  age_entry:
  printf("Enter your age: ");
  scanf("%d", &age);

  if (age >= 18) {
    printf("You are an adult\\n");
  } else {
    printf("You are not an adult\\n");
    goto age_entry;
  }

  return 0;
}`;
  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/C/booleans">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/C/switch">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body ">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Conditional Statements:
              </h2>
              <div class="ui clearing divider"></div>
              <p>
                In C, the conditional statement is used to control the flow of a
                program based on specified conditions. It allows us to execute
                different blocks of code depending on whether the provided
                condition is true or false.
              </p>
              <br />
              We use <b>if</b>, <b>if-else</b>, <b>else-if</b> conditional
              statements. <br />
              <br />
              <p style={{ backgroundColor: "#FBEEAC", padding: "10px" }}>
                <b>Note : </b>Comparison operators are used to check the condition.
              </p>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                If statement:
              </h2>
              <div class="ui clearing divider"></div>
              If statement executes the block of code if the provided condition
              is true. <br />
              <br />
              <SyntaxHighlighter language="c" style={darcula}>
                {codeif}
              </SyntaxHighlighter>
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>if-else.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code0}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                If else:
              </h2>
              <div class="ui clearing divider"></div>
              In if else statement, if the provided condition is true, if block
              will be executed otherwise else block is executed.
              <br />
              <br />
              <SyntaxHighlighter language="c" style={darcula}>
                {code1}
              </SyntaxHighlighter>
              <br />
              <p style={{ backgroundColor: "#FBEEAC", padding: "10px" }}>
                <b>Note: </b>
                <br />
                <ul class="ui unordered list">
                  <li>We would have to provide condition for if statement.</li>
                  <li>We do not need to provide condition for else statement.</li>
                </ul>
              </p>
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>if-else.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code2}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Else if:
              </h2>
              <div class="ui clearing divider"></div>
              We use else if statement when we want to check multiple
              conditions. If the particular condition is true, the relevant
              block of code is executed. If all the conditions are false, else
              block is executed. <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>if-else.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code3}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                If else with logical operators:
              </h2>
              <div class="ui clearing divider"></div>
              We can use logical operators if we want to check multiple
              conditions under single if statement.
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>if-else.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code4}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <span>
                In this way, you can use any comparison operator with
                conditional statements to check conditions.
              </span>
              <br />
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Nested if else:
              </h2>
              <div class="ui clearing divider"></div>
              In nested if else, we use if statement within if statement. <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>if-else.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code5}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Dangling else Grammar:
              </h2>
              <div class="ui clearing divider"></div>
              If you are using multiple if statements and single else statement,
              then there is dangling else problem. <br />
              <SyntaxHighlighter language="c" style={darcula}>
                {code6}
              </SyntaxHighlighter>
              Compiler does not know that with which if, else will go. <br />
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Ternary Operator:
              </h2>
              <div class="ui clearing divider"></div>
              It is also used to check condition like if else. It is a single
              lined statement. It is also called conditional
              operator.
              <br />
              <br />
              <SyntaxHighlighter language="c" style={darcula}>
                {code7}
              </SyntaxHighlighter>
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>if-else.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code8}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Goto Statement:
              </h2>
              <div class="ui clearing divider"></div>
              As name indicates goto statement is used to go to a particular
              line of code. We mark a line of code and at some point, we
              write "goto marker" and our control jumps to that marker. <br />
              <br />
              <div ref={divRef}>
                <SyntaxHighlighter language="c" style={darcula}>
                  {code9}
                </SyntaxHighlighter>
              </div>
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>goto.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code10}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <p style={{ backgroundColor: "#FBEEAC", padding: "10px" }}>
                <b>Note: </b>We can use ternary operator when logic is simple.
                It makes your code more compact and easy to use. <br />
              </p>
            </div>
            <br />
            <Link to="/C/booleans">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/C/switch">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default CC14;
