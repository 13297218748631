import { useState, useEffect } from "react";
import Sidebar from "./SideBar";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
// import "./Quiz.css";
import "../../Components/Chapters/Chapters.css";
import Modal from "react-modal";

const PQuiz9 = () => {
  const [answers, setAnswers] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const handleQuizSubmit = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const questions = [
    {
      id: 1,
      question: "  What is the data type of a variable that stores text data or a sequence of characters in Python:",
      options: ["int", "float", "str", "Complex"],
      correctAnswer: "str",
    },
    {
      id: 2,
      question: "  How can you concatenate two strings in Python:",
      options: ["Using the * operator", "Using the join operator", "Using the + operator", "Using the - operator"],
      correctAnswer: "Using the + operator",
    },
    {
      id: 3,
      question: "  What is the purpose of using multi-line strings in Python:",
      options: [
        "To store multiple integers",
        "To store a sequence of characters",
        "To store text data with line breaks",
        "To store only single characters",
      ],
      correctAnswer: "To store text data with line breaks",
    },
    {
        id: 4,
        question: "  How can you check if a specific text is present in a string in Python:",
        options: ["Using the find method", "Using the index method", "Using the in keyword", "Using the contains method"],
        correctAnswer: "Using the in keyword",
      },
      {
        id: 5,
        question: "  Which string method is used to convert a string into title case, where the first character of each word is in uppercase:",
        options: ["upper()", "capitalize()", "title()", "swapcase()"],
        correctAnswer: "title()",
      },
      {
        id: 6,
        question: "  What does the strip method do in Python:",
        options: ["Removes leading and trailing characters from a string", "Replaces specified characters with another character", "Converts the string into lowercase", "Splits the string into a list of substrings"],
        correctAnswer: "Removes leading and trailing characters from a string",
      },
      {
        id: 7,
        question: "  How do you access individual characters in a string in Python:",
        options: ["Using the substring method", "Using the characterAt function", "Using an index number inside square brackets", "Using the charAt method"],
        correctAnswer: "Using an index number inside square brackets",
      },
      {
        id: 8,
        question: "  Which method is used to split a string into a list of substrings based on a specified separator:",
        options: ["split()", "slice()", "concat()", "join()"],
        correctAnswer: "split()",
      },
      {
        id: 9,
        question: "  Which method is used to replace a substring with another substring in a string:",
        options: ["replace()", "find()", "insert()", "swap()"],
        correctAnswer: "replace()",
      },
      {
        id: 10,
        question: "  What is the purpose of using string formatting in Python:",
        options: ["To convert a string into a sequence of bytes", "To check if a string is empty or not", "To generate dynamic data from variables", "To find the index of a character in a string"],
        correctAnswer: "To generate dynamic data from variables",
      },
  ];
  const handleAnswerChange = (questionId, selectedAnswer) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: selectedAnswer,
    }));
  };

  const calculateScore = () => {
    let correctCount = 0;
    let incorrectCount = 0;

    questions.forEach((question) => {
      const userAnswer = answers[question.id];
      if (userAnswer === question.correctAnswer) {
        correctCount++;
      } else {
        incorrectCount++;
      }
    });

    return { correctCount, incorrectCount };
  };

  const { correctCount, incorrectCount } = calculateScore();
  return (
    <>
      <Sidebar>
        <div class="container">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/quizes/python_quiz8">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                  }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/quizes/python_quiz10">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              </h2>
              <h1 class="start content ui left floated header">
                Strings
              </h1>
              <div className="ui clearing divider"></div>
            

            <div class="main-body">
              <h2 class="ui left floated header">
                Quiz # 9
              </h2>
              <span class="tut-btn"><Link to="/python/strings">
                  <button class="ui secondary button">Read Tutorial</button>
                </Link></span>
              <div class="ui clearing divider"></div>
              <div>
                {questions.map((question, i) => (
                  <div key={question.id}>
                    <div class="con">
                    <div class="que">Q{i+1}:<span class="one-per-left-margin">{question.question}</span></div>
                      {question.options.map((option) => (
                        <label class="label" key={option}>
                          <input
                            type="radio"
                            name={`question_${question.id}`}
                            value={option}
                            checked={answers[question.id] === option}
                            onChange={(e) =>
                              handleAnswerChange(question.id, e.target.value)
                            }
                          />
                          <span className="one-per-left-margin">{option}</span>
                          <br />
                        </label>
                      ))}
                    </div>
                    <hr />
                  </div>
                ))}<br/>
                <center><button class="ui primary button" onClick={handleQuizSubmit}>Submit Quiz</button></center>

                {/* Modal for displaying results */}
                <Modal class="modal" isOpen={isModalOpen} onRequestClose={closeModal}>
                  <b style={{fontSize:'20px'}}>Quiz Results</b>
                  <b><p>Your score is {correctCount}/{questions.length} </p></b>
                  {questions.map((question,i) => (
                    <div key={question.id}>
                      <p>Q{i+1}: {question.question}</p>
                      <p><b>Correct Answer:</b> {question.correctAnswer}</p>
                      <p className={`${question.correctAnswer}` === `${answers[question.id]}` ? "correct" : "wrong"}>
                        <b>Your Answer:</b> {answers[question.id] || "Not answered"}
                      </p>
                      <hr />
                    </div>
                  ))}
                  <button class="ui secondary button" onClick={closeModal}>Close</button><br /><br />
                </Modal>
              </div>

              <br />
            </div>
            <Link to="/quizes/python_quiz8">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                  }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/quizes/python_quiz10">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
            <br />
          </div>
        </div>
      </Sidebar>

      <div class="sideBarBody">
        <Footer />
      </div>
    </>
  );
};
export default PQuiz9;
