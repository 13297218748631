import { useRef, useEffect } from "react";
// import "./Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Sidebar from "../CSideBar";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const CC15 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor:'#D8D9DA'
  };
  const code1 = `switch(expression) {
  case x:
    // code block
    break;
  case y:
    // code block
    break;
  default:
    // code block
}`;
  const code2 = `#include <stdio.h>

int main() {
  int num = 19;
      
  switch (num) {
    case 1:
      printf("Number is 1.\\n");
      break;
    case 2:
      printf("Number is 2.\\n");
      break;
    default:
      printf("Number is neither 1 nor 2.\\n");
  }
      
    return 0;
}`;
  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/C/if_else">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/C/for_loop">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body ">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Switch Statements:
              </h2>
              <div class="ui clearing divider"></div>
              <p>
                A switch statement is used as a control flow statement found in many
                programming languages. It provides a way to select and execute
                different code blocks based on the value of a given expression.
              </p>
              <br />
              The switch statement matches the value with the expression provided in the 
               parentheses and executes a block of code. It is like if statement. It can be used 
               as an alternative of if statement.
               <br /><br />
               <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Syntax:
              </h2>
              <div class="ui clearing divider"></div>
                <SyntaxHighlighter language="c" style={darcula}>
                  {code1}
                </SyntaxHighlighter>
              
              <br />
              <p style={{ backgroundColor: "#FBEEAC", padding: "10px" }}>
                  <b>Note: </b>Do not forget to put break after every case. 
              </p><br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Description:
              </h2>
              <div class="ui clearing divider"></div>
              <ol className="ui ordered list">
                <li>
                The switch statement begins with the keyword switch, followed
              by an expression enclosed in parentheses. This expression is often
              referred as the "selector" or "switch variable.".</li> <br />
              <li>
              After the opening curly brace. you define individual cases
              using the case keyword, followed by a constant value or expression
              that represents a specific case to be matched. 
              </li>
              <br />
              <li>
              Each case block code consists of one or more statements that will be
              executed if the corresponding case matches with the value of the
              selector expression. The statements within each case are
               indented to make it readable.</li> <br />
               <li>
               At the end of each case, it is essential to include the
              break statement to exit the switch block. Without the break
              statement, execution will continue to the next case, which may not
              be the desired behavior.</li> <br />
              <li>
              Optionally, we can include a default case, which is executed
              when none of the previous cases match the value of the selector
              expression. It acts as a default fallback option when no specific case
              matches.</li> <br />
              <li>
              The closing curly brace marks the end of the switch statement.
              </li>
              </ol>
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>switch.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code2}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <p style={{ backgroundColor: "#FBEEAC", padding: "10px" }}>
                <b>Note: </b>
                
                Default case is optional. It is executed when no case is
                executed. There is no need to add a break after the default case.
              </p>
              
            </div><br />
            <Link to="/C/if_else">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/C/for_loop">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default CC15;
