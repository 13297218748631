import { useRef, useEffect } from "react";
// import "./Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Sidebar from "../CSideBar";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const CC12 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };

  const code0 = `#include <math.h>`;

  const code1 = `#include <stdio.h>
#include <math.h>
  
int main() {
  int num = 16;
  int result = sqrt(num);
  printf("Square root is %d", result);
  return 0;
}`;
  const code2 = `double result = pow(base, exponent);`;
  const code3 = `double sinValue = sin(x);`;
  const code4 = `double cosValue = cos(x);`;
  const code5 = `double tanValue = tan(x);`;
  const code6 = `double naturalLog = log(x);`;
  const code7 = `double naturalLog = log10(x);`;
  const code8 = `double absoluteValue = fabs(x);`;
  const code9 = `double ceilValue = ceil(x);`;
  const code10 = `double floorValue = floor(x);`;
  const code11 = `double roundedValue = round(x);`;
  const code12 = `double minValue = fmin(num1, num2);`;
  const code13 = `double maxValue = fmax(num1, num2);`;

  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/C/operators">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/C/booleans">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
            </h2>
            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                C Maths:
              </h2>
              <div class="ui clearing divider"></div>
              In C language, we can use math library and perform various
              operations. This library provides us a wide range of mathematical
              functions for tasks such as trigonometry, logarithms,
              exponentiation, and many more. This library provides a set of
              mathematical functions that can be used for various mathematical
              operations.
              <br />
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Usage:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                To use functions from math.h library, we need to include the
                header file at the top of the program using #include directive.
              </span>
              <br />
              <SyntaxHighlighter language="c" style={darcula}>
                {code0}
              </SyntaxHighlighter>
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>maths.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code1}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Functions in Math Library:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                There are different functions which are already defined in
                math.h Library. We just have to call them in our code.
                Performing these functions by yourself can be challenging for us
                and will be time consuming. Therefore, we use these libraries to
                save our time and to reduce the chance of errors.
              </span>
              <br />
              <br />
              <table className="ui very padded table">
                <thead>
                  <tr>
                    <th>Function</th>
                    <th>Description</th>
                    <th>Code</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>pow(x, y)</td>
                    <td>It takes the power of x raised to y.</td>
                    <td>
                      <SyntaxHighlighter language="c" style={darcula}>
                        {code2}
                      </SyntaxHighlighter>
                    </td>
                  </tr>
                  <tr>
                    <td>sin(x)</td>
                    <td>It calculates sin of x.</td>
                    <td>
                      <SyntaxHighlighter language="c" style={darcula}>
                        {code3}
                      </SyntaxHighlighter>
                    </td>
                  </tr>
                  <tr>
                    <td>cos(x)</td>
                    <td>It calculates cos of x.</td>
                    <td>
                      <SyntaxHighlighter language="c" style={darcula}>
                        {code4}
                      </SyntaxHighlighter>
                    </td>
                  </tr>
                  <tr>
                    <td>tan(x)</td>
                    <td>It calculates tan of x.</td>
                    <td>
                      <SyntaxHighlighter language="c" style={darcula}>
                        {code5}
                      </SyntaxHighlighter>
                    </td>
                  </tr>
                  <tr>
                    <td>log(x)</td>
                    <td>It calculates natural log of x.</td>
                    <td>
                      <SyntaxHighlighter language="c" style={darcula}>
                        {code6}
                      </SyntaxHighlighter>
                    </td>
                  </tr>
                  <tr>
                    <td>log10(x)</td>
                    <td>It calculates log of x with base 10.</td>
                    <td>
                      <SyntaxHighlighter language="c" style={darcula}>
                        {code7}
                      </SyntaxHighlighter>
                    </td>
                  </tr>
                  <tr>
                    <td>fabs(x)</td>
                    <td>
                      It calculates absolute value of floating point numbers.
                    </td>
                    <td>
                      <SyntaxHighlighter language="c" style={darcula}>
                        {code8}
                      </SyntaxHighlighter>
                    </td>
                  </tr>
                  <tr>
                    <td>ceil(x)</td>
                    <td>It rounds a number up to a nearest integer.</td>
                    <td>
                      <SyntaxHighlighter language="c" style={darcula}>
                        {code9}
                      </SyntaxHighlighter>
                    </td>
                  </tr>
                  <tr>
                    <td>floor(x)</td>
                    <td>It rounds a number down to a nearest integer.</td>
                    <td>
                      <SyntaxHighlighter language="c" style={darcula}>
                        {code10}
                      </SyntaxHighlighter>
                    </td>
                  </tr>
                  <tr>
                    <td>round(x)</td>
                    <td>
                      It rounds a floating point number to a nearest integer.
                    </td>
                    <td>
                      <SyntaxHighlighter language="c" style={darcula}>
                        {code11}
                      </SyntaxHighlighter>
                    </td>
                  </tr>
                  <tr>
                    <td>fmin(x, y)</td>
                    <td>
                      It returns the minimum value among two floating point
                      numbers.
                    </td>
                    <td>
                      <SyntaxHighlighter language="c" style={darcula}>
                        {code12}
                      </SyntaxHighlighter>
                    </td>
                  </tr>
                  <tr>
                    <td>fmax(x, y)</td>
                    <td>
                      It returns the maximum value among two floating point
                      numbers.
                    </td>
                    <td>
                      <SyntaxHighlighter language="c" style={darcula}>
                        {code13}
                      </SyntaxHighlighter>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p style={{ backgroundColor: "#FBEEAC", padding: "10px" }}>
                <b>Note: </b>Before using any function of math.h Library, do not
                forget to include math.h.
              </p>
            </div>
            <br />
            <Link to="/C/operators">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/C/booleans">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default CC12;
