import React, { useState, useRef, useEffect } from "react";
import Sidebar from "./SideBar";
import "../../Components/SideBar/App.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const CodeAccordion = ({ questions, code, toggleAccordion, changeCodeLanguage }) => {
    const divRef = useRef(null);
    const copyContent = () => {
        const divContent = divRef.current.innerText;
        navigator.clipboard
          .writeText(divContent)
          .then(() => {
            toast.success("Content copied!", { position: "top-center" });
          })
          .catch((error) => {
            toast.error("Error copying content:", error, {
              position: "top-center",
            });
          });
      };
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };

  return (
    <div>
      {questions.map((item, index) => (
        <div key={index} className="border p-2 rounded-md">
          <div className="flex justify-between">
            <span className="text-lg font-semibold">
             {item.question}
            </span>
            <button
              className="bg-gray-200 px-2 py-1 rounded-md"
              onClick={() => toggleAccordion(index)}
            >
              {item.isOpen ? "-" : "+"}
            </button>
          </div>
          <div className={`mt-2 ${item.isOpen ? "block" : "hidden"}`}>
            <div className="mt-2 flex space-x-4">
              {Object.keys(code).map((language) => (
                <button
                  key={language}
                  onClick={() => changeCodeLanguage(index, language)}
                  className={`${
                    item.codeLanguage === language
                      ? "bg-blue-500 hover:bg-blue-600 text-white"
                      : "bg-gray-200 hover:bg-gray-300 text-gray-700"
                  } px-3 py-2 rounded-md transition duration-300 ease-in-out transform hover:scale-105`}
                >
                  {language}
                </button>
              ))}
            </div>
            <p className="text-gray-800">
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>output.cpp</b>
                  <i
                    className="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language={`${item.codeLanguage}`} style={darcula}>
                    {code[item.codeLanguage]}
                  </SyntaxHighlighter>
                </div>
              </div>
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

const Question12 = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
    const code = {
        c: `// C code
#include <stdio.h>

struct Student {
    char name[50];
    int rollNumber;
    float marks[3];
};

int main() {
    struct Student students[5];
    
    for (int i = 0; i < 5; i++) {
        printf("Enter details for Student %d:\\n", i + 1);
        printf("Name: ");
        scanf("%s", students[i].name);
        printf("Roll Number: ");
        scanf("%d", &students[i].rollNumber);
        printf("Marks in three subjects: ");
        scanf("%f %f %f", &students[i].marks[0], &students[i].marks[1], &students[i].marks[2]);
    }

    printf("\\nStudent Details:\\n");
    for (int i = 0; i < 5; i++) {
        printf("Name: %s\\n", students[i].name);
        printf("Average Marks: %.2f\\n", (students[i].marks[0] + students[i].marks[1] + students[i].marks[2]) / 3);
    }

    return 0;
}`,
        cpp: `// C++ code
#include <iostream>
using namespace std;

struct Student {
    string name;
    int rollNumber;
    float marks[3];
};

int main() {
    Student students[5];

    for (int i = 0; i < 5; i++) {
        cout << "Enter details for Student " << i + 1 << ":" << endl;
        cout << "Name: ";
        cin >> students[i].name;
        cout << "Roll Number: ";
        cin >> students[i].rollNumber;
        cout << "Marks in three subjects: ";
        cin >> students[i].marks[0] >> students[i].marks[1] >> students[i].marks[2];
    }

    cout << "\\nStudent Details:" << endl;
    for (int i = 0; i < 5; i++) {
        cout << "Name: " << students[i].name << endl;
        float averageMarks = (students[i].marks[0] + students[i].marks[1] + students[i].marks[2]) / 3;
        cout << "Average Marks: " << averageMarks << endl;
    }

    return 0;
}`,
        
      };
      const code1 = {
        c: `// C code
#include <stdio.h>
#include <string.h>

struct Employee {
    char name[50];
    int employeeID;
    float salary;
};

int main() {
    struct Employee employees[10];
    
    // Input details for 10 employees
    for (int i = 0; i < 10; i++) {
        printf("Enter details for Employee %d:\\n", i + 1);
        printf("Name: ");
        scanf("%s", employees[i].name);
        printf("Employee ID: ");
        scanf("%d", &employees[i].employeeID);
        printf("Salary: ");
        scanf("%f", &employees[i].salary);
    }

    // Find the employee with the highest salary
    float highestSalary = employees[0].salary;
    int highestSalaryEmployeeIndex = 0;

    for (int i = 1; i < 10; i++) {
        if (employees[i].salary > highestSalary) {
            highestSalary = employees[i].salary;
            highestSalaryEmployeeIndex = i;
        }
    }

    // Display details of the employee with the highest salary
    printf("\\nEmployee with the Highest Salary:\\n");
    printf("Name: %s\\n", employees[highestSalaryEmployeeIndex].name);
    printf("Employee ID: %d\\n", employees[highestSalaryEmployeeIndex].employeeID);
    printf("Salary: %.2f\\n", employees[highestSalaryEmployeeIndex].salary);

    return 0;
}`,
        cpp: `// C++ code
#include <iostream>
#include <string>
using namespace std;

struct Employee {
    string name;
    int employeeID;
    float salary;
};

int main() {
    Employee employees[10];
    
    // Input details for 10 employees
    for (int i = 0; i < 10; i++) {
        cout << "Enter details for Employee " << i + 1 << ":" << endl;
        cout << "Name: ";
        cin >> employees[i].name;
        cout << "Employee ID: ";
        cin >> employees[i].employeeID;
        cout << "Salary: ";
        cin >> employees[i].salary;
    }

    // Find the employee with the highest salary
    float highestSalary = employees[0].salary;
    int highestSalaryEmployeeIndex = 0;

    for (int i = 1; i < 10; i++) {
        if (employees[i].salary > highestSalary) {
            highestSalary = employees[i].salary;
            highestSalaryEmployeeIndex = i;
        }
    }

    // Display details of the employee with the highest salary
    cout << "\\nEmployee with the Highest Salary:" << endl;
    cout << "Name: " << employees[highestSalaryEmployeeIndex].name << endl;
    cout << "Employee ID: " << employees[highestSalaryEmployeeIndex].employeeID << endl;
    cout << "Salary: " << employees[highestSalaryEmployeeIndex].salary << endl;

    return 0;
}`,
        
      };
      const code2 = {
        c: `// C code
#include <stdio.h>

struct Time {
    int hours;
    int minutes;
    int seconds;
};

int main() {
    struct Time time;

    printf("Enter time in seconds: ");
    int totalSeconds;
    scanf("%d", &totalSeconds);

    time.hours = totalSeconds / 3600;
    totalSeconds %= 3600;
    time.minutes = totalSeconds / 60;
    time.seconds = totalSeconds % 60;

    printf("Time: %02d:%02d:%02d\\n", time.hours, time.minutes, time.seconds);

    return 0;
}`,
        cpp: `// C++ code
#include <iostream>
using namespace std;

struct Time {
    int hours;
    int minutes;
    int seconds;
};

int main() {
    Time time;

    cout << "Enter time in seconds: ";
    int totalSeconds;
    cin >> totalSeconds;

    time.hours = totalSeconds / 3600;
    totalSeconds %= 3600;
    time.minutes = totalSeconds / 60;
    time.seconds = totalSeconds % 60;

    cout << "Time: " << setfill('0') << setw(2) << time.hours << ":"
              << setfill('0') << setw(2) << time.minutes << ":"
              << setfill('0') << setw(2) << time.seconds << endl;

    return 0;
}`,
        
      };
      const code3 = {
        c: `// C code
#include <stdio.h>
#include <string.h>

struct Book {
    char title[100];
    char author[100];
    float price;
};

int main() {
    int numBooks;
    printf("Enter the number of books: ");
    scanf("%d", &numBooks);

    struct Book books[numBooks];

    for (int i = 0; i < numBooks; i++) {
        printf("Enter details for Book %d:\\n", i + 1);
        printf("Title: ");
        scanf(" %[^\\n]", books[i].title);
        printf("Author: ");
        scanf(" %[^\\n]", books[i].author);
        printf("Price: ");
        scanf("%f", &books[i].price);
    }

    float specifiedPrice;
    printf("Enter the specified price: ");
    scanf("%f", &specifiedPrice);

    printf("\\nBooks with price greater than %.2f:\\n", specifiedPrice);
    for (int i = 0; i < numBooks; i++) {
        if (books[i].price > specifiedPrice) {
            printf("Title: %s\\n", books[i].title);
            printf("Author: %s\\n", books[i].author);
            printf("Price: %.2f\\n", books[i].price);
        }
    }

    return 0;
}`,
        cpp: `// C++ code
#include <iostream>
#include <string>
using namespace std;

struct Book {
    string title;
    string author;
    float price;
};

int main() {
    int numBooks;
    cout << "Enter the number of books: ";
    cin >> numBooks;

    Book books[numBooks];

    for (int i = 0; i < numBooks; i++) {
        cout << "Enter details for Book " << i + 1 << ":" << endl;
        cout << "Title: ";
        cin.ignore(); // Clear the newline character from the previous input
        getline(cin, books[i].title);
        cout << "Author: ";
        getline(cin, books[i].author);
        cout << "Price: ";
        cin >> books[i].price;
    }

    float specifiedPrice;
    cout << "Enter the specified price: ";
    cin >> specifiedPrice;

    cout << "\\nBooks with price greater than " << specifiedPrice << ":" << endl;
    for (int i = 0; i < numBooks; i++) {
        if (books[i].price > specifiedPrice) {
            cout << "Title: " << books[i].title << endl;
            cout << "Author: " << books[i].author << endl;
            cout << "Price: " << books[i].price << endl;
        }
    }

    return 0;
}`,
        
      };
      const code4 = {
        c: `// C code
#include <stdio.h>

union Temperature {
    float celsius;
    float fahrenheit;
};

int main() {
    union Temperature temp;

    printf("Enter a temperature value: ");
    float temperature;
    scanf("%f", &temperature);

    char unit;
    printf("Enter the temperature unit (C for Celsius, F for Fahrenheit): ");
    scanf(" %c", &unit);

    if (unit == 'C' || unit == 'c') {
        temp.celsius = temperature;
        temp.fahrenheit = (temp.celsius * 9 / 5) + 32;
        printf("Temperature in Celsius: %.2f\\n", temp.celsius);
        printf("Temperature in Fahrenheit: %.2f\\n", temp.fahrenheit);
    } else if (unit == 'F' || unit == 'f') {
        temp.fahrenheit = temperature;
        temp.celsius = (temp.fahrenheit - 32) * 5 / 9;
        printf("Temperature in Fahrenheit: %.2f\\n", temp.fahrenheit);
        printf("Temperature in Celsius: %.2f\\n", temp.celsius);
    } else {
        printf("Invalid temperature unit. Please enter 'C' or 'F'.\\n");
    }

    return 0;
}`,
        cpp: `// C++ code
#include <iostream>
using namespace std;

union Temperature {
    float celsius;
    float fahrenheit;
};

int main() {
    Temperature temp;

    cout << "Enter a temperature value: ";
    float temperature;
    cin >> temperature;

    char unit;
    cout << "Enter the temperature unit (C for Celsius, F for Fahrenheit): ";
    cin >> unit;

    if (unit == 'C' || unit == 'c') {
        temp.celsius = temperature;
        temp.fahrenheit = (temp.celsius * 9 / 5) + 32;
        cout << "Temperature in Celsius: " << temp.celsius << endl;
        cout << "Temperature in Fahrenheit: " << temp.fahrenheit << endl;
    } else if (unit == 'F' || unit == 'f') {
        temp.fahrenheit = temperature;
        temp.celsius = (temp.fahrenheit - 32) * 5 / 9;
        cout << "Temperature in Fahrenheit: " << temp.fahrenheit << endl;
        cout << "Temperature in Celsius: " << temp.celsius << endl;
    } else {
        cout << "Invalid temperature unit. Please enter 'C' or 'F'." << endl;
    }

    return 0;
}`,
        
      };
      const code5 = {
        c: `// C code
#include <stdio.h>

union Result {
    float mathematics;
    float science;
};

struct Student {
    char name[50];
    int rollNumber;
    union Result result;
};

int main() {
    struct Student students[5];

    for (int i = 0; i < 5; i++) {
        printf("Enter details for Student %d:\\n", i + 1);
        printf("Name: ");
        scanf("%s", students[i].name);
        printf("Roll Number: ");
        scanf("%d", &students[i].rollNumber);

        char choice;
        printf("Enter 'M' for mathematics or 'S' for science: ");
        scanf(" %c", &choice);

        if (choice == 'M' || choice == 'm') {
            printf("Marks in Mathematics: ");
            scanf("%f", &students[i].result.mathematics);
        } else if (choice == 'S' || choice == 's') {
            printf("Marks in Science: ");
            scanf("%f", &students[i].result.science);
        } else {
            printf("Invalid choice. Please enter 'M' or 'S'.\\n");
            i--;  // Decrement i to re-enter details for the same student
        }
    }

    printf("\\nStudent Details:\\n");
    for (int i = 0; i < 5; i++) {
        printf("Name: %s\\n", students[i].name);
        printf("Roll Number: %d\\n", students[i].rollNumber);
        char choice;
        printf("Enter 'M' for mathematics or 'S' for science: ");
        scanf(" %c", &choice);

        if (choice == 'M' || choice == 'm') {
            printf("Marks in Mathematics: %.2f\\n", students[i].result.mathematics);
        } else if (choice == 'S' || choice == 's') {
            printf("Marks in Science: %.2f\\n", students[i].result.science);
        }
    }

    return 0;
}`,
        cpp: `// C++ code
#include <iostream>
using namespace std;

union Result {
    float mathematics;
    float science;
};

struct Student {
    string name;
    int rollNumber;
    Result result;
};

int main() {
    Student students[5];

    for (int i = 0; i < 5; i++) {
        cout << "Enter details for Student " << i + 1 << ":" << endl;
        cout << "Name: ";
        cin >> students[i].name;
        cout << "Roll Number: ";
        cin >> students[i].rollNumber;

        char choice;
        cout << "Enter 'M' for mathematics or 'S' for science: ";
        cin >> choice;

        if (choice == 'M' || choice == 'm') {
            cout << "Marks in Mathematics: ";
            cin >> students[i].result.mathematics;
        } else if (choice == 'S' || choice == 's') {
            cout << "Marks in Science: ";
            cin >> students[i].result.science;
        } else {
            cout << "Invalid choice. Please enter 'M' or 'S'." << endl;
            i--;  // Decrement i to re-enter details for the same student
        }
    }

    cout << "\\nStudent Details:" << endl;
    for (int i = 0; i < 5; i++) {
        cout << "Name: " << students[i].name << endl;
        cout << "Roll Number: " << students[i].rollNumber << endl;
        char choice;
        cout << "Enter 'M' for mathematics or 'S' for science: ";
        cin >> choice;

        if (choice == 'M' || choice == 'm') {
            cout << "Marks in Mathematics: " << students[i].result.mathematics << endl;
        } else if (choice == 'S' || choice == 's') {
            cout << "Marks in Science: " << students[i].result.science << endl;
        }
    }

    return 0;
}`,
        
      };
      const code6 = {
        c: `// C code
#include <stdio.h>

struct Complex {
    double real;
    double imaginary;
};

int main() {
    struct Complex num1, num2, result;

    // Input for the first complex number
    printf("Enter the real part of the first complex number: ");
    scanf("%lf", &num1.real);
    printf("Enter the imaginary part of the first complex number: ");
    scanf("%lf", &num1.imaginary);

    // Input for the second complex number
    printf("Enter the real part of the second complex number: ");
    scanf("%lf", &num2.real);
    printf("Enter the imaginary part of the second complex number: ");
    scanf("%lf", &num2.imaginary);

    // Addition
    result.real = num1.real + num2.real;
    result.imaginary = num1.imaginary + num2.imaginary;

    printf("Addition: %.2lf + %.2lfi\\n", result.real, result.imaginary);

    // Subtraction
    result.real = num1.real - num2.real;
    result.imaginary = num1.imaginary - num2.imaginary;

    printf("Subtraction: %.2lf - %.2lfi\\n", result.real, result.imaginary);

    return 0;
}`,
        cpp: `// C++ code
#include <iostream>
using namespace std;

struct Complex {
    double real;
    double imaginary;
};

int main() {
    Complex num1, num2, result;

    // Input for the first complex number
    cout << "Enter the real part of the first complex number: ";
    cin >> num1.real;
    cout << "Enter the imaginary part of the first complex number: ";
    cin >> num1.imaginary;

    // Input for the second complex number
    cout << "Enter the real part of the second complex number: ";
    cin >> num2.real;
    cout << "Enter the imaginary part of the second complex number: ";
    cin >> num2.imaginary;

    // Addition
    result.real = num1.real + num2.real;
    result.imaginary = num1.imaginary + num2.imaginary;

    cout << "Addition: " << result.real << " + " << result.imaginary << "i" << endl;

    // Subtraction
    result.real = num1.real - num2.real;
    result.imaginary = num1.imaginary - num2.imaginary;

    cout << "Subtraction: " << result.real << " - " << result.imaginary << "i" << endl;

    return 0;
}`,
        
      };
      const code7 = {
        c: `// C code
#include <stdio.h>
#include <math.h>

union Shape {
    struct {
        float radius;
    } circle;
    struct {
        float sideLength;
    } square;
    struct {
        float base;
        float height;
    } triangle;
};

int main() {
    union Shape shape;

    char choice;
    printf("Select a shape (C for Circle, S for Square, T for Triangle): ");
    scanf(" %c", &choice);

    switch (choice) {
        case 'C':
        case 'c':
            printf("Enter the radius of the circle: ");
            scanf("%f", &shape.circle.radius);
            // Calculate and display the area of the circle
            printf("Area of the circle: %.2f\\n", M_PI * shape.circle.radius * shape.circle.radius);
            break;

        case 'S':
        case 's':
            printf("Enter the side length of the square: ");
            scanf("%f", &shape.square.sideLength);
            // Calculate and display the area of the square
            printf("Area of the square: %.2f\\n", shape.square.sideLength * shape.square.sideLength);
            break;

        case 'T':
        case 't':
            printf("Enter the base of the triangle: ");
            scanf("%f", &shape.triangle.base);
            printf("Enter the height of the triangle: ");
            scanf("%f", &shape.triangle.height);
            // Calculate and display the area of the triangle
            printf("Area of the triangle: %.2f\\n", 0.5 * shape.triangle.base * shape.triangle.height);
            break;

        default:
            printf("Invalid choice. Please enter 'C', 'S', or 'T'.\\n");
    }

    return 0;
}`,
        cpp: `// C++ code
#include <iostream>
#include <cmath>
using namespace std;

union Shape {
    struct {
        float radius;
    } circle;
    struct {
        float sideLength;
    } square;
    struct {
        float base;
        float height;
    } triangle;
};

int main() {
    Shape shape;

    char choice;
    cout << "Select a shape (C for Circle, S for Square, T for Triangle): ";
    cin >> choice;

    switch (choice) {
        case 'C':
        case 'c':
            cout << "Enter the radius of the circle: ";
            cin >> shape.circle.radius;
            // Calculate and display the area of the circle
            cout << "Area of the circle: " << M_PI * shape.circle.radius * shape.circle.radius << endl;
            break;

        case 'S':
        case 's':
            cout << "Enter the side length of the square: ";
            cin >> shape.square.sideLength;
            // Calculate and display the area of the square
            cout << "Area of the square: " << shape.square.sideLength * shape.square.sideLength << endl;
            break;

        case 'T':
        case 't':
            cout << "Enter the base of the triangle: ";
            cin >> shape.triangle.base;
            cout << "Enter the height of the triangle: ";
            cin >> shape.triangle.height;
            // Calculate and display the area of the triangle
            cout << "Area of the triangle: " << 0.5 * shape.triangle.base * shape.triangle.height << endl;
            break;

        default:
            cout << "Invalid choice. Please enter 'C', 'S', or 'T'." << endl;
    }

    return 0;
}`,
        
      };

  const [questions, setQuestions] = useState([
    {
      question: "Q1: Create a structure called Student with the following attributes: name, roll number, and marks in three subjects. Write a program that takes input for five students, stores their information in an array of structures, and then displays the name and average marks of each student.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions1, setQuestions1] = useState([
    {
      question: "Q2: Create a structure called Employee with attributes for employee name, employee ID, and salary. Write a program to input details of 10 employees and display the employee with the highest salary.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions2, setQuestions2] = useState([
    {
      question: "Q3: Create a structure called Time with attributes for hours, minutes, and seconds. Write a program that takes input for time in seconds and converts it into hours, minutes, and seconds, then displays the result.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions3, setQuestions3] = useState([
    {
      question: "Q4: Create a structure called Book with attributes for title, author, and price. Write a program that allows the user to input details for a collection of books, stores them in an array of structures, and then displays the details of books with a price greater than a specified value.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions4, setQuestions4] = useState([
    {
      question: "Q5: Create a union called Temperature with attributes for Celsius and Fahrenheit temperatures. Write a program that takes input for a temperature value in either Celsius or Fahrenheit, and store it in the union. Convert and display the temperature in both Celsius and Fahrenheit.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions5, setQuestions5] = useState([
    {
      question: "Q6: Create a structure called Student with attributes for name, roll number, and a union called Result that contains attributes for marks in mathematics and science. Write a program to input details of five students and display the name, roll number, and marks in either mathematics or science, depending on user input.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions6, setQuestions6] = useState([
    {
      question: "Q7: Create a structure called Complex with attributes for real and imaginary parts of a complex number. Write a program to perform addition and subtraction operations on two complex numbers entered by the user.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions7, setQuestions7] = useState([
    {
      question: "Q8: Create a union called Shape that represents different geometric shapes, such as circle, square, and triangle. Each shape should have attributes for its parameters (e.g., radius, side length, base, height). Write a program to calculate and display the area of the selected shape.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  

  const toggleAccordion = (index, questions, setQuestions) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].isOpen = !updatedQuestions[index].isOpen;
    setQuestions(updatedQuestions);
  };

  const changeCodeLanguage = (index, language, questions, setQuestions) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].codeLanguage = language;
    setQuestions(updatedQuestions);
  };

  

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };
  const divRef = useRef(null);
  return (
    <div className="testing">
      <Sidebar>
        <div className="containera">
          <div className="ch-content">
            <h2 className="ui header">
            <Link to="/ques11">
              <button
                className="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
              </Link>
              <Link to="/ques13">
                <button
                  className="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>
            <div className="main-body ui">
              <h2 className="ui left floated header" style={{ color: "#001C30" }}>
                Structures and Unions:
              </h2>
              <div className="ui clearing divider"></div>
              <CodeAccordion
                questions={questions}
                code={code}
                toggleAccordion={(index) => toggleAccordion(index, questions, setQuestions)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions, setQuestions)
                }
              /><br/>
              <CodeAccordion
                questions={questions1}
                code={code1}
                toggleAccordion={(index) => toggleAccordion(index, questions1, setQuestions1)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions1, setQuestions1)
                }
              /><br/>
              <CodeAccordion
                questions={questions2}
                code={code2}
                toggleAccordion={(index) => toggleAccordion(index, questions2, setQuestions2)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions2, setQuestions2)
                }
              /><br/>
              <CodeAccordion
                questions={questions3}
                code={code3}
                toggleAccordion={(index) => toggleAccordion(index, questions3, setQuestions3)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions3, setQuestions3)
                }
              /><br/>
              <CodeAccordion
                questions={questions4}
                code={code4}
                toggleAccordion={(index) => toggleAccordion(index, questions4, setQuestions4)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions4, setQuestions4)
                }
              /><br/>
              <CodeAccordion
                questions={questions5}
                code={code5}
                toggleAccordion={(index) => toggleAccordion(index, questions5, setQuestions5)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions5, setQuestions5)
                }
              /><br/>
              <CodeAccordion
                questions={questions6}
                code={code6}
                toggleAccordion={(index) => toggleAccordion(index, questions6, setQuestions6)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions6, setQuestions6)
                }
              /><br/>
              <CodeAccordion
                questions={questions7}
                code={code7}
                toggleAccordion={(index) => toggleAccordion(index, questions7, setQuestions7)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions7, setQuestions7)
                }
              /><br/>
              
            </div>
            <Link to="/ques11">
              <button
                className="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
              </Link>
              <Link to="/ques13">
                <button
                  className="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default Question12;
