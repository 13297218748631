import { useRef, useEffect } from "react";
import "../../Components/Chapters/Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Sidebar from "../JavaSideBar";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const JavaC27 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };

  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };
  const jc27code1 = `class Animal {
  String name;

  public Animal(String name) {
    this.name = name;
  }

  public void eat() {
    System.out.println(name + " is eating.");
  }
}

class Dog extends Animal {
  public Dog(String name) {
    // Call superclass constructor
    super(name); 
  }

  public void bark() {
    System.out.println(name + " is barking.");
  }
}

public class Main {
  public static void main(String[] args) {
    Dog dog = new Dog("Buddy");
    dog.eat(); // Inherited from Animal
    dog.bark(); // Specific to Dog
  }
}`;
  const jc27code2 = `// Superclass (Base Class)
class Vehicle {
  String brand;
  int year;
  
  public Vehicle(String brand, int year) {
    this.brand = brand;
    this.year = year;
  }
  
  public void start() {
    System.out.println("Starting the vehicle.");
  }
  
  public void stop() {
    System.out.println("Stopping the vehicle.");
  }
}
  
// Subclass (Derived Class)
class Car extends Vehicle {
  int doors;
  
  public Car(String brand, int year, int doors)
  {
    // Call the superclass constructor
    super(brand, year); 
    this.doors = doors;
  }
  
  public void accelerate() {
    System.out.println("Car is accelerating.");
  }
  
  public void brake() {
    System.out.println("Car is braking.");
  }
}
  
public class Main {
  public static void main(String[] args) {
    Car car = new Car("Toyota", 2022, 4);
  
    // Access fields and methods 
    System.out.println("Brand: " + car.brand);
    System.out.println("Year: " + car.year);
    car.start();
    car.stop();
  
    // Access specific fields and methods 
    System.out.println("Doors: " + car.doors);
    car.accelerate();
    car.brake();
    }
}`;
  const jc27code3 = `class Animal {
  String name;

  public Animal(String name) {
    this.name = name;
  }

  public void eat() {
    System.out.println(name + " is eating.");
  }
}

class Mammal extends Animal {
  public Mammal(String name) {
    super(name);
  }

  public void run() {
    System.out.println(name + " is running.");
  }
}

class Dog extends Mammal {
  public Dog(String name) {
    super(name);
  }

  public void bark() {
    System.out.println(name + " is barking.");
  }
}

public class Main {
  public static void main(String[] args) {
    Dog dog = new Dog("Buddy");
    dog.eat(); // Inherited from Animal
    dog.run(); // Inherited from Mammal
    dog.bark(); // Specific to Dog
  }
}`;
  const jc27code4 = `class Animal {
  String name;

  public Animal(String name) {
    this.name = name;
  }

  public void eat() {
    System.out.println(name + " is eating.");
  }
}

class Dog extends Animal {
  public Dog(String name) {
    super(name);
  }

  public void bark() {
    System.out.println(name + " is barking.");
  }
}

class Cat extends Animal {
  public Cat(String name) {
    super(name);
  }

  public void meow() {
    System.out.println(name + " is meowing.");
  }
}

public class Main {
  public static void main(String[] args) {
    Dog dog = new Dog("Buddy");
    Cat myCat = new Cat("Whiskers");

    dog.eat(); // Inherited from Animal
    dog.bark(); // Specific to Dog

    myCat.eat(); // Inherited from Animal
    myCat.meow(); // Specific to Cat
  }
}`;
  const jc27code5 = `// Superclass
class Animal {
  protected String name; // Protected field
  
  public Animal(String name) {
      this.name = name;
  }
  
  protected void makeSound() { 
    System.out.print(name+" makes sound.");
  }
}
  
// Subclass
class Dog extends Animal {
  public Dog(String name) {
      super(name);
  }
  
  public void bark() {
      System.out.println(name + " barks.");
  }
  
  public void interact() {
      // Accessing protected method 
      makeSound(); 
  }
}
  
public class Main {
  public static void main(String[] args) {
    Dog dog = new Dog("Buddy");
  
    // Accessing the protected field 
    //and method from the subclass
    System.out.println("Name: " + dog.name);
    dog.makeSound();
  
    // Using a method in the subclass 
    //that calls the protected method
    dog.interact();
  }
}`;
  const jc27code6 = ``;
  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/java/encapsulation">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/java/interfaces">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body ">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Inheritance:
              </h2>
              <div class="ui clearing divider"></div>
              <p style={{ backgroundColor: "", padding: "" }}>
                Inheritance in Java allows you to create a hierarchy of classes
                where a subclass inherits the properties and behaviors of a
                superclass.
                <br />
                <br />
                <b>Parent Class : </b>The class from which properties are
                inherited is called Parent Class. We also call Parent class as Base or Super Class. <br />
                <b>Child Class : </b>The class which inherits the properties of
                parent class is called Child Class. Child Class is also called
                Sub Class or Derived Class. <br />
                <br />
                <span>
                  Here is a basic layout, how inheritance is implemented. We
                  have to write the keyword "extends" with base class and after that
                  write the name of Parent Class.
                </span>
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>inheritance.java</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="java" style={darcula}>
                      {jc27code1}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
              </p>
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Protected Access Modifier:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                The protected members are not accessible outside the class. They
                are only accessible only in child class.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>inheritance.java</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="java" style={darcula}>
                    {jc27code5}
                  </SyntaxHighlighter>
                </div>
              </div>

              <br />

              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Levels of Inheritance:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                <ul className="ui unordered list">
                  <li>Single level</li>
                  <li>Multi-level</li>
                  <li>Hierarchical</li>
                  <li>Multiple</li>
                  <li>Hybrid</li>
                </ul>
              </span>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Single level Inheritance:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                When one child class inherits attributes or properties or both
                from a parent class, this type of inheritance is called single
                level inheritance.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>inheritance.java</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="java" style={darcula}>
                    {jc27code2}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />

              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Multi-level Inheritance:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                In multi-level inheritance, we have multiple levels of
                inheritance. Multilevel inheritance occurs when a subclass is
                derived from another subclass.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>inheritance.java</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="java" style={darcula}>
                    {jc27code3}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Hierarchical Inheritance:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                In Hierarchical inheritance, we have multiple child classes and
                a single parent class.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>inheritance.java</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="java" style={darcula}>
                    {jc27code4}
                  </SyntaxHighlighter>
                </div>
              </div>

              <br />
              <span>
                In Java, we can not implement multiple and hybrid inheritance
                directly. We can achieve these concepts using interfaces.
              </span>
            </div>
            <p style={{ backgroundColor: "#FBEEAC", padding: "15px" }}>
              <b>Note : </b>Inheritance allow the creation of a class hierarchy, allowing a subclass to inherit the properties and behaviors of a superclass. 
              There are two main classes involved: <br />

<b>Parent Class (Superclass or Base Class) : </b> The class whose properties are inherited. <br />
<b>Child Class (Subclass or Derived Class) : </b> The class that inherits the properties of the base class.
            </p>
            <br />
            <Link to="/java/encapsulation">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/java/interfaces">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default JavaC27;
