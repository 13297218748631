import { useRef, useEffect } from "react";
// import "./Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Sidebar from "../CSideBar";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const CC5 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor:'#D8D9DA'
  };
  const code1 = `#include <stdio.h>

int main() {
  // Printing Hello World! on screen
  printf("Hello World!");
  return 0;
}`;
  const code2 = `#include <stdio.h>

int main() {
  /* Printing Hello World! message on screen
  Telling my friends that I am fine */
  printf("Hello World!\\n");
  printf("I am fine\\n");
  return 0;
}`;

  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
            <Link to="/C/output">
            <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button></Link>
              <Link to="/C/variables">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link><br />
            </h2>

            <div class="main-body ui">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Comments:
              </h2>
              <div class="ui clearing divider"></div>
              <p>
                Comments are used to explain your code and they have nothing to
                do with the compiler. Compiler ignores your comment and do not
                consider them the part of your code. They just make your code
                more readable. <br /><br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
              Why Comments :
              </h2>
              <div class="ui clearing divider"></div>
                <ul className="ui unordered list">
                  <li>
                  If you have written code for a calculator and if you want 
                to tell someone (who reads the code) that what my code is doing, you 
                do this with the help of comments.
                  </li>
                  <li>
                    If you have written code today and you do not know when will you review your code 
                    in future. Maybe you check it after a year. Chances are there that you will forget 
                    that which function is doing which work. You will have to thoroughly go through 
                    your code. But if you write comment for each function like in case of calculator, first function 
                    is doing addition, second one is doing subtraction and so on. In this way you will understand the 
                    code at once.
                  </li>
                  <li>
                    If you do not want some piece of you code, you comment it and it will 
                    be ignored by the compiler. In future, if you need it, you can uncomment it.
                  </li>
                </ul>
              </p>
              <br />
              <span>
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
              Types :
              </h2>
              <div class="ui clearing divider"></div>
              <p>There are basically two types of comments.</p>
              </span>
              <ul class="ui unordered list">
                <li>Single Lined Coments</li>
                <li>Multi Lined Comments</li>
              </ul>
              <span>
                The details about single and multilined comments is provided below.
              </span>
              <br /><br />
              
              
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
              Single Lined Comment:
              </h2>
              <div class="ui clearing divider"></div>
              
              If you want to comment a single line, you wil have to put two forward slashes before that line.<br /><br />
              <div style={containerStyle}>
                <div style={{ paddingLeft:'10px', paddingRight:'10px' }}>
                  <b>singlelinedcmt.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code1}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
              Multi Lined Comment:
              </h2>
              <div class="ui clearing divider"></div>
              If you want to comment multiple lines,
              put /* at the start of the first line and */ at the end of the last line.<br /><br />
              <div style={containerStyle}>
                <div style={{ paddingLeft:'10px', paddingRight:'10px' }}>
                  <b>multilinedcmt.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code2}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
              Some Points about comments :
              </h2>
              <div class="ui clearing divider"></div>
              <p style={{ backgroundColor: "#FBEEAC", padding: "10px" }}>
                
                <ul class="ui unordered list">
                  <li>
                    If you do not want some lines of your code to execute, put
                    them in comments.
                  </li>
                  <li>
                    To comment a line, click on that line to place cursor on
                    that line and then press "Ctrl + /".
                  </li>
                  <li>
                    Comments are just used to increase the readability of your
                    code.
                  </li>
                </ul>
              </p>
              
            </div><br />
            <Link to="/C/output">
            <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button></Link>
              <Link to="/C/variables">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default CC5;
