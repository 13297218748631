import { useRef, useEffect } from "react";
import "../../Components/Chapters/Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "../PythonSideBar";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

const PythonC17 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };

  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };
  const pc17code1 = `count = 0
while count < 5:
    print("Count:", count)
    count += 1    #count = count + 1`;
  const pc17code2 = `count = 1
while count < 5:
  print(count)
  count += 1    #count = count + 1
else:
  print("Value of count is 5")`;

  return (
    <div class="testing">
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/python/for_loop">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/python/booleans">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                While Loops:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                In Python, a while loop is used to repeatedly execute a block of
                code as long as a certain condition remains true. The loop
                continues iterating until the condition evaluates to False. We
                use a counter variable which changes in every iteration and the
                condition is checked on the base of this variable.
              </span>
              <br />
              <br />

              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>whileLoop.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc17code1}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h1>In this example;</h1>
              <br />
              <span>
                <ul className="ui unordered list">
                  <li>
                    In the first line, we have assigned 0 to the variable named
                    "count".
                  </li>
                  <li>
                    Then in the second line, we check if count is less than 5
                    (which is true).
                  </li>
                  <li>
                    If true, we enter the block of while loop. Inside while
                    loop, we print using print command.
                  </li>
                  <li>
                    {" "}
                    In the fourth line, we increment count variable. It becomes
                    1. Again the process occurs, it becomes 2 and then 3, 4, and
                    5.
                  </li>
                  <li>
                    When it is 5, count is not less than 5. The condition on
                    line 2 becomes false and it moves out of the while loop and
                    the program terminates.
                  </li>
                </ul>
              </span>
              <br />
              <p style={{ backgroundColor: "#FBEEAC", padding: "15px" }}>
                <b>Note: </b>If the condition is true and it is not becoming
                false, the loop will iterate again and again and never stops.
                The loop will run until the given condition is true. When
                infinite loop starts, you can use ctrl + c to terminate the
                loop.
              </p>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Else Statement with while loop:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                We can also use else while loop. The while statement will not be
                executed until the condition in while loop is true. When the
                condition is false, the else statement is executed.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>whileLoop.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc17code2}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h1>In this example;</h1>

              <ul className="ui unordered list">
                <li>
                  A variable named <b>count</b> is initialized with 1.
                </li>
                <li>
                  While loop starts and the condition is checked. If the value
                  of count is less than 5, then the loop will be executed.
                </li>
                <li>
                  Inside while loop, value of count is printed using print()
                  function. After that the value of <b>i</b> is incremented by
                  1.
                </li>
                <li>
                  When the value of <b>i</b> becomes 5, the condition becomes
                  false and the control moves out of the loop.
                </li>
                <li>
                  Outside the while loop, else statement is encountered. It will
                  print the text written inside the print function.
                </li>
              </ul>

              <p style={{ backgroundColor: "#FBEEAC", padding: "15px" }}>
                <b>Note: </b>The code in the else block is not executed if the
                loop is stopped by a break statement.
              </p>
            </div>
            <br />
            <Link to="/python/for_loop">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/python/booleans">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </div>
  );
};
export default PythonC17;
