import { useRef, useEffect } from "react";
// import "./Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Sidebar from "../CSideBar";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const CC10 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };
  const code0 = `char name[50] = "Andrew";`;
  const code1 = `#include <stdio.h>
#include <string.h>
  
int main() {
  char greet1[50] = "Hello, ";
  char greet2[50] = "World!";
  
  strcat(greet1, greet2);
  
  printf("Concatenated string: %s", greet1);
  
  return 0;
}`;
  const code2 = `#include <stdio.h>

int main() {
  char name[] = "John"; 

  char firstChar = name[0];
  char thirdChar = name[2]; 

  printf("First character: %c\\n", firstChar);
  printf("Sixth character: %c\\n", thirdChar);

  return 0;
}`;
  const code3 = `#include <stdio.h>

int main() {
  printf("Hello World!\\n");
  printf("\"Hello World!\"");
  return 0;
}`;
  const code4 = `#include <stdio.h>

int main() {
  char name[50]; 
    
  // fgets consider spaces (recommended)
  printf("Enter your name: ");
  fgets(name, sizeof(name), stdin); 

  printf("You entered: %s", name);
    
  // scan do not consider spaces
  printf("Enter your name : ");
  scanf("%s", name);
  printf("You entered: %s", name);

  return 0;
}`;
  const code5 = `#include <stdio.h>

int main() {
  char name[50] = "John";
  name[0] = 'M';
  printf("%s", name);

  return 0;
}`;
  const code6 = `printf("%d", strlen("Hi there!"));`;
  const code7 = `printf("%s", strcat(str1, str2));`;
  const code8 = `strcpy(str2, str1);`;
  const code9 = `printf("%d\\n", strcmp(str1, str2));`;
  const code10 = `char *strstr(
  const char *haystack,
  const char *needle
);`;
  const code11 = `char *strncpy(
  char *dest,
  const char *src,
  size_t n
);`;
  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/C/type_casting">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/C/operators">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Strings:
              </h2>
              <div class="ui clearing divider"></div>
              <p>
                String is a primitive data type which we use to store text or
                sequence of characters. Strings are always enclosed in double
                quotation marks. String is an array of characters. We will
                discuss arrays later on.
                <br />
              </p>
              <br />
              <SyntaxHighlighter language="c" style={darcula}>
                {code0}
              </SyntaxHighlighter>
              <br />
              <span>
                <ul className="ui unordered list">
                  <li>char is the data type.</li>
                  <li>name is the name of variable.</li>
                  <li>
                    [50] specifies the length of characters in this string
                    variable.
                  </li>
                </ul>
              </span>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Concatenation:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                Concatenation is a method which allows us to join two or more
                strings. We can use strcat() function for string concatenation.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>string.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code1}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <p style={{ backgroundColor: "#FBEEAC", padding: "10px" }}>
                <b>Note: </b>If you have numbers enclosed in quotation marks
                (string), they will not sum up, rather they will be
                concatenated.
              </p>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Accessing Characters in String:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                As we know that strings are arrays (store multiple characters),
                so we can access every character using indexes inside square
                brackets. <br />
                Arrays are data structure which can store multiple data values
                which have same data type.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>string.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code2}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <p style={{ backgroundColor: "#FBEEAC", padding: "10px" }}>
                <b>Note: </b>If you want to print double quotes inside string,
                use \"
              </p>
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>string.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code3}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Inputs in String:
              </h2>
              <div class="ui clearing divider"></div>
              There are two methods of taking inputs in string.<br /><br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>string.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code4}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Modification in Strings:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                We can modify our whole string or its part. We can access a
                specified index and can change it.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>string.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code5}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Methods of Strings:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                In C, there is no built-in string data type or string methods
                like in C++ or any other language. We represent string as an array
                of characters in C. Whenever we want to use methods of strings,
                we have to include a header file #include &lt;string.h&gt;
              </span>
              <table className="ui very padded table">
                <thead>
                  <tr>
                    <th>Method</th>
                    <th>Description</th>
                    <th>Code</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>strlen()</td>
                    <td>
                      It is used to calculate the length of a string variable.
                    </td>
                    <td>
                      <SyntaxHighlighter language="c" style={darcula}>
                        {code6}
                      </SyntaxHighlighter>
                    </td>
                  </tr>
                  <tr>
                    <td>strcat()</td>
                    <td>It is used to concatenate strings.</td>
                    <td>
                      <SyntaxHighlighter language="c" style={darcula}>
                        {code7}
                      </SyntaxHighlighter>
                    </td>
                  </tr>
                  <tr>
                    <td>strcpy()</td>
                    <td>It is used to copy one string to the other.</td>
                    <td>
                      <SyntaxHighlighter language="c" style={darcula}>
                        {code8}
                      </SyntaxHighlighter>
                    </td>
                  </tr>
                  <tr>
                    <td>strncpy()</td>
                    <td>
                      It is used to copy one string to the other but allows you
                      to specify the number of characters to copy.
                    </td>
                    <td>
                      <SyntaxHighlighter language="c" style={darcula}>
                        {code11}
                      </SyntaxHighlighter>
                    </td>
                  </tr>
                  <tr>
                    <td>strcmp()</td>
                    <td>
                      It is used to compare two strings. If they are same, it
                      returns true otherwise false.
                    </td>
                    <td>
                      <SyntaxHighlighter language="c" style={darcula}>
                        {code9}
                      </SyntaxHighlighter>
                    </td>
                  </tr>
                  <tr>
                    <td>*strstr()</td>
                    <td>It is used for string searching.</td>
                    <td>
                      <SyntaxHighlighter language="c" style={darcula}>
                        {code10}
                      </SyntaxHighlighter>
                    </td>
                  </tr>
                </tbody>
              </table>
              
            </div><br />
            <Link to="/C/type_casting">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/C/operators">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default CC10;
