import React, { useState, useRef,useEffect } from "react";
import Sidebar from "./SideBar";
import "../../Components/SideBar/App.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const CodeAccordion = ({ questions, code, toggleAccordion, changeCodeLanguage }) => {
    const divRef = useRef(null);
    const copyContent = () => {
        const divContent = divRef.current.innerText;
        navigator.clipboard
          .writeText(divContent)
          .then(() => {
            toast.success("Content copied!", { position: "top-center" });
          })
          .catch((error) => {
            toast.error("Error copying content:", error, {
              position: "top-center",
            });
          });
      };
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };

  return (
    <div>
      {questions.map((item, index) => (
        <div key={index} className="border p-2 rounded-md">
          <div className="flex justify-between">
            <span className="text-lg font-semibold">
              {item.question}
            </span>
            <button
              className="bg-gray-200 px-2 py-1 rounded-md"
              onClick={() => toggleAccordion(index)}
            >
              {item.isOpen ? "-" : "+"}
            </button>
          </div>
          <div className={`mt-2 ${item.isOpen ? "block" : "hidden"}`}>
            <div className="mt-2 flex space-x-4">
              {Object.keys(code).map((language) => (
                <button
                  key={language}
                  onClick={() => changeCodeLanguage(index, language)}
                  className={`${
                    item.codeLanguage === language
                      ? "bg-blue-500 hover:bg-blue-600 text-white"
                      : "bg-gray-200 hover:bg-gray-300 text-gray-700"
                  } px-3 py-2 rounded-md transition duration-300 ease-in-out transform hover:scale-105`}
                >
                  {language}
                </button>
              ))}
            </div>
            <p className="text-gray-800">
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>output.cpp</b>
                  <i
                    className="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language={`${item.codeLanguage}`} style={darcula}>
                    {code[item.codeLanguage]}
                  </SyntaxHighlighter>
                </div>
              </div>
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

const Question6 = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when the component mounts
      }, []);
  const code = {
    c: `// C code
#include <stdio.h>

int main() {
    int num;
    printf("Enter an integer: ");
    scanf("%d", &num);

    if (num % 2 == 0) {
        printf("The number is even.\\n");
    } else {
        printf("The number is odd.\\n");
    }

    return 0;
}`,
    cpp: `// C++ code
#include <iostream>
using namespace std;

int main() {
    int num;
    cout << "Enter an integer: ";
    cin >> num;

    if (num % 2 == 0) {
        cout << "The number is even." << endl;
    } else {
        cout << "The number is odd." << endl;
    }

    return 0;
}`,
    java: `// Java code
import java.util.Scanner;

public class EvenOdd {
    public static void main(String[] args) {
        Scanner scanner = new Scanner(System.in);
        System.out.print("Enter an integer: ");
        int num = scanner.nextInt();

        if (num % 2 == 0) {
            System.out.println("The number is even.");
        } else {
            System.out.println("The number is odd.");
        }
    }
}`,
    python: `# Python code
num = int(input("Enter an integer: "))

if num % 2 == 0:
    print("The number is even.")
else:
    print("The number is odd.")`,
  };

  const code1 = {
    c: `// C code
#include <stdio.h>

int main() {
    int age;
    printf("Enter your age: ");
    scanf("%d", &age);

    if (age >= 18) {
        printf("You are eligible to vote.\\n");
    } else {
        printf("You are not eligible to vote.\\n");
    }

    return 0;
}`,
    cpp: `// C++ code
#include <iostream>
using namespace std;

int main() {
    int age;
    cout << "Enter your age: ";
    cin >> age;

    if (age >= 18) {
        cout << "You are eligible to vote." << endl;
    } else {
        cout << "You are not eligible to vote." << endl;
    }

    return 0;
}`,
    java: `// Java code
import java.util.Scanner;

public class VotingEligibility {
    public static void main(String[] args) {
        Scanner scanner = new Scanner(System.in);
        System.out.print("Enter your age: ");
        int age = scanner.nextInt();

        if (age >= 18) {
            System.out.println("You are eligible to vote.");
        } else {
            System.out.println("You are not eligible to vote.");
        }
    }
}`,
    python: `# Python code
age = int(input("Enter your age: "))

if age >= 18:
    print("You are eligible to vote.")
else:
    print("You are not eligible to vote.")`,
  };

  const code2 = {
    c: `// C code
#include <stdio.h>

int main() {
    int num1, num2, num3;
    printf("Enter three numbers: ");
    scanf("%d %d %d", &num1, &num2, &num3);

    int largest = num1;

    if (num2 > largest) {
        largest = num2;
    }

    if (num3 > largest) {
        largest = num3;
    }

    printf("The largest number is: %d\\n", largest);

    return 0;
}`,
    cpp: `// C++ code
#include <iostream>
using namespace std;

int main() {
    int num1, num2, num3;
    cout << "Enter three numbers: ";
    cin >> num1 >> num2 >> num3;

    int largest = num1;

    if (num2 > largest) {
        largest = num2;
    }

    if (num3 > largest) {
        largest = num3;
    }

    cout << "The largest number is: " << largest << endl;

    return 0;
}`,
    java: `// Java code
import java.util.Scanner;

public class FindLargestNumber {
    public static void main(String[] args) {
        Scanner scanner = new Scanner(System.in);
        System.out.print("Enter three numbers: ");
        int num1 = scanner.nextInt();
        int num2 = scanner.nextInt();
        int num3 = scanner.nextInt();

        int largest = num1;

        if (num2 > largest) {
            largest = num2;
        }

        if (num3 > largest) {
            largest = num3;
        }

        System.out.println("The largest number is: " + largest);
    }
}`,
    python: `# Python code
num1 = int(input("Enter the first number: "))
num2 = int(input("Enter the second number: "))
num3 = int(input("Enter the third number: "))

largest = num1

if num2 > largest:
    largest = num2

if num3 > largest:
    largest = num3

print("The largest number is:", largest)`,
  };
  const code3 = {
    c: `// C code
#include <stdio.h>

int main() {
    double totalCost = 0;

    for (int i = 1; i <= 3; i++) {
        double price, quantity;
        printf("Enter the price and quantity for item %d: ", i);
        scanf("%lf %lf", &price, &quantity);
        totalCost += (price * quantity);
    }

    if (totalCost > 100) {
        totalCost -= (totalCost * 0.1); // Apply a 10% discount for a total cost above $100
    }

    printf("Total cost after discount: $%.2lf\\n", totalCost);

    return 0;
}`,
    cpp: `// C++ code
#include <iostream>
using namespace std;

int main() {
    double totalCost = 0;

    for (int i = 1; i <= 3; i++) {
        double price, quantity;
        cout << "Enter the price and quantity for item " << i << ": ";
        cin >> price >> quantity;
        totalCost += (price * quantity);
    }

    if (totalCost > 100) {
        totalCost -= (totalCost * 0.1); // Apply a 10% discount for a total cost above $100
    }

    cout << "Total cost after discount: $" << fixed << setprecision(2) << totalCost << endl;

    return 0;
}`,
    java: `// Java code
import java.util.Scanner;

public class ShoppingCart {
    public static void main(String[] args) {
        Scanner scanner = new Scanner(System.in);
        double totalCost = 0;

        for (int i = 1; i <= 3; i++) {
            System.out.print("Enter the price and quantity for item " + i + ": ");
            double price = scanner.nextDouble();
            double quantity = scanner.nextDouble();
            totalCost += price * quantity;
        }

        if (totalCost > 100) {
            totalCost -= (totalCost * 0.1); // Apply a 10% discount for a total cost above $100
        }

        System.out.println("Total cost after discount: $" + totalCost);
    }
}`,
    python: `# Python code
total_cost = 0

for i in range(1, 4):
    price = float(input(f"Enter the price for item {i}: "))
    quantity = float(input(f"Enter the quantity for item {i}: "))
    total_cost += price * quantity

if total_cost > 100:
    total_cost -= total_cost * 0.1  # Apply a 10% discount for a total cost above $100

print(f\"Total cost after discount: \${total_cost:.2f}\")`,
  };
  const code4 = {
    c: `// C code
#include <stdio.h>

int main() {
    char operator;
    double num1, num2;

    printf("Enter an operator (+, -, *, /): ");
    scanf(" %c", &operator);

    printf("Enter two numbers: ");
    scanf("%lf %lf", &num1, &num2);

    switch (operator) {
        case '+':
            printf("Result: %.2lf\\n", num1 + num2);
            break;
        case '-':
            printf("Result: %.2lf\\n", num1 - num2);
            break;
        case '*':
            printf("Result: %.2lf\\n", num1 * num2);
            break;
        case '/':
            if (num2 == 0) {
                printf("Error: Division by zero is not allowed.\\n");
            } else {
                printf("Result: %.2lf\\n", num1 / num2);
            }
            break;
        default:
            printf("Error: Invalid operator.\\n");
    }

    return 0;
}`,
    cpp: `// C++ code
#include <iostream>
using namespace std;

int main() {
    char op;
    double num1, num2;

    cout << "Enter an operator (+, -, *, /): ";
    cin >> op;

    cout << "Enter two numbers: ";
    cin >> num1 >> num2;

    switch (op) {
        case '+':
            cout << "Result: " << num1 + num2 << endl;
            break;
        case '-':
            cout << "Result: " << num1 - num2 << endl;
            break;
        case '*':
            cout << "Result: " << num1 * num2 << endl;
            break;
        case '/':
            if (num2 == 0) {
                cout << "Error: Division by zero is not allowed." << endl;
            } else {
                cout << "Result: " << num1 / num2 << endl;
            }
            break;
        default:
            cout << "Error: Invalid operator." << endl;
    }

    return 0;
}`,
    java: `// Java code
import java.util.Scanner;

public class Calculator {
    public static void main(String[] args) {
        char operator;
        double num1, num2;

        Scanner scanner = new Scanner(System.in);

        System.out.print("Enter an operator (+, -, *, /): ");
        operator = scanner.next().charAt(0);

        System.out.print("Enter two numbers: ");
        num1 = scanner.nextDouble();
        num2 = scanner.nextDouble();

        switch (operator) {
            case '+':
                System.out.println("Result: " + (num1 + num2));
                break;
            case '-':
                System.out.println("Result: " + (num1 - num2));
                break;
            case '*':
                System.out.println("Result: " + (num1 * num2));
                break;
            case '/':
                if (num2 == 0) {
                    System.out.println("Error: Division by zero is not allowed.");
                } else {
                    System.out.println("Result: " + (num1 / num2));
                }
                break;
            default:
                System.out.println("Error: Invalid operator.");
        }
    }
}`,
    // python: `# Python code`,
  };
  const code5 = {
    c: `// C code
#include <stdio.h>

int main() {
    char grade;
    printf("Enter your grade (A, B, C, D, or F): ");
    scanf(" %c", &grade);

    switch (grade) {
        case 'A':
            printf("Excellent\\n");
            break;
        case 'B':
            printf("Good\\n");
            break;
        case 'C':
            printf("Satisfactory\\n");
            break;
        case 'D':
            printf("Pass\\n");
            break;
        case 'F':
            printf("Fail\\n");
            break;
        default:
            printf("Invalid grade\\n");
    }

    return 0;
}`,
    cpp: `// C++ code
#include <iostream>
using namespace std;

int main() {
    char grade;
    cout << "Enter your grade (A, B, C, D, or F): ";
    cin >> grade;

    switch (grade) {
        case 'A':
            cout << "Excellent" << endl;
            break;
        case 'B':
            cout << "Good" << endl;
            break;
        case 'C':
            cout << "Satisfactory" << endl;
            break;
        case 'D':
            cout << "Pass" << endl;
            break;
        case 'F':
            cout << "Fail" << endl;
            break;
        default:
            cout << "Invalid grade" << endl;
    }

    return 0;
}`,
    java: `// Java code
import java.util.Scanner;

public class GradePerformance {
    public static void main(String[] args) {
        Scanner scanner = new Scanner(System.in);

        System.out.print("Enter your grade (A, B, C, D, or F): ");
        char grade = scanner.next().charAt(0);

        switch (grade) {
            case 'A':
                System.out.println("Excellent");
                break;
            case 'B':
                System.out.println("Good");
                break;
            case 'C':
                System.out.println("Satisfactory");
                break;
            case 'D':
                System.out.println("Pass");
                break;
            case 'F':
                System.out.println("Fail");
                break;
            default:
                System.out.println("Invalid grade");
        }
    }
}`,
    python: `# Python code
grade = input("Enter your grade (A, B, C, D, or F): ")

if grade == 'A':
    print("Excellent")
elif grade == 'B':
    print("Good")
elif grade == 'C':
    print("Satisfactory")
elif grade == 'D':
    print("Pass")
elif grade == 'F':
    print("Fail")
else:
    print("Invalid grade")`,
  };
  const code6 = {
    c: `// C code
#include <stdio.h>

int main() {
    int side1, side2, side3;
    printf("Enter three integers representing the sides of a triangle: ");
    scanf("%d %d %d", &side1, &side2, &side3);

    if (side1 + side2 > side3 && side1 + side3 > side2 && side2 + side3 > side1) {
        if (side1 == side2 && side2 == side3) {
            printf("It's an equilateral triangle.\\n");
        } else if (side1 == side2 || side2 == side3 || side1 == side3) {
            printf("It's an isosceles triangle.\\n");
        } else {
            printf("It's a scalene triangle.\\n");
        }
    } else {
        printf("These sides cannot form a triangle.\\n");
    }

    return 0;
}`,
    cpp: `// C++ code
#include <iostream>
using namespace std;

int main() {
    int side1, side2, side3;
    cout << "Enter three integers representing the sides of a triangle: ";
    cin >> side1 >> side2 >> side3;

    if (side1 + side2 > side3 && side1 + side3 > side2 && side2 + side3 > side1) {
        if (side1 == side2 && side2 == side3) {
            cout << "It's an equilateral triangle." << endl;
        } else if (side1 == side2 || side2 == side3 || side1 == side3) {
            cout << "It's an isosceles triangle." << endl;
        } else {
            cout << "It's a scalene triangle." << endl;
        }
    } else {
        cout << "These sides cannot form a triangle." << endl;
    }

    return 0;
}`,
    java: `// Java code
import java.util.Scanner;

public class TriangleType {
    public static void main(String[] args) {
        Scanner scanner = new Scanner(System.in);

        System.out.print("Enter three integers representing the sides of a triangle: ");
        int side1 = scanner.nextInt();
        int side2 = scanner.nextInt();
        int side3 = scanner.nextInt();

        if (side1 + side2 > side3 && side1 + side3 > side2 && side2 + side3 > side1) {
            if (side1 == side2 && side2 == side3) {
                System.out.println("It's an equilateral triangle.");
            } else if (side1 == side2 || side2 == side3 || side1 == side3) {
                System.out.println("It's an isosceles triangle.");
            } else {
                System.out.println("It's a scalene triangle.");
            }
        } else {
            System.out.println("These sides cannot form a triangle.");
        }
    }
}`,
    python: `# Python code
side1, side2, side3 = map(int, input("Enter three integers representing the sides of a triangle: ").split())

if side1 + side2 > side3 and side1 + side3 > side2 and side2 + side3 > side1:
    if side1 == side2 == side3:
        print("It's an equilateral triangle.")
    elif side1 == side2 or side2 == side3 or side1 == side3:
        print("It's an isosceles triangle.")
    else:
        print("It's a scalene triangle.")
else:
    print("These sides cannot form a triangle.")`,
  };
  const code7 = {
    c: `// C code
#include <stdio.h>

int main() {
    double balance = 1000.0;
    int choice;
    double amount;

    while (1) {
        printf("ATM Menu:\\n");
        printf("1. Check Balance\\n");
        printf("2. Deposit\\n");
        printf("3. Withdraw\\n");
        printf("4. Exit\\n");
        printf("Enter your choice: ");
        scanf("%d", &choice);

        switch (choice) {
            case 1:
                printf("Your balance is $%.2lf\\n", balance);
                break;
            case 2:
                printf("Enter the amount to deposit: $");
                scanf("%lf", &amount);
                balance += amount;
                printf("Deposited $%.2lf. Your balance is now $%.2lf\\n", amount, balance);
                break;
            case 3:
                printf("Enter the amount to withdraw: $");
                scanf("%lf", &amount);
                if (amount > balance) {
                    printf("Insufficient balance. Withdrawal failed.\\n");
                } else {
                    balance -= amount;
                    printf("Withdrawn $%.2lf. Your balance is now $%.2lf\\n", amount, balance);
                }
                break;
            case 4:
                printf("Thank you for using the ATM. Goodbye!\\n");
                return 0;
            default:
                printf("Invalid choice. Please select a valid option.\\n");
        }
    }

    return 0;
}`,
    cpp: `// C++ code
#include <iostream>
using namespace std;

int main() {
    double balance = 1000.0;
    int choice;
    double amount;

    while (true) {
        cout << "ATM Menu:" << endl;
        cout << "1. Check Balance" << endl;
        cout << "2. Deposit" << endl;
        cout << "3. Withdraw" << endl;
        cout << "4. Exit" << endl;
        cout << "Enter your choice: ";
        cin >> choice;

        switch (choice) {
            case 1:
                cout << "Your balance is $" << balance << endl;
                break;
            case 2:
                cout << "Enter the amount to deposit: $";
                cin >> amount;
                balance += amount;
                cout << "Deposited $" << amount << ". Your balance is now $" << balance << endl;
                break;
            case 3:
                cout << "Enter the amount to withdraw: $";
                cin >> amount;
                if (amount > balance) {
                    cout << "Insufficient balance. Withdrawal failed." << endl;
                } else {
                    balance -= amount;
                    cout << "Withdrawn $" << amount << ". Your balance is now $" << balance << endl;
                }
                break;
            case 4:
                cout << "Thank you for using the ATM. Goodbye!" << endl;
                return 0;
            default:
                cout << "Invalid choice. Please select a valid option." << endl;
        }
    }

    return 0;
}`,
    java: `// Java code
import java.util.Scanner;

public class ATM {
    public static void main(String[] args) {
        double balance = 1000.0;
        int choice;
        double amount;

        Scanner scanner = new Scanner(System.in);

        while (true) {
            System.out.println("ATM Menu:");
            System.out.println("1. Check Balance");
            System.out.println("2. Deposit");
            System.out.println("3. Withdraw");
            System.out.println("4. Exit");
            System.out.print("Enter your choice: ");
            choice = scanner.nextInt();

            switch (choice) {
                case 1:
                    System.out.println("Your balance is $" + balance);
                    break;
                case 2:
                    System.out.print("Enter the amount to deposit: $");
                    amount = scanner.nextDouble();
                    balance += amount;
                    System.out.println("Deposited $" + amount + ". Your balance is now $" + balance);
                    break;
                case 3:
                    System.out.print("Enter the amount to withdraw: $");
                    amount = scanner.nextDouble();
                    if (amount > balance) {
                        System.out.println("Insufficient balance. Withdrawal failed.");
                    } else {
                        balance -= amount;
                        System.out.println("Withdrawn $" + amount + ". Your balance is now $" + balance);
                    }
                    break;
                case 4:
                    System.out.println("Thank you for using the ATM. Goodbye!");
                    return;
                default:
                    System.out.println("Invalid choice. Please select a valid option.");
            }
        }
    }
}`,
    python: `# Python code
balance = 1000.0

while True:
    print("ATM Menu:")
    print("1. Check Balance")
    print("2. Deposit")
    print("3. Withdraw")
    print("4. Exit")

    choice = input("Enter your choice: ")

    if choice == '1':
        print("Your balance is $%.2f" % balance)
    elif choice == '2':
        amount = float(input("Enter the amount to deposit: $"))
        balance += amount
        print("Deposited $%.2f. Your balance is now $%.2f" % (amount, balance))
    elif choice == '3':
        amount = float(input("Enter the amount to withdraw: $"))
        if amount > balance:
            print("Insufficient balance. Withdrawal failed.")
        else:
            balance -= amount
            print("Withdrawn $%.2f. Your balance is now $%.2f" % (amount, balance))
    elif choice == '4':
        print("Thank you for using the ATM. Goodbye!")
        break
    else:
        print("Invalid choice. Please select a valid option.")`,
  };
  const code8 = {
    c: `// C code
#include <stdio.h>

int main() {
    double income, tax;

    printf("Enter your annual income: $");
    scanf("%lf", &income);

    if (income <= 10000) {
        tax = 0;
    } else if (income <= 50000) {
        tax = 0.1 * (income - 10000);
    } else if (income <= 100000) {
        tax = 0.1 * 40000 + 0.2 * (income - 50000);
    } else {
        tax = 0.1 * 40000 + 0.2 * 50000 + 0.3 * (income - 100000);
    }

    printf("Your income tax is: $%.2lf\\n", tax);

    return 0;
}`,
    cpp: `// C++ code
#include <iostream>
using namespace std;

int main() {
    double income, tax;

    cout << "Enter your annual income: $";
    cin >> income;

    if (income <= 10000) {
        tax = 0;
    } else if (income <= 50000) {
        tax = 0.1 * (income - 10000);
    } else if (income <= 100000) {
        tax = 0.1 * 40000 + 0.2 * (income - 50000);
    } else {
        tax = 0.1 * 40000 + 0.2 * 50000 + 0.3 * (income - 100000);
    }

    cout << "Your income tax is: $" << fixed << setprecision(2) << tax << endl;

    return 0;
}`,
    java: `// Java code
import java.util.Scanner;

public class IncomeTaxCalculator {
    public static void main(String[] args) {
        double income, tax;

        Scanner scanner = new Scanner(System.in);

        System.out.print("Enter your annual income: $");
        income = scanner.nextDouble();

        if (income <= 10000) {
            tax = 0;
        } else if (income <= 50000) {
            tax = 0.1 * (income - 10000);
        } else if (income <= 100000) {
            tax = 0.1 * 40000 + 0.2 * (income - 50000);
        } else {
            tax = 0.1 * 40000 + 0.2 * 50000 + 0.3 * (income - 100000);
        }

        System.out.println("Your income tax is: $" + tax);
    }
}`,
    python: `# Python code
income = float(input("Enter your annual income: $"))
tax = 0

if income <= 10000:
    tax = 0
elif income <= 50000:
    tax = 0.1 * (income - 10000)
elif income <= 100000:
    tax = 0.1 * 40000 + 0.2 * (income - 50000)
else:
    tax = 0.1 * 40000 + 0.2 * 50000 + 0.3 * (income - 100000)

print(f"Your income tax is: \${tax:.2f}")`,
  };
  const [questions, setQuestions] = useState([
    {
      question: "Q1: Write a program that takes an integer as input and checks whether it is even or odd using conditional statements. Display an appropriate message.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions1, setQuestions1] = useState([
    {
      question: "Q2: Create a program that takes a user's age as input and determines if he is eligible to vote (18 years or older) or not using conditional statements.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions2, setQuestions2] = useState([
    {
      question: "Q3: Write a program that takes three numbers as input and finds the largest among them using if-else statements.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions3, setQuestions3] = useState([
    {
      question: "Q4: Create a program that calculates the total cost of purchasing items in a shopping cart. The program should ask for the price and quantity of each item and apply a discount if the total cost exceeds a certain amount using if-else statements.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions4, setQuestions4] = useState([
    {
      question: "Q5: Use switch statement to make a simple calculator.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions5, setQuestions5] = useState([
    {
      question: "Q6: Write a program that asks the user for a grade (A, B, C, D, or F) and provides a message indicating their performance (e.g., \"Excellent,\" \"Good,\" \"Satisfactory,\" \"Pass,\" \"Fail\") using a switch statement (C, C++, Java) or if-else statements (Python).",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions6, setQuestions6] = useState([
    {
      question: "Q7: Write a program that takes three integers as input and determines if they can form the sides of a triangle. If yes, specify whether it's an equilateral, isosceles, or scalene triangle.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions7, setQuestions7] = useState([
    {
      question: "Q8: Create a program that simulates a simple ATM machine. Allow users to check their balance, deposit funds, and withdraw money. Ensure that the user cannot withdraw more money than their account balance. Use if-else statements for handling these operations.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions8, setQuestions8] = useState([
    {
      question: "Q9: Write a program that calculates the income tax based on the annual income of a person. The tax rates can vary depending on the income range. Use conditional statements to determine the tax amount.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);

  const toggleAccordion = (index, questions, setQuestions) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].isOpen = !updatedQuestions[index].isOpen;
    setQuestions(updatedQuestions);
  };

  const changeCodeLanguage = (index, language, questions, setQuestions) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].codeLanguage = language;
    setQuestions(updatedQuestions);
  };

  

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };
  const divRef = useRef(null);
  return (
    <div className="testing">
      <Sidebar>
        <div className="containera">
          <div className="ch-content">
            <h2 className="ui header">
            <Link to="/ques5">
              <button
                className="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
              </Link>
              <Link to="/ques7">
                <button
                  className="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>
            <div className="main-body ui ">
              <h2 className="ui left floated header" style={{ color: "#001C30" }}>
                Conditional Statements(If-else and Switch):
              </h2>
              <div className="ui clearing divider"></div>
              <CodeAccordion
                questions={questions}
                code={code}
                toggleAccordion={(index) => toggleAccordion(index, questions, setQuestions)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions, setQuestions)
                }
              /><br/>
              <CodeAccordion
                questions={questions1}
                code={code1}
                toggleAccordion={(index) => toggleAccordion(index, questions1, setQuestions1)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions1, setQuestions1)
                }
              />
              <br/>
              <CodeAccordion
                questions={questions2}
                code={code2}
                toggleAccordion={(index) => toggleAccordion(index, questions2, setQuestions2)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions2, setQuestions2)
                }
              />
              <br/>
              <CodeAccordion
                questions={questions3}
                code={code3}
                toggleAccordion={(index) => toggleAccordion(index, questions3, setQuestions3)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions3, setQuestions3)
                }
              />
              <br/>
              <CodeAccordion
                questions={questions4}
                code={code4}
                toggleAccordion={(index) => toggleAccordion(index, questions4, setQuestions4)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions4, setQuestions4)
                }
              />
              <br/>
              <CodeAccordion
                questions={questions5}
                code={code5}
                toggleAccordion={(index) => toggleAccordion(index, questions5, setQuestions5)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions5, setQuestions5)
                }
              />
              <br/>
              <CodeAccordion
                questions={questions6}
                code={code6}
                toggleAccordion={(index) => toggleAccordion(index, questions6, setQuestions6)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions6, setQuestions6)
                }
              />
              <br/>
              <CodeAccordion
                questions={questions7}
                code={code7}
                toggleAccordion={(index) => toggleAccordion(index, questions7, setQuestions7)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions7, setQuestions7)
                }
              />
              <br/>
              <CodeAccordion
                questions={questions8}
                code={code8}
                toggleAccordion={(index) => toggleAccordion(index, questions8, setQuestions8)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions8, setQuestions8)
                }
              />
            </div><br />
            <Link to="/ques5">
              <button
                className="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
              </Link>
              <Link to="/ques7">
                <button
                  className="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default Question6;
