import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Home.css"

const Tutintro = () => {
   
    const [scrolling, setScrolling] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 300) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
     <div className={`fade-in ${scrolling ? 'visible' : ''}`}>
          <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
            <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
              <img
                class="object-cover object-center rounded"
                alt="hero"
                src="images/img1.png"
              />
            </div>
            <div class="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
              <h1
                class="title-font sm:text-4xl text-3xl mb-4 font-medium"
                style={{ color: "#183b56" }}
              >
                
        Tutorials!
      
              </h1>
              <p class="mb-8 leading-relaxed">
              We provide you unlimited possibilities to learn programming with our interactive tutorials.
              </p>
              <div class="flex w-full md:justify-start justify-center items-end">
                <Link to="tutorials">
                  <button class="inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">
                    Start Now !
                  </button>
                </Link>
              </div>
              <div>
                <div class="h-full rounded" style={{ marginTop: "3%" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    class="block w-5 h-5 text-gray-400 mb-4"
                    viewBox="0 0 975.036 975.036"
                  >
                    <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
                  </svg>
                  <p class="leading-relaxed mb-6">
                  I had always been entranced by programming but felt intimidated by the complexity of programming. Thanks to Code Progs, I was able to grasp the core concepts.
                  </p>
                  <a class="inline-flex items-center">
                  <img
                      alt="testimonial"
                      src="images/ali.png"
                      class="w-12 h-12 rounded-full flex-shrink-0 object-cover object-center"
                    />
                    <span class="flex-grow flex flex-col pl-4">
                      <span
                        class="title-font font-medium text-gray-900"
                        style={{ color: "rgb(99 102 241)" }}
                      >
                        <b>Nauroz Ali</b>
                      </span>
                      <span class="text-gray-500 text-sm">Software Engineer</span>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          </div>
  )
}
export default Tutintro