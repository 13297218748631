import { useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import Subnavbar from "./Subnavbar";

const Navbar = () => {
  const [openDropdown, setOpenDropdown] = useState(false);

  const handleClicked = () => {
    setOpenDropdown(false);
  };
  const handleClick = () => {
    setOpenDropdown(true);
  };
  return (
    <div>
      <nav>
        <div className="logo">
          <Link to="/">
            <img className="" src="images/logo.png" alt="img" />
          </Link>
        </div>

        <input type="checkbox" id="click" />
        <label htmlFor="click" className="menu-btn">
          <i className="fas fa-bars" onClick={handleClick}></i>
        </label>
        <ul className={`${openDropdown === true ? "open" : "close"}`}>
          <li>
            <div onClick={handleClicked}>
              <Link to="/">Home</Link>
            </div>
          </li>

          <li>
            <div onClick={handleClicked}>
              <Link to="/tutorials">Tutorials</Link>
            </div>
          </li>
          <li>
            <div onClick={handleClicked}>
              <Link to="/ques1">Challenges</Link>
            </div>
          </li>
          <li>
            <div onClick={handleClicked}>
              <Link to="/cheatsheets">Cheat Sheets</Link>
            </div>
          </li>
          
        </ul>
      </nav>
      {/* <Subnavbar/> */}
    </div>
  );
};

export default Navbar;
