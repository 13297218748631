import { useRef, useEffect } from "react";
// import "./Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Sidebar from "../CSideBar";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const CC17 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };
  const code1 = `//initialize variable
while(check condition)
{
    //code
    //change value of variable
}`;
  const code2 = `#include <stdio.h>

int main() {
  int i = 0;
    
  while (i < 5) {
    printf("My name is Sarah\\n");
    i++;
  }
    
  return 0;
}`;
  const code3 = `#include <stdio.h>

int main(){
  int i=0;
  while(i < 5){
    printf("%d\\n", i);
    i++;
  }
  return 0;
}`;

  const code4 = `#include <stdio.h>

int main() {
  int i = 10;
    
  do {
    printf("%d\\n", i);
    i++;
  }
  while (i < 5);
    
  return 0;
}`;
  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/C/for_loop">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/C/continue_break">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                While Loop:
              </h2>
              <div class="ui clearing divider"></div>
              <p>
                Loop executes the same block of code over and over again until
                the provided condition is true. We use while loop when we do not
                know the exactly that how many times loop will run.
              </p>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                How a While Loop works:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                <ul className="ui unordered list">
                  <li>
                    Initialize a variable on the basis of which condition is
                    checked.
                  </li>
                  <li>
                    Check the condition. If the condition is true, the loop will
                    run. Otherwise the loop will be terminated.
                  </li>
                  <li>
                    Change the vlaue of the variable such that the loop does not
                    become infinite. When we change the value of the variable it
                    will make the condition false and the loop will be
                    terminated.
                  </li>
                </ul>
              </span>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Syntax:
              </h2>
              <div class="ui clearing divider"></div>
              <SyntaxHighlighter language="c" style={darcula}>
                {code1}
              </SyntaxHighlighter>
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>while.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code2}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              This will print name 5 times
              <br />
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Program to print couting from 1 to 5:
              </h2>
              <div class="ui clearing divider"></div>
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>while.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code3}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <p style={{ backgroundColor: "#FBEEAC", padding: "15px" }}>
                <b>Note: </b> If we write true in condition, this while loop
                will become infinite. You can use ctrl + c on your keyboard to
                stop it.
              </p>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Do While Loop:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                While and do while loops are almost the same. The main diference
                is in the first iteration. In while loop, all iterations are
                done on the basis of condition. In do while loop, first
                iteration is done without checking the condition. After first
                iteration, it will work like while loop. The condition is
                checked and if it is true second iteration is done and this
                continues until the condition is true.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>do-while.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code4}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <span>
                This code will execute one time, no matter the condition is
                false. But after first iteration, it will check the condition.
                If the condition is true, the loop will run. If the given
                condition is false, the loop will be terminated and the control
                will move out of the loop.
              </span>
            </div>
            <br />
            <Link to="/C/for_loop">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/C/continue_break">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default CC17;
