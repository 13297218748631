import { useRef, useEffect } from "react";
import "../../Components/Chapters/Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "../PythonSideBar";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

const PythonC31 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };

  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };
  const pc31code1 = `file = open("example.txt", "r")

# Read the entire file
content = file.read()

# Read one line at a time
line = file.readline()

# Read all lines into a list
lines = file.readlines()

file.close()`;
  const pc31code2 = `file = open("example.txt", "w")

# Write data to the file
file.write("Hello, World!")
  
file.close()`;
  const pc31code3 = `file = open("example.txt", "a")

# Append data at the end of the file
file.write("\\nThis is a new line.")

file.close()`;
  const pc31code4 = `with open("example.txt", "r") as file:
    content = file.read()
    # Perform operations on content`;
  const pc31code5 = `with open("example.txt", "r") as file:
    for line in file:
        # Process each line`;
  const pc31code6 = `import os

if os.path.exists("example.txt"):
    os.remove("example.txt")
else:
    print("File does not exist.")`;
  const pc31code7 = `try:
    with open("example.txt", "r") as file:
        content = file.read()
except FileNotFoundError:
    print("File not found.")
except PermissionError:
    print("Permission denied.")
except Exception as e:
    print(f"An error occurred: {e}")`;

  const pc31code8 = `import os

os.rename("old_name.txt", "new_name.txt")
os.rename("old_directory/old_file.txt", "new_directory/new_file.txt")`;
  const pc31code9 = `import os

os.mkdir("new_directory")  
os.rmdir("new_directory")  
os.listdir("directory_path")`;

  return (
    <div class="testing">
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/python/exceptions">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/python/iterators">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>
            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                File Handling:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                File handling in Python is the process of working with files,
                including reading from files, writing to files, and performing
                various operations on files.
                <br />
                Python provides many built-in functions or methods for handling
                files.
              </span>
              <br />
              <br />
              <span>
                <ul className="ui unordered list">
                  <li>
                    Before you can perform any file operations, you need to open
                    the file using the open() function.{" "}
                  </li>
                  <li>
                    When you're done with a file, it's important to close it
                    using the close() method to free up system resources.
                  </li>
                </ul>
              </span>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Modes for Opening Files:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                The second mode in the open() function specifies the mode in
                which the file is opened. <br />
                <ul className="ui unordered list">
                  <li>
                    <b>Read("r") </b>opens file for reading.
                  </li>
                  <li>
                    <b>Write("w") </b>opens file for writing.
                  </li>
                  <li>
                    <b>Append("a") </b>opens the file for writing but appends
                    new data to the end of that file. If the file doesn't exist,
                    it creates a new file.
                  </li>
                  <li>
                    <b>Exclusive creation("x") </b> creates a new file and opens
                    the file for writing.{" "}
                  </li>
                  <li>
                    <b>Binary Mode("b") </b>opens the file in binary mode for non-text files.
                  </li>
                  <li>
                    <b>Text Mode("t") </b>opens the file in text mode.
                  </li>
                </ul>
              </span>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Reading from Files:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                The contents of a file can be read using different methods;
              </span>{" "}
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>file.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc31code1}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Writing to Files:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                You can write data to a file using methods like write().
              </span>{" "}
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>file.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc31code2}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Appending to Files:
              </h2>
              <div class="ui clearing divider"></div>
              <span>To append data to an existing file, use the "a" mode.</span>
              <br /> <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>file.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc31code3}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Using with Statements:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                A better practice is to use the with statement (context
                manager), which ensures that the file is properly closed even if
                an exception occurs.
              </span>
              <br /> <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>file.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc31code4}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                File Iteration:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                You can iterate through the lines of a file using a for loop.
              </span>
              <br /> <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>file.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc31code5}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Exception Handling for File Operations:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                File operations may raise exceptions, such as FileNotFoundError
                when attempting to open a non-existent file or PermissionError
                when attempting to write to a read-only file. Always include
                proper exception handling when working with files.
              </span>
              <br /> <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>file.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc31code7}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Deleting Files:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                To delete a file, you can use the os.remove() function from the
                os module.
              </span>{" "}
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>file.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc31code6}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              {/* <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Renaming and Moving Files:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                You can use the os.rename() function to rename files or move
                them to a different directory.
              </span>{" "}
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>file.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc31code8}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br /> */}
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Working with Directories:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                The os module also provides functions for creating, listing, and
                deleting directories.
              </span>{" "}
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>file.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc31code9}
                  </SyntaxHighlighter>
                </div>
              </div>
            </div>{" "}
            <br />
            <Link to="/python/exceptions">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/python/iterators">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </div>
  );
};
export default PythonC31;
