import { useRef, useEffect } from "react";
import "./Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Q15 from "../Questions/Q15";
import Sidebar from "../SideBar/Sidebar";
import Footer from "../Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const Ch15 = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  const divRef = useRef(null);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor:'#D8D9DA'
  };
const code0 = `#include <iostream>
using namespace std;

int main(){
    int marks [5] = { 76, 55, 92, 65 };
    return 0;
}`
  const code1 = `datatype name[size] = {1, 2, 3}`;
  const code2 = `#include <iostream>
using namespace std;

int main(){
    int marks [4] = { 76, 55, 92, 65 };
    cout<<marks[0];      
    cout<<marks[1];       
    cout<<marks[2];      
    cout<<marks[3];      
    return 0;
}`;
  const code3 = `#include <iostream>
using namespace std;

int main(){
    int marks [5] = { 76, 55, 92, 65 };
    for(int i=0; i<5; i++){
        cout<<marks[i]<<endl;
    }
    return 0;
}`;
  const code4 = `#include <iostream>
using namespace std;

int main(){
    string names [3] = { “Andrew”, “Ema”, "Jo" }; 
    for(int i=0; i<3; i++){
        cout<<marks[i];
    }
    cout<<endl; //print new line 
    names[0] = “Leah”;
    for(int i=0; i<3; i++){
        cout<<marks[i];
    }
    cout<<endl;
    return 0;
}`;

  const code5 = `#include <iostream>
using namespace std;

int main(){
    int ages[] = { 20, 22, 24 } 
    return 0;
}`;
  const code6 = `int totalItems = sizeof(array) / sizeof(datatype)`;

  const code7 = `#include <iostream>
using namespace std;

int main(){
    int marks [] = { 10, 20, 30 };
    int size = sizeof(marks)/ sizeof(int);
    for(int i = 0; i < size;  i++){ 
        cout<<marks[i];
    }
}`;
const code8 = `#include <iostream>
using namespace std;

int main(){
    int marks [10] ;
    for(int i = 0; i < 10;  i++){ 
        cin>>marks[i];
    }
}`

  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
            <Link to="/Cpp/continue_break">
            <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button></Link>
              <Link to="/Cpp/struct">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Arrays:
              </h2>
              <div class="ui clearing divider"></div>
              <p>
                Arrays are data type in C++ which are used to store multiple
                values of same data type.
              </p>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Why we need Arrays?
              </h2>
              <div class="ui clearing divider"></div>
              <span>
              In C++, if you want to store marks of ten students, you will have
              to declare ten variables. But if you use arrays, you do not have
              to declare ten variables. As data type of all these variables is
              same, you can use only one array variable to store them.</span> <br />
                <SyntaxHighlighter language="cpp" style={darcula}>
                  {code1}
                </SyntaxHighlighter>
              <br />
              <span>
              Here size stores, how many elements are there in array and if you
              want to access data on some individual at any index, you can
              access,
              </span><br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>array.cpp</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="cpp" style={darcula}>
                    {code0}
                  </SyntaxHighlighter>
                </div>
              </div>










              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Accessing Elements of Arrays:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
              We can access individual elements of arrays using indexes.
              name[0] will access first element of array. <br />
              Similarly name[2] will access data at index 2 and it will be
              on third number because index starts from zero.</span> <br /><br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>array.cpp</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="cpp" style={darcula}>
                    {code2}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
              Problem:
              </h2>
              <div class="ui clearing divider"></div>
              
              <span>
              It is easy to print when size of array is 10, 20, 30 or 100. But
              imagine if the size of array is 1 million, is it good to print
              individual indexes like this? What we are doing here? We are doing
              the same work over and over again. And we have seen that when we
              need to repeat our same block of code, we use loops.
              </span><br /><br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
              Solution:
              </h2>
              <div class="ui clearing divider"></div>
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>array.cpp</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="cpp" style={darcula}>
                    {code3}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
              Taking input in arrays:
              </h2>
              <div class="ui clearing divider"></div>
            
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>array.cpp</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="cpp" style={darcula}>
                    {code8}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
              Changing elements of array:
              </h2>
              <div class="ui clearing divider"></div>
      
              <span>
              You can change the elements of array at any index you want.</span> <br /><br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>array.cpp</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="cpp" style={darcula}>
                    {code4}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <p style={{ backgroundColor: "#FBEEAC", padding: "10px" }}>
                <b>Note: </b>If you do not specify the size of array, it is ok.
              </p>
              <br />
              
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>array.cpp</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="cpp" style={darcula}>
                    {code5}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <p style={{ backgroundColor: "#FBEEAC", padding: "10px" }}>
                <b>Note: </b>Point: If we do not know the size of array, to which number
                we will run loop?
              </p>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
              Find size of array:
              </h2>
              <div class="ui clearing divider"></div>
             
              sizeof() operator as discussed earlier will give us the size of
              array. The size of array does not give us the number of elements in the
              array. As size of int is 4bytes and we have three integers, so
              size of array will be 12 bytes. For this purpose, we have to
              divide size of array with size of datatype. <br />
                <SyntaxHighlighter language="cpp" style={darcula}>
                  {code6}
                </SyntaxHighlighter>
              
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>array.cpp</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="cpp" style={darcula}>
                    {code7}
                  </SyntaxHighlighter>
                </div>
              </div>
              
            </div><br />
            <Link to="/Cpp/continue_break">
            <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button></Link>
              <Link to="/Cpp/struct">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default Ch15;
