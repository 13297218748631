import { useRef, useEffect } from "react";
import "../../Components/Chapters/Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "../PythonSideBar";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

const PythonC15 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };

  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor:'#D8D9DA'
  };
  const pc15code1 = `person = {
    "name": "John",
    "age": 35,
    "gender": "male"
}
print(person)`;
  const pc15code2 = `person = {
    "name": "John",
    "age": 35,
    "gender": "male"
}
print(person["name"])

#Accessing items using get method
print(person.get("age"))`;
  const pc15code3 = `items = {
    "car": "Gazoo",
    "fruit": "Apple",
    "animal": "Lion",
    "animal": "Lion"
}
print(items)`;
  const pc15code4 = `person = {
    "name": "John",
    "age": 35,
    "height": 6.1,
    "male": True
}
print(person)`;
  const pc15code5 = `person = {
    "name": "John",
    "age": 35,
    "height": 6.1,
    "male": True
}

person["name"] = "Alexander"
print(person)`;
  const pc15code6 = `person = {
    "name": "John",
    "age": 35,
    "height": 6.1,
}

person["male"] = True
print(person)`;
  const pc15code7 = `#checking presence of key using "in"
person = {
    "name": "Lauren",
    "age": 20,
    "height": 6.2
}
print("age" in person)

#checking absence of key using "not in"
person = {
    "name": "Lauren",
    "age": 20,
    "height": 6.2
  }
  print("student" not in person)`;
  const pc15code8 = `person = {
    "person1" : {
          "name": "Lauren",
          "age": 20,
          "height": 6.2
    }
  }
  print(person["person1"])`;
  const pc15code9 = `persons = {
    "person1" : {
          "name": "Lauren",
          "age": 20,
          "height": 6.2
    },
    "person2" : {
          "name": "Andrew",
          "age": 22,
          "height": 6.1
    },
    "person3" : {
          "name": "Sarah",
          "age": 24,
          "height": 5.7
    }
}

#accessing a single item
print(persons["person1"]["name"])

#accessing whole dictionary
print(persons)`;
  const pc15code10 = `myDict = {'a': 13, 'b': 21, 'c': 34}
myDict.clear()    
#it will clear the values in dictionary
print(myDict)`;
  const pc15code11 = `original_dict = {'a': 12, 'b': 29, 'c': 43}
copied_dict = original_dict.copy()

print(copied_dict)`;
  const pc15code12 = `keys = ['a', 'b', 'c']
default = 0

newDict = dict.fromkeys(keys, default)
print(newDict)`;
  const pc15code13 = `myDict = {'a': 19, 'b': 62, 'c': 83}

value_a = myDict.get('a')
print(value_a)  # Output: 1

value_d = myDict.get('d')
print(value_d)  # Output: None 

new_dict = myDict.get('d', 0)
print(new_dict)`;
  const pc15code14 = `my_dict = {'a': 17, 'b': 92, 'c': 30}

items = my_dict.items()
print(items)`;
  const pc15code15 = `my_dict = {'a': 19, 'b': 27, 'c': 31}

keys = my_dict.keys()
print(keys)`;
  const pc15code16 = `my_dict = {'a': 11, 'b': 52, 'c': 83}

value_b = my_dict.pop('b')
print(value_b)  
print(my_dict)`;
  const pc15code17 = `my_dict = {'a': 17, 'b': 42, 'c': 33}

key_value_pair = my_dict.popitem()
print(key_value_pair)  
print(my_dict)`;
  const pc15code18 = `my_dict = {'a': 1, 'b': 2}

value_a = my_dict.setdefault('a', 10)
print(value_a)  

print(my_dict)`;
  const pc15code19 = `my_dict = {'a': 1, 'b': 2}

print(my_dict)

update_dict = {'b': 3, 'c': 4}
my_dict.update(update_dict)
print(my_dict)`;
  const pc15code20 = `my_dict = {'a': 13, 'b': 21, 'c': 31}

values = my_dict.values()
print(values)`;
  const pc15code21 = `myDict = [('a', 1), ('b', 2), ('c', 3)]
newDict = dict(myDict)
print(newDict)`;
  return (
    <div class="testing">
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/python/sets">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/python/if_else">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Dictionaries:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                A dictionary is a built-in data type in Python that stores a
                collection of key-value pairs. Each key in a dictionary maps to
                a corresponding value, allowing you to associate data in a way
                that's easy to retrieve and manipulate. Dictionaries are also
                known as associative arrays, hash maps, or hash tables in other
                programming languages.
              </span>
              <br />
              <br />
              
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>dict.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc15code1}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <span>
                  Dictionaries are ordered data types which are used to store
                  data values in key and value pairs.
                </span>
                <br />
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Accessing Items in Dictionary:
                </h2>
                <div className="ui clearing divider"></div>

                <span>
                  We can access individual items in a dictionary using keys.
                </span><br /><br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>dict.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc15code2}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Unique Items:
                </h2>
                <div className="ui clearing divider"></div>

                <span>
                  Items in a dictionary must be unique. If you add duplicate
                  items in a dictionary, they will be discarded.
                </span><br /><br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>dict.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc15code3}
                    </SyntaxHighlighter>
                  </div>
                </div><br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Data types of Dictionary Items:
                </h2>
                <div className="ui clearing divider"></div>

                <span>
                  Items in a dictionary can be of same or different data type.
                </span><br /><br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>dict.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc15code4}
                    </SyntaxHighlighter>
                  </div>
                </div><br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Changing Items in Dictionary:
                </h2>
                <div className="ui clearing divider"></div>

                <span>
                  We can also change items in dictionary by referencing to keys.
                </span><br /><br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>dict.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc15code5}
                    </SyntaxHighlighter>
                  </div>
                </div><br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Adding Items in Dictionary:
                </h2>
                <div className="ui clearing divider"></div>

                <span>
                  We can add new items in dictionary by referencing to keys.
                </span><br /><br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>dict.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc15code6}
                    </SyntaxHighlighter>
                  </div>
                </div><br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Checking if the item exists in a Dictionary:
                </h2>
                <div className="ui clearing divider"></div>

                <span>
                  We have two ways to check if the item exists in a dictionary
                  or not.
                </span><br /><br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>dict.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc15code7}
                    </SyntaxHighlighter>
                  </div>
                </div><br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Nested Dictionaries:
                </h2>
                <div className="ui clearing divider"></div>

                <span>
                  If a dictionary contains another dictionary, it is called
                  nested dictionary.
                </span><br /><br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>dict.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc15code8}
                    </SyntaxHighlighter>
                  </div>
                </div><br /><br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>dict.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc15code9}
                    </SyntaxHighlighter>
                  </div>
                </div><br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Methods on Dictionaries:
                </h2>
                <div className="ui clearing divider"></div>
                <table className="ui very padded table">
                  <thead>
                    <th>Method</th>
                    <th>Code</th>
                    <th>Description</th>
                  </thead>
                  <tbody>
                    <tr>
                      <td><b>clear()</b></td>
                      <td>
                        <div style={containerStyle}>
                          <div className="ml-3 mr-3">
                            <b style={{ color: "black" }}>dict.py</b>

                            <i
                              class="copy icon"
                              onClick={copyContent}
                              style={{
                                cursor: "pointer",
                                float: "right",
                                fontSize: "20px",
                              }}
                            ></i>
                          </div>
                          <div ref={divRef}>
                            <SyntaxHighlighter language="python" style={darcula}>
                              {pc15code10}
                            </SyntaxHighlighter>
                          </div>
                        </div>
                      </td>
                      <td>removes all key-value pairs from the dictionary, effectively emptying the dictionary.</td>
                    </tr>
                    <tr>
                      <td><b>copy()</b></td>
                      <td>
                        <div style={containerStyle}>
                          <div className="ml-3 mr-3">
                            <b style={{ color: "black" }}>dict.py</b>

                            <i
                              class="copy icon"
                              onClick={copyContent}
                              style={{
                                cursor: "pointer",
                                float: "right",
                                fontSize: "20px",
                              }}
                            ></i>
                          </div>
                          <div ref={divRef}>
                            <SyntaxHighlighter language="python" style={darcula}>
                              {pc15code11}
                            </SyntaxHighlighter>
                          </div>
                        </div>
                      </td>
                      <td> creates a shallow copy of a dictionary. </td>
                    </tr>
                    <tr>
                      <td><b>fromkeys()</b></td>
                      <td>
                        <div style={containerStyle}>
                          <div className="ml-3 mr-3">
                            <b style={{ color: "black" }}>dict.py</b>

                            <i
                              class="copy icon"
                              onClick={copyContent}
                              style={{
                                cursor: "pointer",
                                float: "right",
                                fontSize: "20px",
                              }}
                            ></i>
                          </div>
                          <div ref={divRef}>
                            <SyntaxHighlighter language="python" style={darcula}>
                              {pc15code12}
                            </SyntaxHighlighter>
                          </div>
                        </div>
                      </td>
                      <td>creates a new dictionary with specified keys and an optional default value for those keys.</td>
                    </tr>
                    <tr>
                      <td><b>get()</b></td>
                      <td>
                        <div style={containerStyle}>
                          <div className="ml-3 mr-3">
                            <b style={{ color: "black" }}>dict.py</b>

                            <i
                              class="copy icon"
                              onClick={copyContent}
                              style={{
                                cursor: "pointer",
                                float: "right",
                                fontSize: "20px",
                              }}
                            ></i>
                          </div>
                          <div ref={divRef}>
                            <SyntaxHighlighter language="python" style={darcula}>
                              {pc15code13}
                            </SyntaxHighlighter>
                          </div>
                        </div>
                      </td>
                      <td>retrieves the data value associated with a given key.</td>
                    </tr>
                    <tr>
                      <td><b>items()</b></td>
                      <td>
                        <div style={containerStyle}>
                          <div className="ml-3 mr-3">
                            <b style={{ color: "black" }}>dict.py</b>

                            <i
                              class="copy icon"
                              onClick={copyContent}
                              style={{
                                cursor: "pointer",
                                float: "right",
                                fontSize: "20px",
                              }}
                            ></i>
                          </div>
                          <div ref={divRef}>
                            <SyntaxHighlighter language="python" style={darcula}>
                              {pc15code14}
                            </SyntaxHighlighter>
                          </div>
                        </div>
                      </td>
                      <td>retrieves a view of the dictionary's key-value pairs as tuples.</td>
                    </tr>
                    <tr>
                      <td><b>keys()</b></td>
                      <td>
                        <div style={containerStyle}>
                          <div className="ml-3 mr-3">
                            <b style={{ color: "black" }}>dict.py</b>

                            <i
                              class="copy icon"
                              onClick={copyContent}
                              style={{
                                cursor: "pointer",
                                float: "right",
                                fontSize: "20px",
                              }}
                            ></i>
                          </div>
                          <div ref={divRef}>
                            <SyntaxHighlighter language="python" style={darcula}>
                              {pc15code15}
                            </SyntaxHighlighter>
                          </div>
                        </div>
                      </td>
                      <td>retrieve a view of the dictionary's keys.</td>
                    </tr>
                    <tr>
                      <td><b>pop()</b></td>
                      <td>
                        <div style={containerStyle}>
                          <div className="ml-3 mr-3">
                            <b style={{ color: "black" }}>dict.py</b>

                            <i
                              class="copy icon"
                              onClick={copyContent}
                              style={{
                                cursor: "pointer",
                                float: "right",
                                fontSize: "20px",
                              }}
                            ></i>
                          </div>
                          <div ref={divRef}>
                            <SyntaxHighlighter language="python" style={darcula}>
                              {pc15code16}
                            </SyntaxHighlighter>
                          </div>
                        </div>
                      </td>
                      <td>removes and returns the data value associated with a specified key.</td>
                    </tr>
                    <tr>
                      <td><b>popitem()</b></td>
                      <td>
                        <div style={containerStyle}>
                          <div className="ml-3 mr-3">
                            <b style={{ color: "black" }}>dict.py</b>

                            <i
                              class="copy icon"
                              onClick={copyContent}
                              style={{
                                cursor: "pointer",
                                float: "right",
                                fontSize: "20px",
                              }}
                            ></i>
                          </div>
                          <div ref={divRef}>
                            <SyntaxHighlighter language="python" style={darcula}>
                              {pc15code17}
                            </SyntaxHighlighter>
                          </div>
                        </div>
                      </td>
                      <td>removes and returns a key value pair from the dictionary. </td>
                    </tr>
                    <tr>
                      <td><b>setdefault()</b></td>
                      <td>
                        <div style={containerStyle}>
                          <div className="ml-3 mr-3">
                            <b style={{ color: "black" }}>dict.py</b>

                            <i
                              class="copy icon"
                              onClick={copyContent}
                              style={{
                                cursor: "pointer",
                                float: "right",
                                fontSize: "20px",
                              }}
                            ></i>
                          </div>
                          <div ref={divRef}>
                            <SyntaxHighlighter language="python" style={darcula}>
                              {pc15code18}
                            </SyntaxHighlighter>
                          </div>
                        </div>
                      </td>
                      <td>retrieves the value associated with a key, and if the key is not present in the dictionary, it sets a default value for that key and adds it to the dictionary.</td>
                    </tr>
                    <tr>
                      <td><b>update()</b></td>
                      <td>
                        <div style={containerStyle}>
                          <div className="ml-3 mr-3">
                            <b style={{ color: "black" }}>dict.py</b>

                            <i
                              class="copy icon"
                              onClick={copyContent}
                              style={{
                                cursor: "pointer",
                                float: "right",
                                fontSize: "20px",
                              }}
                            ></i>
                          </div>
                          <div ref={divRef}>
                            <SyntaxHighlighter language="python" style={darcula}>
                              {pc15code19}
                            </SyntaxHighlighter>
                          </div>
                        </div>
                      </td>
                      <td>updates a dictionary with the key-value pairs from another dictionary or from an iterable of the key-value pairs.</td>
                    </tr>
                    <tr>
                      <td><b>values()</b></td>
                      <td>
                        <div style={containerStyle}>
                          <div className="ml-3 mr-3">
                            <b style={{ color: "black" }}>dict.py</b>

                            <i
                              class="copy icon"
                              onClick={copyContent}
                              style={{
                                cursor: "pointer",
                                float: "right",
                                fontSize: "20px",
                              }}
                            ></i>
                          </div>
                          <div ref={divRef}>
                            <SyntaxHighlighter language="python" style={darcula}>
                              {pc15code20}
                            </SyntaxHighlighter>
                          </div>
                        </div>
                      </td>
                      <td>retrieves a view of the dictionary's values.</td>
                    </tr>
                    <tr>
                      <td><b>dict()</b></td>
                      <td>
                        <div style={containerStyle}>
                          <div className="ml-3 mr-3">
                            <b style={{ color: "black" }}>dict.py</b>

                            <i
                              class="copy icon"
                              onClick={copyContent}
                              style={{
                                cursor: "pointer",
                                float: "right",
                                fontSize: "20px",
                              }}
                            ></i>
                          </div>
                          <div ref={divRef}>
                            <SyntaxHighlighter language="python" style={darcula}>
                              {pc15code21}
                            </SyntaxHighlighter>
                          </div>
                        </div>
                      </td>
                      <td>creates a new dictionary or to convert other data types into dictionaries. </td>
                    </tr>
                  </tbody>
                </table>
              
            </div><br />
            <Link to="/python/sets">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/python/if_else">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </div>
  );
};
export default PythonC15;
