import { useRef, useEffect } from "react";
// import "./Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Sidebar from "../CSideBar";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const CC8 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor:'#D8D9DA'
  };

  const code1 = `#include <stdio.h>

int main() {
  int age = 42;
  float pi = 3.14;
  double per = 90.345;
      
  
  printf("Integer: %d\\n", age);
  printf("Float: %f\\n", pi);
  printf("Double: %lf\\n", per);
  
  return 0;
}`;
  const code2 = `#include <stdio.h>

int main() {
  char grade;
  
  printf("Enter your grade : ");
  scanf(" %c", &grade); 
  
  printf("Grade = %c", grade);
  
  return 0;
}`;
  const code3 = `#include <stdio.h>
#include <stdbool.h>  
  
int main() {
  bool isTrue = true;
  bool isFalse = false;
  printf("%d ", isTrue);  
  printf("%d", isFalse);         
    
  return 0;
}`;
  const code4 = `#include <stdio.h>

int main() {
  char greetings[] = "Hello, World!";
      
  printf("String: %s\\n", greetings);
  
  return 0;
}`;
  const code5 = `#include <stdio.h>

int main() {
  print("Size of data types in bytes:\\n");
  printf("Int: %zu\\n", sizeof(int));
  printf("Float: %zu\\n", sizeof(float));
  printf("Double: %zu\\n", sizeof(double));
  return 0;
}`;
  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/C/input">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/C/type_casting">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Data types of Variables:
              </h2>
              <div class="ui clearing divider"></div>
              <p>
                Data types specify the type of data getting stored in the
                variable. They restrict other types of data to be stored in the
                variable. In this tutorial we will discuss about some basic data
                types and their implementation.
                <br />
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  For Numbers:
                </h2>
                <div class="ui clearing divider"></div>
                <span>
                  The basic data types which we commonly used to store numbers
                  are given below.
                </span>
                <ul className="ui unordered list">
                  <li>int - stores integer value.</li>
                  <li>float - it stores decimal values.</li>
                  <li>
                    double - it also stores decimal values but its precision is
                    double than float.
                  </li>
                </ul>
              </p>
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>datatype.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code1}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Char Data Type:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                Char data type is used to store any single character in a
                variable.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>datatype.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code2}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Boolean Data Type:
              </h2>
              <div class="ui clearing divider"></div>
              Boolean data type stores only true / False. For using booleans in
              C, you have to import &lt;stdbool.h&gt; header file. It return 1
              if true and 0 if false. <br />
              <br />
              Booleans can store:
              <ul className="ui unordered list">
                <li>true / false</li>
                <li>1 / 0</li>
                <li>Yes / No</li>
              </ul>
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>datatype.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code3}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                String Data Type:
              </h2>
              <div class="ui clearing divider"></div>
              String data type is use to store sequence of
              characters/text/anything inside double quotation marks. <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>string.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code4}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              We will learn about string data type in detail later in this tutorial.{" "}
              <br />
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Format Specifiers:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                Format specifiers in C are used with functions like printf and
                scanf to specify the expected format of the data to be printed
                or read.
              </span>
              <br />
              <table class="ui table">
                <thead>
                  <tr>
                    <th class="ten wide">Data Type</th>
                    <th class="six wide">Format specifier</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>int</td>
                    <td>%d</td>
                  </tr>
                  <tr>
                    <td>float</td>
                    <td>%f</td>
                  </tr>
                  <tr>
                    <td>double </td>
                    <td>%lf</td>
                  </tr>
                  <tr>
                    <td>char</td>
                    <td>%c</td>
                  </tr>
                  <tr>
                    <td>string</td>
                    <td>%s</td>
                  </tr>
                </tbody>
              </table>
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Size of Data Types:
              </h2>
              <div class="ui clearing divider"></div>
              Size of variable depends upon the data type of variable. <br />
              <table class="ui table">
                <thead>
                  <tr>
                    <th class="ten wide">Data Type</th>
                    <th class="six wide">Size</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>char</td>
                    <td>1 byte</td>
                  </tr>
                  <tr>
                    <td>bool</td>
                    <td>1 byte</td>
                  </tr>
                  <tr>
                    <td>int </td>
                    <td>2 or 4 bytes</td>
                  </tr>
                  <tr>
                    <td>float</td>
                    <td>4 bytes</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th>double</th>
                    <th>8 bytes</th>
                  </tr>
                </tfoot>
              </table>
              <br />
              <p style={{ backgroundColor: "#FBEEAC", padding: "10px" }}>
                <b>Note: </b>It is not necessary to remember the size of all
                data types. <br />
              </p>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                sizeof() operator:
              </h2>
              <div class="ui clearing divider"></div>
              We can find the size of any data type or variable using sizeof()
              operator. Let's see an example.
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>size.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code5}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              
            </div>
            <Link to="/C/input">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/C/type_casting">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default CC8;
