import React, { useState, useRef, useEffect } from "react";
import Sidebar from "./SideBar";
import "../../Components/SideBar/App.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const CodeAccordion = ({ questions, code, toggleAccordion, changeCodeLanguage }) => {
    const divRef = useRef(null);
    useEffect(() => {
      window.scrollTo(0, 0); // Scroll to the top when the component mounts
    }, []);
    const copyContent = () => {
        const divContent = divRef.current.innerText;
        navigator.clipboard
          .writeText(divContent)
          .then(() => {
            toast.success("Content copied!", { position: "top-center" });
          })
          .catch((error) => {
            toast.error("Error copying content:", error, {
              position: "top-center",
            });
          });
      };
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };

  return (
    <div>
      {questions.map((item, index) => (
        <div key={index} className="border p-2 rounded-md">
          <div className="flex justify-between">
            <span className="text-lg font-semibold">
              {item.question}
            </span>
            <button
              className="bg-gray-200 px-2 py-1 rounded-md"
              onClick={() => toggleAccordion(index)}
            >
              {item.isOpen ? "-" : "+"}
            </button>
          </div>
          <div className={`mt-2 ${item.isOpen ? "block" : "hidden"}`}>
            <div className="mt-2 flex space-x-4">
              {Object.keys(code).map((language) => (
                <button
                  key={language}
                  onClick={() => changeCodeLanguage(index, language)}
                  className={`${
                    item.codeLanguage === language
                      ? "bg-blue-500 hover:bg-blue-600 text-white"
                      : "bg-gray-200 hover:bg-gray-300 text-gray-700"
                  } px-3 py-2 rounded-md transition duration-300 ease-in-out transform hover:scale-105`}
                >
                  {language}
                </button>
              ))}
            </div>
            <p className="text-gray-800">
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>output.cpp</b>
                  <i
                    className="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language={`${item.codeLanguage}`} style={darcula}>
                    {code[item.codeLanguage]}
                  </SyntaxHighlighter>
                </div>
              </div>
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

const Question3 = () => {
  const code = {
    c: `// C code\n#include <stdio.h>\n\n\tint main() {\n\tchar name[50];\n\tint age;\n\tchar grade;\n\tfloat gpa;\n\n\tprintf("Enter your name: ");\n\tscanf("%s", name);\n\n\tprintf("Enter your age: ");\n\tscanf("%d", &age);\n\n\tprintf("Enter your grade (A, B, C, etc.): ");\n\tscanf(" %c", &grade);\n\n\tprintf("Enter your GPA: ");\n\tscanf("%f", &gpa);\n\n\tprintf("Name: %s\\n", name);\n\tprintf("Age: %d\\n", age);\n\tprintf("Grade: %c\\n", grade);\n\tprintf("GPA: %.2f\\n", gpa);\n\n\treturn 0;\n}`,
    cpp: `// C++ code\n#include <iostream>\n\tusing namespace std;\n\n\tint main() {\n\tstring name;\n\tint age;\n\tchar grade;\n\tfloat gpa;\n\n\tcout << "Enter your name: ";\n\tgetline(cin, name);\n\n\tcout << "Enter your age: ";\n\tcin >> age;\n\n\tcout << "Enter your grade (A, B, C, etc.): ";\n\tcin >> grade;\n\n\tcout << "Enter your GPA: ";\n\tcin >> gpa;\n\n\tcout << "Name: " << name << endl;\n\tcout << "Age: " << age << endl;\n\tcout << "Grade: " << grade << endl;\n\tcout << "GPA: " << gpa << endl;\n\n\treturn 0;\n}`,
    java: `// Java code\nimport java.util.Scanner;\n\n\tpublic class Main {\n\tpubli\n\tstatic void main(String[] args) {\n\t\tScanner input = new Scanner(System.in);\n\n\t\tSystem.out.print("Enter your name: ");\n\t\tString name = input.nextLine();\n\n\t\tSystem.out.print("Enter your age: ");\n\t\tint age = input.nextInt();\n\n\t\tSystem.out.print("Enter your grade (A, B, C, etc.): ");\n\t\tchar grade = input.next().charAt(0);\n\n\t\tSystem.out.print("Enter your GPA: ");\n\t\tfloat gpa = input.nextFloat();\n\n\t\tSystem.out.println("Name: " + name);\n\t\tSystem.out.println("Age: " + age);\n\t\tSystem.out.println("Grade: " + grade);\n\t\tSystem.out.println("GPA: " + gpa);\n\t}\n}`,
    python: `# Python code\nname = input("Enter your name: ")\nage = int(input("Enter your age: "))\ngrade = input("Enter your grade (A, B, C, etc.): ")\ngpa = float(input("Enter your GPA: "))\n\nprint("Name:", name)\nprint("Age:", age)\nprint("Grade:", grade)\nprint("GPA:", gpa)`,
  };
  const code1 = {
    c: `// C code
#include <stdio.h>

int main() {
    int num1, num2, sum;

    printf("Enter the first number: ");
    scanf("%d", &num1);

    printf("Enter the second number: ");
    scanf("%d", &num2);

    sum = num1 + num2;

    printf("Sum: %d\\n", sum);

    return 0;
}`,
    cpp: `// C++ code
#include <iostream>
using namespace std;

int main() {
    int num1, num2, sum;

    cout << "Enter the first number: ";
    cin >> num1;

    cout << "Enter the second number: ";
    cin >> num2;

    sum = num1 + num2;

    cout << "Sum: " << sum << endl;

    return 0;
}`,
    java: `// Java code
import java.util.Scanner;

public class SumProgram {
    public static void main(String[] args) {
        Scanner scanner = new Scanner(System.in);

        System.out.print("Enter the first number: ");
        int num1 = scanner.nextInt();

        System.out.print("Enter the second number: ");
        int num2 = scanner.nextInt();

        int sum = num1 + num2;

        System.out.println("Sum: " + sum);
    }
}`,
    python: `# Python code
num1 = int(input("Enter the first number: "))
num2 = int(input("Enter the second number: "))

sum_result = num1 + num2

print("Sum:", sum_result)`,
  };


  const [questions, setQuestions] = useState([
    {
      question: "Q1: Write a program to take user's input. Take name, grades, age and gpa as input and print values on screen.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions1, setQuestions1] = useState([
    {
      question: "Q2: Write a program to take two numbers from the user as input on run time and print their values on screen using cout. The data type of numbers should be int.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  

  const toggleAccordion = (index, questions, setQuestions) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].isOpen = !updatedQuestions[index].isOpen;
    setQuestions(updatedQuestions);
  };

  const changeCodeLanguage = (index, language, questions, setQuestions) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].codeLanguage = language;
    setQuestions(updatedQuestions);
  };

  

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };
  const divRef = useRef(null);
  return (
    <div className="testing">
      <Sidebar>
        <div className="containera mb-60 md:mb-0">
          <div className="ch-content">
            <h2 className="ui header">
            <Link to="/ques2">
              <button
                className="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
              </Link>
              <Link to="/ques4">
                <button
                  className="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>
            <div className="main-body ui">
              <h2 className="ui left floated header" style={{ color: "#001C30" }}>
                Taking Input:
              </h2>
              <div className="ui clearing divider"></div>
              <CodeAccordion
                questions={questions}
                code={code}
                toggleAccordion={(index) => toggleAccordion(index, questions, setQuestions)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions, setQuestions)
                }
              />
              <br />
              <CodeAccordion
                questions={questions1}
                code={code1}
                toggleAccordion={(index) => toggleAccordion(index, questions1, setQuestions1)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions1, setQuestions1)
                }
              />
              
            </div><br />
            <Link to="/ques2">
              <button
                className="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
              </Link>
              <Link to="/ques4">
                <button
                  className="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default Question3;
