import React, { useState } from "react";
import {
  FaTh,
  FaBars,
  FaUserAlt,
  FaRegChartBar,
  FaCommentAlt,
  FaShoppingBag,
  FaThList,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import "../../Components/SideBar/App.css";

const Sidebar = ({ children }) => {
  const [isOpenn, setisOpenn] = useState(true);
  const toggle = () => setisOpenn(!isOpenn);
  const menuItem = [
    {
      path: "/quizes/java_quiz1",
      name: "Intro to Java",
    },
    {
      path: "/quizes/java_quiz2",
      name: "Getting Started",
    },
    {
      path: "/quizes/java_quiz3",
      name: "Basic Syntax",
    },
    {
      path: "/quizes/java_quiz4",
      name: "Printing Output",
    },
    {
      path: "/quizes/java_quiz5",
      name: "Comments",
    },
    {
      path: "/quizes/java_quiz6",
      name: "Variables",
    },
    {
      path: "/quizes/java_quiz7",
      name: "Taking Input",
    },
    {
      path: "/quizes/java_quiz8",
      name: "Data Types",
    },
    {
      path: "/quizes/java_quiz9",
      name: "Type Casting",
    },
    {
      path: "/quizes/java_quiz10",
      name: "Strings",
    },
    {
      path: "/quizes/java_quiz11",
      name: "Operators",
    },
    {
      path: "/quizes/java_quiz12",
      name: "Java Maths",
    },
    {
      path: "/quizes/java_quiz13",
      name: "Booleans",
    },
    {
      path: "/quizes/java_quiz14",
      name: "Conditional Statements",
    },
    {
      path: "/quizes/java_quiz15",
      name: "Switch Statements",
    },
    {
      path: "/quizes/java_quiz16",
      name: "For Loop",
    },
    {
      path: "/quizes/java_quiz17",
      name: "While Loops",
    },
    {
      path: "/quizes/java_quiz18",
      name: "Continue and Break Statements",
    },
    {
      path: "/quizes/java_quiz19",
      name: "Arrays",
    },
    {
      path: "/quizes/java_quiz20",
      name: "Variable Scope",
    },
    {
      path: "/quizes/java_quiz21",
      name: "Classes and Objects",
    },
    {
      path: "/quizes/java_quiz22",
      name: "Constructors and Destructors",
    },
    {
      path: "/quizes/java_quiz23",
      name: "Access Modifiers",
    },
    {
      path: "/quizes/java_quiz24",
      name: "Encapsulation",
    },
    {
      path: "/quizes/java_quiz25",
      name: "Inheritance",
    },
    {
      path: "/quizes/java_quiz26",
      name: "Interfaces in Java",
    },
    {
      path: "/quizes/java_quiz27",
      name: "Abstraction",
    },
    {
      path: "/quizes/java_quiz28",
      name: "Polymorphism",
    },
    {
      path: "/quizes/java_quiz29",
      name: "Exceptions",
    },
    {
      path: "/quizes/java_quiz30",
      name: "File Handling",
    },
  ];
  const handleClick = (e) => {
    e.preventDefault();
    setisOpenn(!isOpenn);
  };
  const handleClicked = () => {
    setisOpenn(!isOpenn);
  };
  return (
    <div className={`${isOpenn === true ? "flex" : "sidebar_"}`}>
      <input type="checkbox" id="click" />
      <label htmlFor="click" className="menu-btn" style={{ marginTop: "20%" }}>
        <i
          onClick={handleClick}
          className="fas fa-bars"
          style={{ marginTop: "20%" }}
        ></i>
      </label>
      <div
        style={{ display: "block", width: "200px" }}
        className={`${isOpenn === true ? "sidebar" : "nosidebar"}`}
      >
        <div className="top_section">
          <h1 style={{ display: "block" }} className="logo">
            <span style={{ color: "black", fontSize: "22px" }}>
              JavaTutorial
            </span>
          </h1>
        </div>
        {menuItem.map((item, index) => (
          <ul>
            <li>
              <Link
                to={item.path}
                key={index}
                className="link"
                activeclassName="active"
              >
                {/* here */}
                <div
                  onClick={handleClicked}
                  style={{
                    display: isOpenn ? "block" : "block",
                    color: "black",
                    fontSize: "14px",
                  }}
                  className="link_text"
                >
                  {item.name}
                </div>
              </Link>
            </li>
          </ul>
        ))}
      </div>
      <div className={`${isOpenn === true ? "" : "closemain"}`}>
        <main>{children}</main>
      </div>
    </div>
  );
};

export default Sidebar;
