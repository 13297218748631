import { useState, useEffect } from "react";
import Sidebar from "./SideBar";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
import "./Quiz.css";
import "../../Components/SideBar/App.css";
import Modal from "react-modal";

const CQuiz13 = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  const [answers, setAnswers] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleQuizSubmit = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const questions = [
    {
      id: 1,
      question: 'Choose an option to match with loop:',
      options: [
        "execution", "code", "repetition", "none" 
      ],
      correctAnswer: "repetition",
    },
    {
      id: 2,
      question: 'Correct syntax for for-loop is:',
      options: [
        "for( i=0; i<10; i++)", "for( i=1; i<10; i++)", "for( i=0; i<10; i--)", "All are true" 
      ],
      correctAnswer: "All are true",
    },
    {
      id: 3,
      question: 'for(i=0; i<5; i++) this loop will terminate when i = __:',
      options: ["2", "3", "4", "5"],
      correctAnswer: "5",
    },
    {
      id: 4,
      question: 'By using loops, we can reduce the lines of code:',
      options: ["Yes", "No"],
      correctAnswer: "Yes",
    },
    
    
  ];
  const handleAnswerChange = (questionId, selectedAnswer) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: selectedAnswer,
    }));
  };

  const calculateScore = () => {
    let correctCount = 0;
    let incorrectCount = 0;

    questions.forEach((question) => {
      const userAnswer = answers[question.id];
      if (userAnswer === question.correctAnswer) {
        correctCount++;
      } else {
        incorrectCount++;
      }
    });

    return { correctCount, incorrectCount };
  };

  const { correctCount, incorrectCount } = calculateScore();
  return (
    <>
      <Sidebar>
        <div class="container">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/quizes/c_quiz15">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                  }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/quizes/c_quiz17">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
            </h2>
            <h1 class="start content ui left floated header">For Loop</h1>
            <div className="ui clearing divider"></div>

            <div class="main-body ">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Quiz # 16
              </h2>
              <span class="tut-btn">
                <Link to="/C/for_loop">
                  <button class="ui secondary button">Read Tutorial</button>
                </Link>
              </span>
              <div class="ui clearing divider"></div>
              <div>
                {questions.map((question, i) => (
                  <div key={question.id}>
                    <div class="con">
                      <div class="que">
                        Q{i + 1}:
                        <span class="one-per-left-margin">
                          {question.question}
                        </span>
                      </div>
                      {question.options.map((option) => (
                        <label class="label" key={option}>
                          <input
                            type="radio"
                            name={`question_${question.id}`}
                            value={option}
                            checked={answers[question.id] === option}
                            onChange={(e) =>
                              handleAnswerChange(question.id, e.target.value)
                            }
                          />
                          <span className="one-per-left-margin">{option}</span>
                          <br />
                        </label>
                      ))}
                    </div>
                    <hr />
                  </div>
                ))}
                <br />
                <center>
                  <button class="ui primary button" onClick={handleQuizSubmit}>
                    Submit Quiz
                  </button>
                </center>

                {/* Modal for displaying results */}
                <Modal
                  class="modal"
                  isOpen={isModalOpen}
                  onRequestClose={closeModal}
                >
                  <b style={{ fontSize: "20px" }}>Quiz Results</b>
                  <b>
                    <p>
                      Your score is {correctCount}/{questions.length}{" "}
                    </p>
                  </b>
                  {questions.map((question, i) => (
                    <div key={question.id}>
                      <p>
                        Q{i + 1}: {question.question}
                      </p>
                      <p>
                        <b>Correct Answer:</b> {question.correctAnswer}
                      </p>
                      <p
                        className={
                          `${question.correctAnswer}` ===
                          `${answers[question.id]}`
                            ? "correct"
                            : "wrong"
                        }
                      >
                        <b>Your Answer:</b>{" "}
                        {answers[question.id] || "Not answered"}
                      </p>
                      <hr />
                    </div>
                  ))}
                  <button class="ui secondary button" onClick={closeModal}>
                    Close
                  </button>
                  <br />
                  <br />
                </Modal>
              </div>

              <br />
            </div>
            <Link to="/quizes/c_quiz15">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                }}
              >
                Previous
              </button>
            </Link>
            <Link to="/quizes/c_quiz17">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
            <br />
          </div>
        </div>
      </Sidebar>

      <div class="sideBarBody">
        <Footer />
      </div>
    </>
  );
};
export default CQuiz13;
