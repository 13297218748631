import { useRef, useEffect } from "react";
import "./Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Q28 from "../Questions/Q28";
import Sidebar from "../SideBar/Sidebar";
import Footer from "../Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const Ch28 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };
  const code1 = `try {
    // here goes the code
    //throw exception if any error 
    throw exception; 
}
catch () {
    //Code to handle errors
}`;
  const code2 = `#include <iostream>
#include <string>
using namespace std;

int main() {
	long long int ph;
    try{
		cout<<"Enter your phone number "<<endl;
		cin>>ph;
		if(ph){
			cout<<"0"<<ph<<endl;
		}
		else
		throw 505;
		}
		catch(...){
			cout<<"Invalid Phone numer."<<endl;
		}

    return 0;
}`;
  const code3 = `#include <iostream>
#include <string>
using namespace std;

int main() {
	  int con;
	  cout<<"Press 1 if you are human.";
	  cin>>con;

    try {
  	  if (con == 1) {
    	  cout << "Access granted.";
  	  } else {
    	  throw 505;
  	  }
    }
	  catch (...) {
  		  cout << "Access denied.\\n";
	  }
    return 0;
}`;

  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/Cpp/abstraction">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/Cpp/file_handling">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Handling Exceptions
              </h2>
              <div class="ui clearing divider"></div>
              <p>
                In C++ programming, errors can occur during the execution of
                code, and it's essential to handle these errors gracefully.
                These errors may result from incorrect input or other unforeseen
                circumstances. These error situations are commonly
                referred to as exceptions in C++.
                <br />
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Syntax:
                </h2>
                <div class="ui clearing divider"></div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="cpp" style={darcula}>
                    {code1}
                  </SyntaxHighlighter>
                </div>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  What happens in try catch block:
                </h2>
                <div class="ui clearing divider"></div>
                <ol className="ui ordered list">
                  <li>
                    In try block, we write our code that may throw an exception.
                  </li>
                  <li>
                    If there is a problem in code, the throw keyword throws an
                    exception.
                  </li>
                  <li>
                    The catch block has the code which is to be executed in case
                    of error.
                  </li>
                </ol>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Example:
                </h2>
                <div class="ui clearing divider"></div>
                Let us explore an example. <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>exception.cpp</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="cpp" style={darcula}>
                      {code2}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                In this example, the variable named <b>ph</b> which takes input
                phone number is integer type. But if user inputs some other data
                type, it will throw an error and we will get error message.
                <br />
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Another Example:
                </h2>
                <div class="ui clearing divider"></div>
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>exception.cpp</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="cpp" style={darcula}>
                      {code3}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
              </p>
            </div>
            <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Why Handling Exceptions?
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                <ol className="ui ordered list">
                  <li><b>Error Handling: </b>Exception handling helps us to separate the error handling code 
                  from the normal flow of the program making code more readable. If we do not use exception 
                  handling to handle errors, error results in the termination of our code.</li>
                  <li>
                    <b>User Friendly: </b>When we create applications with a user interface, exception handling allows us to provide meaningful 
                    message to the user if error occurs. It will improve the user experience.
                  </li>
                  <li>
                    <b>Readable Code: </b>Exception handling allows us to write a clearer code. In this way, code becomes 
                    more readable and manageable.
                  </li>
                </ol>
              </span>
            <br />
            <Link to="/Cpp/abstraction">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/Cpp/file_handling">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default Ch28;
