import { useRef, useEffect } from "react";
import "../../Components/Chapters/Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Sidebar from "../JavaSideBar";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const JavaC30 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };

  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };
  const jc30code1 = `try {
    // Code that may throw exception
} catch (ExceptionType e) {
    // Exception handling code
}`;
  const jc30code2 = `try {
    // Code that may throw exception
} catch (ExceptionType e) {
    // Exception handling code
} finally {
    // Cleanup code (optional)
}`;
  const jc30code3 = `public class Exception {
  public static void main(String[] args) {
    try {
      int num1 = 10;
      int num2 = 0;

      // Attempt to divide
      double result = div(num1, num2);

      System.out.println("Result: " + result);
    } catch (ArithmeticException e) {
      System.err.println("Zero Division Error");
    } finally {
      System.out.println("Cleanup code");
    }
  }

  public static double div(int num1, int num2) {
    if (num2 == 0) {
      throw new ArithmeticException("Error");
    }
    return (double) num1 / num2;
  }
}`;
  const jc30code4 = `public class ThrowExample {
  public static void main(String[] args) {
    try {
      int age = -13; // Assume this is the user's input

      if (age < 0) {
        throw new Exception("Invalid age.");
      }

      System.out.println("Age: " + age);
    } catch (Exception e) {
      System.err.print("Error: " + e.getMessage());
    }
  }
}`;
  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/java/polymorphism">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/java/file_handling">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body ">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Exceptions:
              </h2>
              <div class="ui clearing divider"></div>
              <p style={{ backgroundColor: "", padding: "" }}>
                In Java programming, errors can arise during the execution of the code, and
                it is vital to manage these errors effectively. These errors may
                stem from incorrect inputs or unexpected situations. Appropriate
                error handling is essential to maintain program reliability and
                offer informative feedback to users when errors occur. These
                error scenarios are typically known as the exceptions in Java.
                <br />
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Try and Catch block in Java:
                </h2>
                <div class="ui clearing divider"></div>
                <span>
                  The try and catch block usually come in pair in Java
                  programming. <br />
                  <ul className="ui unordered list">
                    <li>
                      <b>Try block - </b>executes the code after testing it.
                    </li>
                    <li>
                      <b>Catch block - </b>if error occurs in the try block,
                      catch block is executed.
                    </li>
                  </ul>
                </span>
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>exception.java</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="java" style={darcula}>
                      {jc30code1}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Finally block:
                </h2>
                <div class="ui clearing divider"></div>
                <span>
                  The finally block, if provided, is executed regardless of
                  whether an exception is thrown or caught. It is often used for
                  cleanup tasks.
                </span>
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>exception.java</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="java" style={darcula}>
                      {jc30code2}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Code for Exception Handling:
                </h2>
                <div class="ui clearing divider"></div>
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>exception.java</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="java" style={darcula}>
                      {jc30code3}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  The "Throw" Keyword:
                </h2>
                <div class="ui clearing divider"></div>
                <span>
                  The throw keyword in Java serves the purpose of explicitly
                  triggering an exception. It is employed when you wish to
                  signify a particular error situation within your code.
                </span>
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>exception.java</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="java" style={darcula}>
                      {jc30code4}
                    </SyntaxHighlighter>
                  </div>
                </div>
              </p>
            </div>

            <br />
            <Link to="/java/polymorphism">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/java/file_handling">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default JavaC30;
