import { useEffect } from "react";
import "../../Components/Chapters/Chapters.css";
import Sidebar from "../PythonSideBar";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { useRef } from "react";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import { ToastContainer, toast } from "react-toastify";

const PythonC2 = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  const divRef = useRef(null);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };

  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };
const c2code1 = `print ("Hello World!")`
  return (
    <div class="testing">
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/python/introduction">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/python/syntax">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body ui ">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                How to download Python:
              </h2>
              <div class="ui clearing divider"></div>
              <p>
                <ol class="ui ordered list">
                  <li>Go to the official website of python, python.org</li>

                  <li>On this page click on downloads.</li>

                  <li>
                    On downloads page, download the latest version of python.
                  </li>
                  <li>
                    When you have downloaded python installer, go in your
                    download folder and double click on it.
                  </li>
                  <li>
                    Now click on checkbox <b>“add python to path”</b> and install
                    python.
                  </li>
                </ol>
                <br />
                Now you need a code editor to write your code. It can be Pycharm,
                Visual Studio code, or Jupyter Notebook.
              </p>
              <br />

              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                How to download Pycharm:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                Pycharm is an Integrated Development Environment (IDE) which is
                used to write code.
              </span>
              <br />
              <p>
                <ol class="ui ordered list">
                  <li>Go to Jetbrains official website.</li>

                  <li>
                    Click on downloads button and go to the downloads page. Now
                    download the community version for free.
                  </li>

                  <li>
                    Click on the pycharm installer in the downloads folder in
                    your pc and install pycharm.{" "}
                  </li>
                  <li>
                    After installation, open the pycharm and set the base
                    interpreter to your downloaded version of python and now,
                    you can start coding here.
                  </li>
                </ol>
              </p>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                What is Python Interpreter:
              </h2>
              <div class="ui clearing divider"></div>
              <p>
                It is a program that translates python program written in high
                level language (Python) to machine language (to communicate with
                computer) or simply it executes your python code. It executes
                our code line by line and whenever error occurs, it stops there
                and does not execute any line after the error.
              </p>
              <br /><br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Writing Python Code:
              </h2>
              <div class="ui clearing divider"></div>
              After setting up the environment, open the IDE which you have installed (like Pycharm) and write the 
              following code with extension <b>.py</b><br /><br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>syntax.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {c2code1}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Interpreting Python Code:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                The flowchart for the interpretation of Python code is given:
              </span>
              <img src="/images/flowchartpy.png" alt="flowchartpy" style={{ width: "60%", margin: "auto" }}  /><br/>
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Explanation:
              </h2>
              <div class="ui clearing divider"></div>
              <ul className="ui unordered list">
                <li>
                <h1 style={{ fontSize: "20px", fontWeight: "500" }}>
                    Source Code:
                  </h1><br />
                  Source code is the code that we write in human readable programming language. 
                  In our case, we are writing the source code in Python.
                </li>
                <li>
                <h1 style={{ fontSize: "20px", fontWeight: "500" }}>
                    Python Interpreter:
                  </h1><br />
                  Python interpreter converts the code written in high level 
                  language into byte code and executes it. Python Interpreter is actually Compiler 
                  and Python Virtual Machine (PVM) stored in memory.
                </li>
                <li>
                <h1 style={{ fontSize: "20px", fontWeight: "500" }}>
                    Compiler:
                  </h1><br />
                  Compiler converts our source code into <b>Byte Code</b>.
                </li>
                <li>
                <h1 style={{ fontSize: "20px", fontWeight: "500" }}>
                    Python Virtual Machine (PVM):
                  </h1>
                  <br />
                  PVM makes the byte code executable and we get the output.
                </li>
              </ul>



            </div>
            <br />
            <Link to="/python/introduction">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/python/syntax">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
            <br />
          </div>
        </div>
      </Sidebar>

      <Footer />
    </div>
  );
};
export default PythonC2;
