import React, { useState, useRef, useEffect } from "react";
import Sidebar from "./SideBar";
import "../../Components/SideBar/App.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const CodeAccordion = ({ questions, code, toggleAccordion, changeCodeLanguage }) => {
    const divRef = useRef(null);
    const copyContent = () => {
        const divContent = divRef.current.innerText;
        navigator.clipboard
          .writeText(divContent)
          .then(() => {
            toast.success("Content copied!", { position: "top-center" });
          })
          .catch((error) => {
            toast.error("Error copying content:", error, {
              position: "top-center",
            });
          });
      };
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };

  return (
    <div>
      {questions.map((item, index) => (
        <div key={index} className="border p-2 rounded-md">
          <div className="flex justify-between">
            <span className="text-lg font-semibold">
              {item.question}
            </span>
            <button
              className="bg-gray-200 px-2 py-1 rounded-md"
              onClick={() => toggleAccordion(index)}
            >
              {item.isOpen ? "-" : "+"}
            </button>
          </div>
          <div className={`mt-2 ${item.isOpen ? "block" : "hidden"}`}>
            <div className="mt-2 flex space-x-4">
              {Object.keys(code).map((language) => (
                <button
                  key={language}
                  onClick={() => changeCodeLanguage(index, language)}
                  className={`${
                    item.codeLanguage === language
                      ? "bg-blue-500 hover:bg-blue-600 text-white"
                      : "bg-gray-200 hover:bg-gray-300 text-gray-700"
                  } px-3 py-2 rounded-md transition duration-300 ease-in-out transform hover:scale-105`}
                >
                  {language}
                </button>
              ))}
            </div>
            <p className="text-gray-800">
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>output.cpp</b>
                  <i
                    className="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language={`${item.codeLanguage}`} style={darcula}>
                    {code[item.codeLanguage]}
                  </SyntaxHighlighter>
                </div>
              </div>
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

const Question19 = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when the component mounts
      }, []);
    
    const code = {
        cpp: `// C++ code
#include <iostream>
#include <string>
using namespace std;

class Person {
public:
    Person(string name, int age) : name(name), age(age) {}

    void printDetails() {
        cout << "Name: " << name << ", Age: " << age << endl;
    }

private:
    string name;
    int age;
};

int main() {
    // Instantiate objects of the "Person" class
    Person person1("Alice", 30);
    Person person2("Bob", 25);
    Person person3("Charlie", 40);

    // Print the details of each person
    person1.printDetails();
    person2.printDetails();
    person3.printDetails();

    return 0;
}`,
        java: `// Java code
public class Person {
    private String name;
    private int age;

    public Person(String name, int age) {
        this.name = name;
        this.age = age;
    }

    public void printDetails() {
        System.out.println("Name: " + name + ", Age: " + age);
    }

    public static void main(String[] args) {
        // Instantiate objects of the "Person" class
        Person person1 = new Person("Alice", 30);
        Person person2 = new Person("Bob", 25);
        Person person3 = new Person("Charlie", 40);

        // Print the details of each person
        person1.printDetails();
        person2.printDetails();
        person3.printDetails();
    }
}`,
        python: `# Python code
class Person:
    def __init__(self, name, age):
        self.name = name
        self.age = age

# Instantiate objects of the "Person" class
person1 = Person("Alice", 30)
person2 = Person("Bob", 25)
person3 = Person("Charlie", 40)

# Print the details of each person
print("Person 1: Name -", person1.name, ", Age -", person1.age)
print("Person 2: Name -", person2.name, ", Age -", person2.age)
print("Person 3: Name -", person3.name, ", Age -", person3.age)`,
    };
    const code1 = {
        cpp: `// C++ code
#include <iostream>
using namespace std;

class BankAccount {
private:
    double balance;

public:
    BankAccount(double initialBalance) {
        balance = initialBalance;
    }

    void deposit(double amount) {
        if (amount > 0) {
            balance += amount;
            cout << "Deposited $" << amount << endl;
        } else {
            cout << "Invalid deposit amount." << endl;
        }
    }

    void withdraw(double amount) {
        if (amount > 0 && amount <= balance) {
            balance -= amount;
            cout << "Withdrawn $" << amount << endl;
        } else {
            cout << "Invalid withdrawal amount." << endl;
        }
    }

    double checkBalance() {
        return balance;
    }
};

int main() {
    BankAccount account(1000.0);
    
    account.deposit(500.0);
    account.withdraw(200.0);

    double currentBalance = account.checkBalance();
    cout << "Current Balance: $" << currentBalance << endl;

    return 0;
}`,
        java: `// Java code
public class BankAccount {
    private double balance;

    public BankAccount(double initialBalance) {
        balance = initialBalance;
    }

    public void deposit(double amount) {
        if (amount > 0) {
            balance += amount;
            System.out.println("Deposited $" + amount);
        } else {
            System.out.println("Invalid deposit amount.");
        }
    }

    public void withdraw(double amount) {
        if (amount > 0 && amount <= balance) {
            balance -= amount;
            System.out.println("Withdrawn $" + amount);
        } else {
            System.out.println("Invalid withdrawal amount.");
        }
    }

    public double checkBalance() {
        return balance;
    }

    public static void main(String[] args) {
        BankAccount account = new BankAccount(1000.0);
        
        account.deposit(500.0);
        account.withdraw(200.0);

        double currentBalance = account.checkBalance();
        System.out.println("Current Balance: $" + currentBalance);
    }
}`,
        python: `# Python code
class BankAccount:
    def __init__(self, initial_balance):
        self.balance = initial_balance

    def deposit(self, amount):
        if amount > 0:
            self.balance += amount
            print(f"Deposited \${amount}")
        else:
            print("Invalid deposit amount.")

    def withdraw(self, amount):
        if amount > 0 and amount <= self.balance:
            self.balance -= amount
            print(f"Withdrawn \${amount}")
        else:
            print("Invalid withdrawal amount.")

    def check_balance(self):
        return self.balance

if __name__ == "__main__":
    account = BankAccount(1000.0)

    account.deposit(500.0)
    account.withdraw(200.0)

    current_balance = account.check_balance()
    print(f"Current Balance: \${current_balance}")`,
    };
    const code2 = {
        cpp: `// C++ code
#include <iostream>
#include <string>
using namespace std;

class Student {
private:
    string name;
    int rollNumber;

public:
    // Constructor to initialize name and roll number
    Student(string studentName, int studentRollNumber) : name(studentName), rollNumber(studentRollNumber) {
    }

    void displayInfo() {
        cout << "Name: " << name << ", Roll Number: " << rollNumber << endl;
    }
};

int main() {
    // Instantiate a Student object with a constructor
    Student student("Alice", 101);

    // Display student information
    student.displayInfo();

    return 0;
}`,
        java: `// Java code
public class Student {
    private String name;
    private int rollNumber;

    // Constructor to initialize name and roll number
    public Student(String studentName, int studentRollNumber) {
        name = studentName;
        rollNumber = studentRollNumber;
    }

    public void displayInfo() {
        System.out.println("Name: " + name + ", Roll Number: " + rollNumber);
    }

    public static void main(String[] args) {
        // Instantiate a Student object with a constructor
        Student student = new Student("Alice", 101);

        // Display student information
        student.displayInfo();
    }
}`,
        python: `# Python code
class Student:
    def __init__(self, student_name, student_roll_number):
        self.name = student_name
        self.roll_number = student_roll_number

    def display_info(self):
        print("Name:", self.name, ", Roll Number:", self.roll_number)

if __name__ == "__main__":
    # Instantiate a Student object with a constructor
    student = Student("Alice", 101)

    # Display student information
    student.display_info()`,
    };
    const code3 = {
        cpp: `// C++ code
#include <iostream>
#include <string>
using namespace std;

class Book {
private:
    string title;
    string author;

public:
    Book(string bookTitle, string bookAuthor) : title(bookTitle), author(bookAuthor) {
    }

    void displayInfo() {
        cout << "Title: " << title << ", Author: " << author << endl;
    }
};

int main() {
    Book book("The Catcher in the Rye", "J.D. Salinger");
    
    book.displayInfo();

    return 0;
}`,
        java: `// Java code
public class Book {
    private String title;
    private String author;

    public Book(String bookTitle, String bookAuthor) {
        title = bookTitle;
        author = bookAuthor;
    }

    public void displayInfo() {
        System.out.println("Title: " + title + ", Author: " + author);
    }

    public static void main(String[] args) {
        Book book = new Book("The Catcher in the Rye", "J.D. Salinger");
        
        book.displayInfo();
    }
}`,
        python: `# Python code
class Book:
    def __init__(self, book_title, book_author):
        self.title = book_title
        self.author = book_author

    def display_info(self):
        print("Title:", self.title, ", Author:", self.author)

if __name__ == "__main__":
    book = Book("The Catcher in the Rye", "J.D. Salinger")
    
    book.display_info()`,
    };
    const code4 = {
        cpp: `// C++ code
#include <iostream>
#include <string>
using namespace std;

class Employee {
private:
    string name;
    double salary;
    int id;

public:
    Employee(string employeeName, double employeeSalary, int employeeID) : name(employeeName), salary(employeeSalary), id(employeeID) {
    }

    void displayInfo() {
        cout << "Name: " << name << ", Salary: $" << salary << ", ID: " << id << endl;
    }
};

int main() {
    Employee employee1("Alice", 50000.0, 101);
    Employee employee2("Bob", 60000.0, 102);
    
    employee1.displayInfo();
    employee2.displayInfo();

    return 0;
}`,
        java: `// Java code
public class Employee {
    private String name;
    private double salary;
    private int id;

    public Employee(String employeeName, double employeeSalary, int employeeID) {
        name = employeeName;
        salary = employeeSalary;
        id = employeeID;
    }

    public void displayInfo() {
        System.out.println("Name: " + name + ", Salary: $" + salary + ", ID: " + id);
    }

    public static void main(String[] args) {
        Employee employee1 = new Employee("Alice", 50000.0, 101);
        Employee employee2 = new Employee("Bob", 60000.0, 102);
        
        employee1.displayInfo();
        employee2.displayInfo();
    }
}`,
        python: `# Python code
class Employee:
    def __init__(self, employee_name, employee_salary, employee_id):
        self.name = employee_name
        self.salary = employee_salary
        self.id = employee_id

    def display_info(self):
        print("Name:", self.name, ", Salary: $", self.salary, ", ID:", self.id)

if __name__ == "__main__":
    employee1 = Employee("Alice", 50000.0, 101)
    employee2 = Employee("Bob", 60000.0, 102)
    
    employee1.display_info()
    employee2.display_info()`,
    };
    const code5 = {
        cpp: `// C++ code
#include <iostream>
using namespace std;

class Rectangle {
private:
    double length;
    double width;

public:
    Rectangle(double rectLength, double rectWidth) : length(rectLength), width(rectWidth) {
    }

    double calculateArea() {
        return length * width;
    }

    double calculatePerimeter() {
        return 2 * (length + width);
    }
};

int main() {
    Rectangle rect(5.0, 3.0);

    double area = rect.calculateArea();
    double perimeter = rect.calculatePerimeter();

    cout << "Area: " << area << endl;
    cout << "Perimeter: " << perimeter << endl;

    return 0;
}`,
        java: `// Java code
public class Rectangle {
    private double length;
    private double width;

    public Rectangle(double rectLength, double rectWidth) {
        length = rectLength;
        width = rectWidth;
    }

    public double calculateArea() {
        return length * width;
    }

    public double calculatePerimeter() {
        return 2 * (length + width);
    }

    public static void main(String[] args) {
        Rectangle rect = new Rectangle(5.0, 3.0);

        double area = rect.calculateArea();
        double perimeter = rect.calculatePerimeter();

        System.out.println("Area: " + area);
        System.out.println("Perimeter: " + perimeter);
    }
}`,
        python: `# Python code
class Rectangle:
    def __init__(self, rect_length, rect_width):
        self.length = rect_length
        self.width = rect_width

    def calculate_area(self):
        return self.length * self.width

    def calculate_perimeter(self):
        return 2 * (self.length + self.width)

if __name__ == "__main__":
    rect = Rectangle(5.0, 3.0)

    area = rect.calculate_area()
    perimeter = rect.calculate_perimeter()

    print("Area:", area)
    print("Perimeter:", perimeter)`,
    };
    const code6 = {
        cpp: `// C++ code
#include <iostream>
using namespace std;

class Date {
private:
    int day;
    int month;
    int year;

public:
    Date(int d, int m, int y) : day(d), month(m), year(y) {
    }

    bool isLeapYear(int y) {
        return (y % 4 == 0 && y % 100 != 0) || (y % 400 == 0);
    }

    int daysInMonth(int m, int y) {
        int days[] = {0, 31, 28 + isLeapYear(y), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31};
        return days[m];
    }

    bool isValidDate() {
        return (year >= 0 && month >= 1 && month <= 12 && day >= 1 && day <= daysInMonth(month, year));
    }

    int calculateDifference(const Date& otherDate) {
        if (!isValidDate() || !otherDate.isValidDate()) {
            return -1;  // Invalid date(s)
        }

        int days1 = day;
        for (int i = 0; i < month; i++) {
            days1 += daysInMonth(i, year);
        }
        for (int i = 0; i < year; i++) {
            days1 += (isLeapYear(i) ? 366 : 365);
        }

        int days2 = otherDate.day;
        for (int i = 0; i < otherDate.month; i++) {
            days2 += daysInMonth(i, otherDate.year);
        }
        for (int i = 0; i < otherDate.year; i++) {
            days2 += (isLeapYear(i) ? 366 : 365);
        }

        return abs(days1 - days2);
    }
};

int main() {
    Date date1(2, 11, 2023);
    Date date2(25, 12, 2023);

    if (date1.isValidDate() && date2.isValidDate()) {
        int difference = date1.calculateDifference(date2);
        cout << "Number of days between the two dates: " << difference << endl;
    } else {
        cout << "Invalid date(s)." << endl;
    }

    return 0;
}`,
        java: `// Java code
public class Date {
    private int day;
    private int month;
    private int year;

    public Date(int d, int m, int y) {
        day = d;
        month = m;
        year = y;
    }

    private boolean isLeapYear(int y) {
        return (y % 4 == 0 && y % 100 != 0) || (y % 400 == 0);
    }

    private int daysInMonth(int m, int y) {
        int[] days = {0, 31, 28 + (isLeapYear(y) ? 1 : 0), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31};
        return days[m];
    }

    public boolean isValidDate() {
        return (year >= 0 && month >= 1 && month <= 12 && day >= 1 && day <= daysInMonth(month, year));
    }

    public int calculateDifference(Date otherDate) {
        if (!isValidDate() || !otherDate.isValidDate()) {
            return -1;  // Invalid date(s)
        }

        int days1 = day;
        for (int i = 1; i < month; i++) {
            days1 += daysInMonth(i, year);
        }
        for (int i = 1; i < year; i++) {
            days1 += (isLeapYear(i) ? 366 : 365);
        }

        int days2 = otherDate.day;
        for (int i = 1; i < otherDate.month; i++) {
            days2 += otherDate.daysInMonth(i, otherDate.year);
        }
        for (int i = 1; i < otherDate.year; i++) {
            days2 += (otherDate.isLeapYear(i) ? 366 : 365);
        }

        return Math.abs(days1 - days2);
    }

    public static void main(String[] args) {
        Date date1 = new Date(2, 11, 2023);
        Date date2 = new Date(25, 12, 2023);

        if (date1.isValidDate() && date2.isValidDate()) {
            int difference = date1.calculateDifference(date2);
            System.out.println("Number of days between the two dates: " + difference);
        } else {
            System.out.println("Invalid date(s).");
        }
    }
}`,
        python: `# Python code
class Date:
    def __init__(self, d, m, y):
        self.day = d
        self.month = m
        self.year = y

    def is_leap_year(self, y):
        return (y % 4 == 0 and y % 100 != 0) or (y % 400 == 0)

    def days_in_month(self, m, y):
        days = [0, 31, 28 + (1 if self.is_leap_year(y) else 0), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
        return days[m]

    def is_valid_date(self):
        return self.year >= 0 and 1 <= self.month <= 12 and 1 <= self.day <= self.days_in_month(self.month, self.year)

    def calculate_difference(self, other_date):
        if not self.is_valid_date() or not other_date.is_valid_date():
            return -1  # Invalid date(s)

        days1 = self.day
        for i in range(1, self.month):
            days1 += self.days_in_month(i, self.year)
        for i in range(1, self.year):
            days1 += 366 if self.is_leap_year(i) else 365

        days2 = other_date.day
        for i in range(1, other_date.month):
            days2 += other_date.days_in_month(i, other_date.year)
        for i in range(1, other_date.year):
            days2 += 366 if other_date.is_leap_year(i) else 365

        return abs(days1 - days2)

if __name__ == "__main__":
    date1 = Date(2, 11, 2023)
    date2 = Date(25, 12, 2023)

    if date1.is_valid_date() and date2.is_valid_date():
        difference = date1.calculate_difference(date2)
        print("Number of days between the two dates:", difference)
    else:
        print("Invalid date(s).")`,
    };
    const code7 = {
        cpp: `// C++ code
#include <iostream>
using namespace std;

class ComplexNumber {
private:
    double real;
    double imaginary;

public:
    ComplexNumber(double realPart, double imaginaryPart) : real(realPart), imaginary(imaginaryPart) {
    }

    ComplexNumber operator+(const ComplexNumber& other) {
        return ComplexNumber(real + other.real, imaginary + other.imaginary);
    }

    ComplexNumber operator-(const ComplexNumber& other) {
        return ComplexNumber(real - other.real, imaginary - other.imaginary);
    }

    ComplexNumber operator*(const ComplexNumber& other) {
        return ComplexNumber(real * other.real - imaginary * other.imaginary, real * other.imaginary + imaginary * other.real);
    }

    void display() {
        cout << real << " + " << imaginary << "i" << endl;
    }
};

int main() {
    ComplexNumber num1(3.0, 2.0);
    ComplexNumber num2(1.5, 4.5);

    ComplexNumber sum = num1 + num2;
    ComplexNumber difference = num1 - num2;
    ComplexNumber product = num1 * num2;

    cout << "Sum: ";
    sum.display();

    cout << "Difference: ";
    difference.display();

    cout << "Product: ";
    product.display();

    return 0;
}`,
        java: `// Java code
public class ComplexNumber {
    private double real;
    private double imaginary;

    public ComplexNumber(double realPart, double imaginaryPart) {
        real = realPart;
        imaginary = imaginaryPart;
    }

    public ComplexNumber add(ComplexNumber other) {
        return new ComplexNumber(real + other.real, imaginary + other.imaginary);
    }

    public ComplexNumber subtract(ComplexNumber other) {
        return new ComplexNumber(real - other.real, imaginary - other.imaginary);
    }

    public ComplexNumber multiply(ComplexNumber other) {
        return new ComplexNumber(real * other.real - imaginary * other.imaginary, real * other.imaginary + imaginary * other.real);
    }

    public void display() {
        System.out.println(real + " + " + imaginary + "i");
    }

    public static void main(String[] args) {
        ComplexNumber num1 = new ComplexNumber(3.0, 2.0);
        ComplexNumber num2 = new ComplexNumber(1.5, 4.5);

        ComplexNumber sum = num1.add(num2);
        ComplexNumber difference = num1.subtract(num2);
        ComplexNumber product = num1.multiply(num2);

        System.out.println("Sum: ");
        sum.display();

        System.out.println("Difference: ");
        difference.display();

        System.out.println("Product: ");
        product.display();
    }
}`,
        python: `# Python code
class ComplexNumber:
    def __init__(self, realPart, imaginaryPart):
        self.real = realPart
        self.imaginary = imaginaryPart

    def add(self, other):
        return ComplexNumber(self.real + other.real, self.imaginary + other.imaginary)

    def subtract(self, other):
        return ComplexNumber(self.real - other.real, self.imaginary - other.imaginary)

    def multiply(self, other):
        real_result = self.real * other.real - self.imaginary * other.imaginary
        imaginary_result = self.real * other.imaginary + self.imaginary * other.real
        return ComplexNumber(real_result, imaginary_result)

    def display(self):
        print(f"{self.real} + {self.imaginary}i")

if __name__ == "__main__":
    num1 = ComplexNumber(3.0, 2.0)
    num2 = ComplexNumber(1.5, 4.5)

    sum_result = num1.add(num2)
    difference_result = num1.subtract(num2)
    product_result = num1.multiply(num2)

    print("Sum:")
    sum_result.display()

    print("Difference:")
    difference_result.display()

    print("Product:")
    product_result.display()`,
    };
    const code8 = {
        cpp: `// C++ code
#include <iostream>
#include <vector>
#include <string>
using namespace std;

class Book {
public:
    string title;
    string author;
    bool borrowed;

    Book(const string& bookTitle, const string& bookAuthor)
        : title(bookTitle), author(bookAuthor), borrowed(false) {
    }
};

class Patron {
public:
    string name;

    Patron(const string& patronName) : name(patronName) {
    }
};

class Library {
private:
    vector<Book> books;
    vector<Patron> patrons;

public:
    void addBook(const string& title, const string& author) {
        books.push_back(Book(title, author));
    }

    void addPatron(const string& name) {
        patrons.push_back(Patron(name));
    }

    vector<Book> searchBooks(const string& query) {
        vector<Book> results;
        for (const Book& book : books) {
            if (book.title.find(query) != string::npos || book.author.find(query) != string::npos) {
                results.push_back(book);
            }
        }
        return results;
    }

    bool borrowBook(Book& book, Patron& patron) {
        if (book.borrowed) {
            return false; // Book is already borrowed
        }
        book.borrowed = true;
        return true;
    }

    bool returnBook(Book& book, Patron& patron) {
        if (!book.borrowed) {
            return false; // Book is not borrowed
        }
        book.borrowed = false;
        return true;
    }
};

int main() {
    Library library;

    library.addBook("Python Crash Course", "Eric Matthes");
    library.addBook("The Great Gatsby", "F. Scott Fitzgerald");

    library.addPatron("Alice");
    library.addPatron("Bob");

    vector<Book> searchResults = library.searchBooks("Python");
    if (!searchResults.empty()) {
        Book& bookToBorrow = searchResults[0];
        Patron& patronBorrowing = library.patrons[0];

        if (library.borrowBook(bookToBorrow, patronBorrowing)) {
            cout << patronBorrowing.name << " borrowed " << bookToBorrow.title << "." << endl;
        } else {
            cout << bookToBorrow.title << " is not available for borrowing." << endl;
        }
    }

    vector<Book> searchResults2 = library.searchBooks("Gatsby");
    if (!searchResults2.empty()) {
        Book& bookToReturn = searchResults2[0];
        Patron& patronReturning = library.patrons[1];

        if (library.returnBook(bookToReturn, patronReturning)) {
            cout << patronReturning.name << " returned " << bookToReturn.title << "." << endl;
        } else {
            cout << bookToReturn.title << " was not borrowed by " << patronReturning.name << "." << endl;
        }
    }

    // Display search results
    cout << "Search results:" << endl;
    for (const Book& book : searchResults) {
        cout << book.title << " by " << book.author << endl;
    }
    return 0;
}`,
        java: `// Java code
import java.util.ArrayList;
import java.util.List;

class Book {
    String title;
    String author;
    boolean borrowed;

    Book(String bookTitle, String bookAuthor) {
        title = bookTitle;
        author = bookAuthor;
        borrowed = false;
    }
}

class Patron {
    String name;

    Patron(String patronName) {
        name = patronName;
    }
}

class Library {
    private List<Book> books = new ArrayList<>();
    private List<Patron> patrons = new ArrayList<>();

    void addBook(String title, String author) {
        books.add(new Book(title, author));
    }

    void addPatron(String name) {
        patrons.add(new Patron(name));
    }

    List<Book> searchBooks(String query) {
        List<Book> results = new ArrayList<>();
        for (Book book : books) {
            if (book.title.toLowerCase().contains(query.toLowerCase()) || book.author.toLowerCase().contains(query.toLowerCase())) {
                results.add(book);
            }
        }
        return results;
    }

    boolean borrowBook(Book book, Patron patron) {
        if (book.borrowed) {
            return false; // Book is already borrowed
        }
        book.borrowed = true;
        return true;
    }

    boolean returnBook(Book book, Patron patron) {
        if (!book.borrowed) {
            return false; // Book is not borrowed
        }
        book.borrowed = false;
        return true;
    }

    public static void main(String[] args) {
        Library library = new Library();

        library.addBook("Python Crash Course", "Eric Matthes");
        library.addBook("The Great Gatsby", "F. Scott Fitzgerald");

        library.addPatron("Alice");
        library.addPatron("Bob");

        List<Book> searchResults = library.searchBooks("Python");
        if (!searchResults.isEmpty()) {
            Book bookToBorrow = searchResults.get(0);
            Patron patronBorrowing = library.patrons.get(0);

            if (library.borrowBook(bookToBorrow, patronBorrowing)) {
                System.out.println(patronBorrowing.name + " borrowed " + bookToBorrow.title + ".");
            } else {
                System.out.println(bookToBorrow.title + " is not available for borrowing.");
            }
        }

        List<Book> searchResults2 = library.searchBooks("Gatsby");
        if (!searchResults2.isEmpty()) {
            Book bookToReturn = searchResults2.get(0);
            Patron patronReturning = library.patrons.get(1);

            if (library.returnBook(bookToReturn, patronReturning)) {
                System.out.println(patronReturning.name + " returned " + bookToReturn.title + ".");
            } else {
                System.out.println(bookToReturn.title + " was not borrowed by " + patronReturning.name + ".");
            }
        }

        // Display search results
        System.out.println("Search results:");
        for (Book book : searchResults) {
            System.out.println(book.title + " by " + book.author);
        }
    }
}`,
        python: `# Python code
class Book:
    def __init__(self, title, author):
        self.title = title
        self.author = author
        self.borrowed = False

    def __str__(self):
        return f"{self.title} by {self.author}"

    def is_available(self):
        return not self.borrowed

    def borrow(self):
        if self.is_available():
            self.borrowed = True
            return True
        else:
            return False

    def return_book(self):
        if not self.is_available():
            self.borrowed = False
            return True
        else:
            return False

class Patron:
    def __init__(self, name):
        self.name = name

    def __str__(self):
        return self.name

class Library:
    def __init__(self):
        self.books = []
        self.patrons = []

    def add_book(self, title, author):
        book = Book(title, author)
        self.books.append(book)

    def add_patron(self, name):
        patron = Patron(name)
        self.patrons.append(patron)

    def search_books(self, query):
        results = [book for book in self.books if query.lower() in book.title.lower() or query.lower() in book.author.lower()]
        return results

    def borrow_book(self, book, patron):
        if book in self.books and patron in self.patrons:
            return book.borrow()
        return False

    def return_book(self, book, patron):
        if book in self.books and patron in self.patrons:
            return book.return_book()
        return False

if __name__ == "__main__":
    library = Library()

    library.add_book("Python Crash Course", "Eric Matthes")
    library.add_book("The Great Gatsby", "F. Scott Fitzgerald")

    library.add_patron("Alice")
    library.add_patron("Bob")

    book_to_borrow = library.search_books("Python")[0]
    patron_borrowing = library.patrons[0]

    if library.borrow_book(book_to_borrow, patron_borrowing):
        print(f"{patron_borrowing} borrowed {book_to_borrow}.")
    else:
        print(f"{book_to_borrow} is not available for borrowing.")

    book_to_return = library.search_books("Gatsby")[0]
    patron_returning = library.patrons[1]

    if library.return_book(book_to_return, patron_returning):
        print(f"{patron_returning} returned {book_to_return}.")
    else:
        print(f"{book_to_return} was not borrowed by {patron_returning}.")

    # Search for books matching a query
    search_results = library.search_books("Gatsby")
    if search_results:
        print("Search results:")
        for result in search_results:
            print(result)
    else:
        print("No books found.")`,
    };


  const [questions, setQuestions] = useState([
    {
      question: "Q1: Create a class \"Person\" with attributes for name and age. Instantiate a few objects and print their details.",
      codeLanguage: "cpp",
      isOpen: false,
    },
  ]);
  const [questions1, setQuestions1] = useState([
    {
      question: "Q2: Implement a class \"BankAccount\" with methods for deposit and withdrawal. Enhance the \"BankAccount\" class with a method to check the account balance.",
      codeLanguage: "cpp",
      isOpen: false,
    },
  ]);
  const [questions2, setQuestions2] = useState([
    {
      question: "Q3: Create a class \"Student\" with a constructor to initialize name and roll number.",
      codeLanguage: "cpp",
      isOpen: false,
    },
  ]);
  const [questions3, setQuestions3] = useState([
    {
      question: "Q4: Create a class \"Book\" with attributes title and author. Implement a method to display book information.",
      codeLanguage: "cpp",
      isOpen: false,
    },
  ]);
  const [questions4, setQuestions4] = useState([
    {
      question: "Q5: Implement a class \"Employee\" with attributes like name, salary, and ID, and create objects for different employees.",
      codeLanguage: "cpp",
      isOpen: false,
    },
  ]);
  const [questions5, setQuestions5] = useState([
    {
      question: "Q6: Develop a class \"Rectangle\" with methods to calculate area and perimeter.",
      codeLanguage: "cpp",
      isOpen: false,
    },
  ]);
  const [questions6, setQuestions6] = useState([
    {
      question: "Q7: Design a class \"Date\" to represent dates and implement methods to compare dates and calculate the number of days between them.",
      codeLanguage: "cpp",
      isOpen: false,
    },
  ]);
  const [questions7, setQuestions7] = useState([
    {
      question: "Q8: Create a class \"ComplexNumber\" for performing complex number arithmetic, including addition, subtraction, and multiplication.",
      codeLanguage: "cpp",
      isOpen: false,
    },
  ]);
  const [questions8, setQuestions8] = useState([
    {
      question: "Q9: Develop a class \"Library\" to manage books, authors, and patrons, with methods for borrowing, returning, and searching books.",
      codeLanguage: "cpp",
      isOpen: false,
    },
  ]);
  

  const toggleAccordion = (index, questions, setQuestions) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].isOpen = !updatedQuestions[index].isOpen;
    setQuestions(updatedQuestions);
  };

  const changeCodeLanguage = (index, language, questions, setQuestions) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].codeLanguage = language;
    setQuestions(updatedQuestions);
  };

  

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };
  const divRef = useRef(null);
  return (
    <div className="testing">
      <Sidebar>
        <div className="containera">
          <div className="ch-content">
            <h2 className="ui header">
            <Link to="/ques18">
              <button
                className="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
              </Link>
              <Link to="/ques20">
                <button
                  className="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>
            <div className="main-body ui">
              <h2 className="ui left floated header" style={{ color: "#001C30" }}>
                Classes and Objects:
              </h2>
              <div className="ui clearing divider"></div>
              <CodeAccordion
                questions={questions}
                code={code}
                toggleAccordion={(index) => toggleAccordion(index, questions, setQuestions)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions, setQuestions)
                }
              /><br/>
              <CodeAccordion
                questions={questions1}
                code={code1}
                toggleAccordion={(index) => toggleAccordion(index, questions1, setQuestions1)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions1, setQuestions1)
                }
              /><br/>
              <CodeAccordion
                questions={questions2}
                code={code2}
                toggleAccordion={(index) => toggleAccordion(index, questions2, setQuestions2)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions2, setQuestions2)
                }
              /><br/>
              <CodeAccordion
                questions={questions3}
                code={code3}
                toggleAccordion={(index) => toggleAccordion(index, questions3, setQuestions3)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions3, setQuestions3)
                }
              /><br/>
              <CodeAccordion
                questions={questions4}
                code={code4}
                toggleAccordion={(index) => toggleAccordion(index, questions4, setQuestions4)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions4, setQuestions4)
                }
              /><br/>
              <CodeAccordion
                questions={questions5}
                code={code5}
                toggleAccordion={(index) => toggleAccordion(index, questions5, setQuestions5)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions5, setQuestions5)
                }
              /><br/>
              <CodeAccordion
                questions={questions6}
                code={code6}
                toggleAccordion={(index) => toggleAccordion(index, questions6, setQuestions6)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions6, setQuestions6)
                }
              /><br/>
              <CodeAccordion
                questions={questions7}
                code={code7}
                toggleAccordion={(index) => toggleAccordion(index, questions7, setQuestions7)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions7, setQuestions7)
                }
              /><br/>
              <CodeAccordion
                questions={questions8}
                code={code8}
                toggleAccordion={(index) => toggleAccordion(index, questions8, setQuestions8)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions8, setQuestions8)
                }
              /><br/>
              
            </div>
            <Link to="/ques18">
              <button
                className="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
              </Link>
              <Link to="/ques20">
                <button
                  className="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default Question19;
