import { useRef, useEffect } from "react";
import "../../Components/Chapters/Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "../PythonSideBar";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

const PythonC32 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };

  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor:'#D8D9DA'
  };
  const pc32code1 = `nums = [1, 2, 3, 4, 5]
for item in nums:
    print(item)`;
  const pc32code2 = `myTuple = ("John", "Smith", "Sarah", "Leah")

for item in myTuple:
    print(item)`;
  const pc32code3 = `myDict = {'a': 'Andrew', 'b': 'Andy', 'c': 'Lisa'}
for key in myDict:
    print(key)

for value in myDict.values():
    print(value)

for key, value in myDict.items():
    print(key, value)`;
  const pc32code4 = `class MyIterator:
def __init__(self, limit):
    self.limit = limit
    self.current = 0

def __iter__(self):
    return self

def __next__(self):
    if self.current < self.limit:
        result = self.current
        self.current += 1
        return result
    else:
        raise StopIteration

# Using the custom iterator
my_iter = MyIterator(5)
for num in my_iter:
print(num)`;
  return (
    <div class="testing">
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/python/file_handling">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              <br />
            </h2>

            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Python Iterators:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                Iterator is an object which contain some data values and we can
                iterate upon them. <br />
                <br />
                Iterators allow you to traverse through a collection of items,
                such as tuples, lists, dictionaries. They allow you to access
                each element in a sequence one at a time, without the need to
                access all the elements at once.
              </span>
              <br />
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Built-in Iterators:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                Python provides several built-in iterators and iterable objects.
                You can iterate through their items using a for loop. <br />
                <br />
                Some of the most commonly used iterators include: <br />
                <ul className="ui unordered list">
                  <li>List</li>
                  <li>Tuple</li>
                  <li>Dictionary</li>
                </ul>
              </span>
              <br />
              
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                List:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                We use a for loop to iterate through each element in the list.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div style={{ fontSize: "15px" }}>
                  <b style={{ color: "black" }}>iterators.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc32code1}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />

              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Tuple:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                We use a for loop to iterate through each element in the tuple.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div style={{ fontSize: "15px" }}>
                  <b style={{ color: "black" }}>iterators.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc32code2}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />

              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Dictionaries:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                We use a for loop to iterate through each element in the
                dictionary.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div style={{ fontSize: "15px" }}>
                  <b style={{ color: "black" }}>iterators.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc32code3}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />

              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Creating an Iterator:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                You can create your own custom iterators by defining a class
                that implements the iterator protocol.
              </span>
              <br />
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                The Iterator Protocol:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                In Python, an object is considered iterable if it follows the
                iterator protocol, which means it must implement two methods:
                <br />
                <ul className="ui unordered list">
                  <li>
                    <b>__iter__() - </b>returns the iterator object itself. It
                    is called when you create an iterator using the iter()
                    function or when you use a loop to iterate through an
                    iterable.
                  </li>
                  <li>
                    <b>__next__() -</b> returns the next value from the
                    iterable. It is called in a loop to get the next item. If
                    there are no more items to return, it will raise the
                    StopIteration exception.
                  </li>
                </ul>
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div style={{ fontSize: "15px" }}>
                  <b style={{ color: "black" }}>iterators.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc32code4}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <span>
                Iterators serve as a versatile and potent instrument in Python
                for handling data collections. Proficiency in crafting custom
                iterators and utilizing built-in ones is crucial to ensure code
                efficiency and readability, especially when addressing data
                sequences. Whether you are managing extensive datasets, parsing
                files line by line, or interfacing with bespoke data structures,
                iterators offer a flexible approach to accessing and
                manipulating your data.
              </span>

              
            </div><br />
            <Link to="/python/file_handling">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            {/* <Link to="/python/Chapter4"> */}
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            {/* </Link> */}
            <br />
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </div>
  );
};
export default PythonC32;
