import { useRef, useEffect } from "react";
// import "./Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Sidebar from "../CSideBar";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const CC2 = () => {
  const divRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };

  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };
  const cc2code1 = `#include <stdio.h>

  int main() {
    printf("Hello World!");
    return 0;
}`;

  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/C/introduction">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/C/syntax">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body ">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Environment to write code:
              </h2>
              <div class="ui clearing divider"></div>
              <p style={{ backgroundColor: "", padding: "" }}>
                Environment to write code means that the environment where we
                can write and run (compile) our code. To write code, we need a
                text editor. We need a compiler to compile our code which will
                convert our code to machine understandable code.
                <br />
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Compiler:
                </h2>
                <div class="ui clearing divider"></div>
                Compiler translates instructions written in a high-level
                programming language into the equivalent machine code. There are
                many compilers which are available in the market. For C
                language, you can use gcc which is good as far as 2024.
                <br />
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Integrated Development Environment (IDE):
                </h2>
                <div class="ui clearing divider"></div>
                IDE functions as an editor as well as a compiler. There are
                different IDEs available like:
                <div className="margin">
                  <ul class="ui list">
                    <li>Visual Studio</li>
                    <li>Code Blocls</li>
                    <li>Eclipse</li>
                    <li>VS Code</li>
                    <li>CLion</li>
                  </ul>
                </div>
                <br />
                These are all free and you can use any of these to run your
                code. <br />
                <br />
              </p>
              <p style={{ backgroundColor: "#FBEEAC", padding: "10px" }}>
                <b>Note:</b> Online Integrated Development Environments (IDEs)
                are also available but they have limited functionality. Download
                the Integrated Development Environments (IDEs) listed above for
                better experience.
              </p>
              <br />
              <p>
                After setting up the environment, open the new file. Write the
                following code and save it with ".c" extension.
              </p>
              <br />
              <div style={containerStyle}>
                <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                  <b>syntax.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {cc2code1}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Flowchart for Code Compilation:
              </h2>
              <div class="ui clearing divider"></div>

              <br />
              <img
                src="/images/flowchart.jpg"
                alt="flowchart"
                style={{ width: "60%", margin: "auto" }}
              />
              <br />
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Flowchart Explanation:
              </h2>
              <div class="ui clearing divider"></div>
              <ul className="ui unordered list">
                <li>
                  <h1 style={{ fontSize: "20px", fontWeight: "500" }}>
                    Source Code
                  </h1>
                  <br />
                  Source code is the code which we write using a programming
                  language like C, C++, Java, Python etc.
                </li>
                <br />
                <li>
                  <h1 style={{ fontSize: "20px", fontWeight: "500" }}>
                    Preprocessor
                  </h1>
                  <br />
                  Removes all the comments and added header files and does stuff
                  like that and send code to the compiler for compilation.
                </li>
                <br />
                <li>
                  <h1 style={{ fontSize: "20px", fontWeight: "500" }}>
                    Compiler
                  </h1>
                  <br />
                  Compiler compiles the code and convert the code into assembly
                  code. For C language, gcc is available.
                </li>
                <br />
                <li>
                  <h1 style={{ fontSize: "20px", fontWeight: "500" }}>
                    Assembler
                  </h1>
                  <br />
                  Assembler converts the assembly code into machine code.
                  Assembler gives .obj file as output.
                </li>
                <br />
                <li>
                  <h1 style={{ fontSize: "20px", fontWeight: "500" }}>
                    Linker
                  </h1>
                  <br />
                  Linker links static libraries with our machine code and makes
                  it executable. Linker gives .exe file as output.
                </li>
              </ul>
              <br />

              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Components of C Code:
              </h2>
              <div class="ui clearing divider"></div>
              <ul className="ui unordered list">
                <li>
                  <b>Header Files : </b>They provide functionality.
                </li>
                <li>
                  <b>Main Function : </b>All code is written in Main function.
                </li>
                <li>
                  <b>Return Statement : </b>It returns from the main function.
                </li>
              </ul>

              <p>
                We will understand this code in the next chapter. <br />
                Your code should be syntactically correct so that it will
                execute without any error.
              </p>
            </div>
            <br />
            <Link to="/C/introduction">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/C/syntax">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default CC2;
