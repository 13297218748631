import React from "react";
import { Link } from "react-router-dom";
import { FaFacebook, FaInstagram, FaTwitter, FaGithub } from "react-icons/fa";
import "./Footer.css";

const Footer = () => {
  return (
    <section>
      <footer
        className="bg-white px-4 text-black flex flex-col md:flex-row justify-between gap-6 md:gap-0 border-t-2 py-8 w-full"
        style={{ backgroundColor: "#F5F7F8" }}
      >
        <div className="flex flex-col justify-center items-center w-full md:w-1/2">
          <Link to="/">
            <img
              src="/images/logo.png"
              alt="logo"
              style={{ marginLeft: "30%", width: "40%" }}
            />
          </Link>
          <p className="text-gray-600">
            Master programming, Create Limitless Possibilities!
          </p>

          <div className="flex gap-3 mt-2 text-xl">
            <Link
              to="https://github.com/SajeelAhmad1"
              target="_blank"
              style={{ color: "black" }}
            >
              <FaGithub />
            </Link>
            <Link
              to="https://www.facebook.com/profile.php?id=100008395144945"
              target="_blank"
              style={{ color: "black" }}
            >
              <FaFacebook />
            </Link>
            <Link
              to="https://instagram.com/_sajeel_ahmad_?igshid=MWY0cXF5aGJvNzh2YQ=="
              target="_blank"
              style={{ color: "black" }}
            >
              <FaInstagram />
            </Link>
          </div>
        </div>
        <div className="flex justify-center gap-32 w-full md:w-1/2">
          <div className="text-gray-600">
            <h2 className="text-black text-2xl">Quick Links</h2>
            <br />
            <div className="flex flex-col">
              <Link to="/" className="hover:text-black">
                Home
              </Link>
              <Link to="/tutorials" className="hover:text-black">
                Tutorials
              </Link>
              <Link to="/cheatsheets" className="hover:text-black">
                Cheat Sheets
              </Link>
              <Link to="/ques1" className="hover:text-black">
                Challenges
              </Link>
            </div>
          </div>
          <div className="text-gray-600">
            <h2 className="text-black text-2xl">Help</h2>
            <br />
            <div className="flex flex-col">
              <Link to="/privacypolicy" className="hover:text-black">
                Privacy Policy
              </Link>
              <Link to="/disclaimer" className="hover:text-black">
                Disclaimer
              </Link>
              <Link to="/aboutus" className="hover:text-black">
                About Us
              </Link>
              <Link to="/contact" className="hover:text-black">
                Contact
              </Link>
            </div>
          </div>
        </div>
      </footer>
      <div className="under-footer">
        <div className="under-footer-text">
          Copyright(c) 2023{" "}
          <Link className="under-footer-link" to="/">
            <b>Code Progs</b>
          </Link>{" "}
          All rights reserved.
        </div>
      </div>
    </section>
  );
};

export default Footer;
