import { useState, useEffect } from "react";
import Sidebar from "./SideBar";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
import "./Quiz.css";
import "../../Components/SideBar/App.css";
import Modal from "react-modal";

const CQuiz20 = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  const [answers, setAnswers] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleQuizSubmit = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const questions = [
    {
      id: 1,
      question: 'What is a structure in C:',
      options: [
        "A type of loop", " A user-defined data type that groups together variables of different data types under a single name",  "Both A and B"
      ],
      correctAnswer: " A user-defined data type that groups together variables of different data types under a single name",
    },
    {
      id: 2,
      question: 'How are struct variables declared in C:',
      options: [
        "Inside the main function", "With the var keyword", "With the struct keyword followed by a name" , "Using the initialize keyword" 
      ],
      correctAnswer: "With the struct keyword followed by a name",
    },
    {
      id: 3,
      question: 'What are the variables inside a struct called:',
      options: ["Parameters", "Variables", "Members", "Constants"],
      correctAnswer: "Members",
    },
    {
      id: 4,
      question: 'How do you access the members of a struct in C:',
      options: ["Using the -> operator", "Using the . operator", "Using the : operator", "Using the = operator"],
      correctAnswer: "Using the . operator",
    },
    {
      id: 5,
      question: 'What is the purpose of having multiple instances of a struct in C:',
      options: ["It is not allowed in C.", "To save memory", " To demonstrate pointer usage", "To create distinct data sets with the same structure"],
      correctAnswer: "To create distinct data sets with the same structure",
    },
    {
      id: 6,
      question: 'How does a union differ from a struct in terms of memory allocation:',
      options: ["A union allocates memory separately for each member.", "A union allocates memory only for the largest member, and all members share the same memory location.", "A union does not allocate any memory."," A union allocates memory for each member, but it's automatically freed after use."],
      correctAnswer: "A union allocates memory only for the largest member, and all members share the same memory location.",
    },
    {
      id: 7,
      question: 'Can functions be declared inside a union in C:',
      options: ["Yes, functions can be declared inside a union.", "No, functions are not allowed inside unions.", "Functions are not allowed in C.", "Only function prototypes can be declared inside a union."],
      correctAnswer: "No, functions are not allowed inside unions.",
    },
    {
      id: 8,
      question: 'How does a union optimize memory compared to a struct with the same data members:',
      options: ["A union uses more memory than a struct.", "A union uses the same amount of memory as a struct.", "A union uses less memory than a struct.", "Memory optimization is not related to unions."],
      correctAnswer: "A union uses less memory than a struct.",
    },
    
    
    
  ];
  const handleAnswerChange = (questionId, selectedAnswer) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: selectedAnswer,
    }));
  };

  const calculateScore = () => {
    let correctCount = 0;
    let incorrectCount = 0;

    questions.forEach((question) => {
      const userAnswer = answers[question.id];
      if (userAnswer === question.correctAnswer) {
        correctCount++;
      } else {
        incorrectCount++;
      }
    });

    return { correctCount, incorrectCount };
  };

  const { correctCount, incorrectCount } = calculateScore();
  return (
    <>
      <Sidebar>
        <div class="container">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/quizes/c_quiz21">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                  }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/quizes/c_quiz23">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
            </h2>
            <h1 class="start content ui left floated header">Structures and Unions</h1>
            <div className="ui clearing divider"></div>

            <div class="main-body ">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Quiz # 22
              </h2>
              <span class="tut-btn">
                <Link to="/C/struct">
                  <button class="ui secondary button">Read Tutorial</button>
                </Link>
              </span>
              <div class="ui clearing divider"></div>
              <div>
                {questions.map((question, i) => (
                  <div key={question.id}>
                    <div class="con">
                      <div class="que">
                        Q{i + 1}:
                        <span class="one-per-left-margin">
                          {question.question}
                        </span>
                      </div>
                      {question.options.map((option) => (
                        <label class="label" key={option}>
                          <input
                            type="radio"
                            name={`question_${question.id}`}
                            value={option}
                            checked={answers[question.id] === option}
                            onChange={(e) =>
                              handleAnswerChange(question.id, e.target.value)
                            }
                          />
                          <span className="one-per-left-margin">{option}</span>
                          <br />
                        </label>
                      ))}
                    </div>
                    <hr />
                  </div>
                ))}
                <br />
                <center>
                  <button class="ui primary button" onClick={handleQuizSubmit}>
                    Submit Quiz
                  </button>
                </center>

                {/* Modal for displaying results */}
                <Modal
                  class="modal"
                  isOpen={isModalOpen}
                  onRequestClose={closeModal}
                >
                  <b style={{ fontSize: "20px" }}>Quiz Results</b>
                  <b>
                    <p>
                      Your score is {correctCount}/{questions.length}{" "}
                    </p>
                  </b>
                  {questions.map((question, i) => (
                    <div key={question.id}>
                      <p>
                        Q{i + 1}: {question.question}
                      </p>
                      <p>
                        <b>Correct Answer:</b> {question.correctAnswer}
                      </p>
                      <p
                        className={
                          `${question.correctAnswer}` ===
                          `${answers[question.id]}`
                            ? "correct"
                            : "wrong"
                        }
                      >
                        <b>Your Answer:</b>{" "}
                        {answers[question.id] || "Not answered"}
                      </p>
                      <hr />
                    </div>
                  ))}
                  <button class="ui secondary button" onClick={closeModal}>
                    Close
                  </button>
                  <br />
                  <br />
                </Modal>
              </div>

              <br />
            </div>
            <Link to="/quizes/c_quiz21">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                }}
              >
                Previous
              </button>
            </Link>
            <Link to="/quizes/c_quiz23">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
            <br />
          </div>
        </div>
      </Sidebar>

      <div class="sideBarBody">
        <Footer />
      </div>
    </>
  );
};
export default CQuiz20;
