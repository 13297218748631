import { useRef, useEffect } from "react";
import "./Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Q14 from "../Questions/Q14";
import Sidebar from "../SideBar/Sidebar";
import Footer from "../Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const Ch14b = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  const divRef = useRef(null);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };
  const code1 = `#include<iostream>
using namespace std;

int main(){
    for (int i = 1; i < 10; i++) {
        if (i == 5) {
            break; // Exit the loop when i is 5
        }
        cout << i << " "; // Output: 1 2 3 4
    }
}`;

  const code2 = `#include<iostream>
using namespace std;

int main(){
    for (int i = 1; i <= 5; i++) {
        if (i == 3) {
            continue; // skip the iteration when i = 3
        }
        cout << i << " "; // Output: 1 2 4 5
    }
}`;

  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/Cpp/while_loop">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/Cpp/arrays">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Break Statement:
              </h2>
              <div class="ui clearing divider"></div>
              <p>
                The break statement is a control inflow statement which is used
                to terminate the prosecution of a loop if the provided condition
                is true. Most commonly, break statement is used with loops and switch
                statements. When the break statement is encountered inside a
                loop, the program immediately exits that block, and the control
                moves out of the loop.
              </p>
              <br />

              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>break.cpp</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="cpp" style={darcula}>
                    {code1}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Output:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                As we can see in the code that the counter variable <b>i</b> is initialized with 1. 
                and the for loop will continue until the value of <b>i</b> is less than 10.
                But when we move inside the loop, we can see that when the value of <b>i</b> is 5, a 
                break statement is encountered which causes the loop to stop and the control will 
                move out of the loop. So the output will be 1 2 3 4.
              </span>

              <br /><br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Continue Statement:
              </h2>
              <div class="ui clearing divider"></div>
              <p>
                The continue statement is a control inflow statement which is used to
                skip the current iteration of the loop and continue to the next
                iteration if the provided condition is true. When the continue
                statement is encountered in a loop, it jumps to the next
                iteration, ignoring the code within the loop for the current
                iteration.
              </p>
              <br />

              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>continue.cpp</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="cpp" style={darcula}>
                    {code2}
                  </SyntaxHighlighter>
                </div>
              </div>
            </div>
            <br />
           
            <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Output:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                    As we can see from the given code that <b>i</b> is initialized with 1 and 
                    goes upto 5. But when we move inside the loop, we can see that when the value of <b>i</b>
                    is equal to 3, a continue statement is encountered. When a continue statement is encountered 
                    in a loop, it skips that iteration. So when <b>i</b> is equal to 3, that iteration is 
                    skipped. So that output will be 1 2 4 5.
              </span>
              <br />
              <br />
            <Link to="/Cpp/while_loop">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/Cpp/arrays">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default Ch14b;
