import { useState, useEffect } from "react";
import Sidebar from "./SideBar";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
// import "./Quiz.css";
import "../../Components/Chapters/Chapters.css";
import Modal from "react-modal";

const JQuiz19 = () => {
  const [answers, setAnswers] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const handleQuizSubmit = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const questions = [
    {
      id: 1,
      question: "  What is the primary advantage of using arrays in Java:",
      options: ["They can store data of different data types", "They have a dynamic size that can change during program execution", "They allow you to represent multiple data items of the same type in a single variable", "They automatically sort the data they store"],
      correctAnswer: "They allow you to represent multiple data items of the same type in a single variable",
    },
    {
      id: 2,
      question: "  In Java, what is the index of the first element in an array:",
      options: ["0", "1", "-1", "inex depends on the size of array"],
      correctAnswer: "0",
    },
    {
      id: 3,
      question: "  How do you change the value of an element in a Java array:",
      options: [
        "By using the \"modify\" keyword",
        "By calling a special \"setElement\" method",
        "You cannot change the value of an element in an array",
        "Variables can have multiple data types simultaneously.",
      ],
      correctAnswer: "By using the assignment operator and specifying the new value",
    },
    {
      id: 4,
      question: "  How can you find the length of an array in Java:",
      options: ["Using the \"length\" method", "By counting the number of elements in the array manually", "By using the \"size\" method", "Using the \"length\" property of the array"],
      correctAnswer: "Java Standard Library (Java API)",
    },
    {
        id: 5,
        question: "  Which loop is commonly used to iterate through elements of an array in Java:",
        options: ["while loop", "for loop", "do-while loop", "if-else statement"],
        correctAnswer: "for loop",
      },
      {
        id: 6,
        question: "  What is a 2D array in Java:",
        options: ["An array that has two elements", "An array with a length of 2", "An array that stores elements of different data types", "An array of arrays that creates a grid-like structure"],
        correctAnswer: "An array of arrays that creates a grid-like structure",
      },
      {
        id: 7,
        question: "  How do you access an element in a 2D array in Java:",
        options: ["By using the \"access\" keyword", "By specifying the row and column index", "By using the \"getElement\" method", "By using the \"get\" keyword followed by the element's coordinates"],
        correctAnswer: "By specifying the row and column index",
      },
  ];
  const handleAnswerChange = (questionId, selectedAnswer) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: selectedAnswer,
    }));
  };

  const calculateScore = () => {
    let correctCount = 0;
    let incorrectCount = 0;

    questions.forEach((question) => {
      const userAnswer = answers[question.id];
      if (userAnswer === question.correctAnswer) {
        correctCount++;
      } else {
        incorrectCount++;
      }
    });

    return { correctCount, incorrectCount };
  };

  const { correctCount, incorrectCount } = calculateScore();
  return (
    <>
      <Sidebar>
        <div class="container">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/quizes/java_quiz18">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                  }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/quizes/java_quiz20">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              </h2>
              <h1 class="start content ui left floated header">
                Arrays
              </h1>
              <div className="ui clearing divider"></div>
            

            <div class="main-body">
              <h2 class="ui left floated header">
                Quiz # 19
              </h2>
              <span class="tut-btn"><Link to="/java/arrays">
                  <button class="ui secondary button">Read Tutorial</button>
                </Link></span>
              <div class="ui clearing divider"></div>
              <div>
                {questions.map((question, i) => (
                  <div key={question.id}>
                    <div class="con">
                    <div class="que">Q{i+1}:<span class="one-per-left-margin">{question.question}</span></div>
                      {question.options.map((option) => (
                        <label class="label" key={option}>
                          <input
                            type="radio"
                            name={`question_${question.id}`}
                            value={option}
                            checked={answers[question.id] === option}
                            onChange={(e) =>
                              handleAnswerChange(question.id, e.target.value)
                            }
                          />
                          <span className="one-per-left-margin">{option}</span>
                          <br />
                        </label>
                      ))}
                    </div>
                    <hr />
                  </div>
                ))}<br/>
                <center><button class="ui primary button" onClick={handleQuizSubmit}>Submit Quiz</button></center>

                {/* Modal for displaying results */}
                <Modal class="modal" isOpen={isModalOpen} onRequestClose={closeModal}>
                  <b style={{fontSize:'20px'}}>Quiz Results</b>
                  <b><p>Your score is {correctCount}/{questions.length} </p></b>
                  {questions.map((question,i) => (
                    <div key={question.id}>
                      <p>Q{i+1}: {question.question}</p>
                      <p><b>Correct Answer:</b> {question.correctAnswer}</p>
                      <p className={`${question.correctAnswer}` === `${answers[question.id]}` ? "correct" : "wrong"}>
                        <b>Your Answer:</b> {answers[question.id] || "Not answered"}
                      </p>
                      <hr />
                    </div>
                  ))}
                  <button class="ui secondary button" onClick={closeModal}>Close</button><br /><br />
                </Modal>
              </div>

              <br />
            </div>
            <Link to="/quizes/java_quiz18">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                  }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/quizes/java_quiz20">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
            <br />
          </div>
        </div>
      </Sidebar>

      <div class="sideBarBody">
        <Footer />
      </div>
    </>
  );
};
export default JQuiz19;
