import Footer from "./Components/Home/Footer";
import { useEffect } from "react";

const Disclaimer = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when the component mounts
      }, []);
  return (
    <>
      <div className="bg-gray-100 pt-24 md:pt-28 pl-5 md:pl-28 pr-5 md:pr-28 pb-5 md:pb-24">
        <h1 className="text-4xl font-bold  mb-8 justify-items text-left underline">
          Disclaimer
        </h1>
        <p>Last updated: January 16, 2024</p>
        <h2 className="text-3xl font-bold  mb-8 justify-items text-left underline">
          Interpretation and Definitions
        </h2>
        <h3 className="text-2xl font-bold  mb-8 justify-items text-left underline">
          Interpretation
        </h3>
        <p>
          The words of which the initial letter is capitalized have the meanings
          defined under the following conditions. The following definitions will
          have the same meanings whether they appear in singular or in plural.
        </p><br />
        <h3 className="text-2xl font-bold  mb-8 justify-items text-left underline">
          Definitions
        </h3>
        <p>For the purposes of this Disclaimer:</p>
        <ul>
          <li>
            <strong>Company</strong> (referred to as either &quot;the
            Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in
            this Disclaimer) refers to Start Your Coding Bootcamp Now!.
          </li>
          <li>
            <strong>Service</strong> refers to the Website.
          </li>
          <li>
            <strong>You</strong> means that the individuals accessing the
            Service, or the company, or other legal entity on behalf of which
            such individual is accessing or using the Service, as applicable.
          </li>
          <li>
            <strong>Website</strong> refers to Start Your Coding Bootcamp Now!,
            accessible from{" "}
            <a
              href="https://codeprogs.com/"
              rel="external nofollow noopener"
              target="_blank"
            >
              https://codeprogs.com/
            </a>
          </li>
        </ul><br />
        <h2 className="text-3xl font-bold  mb-8 justify-items text-left underline">
          Disclaimer
        </h2>
        <p>
          The information contained on the Service is for educational purposes
          only.
        </p>

        <p>
          In no event shall the Company be liable for any direct, special,
          indirect, consequential, or incidental damages or any damages
          whatsoever, whether in an action of contract, negligence or other
          tort, arising out of or in connection with the use of the Website or
          the contents of the Service. The Company reserves the right to make
          additions, modifications, or deletions to the contents on the website
          at any time without prior notice.
        </p>
<br />
        

        <h2 className="text-3xl font-bold  mb-8 justify-items text-left underline">
          Fair Use Disclaimer
        </h2>
        <p>
          The content provided by the service is unique and does not contain any
          copyright content.
        </p>
        <p>
          As provided in section 107 of the United States Copyright law, the
          Company believes this constitutes a &quot;fair use&quot; of any such
          copyrighted material.
        </p><br />

        <h2 className="text-3xl font-bold  mb-8 justify-items text-left underline">
          Views Expressed Disclaimer
        </h2>
        <p>
          The Service may contain opinions and views which are those of the
          authors and do not necessarily reflect the official policy or position
          of any other author, agency, organization, employer or company,
          including the Company.
        </p>
<br />
        <h2 className="text-3xl font-bold  mb-8 justify-items text-left underline">
          No Responsibility Disclaimer
        </h2>
        <p>
          The information on the Service is provided with the understanding that
          the Company is not herein engaged in rendering legal, accounting, tax,
          or other professional services and advice. As such, it should not be
          used as a substitute for consultation with professional tax,
          accounting, legal or other competent advisers.
        </p>
        <p>
          In no event shall the Company or its suppliers be liable for any
          special, indirect, incidental, or consequential damages whatsoever
          arising out of or in connection with your access or use or inability
          to use or access the Website.
        </p><br />
        <h2 className="text-3xl font-bold  mb-8 justify-items text-left underline">
          &quot;Use at Your Own Risk&quot; Disclaimer
        </h2>
        <p>
          All information in the Service is provided &quot;as is&quot;, with no
          guarantee of completeness, timeliness, accuracy or of the results
          obtained from the use of this information, and without warranty of any
          express, kind or implied, including, but not limited to warranties of
          performance, merchantability and fitness for a particular purpose.
        </p>
        <p>
          The Company will not be liable to anyone for any decision made or
          action taken in reliance on the information given by the Service or
          for any special, similar or consequential damages, even if advised of
          the chances of such damages.
        </p><br />
        <h2 className="text-3xl font-bold  mb-8 justify-items text-left underline">
          Contact Us
        </h2>
        <p>If you have queries about this Disclaimer, You can contact Us:</p>
        <ul>
          <li>By email: sajeelahmad@codeprogs.com</li>
        </ul>
      </div>
      <Footer />
    </>
  );
};
export default Disclaimer;
