import { useState, useEffect } from "react";
import Sidebar from "./SideBar";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
// import "./Quiz.css";
import "../../Components/Chapters/Chapters.css";
import Modal from "react-modal";

const PQuiz14 = () => {
  const [answers, setAnswers] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const handleQuizSubmit = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const questions = [
    {
      id: 1,
      question: "  What is the primary characteristic of a Python set:",
      options: ["Changeable", "Ordered", "Unordered", "Unchangeable"],
      correctAnswer: "Unordered",
    },
    {
      id: 2,
      question: "  What happens when you add duplicate items to a set in Python:",
      options: ["They are added, and duplicates are kept", "They are added, and duplicates are removed", "An error occurs", "The set becomes empty"],
      correctAnswer: "They are added, and duplicates are removed",
    },
    {
      id: 3,
      question: "  How do you calculate the number of unique items in a set:",
      options: [
        "Using the \"size\" method",
        "Using the \"length\" method",
        "Using the \"len\" method",
        "Using the \"count\" method",
      ],
      correctAnswer: "Using the \"len\" method",
    },
    {
      id: 4,
      question: "  What data types can items in a set have:",
      options: [
        "Only integers",
        "Only strings",
        "Same data type",
        "Different data types",
      ],
      correctAnswer: "Different data types",
    },
    {
        id: 5,
        question: "  How do you access items in a set in Python:",
        options: [
          "Using indexes",
          "Using a loop",
          "Using the \"get\" method",
          "Using the \"getItem\" method",
        ],
        correctAnswer: "Using a loop",
      },
      {
        id: 6,
        question: "  Which method is used to check if a specific item is present in a set:",
        options: [
          "set.find(item)",
          "item.inset(set)",
          "item in set",
          "set.contains(item)",
        ],
        correctAnswer: "item in set",
      },
      {
        id: 7,
        question: "  What is the result of using the \"+\" operator to join two sets in Python:",
        options: [
          "A new set containing all items",
          "An error",
          "A list of items",
          "The sets are merged into one",
        ],
        correctAnswer: "A new set containing all items",
      },
      {
        id: 8,
        question: "  Which method is used to add a single element to a set:",
        options: [
          "set.insert(item)",
          "set.add(item)",
          "set.append(item)",
          "set.update(item)",
        ],
        correctAnswer: "set.add(item)",
      },
      {
        id: 9,
        question: "  How do you remove an element from a set without causing an error if it doesn't exist:",
        options: [
          "set.remove(item)",
          "set.delete(item)",
          "set.discard(item)",
          "set.extract(item)",
        ],
        correctAnswer: "set.discard(item)",
      },
      {
        id: 10,
        question: "  What method is used to find the intersection of two sets in Python:",
        options: [
          "set.intersection()",
          "set.common()",
          "set.intersect()",
          "set.match()",
        ],
        correctAnswer: "set.intersection()",
      },
  ];
  const handleAnswerChange = (questionId, selectedAnswer) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: selectedAnswer,
    }));
  };

  const calculateScore = () => {
    let correctCount = 0;
    let incorrectCount = 0;

    questions.forEach((question) => {
      const userAnswer = answers[question.id];
      if (userAnswer === question.correctAnswer) {
        correctCount++;
      } else {
        incorrectCount++;
      }
    });

    return { correctCount, incorrectCount };
  };

  const { correctCount, incorrectCount } = calculateScore();
  return (
    <>
      <Sidebar>
        <div class="container">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/quizes/python_quiz13">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                  }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/quizes/python_quiz15">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              </h2>
              <h1 class="start content ui left floated header">
                Sets
              </h1>
              <div className="ui clearing divider"></div>
            

            <div class="main-body">
              <h2 class="ui left floated header">
                Quiz # 14
              </h2>
              <span class="tut-btn"><Link to="/python/sets">
                  <button class="ui secondary button">Read Tutorial</button>
                </Link></span>
              <div class="ui clearing divider"></div>
              <div>
                {questions.map((question, i) => (
                  <div key={question.id}>
                    <div class="con">
                    <div class="que">Q{i+1}:<span class="one-per-left-margin">{question.question}</span></div>
                      {question.options.map((option) => (
                        <label class="label" key={option}>
                          <input
                            type="radio"
                            name={`question_${question.id}`}
                            value={option}
                            checked={answers[question.id] === option}
                            onChange={(e) =>
                              handleAnswerChange(question.id, e.target.value)
                            }
                          />
                          <span className="one-per-left-margin">{option}</span>
                          <br />
                        </label>
                      ))}
                    </div>
                    <hr />
                  </div>
                ))}<br/>
                <center><button class="ui primary button" onClick={handleQuizSubmit}>Submit Quiz</button></center>

                {/* Modal for displaying results */}
                <Modal class="modal" isOpen={isModalOpen} onRequestClose={closeModal}>
                  <b style={{fontSize:'20px'}}>Quiz Results</b>
                  <b><p>Your score is {correctCount}/{questions.length} </p></b>
                  {questions.map((question,i) => (
                    <div key={question.id}>
                      <p>Q{i+1}: {question.question}</p>
                      <p><b>Correct Answer:</b> {question.correctAnswer}</p>
                      <p className={`${question.correctAnswer}` === `${answers[question.id]}` ? "correct" : "wrong"}>
                        <b>Your Answer:</b> {answers[question.id] || "Not answered"}
                      </p>
                      <hr />
                    </div>
                  ))}
                  <button class="ui secondary button" onClick={closeModal}>Close</button><br /><br />
                </Modal>
              </div>

              <br />
            </div>
            <Link to="/quizes/python_quiz13">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                  }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/quizes/python_quiz15">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
            <br />
          </div>
        </div>
      </Sidebar>

      <div class="sideBarBody">
        <Footer />
      </div>
    </>
  );
};
export default PQuiz14;
