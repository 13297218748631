import React, { useState, useRef, useEffect } from "react";
import Sidebar from "./SideBar";
import "../../Components/SideBar/App.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const CodeAccordion = ({ questions, code, toggleAccordion, changeCodeLanguage }) => {
    const divRef = useRef(null);
    const copyContent = () => {
        const divContent = divRef.current.innerText;
        navigator.clipboard
          .writeText(divContent)
          .then(() => {
            toast.success("Content copied!", { position: "top-center" });
          })
          .catch((error) => {
            toast.error("Error copying content:", error, {
              position: "top-center",
            });
          });
      };
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };

  return (
    <div>
      {questions.map((item, index) => (
        <div key={index} className="border p-2 rounded-md">
          <div className="flex justify-between">
            <span className="text-lg font-semibold">
              {item.question}
            </span>
            <button
              className="bg-gray-200 px-2 py-1 rounded-md"
              onClick={() => toggleAccordion(index)}
            >
              {item.isOpen ? "-" : "+"}
            </button>
          </div>
          <div className={`mt-2 ${item.isOpen ? "block" : "hidden"}`}>
            <div className="mt-2 flex space-x-4">
              {Object.keys(code).map((language) => (
                <button
                  key={language}
                  onClick={() => changeCodeLanguage(index, language)}
                  className={`${
                    item.codeLanguage === language
                      ? "bg-blue-500 hover:bg-blue-600 text-white"
                      : "bg-gray-200 hover:bg-gray-300 text-gray-700"
                  } px-3 py-2 rounded-md transition duration-300 ease-in-out transform hover:scale-105`}
                >
                  {language}
                </button>
              ))}
            </div>
            <p className="text-gray-800">
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>output.cpp</b>
                  <i
                    className="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language={`${item.codeLanguage}`} style={darcula}>
                    {code[item.codeLanguage]}
                  </SyntaxHighlighter>
                </div>
              </div>
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

const Question20 = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when the component mounts
      }, []);
    const code = {
        cpp: `// C++ code
#include <iostream>
#include <string>
using namespace std;

class Animal {
protected:
    string name;
    int age;

public:
    Animal(const string& animalName, int animalAge) : name(animalName), age(animalAge) {}

    virtual void makeSound() {
        cout << "Animal makes a sound." << endl;
    }
};

class Dog : public Animal {
public:
    Dog(const string& dogName, int dogAge) : Animal(dogName, dogAge) {}

    void makeSound() override {
        cout << "Dog barks." << endl;
    }

    void fetch() {
        cout << "Dog fetches a ball." << endl;
    }
};

class Cat : public Animal {
public:
    Cat(const string& catName, int catAge) : Animal(catName, catAge) {}

    void makeSound() override {
        cout << "Cat meows." << endl;
    }

    void climb() {
        cout << "Cat climbs a tree." << endl;
    }
};

int main() {
    Animal* myDog = new Dog("Buddy", 2);
    Animal* myCat = new Cat("Whiskers", 3);

    myDog->makeSound();
    myCat->makeSound();

    Dog* myDogDerived = dynamic_cast<Dog*>(myDog);
    if (myDogDerived) {
        myDogDerived->fetch();
    }

    Cat* myCatDerived = dynamic_cast<Cat*>(myCat);
    if (myCatDerived) {
        myCatDerived->climb();
    }

    delete myDog;
    delete myCat;

    return 0;
}`,
        java: `// Java code
class Animal {
    protected String name;
    protected int age;

    public Animal(String animalName, int animalAge) {
        name = animalName;
        age = animalAge;
    }

    public void makeSound() {
        System.out.println("Animal makes a sound.");
    }
}

class Dog extends Animal {
    public Dog(String dogName, int dogAge) {
        super(dogName, dogAge);
    }

    @Override
    public void makeSound() {
        System.out.println("Dog barks.");
    }

    public void fetch() {
        System.out.println("Dog fetches a ball.");
    }
}

class Cat extends Animal {
    public Cat(String catName, int catAge) {
        super(catName, catAge);
    }

    @Override
    public void makeSound() {
        System.out.println("Cat meows.");
    }

    public void climb() {
        System.out.println("Cat climbs a tree.");
    }
}

public class Main {
    public static void main(String[] args) {
        Animal myDog = new Dog("Buddy", 2);
        Animal myCat = new Cat("Whiskers", 3);

        myDog.makeSound();
        myCat.makeSound();

        if (myDog instanceof Dog) {
            Dog myDogDerived = (Dog) myDog;
            myDogDerived.fetch();
        }

        if (myCat instanceof Cat) {
            Cat myCatDerived = (Cat) myCat;
            myCatDerived.climb();
        }
    }
}`,
        python: `# Python code
class Animal:
    def __init__(self, animal_name, animal_age):
        self.name = animal_name
        self.age = animal_age

    def make_sound(self):
        print("Animal makes a sound")

class Dog(Animal):
    def __init__(self, dog_name, dog_age):
        super().__init__(dog_name, dog_age)

    def make_sound(self):
        print("Dog barks")

    def fetch(self):
        print("Dog fetches a ball")

class Cat(Animal):
    def __init__(self, cat_name, cat_age):
        super().__init__(cat_name, cat_age)

    def make_sound(self):
        print("Cat meows")

    def climb(self):
        print("Cat climbs a tree")

my_dog = Dog("Buddy", 2)
my_cat = Cat("Whiskers", 3)

my_dog.make_sound()
my_cat.make_sound()

if isinstance(my_dog, Dog):
    my_dog.fetch()

if isinstance(my_cat, Cat):
    my_cat.climb()`,
    };
    const code1 = {
        cpp: `// C++ code
#include <iostream>
#include <cmath>
using namespace std;

class Shape {
public:
    virtual double calculateArea() const = 0;
    virtual double calculatePerimeter() const = 0;
};

class Circle : public Shape {
private:
    double radius;

public:
    Circle(double r) : radius(r) {}

    double calculateArea() const override {
        return M_PI * radius * radius;
    }

    double calculatePerimeter() const override {
        return 2 * M_PI * radius;
    }
};

class Rectangle : public Shape {
private:
    double length;
    double width;

public:
    Rectangle(double l, double w) : length(l), width(w) {}

    double calculateArea() const override {
        return length * width;
    }

    double calculatePerimeter() const override {
        return 2 * (length + width);
    }
};

int main() {
    Circle circle(5.0);
    Rectangle rectangle(4.0, 6.0);

    cout << "Circle Area: " << circle.calculateArea() << " Perimeter: " << circle.calculatePerimeter() << endl;
    cout << "Rectangle Area: " << rectangle.calculateArea() << " Perimeter: " << rectangle.calculatePerimeter() << endl;

    return 0;
}`,
        java: `// Java code
abstract class Shape {
    public abstract double calculateArea();
    public abstract double calculatePerimeter();
}

class Circle extends Shape {
    private double radius;

    public Circle(double r) {
        radius = r;
    }

    @Override
    public double calculateArea() {
        return Math.PI * radius * radius;
    }

    @Override
    public double calculatePerimeter() {
        return 2 * Math.PI * radius;
    }
}

class Rectangle extends Shape {
    private double length;
    private double width;

    public Rectangle(double l, double w) {
        length = l;
        width = w;
    }

    @Override
    public double calculateArea() {
        return length * width;
    }

    @Override
    public double calculatePerimeter() {
        return 2 * (length + width);
    }
}

public class Main {
    public static void main(String[] args) {
        Circle circle = new Circle(5.0);
        Rectangle rectangle = new Rectangle(4.0, 6.0);

        System.out.println("Circle Area: " + circle.calculateArea() + " Perimeter: " + circle.calculatePerimeter());
        System.out.println("Rectangle Area: " + rectangle.calculateArea() + " Perimeter: " + rectangle.calculatePerimeter());
    }
}`,
        python: `# Python code
from abc import ABC, abstractmethod
import math

class Shape(ABC):
    @abstractmethod
    def calculate_area(self):
        pass

    @abstractmethod
    def calculate_perimeter(self):
        pass

class Circle(Shape):
    def __init__(self, radius):
        self.radius = radius

    def calculate_area(self):
        return math.pi * self.radius ** 2

    def calculate_perimeter(self):
        return 2 * math.pi * self.radius

class Rectangle(Shape):
    def __init__(self, length, width):
        self.length = length
        self.width = width

    def calculate_area(self):
        return self.length * self.width

    def calculate_perimeter(self):
        return 2 * (self.length + self.width)

circle = Circle(5.0)
rectangle = Rectangle(4.0, 6.0)

print(f"Circle Area: {circle.calculate_area()} Perimeter: {circle.calculate_perimeter()}")
print(f"Rectangle Area: {rectangle.calculate_area()} Perimeter: {rectangle.calculate_perimeter()}")`,
    };
    const code2 = {
        cpp: `// C++ code
#include <iostream>
#include <string>
using namespace std;

class Vehicle {
protected:
    string type;
    int wheels;

public:
    Vehicle(const string& vehicleType, int numWheels)
        : type(vehicleType), wheels(numWheels) {}

    void displayInfo() {
        cout << "Type: " << type << ", Wheels: " << wheels << " wheels" << endl;
    }
};

class Car : public Vehicle {
public:
    Car() : Vehicle("Car", 4) {}
};

class Bike : public Vehicle {
public:
    Bike() : Vehicle("Bike", 2) {}
};

int main() {
    Car myCar;
    Bike myBike;

    myCar.displayInfo();
    myBike.displayInfo();

    return 0;
}`,
        java: `// Java code
class Vehicle {
    protected String type;
    protected int wheels;

    public Vehicle(String vehicleType, int numWheels) {
        type = vehicleType;
        wheels = numWheels;
    }

    public void displayInfo() {
        System.out.println("Type: " + type + ", Wheels: " + wheels + " wheels");
    }
}

class Car extends Vehicle {
    public Car() {
        super("Car", 4);
    }
}

class Bike extends Vehicle {
    public Bike() {
        super("Bike", 2);
    }
}

public class Main {
    public static void main(String[] args) {
        Car myCar = new Car();
        Bike myBike = new Bike();

        myCar.displayInfo();
        myBike.displayInfo();
    }
}`,
        python: `# Python code
class Vehicle:
    def __init__(self, vehicle_type, num_wheels):
        self.type = vehicle_type
        self.wheels = num_wheels

    def display_info(self):
        print(f"Type: {self.type}, Wheels: {self.wheels} wheels")

class Car(Vehicle):
    def __init__(self):
        super().__init__("Car", 4)

class Bike(Vehicle):
    def __init__(self):
        super().__init__("Bike", 2)

my_car = Car()
my_bike = Bike()

my_car.display_info()
my_bike.display_info()`,
    };
    const code3 = {
        cpp: `// C++ code
#include <iostream>
#include <string>
using namespace std;

class Vehicle {
protected:
    string type;
    int wheels;

public:
    Vehicle(const string& vehicleType, int numWheels)
        : type(vehicleType), wheels(numWheels) {}

    void displayInfo() {
        cout << "Type: " << type << ", Wheels: " << wheels << " wheels" << endl;
    }
};

class Car : public Vehicle {
public:
    Car() : Vehicle("Car", 4) {}
};

class Bike : public Vehicle {
public:
    Bike() : Vehicle("Bike", 2) {}
};

class Truck : public Vehicle {
public:
    Truck() : Vehicle("Truck", 6) {}
};

class Motorcycle : public Vehicle {
public:
    Motorcycle() : Vehicle("Motorcycle", 2) {}
};

int main() {
    Car myCar;
    Bike myBike;
    Truck myTruck;
    Motorcycle myMotorcycle;

    myCar.displayInfo();
    myBike.displayInfo();
    myTruck.displayInfo();
    myMotorcycle.displayInfo();

    return 0;
}`,
        java: `// Java code
class Vehicle {
    protected String type;
    protected int wheels;

    public Vehicle(String vehicleType, int numWheels) {
        type = vehicleType;
        wheels = numWheels;
    }

    public void displayInfo() {
        System.out.println("Type: " + type + ", Wheels: " + wheels + " wheels");
    }
}

class Car extends Vehicle {
    public Car() {
        super("Car", 4);
    }
}

class Bike extends Vehicle {
    public Bike() {
        super("Bike", 2);
    }
}

class Truck extends Vehicle {
    public Truck() {
        super("Truck", 6);
    }
}

class Motorcycle extends Vehicle {
    public Motorcycle() {
        super("Motorcycle", 2);
    }
}

public class Main {
    public static void main(String[] args) {
        Car myCar = new Car();
        Bike myBike = new Bike();
        Truck myTruck = new Truck();
        Motorcycle myMotorcycle = new Motorcycle();

        myCar.displayInfo();
        myBike.displayInfo();
        myTruck.displayInfo();
        myMotorcycle.displayInfo();
    }
}`,
        python: `# Python code
class Vehicle:
    def __init__(self, vehicle_type, num_wheels):
        self.type = vehicle_type
        self.wheels = num_wheels

    def display_info(self):
        print(f"Type: {self.type}, Wheels: {self.wheels} wheels")

class Car(Vehicle):
    def __init__(self):
        super().__init__("Car", 4)

class Bike(Vehicle):
    def __init__(self):
        super().__init__("Bike", 2)

class Truck(Vehicle):
    def __init__(self):
        super().__init__("Truck", 6)

class Motorcycle(Vehicle):
    def __init__(self):
        super().__init__("Motorcycle", 2)

my_car = Car()
my_bike = Bike()
my_truck = Truck()
my_motorcycle = Motorcycle()

my_car.display_info()
my_bike.display_info()
my_truck.display_info()
my_motorcycle.display_info()`,
    };
    const code4 = {
        cpp: `// C++ code
#include <iostream>
using namespace std;

class Shape {
public:
    virtual double calculateArea() const = 0;
    virtual double calculatePerimeter() const = 0;
};

class Circle : public Shape {
private:
    double radius;

public:
    Circle(double r) : radius(r) {}

    double calculateArea() const override {
        return 3.14159265359 * radius * radius;
    }

    double calculatePerimeter() const override {
        return 2 * 3.14159265359 * radius;
    }
};

class Rectangle : public Shape {
private:
    double length;
    double width;

public:
    Rectangle(double l, double w) : length(l), width(w) {}

    double calculateArea() const override {
        return length * width;
    }

    double calculatePerimeter() const override {
        return 2 * (length + width);
    }
};

int main() {
    Circle circle(5.0);
    Rectangle rectangle(4.0, 6.0);

    cout << "Circle Area: " << circle.calculateArea() << " Perimeter: " << circle.calculatePerimeter() << endl;
    cout << "Rectangle Area: " << rectangle.calculateArea() << " Perimeter: " << rectangle.calculatePerimeter() << endl;

    return 0;
}`,
        java: `// Java code
abstract class Shape {
    public abstract double calculateArea();
    public abstract double calculatePerimeter();
}

class Circle extends Shape {
    private double radius;

    public Circle(double r) {
        radius = r;
    }

    @Override
    public double calculateArea() {
        return Math.PI * radius * radius;
    }

    @Override
    public double calculatePerimeter() {
        return 2 * Math.PI * radius;
    }
}

class Rectangle extends Shape {
    private double length;
    private double width;

    public Rectangle(double l, double w) {
        length = l;
        width = w;
    }

    @Override
    public double calculateArea() {
        return length * width;
    }

    @Override
    public double calculatePerimeter() {
        return 2 * (length + width);
    }
}

public class Main {
    public static void main(String[] args) {
        Circle circle = new Circle(5.0);
        Rectangle rectangle = new Rectangle(4.0, 6.0);

        System.out.println("Circle Area: " + circle.calculateArea() + " Perimeter: " + circle.calculatePerimeter());
        System.out.println("Rectangle Area: " + rectangle.calculateArea() + " Perimeter: " + rectangle.calculatePerimeter());
    }
}`,
        python: `# Python code
from abc import ABC, abstractmethod

class Shape(ABC):
    @abstractmethod
    def calculate_area(self):
        pass

    @abstractmethod
    def calculate_perimeter(self):
        pass

class Circle(Shape):
    def __init__(self, radius):
        self.radius = radius

    def calculate_area(self):
        return 3.14159265359 * self.radius ** 2

    def calculate_perimeter(self):
        return 2 * 3.14159265359 * self.radius

class Rectangle(Shape):
    def __init__(self, length, width):
        self.length = length
        self.width = width

    def calculate_area(self):
        return self.length * self.width

    def calculate_perimeter(self):
        return 2 * (self.length + self.width)

circle = Circle(5.0)
rectangle = Rectangle(4.0, 6.0)

print(f"Circle Area: {circle.calculate_area()} Perimeter: {circle.calculate_perimeter()}")
print(f"Rectangle Area: {rectangle.calculate_area()} Perimeter: {rectangle.calculate_perimeter()}")`,
    };
    const code5 = {
        cpp: `// C++ code
#include <iostream>
#include <string>
using namespace std;

class Employee {
protected:
    string name;
    int employeeId;
    double salary;

public:
    Employee(const string& empName, int empId, double empSalary)
        : name(empName), employeeId(empId), salary(empSalary) {}

    virtual void displayDetails() {
        cout << "Name: " << name << ", Employee ID: " << employeeId << ", Salary: $" << salary << endl;
    }

    virtual void work() {
        cout << "Employee is working." << endl;
    }
};

class Manager : public Employee {
    int teamSize;

public:
    Manager(const string& empName, int empId, double empSalary, int size)
        : Employee(empName, empId, empSalary), teamSize(size) {}

    void displayDetails() override {
        cout << "Manager Details - ";
        Employee::displayDetails();
        cout << "Team Size: " << teamSize << " members" << endl;
    }

    void organizeMeetings() {
        cout << "Manager is organizing meetings." << endl;
    }
};

class Developer : public Employee {
    string programmingLanguage;

public:
    Developer(const string& empName, int empId, double empSalary, const string& language)
        : Employee(empName, empId, empSalary), programmingLanguage(language) {}

    void displayDetails() override {
        cout << "Developer Details - ";
        Employee::displayDetails();
        cout << "Programming Language: " << programmingLanguage << endl;
    }

    void writeCode() {
        cout << "Developer is writing code." << endl;
    }
};

int main() {
    Manager manager("Alice", 101, 75000, 5);
    Developer developer("Bob", 201, 60000, "Python");

    manager.displayDetails();
    manager.organizeMeetings();

    developer.displayDetails();
    developer.writeCode();

    return 0;
}`,
        java: `// Java code
class Employee {
    protected String name;
    protected int employeeId;
    protected double salary;

    public Employee(String empName, int empId, double empSalary) {
        name = empName;
        employeeId = empId;
        salary = empSalary;
    }

    public void displayDetails() {
        System.out.println("Name: " + name + ", Employee ID: " + employeeId + ", Salary: $" + salary);
    }

    public void work() {
        System.out.println("Employee is working.");
    }
}

class Manager extends Employee {
    private int teamSize;

    public Manager(String empName, int empId, double empSalary, int size) {
        super(empName, empId, empSalary);
        teamSize = size;
    }

    public void displayDetails() {
        System.out.println("Manager Details - " + super.name + ", Employee ID: " + super.employeeId + ", Salary: $" + super.salary);
        System.out.println("Team Size: " + teamSize + " members");
    }

    public void organizeMeetings() {
        System.out.println("Manager is organizing meetings.");
    }
}

class Developer extends Employee {
    private String programmingLanguage;

    public Developer(String empName, int empId, double empSalary, String language) {
        super(empName, empId, empSalary);
        programmingLanguage = language;
    }

    public void displayDetails() {
        System.out.println("Developer Details - " + super.name + ", Employee ID: " + super.employeeId + ", Salary: $" + super.salary);
        System.out.println("Programming Language: " + programmingLanguage);
    }

    public void writeCode() {
        System.out.println("Developer is writing code.");
    }
}

public class Main {
    public static void main(String[] args) {
        Manager manager = new Manager("Alice", 101, 75000, 5);
        Developer developer = new Developer("Bob", 201, 60000, "Python");

        manager.displayDetails();
        manager.organizeMeetings();

        developer.displayDetails();
        developer.writeCode();
    }
}`,
        python: `# Python code
class Employee:
    def __init__(self, emp_name, emp_id, emp_salary):
        self.name = emp_name
        self.employee_id = emp_id
        self.salary = emp_salary

    def display_details(self):
        print(f"Name: {self.name}, Employee ID: {self.employee_id}, Salary: \${self.salary}")

    def work(self):
        print("Employee is working.")

class Manager(Employee):
    def __init__(self, emp_name, emp_id, emp_salary, size):
        super().__init__(emp_name, emp_id, emp_salary)
        self.team_size = size

    def display_details(self):
        print(f"Manager Details - Name: {self.name}, Employee ID: {self.employee_id}, Salary: \${self.salary}")
        print(f"Team Size: {self.team_size} members")

    def organize_meetings(self):
        print("Manager is organizing meetings.")

class Developer(Employee):
    def __init__(self, emp_name, emp_id, emp_salary, language):
        super().__init__(emp_name, emp_id, emp_salary)
        self.programming_language = language

    def display_details(self):
        print(f"Developer Details - Name: {self.name}, Employee ID: {self.employee_id}, Salary: \${self.salary}")
        print(f"Programming Language: {self.programming_language}")

    def write_code(self):
        print("Developer is writing code.")

manager = Manager("Alice", 101, 75000, 5)
developer = Developer("Bob", 201, 60000, "Python")

manager.display_details()
manager.organize_meetings()

developer.display_details()
developer.write_code()`,
    };
    const code6 = {
        cpp: `// C++ code
#include <iostream>
#include <string>
using namespace std;

class GameObject {
protected:
    string name;
    int x, y;

public:
    GameObject(const string& objName, int posX, int posY)
        : name(objName), x(posX), y(posY) {}

    virtual void displayInfo() {
        cout << "Name: " << name << ", Position: (" << x << ", " << y << ")" << endl;
    }
};

class Player : public GameObject {
public:
    Player(const string& playerName, int posX, int posY)
        : GameObject(playerName, posX, posY) {}

    void displayInfo() override {
        cout << "Player Info - ";
        GameObject::displayInfo();
        cout << "Type: Player" << endl;
    }

    void move(int newX, int newY) {
        x = newX;
        y = newY;
        cout << "Player moved to position (" << x << ", " << y << ")." << endl;
    }
};

class Enemy : public GameObject {
public:
    Enemy(const string& enemyName, int posX, int posY)
        : GameObject(enemyName, posX, posY) {}

    void displayInfo() override {
        cout << "Enemy Info - ";
        GameObject::displayInfo();
        cout << "Type: Enemy" << endl;
    }

    void attack() {
        cout << "Enemy attacks the player." << endl;
    }
};

class Obstacle : public GameObject {
public:
    Obstacle(const string& obstacleName, int posX, int posY)
        : GameObject(obstacleName, posX, posY) {}

    void displayInfo() override {
        cout << "Obstacle Info - ";
        GameObject::displayInfo();
        cout << "Type: Obstacle" << endl;
    }

    void blockPath() {
        cout << "Obstacle blocks the path." << endl;
    }
};

int main() {
    Player player("Hero", 2, 3);
    Enemy enemy("Dragon", 6, 9);
    Obstacle obstacle("Wall", 4, 4);

    player.displayInfo();
    player.move(5, 6);

    enemy.displayInfo();
    enemy.attack();

    obstacle.displayInfo();
    obstacle.blockPath();

    return 0;
}`,
        java: `// Java code
class GameObject {
    protected String name;
    protected int x, y;

    public GameObject(String objName, int posX, int posY) {
        name = objName;
        x = posX;
        y = posY;
    }

    public void displayInfo() {
        System.out.println("Name: " + name + ", Position: (" + x + ", " + y + ")");
    }
}

class Player extends GameObject {
    public Player(String playerName, int posX, int posY) {
        super(playerName, posX, posY);
    }

    public void displayInfo() {
        System.out.println("Player Info - Name: " + name + ", Position: (" + x + ", " + y + "), Type: Player");
    }

    public void move(int newX, int newY) {
        x = newX;
        y = newY;
        System.out.println("Player moved to position (" + x + ", " + y + ").");
    }
}

class Enemy extends GameObject {
    public Enemy(String enemyName, int posX, int posY) {
        super(enemyName, posX, posY);
    }

    public void displayInfo() {
        System.out.println("Enemy Info - Name: " + name + ", Position: (" + x + ", " + y + "), Type: Enemy");
    }

    public void attack() {
        System.out.println("Enemy attacks the player.");
    }
}

class Obstacle extends GameObject {
    public Obstacle(String obstacleName, int posX, int posY) {
        super(obstacleName, posX, posY);
    }

    public void displayInfo() {
        System.out.println("Obstacle Info - Name: " + name + ", Position: (" + x + ", " + y + "), Type: Obstacle");
    }

    public void blockPath() {
        System.out.println("Obstacle blocks the path.");
    }
}

public class Main {
    public static void main(String[] args) {
        Player player = new Player("Hero", 2, 3);
        Enemy enemy = new Enemy("Dragon", 6, 9);
        Obstacle obstacle = new Obstacle("Wall", 4, 4);

        player.displayInfo();
        player.move(5, 6);

        enemy.displayInfo();
        enemy.attack();

        obstacle.displayInfo();
        obstacle.blockPath();
    }
}`,
        python: `# Python code
class GameObject:
    def __init__(self, obj_name, pos_x, pos_y):
        self.name = obj_name
        self.x = pos_x
        self.y = pos_y

    def display_info(self):
        print(f"Name: {self.name}, Position: ({self.x}, {self.y})")

class Player(GameObject):
    def __init__(self, player_name, pos_x, pos_y):
        super().__init__(player_name, pos_x, pos_y)

    def display_info(self):
        print(f"Player Info - Name: {self.name}, Position: ({self.x}, {self.y}), Type: Player")

    def move(self, new_x, new_y):
        self.x = new_x
        self.y = new_y
        print(f"Player moved to position ({self.x}, {self.y}).")

class Enemy(GameObject):
    def __init__(self, enemy_name, pos_x, pos_y):
        super().__init__(enemy_name, pos_x, pos_y)

    def display_info(self):
        print(f"Enemy Info - Name: {self.name}, Position: ({self.x}, {self.y}), Type: Enemy")

    def attack(self):
        print("Enemy attacks the player.")

class Obstacle(GameObject):
    def __init__(self, obstacle_name, pos_x, pos_y):
        super().__init__(obstacle_name, pos_x, pos_y)

    def display_info(self):
        print(f"Obstacle Info - Name: {self.name}, Position: ({self.x}, {self.y}), Type: Obstacle")

    def block_path(self):
        print("Obstacle blocks the path.")

player = Player("Hero", 2, 3)
enemy = Enemy("Dragon", 6, 9)
obstacle = Obstacle("Wall", 4, 4)

player.display_info()
player.move(5, 6)

enemy.display_info()
enemy.attack()

obstacle.display_info()
obstacle.block_path()`,
    };
    const code7 = {
        cpp: `// C++ code
#include <iostream>
#include <cmath>
using namespace std;

class Shape {
public:
    virtual double area() const = 0;
    virtual double perimeter() const = 0;
    virtual void displayInfo() const {
        cout << "Shape: Unknown" << endl;
    }
};

class Circle : public Shape {
private:
    double radius;

public:
    Circle(double r) : radius(r) {}

    double area() const override {
        return M_PI * radius * radius;
    }

    double perimeter() const override {
        return 2 * M_PI * radius;
    }

    void displayInfo() const override {
        cout << "Shape: Circle, Radius: " << radius << endl;
    }
};

class Rectangle : public Shape {
private:
    double width;
    double height;

public:
    Rectangle(double w, double h) : width(w), height(h) {}

    double area() const override {
        return width * height;
    }

    double perimeter() const override {
        return 2 * (width + height);
    }

    void displayInfo() const override {
        cout << "Shape: Rectangle, Width: " << width << ", Height: " << height << endl;
    }
};

int main() {
    Circle circle(5.0);
    Rectangle rectangle(4.0, 6.0);

    Shape* customShape = new Circle(3.0);

    circle.displayInfo();
    cout << "Circle Area: " << circle.area() << " Perimeter: " << circle.perimeter() << endl;

    rectangle.displayInfo();
    cout << "Rectangle Area: " << rectangle.area() << " Perimeter: " << rectangle.perimeter() << endl;

    customShape->displayInfo();
    cout << "Custom Shape Area: " << customShape->area() << " Perimeter: " << customShape->perimeter() << endl;

    delete customShape;

    return 0;
}`,
        java: `// Java code
abstract class Shape {
    public abstract double area();
    public abstract double perimeter();
    public void displayInfo() {
        System.out.println("Shape: Unknown");
    }
}

class Circle extends Shape {
    private double radius;

    public Circle(double r) {
        radius = r;
    }

    @Override
    public double area() {
        return Math.PI * radius * radius;
    }

    @Override
    public double perimeter() {
        return 2 * Math.PI * radius;
    }

    @Override
    public void displayInfo() {
        System.out.println("Shape: Circle, Radius: " + radius);
    }
}

class Rectangle extends Shape {
    private double width;
    private double height;

    public Rectangle(double w, double h) {
        width = w;
        height = h;
    }

    @Override
    public double area() {
        return width * height;
    }

    @Override
    public double perimeter() {
        return 2 * (width + height);
    }

    @Override
    public void displayInfo() {
        System.out.println("Shape: Rectangle, Width: " + width + ", Height: " + height);
    }
}

public class Main {
    public static void main(String[] args) {
        Circle circle = new Circle(5.0);
        Rectangle rectangle = new Rectangle(4.0, 6.0);

        Shape customShape = new Circle(3.0);

        circle.displayInfo();
        System.out.println("Circle Area: " + circle.area() + " Perimeter: " + circle.perimeter());

        rectangle.displayInfo();
        System.out.println("Rectangle Area: " + rectangle.area() + " Perimeter: " + rectangle.perimeter());

        customShape.displayInfo();
        System.out.println("Custom Shape Area: " + customShape.area() + " Perimeter: " + customShape.perimeter());
    }
}`,
        python: `# Python code
from abc import ABC, abstractmethod
import math

class Shape(ABC):
    @abstractmethod
    def area(self):
        pass

    @abstractmethod
    def perimeter(self):
        pass

    def display_info(self):
        print("Shape: Unknown")

class Circle(Shape):
    def __init__(self, r):
        self.radius = r

    def area(self):
        return math.pi * self.radius ** 2

    def perimeter(self):
        return 2 * math.pi * self.radius

    def display_info(self):
        print(f"Shape: Circle, Radius: {self.radius}")

class Rectangle(Shape):
    def __init__(self, w, h):
        self.width = w
        self.height = h

    def area(self):
        return self.width * self.height

    def perimeter(self):
        return 2 * (self.width + self.height)

    def display_info(self):
        print(f"Shape: Rectangle, Width: {self.width}, Height: {self.height}")

circle = Circle(5.0)
rectangle = Rectangle(4.0, 6.0)

custom_shape = Circle(3.0)

circle.display_info()
print(f"Circle Area: {circle.area()} Perimeter: {circle.perimeter()}")

rectangle.display_info()
print(f"Rectangle Area: {rectangle.area()} Perimeter: {rectangle.perimeter()}")

custom_shape.display_info()
print(f"Custom Shape Area: {custom_shape.area()} Perimeter: {custom_shape.perimeter()}")`,
    };
    const code8 = {
        cpp: `// C++ code
#include <iostream>
#include <string>
using namespace std;

class Account {
protected:
    string accountNumber;
    double balance;

public:
    Account(const string& accNumber, double initialBalance)
        : accountNumber(accNumber), balance(initialBalance) {}

    virtual void deposit(double amount) {
        balance += amount;
    }

    virtual void withdraw(double amount) {
        if (balance >= amount)
            balance -= amount;
        else
            cout << "Insufficient funds!" << endl;
    }

    virtual void displayInfo() const {
        cout << "Account Number: " << accountNumber << ", Balance: $" << balance << endl;
    }
};

class SavingsAccount : public Account {
    double interestRate;

public:
    SavingsAccount(const string& accNumber, double initialBalance, double rate)
        : Account(accNumber, initialBalance), interestRate(rate) {}

    void applyInterest() {
        balance += balance * interestRate;
    }

    void displayInfo() const override {
        cout << "Savings Account Number: " << accountNumber << ", Balance: $" << balance << ", Interest Rate: " << (interestRate * 100) << "%" << endl;
    }
};

class CheckingAccount : public Account {
    double overdraftLimit;

public:
    CheckingAccount(const string& accNumber, double initialBalance, double limit)
        : Account(accNumber, initialBalance), overdraftLimit(limit) {}

    void withdraw(double amount) override {
        if (balance + overdraftLimit >= amount)
            balance -= amount;
        else
            cout << "Exceeded overdraft limit! Cannot withdraw." << endl;
    }

    void displayInfo() const override {
        cout << "Checking Account Number: " << accountNumber << ", Balance: $" << balance << ", Overdraft Limit: $" << overdraftLimit << endl;
    }
};

int main() {
    SavingsAccount savings("SA12345", 1000, 0.03);
    CheckingAccount checking("CA67890", 500, 200);

    savings.displayInfo();
    savings.deposit(200);
    savings.applyInterest();
    savings.displayInfo();

    checking.displayInfo();
    checking.withdraw(700);
    checking.displayInfo();

    return 0;
}`,
        java: `// Java code
class Account {
    protected String accountNumber;
    protected double balance;

    public Account(String accNumber, double initialBalance) {
        accountNumber = accNumber;
        balance = initialBalance;
    }

    public void deposit(double amount) {
        balance += amount;
    }

    public void withdraw(double amount) {
        if (balance >= amount) {
            balance -= amount;
        } else {
            System.out.println("Insufficient funds!");
        }
    }

    public void displayInfo() {
        System.out.println("Account Number: " + accountNumber + ", Balance: $" + balance);
    }
}

class SavingsAccount extends Account {
    private double interestRate;

    public SavingsAccount(String accNumber, double initialBalance, double rate) {
        super(accNumber, initialBalance);
        interestRate = rate;
    }

    public void applyInterest() {
        balance += balance * interestRate;
    }

    @Override
    public void displayInfo() {
        System.out.println("Savings Account Number: " + accountNumber + ", Balance: $" + balance + ", Interest Rate: " + (interestRate * 100) + "%");
    }
}

class CheckingAccount extends Account {
    private double overdraftLimit;

    public CheckingAccount(String accNumber, double initialBalance, double limit) {
        super(accNumber, initialBalance);
        overdraftLimit = limit;
    }

    @Override
    public void withdraw(double amount) {
        if (balance + overdraftLimit >= amount) {
            balance -= amount;
        } else {
            System.out.println("Exceeded overdraft limit! Cannot withdraw.");
        }
    }

    @Override
    public void displayInfo() {
        System.out.println("Checking Account Number: " + accountNumber + ", Balance: $" + balance + ", Overdraft Limit: $" + overdraftLimit);
    }
}

public class Main {
    public static void main(String[] args) {
        SavingsAccount savings = new SavingsAccount("SA12345", 1000, 0.03);
        CheckingAccount checking = new CheckingAccount("CA67890", 500, 200);

        savings.displayInfo();
        savings.deposit(200);
        savings.applyInterest();
        savings.displayInfo();

        checking.displayInfo();
        checking.withdraw(700);
        checking.displayInfo();
    }
}`,
        python: `# Python code
class Account:
    def __init__(self, acc_number, initial_balance):
        self.account_number = acc_number
        self.balance = initial_balance

    def deposit(self, amount):
        self.balance += amount

    def withdraw(self, amount):
        if self.balance >= amount:
            self.balance -= amount
        else:
            print("Insufficient funds!")

    def display_info(self):
        print(f"Account Number: {self.account_number}, Balance: \${self.balance}")

class SavingsAccount(Account):
    def __init__(self, acc_number, initial_balance, rate):
        super().__init__(acc_number, initial_balance)
        self.interest_rate = rate

    def apply_interest(self):
        self.balance += self.balance * self.interest_rate

    def display_info(self):
        print(f"Savings Account Number: {self.account_number}, Balance: \${self.balance}, Interest Rate: {self.interest_rate * 100}%")

class CheckingAccount(Account):
    def __init__(self, acc_number, initial_balance, limit):
        super().__init__(acc_number, initial_balance)
        self.overdraft_limit = limit

    def withdraw(self, amount):
        if self.balance + self.overdraft_limit >= amount:
            self.balance -= amount
        else:
            print("Exceeded overdraft limit! Cannot withdraw.")

    def display_info(self):
        print(f"Checking Account Number: {self.account_number}, Balance: \${self.balance}, Overdraft Limit: \${self.overdraft_limit}")

savings = SavingsAccount("SA12345", 1000, 0.03)
checking = CheckingAccount("CA67890", 500, 200)

savings.display_info()
savings.deposit(200)
savings.apply_interest()
savings.display_info()

checking.display_info()
checking.withdraw(700)
checking.display_info()`,
    };


  const [questions, setQuestions] = useState([
    {
      question: "Q1: Create a base class \"Animal\" with derived classes \"Dog\" and \"Cat.\" Add properties and methods to each derived class.",
      codeLanguage: "cpp",
      isOpen: false,
    },
  ]);
  const [questions1, setQuestions1] = useState([
    {
      question: "Q2: Implement a base class \"Shape\" and create subclasses \"Circle\" and \"Rectangle\" to calculate area and perimeter.",
      codeLanguage: "cpp",
      isOpen: false,
    },
  ]);
  const [questions2, setQuestions2] = useState([
    {
      question: "Q3: Design a base class \"Vehicle\" with subclasses \"Car\" and \"Bike\" to represent different vehicle types.",
      codeLanguage: "cpp",
      isOpen: false,
    },
  ]);
  const [questions3, setQuestions3] = useState([
    {
      question: "Q4: Extend the \"Vehicle\" hierarchy with additional subclasses like \"Truck\" and \"Motorcycle.\"",
      codeLanguage: "cpp",
      isOpen: false,
    },
  ]);
  const [questions4, setQuestions4] = useState([
    {
      question: "Q5: Create an abstract base class \"Shape\" with methods for calculating area and perimeter and then create concrete shapes that inherit from it.",
      codeLanguage: "cpp",
      isOpen: false,
    },
  ]);
  const [questions5, setQuestions5] = useState([
    {
      question: "Q6: Develop a class hierarchy representing different types of employees (e.g., \"Manager,\" \"Developer\") with common and specific attributes/methods.",
      codeLanguage: "cpp",
      isOpen: false,
    },
  ]);
  const [questions6, setQuestions6] = useState([
    {
      question: "Q7: Implement a class hierarchy for a game with a base class \"GameObject\" and subclasses \"Player,\" \"Enemy,\" and \"Obstacle.\"",
      codeLanguage: "cpp",
      isOpen: false,
    },
  ]);
  const [questions7, setQuestions7] = useState([
    {
      question: "Q8: Create a class hierarchy for a geometric shapes library, allowing users to create custom shapes by inheriting from a base \"Shape\" class.",
      codeLanguage: "cpp",
      isOpen: false,
    },
  ]);
  const [questions8, setQuestions8] = useState([
    {
      question: "Q9: Develop a class hierarchy for a banking system, including classes like \"Account,\" \"SavingsAccount,\" and \"CheckingAccount\" with inheritance relationships.",
      codeLanguage: "cpp",
      isOpen: false,
    },
  ]);
  

  const toggleAccordion = (index, questions, setQuestions) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].isOpen = !updatedQuestions[index].isOpen;
    setQuestions(updatedQuestions);
  };

  const changeCodeLanguage = (index, language, questions, setQuestions) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].codeLanguage = language;
    setQuestions(updatedQuestions);
  };

  

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };
  const divRef = useRef(null);
  return (
    <div className="testing">
      <Sidebar>
        <div className="containera">
          <div className="ch-content">
            <h2 className="ui header">
            <Link to="/ques19">
              <button
                className="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
              </Link>
              <Link to="/ques21">
                <button
                  className="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>
            <div className="main-body ui">
              <h2 className="ui left floated header" style={{ color: "#001C30" }}>
                Inheritance:
              </h2>
              <div className="ui clearing divider"></div>
              <CodeAccordion
                questions={questions}
                code={code}
                toggleAccordion={(index) => toggleAccordion(index, questions, setQuestions)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions, setQuestions)
                }
              /><br/>
              <CodeAccordion
                questions={questions1}
                code={code1}
                toggleAccordion={(index) => toggleAccordion(index, questions1, setQuestions1)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions1, setQuestions1)
                }
              /><br/>
              <CodeAccordion
                questions={questions2}
                code={code2}
                toggleAccordion={(index) => toggleAccordion(index, questions2, setQuestions2)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions2, setQuestions2)
                }
              /><br/>
              <CodeAccordion
                questions={questions3}
                code={code3}
                toggleAccordion={(index) => toggleAccordion(index, questions3, setQuestions3)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions3, setQuestions3)
                }
              /><br/>
              <CodeAccordion
                questions={questions4}
                code={code4}
                toggleAccordion={(index) => toggleAccordion(index, questions4, setQuestions4)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions4, setQuestions4)
                }
              /><br/>
              <CodeAccordion
                questions={questions5}
                code={code5}
                toggleAccordion={(index) => toggleAccordion(index, questions5, setQuestions5)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions5, setQuestions5)
                }
              /><br/>
              <CodeAccordion
                questions={questions6}
                code={code6}
                toggleAccordion={(index) => toggleAccordion(index, questions6, setQuestions6)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions6, setQuestions6)
                }
              /><br/>
              <CodeAccordion
                questions={questions7}
                code={code7}
                toggleAccordion={(index) => toggleAccordion(index, questions7, setQuestions7)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions7, setQuestions7)
                }
              /><br/>
              <CodeAccordion
                questions={questions8}
                code={code8}
                toggleAccordion={(index) => toggleAccordion(index, questions8, setQuestions8)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions8, setQuestions8)
                }
              /><br/>
              
            </div>
            <Link to="/ques19">
              <button
                className="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
              </Link>
              <Link to="/ques21">
                <button
                  className="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default Question20;
