import { useState, useEffect } from "react";
import Sidebar from "./SideBar";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
// import "./Quiz.css";
import "../../Components/Chapters/Chapters.css";
import Modal from "react-modal";

const JQuiz13 = () => {
  const [answers, setAnswers] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const handleQuizSubmit = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const questions = [
    {
      id: 1,
      question: "  What does the boolean data type store in Java:",
      options: ["Only positive integers", "Textual data", "Values that can be either true or false", "Floating-point numbers"],
      correctAnswer: "Values that can be either true or false",
    },
    {
      id: 2,
      question: "  Which keyword is used to declare a boolean variable in Java:",
      options: ["var", "int", "bool", "boolean"],
      correctAnswer: "boolean",
    },
    {
      id: 3,
      question: "  How is a true boolean value represented in Java:",
      options: [
        "true",
        "1",
        "0",
        "false",
      ],
      correctAnswer: "true",
    },
    {
      id: 4,
      question: "  What is the result of the expression true && false in Java:",
      options: ["true", "false", "1", "0"],
      correctAnswer: "false",
    },
    {
        id: 5,
        question: "  Which logical operator returns true if either of its operands is true in Java:",
        options: ["&&", "||", "!", "&"],
        correctAnswer: "||",
      },
      {
        id: 6,
        question: "  How are booleans typically used in conditional statements in Java:",
        options: ["To store text data", "To perform arithmetic operations", "To control the flow of the program based on conditions", "To declare variables"],
        correctAnswer: "To control the flow of the program based on conditions",
      },
  ];
  const handleAnswerChange = (questionId, selectedAnswer) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: selectedAnswer,
    }));
  };

  const calculateScore = () => {
    let correctCount = 0;
    let incorrectCount = 0;

    questions.forEach((question) => {
      const userAnswer = answers[question.id];
      if (userAnswer === question.correctAnswer) {
        correctCount++;
      } else {
        incorrectCount++;
      }
    });

    return { correctCount, incorrectCount };
  };

  const { correctCount, incorrectCount } = calculateScore();
  return (
    <>
      <Sidebar>
        <div class="container">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/quizes/java_quiz12">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                  }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/quizes/java_quiz14">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              </h2>
              <h1 class="start content ui left floated header">
                Booleans
              </h1>
              <div className="ui clearing divider"></div>
            

            <div class="main-body">
              <h2 class="ui left floated header">
                Quiz # 13
              </h2>
              <span class="tut-btn"><Link to="/java/booleans">
                  <button class="ui secondary button">Read Tutorial</button>
                </Link></span>
              <div class="ui clearing divider"></div>
              <div>
                {questions.map((question, i) => (
                  <div key={question.id}>
                    <div class="con">
                    <div class="que">Q{i+1}:<span class="one-per-left-margin">{question.question}</span></div>
                      {question.options.map((option) => (
                        <label class="label" key={option}>
                          <input
                            type="radio"
                            name={`question_${question.id}`}
                            value={option}
                            checked={answers[question.id] === option}
                            onChange={(e) =>
                              handleAnswerChange(question.id, e.target.value)
                            }
                          />
                          <span className="one-per-left-margin">{option}</span>
                          <br />
                        </label>
                      ))}
                    </div>
                    <hr />
                  </div>
                ))}<br/>
                <center><button class="ui primary button" onClick={handleQuizSubmit}>Submit Quiz</button></center>

                {/* Modal for displaying results */}
                <Modal class="modal" isOpen={isModalOpen} onRequestClose={closeModal}>
                  <b style={{fontSize:'20px'}}>Quiz Results</b>
                  <b><p>Your score is {correctCount}/{questions.length} </p></b>
                  {questions.map((question,i) => (
                    <div key={question.id}>
                      <p>Q{i+1}: {question.question}</p>
                      <p><b>Correct Answer:</b> {question.correctAnswer}</p>
                      <p className={`${question.correctAnswer}` === `${answers[question.id]}` ? "correct" : "wrong"}>
                        <b>Your Answer:</b> {answers[question.id] || "Not answered"}
                      </p>
                      <hr />
                    </div>
                  ))}
                  <button class="ui secondary button" onClick={closeModal}>Close</button><br /><br />
                </Modal>
              </div>

              <br />
            </div>
            <Link to="/quizes/java_quiz12">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                  }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/quizes/java_quiz14">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
            <br />
          </div>
        </div>
      </Sidebar>

      <div class="sideBarBody">
        <Footer />
      </div>
    </>
  );
};
export default JQuiz13;
