import { useRef, useEffect } from "react";
import "../../Components/Chapters/Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "../PythonSideBar";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

const PythonC26 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };

  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };
  const pc26code1 = `class Animal:
  def speak(self):
      pass

class Dog(Animal):
  def speak(self):
      return "Woof!"

class Cat(Animal):
  def speak(self):
      return "Meow!"

def animal_sound(animal):
  print(animal.speak())

dog = Dog()
cat = Cat()

animal_sound(dog)  # Outputs: "Woof!"
animal_sound(cat)  # Outputs: "Meow!"`;
  const pc26code2 = `class Duck:
  def sound(self):
      return "Quack!"

class Human:
  def sound(self):
      return "Hello!"

def make_sound(entity):
  print(entity.sound())

duck = Duck()
person = Human()

make_sound(duck)    # Outputs: "Quack!"
make_sound(person)  # Outputs: "Hello!"`;

  return (
    <div class="testing">
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/python/inheritance">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/python/modules">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Polymorphism:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                Polymorphism - poly means many and morphs means forms.
                Polymorphism is the ability of an object to have more than one
                forms. For example, we have two or more objects from the same
                base class with methods with same name but their implementation
                is different.
              </span>
              <br />
              <br />
              <span>
                In Python, we can achieve polymorphism by using two methods;{" "}
                <br />
                <ul className="ui unordered list">
                  <li>Method Overriding</li>
                  <li>Duck Typing</li>
                </ul>
              </span>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Method Overriding:
              </h2>
              <div className="ui clearing divider"></div>
              <span>
                In Python, method overriding is a form of polymorphism where a
                subclass provides a specific implementation for a method that is
                already defined in its superclass. When an object of the
                subclass is used, the overridden method in the subclass is
                called instead of the method in the superclass.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>polymorphism.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc26code1}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <span>
                In this example, both Dog and Cat classes inherit from the
                Animal class and override the speak method. When the
                animal_sound function is called with different objects, it
                demonstrates polymorphism by calling the appropriate overridden
                speak method.
              </span>
              <br />
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Duck Typing:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                Python follows the principle of duck typing, which means that
                the type or class of an object is determined by its behavior
                (methods and attributes) rather than by its explicit type. If an
                object behaves like a certain class, it is treated as an
                instance of that class, regardless of its actual class.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>polymorphism.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc26code2}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <span>
                In this example, both Duck and Human classes have a sound
                method. The make_sound() function takes an argument and calls
                the sound method on it. This demonstrates polymorphism through
                duck typing, where the function works with objects of different
                classes as long as they have a compatible interface (in this
                case, we have the sound method).
              </span>
            </div>
            <br />
            <Link to="/python/inheritance">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/python/modules">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </div>
  );
};
export default PythonC26;
