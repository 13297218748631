import { useRef, useEffect } from "react";
import "../../Components/Chapters/Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "../PythonSideBar";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

const PythonC24 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };

  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor:'#D8D9DA'
  };
  const pc24code0 = `@decorator_name
def myFunction():
  # code goes here`
  const pc24code1 = `def my_decorator(original_function):
  def wrapper_function(*args, **kwargs):
      print("Before the function is called")
      result = original_function(*args, **kwargs)
      print("After the function is called")
      return result
  return wrapper_function

@my_decorator
def greet(name):
  print(f"Hello, {name}!")

greet("Leah")`;
  const pc24code2 = `class MyClass:
def __init__(self):
    self._my_attribute = None  

def getAttr(self):
    return self._my_attribute

def setAttr(self, value):
    if value < 0:
        raise ValueError("Value must be positive.")
    self._my_attribute = value

my_attribute = property(getAttr, setAttr)

obj = MyClass()
obj.my_attribute = 42  # Calls the setter
print(obj.my_attribute)  # Calls the getter`;
const pc24code3 = `@decorator1
@decorator2
def myFunction():
    # code goes here`
  return (
    <div class="testing">
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/python/scope">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/python/inheritance">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Decorators in Python:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                In Python, decorators are a powerful and flexible way to modify
                or enhance the behavior of functions or methods without changing
                their code. Decorators are essentially functions themselves, and
                they are used to wrap other functions or methods to extend or
                modify their functionality.
              </span>
              <br />
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Syntax:
              </h2>
              <div class="ui clearing divider"></div>
              <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc24code0}
                    </SyntaxHighlighter>
                  </div><br />
                  <p style={{ backgroundColor: "#FBEEAC", padding: "15px" }}>
                    <b>Note: </b>In Python, decorators are a powerful tool which extends the behavior of functions. 
                    They make our code clean and readable and increase reusability and maintainability of our Python code.
                  </p>
                  <br />
                  <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Example:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                In the following example, we will how decorators work in Python.
              </span>
                
              <br /><br />

                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>decorators.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc24code1}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <span>
                  In this example, the my_decorator function is used as a
                  decorator for the greet function. When you call
                  greet("Alice"), it actually calls the wrapper_function defined
                  inside my_decorator, which adds behavior before and after the
                  original greet function is called.
                </span>
                <br />
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Getters and Setter:
                </h2>
                <div class="ui clearing divider"></div>
                <span>
                  In Python, getters and setters are methods used to control
                  access to class attributes or instance variables. They are a
                  part of the concept of encapsulation, which helps in
                  maintaining data integrity and controlling how data is
                  accessed and modified. Getters are used to retrieve or get the value
                  of an attribute, and setters are used to modify or set the value of
                  an attribute while applying some validation or logic.
                </span>
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>decorators.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc24code2}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <p style={{ backgroundColor: "#FBEEAC", padding: "15px" }}>
                  <b>Note: </b>We can conditionally set
                  the value of data members using the setter method.
                </p>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Multiple Decorators:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                We can also use multiple decorators in Python code. Let's check the following code. 
              </span><br /><br />
              <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>decorators.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc24code3}
                    </SyntaxHighlighter>
                  </div>
                </div>
            </div><br />
            <Link to="/python/scope">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/python/inheritance">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </div>
  );
};
export default PythonC24;
