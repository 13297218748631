import { useState, useEffect } from "react";
import Sidebar from "./SideBar";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
// import "./Quiz.css";
import "../../Components/Chapters/Chapters.css";
import Modal from "react-modal";

const JQuiz24 = () => {
  const [answers, setAnswers] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const handleQuizSubmit = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const questions = [
    {
      id: 1,
      question: "  What is encapsulation in Java:",
      options: ["Bundling data and methods into a single unit", "Creating separate classes for data and methods", "Storing data in a public class", "Hiding data from the program"],
      correctAnswer: "Bundling data and methods into a single unit",
    },
    {
      id: 2,
      question: " What is the purpose of a getter method in encapsulation:",
      options: ["To modify the value of a private data member", "To provide controlled access to the data by returning its value", "To set the value of a data member", "To create a new instance of a class"],
      correctAnswer: "To provide controlled access to the data by returning its value",
    },
    {
      id: 3,
      question: "  How is a getter method named conventionally in Java:",
      options: [
        "It has no specific naming convention",
        "It starts with \"set\" followed by the data member's name",
        "It starts with \"get\" followed by the data member's name",
        "It starts with \"retrieve\" followed by the data member's name",
      ],
      correctAnswer: "It starts with \"get\" followed by the data member's name",
    },
    {
      id: 4,
      question: "  What is the purpose of a setter method in encapsulation:",
      options: ["To retrieve the value of a private data member", "To hide data from the program", "To provide controlled access to the data by accepting a new value", "To create a new instance of a class"],
      correctAnswer: "To provide controlled access to the data by accepting a new value",
    },
    {
        id: 5,
        question: "  How is a setter method named conventionally in Java:",
        options: ["It has no specific naming convention", "It starts with \"modify\" followed by the data member's name", "It starts with \"set\" followed by the data member's name", "It starts with \"get\" followed by the data member's name"],
        correctAnswer: "It starts with \"set\" followed by the data member's name",
      },
      {
        id: 6,
        question: "  What is the key benefit of encapsulation in Java:",
        options: ["Making all data members public for easy access", "Ensuring that all data is hidden from the program", "Controlling access to class fields and maintaining data integrity", "Eliminating the need for getter and setter methods"],
        correctAnswer: "Controlling access to class fields and maintaining data integrity",
      },
      {
        id: 7,
        question: "  In Java, encapsulation helps in achieving which important programming concept:",
        options: ["Polymorphism", "Abstraction", "Inheritance", "Encapsulation"],
        correctAnswer: "Encapsulation",
      },
  ];
  const handleAnswerChange = (questionId, selectedAnswer) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: selectedAnswer,
    }));
  };

  const calculateScore = () => {
    let correctCount = 0;
    let incorrectCount = 0;

    questions.forEach((question) => {
      const userAnswer = answers[question.id];
      if (userAnswer === question.correctAnswer) {
        correctCount++;
      } else {
        incorrectCount++;
      }
    });

    return { correctCount, incorrectCount };
  };

  const { correctCount, incorrectCount } = calculateScore();
  return (
    <>
      <Sidebar>
        <div class="container">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/quizes/java_quiz23">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                  }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/quizes/java_quiz25">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              </h2>
              <h1 class="start content ui left floated header">
                Encapsulation
              </h1>
              <div className="ui clearing divider"></div>
            

            <div class="main-body">
              <h2 class="ui left floated header">
                Quiz # 24
              </h2>
              <span class="tut-btn"><Link to="/java/encapsulation">
                  <button class="ui secondary button">Read Tutorial</button>
                </Link></span>
              <div class="ui clearing divider"></div>
              <div>
                {questions.map((question, i) => (
                  <div key={question.id}>
                    <div class="con">
                    <div class="que">Q{i+1}:<span class="one-per-left-margin">{question.question}</span></div>
                      {question.options.map((option) => (
                        <label class="label" key={option}>
                          <input
                            type="radio"
                            name={`question_${question.id}`}
                            value={option}
                            checked={answers[question.id] === option}
                            onChange={(e) =>
                              handleAnswerChange(question.id, e.target.value)
                            }
                          />
                          <span className="one-per-left-margin">{option}</span>
                          <br />
                        </label>
                      ))}
                    </div>
                    <hr />
                  </div>
                ))}<br/>
                <center><button class="ui primary button" onClick={handleQuizSubmit}>Submit Quiz</button></center>

                {/* Modal for displaying results */}
                <Modal class="modal" isOpen={isModalOpen} onRequestClose={closeModal}>
                  <b style={{fontSize:'20px'}}>Quiz Results</b>
                  <b><p>Your score is {correctCount}/{questions.length} </p></b>
                  {questions.map((question,i) => (
                    <div key={question.id}>
                      <p>Q{i+1}: {question.question}</p>
                      <p><b>Correct Answer:</b> {question.correctAnswer}</p>
                      <p className={`${question.correctAnswer}` === `${answers[question.id]}` ? "correct" : "wrong"}>
                        <b>Your Answer:</b> {answers[question.id] || "Not answered"}
                      </p>
                      <hr />
                    </div>
                  ))}
                  <button class="ui secondary button" onClick={closeModal}>Close</button><br /><br />
                </Modal>
              </div>

              <br />
            </div>
            <Link to="/quizes/java_quiz23">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                  }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/quizes/java_quiz25">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
            <br />
          </div>
        </div>
      </Sidebar>

      <div class="sideBarBody">
        <Footer />
      </div>
    </>
  );
};
export default JQuiz24;
