import { useRef, useEffect } from "react";
import "../../Components/Chapters/Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "../PythonSideBar";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "./Python.css";

const PythonC10 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };

  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };
  const pc10code0 = `print("Hello World!")`;
  const pc10code1 = `greetings = "Hello! How are you?"
print(greetings)`;
  const pc10code2 = `num1 = "10"
num2 = "20"
print(num1 + num2)
name = "John"
age = "20"
print(name + " is " + age)`;
  const pc10code3 = `intro = """My name is John.
I am 20 years old.
I am a boy."""
print(intro)`;
  const pc10code4 = `greetings = "Hi, How are you doing?"
print(len(greetings))`;
  const pc10code5 = `name = "John"
print(name[0])
print(name[1])
print(name[2])
print(name[3])`;
  const pc10code6 = `myAge = "I am 20 years old"
print("I am" in myAge)
print("Iam" in myAge)`;
  const pc10code7 = `myAge = "I am 20 years old"
print("I am" not in myAge)
print("Iam" not in myAge)`;
  const pc10code8 = `greetings = "Hi there!"
print(greetings[1:3])`;
  const pc10code9 = `greetings = "Hi there!"
print(greetings[:3])`;
  const pc10code10 = `greetings = "Hi there!"
print(greetings[1:])`;
  const pc10code11 = `greetings = "Hi there!"
print(greetings[-1])    
print(greetings[-5:-1]) `;
  const pc10code12 = `f"{var_name} here goes the text"`;
  const pc10code13 = `name = "John"
age = 20
ageOfPerson = f"{name} is {age} years old."    
print(ageOfPerson)`;
  const pc10code14 = `firstName = input("Enter first name: ")
lastName = input("Enter last name: ")
fullName = firstName + " " + lastName   
print(f"Full name is {fullName}")`;
  const pc10code15 = `greetings = "Hello World"
print(greetings.upper())`;
  const pc10code16 = `greetings = "Hello World"
print(greetings.lower())`;
  const pc10code17 = `greetings = "hello world"
print(greetings.capitalize())`;
  const pc10code18 = `greetings = "hello world"
print(greetings.count("l"))`;
  const pc10code19 = `greetings = "hello world"
print(greetings.encode())`;
  const pc10code20 = `greetings = "hello world"
print(greetings.center(20, "-"))`;
  const pc10code21 = `name = "John"
print("Hi, my name is {}".format(name))`;
  const pc10code22 = `x = {'name' : "John", 'age' : 20}
print("{name}:{age}".format_map(x))`;
  const pc10code23 = `name = "Sarah"
print(name.find("S"))`;
  const pc10code24 = `name = "Sarah"
print(name.rfind("S"))`;
  const pc10code25 = `txt = "Hello\tWorld"
print(txt.expandtabs(4))`;
  const pc10code26 = `txt = "Hello World"
print(txt.index("W"))`;
  const pc10code27 = `txt = "Hello World"
print(txt.rindex("o"))`;
  const pc10code29 = `greetings = "Hello World"
print(greetings.replace("Hello", "Hi"))`;
  const pc10code30 = `greetings = "hello world"
print(greetings.title())`;
  const pc10code31 = `greetings = "--helloworld--"
print(greetings.strip("-"))`;
  const pc10code32 = `greetings = "--helloworld--"
print(greetings.lstrip("-"))`;
  const pc10code33 = `greetings = "--helloworld--"
print(greetings.rstrip("-"))`;
  const pc10code34 = `greetings = "hello World"
print(greetings.swapcase())`;
  const pc10code35 = `greetings = "hello World"
print(greetings.startswith("h"))`;
  const pc10code36 = `greetings = "hello World"
print(greetings.endswith("h"))`;
  const pc10code37 = `greetings = "hello World"
print(greetings.split())`;
  const pc10code38 = `greetings = "hello World"
print(greetings.rsplit())`;
  const pc10code39 = `greetings = "hello World\nHi there"
print(greetings.splitlines())`;
  const pc10code40 = `greetings = "Hello World"
trans = {72 :70}
print(greetings.translate(trans))`;
  const pc10code41 = `greetings = "Hello World"
t_table = str.maketrans("abcd", "1234")
print(greetings.translate(t_table))`;
  const pc10code42 = `greetings = "Hello World"
print(greetings.zfill(16))`;
  const pc10code43 = `greetings = "Hello World"
print(greetings.partition(" "))`;
  const pc10code44 = `greetings = "Hello World"
print(greetings.rpartition(" "))`;
  const pc10code45 = `greetings = "Hello World"
print(greetings.ljust(20, "-"))`;
  const pc10code46 = `greetings = "Hello World"
print(greetings.rjust(20, "-"))`;
  const pc10code47 = `text = "Hello1234"
print(text.isalnum())`;
  const pc10code48 = `text = "Hello"
print(text.isalpha())`;
  const pc10code49 = `text = "Hello"
print(text.isascii())`;
  const pc10code50 = `text = "10"
print(text.isdecimal())`;
  const pc10code51 = `text = "10"
print(text.isdigit())`;
  const pc10code52 = `text = "name"
print(text.isidentifier())`;
  const pc10code53 = `text = "name"
print(text.islower())`;
  const pc10code54 = `text = "123"
print(text.isnumeric())`;
  const pc10code55 = `text = "I have a dog."
print(text.isprintable())`;
  const pc10code56 = `text = " \t  \\n  "
print(text.isspace())`;
  const pc10code57 = `text = "All Boys Are Good."
print(text.istitle())`;
  const pc10code58 = `text = "ALL BOYS ARE GOOD."
print(text.isupper())`;
  const pc10code59 = `text = "ALL BOYS ARE GOOD."
print(text.casefold())`;
  const pc10code28 = `fruits = ["apple", "banana", "orange"]
sep = " "
print(sep.join(fruits))`;
  const pc10codes1 = `name = input("Enter name: ")
age = input("Enter age: ")
intro = "My name is {0}. I am  {1} years old."

print(intro.format(name, age))`;
  const pc10codes2 = `intro = "Name: {name}. Age:  {age} years"
print(intro.format(name="John", age="22"))`;
  return (
    <div class="testing">
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/python/numbers">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/python/operators">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Strings:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                String is a primitive data types which is surrounded by single
                or double quotation marks and is used to store text data or
                sequence of characters. When we store data in a variable and
                surround the data with single or double quotation marks, its
                data type will be string. String is an array of characters.
              </span>
              <br />
              <br />
             
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>string.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc10code0}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <span>We can also assign string data to a variable.</span>
              <br />
              <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>string.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc10code1}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Concatenation:
                </h2>
                <div className="ui clearing divider"></div>
                <span>
                  When we have numbers in string variables, they do not sum up
                  rather they concatenate. Concatenation is the method of
                  joining two strings using "+" operator.
                </span>
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>string.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc10code2}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Multi-line Strings:
                </h2>
                <div className="ui clearing divider"></div>
                <span>
                  When you write you string inside three single or double
                  quotation marks, it becomes multi-lined string. The difference
                  is that in simple string, all you text is printed on the same
                  line but in multi-lined strings, line breaks are also counted.
                </span>
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>string.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc10code3}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  String Length:
                </h2>
                <div className="ui clearing divider"></div>
                <span>
                  You can also count the number of characters in your string.
                </span>
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>string.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc10code4}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Accessing Characters in a String:
                </h2>
                <div className="ui clearing divider"></div>
                <span>
                  We know that string is a sequence of characters. So, we can
                  access individual characters.
                </span>
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>string.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc10code5}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <p style={{ backgroundColor: "#FBEEAC", padding: "15px" }}>
                  <b>Note: </b>If there is a space, it will also be given index
                  number.
                </p>

                <br />
                <span>
                  Index counting starts from zero. <br />
                </span>
                <br />
                <span>
                  This is the lengthy method for accessing individual
                  characters. If we use loop for this purpose, it will become
                  easy. We will discuss about loops in the coming section.
                </span>
                <br />
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Negative Indexes:
                </h2>
                <div className="ui clearing divider"></div>
                <span>
                  When we provide negative index, it starts from end. -1 is
                  assigned to the last character. On the other hand, positive
                  index starts from zero and it is from first character.
                </span>
                <br />
                <br />

                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>numbers.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc10code11}
                    </SyntaxHighlighter>
                  </div>
                </div>

                
                <br />

                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Checking in a String:
                </h2>
                <div className="ui clearing divider"></div>
                <span>
                  We can also check if some text is present in a string or not
                  by using "in" keyword. It gives boolean value true or false.
                  If required text is present, it returns true otherwise false.
                </span>
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>string.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc10code6}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <span>
                  We can also check this by using "not in". If the required
                  sequence of characters is present in string, it will return
                  false otherwise true.
                </span>
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>string.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc10code7}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  String Slicing:
                </h2>
                <div className="ui clearing divider"></div>
                <span>
                  String slicing means cutting string from some given index to
                  one less than the other given index. It returns the part of
                  string from the given start index to one less than the given
                  last index.
                </span>
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>numbers.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc10code8}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />

                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Slicing from start:
                </h2>
                <div className="ui clearing divider"></div>
                <span>
                  When we do not provide the starting index, then by default it
                  starts from zero index.
                </span>
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>numbers.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc10code9}
                    </SyntaxHighlighter>
                  </div>
                </div>

                <br />

                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Slicing to end:
                </h2>
                <div className="ui clearing divider"></div>
                <span>
                  When we do not provide the last index, then by default it ends
                  at last index.
                </span>
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>numbers.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc10code10}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  String Formatting:
                </h2>
                <div className="ui clearing divider"></div>
                <span>
                  We use string formatting when we have to generate dynamic data
                  from variables.
                </span>
                
                

                
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc10code12}
                    </SyntaxHighlighter>
                  
                <br />
                
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>numbers.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc10code13}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />

                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Index Numbers:
                </h2>
                <div className="ui clearing divider"></div>
                <span>
                  We can use index numbers inside curly braces as placeholders
                  for the dynamic values.
                </span>
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>numbers.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc10codes1}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Named Indexes:
                </h2>
                <div className="ui clearing divider"></div>
                <span>
                  Like numbered indexes, we also have named indexes. We use
                  names inside curly braces as placeholders for the dynamic
                  data.
                </span>
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>numbers.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc10codes2}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  String Concatenation:
                </h2>
                <div className="ui clearing divider"></div>
                <span>
                  String concatenation is the process of joining two or more
                  string with the help of "+" operator.
                </span>
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>numbers.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc10code14}
                    </SyntaxHighlighter>
                  </div>
                </div>

              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                String Methods:
              </h2>
              <div className="ui clearing divider"></div>
              <div class="table-container">
                <table class="ui celled table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Description</th>
                      <th>Code</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>.upper()</td>
                      <td>
                        converts all the lower case characters in string to
                        upper case.
                      </td>
                      <td>
                        <div style={containerStyle}>
                          <div className="ml-3 mr-3">
                            <b style={{ color: "black" }}>numbers.py</b>

                            <i
                              class="copy icon"
                              onClick={copyContent}
                              style={{
                                cursor: "pointer",
                                float: "right",
                                fontSize: "20px",
                              }}
                            ></i>
                          </div>
                          <div ref={divRef}>
                            <SyntaxHighlighter language="python" style={darcula}>
                              {pc10code15}
                            </SyntaxHighlighter>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>.lower()</td>
                      <td>
                        converts all the upper case characters to lower case.
                      </td>
                      <td>
                        <div style={containerStyle}>
                          <div className="ml-3 mr-3">
                            <b style={{ color: "black" }}>numbers.py</b>

                            <i
                              class="copy icon"
                              onClick={copyContent}
                              style={{
                                cursor: "pointer",
                                float: "right",
                                fontSize: "20px",
                              }}
                            ></i>
                          </div>
                          <div ref={divRef}>
                            <SyntaxHighlighter language="python" style={darcula}>
                              {pc10code16}
                            </SyntaxHighlighter>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>.capitalize()</td>
                      <td>
                        returns first character with upper case and all other
                        characters with lower case.
                      </td>
                      <td>
                        <div style={containerStyle}>
                          <div className="ml-3 mr-3">
                            <b style={{ color: "black" }}>numbers.py</b>

                            <i
                              class="copy icon"
                              onClick={copyContent}
                              style={{
                                cursor: "pointer",
                                float: "right",
                                fontSize: "20px",
                              }}
                            ></i>
                          </div>
                          <div ref={divRef}>
                            <SyntaxHighlighter language="python" style={darcula}>
                              {pc10code17}
                            </SyntaxHighlighter>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>.count()</td>
                      <td>
                        counts the occurrences of a specified substring or
                        element within the given string or list.
                      </td>
                      <td>
                        <div style={containerStyle}>
                          <div className="ml-3 mr-3">
                            <b style={{ color: "black" }}>numbers.py</b>

                            <i
                              class="copy icon"
                              onClick={copyContent}
                              style={{
                                cursor: "pointer",
                                float: "right",
                                fontSize: "20px",
                              }}
                            ></i>
                          </div>
                          <div ref={divRef}>
                            <SyntaxHighlighter language="python" style={darcula}>
                              {pc10code18}
                            </SyntaxHighlighter>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>.encode()</td>
                      <td>converts a string into a sequence of bytes.</td>
                      <td>
                        <div style={containerStyle}>
                          <div className="ml-3 mr-3">
                            <b style={{ color: "black" }}>numbers.py</b>

                            <i
                              class="copy icon"
                              onClick={copyContent}
                              style={{
                                cursor: "pointer",
                                float: "right",
                                fontSize: "20px",
                              }}
                            ></i>
                          </div>
                          <div ref={divRef}>
                            <SyntaxHighlighter language="python" style={darcula}>
                              {pc10code19}
                            </SyntaxHighlighter>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>.center()</td>
                      <td>center-aligns a string within a specified width.</td>
                      <td>
                        <div style={containerStyle}>
                          <div className="ml-3 mr-3">
                            <b style={{ color: "black" }}>numbers.py</b>

                            <i
                              class="copy icon"
                              onClick={copyContent}
                              style={{
                                cursor: "pointer",
                                float: "right",
                                fontSize: "20px",
                              }}
                            ></i>
                          </div>
                          <div ref={divRef}>
                            <SyntaxHighlighter language="python" style={darcula}>
                              {pc10code20}
                            </SyntaxHighlighter>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>.format()</td>
                      <td>provides a way to create formatted strings</td>
                      <td>
                        <div style={containerStyle}>
                          <div className="ml-3 mr-3">
                            <b style={{ color: "black" }}>numbers.py</b>

                            <i
                              class="copy icon"
                              onClick={copyContent}
                              style={{
                                cursor: "pointer",
                                float: "right",
                                fontSize: "20px",
                              }}
                            ></i>
                          </div>
                          <div ref={divRef}>
                            <SyntaxHighlighter language="python" style={darcula}>
                              {pc10code21}
                            </SyntaxHighlighter>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>.format_map()</td>
                      <td>
                        formats strings by replacing placeholders with values
                        from a mapping.
                      </td>
                      <td>
                        <div style={containerStyle}>
                          <div className="ml-3 mr-3">
                            <b style={{ color: "black" }}>numbers.py</b>

                            <i
                              class="copy icon"
                              onClick={copyContent}
                              style={{
                                cursor: "pointer",
                                float: "right",
                                fontSize: "20px",
                              }}
                            ></i>
                          </div>
                          <div ref={divRef}>
                            <SyntaxHighlighter language="python" style={darcula}>
                              {pc10code22}
                            </SyntaxHighlighter>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>.find()</td>
                      <td>
                        {" "}
                        finds the index of the first occurrence of a specified
                        substring within the given string.
                      </td>
                      <td>
                        <div style={containerStyle}>
                          <div className="ml-3 mr-3">
                            <b style={{ color: "black" }}>numbers.py</b>

                            <i
                              class="copy icon"
                              onClick={copyContent}
                              style={{
                                cursor: "pointer",
                                float: "right",
                                fontSize: "20px",
                              }}
                            ></i>
                          </div>
                          <div ref={divRef}>
                            <SyntaxHighlighter language="python" style={darcula}>
                              {pc10code23}
                            </SyntaxHighlighter>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>.rfind()</td>
                      <td>
                        finds the index of the last occurrence of a specified
                        substring within the given string.
                      </td>
                      <td>
                        <div style={containerStyle}>
                          <div className="ml-3 mr-3">
                            <b style={{ color: "black" }}>numbers.py</b>

                            <i
                              class="copy icon"
                              onClick={copyContent}
                              style={{
                                cursor: "pointer",
                                float: "right",
                                fontSize: "20px",
                              }}
                            ></i>
                          </div>
                          <div ref={divRef}>
                            <SyntaxHighlighter language="python" style={darcula}>
                              {pc10code24}
                            </SyntaxHighlighter>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>.expandtabs()</td>
                      <td>
                        replaces tab characters ('\t') within a string with a
                        specified number of spaces.
                      </td>
                      <td>
                        <div style={containerStyle}>
                          <div className="ml-3 mr-3">
                            <b style={{ color: "black" }}>numbers.py</b>

                            <i
                              class="copy icon"
                              onClick={copyContent}
                              style={{
                                cursor: "pointer",
                                float: "right",
                                fontSize: "20px",
                              }}
                            ></i>
                          </div>
                          <div ref={divRef}>
                            <SyntaxHighlighter language="python" style={darcula}>
                              {pc10code25}
                            </SyntaxHighlighter>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>.index()</td>
                      <td>
                        finds the index of the first occurrence of a specified
                        substring within the given string.
                      </td>
                      <td>
                        <div style={containerStyle}>
                          <div className="ml-3 mr-3">
                            <b style={{ color: "black" }}>numbers.py</b>

                            <i
                              class="copy icon"
                              onClick={copyContent}
                              style={{
                                cursor: "pointer",
                                float: "right",
                                fontSize: "20px",
                              }}
                            ></i>
                          </div>
                          <div ref={divRef}>
                            <SyntaxHighlighter language="python" style={darcula}>
                              {pc10code26}
                            </SyntaxHighlighter>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>.rindex()</td>
                      <td>
                        finds the index of the last occurrence of a specified
                        substring within the given string.
                      </td>
                      <td>
                        <div style={containerStyle}>
                          <div className="ml-3 mr-3">
                            <b style={{ color: "black" }}>numbers.py</b>

                            <i
                              class="copy icon"
                              onClick={copyContent}
                              style={{
                                cursor: "pointer",
                                float: "right",
                                fontSize: "20px",
                              }}
                            ></i>
                          </div>
                          <div ref={divRef}>
                            <SyntaxHighlighter language="python" style={darcula}>
                              {pc10code27}
                            </SyntaxHighlighter>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>.join()</td>
                      <td>
                        concatenates elements of an iterable, such as a list or
                        tuple, into a single string.{" "}
                      </td>
                      <td>
                        <div style={containerStyle}>
                          <div className="ml-3 mr-3">
                            <b style={{ color: "black" }}>numbers.py</b>

                            <i
                              class="copy icon"
                              onClick={copyContent}
                              style={{
                                cursor: "pointer",
                                float: "right",
                                fontSize: "20px",
                              }}
                            ></i>
                          </div>
                          <div ref={divRef}>
                            <SyntaxHighlighter language="python" style={darcula}>
                              {pc10code28}
                            </SyntaxHighlighter>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>.replace()</td>
                      <td>replaces a substring with a given substring.</td>
                      <td>
                        <div style={containerStyle}>
                          <div className="ml-3 mr-3">
                            <b style={{ color: "black" }}>numbers.py</b>

                            <i
                              class="copy icon"
                              onClick={copyContent}
                              style={{
                                cursor: "pointer",
                                float: "right",
                                fontSize: "20px",
                              }}
                            ></i>
                          </div>
                          <div ref={divRef}>
                            <SyntaxHighlighter language="python" style={darcula}>
                              {pc10code29}
                            </SyntaxHighlighter>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>.title()</td>
                      <td>converts a string into title case.</td>
                      <td>
                        <div style={containerStyle}>
                          <div className="ml-3 mr-3">
                            <b style={{ color: "black" }}>numbers.py</b>

                            <i
                              class="copy icon"
                              onClick={copyContent}
                              style={{
                                cursor: "pointer",
                                float: "right",
                                fontSize: "20px",
                              }}
                            ></i>
                          </div>
                          <div ref={divRef}>
                            <SyntaxHighlighter language="python" style={darcula}>
                              {pc10code30}
                            </SyntaxHighlighter>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>.strip()</td>
                      <td>
                        removes starting and enging characters from a string.
                      </td>
                      <td>
                        <div style={containerStyle}>
                          <div className="ml-3 mr-3">
                            <b style={{ color: "black" }}>numbers.py</b>

                            <i
                              class="copy icon"
                              onClick={copyContent}
                              style={{
                                cursor: "pointer",
                                float: "right",
                                fontSize: "20px",
                              }}
                            ></i>
                          </div>
                          <div ref={divRef}>
                            <SyntaxHighlighter language="python" style={darcula}>
                              {pc10code31}
                            </SyntaxHighlighter>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>.lstrip()</td>
                      <td>removes starting characters from a string. </td>
                      <td>
                        <div style={containerStyle}>
                          <div className="ml-3 mr-3">
                            <b style={{ color: "black" }}>numbers.py</b>

                            <i
                              class="copy icon"
                              onClick={copyContent}
                              style={{
                                cursor: "pointer",
                                float: "right",
                                fontSize: "20px",
                              }}
                            ></i>
                          </div>
                          <div ref={divRef}>
                            <SyntaxHighlighter language="python" style={darcula}>
                              {pc10code32}
                            </SyntaxHighlighter>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>.rstrip()</td>
                      <td>removes starting characters from a string.</td>
                      <td>
                        <div style={containerStyle}>
                          <div className="ml-3 mr-3">
                            <b style={{ color: "black" }}>numbers.py</b>

                            <i
                              class="copy icon"
                              onClick={copyContent}
                              style={{
                                cursor: "pointer",
                                float: "right",
                                fontSize: "20px",
                              }}
                            ></i>
                          </div>
                          <div ref={divRef}>
                            <SyntaxHighlighter language="python" style={darcula}>
                              {pc10code33}
                            </SyntaxHighlighter>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>.swapcase()</td>
                      <td>
                        {" "}
                        creates a new string where the case of each character in
                        the original string is swapped.{" "}
                      </td>
                      <td>
                        <div style={containerStyle}>
                          <div className="ml-3 mr-3">
                            <b style={{ color: "black" }}>numbers.py</b>

                            <i
                              class="copy icon"
                              onClick={copyContent}
                              style={{
                                cursor: "pointer",
                                float: "right",
                                fontSize: "20px",
                              }}
                            ></i>
                          </div>
                          <div ref={divRef}>
                            <SyntaxHighlighter language="python" style={darcula}>
                              {pc10code34}
                            </SyntaxHighlighter>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>.startswith()</td>
                      <td>
                        checks whether a string starts with a specified
                        substring.{" "}
                      </td>
                      <td>
                        <div style={containerStyle}>
                          <div className="ml-3 mr-3">
                            <b style={{ color: "black" }}>numbers.py</b>

                            <i
                              class="copy icon"
                              onClick={copyContent}
                              style={{
                                cursor: "pointer",
                                float: "right",
                                fontSize: "20px",
                              }}
                            ></i>
                          </div>
                          <div ref={divRef}>
                            <SyntaxHighlighter language="python" style={darcula}>
                              {pc10code35}
                            </SyntaxHighlighter>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>.endswith()</td>
                      <td>
                        checks whether a string ends with a specified substring.{" "}
                      </td>
                      <td>
                        <div style={containerStyle}>
                          <div className="ml-3 mr-3">
                            <b style={{ color: "black" }}>numbers.py</b>

                            <i
                              class="copy icon"
                              onClick={copyContent}
                              style={{
                                cursor: "pointer",
                                float: "right",
                                fontSize: "20px",
                              }}
                            ></i>
                          </div>
                          <div ref={divRef}>
                            <SyntaxHighlighter language="python" style={darcula}>
                              {pc10code36}
                            </SyntaxHighlighter>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>.split()</td>
                      <td>splits a string into a list of substrings.</td>
                      <td>
                        <div style={containerStyle}>
                          <div className="ml-3 mr-3">
                            <b style={{ color: "black" }}>numbers.py</b>

                            <i
                              class="copy icon"
                              onClick={copyContent}
                              style={{
                                cursor: "pointer",
                                float: "right",
                                fontSize: "20px",
                              }}
                            ></i>
                          </div>
                          <div ref={divRef}>
                            <SyntaxHighlighter language="python" style={darcula}>
                              {pc10code37}
                            </SyntaxHighlighter>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>.rsplit()</td>
                      <td>
                        splits a string into a list of substrings from right.
                      </td>
                      <td>
                        <div style={containerStyle}>
                          <div className="ml-3 mr-3">
                            <b style={{ color: "black" }}>numbers.py</b>

                            <i
                              class="copy icon"
                              onClick={copyContent}
                              style={{
                                cursor: "pointer",
                                float: "right",
                                fontSize: "20px",
                              }}
                            ></i>
                          </div>
                          <div ref={divRef}>
                            <SyntaxHighlighter language="python" style={darcula}>
                              {pc10code38}
                            </SyntaxHighlighter>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>.splitlines()</td>
                      <td>
                        split a string into a list of substrings based on line
                        breaks.
                      </td>
                      <td>
                        <div style={containerStyle}>
                          <div className="ml-3 mr-3">
                            <b style={{ color: "black" }}>numbers.py</b>

                            <i
                              class="copy icon"
                              onClick={copyContent}
                              style={{
                                cursor: "pointer",
                                float: "right",
                                fontSize: "20px",
                              }}
                            ></i>
                          </div>
                          <div ref={divRef}>
                            <SyntaxHighlighter language="python" style={darcula}>
                              {pc10code39}
                            </SyntaxHighlighter>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>.translate()</td>
                      <td>
                        perform character-level translation or deletion within a
                        string.{" "}
                      </td>
                      <td>
                        <div style={containerStyle}>
                          <div className="ml-3 mr-3">
                            <b style={{ color: "black" }}>numbers.py</b>

                            <i
                              class="copy icon"
                              onClick={copyContent}
                              style={{
                                cursor: "pointer",
                                float: "right",
                                fontSize: "20px",
                              }}
                            ></i>
                          </div>
                          <div ref={divRef}>
                            <SyntaxHighlighter language="python" style={darcula}>
                              {pc10code40}
                            </SyntaxHighlighter>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>.maketrans()</td>
                      <td>
                        creates a translation table that can be used with the
                        .translate()
                      </td>
                      <td>
                        <div style={containerStyle}>
                          <div className="ml-3 mr-3">
                            <b style={{ color: "black" }}>numbers.py</b>

                            <i
                              class="copy icon"
                              onClick={copyContent}
                              style={{
                                cursor: "pointer",
                                float: "right",
                                fontSize: "20px",
                              }}
                            ></i>
                          </div>
                          <div ref={divRef}>
                            <SyntaxHighlighter language="python" style={darcula}>
                              {pc10code41}
                            </SyntaxHighlighter>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>.zfill()</td>
                      <td>
                        pads a numeric string with zeros (0) on the left side to
                        achieve a specified width.{" "}
                      </td>
                      <td>
                        <div style={containerStyle}>
                          <div className="ml-3 mr-3">
                            <b style={{ color: "black" }}>numbers.py</b>

                            <i
                              class="copy icon"
                              onClick={copyContent}
                              style={{
                                cursor: "pointer",
                                float: "right",
                                fontSize: "20px",
                              }}
                            ></i>
                          </div>
                          <div ref={divRef}>
                            <SyntaxHighlighter language="python" style={darcula}>
                              {pc10code42}
                            </SyntaxHighlighter>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>.partition()</td>
                      <td>
                        splits a string into three parts based on a specified
                        separator.
                      </td>
                      <td>
                        <div style={containerStyle}>
                          <div className="ml-3 mr-3">
                            <b style={{ color: "black" }}>numbers.py</b>

                            <i
                              class="copy icon"
                              onClick={copyContent}
                              style={{
                                cursor: "pointer",
                                float: "right",
                                fontSize: "20px",
                              }}
                            ></i>
                          </div>
                          <div ref={divRef}>
                            <SyntaxHighlighter language="python" style={darcula}>
                              {pc10code43}
                            </SyntaxHighlighter>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>.rpartition()</td>
                      <td>
                        splits a string into three parts based on a specified
                        separator from right.
                      </td>
                      <td>
                        <div style={containerStyle}>
                          <div className="ml-3 mr-3">
                            <b style={{ color: "black" }}>numbers.py</b>

                            <i
                              class="copy icon"
                              onClick={copyContent}
                              style={{
                                cursor: "pointer",
                                float: "right",
                                fontSize: "20px",
                              }}
                            ></i>
                          </div>
                          <div ref={divRef}>
                            <SyntaxHighlighter language="python" style={darcula}>
                              {pc10code44}
                            </SyntaxHighlighter>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>.ljust()</td>
                      <td>
                        used to left justify a string with a specified character
                        (or whitespace) on the right side.
                      </td>
                      <td>
                        <div style={containerStyle}>
                          <div className="ml-3 mr-3">
                            <b style={{ color: "black" }}>numbers.py</b>

                            <i
                              class="copy icon"
                              onClick={copyContent}
                              style={{
                                cursor: "pointer",
                                float: "right",
                                fontSize: "20px",
                              }}
                            ></i>
                          </div>
                          <div ref={divRef}>
                            <SyntaxHighlighter language="python" style={darcula}>
                              {pc10code45}
                            </SyntaxHighlighter>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>.rjust()</td>
                      <td>
                        used to right justify a string with a specified
                        character (or whitespace) on the right side.
                      </td>
                      <td>
                        <div style={containerStyle}>
                          <div className="ml-3 mr-3">
                            <b style={{ color: "black" }}>numbers.py</b>

                            <i
                              class="copy icon"
                              onClick={copyContent}
                              style={{
                                cursor: "pointer",
                                float: "right",
                                fontSize: "20px",
                              }}
                            ></i>
                          </div>
                          <div ref={divRef}>
                            <SyntaxHighlighter language="python" style={darcula}>
                              {pc10code46}
                            </SyntaxHighlighter>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>.isalnum()</td>
                      <td>
                        checks whether all characters are alphanumeric
                        characters in a string.{" "}
                      </td>
                      <td>
                        <div style={containerStyle}>
                          <div className="ml-3 mr-3">
                            <b style={{ color: "black" }}>numbers.py</b>

                            <i
                              class="copy icon"
                              onClick={copyContent}
                              style={{
                                cursor: "pointer",
                                float: "right",
                                fontSize: "20px",
                              }}
                            ></i>
                          </div>
                          <div ref={divRef}>
                            <SyntaxHighlighter language="python" style={darcula}>
                              {pc10code47}
                            </SyntaxHighlighter>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>.isalpha()</td>
                      <td>
                        checks whether all characters are alphabetic characters
                        in a string.{" "}
                      </td>
                      <td>
                        <div style={containerStyle}>
                          <div className="ml-3 mr-3">
                            <b style={{ color: "black" }}>numbers.py</b>

                            <i
                              class="copy icon"
                              onClick={copyContent}
                              style={{
                                cursor: "pointer",
                                float: "right",
                                fontSize: "20px",
                              }}
                            ></i>
                          </div>
                          <div ref={divRef}>
                            <SyntaxHighlighter language="python" style={darcula}>
                              {pc10code48}
                            </SyntaxHighlighter>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>.isascii()</td>
                      <td>
                        checks whether all characters are ASCII characters in a
                        string.
                      </td>
                      <td>
                        <div style={containerStyle}>
                          <div className="ml-3 mr-3">
                            <b style={{ color: "black" }}>numbers.py</b>

                            <i
                              class="copy icon"
                              onClick={copyContent}
                              style={{
                                cursor: "pointer",
                                float: "right",
                                fontSize: "20px",
                              }}
                            ></i>
                          </div>
                          <div ref={divRef}>
                            <SyntaxHighlighter language="python" style={darcula}>
                              {pc10code49}
                            </SyntaxHighlighter>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>.isdecimal()</td>
                      <td>
                        checks whether all characters are decimal characters in
                        a string.
                      </td>
                      <td>
                        <div style={containerStyle}>
                          <div className="ml-3 mr-3">
                            <b style={{ color: "black" }}>numbers.py</b>

                            <i
                              class="copy icon"
                              onClick={copyContent}
                              style={{
                                cursor: "pointer",
                                float: "right",
                                fontSize: "20px",
                              }}
                            ></i>
                          </div>
                          <div ref={divRef}>
                            <SyntaxHighlighter language="python" style={darcula}>
                              {pc10code50}
                            </SyntaxHighlighter>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>.isdigit()</td>
                      <td>
                        checks whether all characters are digits characters in a
                        string.
                      </td>
                      <td>
                        <div style={containerStyle}>
                          <div className="ml-3 mr-3">
                            <b style={{ color: "black" }}>numbers.py</b>

                            <i
                              class="copy icon"
                              onClick={copyContent}
                              style={{
                                cursor: "pointer",
                                float: "right",
                                fontSize: "20px",
                              }}
                            ></i>
                          </div>
                          <div ref={divRef}>
                            <SyntaxHighlighter language="python" style={darcula}>
                              {pc10code51}
                            </SyntaxHighlighter>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>.isidentifier()</td>
                      <td>
                        check whether a given string is a valid identifier
                        according to the Python language rules.
                      </td>
                      <td>
                        <div style={containerStyle}>
                          <div className="ml-3 mr-3">
                            <b style={{ color: "black" }}>numbers.py</b>

                            <i
                              class="copy icon"
                              onClick={copyContent}
                              style={{
                                cursor: "pointer",
                                float: "right",
                                fontSize: "20px",
                              }}
                            ></i>
                          </div>
                          <div ref={divRef}>
                            <SyntaxHighlighter language="python" style={darcula}>
                              {pc10code52}
                            </SyntaxHighlighter>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>.islower()</td>
                      <td>
                        checks whether all characters are lower case characters
                        in a string.
                      </td>
                      <td>
                        <div style={containerStyle}>
                          <div className="ml-3 mr-3">
                            <b style={{ color: "black" }}>numbers.py</b>

                            <i
                              class="copy icon"
                              onClick={copyContent}
                              style={{
                                cursor: "pointer",
                                float: "right",
                                fontSize: "20px",
                              }}
                            ></i>
                          </div>
                          <div ref={divRef}>
                            <SyntaxHighlighter language="python" style={darcula}>
                              {pc10code53}
                            </SyntaxHighlighter>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>.isnumeric()</td>
                      <td>
                        checks whether all characters are numeric characters in
                        a string.
                      </td>
                      <td>
                        <div style={containerStyle}>
                          <div className="ml-3 mr-3">
                            <b style={{ color: "black" }}>numbers.py</b>

                            <i
                              class="copy icon"
                              onClick={copyContent}
                              style={{
                                cursor: "pointer",
                                float: "right",
                                fontSize: "20px",
                              }}
                            ></i>
                          </div>
                          <div ref={divRef}>
                            <SyntaxHighlighter language="python" style={darcula}>
                              {pc10code54}
                            </SyntaxHighlighter>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>.isprintable()</td>
                      <td>
                        checks whether all characters are printable characters
                        in a string.
                      </td>
                      <td>
                        <div style={containerStyle}>
                          <div className="ml-3 mr-3">
                            <b style={{ color: "black" }}>numbers.py</b>

                            <i
                              class="copy icon"
                              onClick={copyContent}
                              style={{
                                cursor: "pointer",
                                float: "right",
                                fontSize: "20px",
                              }}
                            ></i>
                          </div>
                          <div ref={divRef}>
                            <SyntaxHighlighter language="python" style={darcula}>
                              {pc10code55}
                            </SyntaxHighlighter>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>.isspace()</td>
                      <td>
                        checks whether all characters are white space characters
                        in a string.
                      </td>
                      <td>
                        <div style={containerStyle}>
                          <div className="ml-3 mr-3">
                            <b style={{ color: "black" }}>numbers.py</b>

                            <i
                              class="copy icon"
                              onClick={copyContent}
                              style={{
                                cursor: "pointer",
                                float: "right",
                                fontSize: "20px",
                              }}
                            ></i>
                          </div>
                          <div ref={divRef}>
                            <SyntaxHighlighter language="python" style={darcula}>
                              {pc10code56}
                            </SyntaxHighlighter>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>.istitle()</td>
                      <td>
                        checks whether the string follows the rules of title
                        case.
                      </td>
                      <td>
                        <div style={containerStyle}>
                          <div className="ml-3 mr-3">
                            <b style={{ color: "black" }}>numbers.py</b>

                            <i
                              class="copy icon"
                              onClick={copyContent}
                              style={{
                                cursor: "pointer",
                                float: "right",
                                fontSize: "20px",
                              }}
                            ></i>
                          </div>
                          <div ref={divRef}>
                            <SyntaxHighlighter language="python" style={darcula}>
                              {pc10code57}
                            </SyntaxHighlighter>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tr>
                    <td>.isupper()</td>
                    <td>
                      checks whether all the alphabetic characters are upper
                      case characters in a string.
                    </td>
                    <td>
                      <div style={containerStyle}>
                        <div className="ml-3 mr-3">
                          <b style={{ color: "black" }}>numbers.py</b>

                          <i
                            class="copy icon"
                            onClick={copyContent}
                            style={{
                              cursor: "pointer",
                              float: "right",
                              fontSize: "20px",
                            }}
                          ></i>
                        </div>
                        <div ref={divRef}>
                          <SyntaxHighlighter language="python" style={darcula}>
                            {pc10code58}
                          </SyntaxHighlighter>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tfoot>
                    <tr>
                      <th>.casefold()</th>
                      <th>converts the string into lower case.</th>
                      <th>
                        <div style={containerStyle}>
                          <div className="ml-3 mr-3">
                            <b style={{ color: "black" }}>numbers.py</b>

                            <i
                              class="copy icon"
                              onClick={copyContent}
                              style={{
                                cursor: "pointer",
                                float: "right",
                                fontSize: "20px",
                              }}
                            ></i>
                          </div>
                          <div ref={divRef}>
                            <SyntaxHighlighter language="python" style={darcula}>
                              {pc10code59}
                            </SyntaxHighlighter>
                          </div>
                        </div>
                      </th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
            <br />
            <Link to="/python/numbers">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/python/operators">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </div>
  );
};
export default PythonC10;
