import { useRef, useEffect } from "react";
import "../../Components/Chapters/Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "../PythonSideBar";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

const PythonC22 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };

  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };
  const pc22code1 = `class Reactangle:

    # data members
    length = 10
    width = 20
    
    # functions
    def draw():
        print("Draw a reactangle")`;
  const pc22code2 = `class Reactangle:
    length = 10
    width = 20

R1 = Reactangle()    # creating an object`;
  const pc22code3 = `class Cow:
    def eat():
        print("Cow eats grass")
Cow1 = Cow()
Cow1.eat()`;
  const pc22code4 = `class Persons:

    def __init__(self, name, age):
        #Here we can initialize data members
        self.name = name
        self.age = age

P1 = Persons("John", 22)

print(P1.name)
print(P1.age)`;
  const pc22code5 = `class MyClass:
def __init__(self, data):
    self.data = data

def __str__(self):
    return f"Object with data: {self.data}"
obj = MyClass(34)
print(obj)`;
  const pc22code6 = `class Person:
def __init__(data, name, age):
  data.name = name
  data.age = age

p1 = Person("John", 22)
print(p1.name)
print(p1.age)`;
  const pc22code7 = `class Myclass
    pass`;
  const pc22code8 = `class Person:
    def __init__(data, name, age):
      data.name = name
      data.age = age
  
p1 = Person("John", 22)
p1.name = "Sarah"
p1.age = 20
print(p1.name)
print(p1.age)`;
  const pc22code9 = `class Person:
def __init__(data, name, age):
  data.name = name
  data.age = age

p1 = Person("John", 22)
del p1.age 
print(p1.age)`;
  const pc22code10 = `class Person:
def __init__(data, name, age):
  data.name = name
  data.age = age

p1 = Person("John", 22)
del p1
print(p1)`;
  const pc22code11 = `class Reactangle:
length = 10
width = 20

R1 = Reactangle() # creating an object
print(R1.length) # accessing properties
print(R1.width) # accessing properties`;

  return (
    <div class="testing">
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/python/oop">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/python/scope">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Class:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                A class is a blueprint for creating objects which defines the
                attributes and behaviors of objects. Class is a user-defined
                data type. Class is defined with "class" keyword.
              </span>
              <br />
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Object:
              </h2>
              <div class="ui clearing divider"></div>
              Object is an entity which has its properties and behavior. For
              example, car is an object. It has properties like its weight,
              color, number etc. and behavior like it can stop, it can
              accelerate etc. These properties and behaviours are defined inside the
              class.
              <ul className="ui unordered list">
                <li>
                  <b>Properties of objects: </b>data members or variables
                  declared inside the class.
                </li>
                <li>
                  <b>Behavior of objects: </b>members functions declared inside
                  the class.{" "}
                </li>
              </ul>
              So, attributes and behaviors are members of class and declared
              inside the class. <br />
              <br />
              Now we can practically implement the concept of classes and
              objects. <br />
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Classes:
              </h2>
              <div class="ui clearing divider"></div>
              <p>We use the keyword "class" to create a class in Pyhton.</p>
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>class.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc22code1}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Objects:
              </h2>
              <div class="ui clearing divider"></div>
              <p>
                To create an object, we use the name of object and put it equal
                to the name of the class. To access properties of objects
                (defined inside class, like variables), we use dot operator.
              </p>
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>class.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc22code2}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Accessing Properties of an Object:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                We can access properties of an object using dot operator.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>class.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc22code11}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <p style={{ backgroundColor: "#FBEEAC", padding: "15px" }}>
                <b>Member Functions:</b>
                We can also declare functions inside the class. These are called
                methods or member functions. If I say that cow eats grass, this
                is the behaviour of the cow.
              </p>
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>class.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc22code3}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                The __init__() Function:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                It is a special function in Python, which is automatically
                executed whenever an object of class is created. It is used to
                initialize properties of an object. It is used to assign values
                to the members of a class.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>class.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc22code4}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <p style={{ backgroundColor: "#FBEEAC", padding: "15px" }}>
                The __init__() function is automatically called whenever we
                create the object of a class. Even if we do not declare __init__
                function, it is called and set the default values to the data
                members.
              </p>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                The self Parameter:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                In Python, self is a conventionally used name for the first
                parameter of instance methods in a class, including the
                __init__() method. It represents the instance of the class
                itself. When you create an instance of a class, the self
                parameter is automatically populated with a reference to that
                instance. It is not necessary to use self as the first
                parameter. We can use any word as the first parameter.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>class.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc22code6}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <p style={{ backgroundColor: "#FBEEAC", padding: "15px" }}>
                <b>Note: </b>We will pass one argument less when we create an object.
                The reason is that the first argument is automatically passed
                which is the object itself.
              </p>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                The __str__() Function:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                In Python, __str__() is a special method (also known as a "magic
                method" or "dunder method") that is used to define a
                human-readable representation of an object.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>class.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc22code5}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                The pass Statement:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                When we declare class, it can not be empty. Due to some reason,
                if you want to keep the class empty, you have to write the
                keyword "pass". If you do not write pass and keep the class
                empty, you will get error.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>class.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc22code7}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Modify Object Properties:
              </h2>
              <div class="ui clearing divider"></div>
              <span>You can reassign values to data members of a class.</span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>class.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc22code8}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Delete Object Properties:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                You can delete values of data members of a class using "del"
                keyword.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>class.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc22code9}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Delete Object:
              </h2>
              <div class="ui clearing divider"></div>
              <span>You can delete the object using "del" keyword.</span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>class.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc22code10}
                  </SyntaxHighlighter>
                </div>
              </div>
            </div>
            <br />
            <Link to="/python/oop">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/python/scope">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </div>
  );
};
export default PythonC22;
