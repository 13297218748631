import { useRef, useEffect } from "react";
// import "./Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Sidebar from "../CSideBar";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const CC7 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor:'#D8D9DA'
  };

  const code1 = `#include <stdio.h>

int main() {
  int age;
  
  // Reading an integer
  printf("Enter your age : ");
  scanf("%d", &age);
      
  // Printing the values entered by the user
  printf("You age is %d", age);
  
  return 0;
}
  `;
  const code2 = `#include <stdio.h>

int main() {
  float per;
  char grade;
  char name[50];
  
  
  // Taking input in a float type variable
  printf("Enter your percentage : ");
  scanf("%f", &per);
  
  // Taking input in a character type variable
  printf("Enter your grade : ");
  scanf(" %c", &grade);   

  // Taking input in a string of characters
  printf("Enter your name without spaces : ");
  scanf("%s", name);

  // Printing the values entered by the user
  printf("Name = %s \\n", name);
  printf("Percentage = %f \\n", per);
  printf("Grade = %c, \\n", grade);
  
  return 0;
}`;

  
  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
            <Link to="/C/variables">
            <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button></Link>
              <Link to="/C/data_types">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link><br />
            </h2>

            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Taking User's Input:
              </h2>
              <div class="ui clearing divider"></div>
              <p>
              In C, we can use scanf function to take input from the user.
                <br />
               <br />
               <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>input.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code1}
                  </SyntaxHighlighter>
                </div>
              </div>
                <br />
              
                
              </p>
              <p style={{ backgroundColor: "#FBEEAC", padding: "10px" }}>
                <b>Point : </b>When we take input from the user, we have to store
                it in a variable. At this point, we have learnt to take input in integer variable. In next example, we 
                will see that how we can take input in vaiables with different data types.
              </p>
              <br />
             <h1>In our example;</h1>
             <ul className="ui unordered list">
                <li>Declared int type variable age first.</li>
                <li>Prompting user to enter age.</li>
                <li>Taking input and storing in age variable.</li>
                <li>Printing value in age variable with some text.</li>
                <li>Returning from the main function.</li>
             </ul>
             {/* <br /> */}
             <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Taking Different types of Variables as Input:
              </h2>
              <div class="ui clearing divider"></div>
              Till now, we know that how we can take input in int type variable.
            In this example, we will see that how can we take input in variables with different data types
            and print them.
                <br />
               <br />
               <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>input.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code2}
                  </SyntaxHighlighter>
                </div>
              </div><br />
              In this way, we can take input in different types of variables and use them later for 
              processing. We can do calculations with data stored in variables using different types of 
              operators which we will discuss in Operators section.
              <br /><br />
              <p style={{ backgroundColor: "#FBEEAC", padding: "10px" }}>
                <b>Note : </b>Variables are stored at some place in memory when they are declared. 
                The data value which is provided by the user as input is stored in the variables.
              </p>
              
              
            </div>
            <br />
            <Link to="/C/variables">
            <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button></Link>
              <Link to="/C/data_types">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default CC7;
