import { useRef, useEffect } from "react";
import "./Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Q27 from "../Questions/Q27";
import Sidebar from "../SideBar/Sidebar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import Footer from "../Footer";

const Ch24 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };
  const code1 = `class Parent{
    public:
    virtual void makeSound() = 0;
};`;

  const code2 = `#include <iostream>
using namespace std;
//this is the abstract class
class Animals{
    public:
 		virtual void behavior() = 0;
};
 
class Lion : public Animals{
 	public:
 		void behavior(){
 			cout<<"Lion rules. \\n";
		 }
};
 
class Donkey : public Animals{
 	public:
 		void behavior(){
 			cout<<"Donkey works hard. \\n";
		 }
};
 
int main(){
 	Animals *animal1 = new Lion();
 	/*as animal1 is a pointer to animal
  class therefore we are using -> operator 
  instead of dot operator for accessing 
  the members*/

 	animal1->behavior();
 	
 	Animals *animal2 = new Donkey();
 	animal2->behavior();
    return 0;
}`;

  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/Cpp/polymorphism">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/Cpp/exceptions">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Virtual Functions:
              </h2>
              <div class="ui clearing divider"></div>
              <p>
                A virtual function is a function which is defined in base class
                and redefined in derived class.
                <br />
                Virtual functions give us the ability of run time polymorphism.{" "}
                <br />
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Rules for Virtual Functions:
                </h2>
                <div class="ui clearing divider"></div>
                <ul class="ui unordered list">
                  <li>They can not be static.</li>
                  <li>Virtual function can be a friend function.</li>
                  <li>They can be accessed using pointers of base class.</li>
                  <li>
                    They are alwaysdefined in main class and overridden in
                    derived class.
                  </li>
                  <li>
                    Virtual constructor is not allowed but virtual destructor is
                    allowed.
                  </li>
                </ul>
                When we call a virtual function using the pointer to base class,
                the most derived version of that function will be executed. We
                have discussed its implementation in our previous tutorial.{" "}
                <br />
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Pure Virtual Functions:
                </h2>
                <div class="ui clearing divider"></div>
                Pure virtual function is the function declared in the parent
                class but the parent class does not have its definition /
                implementation. But all the child classes have their own
                implementation of that function.
                <br />
                <br />
                <div ref={divRef}>
                  <SyntaxHighlighter language="cpp" style={darcula}>
                    {code1}
                  </SyntaxHighlighter>
                </div>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Abstract Class:
                </h2>
                <div class="ui clearing divider"></div>
                A class which has atleast one pure virtual function is called an
                abstract class. In the above code, the class named "Parent" is
                an abstract class. <br />
                <br />
                <p style={{ backgroundColor: "#FBEEAC", padding: "15px" }}>
                  <b>Note: </b>We can not make object of abstract class.
                </p>
                <br />
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Abstraction:
                </h2>
                <div class="ui clearing divider"></div>
                Abstraction is the hiding of implementation details of an object
                and exposing only the relevant and essential details to the
                users. <br />
                <b>For example,</b> when you feel headache you take medicine.
                You only take medicine. You get well and you do not know what is
                happening inside your body after taking medicine. That hidden
                details are abstractive for you. In short, in abstraction,
                complex details are hidden behind simple methods. <br />
                If you do not understand the example do not sweat it it, just
                move. <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>virtualFunc.cpp</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="cpp" style={darcula}>
                      {code2}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <p style={{ backgroundColor: "#FBEEAC", padding: "15px" }}>
                  <b>Note: </b>When we make a pointer to a class, we then access
                  the data members using arrow. In this case, we can not use dot
                  operator.
                </p>
                <br />
              </p>
            </div>
            <br />
            <Link to="/Cpp/polymorphism">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/Cpp/exceptions">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
      
    </>
  );
};
export default Ch24;
