import { useRef, useEffect } from "react";
import "../../Components/Chapters/Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "../PythonSideBar";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

const PythonC30 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };

  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor:'#D8D9DA'
  };
  const pc30code1 = `try:
    # Code that can raise exception will be written here
    result = 5 / 0  
    print("Answer : ",result)
except:
    # if exception occurs
    print("Exception : Not divisible by zero.")`;
  const pc30code2 = `try:
result = 10 / 0
except Exception as e:
print(f"An exception occurred: {e}")`;
const pc30code3 = `try:
    num1 = int(input("Enter a number: "))
    num2 = int(input("Enter another number: "))

    result = num1 / num2

    print(f"Result: {result}")
except ZeroDivisionError:
    print("Error: Zero division error")
except Exception as e:
    # Handle any other exceptions 
    print(f"An exception occurred: {e}")`
const pc30code4 = `try:
    num1 = int(input("Enter a number: "))
    num2 = int(input("Enter another number: "))

    result = num1 / num2
except ZeroDivisionError:
    print("Zero division not allowed.")
else:
    print(f"Result: {result}")`
const pc30code5 = `try:
    num1 = int(input("Enter a number: "))
    num2 = int(input("Enter another number: "))

    result = num1 / num2
except ZeroDivisionError:
    print("Zero division not allowed.")
else:
    print(f"Result: {result}")
finally:
    print("This block is always executed.")`
  return (
    <div class="testing">
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/python/json">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/python/file_handling">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Exception Handling in Python:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                In Python, the try and except blocks are used for exception
                handling. Exception handling allows us to gracefully handle
                errors and exceptions that may occur during the execution of
                our code. This is important because it prevents our program
                from crashing when it encounters unexpected issues.
                <br />
                <ul className="ui unordered list">
                  <li>
                    <b>try block </b>encloses that code which may raise an
                    exception.
                  </li>
                  <li>
                    <b>catch block </b>if exception raises in try block, control
                    jumps to catch block.
                  </li>
                  <li>
                    <b>else block </b>if no exception raises in try block, else
                    block is executed.
                  </li>
                  <li>
                    <b>finally block </b>will be executed all the time whether
                    exception occurs or not.
                  </li>
                </ul>
              </span>
              <br />
              
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>exception.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc30code1}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Catching Exceptions:
                </h2>
                <div className="ui clearing divider"></div>

                <span>
                  You can also determine the type of error in exception
                  handling. This is called generic exception handling.
                </span><br />

                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>exception.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc30code2}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Handling Multiple Exceptions:
                </h2>
                <div className="ui clearing divider"></div>
                <span>
                  Handling multiple exceptions in Python involves using multiple
                  except blocks, each handling a specific type of exception.
                </span><br /><br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>exception.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc30code3}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Else Block:
                </h2>
                <div className="ui clearing divider"></div>
                <span>
                    We can add an else block after catch block. It will be executed when no exception occurs. If there is 
                    an error, else block is not executed.
                </span>
                <br /><br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>exception.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc30code4}
                    </SyntaxHighlighter>
                  </div>
                </div>


                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Finally Block:
                </h2>
                <div className="ui clearing divider"></div>
                <span>
                    We can add a finally block at the end. It will be always be executed no matter error occurs or not.
                </span>
                <br /><br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>exception.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc30code5}
                    </SyntaxHighlighter>
                  </div>
                </div>
              
            </div><br />
            <Link to="/python/json">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/python/file_handling">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </div>
  );
};
export default PythonC30;
