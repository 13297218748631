import { useState, useEffect } from "react";
import Sidebar from "./SideBar";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
// import "./Quiz.css";
import "../../Components/Chapters/Chapters.css";
import Modal from "react-modal";

const PQuiz24 = () => {
  const [answers, setAnswers] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const handleQuizSubmit = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const questions = [
    {
        id: 1,
        question: "  What is a decorator in Python:",
        options: ["A data type", "A built-in function", "A way to enhance the behavior of functions or methods without changing their code", "A type of class"],
        correctAnswer: "A way to enhance the behavior of functions or methods without changing their code",
      },
      {
        id: 2,
        question: "  How are decorators defined in Python:",
        options: ["Using the @ symbol followed by the decorator function name above the target function", "Using the def keyword followed by the decorator function name", "Using the class keyword followed by the decorator function name", "Using the -> symbol followed by the decorator function name"],
        correctAnswer: "Using the @ symbol followed by the decorator function name above the target function",
      },
      {
        id: 3,
        question: "  What is the primary purpose of a decorator:",
        options: ["To add functionality to an existing function or method", "To change the name of a function", "To remove functionality from an existing function or method", "To create a new function"],
        correctAnswer: "To add functionality to an existing function or method",
      },
      {
        id: 4,
        question: "  In a decorator, what is the role of the wrapper function:",
        options: ["It defines a new function that replaces the original function", "It serves as a comment block to describe the original function", "It removes the original function's code and replaces it with new code", "It enhances the behavior of the original function by adding code before and after its execution"],
        correctAnswer: "It enhances the behavior of the original function by adding code before and after its execution",
      },
      {
        id: 5,
        question: "  How do you apply a decorator to a function in Python:",
        options: ["By calling the decorator function within the target function", "By defining the decorator as a class method", "By using the @ symbol followed by the decorator function name above the target function", "By using the $ symbol followed by the decorator function name above the target function"],
        correctAnswer: "By using the @ symbol followed by the decorator function name above the target function",
      },
      {
        id: 6,
        question: "  Which of the following is an example of a decorator usage in Python:",
        options: ["@performance_decorator", "class my_decorator:", "my_function(@decorator)", "def @my_decorator:"],
        correctAnswer: "@performance_decorator",
      }
  ];
  const handleAnswerChange = (questionId, selectedAnswer) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: selectedAnswer,
    }));
  };

  const calculateScore = () => {
    let correctCount = 0;
    let incorrectCount = 0;

    questions.forEach((question) => {
      const userAnswer = answers[question.id];
      if (userAnswer === question.correctAnswer) {
        correctCount++;
      } else {
        incorrectCount++;
      }
    });

    return { correctCount, incorrectCount };
  };

  const { correctCount, incorrectCount } = calculateScore();
  return (
    <>
      <Sidebar>
        <div class="container">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/quizes/python_quiz23">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                  }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/quizes/python_quiz25">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              </h2>
              <h1 class="start content ui left floated header">
                Decorators
              </h1>
              <div className="ui clearing divider"></div>
            

            <div class="main-body">
              <h2 class="ui left floated header">
                Quiz # 24
              </h2>
              <span class="tut-btn"><Link to="/python/decorators">
                  <button class="ui secondary button">Read Tutorial</button>
                </Link></span>
              <div class="ui clearing divider"></div>
              <div>
                {questions.map((question, i) => (
                  <div key={question.id}>
                    <div class="con">
                    <div class="que">Q{i+1}:<span class="one-per-left-margin">{question.question}</span></div>
                      {question.options.map((option) => (
                        <label class="label" key={option}>
                          <input
                            type="radio"
                            name={`question_${question.id}`}
                            value={option}
                            checked={answers[question.id] === option}
                            onChange={(e) =>
                              handleAnswerChange(question.id, e.target.value)
                            }
                          />
                          <span className="one-per-left-margin">{option}</span>
                          <br />
                        </label>
                      ))}
                    </div>
                    <hr />
                  </div>
                ))}<br/>
                <center><button class="ui primary button" onClick={handleQuizSubmit}>Submit Quiz</button></center>

                {/* Modal for displaying results */}
                <Modal class="modal" isOpen={isModalOpen} onRequestClose={closeModal}>
                  <b style={{fontSize:'20px'}}>Quiz Results</b>
                  <b><p>Your score is {correctCount}/{questions.length} </p></b>
                  {questions.map((question,i) => (
                    <div key={question.id}>
                      <p>Q{i+1}: {question.question}</p>
                      <p><b>Correct Answer:</b> {question.correctAnswer}</p>
                      <p className={`${question.correctAnswer}` === `${answers[question.id]}` ? "correct" : "wrong"}>
                        <b>Your Answer:</b> {answers[question.id] || "Not answered"}
                      </p>
                      <hr />
                    </div>
                  ))}
                  <button class="ui secondary button" onClick={closeModal}>Close</button><br /><br />
                </Modal>
              </div>

              <br />
            </div>
            <Link to="/quizes/python_quiz23">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                  }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/quizes/python_quiz25">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
            <br />
          </div>
        </div>
      </Sidebar>

      <div class="sideBarBody">
        <Footer />
      </div>
    </>
  );
};
export default PQuiz24;
