import React, { useState, useRef, useEffect } from "react";
import Sidebar from "./SideBar";
import "../../Components/SideBar/App.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const CodeAccordion = ({ questions, code, toggleAccordion, changeCodeLanguage }) => {
    const divRef = useRef(null);
    useEffect(() => {
      window.scrollTo(0, 0); // Scroll to the top when the component mounts
    }, []);
    const copyContent = () => {
        const divContent = divRef.current.innerText;
        navigator.clipboard
          .writeText(divContent)
          .then(() => {
            toast.success("Content copied!", { position: "top-center" });
          })
          .catch((error) => {
            toast.error("Error copying content:", error, {
              position: "top-center",
            });
          });
      };
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };

  return (
    <div>
      {questions.map((item, index) => (
        <div key={index} className="border p-2 rounded-md">
          <div className="flex justify-between">
            <span className="text-lg font-semibold">
              {item.question}
            </span>
            <button
              className="bg-gray-200 px-2 py-1 rounded-md"
              onClick={() => toggleAccordion(index)}
            >
              {item.isOpen ? "-" : "+"}
            </button>
          </div>
          <div className={`mt-2 ${item.isOpen ? "block" : "hidden"}`}>
            <div className="mt-2 flex space-x-4">
              {Object.keys(code).map((language) => (
                <button
                  key={language}
                  onClick={() => changeCodeLanguage(index, language)}
                  className={`${
                    item.codeLanguage === language
                      ? "bg-blue-500 hover:bg-blue-600 text-white"
                      : "bg-gray-200 hover:bg-gray-300 text-gray-700"
                  } px-3 py-2 rounded-md transition duration-300 ease-in-out transform hover:scale-105`}
                >
                  {language}
                </button>
              ))}
            </div>
            <p className="text-gray-800">
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>output.cpp</b>
                  <i
                    className="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language={`${item.codeLanguage}`} style={darcula}>
                    {code[item.codeLanguage]}
                  </SyntaxHighlighter>
                </div>
              </div>
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

const Question2 = () => {
  const code = {
    c: `// C code\n#include <stdio.h>\n\nint main() {\n\tchar name[] = "John";\n\tint age = 20;\n\tchar city[] = "New York";\n\n\tprintf("My name is %s\\n", name);\n\tprintf("I live in %s\\n", city);\n\tprintf("My age is %d\\n", age);\n\n\treturn 0;\n}`,
    cpp: `// C++ code\n#include <iostream>\nusing namespace std;\n\nint main() {\n\tstring name = "John";\n\tint age = 20;\n\tstring city = "New York";\n\tcout<<"My name is "<<name<<endl;\n\tcout<<"I live in "<<city<<endl;\n\tcout<<"My age is "<<age<<endl;\n\n\treturn 0;\n}`,
    java: `// Java code\npublic class Main {\n\tpublic static void main(String[] args) {\n\t\tString name = "John";\n\t\tint age = 20;\n\t\tString city = "New York";\n\n\t\tSystem.out.println("My name is " + name);\n\t\tSystem.out.println("I live in " + city);\n\t\tSystem.out.println("My age is " + age);\n\t}\n}`,
    python: `# Python code\nname = "John"\nage = 20\ncity = "New York"\n\nprint("My name is " + name)\nprint("I live in " + city)\nprint("My age is " + str(age))`,
  };

  const code1 = {
    c: `// C code\n#include <stdio.h>\n\n\tint main() {\n\tint a = 5;\n\tint b = 10;\n\n\t// Swap with a temporary variable\n\tint temp;\n\ttemp = a;\n\ta = b;\n\tb = temp;\n\tprintf("After swapping (with temp): a = %d, b = %d\\n", a, b);\n\n\t// Swap without a temporary variable\n\ta = a + b;\n\tb = a - b;\n\ta = a - b;\n\tprintf("After swapping (without temp): a = %d, b = %d\\n", a, b);\n\n\treturn 0;\n}`,
    cpp: `// C++ code\n#include <iostream>\nusing namespace std;\n\n\tint main() {\n\tint a = 5;\n\tint b = 10;\n\n\t// Swap with a temporary variable\n\tint temp;\n\ttemp = a;\n\ta = b;\n\tb = temp;\n\tcout << "After swapping (with temp): a = " << a << ", b = " << b << endl;\n\n\t// Swap without a temporary variable\n\ta = a + b;\n\tb = a - b;\n\ta = a - b;\n\tcout << "After swapping (without temp): a = " << a << ", b = " << b << endl;\n\n\treturn 0;\n}`,
    java: `// Java code\npublic class Main {\n\tpublic static void main(String[] args) {\n\t\tint a = 5;\n\t\tint b = 10;\n\n\t\t// Swap with a temporary variable\n\t\tint temp;\n\t\ttemp = a;\n\t\ta = b;\n\t\tb = temp;\n\t\tSystem.out.println("After swapping (with temp): a = " + a + ", b = " + b);\n\n\t\t// Swap without a temporary variable\n\t\ta = a + b;\n\t\tb = a - b;\n\t\ta = a - b;\n\t\tSystem.out.println("After swapping (without temp): a = " + a + ", b = " + b);\n\t}\n}`,
    python: `# Python code\na = 5\nb = 10\n\n# Swap with a temporary variable\ntemp = a\na = b\nb = temp\nprint("After swapping (with temp): a =", a, ", b =", b)\n\n# Swap without a temporary variable\na = a + b\nb = a - b\na = a - b\nprint("After swapping (without temp): a =", a, ", b =", b)`,
  };

  const code2 = {
    c: `// C code\n#include <stdio.h>\n\nint main() {\n\tint is_raining = 1; // 1 represents true, 0 represents false\n\n\tprintf("Is it raining? %s\\n", is_raining ? "True" : "False");\n\n\treturn 0;\n}`,
    cpp: `// C++ code\n#include <iostream>\nusing namespace std;\n\n\tint main() {\n\tbool is_raining = true;\n\n\tcout << "Is it raining? " << (is_raining ? "True" : "False") << endl;\n\n\treturn 0;\n}`,
    java: `// Java code\npublic class Main {\n\tpublic static void main(String[] args) {\n\t\tboolean is_raining = true;\n\n\t\tSystem.out.println("Is it raining? " + (is_raining ? "True" : "False"));\n\t}\n}`,
    python: `# Python code\nis_raining = True\n\nprint("Is it raining?", is_raining)`,
  };

  const [questions, setQuestions] = useState([
    {
      question: "Q1: Store your age, name and city in variables and print their values on screen.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions1, setQuestions1] = useState([
    {
      question: "Q2: Write a program to declare two variables named \"a\" and \"b\" and swap their values using temporary variable. Also swap their values without using temporary variables",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions2, setQuestions2] = useState([
    {
      question: "Q3: Declare a boolean variable named \"is_raining\" and set it to True. Print a message like \"Is it raining? True/False.\".",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);

  const toggleAccordion = (index, questions, setQuestions) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].isOpen = !updatedQuestions[index].isOpen;
    setQuestions(updatedQuestions);
  };

  const changeCodeLanguage = (index, language, questions, setQuestions) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].codeLanguage = language;
    setQuestions(updatedQuestions);
  };

  

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };
  const divRef = useRef(null);
  return (
    <div className="testing">
      <Sidebar>
        <div className="containera">
          <div className="ch-content">
            <h2 className="ui header">
            <Link to="/ques1">
              <button
                className="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
              </Link>
              <Link to="/ques3">
                <button
                  className="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>
            <div className="main-body ui ">
              <h2 className="ui left floated header" style={{ color: "#001C30" }}>
                Variables:
              </h2>
              <div className="ui clearing divider"></div>
              <CodeAccordion
                questions={questions}
                code={code}
                toggleAccordion={(index) => toggleAccordion(index, questions, setQuestions)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions, setQuestions)
                }
              /><br/>
              <CodeAccordion
                questions={questions1}
                code={code1}
                toggleAccordion={(index) => toggleAccordion(index, questions1, setQuestions1)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions1, setQuestions1)
                }
              />
              <br/>
              <CodeAccordion
                questions={questions2}
                code={code2}
                toggleAccordion={(index) => toggleAccordion(index, questions2, setQuestions2)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions2, setQuestions2)
                }
              />
            </div><br />
            <Link to="/ques1">
              <button
                className="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
              </Link>
              <Link to="/ques3">
                <button
                  className="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default Question2;
