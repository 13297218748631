import React, { useState, useRef, useEffect } from "react";
import Sidebar from "./SideBar";
import "../../Components/SideBar/App.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const CodeAccordion = ({ questions, code, toggleAccordion, changeCodeLanguage }) => {
    const divRef = useRef(null);
    const copyContent = () => {
        const divContent = divRef.current.innerText;
        navigator.clipboard
          .writeText(divContent)
          .then(() => {
            toast.success("Content copied!", { position: "top-center" });
          })
          .catch((error) => {
            toast.error("Error copying content:", error, {
              position: "top-center",
            });
          });
      };
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };

  return (
    <div>
      {questions.map((item, index) => (
        <div key={index} className="border p-2 rounded-md">
          <div className="flex justify-between">
            <span className="text-lg font-semibold">
              {item.question}
            </span>
            <button
              className="bg-gray-200 px-2 py-1 rounded-md"
              onClick={() => toggleAccordion(index)}
            >
              {item.isOpen ? "-" : "+"}
            </button>
          </div>
          <div className={`mt-2 ${item.isOpen ? "block" : "hidden"}`}>
            <div className="mt-2 flex space-x-4">
              {Object.keys(code).map((language) => (
                <button
                  key={language}
                  onClick={() => changeCodeLanguage(index, language)}
                  className={`${
                    item.codeLanguage === language
                      ? "bg-blue-500 hover:bg-blue-600 text-white"
                      : "bg-gray-200 hover:bg-gray-300 text-gray-700"
                  } px-3 py-2 rounded-md transition duration-300 ease-in-out transform hover:scale-105`}
                >
                  {language}
                </button>
              ))}
            </div>
            <p className="text-gray-800">
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>output.cpp</b>
                  <i
                    className="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language={`${item.codeLanguage}`} style={darcula}>
                    {code[item.codeLanguage]}
                  </SyntaxHighlighter>
                </div>
              </div>
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

const Question14 = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
    const code = {
        c: `// C code
#include <stdio.h>

int main() {
    int num = 42; // An integer variable
    int *ptr;     // Declare a pointer to an integer

    ptr = &num;   // Assign the address of 'num' to the pointer

    printf("Value at the address pointed to by the pointer: %d\\n", *ptr);

    return 0;
}`,
        cpp: `// C++ code
#include <iostream>
using namespace std;

int main() {
    int num = 42;    // An integer variable
    int *ptr;        // Declare a pointer to an integer

    ptr = &num;      // Assign the address of 'num' to the pointer

    cout << "Value at the address pointed to by the pointer: " << *ptr << endl;

    return 0;
}`,
        
    };
    const code1 = {
        c: `// C code
#include <stdio.h>

void swap(int *a, int *b) {
    int temp = *a;
    *a = *b;
    *b = temp;
}

int main() {
    int num1 = 5;
    int num2 = 10;

    printf("Before swapping: num1 = %d, num2 = %d\\n", num1, num2);

    swap(&num1, &num2);

    printf("After swapping: num1 = %d, num2 = %d\\n", num1, num2);

    return 0;
}`,
        cpp: `// C++ code
#include <iostream>
using namespace std;

void swap(int &a, int &b) {
    int temp = a;
    a = b;
    b = temp;
}

int main() {
    int num1 = 5;
    int num2 = 10;

    cout << "Before swapping: num1 = " << num1 << ", num2 = " << num2 << endl;

    swap(num1, num2);

    cout << "After swapping: num1 = " << num1 << ", num2 = " << num2 << endl;

    return 0;
}`,
        
    };
    const code2 = {
        c: `// C code
#include <stdio.h>
#include <stdlib.h>

int main() {
    int *numPtr; // Declare a pointer to an integer

    // Allocate memory for an integer
    numPtr = (int *)malloc(sizeof(int));

    if (numPtr == NULL) {
        printf("Memory allocation failed.\\n");
        return 1;
    }

    // Assign a value to the allocated memory
    *numPtr = 42;

    printf("Value stored in allocated memory: %d\\n", *numPtr);

    // Free the allocated memory
    free(numPtr);

    return 0;
}`,
        cpp: `// C++ code
#include <iostream>
#include <cstdlib>
using namespace std;

int main() {
    int *numPtr; // Declare a pointer to an integer

    // Allocate memory for an integer
    numPtr = (int *)malloc(sizeof(int));

    if (numPtr == nullptr) {
        cout << "Memory allocation failed." << endl;
        return 1;
    }

    // Assign a value to the allocated memory
    *numPtr = 42;

    cout << "Value stored in allocated memory: " << *numPtr << endl;

    // Free the allocated memory
    free(numPtr);

    return 0;
}`,
        
    };
    const code3 = {
        c: `// C code
#include <stdio.h>
#include <stdlib.h>

typedef struct DynamicArray {
    int *data;
    size_t size;
    size_t capacity;
} DynamicArray;

void initDynamicArray(DynamicArray *arr, size_t initialCapacity) {
    arr->data = (int *)malloc(initialCapacity * sizeof(int));
    arr->size = 0;
    arr->capacity = initialCapacity;
}

void appendElement(DynamicArray *arr, int element) {
    if (arr->size == arr->capacity) {
        arr->capacity *= 2;
        arr->data = (int *)realloc(arr->data, arr->capacity * sizeof(int));
    }
    arr->data[arr->size++] = element;
}

void freeDynamicArray(DynamicArray *arr) {
    free(arr->data);
    arr->size = 0;
    arr->capacity = 0;
}

int main() {
    DynamicArray arr;
    initDynamicArray(&arr, 2);

    for (int i = 0; i < 10; i++) {
        appendElement(&arr, i * 10);
    }

    for (size_t i = 0; i < arr.size; i++) {
        printf("Element %zu: %d\\n", i, arr.data[i]);
    }

    freeDynamicArray(&arr);

    return 0;
}`,
        cpp: `// C++ code
#include <iostream>
using namespace std;

class DynamicArray {
public:
    DynamicArray(size_t initialCapacity) {
        data = new int[initialCapacity];
        size = 0;
        capacity = initialCapacity;
    }

    ~DynamicArray() {
        delete[] data;
    }

    void appendElement(int element) {
        if (size == capacity) {
            capacity *= 2;
            int *newData = new int[capacity];
            for (size_t i = 0; i < size; i++) {
                newData[i] = data[i];
            }
            delete[] data;
            data = newData;
        }
        data[size++] = element;
    }

    void printElements() {
        for (size_t i = 0; i < size; i++) {
            cout << "Element " << i << ": " << data[i] << endl;
        }
    }

private:
    int *data;
    size_t size;
    size_t capacity;
};

int main() {
    DynamicArray arr(2);

    for (int i = 0; i < 10; i++) {
        arr.appendElement(i * 10);
    }

    arr.printElements();

    return 0;
}`,
        
    };
    const code4 = {
        c: `// C code
#include <stdio.h>

int main() {
    int arr[] = {1, 2, 3, 4, 5};
    int *ptr = arr; // Initialize a pointer to the start of the array

    for (int i = 0; i < sizeof(arr) / sizeof(arr[0]); i++) {
        printf("Element %d: %d\\n", i, *ptr);
        ptr++; // Move the pointer to the next element
    }

    return 0;
}`,
        cpp: `// C++ code
#include <iostream>
using namespace std;

int main() {
    int arr[] = {1, 2, 3, 4, 5};
    int *ptr = arr; // Initialize a pointer to the start of the array

    for (int i = 0; i < sizeof(arr) / sizeof(arr[0]); i++) {
        cout << "Element " << i << ": " << *ptr << endl;
        ptr++; // Move the pointer to the next element
    }

    return 0;
}`,
        
    };
    const code5 = {
        c: `// C code
#include <stdio.h>

size_t stringLength(const char *str) {
    const char *ptr = str; // Initialize a pointer to the start of the string

    while (*ptr != '\\0') {
        ptr++; // Move the pointer to the next character
    }

    return ptr - str;
}

int main() {
    const char *text = "Hello, World!";
    size_t length = stringLength(text);
    printf("Length of the string: %zu\\n", length);

    return 0;
}`,
        cpp: `// C++ code
#include <iostream>
using namespace std;

size_t stringLength(const char *str) {
    const char *ptr = str; // Initialize a pointer to the start of the string

    while (*ptr != '\0') {
        ptr++; // Move the pointer to the next character
    }

    return ptr - str;
}

int main() {
    const char *text = "Hello, World!";
    size_t length = stringLength(text);
    cout << "Length of the string: " << length << endl;

    return 0;
}`,
        
    };
    const code6 = {
        c: `// C code
#include <stdio.h>

int main() {
    int arr[] = {10, 20, 30, 40, 50};
    int *ptr = arr; // Initialize a pointer to the start of the array
    int n = 2;      // Find the 3rd element (0-based index)

    // Use pointer arithmetic to find the Nth element
    int *nthElement = ptr + n;

    if (nthElement >= arr && nthElement < arr + sizeof(arr) / sizeof(arr[0])) {
        printf("Nth element: %d\\n", *nthElement);
    } else {
        printf("Index out of bounds\\n");
    }

    return 0;
}`,
        cpp: `// C++ code
#include <iostream>
using namespace std;

int main() {
    int arr[] = {10, 20, 30, 40, 50};
    int *ptr = arr; // Initialize a pointer to the start of the array
    int n = 2;      // Find the 3rd element (0-based index)

    // Use pointer arithmetic to find the Nth element
    int *nthElement = ptr + n;

    if (nthElement >= arr && nthElement < arr + sizeof(arr) / sizeof(arr[0])) {
        cout << "Nth element: " << *nthElement << endl;
    } else {
        cout << "Index out of bounds" << endl;
    }

    return 0;
}`,
        
    };
    const code7 = {
        c: `// C code
#include <stdio.h>
#include <string.h>
#include <stdlib.h>

char* concatenateStrings(const char* str1, const char* str2) {
    size_t len1 = strlen(str1);
    size_t len2 = strlen(str2);
    char* result = (char*)malloc(len1 + len2 + 1);

    if (result != NULL) {
        strcpy(result, str1);
        strcat(result, str2);
    }

    return result;
}

int main() {
    const char* string1 = "Hello, ";
    const char* string2 = "World!";
    char* concatenated = concatenateStrings(string1, string2);

    if (concatenated != NULL) {
        printf("Concatenated string: %s\\n", concatenated);
        free(concatenated);
    } else {
        printf("Memory allocation failed.\\n");
    }

    return 0;
}`,
        cpp: `// C++ code
#include <iostream>
#include <cstring>
using namespace std;

char* concatenateStrings(const char* str1, const char* str2) {
    size_t len1 = strlen(str1);
    size_t len2 = strlen(str2);
    char* result = new char[len1 + len2 + 1];

    if (result != nullptr) {
        strcpy(result, str1);
        strcat(result, str2);
    }

    return result;
}

int main() {
    const char* string1 = "Hello, ";
    const char* string2 = "World!";
    char* concatenated = concatenateStrings(string1, string2);

    if (concatenated != nullptr) {
        cout << "Concatenated string: " << concatenated << endl;
        delete[] concatenated;
    } else {
        cout << "Memory allocation failed." << endl;
    }

    return 0;
}`,
        
    };
    const code8 = {
        c: `// C code
#include <stdio.h>

// Define the structure for a student
struct Student {
    char name[50];
    int age;
};

int main() {
    // Create an array of students
    struct Student students[3];

    // Initialize the attributes of the first student
    strcpy(students[0].name, "Alice");
    students[0].age = 20;

    // Initialize the attributes of the second student
    strcpy(students[1].name, "Bob");
    students[1].age = 22;

    // Initialize the attributes of the third student
    strcpy(students[2].name, "Charlie");
    students[2].age = 21;

    // Access and print the attributes of the students using pointers
    struct Student *ptr = students;

    for (int i = 0; i < 3; i++) {
        printf("Student %d - Name: %s, Age: %d\\n", i + 1, ptr->name, ptr->age);
        ptr++;
    }

    return 0;
}`,
        cpp: `// C++ code
#include <iostream>
#include <cstring>
using namespace std;

// Define the structure for a student
struct Student {
    char name[50];
    int age;
};

int main() {
    // Create an array of students
    Student students[3];

    // Initialize the attributes of the first student
    strcpy(students[0].name, "Alice");
    students[0].age = 20;

    // Initialize the attributes of the second student
    strcpy(students[1].name, "Bob");
    students[1].age = 22;

    // Initialize the attributes of the third student
    strcpy(students[2].name, "Charlie");
    students[2].age = 21;

    // Access and print the attributes of the students using pointers
    Student *ptr = students;

    for (int i = 0; i < 3; i++) {
        cout << "Student " << i + 1 << " - Name: " << ptr->name << ", Age: " << ptr->age << endl;
        ptr++;
    }

    return 0;
}`,
        
    };
    const code9 = {
        c: `// C code
#include <stdio.h>

void calculateSumAndDifference(int num1, int num2, int *sumResult, int *differenceResult) {
    *sumResult = num1 + num2;
    *differenceResult = num1 - num2;
}

int main() {
    int number1 = 10;
    int number2 = 5;
    int sum, difference;

    calculateSumAndDifference(number1, number2, &sum, &difference);

    printf("Sum: %d\\n", sum);
    printf("Difference: %d\\n", difference);

    return 0;
}`,
        cpp: `// C++ code
#include <iostream>
using namespace std;

void calculateSumAndDifference(int num1, int num2, int &sumResult, int &differenceResult) {
    sumResult = num1 + num2;
    differenceResult = num1 - num2;
}

int main() {
    int number1 = 10;
    int number2 = 5;
    int sum, difference;

    calculateSumAndDifference(number1, number2, sum, difference);

    cout << "Sum: " << sum << endl;
    cout << "Difference: " << difference << endl;

    return 0;
}`,
        
    };
    const code10 = {
        c: `// C code
#include <stdio.h>

void findMaxMin(const int *arr, size_t size, int *maxResult, int *minResult) {
    if (size == 0) {
        return; // Handle an empty array if necessary
    }

    *maxResult = *arr;
    *minResult = *arr;

    for (size_t i = 1; i < size; i++) {
        if (arr[i] > *maxResult) {
            *maxResult = arr[i];
        } else if (arr[i] < *minResult) {
            *minResult = arr[i];
        }
    }
}

int main() {
    int numbers[] = {10, 5, 20, 30, 15};
    size_t size = sizeof(numbers) / sizeof(numbers[0]);
    int max, min;

    findMaxMin(numbers, size, &max, &min);

    printf("Maximum value: %d\\n", max);
    printf("Minimum value: %d\\n", min);

    return 0;
}`,
        cpp: `// C++ code
#include <iostream>
using namespace std;

void findMaxMin(const int *arr, size_t size, int &maxResult, int &minResult) {
    if (size == 0) {
        return; // Handle an empty array if necessary
    }

    maxResult = *arr;
    minResult = *arr;

    for (size_t i = 1; i < size; i++) {
        if (arr[i] > maxResult) {
            maxResult = arr[i];
        } else if (arr[i] < minResult) {
            minResult = arr[i];
        }
    }
}

int main() {
    int numbers[] = {10, 5, 20, 30, 15};
    size_t size = sizeof(numbers) / sizeof(numbers[0]);
    int max, min;

    findMaxMin(numbers, size, max, min);

    cout << "Maximum value: " << max << endl;
    cout << "Minimum value: " << min << endl;

    return 0;
}`,
        
    };

  const [questions, setQuestions] = useState([
    {
      question: "Q1: Create a pointer variable, assign it the address of an integer, and print the value at that address.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions1, setQuestions1] = useState([
    {
      question: "Q2: Swap the values of two variables using pointers.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions2, setQuestions2] = useState([
    {
      question: "Q3: Allocate memory for an integer using malloc function, assign a value, and then free the memory.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions3, setQuestions3] = useState([
    {
      question: "Q4: Implement a dynamic array using malloc or new, add elements, and resize the array as needed.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions4, setQuestions4] = useState([
    {
      question: "Q5: Iterate through an integer array using a pointer.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions5, setQuestions5] = useState([
    {
      question: "Q6: Write a function to find the length of a string (null-terminated character array) using pointers.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions6, setQuestions6] = useState([
    {
      question: "Q7: Given an array and a pointer to an element, find the Nth element using pointer arithmetic.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions7, setQuestions7] = useState([
    {
      question: "Q8: Implement a function to concatenate two C-style strings.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions8, setQuestions8] = useState([
    {
      question: "Q9: Define a structure representing a student with name and age. Create an array of students of struct type and access their attributes. Use pointers for this purpose.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions9, setQuestions9] = useState([
    {
      question: "Q10: Write a function that calculates the sum and difference of two integers and returns the results through pointers.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions10, setQuestions10] = useState([
    {
      question: "Q11: Implement a function to find the maximum and minimum values in an array of integers by using pointers.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  

  const toggleAccordion = (index, questions, setQuestions) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].isOpen = !updatedQuestions[index].isOpen;
    setQuestions(updatedQuestions);
  };

  const changeCodeLanguage = (index, language, questions, setQuestions) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].codeLanguage = language;
    setQuestions(updatedQuestions);
  };

  

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };
  const divRef = useRef(null);
  return (
    <div className="testing">
      <Sidebar>
        <div className="containera">
          <div className="ch-content">
            <h2 className="ui header">
            <Link to="/ques13">
              <button
                className="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
              </Link>
              <Link to="/ques15">
                <button
                  className="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>
            <div className="main-body ui">
              <h2 className="ui left floated header" style={{ color: "#001C30" }}>
                Pointers:
              </h2>
              <div className="ui clearing divider"></div>
              <CodeAccordion
                questions={questions}
                code={code}
                toggleAccordion={(index) => toggleAccordion(index, questions, setQuestions)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions, setQuestions)
                }
              /><br/>
              <CodeAccordion
                questions={questions1}
                code={code1}
                toggleAccordion={(index) => toggleAccordion(index, questions1, setQuestions1)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions1, setQuestions1)
                }
              /><br/>
              <CodeAccordion
                questions={questions2}
                code={code2}
                toggleAccordion={(index) => toggleAccordion(index, questions2, setQuestions2)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions2, setQuestions2)
                }
              /><br/>
              <CodeAccordion
                questions={questions3}
                code={code3}
                toggleAccordion={(index) => toggleAccordion(index, questions3, setQuestions3)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions3, setQuestions3)
                }
              /><br/>
              <CodeAccordion
                questions={questions4}
                code={code4}
                toggleAccordion={(index) => toggleAccordion(index, questions4, setQuestions4)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions4, setQuestions4)
                }
              /><br/>
              <CodeAccordion
                questions={questions5}
                code={code5}
                toggleAccordion={(index) => toggleAccordion(index, questions5, setQuestions5)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions5, setQuestions5)
                }
              /><br/>
              <CodeAccordion
                questions={questions6}
                code={code6}
                toggleAccordion={(index) => toggleAccordion(index, questions6, setQuestions6)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions6, setQuestions6)
                }
              /><br/>
              <CodeAccordion
                questions={questions7}
                code={code7}
                toggleAccordion={(index) => toggleAccordion(index, questions7, setQuestions7)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions7, setQuestions7)
                }
              /><br/>
              <CodeAccordion
                questions={questions8}
                code={code8}
                toggleAccordion={(index) => toggleAccordion(index, questions8, setQuestions8)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions8, setQuestions8)
                }
              /><br/>
              <CodeAccordion
                questions={questions9}
                code={code9}
                toggleAccordion={(index) => toggleAccordion(index, questions9, setQuestions9)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions9, setQuestions9)
                }
              /><br/>
              <CodeAccordion
                questions={questions10}
                code={code10}
                toggleAccordion={(index) => toggleAccordion(index, questions10, setQuestions10)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions10, setQuestions10)
                }
              /><br/>
              
            </div>
            <Link to="/ques13">
              <button
                className="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
              </Link>
              <Link to="/ques15">
                <button
                  className="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default Question14;
