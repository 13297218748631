import { useRef, useEffect } from "react";
import Footer from "../../Components/Footer";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./style.css";

const PythonatGlance = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    padding: "20px",
    borderRadius: "3px",
    marginLeft: "0%",
  };
  const code2 = `print("Hello World")`;
  const code3 = `num = input("Enter a number")`;
  const code4 = `#this is a single lined comment.

""" this is a 
multi-lined comment """`;

  const code7 = `#declaring string
my_string = "Hello, World!"

#finding length of string
len(my_string)

#accessing characters of string
my_string[0]

#string slicing
my_string[7:12]        #Extracts a substring from index 7 to 11 (12 is exclusive).
my_string[:5]        #Extracts characters from the beginning up to index 4.
my_string[7:]        #Extracts characters from index 7 to the end.

#string concatenation
new_string = my_string + " How are you?"

#string methods
my_string.lower()        #Converts the string to lowercase.
my_string.upper()        #Converts the string to uppercase.
my_string.strip()        #Removes leading and trailing whitespace.
my_string.replace("Hello", "Hi")        #Replaces occurrences of "Hello" with "Hi".
my_string.split(",")        #Splits the string into a list using a delimiter (e.g., a comma).
my_string.find("World")        #Returns the index where "World" is found in the string (or -1 if not found).
my_string.startswith("Hello")        #Checks if the string starts with "Hello".
my_string.endswith("!")        #Checks if the string ends with "!"

#string formatting
{} is {} years old".format("Alice", 25)

#string interpolation
f"{name} is {age} years old"

#checking substrings
"Hello" in my_string
`;
  const code8 = `Arithmetic Operators
+ #addition
- #subtraction
* #multiplication
/ #division
% #modulus
// #floor division
** #exponentiation

Assignment Operators
= #assign value
+= #add and assign
-= #subtract and assign
/= #divide and assign
*= #multiply and assign
%= #taking modulus and assign
**= #exponentiation assignment
|= #bitwise OR assignment
&= #bitwise AND assignment
^= #bitwise XOR assignment
<<= #left shift assingment
>>+ # right shift assignment

Comparison Operators
== #checks equality
!= #checks unequality
> #checks greater than
>= #checks greater than or equal to
< #check less than
<= #checks less than or equal to

Logical Operators
and #returns true if all are expressions true
or #returns true if only one expression is true
not #returns true if false and vice versa

Bitwise Operators
& #bitwise AND
| #bitwise OR
~ #bitwise NOT
^ #bitwise XOR
&rs #shift right 
&ls #shift left

Identuty Operators
is #returns True if both operands point to the same object in memory,
is not #returns True if both operands point to the same object in memory,`;
  const code9 = `#if statement
if condition:
# Code to be executed if the condition is true

#if-else statement
if condition:
    # Code to be executed if the condition is true
else:
    # Code to be executed if the condition is false


#if-else-elif statement
if condition1:
    # Code to be executed if condition1 is true
elif condition2:
    # Code to be executed if condition2 is true
else:
    # Code to be executed if none of the conditions are true


#Ternary Operator
variable = condition ? ifTrue : ifFalse;
`;
  const code10 = `switch(expression){
    case const-exp:
        #code
        break;
    case const-exp:
        #code
        break;
    .
    .
    .
    default:
        #code
        break;
}`;

  const code11 = `#for Loop:
for variable in sequence:
# Code to be executed for each item in the sequence

for i in range(5):  # Loop from 0 to 4
  print(i)

#while Loop:
while condition:
  # Code to be executed while the condition is true

`;


  const code15 = `def add(a, b):
  result = a + b
  return result
`;
  const code16 = `#break statement
for i in range(5):
  if i == 3:
      break  # Exit the loop when i is 3
  print(i)

#continue
for i in range(5):
  if i == 2:
    continue  # Skip iteration when i is 2
  print(i)

#pass
for i in range(5):
  if i == 2:
    pass  # Placeholder for future code
  print(i)`;
  const code17 = `#escape sequences
\\n            #adds a new line
\\t      #adds four spaces
\\b      #backspace
\\a      #beep sound
\\f      #form feed
\\r            #carriage return
\\\\      #adds backslash
\\'      #adds single quote
\\"            #adds double quote
\\?           #adds question mark
\\0          #null character`;

  

  const code27 = `try:
  # Code that may raise an exception
  x = 10 / 2  # No exception here
except ZeroDivisionError as e:
  # Handle the exception
  print(f"An exception occurred: {e}")
else:
  # Code to execute if no exceptions occurred
  print("No exceptions occurred.")
finally:
  # Code that always runs
  print("This code always runs.")`;

  const code29 = `# Open a file for reading
file = open("example.txt", "r")

#Reading from file
content = file.read()  # Reads the entire file content into a string
print(content)

line = file.readline()  # Reads one line from the file
print(line)

lines = file.readlines()  # Reads all lines into a list
for line in lines:
    print(line)
    
#Writing to file
with open("output.txt", "w") as outfile:
    outfile.write("This is some text to write to the file.")

#Closing a file
with open("example.txt", "r") as file:
    content = file.read()

#Appending to a file
with open("example.txt", "a") as file:
    file.write("This content will be added to the end of the file.")

#checking file existence
import os

if os.path.exists("example.txt"):
    print("The file exists.")
else:
    print("The file does not exist.")

`;

  const code33 = `#type casting
x = 123
y = str(x)  # y will be the string '123'`;

  const code34 = `def greet(name, age=None):
  if age is not None:
      print(f"Hello, {name}! You are {age} years old.")
  else:
      print(f"Hello, {name}!")

# Usage
greet("Alice")           # Prints "Hello, Alice!"
greet("Bob", 30)         # Prints "Hello, Bob! You are 30 years old."
`;

const code35 = `my_list = [1, 2, 3, 4, 5]
#append
my_list.append(6)  # Adds 6 to the end of the list

#extend
other_list = [7, 8, 9]
my_list.extend(other_list)

#insert
my_list.insert(2, 10)  # Inserts 10 at index 2

#remove
my_list.remove(3)  # Removes the first occurrence of 3

#pop
popped_value = my_list.pop(1)  # Removes and returns the element at index 1

#index
index = my_list.index(4)  # Returns the index of the first occurrence of 4

#count
count = my_list.count(4)  # Returns the number of times 4 appears in the list

#sort
my_list.sort()

#reverse
my_list.reverse()

#copy
copied_list = my_list.copy()

#clear
my_list.clear()`

const code36 = `my_tuple = (1, 2, 3, 'apple', 'banana')

#accessing elements
first_element = my_tuple[0]  # Access the first element (1)

#slicing
sub_tuple = my_tuple[1:3]  # Creates a new tuple with elements (2, 3)

#length
length = len(my_tuple)  # Returns 5

#count
count = my_tuple.count(3)  # Returns 1 (there is one 3 in the tuple)

#index
index = my_tuple.index('apple')  # Returns 3 (the index of 'apple' in the tuple)

#membership
is_present = 'pear' in my_tuple  # Returns False

#concatenation
new_tuple = my_tuple + (4, 5)  # Creates a new tuple (1, 2, 3, 'apple', 'banana', 4, 5)

#repetition
repeated_tuple = my_tuple * 2  # Creates a new tuple (1, 2, 3, 'apple', 'banana', 1, 2, 3, 'apple', 'banana')
`
const code37 = `my_dict = {'key1': 'value1', 'key2': 'value2', 'key3': 'value3'}

#accessing elements
value = my_dict['key1']  # Retrieves the value associated with 'key1'

#get()
value = my_dict.get('key2')  # Retrieves the value associated with 'key2'

#keys()
keys = my_dict.keys()  # Returns a view of keys

#values()
values = my_dict.values()  # Returns a view of values

#items()
items = my_dict.items()  # Returns a view of (key, value) pairs

#pop()
value = my_dict.pop('key3')  # Removes and returns the value associated with 'key3'

#popitem()
key, value = my_dict.popitem()  # Removes and returns a random (key, value) pair

#update()
other_dict = {'key4': 'value4'}
my_dict.update(other_dict)  # Adds 'key4': 'value4' to my_dict

#clear()
my_dict.clear()  # Removes all items from my_dict

#copy()
copy_dict = my_dict.copy()  # Creates a copy of my_dict

#setdefault()
value = my_dict.setdefault('key5', 'default_value')  # Adds 'key5': 'default_value' if 'key5' doesn't exist and returns 'default_value'
`
const code38 = `my_set = {1, 2, 3, 4, 5}

#add()
my_set.add(6)

#update()
my_set.update([7, 8, 9])

#remove()
my_set.remove(3)

#discard()
my_set.discard(10)  # No error raised, as 10 is not in the set

#pop()
popped_element = my_set.pop()

#clear()
my_set.clear()

#copy()
copy_set = my_set.copy()

#union()
set1 = {1, 2, 3}
set2 = {3, 4, 5}
union_set = set1.union(set2)  # union_set contains {1, 2, 3, 4, 5}

#intersection
set1 = {1, 2, 3}
set2 = {3, 4, 5}
intersection_set = set1.intersection(set2)  # intersection_set contains {3}

#difference
set1 = {1, 2, 3}
set2 = {3, 4, 5}
difference_set = set1.difference(set2)  # difference_set contains {1, 2}

#symmetric difference
set1 = {1, 2, 3}
set2 = {3, 4, 5}
symmetric_difference_set = set1.symmetric_difference(set2)  # symmetric_difference_set contains {1, 2, 4, 5}

#issubset()
set1 = {1, 2, 3}
set2 = {1, 2}
is_subset = set2.issubset(set1)  # Returns True, as set2 is a subset of set1

#issuperset()
set1 = {1, 2, 3}
set2 = {1, 2}
is_superset = set1.issuperset(set2)  # Returns True, as set1 is a superset of set2`

const code39 = `class MyClass:
def __init__(self, name, age):
    self.name = name
    self.age = age

def greet(self):
    return f"Hello, my name is {self.name} and I am {self.age} years old."`

const code40 = `person = MyClass("Alice", 30)`

const code41 = `print(person.name)  # Accessing an attribute
greeting = person.greet()  # Calling a method
print(greeting)`

const code42 = `class SubClass(MyClass):
def __init__(self, name, age, hobby):
    super().__init__(name, age)
    self.hobby = hobby`

const code43 = `class MyClass:
def __init__(self):
    self._var = 10  # Weak internal use indicator
    self.__secret = 20  # Private attribute`

const code44 = `class Shape:
def area(self):
    pass

class Circle(Shape):
def __init__(self, radius):
    self.radius = radius

def area(self):
    return 3.14159 * self.radius ** 2`

const code45 = `class MyClass:
def __del__(self):
    print("Object destroyed")`

  return (
    <>
      <br />
      <br />
      <br />
      <br />

      <div>
        <div
          class=""
          style={{
            width: "80%",
            marginLeft: "10%",
            marginBottom: "5%",
          }}
        >
          <h2 class="ui left floated header">Python at Glance</h2>
          <div class="ui clearing divider"></div>

          <p>
            <span class="h1">print()</span>
            <div class="ui clearing divider"></div>
            <div>print() is used to print on console. </div>
            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="python" style={darcula}>
                  {code2}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

          <p>
            <span class="h1">input()</span>
            <div class="ui clearing divider"></div>
            <div>input() is used to take input from user. </div>
            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="python" style={darcula}>
                  {code3}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

          <p>
            <span class="h1">Comments</span>
            <div class="ui clearing divider"></div>
            <div>
              Comments are used to make your code more readable. Everything in
              comments are ignored by the compiler.
            </div>
            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="python" style={darcula}>
                  {code4}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

          <p>
            <span class="h1">Escape Sequences</span>
            <div class="ui clearing divider"></div>
            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="python" style={darcula}>
                  {code17}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

          <p>
            <span class="h1">Type Casting</span>
            <div class="ui clearing divider"></div>
            <div>Type casting refers to changing data type of a variable.</div>
            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="python" style={darcula}>
                  {code33}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

          <p>
            <span class="h1">String and its methods</span>
            <div class="ui clearing divider"></div>
            <div>Strings are sequence of characters.</div>
            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="python" style={darcula}>
                  {code7}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

          <p>
            <span class="h1">Operators</span>
            <div class="ui clearing divider"></div>

            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="python" style={darcula}>
                  {code8}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

          <p>
            <span class="h1">Lists</span>
            <div class="ui clearing divider"></div>

            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="python" style={darcula}>
                  {code35}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

          <p>
            <span class="h1">Tuples</span>
            <div class="ui clearing divider"></div>

            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="python" style={darcula}>
                  {code36}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

          <p>
            <span class="h1">Dictionaries</span>
            <div class="ui clearing divider"></div>

            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="python" style={darcula}>
                  {code37}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

          <p>
            <span class="h1">Sets</span>
            <div class="ui clearing divider"></div>

            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="python" style={darcula}>
                  {code38}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

          <p>
            <span class="h1">Conditional statements</span>
            <div class="ui clearing divider"></div>
            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="python" style={darcula}>
                  {code9}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

          <p>
            <span class="h1">Loops</span>
            <div class="ui clearing divider"></div>

            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="python" style={darcula}>
                  {code11}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

          <p>
            <span class="h1">Continue And Break Statements</span>
            <div class="ui clearing divider"></div>

            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="python" style={darcula}>
                  {code16}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

          <p>
            <span class="h1">Functions</span>
            <div class="ui clearing divider"></div>

            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="python" style={darcula}>
                  {code15}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

          <p>
            <span class="h1">Function Overloading</span>
            <div class="ui clearing divider"></div>

            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="python" style={darcula}>
                  {code34}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />


          <p>
            <span class="h1">Classes</span>
            <div class="ui clearing divider"></div>

            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="python" style={darcula}>
                  {code39}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

          <p>
            <span class="h1">Object</span>
            <div class="ui clearing divider"></div>

            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="python" style={darcula}>
                  {code40}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

          <p>
            <span class="h1">Accessing attributes and methods</span>
            <div class="ui clearing divider"></div>

            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="python" style={darcula}>
                  {code41}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

          <p>
            <span class="h1">Inheritance</span>
            <div class="ui clearing divider"></div>

            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="python" style={darcula}>
                  {code42}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

          <p>
            <span class="h1">Encapsulation</span>
            <div class="ui clearing divider"></div>

            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="python" style={darcula}>
                  {code43}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

          <p>
            <span class="h1">Polymorphism and Method Overriding</span>
            <div class="ui clearing divider"></div>

            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="python" style={darcula}>
                  {code44}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

          <p>
            <span class="h1">Destructors</span>
            <div class="ui clearing divider"></div>

            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="python" style={darcula}>
                  {code45}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />


          <p>
            <span class="h1">Exceptions</span>
            <div class="ui clearing divider"></div>

            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="python" style={darcula}>
                  {code27}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

          <p>
            <span class="h1">File Handling</span>
            <div class="ui clearing divider"></div>

            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="python" style={darcula}>
                  {code29}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />


        </div>
      </div>
      <Footer />
    </>
  );
};
export default PythonatGlance;
