import { useRef, useEffect } from "react";
// import "./Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Sidebar from "../CSideBar";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const CC4 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };
  const code0 = `printf(variableName)`;
  const code1 = `printf("Text to be printed")`;
  const code2 = `#include <stdio.h>

int main() {
  printf("Hello World!");
  return 0;
}`;
  const code3 = `#include <stdio.h>

int main() {
  printf("Hi there! \\n I am good.");
  return 0;
}`;

  const code5 = `printf("\\n");`;
  const code6 = `printf("\\t");`;
  const code7 = `printf("\\a");`;
  const code8 = `printf("\\b");`;
  const code9 = `printf("\\f");`;
  const code10 = `printf("\\r");`;
  const code11 = `printf("\\\\");`;
  const code12 = `printf("\\'");`;
  const code13 = `printf("\\?");`;
  const code14 = `printf("\\0");`;
  const code15 = `#include <stdio.h>

int main() {
  int num = 10;
  printf("%d", num);  
  return 0;
}`;
  const code16 = `#include <stdio.h> 

int main() {
  // Declare and initialize variables
  int age = 30;
  double pi = 3.14159265359;
  char grade = 'A';
  
  // Printing variables
  printf("My age is %d years.\\n", age);
  printf("Pi = %.2f\\n", pi);
  printf("My grade is %c\\n", grade);
  
  // Printing variables with additional text
  printf("Age: %d, Grade: %c.\\n", age, grade);
  
  return 0; 
}`;
  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/C/syntax">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/C/comments">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Printing Output:
              </h2>
              <div class="ui clearing divider"></div>
              <p>
                The printf function is used to print text on the screen. The
                data to be printed on screen is text or variable. <br />
                <ul className="ui unordered list">
                  <li>If it is text, it is enclosed in quotation marks.</li>
                </ul>
                <SyntaxHighlighter language="c" style={darcula}>
                  {code1}
                </SyntaxHighlighter>
                <li>
                  If it is variable, name of variable is written without
                  quotation marks.
                </li>
                <SyntaxHighlighter language="c" style={darcula}>
                  {code0}
                </SyntaxHighlighter>
              </p>
              <br />
              <p>
                <br />

                <div style={containerStyle}>
                  <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                    <b>output.c</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="c" style={darcula}>
                      {code2}
                    </SyntaxHighlighter>
                  </div>
                </div>
              </p>
              <br />
              <p style={{ backgroundColor: "#FBEEAC", padding: "15px" }}>
                <b>Note : </b> Do not forget to put ; at the end
              </p>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Adding line breaks:
              </h2>
              <div class="ui clearing divider"></div>
              <b>\n : </b> it forces the cursor to change its position to the
              beginning of the next line on the screen. This results in a new
              line. <br />
              <br />
              <div style={containerStyle}>
                <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                  <b>output.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code3}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Different Escape Sequences:
              </h2>
              <div class="ui clearing divider"></div>
              <table class="ui celled table">
                <thead>
                  <tr>
                    <th>Escape Sequence</th>
                    <th>Description</th>
                    <th>Code</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>\n</td>
                    <td>New line</td>
                    <td>
                      <SyntaxHighlighter language="c" style={darcula}>
                        {code5}
                      </SyntaxHighlighter>
                    </td>
                  </tr>
                  <tr>
                    <td>\t</td>
                    <td>Tab - prints four spaces</td>
                    <td>
                      <SyntaxHighlighter language="c" style={darcula}>
                        {code6}
                      </SyntaxHighlighter>
                    </td>
                  </tr>
                  <tr>
                    <td>\a</td>
                    <td>Beep sound</td>
                    <td>
                      <SyntaxHighlighter language="c" style={darcula}>
                        {code7}
                      </SyntaxHighlighter>
                    </td>
                  </tr>
                  <tr>
                    <td>\b</td>
                    <td>Backspace</td>
                    <td>
                      <SyntaxHighlighter language="c" style={darcula}>
                        {code8}
                      </SyntaxHighlighter>
                    </td>
                  </tr>
                  <tr>
                    <td>\f</td>
                    <td>Form feed</td>
                    <td>
                      <SyntaxHighlighter language="c" style={darcula}>
                        {code9}
                      </SyntaxHighlighter>
                    </td>
                  </tr>
                  <tr>
                    <td>\r</td>
                    <td>Carriage return</td>
                    <td>
                      <SyntaxHighlighter language="c" style={darcula}>
                        {code10}
                      </SyntaxHighlighter>
                    </td>
                  </tr>
                  <tr>
                    <td>\\</td>
                    <td>It adds backslash</td>
                    <td>
                      <SyntaxHighlighter language="c" style={darcula}>
                        {code11}
                      </SyntaxHighlighter>
                    </td>
                  </tr>
                  <tr>
                    <td>\'</td>
                    <td>Single quote</td>
                    <td>
                      <SyntaxHighlighter language="c" style={darcula}>
                        {code12}
                      </SyntaxHighlighter>
                    </td>
                  </tr>
                  <tr>
                    <td>\?</td>
                    <td>Question mark</td>
                    <td>
                      <SyntaxHighlighter language="c" style={darcula}>
                        {code13}
                      </SyntaxHighlighter>
                    </td>
                  </tr>
                  <tr>
                    <td>\0</td>
                    <td>Null character</td>
                    <td>
                      <SyntaxHighlighter language="c" style={darcula}>
                        {code14}
                      </SyntaxHighlighter>
                    </td>
                  </tr>
                </tbody>
              </table>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Printing Numbers:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                If we want to print numbers in C, we store them in variables
                then print variables on screen using %d (for integers). We will
                discuss about this in detail in variable section. Here is a
                brief example.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                  <b>output.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code15}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <p style={{ backgroundColor: "#FBEEAC", padding: "15px" }}>
                If we write numbers in double quotes, these are not treated as
                numbers. Everything inside double quotation marks is a string
                literal which is text.
              </p>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Printing Different Types of Variables:
              </h2>
              <div class="ui clearing divider"></div>
              <div style={containerStyle}>
                <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                  <b>output.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code16}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <span>
                Printing data stores in variables is not our concern for now.
                This is just for overview. You do not need to understand it
                here. Just go throught it once.
              </span>
            </div>
            <br />
            <Link to="/C/syntax">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/C/comments">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default CC4;
