import { useState, useEffect } from "react";
import Sidebar from "./SideBar";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
// import "./Quiz.css";
import "../../Components/Chapters/Chapters.css";
import Modal from "react-modal";

const PQuiz20 = () => {
  const [answers, setAnswers] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const handleQuizSubmit = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const questions = [
    {
        id: 1,
        question: "  Which control statement in Python is used to pass all iterations without executing any code:",
        options: ["Pass", "Continue", "Break", "None of these"],
        correctAnswer: "Pass",
      },
      {
        id: 2,
        question: "  What is the purpose of the \"pass\" statement in Python:",
        options: ["To break out of a loop", "To skip the current iteration", "To execute a specific block of code", "To pass arguments to a function"],
        correctAnswer: "To skip the current iteration",
      },
      {
        id: 3,
        question: "  Which control statement is used to skip the current iteration and continue with the next iteration in a loop:",
        options: ["Continue", "Pass", "Break", "Skip"],
        correctAnswer: "Continue",
      },
      {
        id: 4,
        question: "  In a \"for\" loop, when is the \"continue\" statement typically used:",
        options: ["To terminate the loop", "To execute a specific block of code", "To pass all iterations", "To skip the current iteration and move to the next one"],
        correctAnswer: "To skip the current iteration and move to the next one",
      },
      {
        id: 5,
        question: "  What does the \"break\" statement do in a loop:",
        options: ["Skips all iterations after a specified condition is met", "Ends the program", "Executes a specific block of code", "Skips the current iteration"],
        correctAnswer: "Skips all iterations after a specified condition is met",
      },
      {
        id: 6,
        question: "  Which of the following is NOT a control statement in Python:",
        options: ["Pass", "Continue", "Break", "Stop"],
        correctAnswer: "Stop",
      },
      {
        id: 7,
        question: "  In which type of loop does the \"break\" statement skip all iterations after a specified condition is met:",
        options: ["While loop", "For loop", "Nested Loop", "There is no Loop"],
        correctAnswer: "While loop",
      },
      {
        id: 8,
        question: "  What happens when the \"continue\" statement is executed in a loop:",
        options: ["The program terminates", "It skips the current iteration and moves to the next one", "It executes a specific block of code", "The loop starts over from the beginning"],
        correctAnswer: "It skips the current iteration and moves to the next one",
      }
  ];
  const handleAnswerChange = (questionId, selectedAnswer) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: selectedAnswer,
    }));
  };

  const calculateScore = () => {
    let correctCount = 0;
    let incorrectCount = 0;

    questions.forEach((question) => {
      const userAnswer = answers[question.id];
      if (userAnswer === question.correctAnswer) {
        correctCount++;
      } else {
        incorrectCount++;
      }
    });

    return { correctCount, incorrectCount };
  };

  const { correctCount, incorrectCount } = calculateScore();
  return (
    <>
      <Sidebar>
        <div class="container">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/quizes/python_quiz19">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                  }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/quizes/python_quiz21">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              </h2>
              <h1 class="start content ui left floated header">
                Control Statements
              </h1>
              <div className="ui clearing divider"></div>
            

            <div class="main-body">
              <h2 class="ui left floated header">
                Quiz # 20
              </h2>
              <span class="tut-btn"><Link to="/python/control_statements">
                  <button class="ui secondary button">Read Tutorial</button>
                </Link></span>
              <div class="ui clearing divider"></div>
              <div>
                {questions.map((question, i) => (
                  <div key={question.id}>
                    <div class="con">
                    <div class="que">Q{i+1}:<span class="one-per-left-margin">{question.question}</span></div>
                      {question.options.map((option) => (
                        <label class="label" key={option}>
                          <input
                            type="radio"
                            name={`question_${question.id}`}
                            value={option}
                            checked={answers[question.id] === option}
                            onChange={(e) =>
                              handleAnswerChange(question.id, e.target.value)
                            }
                          />
                          <span className="one-per-left-margin">{option}</span>
                          <br />
                        </label>
                      ))}
                    </div>
                    <hr />
                  </div>
                ))}<br/>
                <center><button class="ui primary button" onClick={handleQuizSubmit}>Submit Quiz</button></center>

                {/* Modal for displaying results */}
                <Modal class="modal" isOpen={isModalOpen} onRequestClose={closeModal}>
                  <b style={{fontSize:'20px'}}>Quiz Results</b>
                  <b><p>Your score is {correctCount}/{questions.length} </p></b>
                  {questions.map((question,i) => (
                    <div key={question.id}>
                      <p>Q{i+1}: {question.question}</p>
                      <p><b>Correct Answer:</b> {question.correctAnswer}</p>
                      <p className={`${question.correctAnswer}` === `${answers[question.id]}` ? "correct" : "wrong"}>
                        <b>Your Answer:</b> {answers[question.id] || "Not answered"}
                      </p>
                      <hr />
                    </div>
                  ))}
                  <button class="ui secondary button" onClick={closeModal}>Close</button><br /><br />
                </Modal>
              </div>

              <br />
            </div>
            <Link to="/quizes/python_quiz19">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                  }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/quizes/python_quiz21">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
            <br />
          </div>
        </div>
      </Sidebar>

      <div class="sideBarBody">
        <Footer />
      </div>
    </>
  );
};
export default PQuiz20;
