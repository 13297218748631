import { useRef, useEffect } from "react";
import "./Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Q16 from "../Questions/Q16";
import Sidebar from "../SideBar/Sidebar";
import Footer from "../Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const Ch16 = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  const divRef = useRef(null);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor:'#D8D9DA'
  };
  const code1 = `struct nameOfStruct {
    //data members or member functions
};`;
  const code2 = `struct std{
    string name = "John";
    string regNo = "21-ComputerSceince";
    int marks = "90";
    float per = "90.00";
};`;
  const code3 = `#include<iostream>
using namespace std;
struct std{
    string name = "John";
    string regNo = "21-ComputerSceince";
    int marks = "90";
    float per = "90.00";
};

int main(){
    cout<<"I have constructed a struct."<<endl;
    return 0;
}`;
  const code4 = `#include<iostream>
using namespace std;
struct std{
    string name = "John";
    string regNo = "21-ComputerSceince";
    int marks = "90";
    float per = "90.00";
};

int main(){
    struct std std1; 
    //Accessing member of struct
    cout<<std1.name<<endl;
    cout<<std1.regNo<<endl;
    cout<<std1.marks<<endl;
    cout<<std1.per<<endl;
    return 0;
}`;
  const code4_ = `#include<iostream>
using namespace std;
struct std{
    string name = "John";
    string regNo = "21-ComputerSceince";
    int marks = "90";
    float per = "90.00";
};

int main(){
    struct std std1; 
    
    std1.name = "Smith"
    cout<<std1.name<<endl;
    return 0;
}`;
  const code5 = `#include<iostream>
using namespace std;
union opt{
    int a;
    int b;
    int c;
};

int main(){
    union opt options; 
    //Accessing member of struct
    options.a = 10;
    cout<<options.a<<endl;
}`;
  const code6 = `union std{
    int marks;//4bytes
    float per;//4bytes
    char grade;//1byte
};`;
  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/Cpp/arrays">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/Cpp/pointers">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Structures:
              </h2>
              <div class="ui clearing divider"></div>
              <p>
                <ul className="ui unordered list">
                  <li>
                    Structures / struct is a user-defined data type that enables
                    us to organize and group together variables of the same or
                    different data types under a single unit for the purpose of
                    creating composite data structures.
                  </li>
                  <li>
                    We studied in our pervious tutorial about arrays. They hold
                    data with the same data type.
                  </li>
                </ul>
              </p>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Why do we need struct?
              </h2>
              <div class="ui clearing divider"></div>
              <span>
              What if we need to store more than one variables with same or
              different data types? Store name, registration number and marks of
              students? In arrays, we store only marks of students. But if want
              to store name and registration number along with marks, we use
              structures or struct. And variables in structures are called
              members of that particular struct.
              </span>
              
              
                <SyntaxHighlighter language="cpp" style={darcula}>
                  {code2}
                </SyntaxHighlighter>
              
              <br />
              <p style={{ backgroundColor: "#FBEEAC", padding: "10px" }}>
                <b>Note : </b>We declare struct outside the main function.
              </p>
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>struct.cpp</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="cpp" style={darcula}>
                    {code3}
                  </SyntaxHighlighter>
                </div>
              </div><br />
              <p style={{ backgroundColor: "#FBEEAC", padding: "10px" }}>
                <ul className="ui unordered list">
                  <li>
                    <b>Arrays : </b>Collection of data values with same data
                    type.{" "}
                  </li>
                  <li>
                    <b>Struct : </b>Collection of data values with same or different
                    data type.
                  </li>
                </ul>
              </p>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Accessing members of struct:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
              We have to create an instance of struct inside main function and
              then using "." operator, we can access members of struct.</span> <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>struct.cpp</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="cpp" style={darcula}>
                    {code4}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Modifying members of struct:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
              We can reassign data values to our struct members.</span> <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>struct.cpp</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="cpp" style={darcula}>
                    {code4_}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />{" "}
              <p style={{ backgroundColor: "#FBEEAC", padding: "10px" }}>
                Actually structures make your code more readable and it makes
                code easy to manage.
              </p>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Size of struct:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                <ul className="ui unordered list">
                  <li>
                    Size of struct depends upon the type of variable and number
                    of variables, it is storing.
                  </li>
                  <li>
                    If struct has 1 int (4 bytes), 1 char(1 byte) and 1 float(4
                    bytes), its size would be 9 bytes. You can check it by using
                    sizeof() operator.
                  </li>
                </ul>
              </span>
              <br />
              
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Unions:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
              Syntax of union is same as struct. We use union when we need only
              one data member at a time. For example, if we have three books, we
              can read one book at a time. We can use one data member of union
              at a time.</span> <br />
              <br />
              <p style={{ backgroundColor: "#FBEEAC", padding: "15px" }}>
                <b>Note : </b>You can not declare functions inside a union.
              </p>
              <br /> We can declare union and use its one data member at a time.
              Unions provide us memory optimization. <br />
              If we declare a union and a struct with same data members, memory
              taken by union will be less.
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>union.cpp</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="cpp" style={darcula}>
                    {code5}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Size of Union:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                In this case, size of union will be 4 bytes.
              </span>
              
                <SyntaxHighlighter language="cpp" style={darcula}>
                  {code6}
                </SyntaxHighlighter>
              
             
              <br />
              <p style={{ backgroundColor: "#FBEEAC", padding: "10px" }}>
                <b>Points : </b>
                Do not forget to put ";" after declaring struct or union.{" "}
              </p>
              
            </div><br />
            <Link to="/Cpp/arrays">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/Cpp/pointers">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default Ch16;
