import { useRef, useEffect } from "react";
// import "./Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Sidebar from "../CSideBar";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const CC21 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };
  const code1 = `int main() {
  printf("Hi there!");
  return 0;
}`;
  const code2 = `return_type funcName ( params ) {...}`;
  const code3 = `void print(){
  printf("Hi there!");
}`;
  const code4 = `void print() {
  printf("Hi there!");
}
  
int main() {
  print(); 
  return 0;
}`;
  const code5 = `int print() {
  int num1 = 3, num2 = 2;
  return num1 + num2;
}
  
int main() {
  int num = print();
  printf("%d", num); 
  return 0;
}`;
  const code6 = `int print(int a, int b) {
  return a + b;
}
  
int main() {
  int num = print(2, 4);
  printf("%d", num); 
  return 0;
}`;
  const code7 = `#include<stdio.h>

int sum(int *a, int *b){
  int sum = *a+*b;
  return sum;
}
int main(){
  int num1=2, num2=3;
  //calling function with arguments 2 and 3.
  int add = sum( &num1, &num2 );
  printf("%d", add);
  return 0;
}`;
  const code8 = `#include<stdio.h>

void printMarks(int marks[5]){
  for(int i=0; i<5; i++){
    printf("%d", marks[i]);
  }
}
int main(){
  int marks[5] = {10,20,30,40,50};
  printMarks(marks);
  return 0;
}`;
  const code9 = `#include <stdio.h>

// Recursive function to calculate factorial
int factorial(int n) {
  // Base case: if n is 0 or 1, factorial is 1
  if (n == 0 || n == 1) {
    return 1;
  } 
  else {
    // Recursive case: n! = n * (n-1)!
    return n * factorial(n - 1);
    }
}

int main() {
  int num;
  printf("Enter a positive integer : ");
  scanf("%d", &num);

  if (num < 0) {
      printf("Number is negative.\\n")
  } else {
      int result = factorial(num);
      printf("Factorial of %d is %d", num, result);
  }

  return 0;
}`;
  const code10 = `#include <stdio.h>

int sum(int a, int b){
  return a+b;
}
float sum(float a, float b, int c){
  return a+b;
}
int main(){
  //funtion with data type int is invoked
  int intSum = sum(5, 10);  
  printf("Integer sum = %d", intSum);
  //funtion with data type float is invoked
  float floatSum = sum(5.0, 10.0, 20);  
  printf("Float sum = %f", floatSum);
  //sum has all three features of overloading
}`;
  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/C/pointers">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/C/struct">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Functions:
              </h2>
              <div class="ui clearing divider"></div>
              <p>
                <ul className="ui unordered list">
                  <li>
                  In the C programming language, functions are blocks of code that achieve specific tasks or operations. Functions allow you to break down a program into smaller, more manageable parts, making the code more modular and easier to maintain.
                  </li>
                  <li>
                  When we need to do that task, we call it in main function because we know that in our C++ code, prosecution starts from main function and ends when main function ends.
                  </li>
                </ul>
              </p>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Main Function:
              </h2>
              <div class="ui clearing divider"></div>
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>functions.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code1}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Declaring a Function:
              </h2>
              <div class="ui clearing divider"></div>
              <div ref={divRef}>
                <SyntaxHighlighter language="c" style={darcula}>
                  {code2}
                </SyntaxHighlighter>
              </div>
              <br />
              <h1>In this example;</h1>
              <span>
                <ul className="ui unordered list">
                  <li>
                  returnType shows the data type of value, function is
                    returning.
                  </li>
                  <li>funcName is the name of function.</li>
                  <li>(params) are the parameters which we pass to function</li>
                  <li>Body of the function is written inside curly braces.</li>
                </ul>
              </span>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Defining a void Function:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
              Void functions do not return anything. If we want to do calculation or print the results etc, we use function which do not return anything.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>functions.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code3}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Calling a void Function inside main function:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
              To see output of a function, we need to call it in our main function. To call a function in main function, we write the name of the function, followed by parenthesis.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>functions.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code4}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Functions which return a value:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                We can have functions which return some value. We can store this
                value in a variable or we can directly print it on screen.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>functions.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code5}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Function Parameters and Arguments:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                <ul className="ui unordered list">
                  <li>
                    Function parameters are variables, which we pass to function
                    to perform an operation.
                  </li>
                  <li>
                    Arguments are the data values which we pass in parenthesis
                    when we call a function.
                  </li>
                </ul>
              </span>
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>functions.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code6}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Passing Arguments by Reference:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                Passing agruments by reference means that we will not pass
                variable's values. We will pass reference to variabes. When we
                want top pass values by reference, we use pointers.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>functions.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code7}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />

              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Passing Arrays as Arguments:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                We can also pass an array as an argument to a function.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>functions.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code8}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />

              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Recursion:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
              Recursion means calling the function within the same function until the provided condition is true. This is done to break the one complicated problem into many simpler problems and then solving the simpler problems.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>functions.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code9}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />

              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Function Overloading:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                Function overloading allows us to define different functions
                with the same name but,
                <br />
                <ul className="ui unordered list">
                  <li>
                    {" "}
                    Overloading functions must have parameters with different
                    data types, OR
                  </li>
                  <li>Number of parameters must be different, OR</li>
                  <li>
                    If number of parameters is same, their sequence must be
                    different.
                  </li>
                </ul>
                Return type of overloading functions may or may not be
                different. <br />
                Funtions with atleast one feature given above or having all the
                three features are said to be overloaded. When function is
                called, compiler determines which version of function to invoke.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>functions.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code10}
                  </SyntaxHighlighter>
                </div>
              </div>
             
            </div>
            <br />
            <Link to="/C/pointers">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/C/struct">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default CC21;
