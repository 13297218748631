import { useRef, useEffect } from "react";
// import "./Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Sidebar from "../CSideBar";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const CC22 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };
  const code1 = `struct Employee {
  char name[50];
  int salary;
  int id;
};
`;

  const code2 = `#include <stdio.h>

struct Employee {
  char name[50];
  int salary;
  int id;
};
int main(){
  struct Employee e1 = {"John", 1000, 7};
            
  printf("%d\\n", e1.id);
  printf("%s\\n", e1.name);
  printf("%d\\n", e1.salary);
}`;

  const code3 = `#include <stdio.h>

struct Employee {
  char name[50];
  int salary;
  int id;
};
int main(){
  // Instance 1
  struct Employee e1 = {"John", 1000, 7};
          
  printf("%d\\n", e1.id);
  printf("%s\\n", e1.name);
  printf("%d\\n", e1.salary);

  // Instance 2
  struct Employee e2 = {"Smith", 1500, 6};
          
  printf("%d\\n", e2.id);
  printf("%s\\n", e2.name);
  printf("%d\\n", e2.salary);
}`;
  const code4 = `#include <stdio.h>

struct Employee {
  char name[50];
  int salary;
  int id;
};
int main(){
  struct Employee e1 = {"John", 1000, 7};
        
  e1.salary = 2000;
  //Can not directly assign 
  //value to a string variable.
  strcpy(e1.name, "Sarah");
  printf("%d\\n", e1.id);
  printf("%s\\n", e1.name);
  printf("%d\\n", e1.salary);
}`;
  const code5 = `#include <stdio.h>

// Define a union named Data
union Data {
  int i;
  float f;
  char str[20];
};

int main() {
  // Declare a union variable
  union Data data;

  // Assign and access union members
  data.i = 42;
  printf("Integer value: %d\\n", data.i);

  data.f = 3.14;
  printf("Float value: %.2f\\n", data.f);

  strcpy(data.str, "Hello, Union!");
  printf("String value: %s\\n", data.str);

  /* The union shares the same 
  memory location, so accessing 
  one member affects others*/
  printf("After string assignment:\\n");
  printf("Integer value: %d\\n", data.i);
  printf("Float value: %.2f\\n", data.f);

  return 0;
}`;
  const code6 = `union std{
  int marks;//4bytes
  float per;//4bytes
  char grade;//1byte
};`;
  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/C/functions">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/C/file_handling">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Structure:
              </h2>
              <div class="ui clearing divider"></div>
              <p>
                <ul className="ui unordered list">
                  <li>
                    Structure is a user-defined data type which allows us to
                    group together variables of different data types under a
                    single unit.
                  </li>
                  <li>
                    We define a structure by using the struct keyword followed
                    by the name of the structure and a pair of curly braces
                    containing the member variables and member functions and a semi-colon at the end.
                  </li>
                  <li>
                    Variables in a struct are called the members of the struct.
                  </li>
                </ul>
              </p>
              <br />
              <p style={{ backgroundColor: "#FBEEAC", padding: "10px" }}>
                <b>Note : </b>We declare the structure outside our main function.
              </p>
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>struct.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code1}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <p style={{ backgroundColor: "#FBEEAC", padding: "10px" }}>
                <ul className="ui unordered list">
                  <li>
                    <b>Arrays : </b>Collection of data values with same data
                    type.{" "}
                  </li>
                  <li>
                    <b>Struct : </b>Collection of variables with same or
                    different data type.
                  </li>
                </ul>
              </p>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Accessiing members of Struct:
              </h2>
              <div class="ui clearing divider"></div>
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>struct.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code2}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Multiple Instances of Struct:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                We can make multiple instances of struct. A structure can have multiple 
                instances like any other data type.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>struct.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code3}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Changing values of Structure:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                Like other assigned values, we can also reassign the value of
                struct.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>struct.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code4}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Unions:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                <ul className="ui unordered list">
                  <li>
                    A union allows us to store data of different types in the
                    same memory location. Unlike structures, which allocate
                    memory for all of their members separately, unions allocate
                    memory only for the largest member, and all members share
                    the same memory location.
                  </li>
                  <li>We use only one member of structure at a time.</li>
                </ul>
              </span>
              <br />
              <p style={{ backgroundColor: "#FBEEAC", padding: "15px" }}>
                <b>Note: </b>We can not declare functions inside a union.{" "}
                <br />
                Union is a composite data type that
              </p>
              <br /> We can declare union and use its one data member at a
              time. Unions provide us memory optimization. <br />
              If we declare a union and a struct with same data members, memory
              taken by union will be less.
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>struct.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code5}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Size of Union:
              </h2>
              <div class="ui clearing divider"></div>
              <span>In this case, size of union will be 4 bytes.</span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>struct.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code6}
                  </SyntaxHighlighter>
                </div>
              </div>
            </div>
            <br />
            <Link to="/C/functions">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/C/file_handling">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default CC22;
