import { useRef, useEffect } from "react";
import "../../Components/Chapters/Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "../PythonSideBar";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

const PythonC21 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor:'#D8D9DA'
  };

  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/python/functions">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/python/classes_objects">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Object Oriented Programming
              </h2>
              <div class="ui clearing divider"></div>
              <p>
                Object Oriented Programming is a programming paradigm in which
                we work around the objects. Python is an Object Oriented Programming Language that supports the creation 
                of classes and objects. <br /><br />
              </p>
              <span>
                In OOP, we create objects and objects have: <br />
                <ul class="ui unordered list">
                  <li>Properties</li>
                  <li>Behaviours</li>
                </ul>
                which are defined inside the class. <br /> <br />
                <b>Object</b> is the instance of the class and has properties defined inside the class. <br /> <b>Class</b> is a
                template which contains attributes and behaviours of objects.{" "}
                <br /><br />
                For example, we have class named animals. The objects will be
                lion, monkey, zebra etc. objects have attributes, lion is brave.
                and behavior, monkey can jump. These attributes (properties) and behaviours
                are defined inside the class.
              </span>
              <br />
              <br />
              <div class="ui segment">
                <div class="ui two column very relaxed grid">
                  <div class="column">
                    <b>Class</b>
                    <div class="ui divider"></div>
                    <p>Animals</p>
                    <b>Properties </b>(data members):
                    <p>brave, intelligent, beautiful</p>
                    <b>Behaviors </b>(member functions):
                    <p>walk(), eat(), sleep()</p>
                  </div>
                  <div class="column">
                    <b>Objects</b>
                    <div class="ui divider"></div>
                    <p>Lion</p>
                    <p>Elephant</p>
                    <p>Donkey</p>
                    <p>Zebra</p>
                  </div>
                </div>
                <div class="ui vertical divider"></div>
              </div>
              <br />
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
              A simple explanation:
              </h2>
              <div class="ui clearing divider"></div>
                            <span>
                An object created as an instance of a class will have all the
                properties and behaviors of that class. For example, if animal
                class has properties brave, intelligent, magnificiant. And we
                create two objects donkey and lion. Then donkey and lion both
                will inherit all the properties. If lion is magnificiant, the
                donkey will also be magnificiant if it is the instance of the
                same class.
              </span>
              <br />
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
              Why OOP?
              </h2>
              <div class="ui clearing divider"></div>
              <ul class="ui unordered list">
                <li>It makes your code easier to read.</li>
                <li>Makes your code 'DRY' - Do not Repeat Yourself.</li>
                <li>Easier to find errors</li>
                <li>Code looks beautiful and structured.</li>
                <li>Code will be reusable.</li>
                <li>Less time consumption.</li>
              </ul>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
              Road Map to OOP:
              </h2>
              <div class="ui clearing divider"></div>
              <b>1. Classes and Objects:</b>
              <br />
              Learn about concept of classes and objects and how do they interact.{" "}
              <br /><br />
              <b>2. Four pillars of OOP:</b>
              <br />
              Study the four pillars of OOP.
              <ul class="ui unordered list">
                <li>Encapsulation</li>
                <li>Inheritance</li>
                <li>Polymorphism</li>
                <li>Abstraction</li>
              </ul>
              <b>3. Class Relationships:</b>
              <br />
              Study different types of class relationships, such as association,
              aggregation, and composition. <br /><br />
              <b>4. Study Design Patterns:</b>
              <br />
              Study about the different design patterns.
              <br /><br />
              <b>5. Error Handling and Debugging:</b>
              <br />
              Learn how to handle exceptions in OOP. <br /><br />
              <b>6. Software Development Principles:</b>
              <br />
              Learn about software development principles. <br /><br />
              
              Now move ahead to advanced OOP concepts and start developing softwares.
              
            </div><br />

            <Link to="/python/functions">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/python/classes_objects">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default PythonC21;
