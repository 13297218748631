import { useRef, useEffect } from "react";
import "../../Components/Chapters/Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "../PythonSideBar";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

const PythonC5 = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  const divRef = useRef(null);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };

  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor:'#D8D9DA'
  };
  const pc5code1 = `#I am writing a comment in Python. 
print("Hello World!")`;
  const pc5code2 = `""" I am writing a comment in Python. 
This will print Hello World. """
print("Hello World!")`;
const pc5code3 = `#I am writing a comment in Python. 
#This will print Hello World.
print("Hello World!")`;
const pc5code4 = `print("Hello World!") 
#I am writing a comment in Python. `
  return (
    <div class="testing">
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/python/output">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/python/variables">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Comments:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                Comments are used to explain your code and they have nothing to
                do with the interpreter. Interpreter ignores the comments and do
                not consider them the part of your code. Comments just make your
                code more readable.
              </span>
              <br /><br />
              There are two types of comments used in Python:
              <ul class="ui unordered list">
                <li>Single Lined Comments</li>
                <li>Multi Lined Comments</li>
              </ul>
              <br />
              
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
              Single Lined Comments:
              </h2>
              <div className="ui clearing divider"></div>
         
              
              <span>
                If we want to comment a single line in our Python code, we use single line comments.
                To comment a single line in our code, 
                
                put a hash symbol <b>"#"</b> before the line which you want to comment.</span><br /><br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>comments.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc5code1}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                Or;
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>comments.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc5code4}
                    </SyntaxHighlighter>
                  </div>
                </div><br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
              Multi Lined Comments:
              </h2>
              <div className="ui clearing divider"></div>
                <span>
                If we want to comment multiple lines in our Python code, we use multi line comments.
                To comment multiple lines in our code, 
                
                put three quotation marks <b>(""")</b> at the start the start of starting line 
                and three quotation marks <b>(""")</b> at the end of ending line.
                  </span><br /><br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>comments.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc5code2}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <span>We can also comment multiple lines of code by placing hash symbol <b>"#"</b> 
                at the start of each line.
                </span><br /><br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>comments.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc5code3}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
              Why do we need comments?
              </h2>
              <div className="ui clearing divider"></div>
              <span>
                <ul className="ui unordered list">
                  <li>Comments improve clarity and readability of our code. It makes our code understandable.</li>
                  <li>By using comments, we can write documentation of our Python code.</li>
                  <li>We can use comments to temporarily disable a piece of code which we do not want to run at a specified time.
                    If we want to run that code in future, we can uncomment it.
                  </li>
                  <li>By using comments, we can communicate with our team members. For example, if a team is working 
                    on a project and each member is performing a certain function. He will write a comment on his part to 
                    explain the other members that what is happening here.
                  </li>
                </ul>
              </span>

              
            </div><br />
            <Link to="/python/output">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/python/variables">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </div>
  );
};
export default PythonC5;
