import React, { useState, useRef, useEffect } from "react";
import Sidebar from "./SideBar";
import "../../Components/SideBar/App.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const CodeAccordion = ({
  questions,
  code,
  toggleAccordion,
  changeCodeLanguage,
}) => {
  const divRef = useRef(null);
  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };
  
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };

  return (
    <div>
      {questions.map((item, index) => (
        <div key={index} className="border p-2 rounded-md">
          <div className="flex justify-between">
            <span className="text-lg font-semibold">{item.question}</span>
            <button
              className="bg-gray-200 px-2 py-1 rounded-md"
              onClick={() => toggleAccordion(index)}
            >
              {item.isOpen ? "-" : "+"}
            </button>
          </div>
          <div className={`mt-2 ${item.isOpen ? "block" : "hidden"}`}>
            <div className="mt-2 flex space-x-4">
              {Object.keys(code).map((language) => (
                <button
                  key={language}
                  onClick={() => changeCodeLanguage(index, language)}
                  className={`${
                    item.codeLanguage === language
                      ? "bg-blue-500 hover:bg-blue-600 text-white"
                      : "bg-gray-200 hover:bg-gray-300 text-gray-700"
                  } px-3 py-2 rounded-md transition duration-300 ease-in-out transform hover:scale-105`}
                >
                  {language}
                </button>
              ))}
            </div>
            <p className="text-gray-800">
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>output.cpp</b>
                  <i
                    className="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter
                    language={`${item.codeLanguage}`}
                    style={darcula}
                  >
                    {code[item.codeLanguage]}
                  </SyntaxHighlighter>
                </div>
              </div>
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

const Question5 = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  const code = {
    c: `// C code\n#include <stdio.h>\n#include <string.h>\n\nint main() {\n\tchar str[100];\n\tprintf("Enter a string: ");\n\tscanf("%s", str);\n\n\tint length = 0;\n\twhile (str[length] != '\0') {\n\t\tlength++;\n\t}\n\n\tfor (int i = 0; i < length / 2; i++) {\n\t\tchar temp = str[i];\n\t\tstr[i] = str[length - i - 1];\n\t\tstr[length - i - 1] = temp;\n\t}\n\n\tprintf("Reversed string: %s\\n", str);\n\n\treturn 0;\n}`,
    cpp: `// C++ code\n#include <iostream>\n#include <string>\nusing namespace std;\n\nint main() {\n\tstring str;\n\tcout << "Enter a string: ";\n\tcin >> str;\n\n\tint length = str.length();\n\n\tfor (int i = 0; i < length / 2; i++) {\n\t\tchar temp = str[i];\n\t\tstr[i] = str[length - i - 1];\n\t\tstr[length - i - 1] = temp;\n\t}\n\n\tcout << "Reversed string: " << str << endl;\n\n\treturn 0;\n}`,
    java: `// Java code\nimport java.util.Scanner;\n\npublic class ReverseStringInPlace {\n\tpublic static void main(String[] args) {\n\t\tScanner scanner = new Scanner(System.in);\n\t\tSystem.out.print("Enter a string: ");\n\t\tString str = scanner.next();\n\n\t\tchar[] charArray = str.toCharArray();\n\t\tint length = charArray.length;\n\n\t\tfor (int i = 0; i < length / 2; i++) {\n\t\t\tchar temp = charArray[i];\n\t\t\tcharArray[i] = charArray[length - i - 1];\n\t\t\tcharArray[length - i - 1] = temp;\n\t\t}\n\n\t\tString reversedString = new String(charArray);\n\n\t\tSystem.out.println("Reversed string: " + reversedString);\n\n\t\tscanner.close();\n\t}\n}`,
    python: `# Python code\ninput_string = input("Enter a string: ")\nchar_list = list(input_string)\n\nlength = len(char_list)\n\nfor i in range(length // 2):\n\tchar_list[i], char_list[length - i - 1] = char_list[length - i - 1], char_list[i]\n\nreversed_string = ''.join(char_list)\nprint("Reversed string:", reversed_string)`,
  };

  const code1 = {
    c: `// C code\n#include <stdio.h>\n\nint main() {\n\tchar str[100];\n\tprintf("Enter a string: ");\n\tscanf("%s", str);\n\n\tint length = 0;\n\twhile (str[length] != '\0') {\n\t\tlength++;\n\t}\n\n\tprintf("Length of the string: %d\\n", length);\n\n\treturn 0;\n}`,
    cpp: `// C++ code\n#include <iostream>\nusing namespace std;\n\nint main() {\n\tstring str;\n\tcout << "Enter a string: ";\n\tcin >> str;\n\n\tint length = 0;\n\tfor (int i = 0; str[i] != '\0'; i++) {\n\t\tlength++;\n\t}\n\n\tcout << "Length of the string: " << length << endl;\n\n\treturn 0;\n}`,
    java: `// Java code\nimport java.util.Scanner;\n\npublic class\n\tStringLengthWithoutLibraryFunction {\n\tpublic static void main(String[] args) {\n\t\tScanner scanner = new Scanner(System.in);\n\t\tSystem.out.print("Enter a string: ");\n\t\tString str = scanner.next();\n\n\t\tint length = 0;\n\t\tchar[] charArray = str.toCharArray();\n\n\t\tfor (char c : charArray) {\n\t\t\tlength++;\n\t\t}\n\n\t\tSystem.out.println("Length of the string: " + length);\n\n\t\tscanner.close();\n\t}\n}`,
    python: `# Python code\ninput_string = input("Enter a string: ")\n\nlength = 0\nfor\n\tchar in input_string:\n\tlength += 1\n\nprint("Length of the string:", length)`,
  };

  const code2 = {
    c: `// C code\n#include <stdio.h>\n#include <string.h>\n#include <ctype.h>\n\nint main() {\n\tchar str[100];\n\tprintf("Enter a string: ");\n\tscanf("%s", str);\n\n\tint vowels = 0;\n\tint consonants = 0;\n\n\tfor (int i = 0; str[i] != '\0'; i++) {\n\t\tchar ch = tolower(str[i]);\n\n\t\tif (ch >= 'a' && ch <= 'z') {\n\t\t\tif (ch == 'a' || ch == 'e' || ch == 'i' || ch == 'o' || ch == 'u') {\n\t\t\t\tvowels++;\n\t\t\t} else {\n\t\t\t\tconsonants++;\n\t\t\t}\n\t\t}\n\t}\n\n\tprintf("Vowels: %d\\n", vowels);\n\tprintf("Consonants: %d\\n", consonants);\n\n\treturn 0;\n}`,
    cpp: `// C++ code\n#include <iostream>\n#include <string>\n#include <cctype>\nusing namespace std;\n\nint main() {\n\tstring str;\n\tcout << "Enter a string: ";\n\tcin >> str;\n\n\tint vowels = 0;\n\tint consonants = 0;\n\n\tfor (char ch : str) {\n\t\tch = tolower(ch);\n\n\t\tif (isalpha(ch)) {\n\t\t\tif (ch == 'a' || ch == 'e' || ch == 'i' || ch == 'o' || ch == 'u') {\n\t\t\t\tvowels++;\n\t\t\t} else {\n\t\t\t\tconsonants++;\n\t\t\t}\n\t\t}\n\t}\n\n\tcout << "Vowels: " << vowels << endl;\n\tcout << "Consonants: " << consonants << endl;\n\n\treturn 0;\n}`,
    java: `// Java code\nimport java.util.Scanner;\n\npublic class CountVowelsConsonants {\n\tpublic static void main(String[] args) {\n\t\tScanner scanner = new Scanner(System.in);\n\t\tSystem.out.print("Enter a string: ");\n\t\tString str = scanner.nextLine().toLowerCase();\n\n\t\tint vowels = 0;\n\t\tint consonants = 0;\n\n\t\tfor (char ch : str.toCharArray()) {\n\t\t\tif (Character.isLetter(ch)) {\n\t\t\t\tif (ch == 'a' || ch == 'e' || ch == 'i' || ch == 'o' || ch == 'u') {\n\t\t\t\t\tvowels++;\n\t\t\t\t} else {\n\t\t\t\t\tconsonants++;\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\n\t\tSystem.out.println("Vowels: " + vowels);\n\t\tSystem.out.println("Consonants: " + consonants);\n\n\t\tscanner.close();\n\t}\n}`,
    python: `# Python code\ninput_string = input("Enter a string: ").lower()\n\nvowels = 0\nconsonants = 0\n\nfor char in input_string:\n\tif char.isalpha():\n\t\tif char in "aeiou":\n\t\t\tvowels += 1\n\t\telse:\n\t\t\tconsonants += 1\n\nprint("Vowels:", vowels)\nprint("Consonants:", consonants)`,
  };
  const code3 = {
    c: `// C code\n#include <stdio.h>\n#include <string.h>\n#include <ctype.h>\n\nint main() {\n\tchar str1[100], str2[100];\n\tprintf("Enter the first string: ");\n\tscanf("%s", str1);\n\tprintf("Enter the second string: ");\n\tscanf("%s", str2);\n\n\tint len1 = strlen(str1);\n\tint len2 = strlen(str2);\n\n\tif (len1 != len2) {\n\t\tprintf("The strings are not anagrams.\\n");\n\t\treturn 0;\n\t}\n\n\tint count[256] = {0};\n\n\tfor (int i = 0; i < len1; i++) {\n\t\tcount[(int)tolower(str1[i])]++;\n\t\tcount[(int)tolower(str2[i])]--;\n\t}\n\n\tfor (int i = 0; i < 256; i++) {\n\t\tif (count[i] != 0) {\n\t\t\tprintf("The strings are not anagrams.\\n");\n\t\t\treturn 0;\n\t\t}\n\t}\n\n\tprintf("The strings are anagrams.\\n");\n\n\treturn 0;\n}`,
    cpp: `// C++ code
#include <iostream>
#include <string>
#include <cctype>
using namespace std;

int main() {
    string str1, str2;
    cout << "Enter the first string: ";
    cin >> str1;
    cout << "Enter the second string: ";
    cin >> str2;

    int len1 = str1.length();
    int len2 = str2.length();

    if (len1 != len2) {
        cout << "The strings are not anagrams." << endl;
        return 0;
    }

    int count[256] = {0};

    for (char c : str1) {
        count[(int)tolower(c)]++;
    }

    for (char c : str2) {
        count[(int)tolower(c)]--;
    }

    for (int i = 0; i < 256; i++) {
        if (count[i] != 0) {
            cout << "The strings are not anagrams." << endl;
            return 0;
        }
    }

    cout << "The strings are anagrams." << endl;

    return 0;
}`,
    java: `// Java code
import java.util.Scanner;

public class AnagramCheck {
        public static void main(String[] args) {
        Scanner scanner = new Scanner(System.in);
        System.out.print("Enter the first string: ");
        String str1 = scanner.next();
        System.out.print("Enter the second string: ");
        String str2 = scanner.next();
        scanner.close();
    
        str1 = str1.toLowerCase();
        str2 = str2.toLowerCase();
    
        int len1 = str1.length();
        int len2 = str2.length();
    
        if (len1 != len2) {
            System.out.println("The strings are not anagrams.");
            return;
        }
    
        int[] count = new int[256];
    
        for (int i = 0; i < len1; i++) {
            count[str1.charAt(i)]++;
            count[str2.charAt(i)]--;
        }
    
        for (int i = 0; i < 256; i++) {
            if (count[i] != 0) {
                System.out.println("The strings are not anagrams.");
                return;
            }
        }
    
        System.out.println("The strings are anagrams.");
    }
}`,
    python: `# Python code
str1 = input("Enter the first string: ").lower()
str2 = input("Enter the second string: ").lower()
    
len1 = len(str1)
len2 = len(str2)
    
if len1 != len2:
    print("The strings are not anagrams.")
else:
    count = [0] * 256
    
    for i in range(len1):
        count[ord(str1[i])] += 1
        count[ord(str2[i])] -= 1
    
    is_anagram = all(c == 0 for c in count)
    
    if is_anagram:
        print("The strings are anagrams.")
    else:
        print("The strings are not anagrams.")`,
  };

  const code4 = {
    c: `// C code
#include <stdio.h>
#include <string.h>
   
int main() {
    char str1[100], str2[100];
    printf("Enter the first string: ");
    scanf("%s", str1);
    printf("Enter the second string: ");
    scanf("%s", str2);
    
    strcat(str1, str2);
    
    printf("Concatenated string: %s\\n", str1);
    
    return 0;
}`,
    cpp: `// C++ code\n
#include <iostream>
#include <string>
using namespace std;

int main() {
    string str1, str2;
    cout << "Enter the first string: ";
    cin >> str1;
    cout << "Enter the second string: ";
    cin >> str2;
    
    str1 += str2;
    
    cout << "Concatenated string: " << str1 << endl;
    
    return 0;
}`,
    java: `// Java code
import java.util.Scanner;

public class ConcatenateStrings {
    public static void main(String[] args) {
        Scanner scanner = new Scanner(System.in);
        System.out.print("Enter the first string: ");
        String str1 = scanner.next();
        System.out.print("Enter the second string: ");
        String str2 = scanner.next();
        scanner.close();

        String concatenatedString = str1.concat(str2);

        System.out.println("Concatenated string: " + concatenatedString);
    }
}`,
    python: `# Python code
str1 = input("Enter the first string: ")
str2 = input("Enter the second string: ")

concatenated_string = str1 + str2

print("Concatenated string:", concatenated_string)`,
  };
  const code5 = {
    c: `// C code
#include <stdio.h>
#include <string.h>

int main() {
    char str[1000];
    printf("Enter a string: ");
    gets(str);
    
    char find[100], replace[100];
    printf("Enter the word to find: ");
    gets(find);
    printf("Enter the word to replace: ");
    gets(replace);

    int strLen = strlen(str);
    int findLen = strlen(find);
    int replaceLen = strlen(replace);

    char result[1000];
    int i, j, k = 0;

    for (i = 0; i < strLen; ) {
        int isSubstring = 1;

        for (j = 0; j < findLen; j++) {
            if (str[i + j] != find[j]) {
                isSubstring = 0;
                break;
            }
        }

        if (isSubstring) {
            for (j = 0; j < replaceLen; j++) {
                result[k++] = replace[j];
            }

            i += findLen;
        } else {
            result[k++] = str[i++];
        }
    }

    result[k] = '\0';
    
    printf("Replaced string: %s\\n", result);

    return 0;
}`,
    cpp: `// C++ code
#include <iostream>
#include <string>
using namespace std;

int main() {
    string str;
    cout << "Enter a string: ";
    getline(cin, str);

    string find, replace;
    cout << "Enter the word to find: ";
    cin >> find;
    cout << "Enter the word to replace: ";
    cin >> replace;

    size_t findLen = find.length();
    size_t replaceLen = replace.length();

    string result;
    size_t i = 0;

    while (i < str.length()) {
        if (str.substr(i, findLen) == find) {
            result += replace;
            i += findLen;
        } else {
            result += str[i];
            i++;
        }
    }

    cout << "Replaced string: " << result << endl;

    return 0;
}`,
    java: `// Java code
import java.util.Scanner;

public class FindAndReplace {
    public static void main(String[] args) {
        Scanner scanner = new Scanner(System.in);
        System.out.print("Enter a string: ");
        String str = scanner.nextLine();

        System.out.print("Enter the word to find: ");
        String find = scanner.next();

        System.out.print("Enter the word to replace: ");
        String replace = scanner.next();
        scanner.close();

        int strLen = str.length();
        int findLen = find.length();
        int replaceLen = replace.length();

        StringBuilder result = new StringBuilder();
        int i = 0;

        while (i < strLen) {
            if (str.regionMatches(i, find, 0, findLen)) {
                result.append(replace);
                i += findLen;
            } else {
                result.append(str.charAt(i));
                i++;
            }
        }

        System.out.println("Replaced string: " + result.toString());
    }
}`,
    python: `# Python code
input_string = input("Enter a string: ")
find_word = input("Enter the word to find: ")
replace_word = input("Enter the word to replace: ")

find_word_length = len(find_word)
output_string = ""
i = 0

while i < len(input_string):
    if input_string[i:i + find_word_length] == find_word:
        output_string += replace_word
        i += find_word_length
    else:
        output_string += input_string[i]
        i += 1

print("Replaced string:", output_string)`,
  };

  const code6 = {
    c: `// C code
#include <stdio.h>

int main() {
    char str[100];
    printf("Enter a string: ");
    gets(str);

    int i = 0;

    if (str[0] == '-' || str[0] == '+') {
        i = 1;
    }

    while (str[i] != '\0') {
        if (str[i] < '0' || str[i] > '9') {
            printf("The string is not a valid integer.\\n");
            return 0;
        }
        i++;
    }

    printf("The string is a valid integer.\\n");

    return 0;
}`,
    cpp: `// C++ code
#include <iostream>
#include <string>

int main() {
    string str;
    cout << "Enter a string: ";
    getline(cin, str);

    int i = 0;

    if (str[0] == '-' || str[0] == '+') {
        i = 1;
    }

    while (i < str.length()) {
        if (str[i] < '0' || str[i] > '9') {
            cout << "The string is not a valid integer." << endl;
            return 0;
        }
        i++;
    }

    cout << "The string is a valid integer." << endl;

    return 0;
}`,
    java: `// Java code
import java.util.Scanner;

public class ValidIntegerCheck {
    public static void main(String[] args) {
        Scanner scanner = new Scanner(System.in);
        System.out.print("Enter a string: ");
        String str = scanner.next();
        scanner.close();

        int i = 0;

        if (str.charAt(0) == '-' || str.charAt(0) == '+') {
            i = 1;
        }

        while (i < str.length()) {
            if (!Character.isDigit(str.charAt(i))) {
                System.out.println("The string is not a valid integer.");
                return;
            }
            i++;
        }

        System.out.println("The string is a valid integer.");
    }
}`,
    python: `# Python code
input_string = input("Enter a string: ")

if input_string:
    i = 0

    if input_string[0] == '-' or input_string[0] == '+':
        i = 1

    while i < len(input_string):
        if not input_string[i].isdigit():
            print("The string is not a valid integer.")
            break
        i += 1
    else:
        print("The string is a valid integer.")
else:
    print("The string is not a valid integer.")`,
  };

  const code7 = {
    c: `// C code
#include <stdio.h>

int main() {
    char str[100];
    char ch;
    int count = 0;

    printf("Enter a string: ");
    gets(str);

    printf("Enter a character to count: ");
    scanf(" %c", &ch);

    for (int i = 0; str[i] != '\0'; i++) {
        if (str[i] == ch) {
            count++;
        }
    }

    printf("The character '%c' appears %d times in the string.\\n", ch, count);

    return 0;
}`,
    cpp: `// C++ code
#include <iostream>
#include <string>
using namespace std;

int main() {
    string str;
    char ch;
    int count = 0;

    cout << "Enter a string: ";
    getline(cin, str);

    cout << "Enter a character to count: ";
    cin >> ch;

    for (char c : str) {
        if (c == ch) {
            count++;
        }
    }

    cout << "The character '" << ch << "' appears " << count << " times in the string." << endl;

    return 0;
}`,
    java: `// Java code
import java.util.Scanner;

public class CharacterCount {
    public static void main(String[] args) {
        Scanner scanner = new Scanner(System.in);
        System.out.print("Enter a string: ");
        String str = scanner.next();
        System.out.print("Enter a character to count: ");
        char ch = scanner.next().charAt(0);
        scanner.close();

        int count = 0;

        for (int i = 0; i < str.length(); i++) {
            if (str.charAt(i) == ch) {
                count++;
            }
        }

        System.out.println("The character '" + ch + "' appears " + count + " times in the string.");
    }
}`,
    python: `# Python code
input_string = input("Enter a string: ")
character = input("Enter a character to count: ")
count = 0

if len(character) == 1:
    for char in input_string:
        if char == character:
            count += 1
    print(f"The character '{character}' appears {count} times in the string.")
else:
    print("Please enter a single character.")`,
  };

  const code8 = {
    c: `// C code
#include <stdio.h>
#include <string.h>

int isPalindrome(const char *str) {
    int length = strlen(str);
    int i, j;

    for (i = 0, j = length - 1; i < j; i++, j--) {
        if (str[i] != str[j]) {
            return 0; // Not a palindrome
        }
    }

    return 1; // Palindrome
}

int main() {
    char str[100];
    printf("Enter a string: ");
    gets(str);

    if (isPalindrome(str)) {
        printf("The string is a palindrome.\\n");
    } else {
        printf("The string is not a palindrome.\\n");
    }

    return 0;
}`,
    cpp: `// C++ code
#include <iostream>
#include <string>
using namespace std;

bool isPalindrome(const string& str) {
    int length = str.length();
    int i, j;

    for (i = 0, j = length - 1; i < j; i++, j--) {
        if (str[i] != str[j]) {
            return false; // Not a palindrome
        }
    }

    return true; // Palindrome
}

int main() {
    string str;
    cout << "Enter a string: ";
    getline(cin, str);

    if (isPalindrome(str)) {
        cout << "The string is a palindrome." << endl;
    } else {
        cout << "The string is not a palindrome." << endl;
    }

    return 0;
}`,
    java: `// Java code
import java.util.Scanner;

public class PalindromeCheck {
    public static boolean isPalindrome(String str) {
        int length = str.length();
        int i, j;

        for (i = 0, j = length - 1; i < j; i++, j--) {
            if (str.charAt(i) != str.charAt(j)) {
                return false; // Not a palindrome
            }
        }

        return true; // Palindrome
    }

    public static void main(String[] args) {
        Scanner scanner = new Scanner(System.in);
        System.out.print("Enter a string: ");
        String str = scanner.next();
        scanner.close();

        if (isPalindrome(str)) {
            System.out.println("The string is a palindrome.");
        } else {
            System.out.println("The string is not a palindrome.");
        }
    }
}`,
    python: `# Python code
input_string = input("Enter a string:")
is_palindrome = True

for i in range(len(input_string) // 2):
    if input_string[i] != input_string[-(i + 1)]:
        is_palindrome = False
        break

if is_palindrome:
    print("The string is a palindrome.")
else:
    print("The string is not a palindrome.")`,
  };
  const code9 = {
    c: `// C code
#include <stdio.h>
#include <string.h>

int main() {
    const char *sentence = "This is a sample sentence.";
    
    printf("First 5 characters: %.5s\\n", sentence);
    
    int length = strlen(sentence);
    
    if (length > 5) {
        printf("Last 5 characters: %s\\n", sentence + length - 5);
    }
    
    if (length >= 10) {
        printf("Characters from 6th to 10th position: %.5s\\n", sentence + 5);
    }
    
    return 0;
}`,
    cpp: `// C++ code
#include <iostream>
#include <string>
using namespace std;

int main() {
    string sentence = "This is a sample sentence.";
    
    cout << "First 5 characters: " << sentence.substr(0, 5) << endl;
    
    int length = sentence.length();
    
    if (length > 5) {
        cout << "Last 5 characters: " << sentence.substr(length - 5) << endl;
    }
    
    if (length >= 10) {
        cout << "Characters from 6th to 10th position: " << sentence.substr(5, 5) << endl;
    }
    
    return 0;
}`,
    java: `// Java code
public class StringSlicing {
    public static void main(String[] args) {
        String sentence = "This is a sample sentence.";
        
        System.out.println("First 5 characters: " + sentence.substring(0, 5));
        
        int length = sentence.length();
        
        if (length > 5) {
            System.out.println("Last 5 characters: " + sentence.substring(length - 5));
        }
        
        if (length >= 10) {
            System.out.println("Characters from 6th to 10th position: " + sentence.substring(5, 10));
        }
    }
}`,
    python: `# Python code
sentence = "This is a sample sentence."

print("First 5 characters:", sentence[:5])

if len(sentence) > 5:
    print("Last 5 characters:", sentence[-5:])

if len(sentence) >= 10:
    print("Characters from 6th to 10th position:", sentence[5:10])`,
  };

  const [questions, setQuestions] = useState([
    {
      question: "Q1: Write a program to reverse a given string.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions1, setQuestions1] = useState([
    {
      question:
        "Q2: Implement a program to find the length of a string without using library functions.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions2, setQuestions2] = useState([
    {
      question:
        "Q3: Create a program that counts the number of vowels and consonants in a string.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions3, setQuestions3] = useState([
    {
      question:
        "Q4: Implement a program to check if two strings are anagrams of each other.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions4, setQuestions4] = useState([
    {
      question:
        "Q5: Write a program to concatenate two strings.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions5, setQuestions5] = useState([
    {
      question:
        "Q6: Create a program to find and replace all occurrences of a specific word in a string.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions6, setQuestions6] = useState([
    {
      question:
        "Q7: Implement a program to check if a string is a valid integer (e.g., \"123\" is valid, but \"abc\" is not).",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions7, setQuestions7] = useState([
    {
      question:
        "Q8: Write a program in C/C++ to count the occurrences of a specific character in a string.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions8, setQuestions8] = useState([
    {
      question:
        "Q9: Write a program to check if a string is a palindrome.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions9, setQuestions9] = useState([
    {
      question:
        "Q10: Create a string variable containing a sentence and use slicing to print the first 5 characters, the last 5 characters, and characters from the 6th to the 10th position.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);

  const toggleAccordion = (index, questions, setQuestions) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].isOpen = !updatedQuestions[index].isOpen;
    setQuestions(updatedQuestions);
  };

  const changeCodeLanguage = (index, language, questions, setQuestions) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].codeLanguage = language;
    setQuestions(updatedQuestions);
  };

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };
  const divRef = useRef(null);
  return (
    <div className="testing">
      <Sidebar>
        <div className="containera">
          <div className="ch-content">
            <h2 className="ui header">
            <Link to="/ques4">
              <button
                className="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
              </Link>
              <Link to="/ques6">
                <button
                  className="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>
            <div className="main-body ui ">
              <h2
                className="ui left floated header"
                style={{ color: "#001C30" }}
              >
                Strings:
              </h2>
              <div className="ui clearing divider"></div>
              <CodeAccordion
                questions={questions}
                code={code}
                toggleAccordion={(index) =>
                  toggleAccordion(index, questions, setQuestions)
                }
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions, setQuestions)
                }
              />
              <br />
              <CodeAccordion
                questions={questions1}
                code={code1}
                toggleAccordion={(index) =>
                  toggleAccordion(index, questions1, setQuestions1)
                }
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions1, setQuestions1)
                }
              />
              <br />
              <CodeAccordion
                questions={questions2}
                code={code2}
                toggleAccordion={(index) =>
                  toggleAccordion(index, questions2, setQuestions2)
                }
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions2, setQuestions2)
                }
              />

              <br />
              <CodeAccordion
                questions={questions3}
                code={code3}
                toggleAccordion={(index) =>
                  toggleAccordion(index, questions3, setQuestions3)
                }
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions3, setQuestions3)
                }
              />
              <br />
              <CodeAccordion
                questions={questions4}
                code={code4}
                toggleAccordion={(index) =>
                  toggleAccordion(index, questions4, setQuestions4)
                }
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions4, setQuestions4)
                }
              />
              <br />
              <CodeAccordion
                questions={questions5}
                code={code5}
                toggleAccordion={(index) =>
                  toggleAccordion(index, questions5, setQuestions5)
                }
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions5, setQuestions5)
                }
              />
              <br />
              <CodeAccordion
                questions={questions6}
                code={code6}
                toggleAccordion={(index) =>
                  toggleAccordion(index, questions6, setQuestions6)
                }
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions6, setQuestions6)
                }
              />
              <br />
              <CodeAccordion
                questions={questions7}
                code={code7}
                toggleAccordion={(index) =>
                  toggleAccordion(index, questions7, setQuestions7)
                }
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions7, setQuestions7)
                }
              />
              <br />
              <CodeAccordion
                questions={questions8}
                code={code8}
                toggleAccordion={(index) =>
                  toggleAccordion(index, questions8, setQuestions8)
                }
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions8, setQuestions8)
                }
              />
              <br />
              <CodeAccordion
                questions={questions9}
                code={code9}
                toggleAccordion={(index) =>
                  toggleAccordion(index, questions9, setQuestions9)
                }
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions9, setQuestions9)
                }
              />
            </div><br />
            <Link to="/ques4">
              <button
                className="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
              </Link>
              <Link to="/ques6">
                <button
                  className="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default Question5;
