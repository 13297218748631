import { useState, useEffect } from "react";
import Sidebar from "./SideBar";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
// import "./Quiz.css";
import "../../Components/Chapters/Chapters.css";
import Modal from "react-modal";

const JQuiz22 = () => {
  const [answers, setAnswers] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const handleQuizSubmit = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const questions = [
    {
      id: 1,
      question: "  What is the primary purpose of a constructor in Java:",
      options: ["To create objects", "To define class-level variables", "To declare methods", "To access object properties"],
      correctAnswer: "To create objects",
    },
    {
      id: 2,
      question: "  Which of the following statements about constructors is true:",
      options: ["Constructors have a return type", "Constructors can have any name, not necessarily the same as the class", "Constructors must be explicitly defined in every class", "Constructors are automatically invoked when an object is created"],
      correctAnswer: "Constructors are automatically invoked when an object is created",
    },
    {
      id: 3,
      question: "  What is a default constructor in Java:",
      options: [
        "A constructor with no parameters",
        "A constructor that automatically initializes all object properties",
        "A constructor with a return type of \"void\"",
        "A constructor that is defined in every class",
      ],
      correctAnswer: "A constructor with no parameters",
    },
    {
      id: 4,
      question: "  When are constructors used in Java:",
      options: ["To define class properties", "To create objects and initialize their state", "To access class-level variables", "To define methods"],
      correctAnswer: "To create objects and initialize their state",
    },
    {
        id: 5,
        question: "  What is constructor overloading in Java:",
        options: ["The process of overloading a class with multiple constructors", "The process of creating multiple classes with the same constructor", "The process of defining a constructor with multiple return types", "The process of overloading methods inside a constructor"],
        correctAnswer: "The process of overloading a class with multiple constructors",
      },
      {
        id: 6,
        question: "  What is the purpose of the \"this\" keyword in Java:",
        options: ["To refer to objects of other classes", "To create a new instance of a class", "To refer to the same class in which it is present", "To specify the return type of a constructor"],
        correctAnswer: "To refer to the same class in which it is present",
      },
      {
        id: 7,
        question: "  Which of the following is a valid use of the \"this\" keyword in a constructor:",
        options: ["To create a new object", "To define a new method", "To refer to an instance variable within the class", "To invoke another constructor from the same class"],
        correctAnswer: "To invoke another constructor from the same class",
      },
  ];
  const handleAnswerChange = (questionId, selectedAnswer) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: selectedAnswer,
    }));
  };

  const calculateScore = () => {
    let correctCount = 0;
    let incorrectCount = 0;

    questions.forEach((question) => {
      const userAnswer = answers[question.id];
      if (userAnswer === question.correctAnswer) {
        correctCount++;
      } else {
        incorrectCount++;
      }
    });

    return { correctCount, incorrectCount };
  };

  const { correctCount, incorrectCount } = calculateScore();
  return (
    <>
      <Sidebar>
        <div class="container">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/quizes/java_quiz21">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                  }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/quizes/java_quiz23">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              </h2>
              <h1 class="start content ui left floated header">
                Constructors and Destructors
              </h1>
              <div className="ui clearing divider"></div>
            

            <div class="main-body">
              <h2 class="ui left floated header">
                Quiz # 22
              </h2>
              <span class="tut-btn"><Link to="/java/constructors">
                  <button class="ui secondary button">Read Tutorial</button>
                </Link></span>
              <div class="ui clearing divider"></div>
              <div>
                {questions.map((question, i) => (
                  <div key={question.id}>
                    <div class="con">
                    <div class="que">Q{i+1}:<span class="one-per-left-margin">{question.question}</span></div>
                      {question.options.map((option) => (
                        <label class="label" key={option}>
                          <input
                            type="radio"
                            name={`question_${question.id}`}
                            value={option}
                            checked={answers[question.id] === option}
                            onChange={(e) =>
                              handleAnswerChange(question.id, e.target.value)
                            }
                          />
                          <span className="one-per-left-margin">{option}</span>
                          <br />
                        </label>
                      ))}
                    </div>
                    <hr />
                  </div>
                ))}<br/>
                <center><button class="ui primary button" onClick={handleQuizSubmit}>Submit Quiz</button></center>

                {/* Modal for displaying results */}
                <Modal class="modal" isOpen={isModalOpen} onRequestClose={closeModal}>
                  <b style={{fontSize:'20px'}}>Quiz Results</b>
                  <b><p>Your score is {correctCount}/{questions.length} </p></b>
                  {questions.map((question,i) => (
                    <div key={question.id}>
                      <p>Q{i+1}: {question.question}</p>
                      <p><b>Correct Answer:</b> {question.correctAnswer}</p>
                      <p className={`${question.correctAnswer}` === `${answers[question.id]}` ? "correct" : "wrong"}>
                        <b>Your Answer:</b> {answers[question.id] || "Not answered"}
                      </p>
                      <hr />
                    </div>
                  ))}
                  <button class="ui secondary button" onClick={closeModal}>Close</button><br /><br />
                </Modal>
              </div>

              <br />
            </div>
            <Link to="/quizes/java_quiz21">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                  }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/quizes/java_quiz23">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
            <br />
          </div>
        </div>
      </Sidebar>

      <div class="sideBarBody">
        <Footer />
      </div>
    </>
  );
};
export default JQuiz22;
