import { useRef, useEffect } from "react";
import "./Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Q23 from "../Questions/Q23";
import Sidebar from "../SideBar/Sidebar";
import Footer from "../Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const Ch23 = () => {

  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor:'#D8D9DA'
  };
const code1 = `class ClassName {
  private:
    // private data members 
    /* Declare the friend function 
    inside the class. It can be 
    public or private*/
    friend type functionName(type param1, ...);
  };
    
  // Define friend function outside the class 
  type funcName(type paramName1, ...) {
  /* Function definition with access
  to private and protected members 
  of the class*/
}`
const code2 = `#include <iostream>
using namespace std;

class Student {
private:
    int num1;
    int num2;

public:
    
    Student(int value1, int value2) {
    	num1 = value1;
    	num2 = value2;
	}

    // Friend function to calculate the sum
    friend int calcSum(const Student& obj);
};

int calcSum(const Student& obj) {
    return obj.num1 + obj.num2;
}

int main() {
    
    Student std(10, 10);
    int sum = calcSum(std);
    cout << "Sum : " << sum << endl;

    return 0;
}`
const code3 = `class MyClass
{
    friend class FriendClass;
};`
const code4 = `#include <iostream>
using namespace std;

// Forward declaration of the FriendClass
class FriendClass;

// Class that declares FriendClass as a friend
class MyClass {
private:
    int num;

public:
    MyClass(int num){
    	this->num = num;
	}
    // Declare FriendClass as a friend class
    friend class FriendClass;
    int getnum() {
        return num;
    }
};

class FriendClass {
public:
    void display(const MyClass& obj) {
      cout << "Private data: " << obj.num << endl;
    }
};

int main() {
  MyClass obj(10);

  FriendClass friendObj;
  friendObj.display(obj);

  return 0;
}`

  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/Cpp/access_specifiers">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/Cpp/encapsulation">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Friend Functions
              </h2>
              <div class="ui clearing divider"></div>
              <p>
                Friend functions are the functions which can access the private members of the class. We have to 
                write the prototype of friend function inside the class of which it is the friend.
                <br /><br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Properties of Friend Functions:
              </h2>
              <div class="ui clearing divider"></div>
                <ul class="ui unordered list">
                    <li>Friend functions are declared inside the class.</li>
                    <li>They can access the private and protected data members of class of which they are friends.</li>
                    <li>They can be declared inside the public or private part of the class.</li>
                    <li>They are not the members of the class and we do not need object to call them.</li>
                    <li>Unlike methods/member functions which can access data members directly, friend functions cannot
                        access data members directly. There is a need of dot operator, like obj.dataMemberName
                    </li>
                </ul><br />
                  <div ref={divRef}>
                    <SyntaxHighlighter language="cpp" style={darcula}>
                      {code1}
                    </SyntaxHighlighter>
                  </div>
                <br />
                  
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>friendFunc.cpp</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="cpp" style={darcula}>
                      {code2}
                    </SyntaxHighlighter>
                  </div>
                  </div><br />
                  <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Friend Classes:
              </h2>
              <div class="ui clearing divider"></div>
                  Friend class is also the same concept. Friend class is a class which can access the private member of some other
                  class. But this relationship is not reversible. For example, if class1 is the friend of class2, then class1 can access 
                  private and protected members of class2 but class2 can not access private or protected members of class21. <br /><br />
                  <div ref={divRef}>
                    <SyntaxHighlighter language="cpp" style={darcula}>
                      {code3}
                    </SyntaxHighlighter>
                  </div><br />
                  <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>friendFunc.cpp</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="cpp" style={darcula}>
                      {code4}
                    </SyntaxHighlighter>
                  </div>
                  </div><br />
                  
                </p>
             
            </div><br />
            <Link to="/Cpp/access_specifiers">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/Cpp/encapsulation">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default Ch23;
