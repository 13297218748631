import { useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../Components/Home/Footer";
import "./Cheatsheets.css";

const Cheatsheets = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  return (
    <>
      <div className="p-20">
        <h1 className="text-3xl pt-20 font-semibold mb-12">Cheatsheets</h1>
        <div class="ui stackable four column grid">
          <div class="column">
            <Link to="/cheatsheets/catglance">
              <div style={{ width: "200px" }} class="ui fluid card">
                <div class="image">
                  <img src="images/c.webp" />
                </div>
                <div class="content">
                  <a class="header justify-items items-center">C Cheatsheet</a>
                </div>
              </div>
            </Link>
          </div>

          <div class="column">
            <Link to="/cheatsheets/cppatglance">
              <div style={{ width: "200px" }} class="ui fluid card">
                <div class="image">
                  <img src="images/cpp.webp" />
                </div>
                <div class="content">
                  <a class="header justify-items items-center">
                    C++ Cheatsheet{" "}
                  </a>
                </div>
              </div>
            </Link>
          </div>
          <div class="column">
            <Link to="/cheatsheets/javaatglance">
              <div
                style={{ width: "200px", backgroundColor: "white" }}
                class="ui fluid card"
              >
                <div class="image">
                  <img src="images/java.webp" />
                </div>
                <div class="content">
                  <a class="header justify-items items-center">
                    Java Cheatsheet{" "}
                  </a>
                </div>
              </div>
            </Link>
          </div>
          <div class="column">
            <Link to="/cheatsheets/pythonatglance">
              <div style={{ width: "200px" }} class="ui fluid card">
                <div class="image">
                  <img src="images/python.webp" />
                </div>
                <div class="content">
                  <a class="header justify-items items-center">
                    Python Cheatsheet
                  </a>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Cheatsheets;
