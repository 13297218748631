import { useState, useEffect } from "react";
import Sidebar from "./SideBar";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
// import "./Quiz.css";
import "../../Components/Chapters/Chapters.css";
import Modal from "react-modal";

const JQuiz21 = () => {
  const [answers, setAnswers] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const handleQuizSubmit = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const questions = [
    {
      id: 1,
      question: "  What is a class in Java:",
      options: ["A user-defined data type that stores properties and behaviors of objects", "A built-in data type that stores multiple values of the same type", "A method that initializes object state", "A variable that holds a reference to an object"],
      correctAnswer: "A user-defined data type that stores properties and behaviors of objects",
    },
    {
      id: 2,
      question: " What is an object in Java:",
      options: ["A user-defined data type that stores properties and behaviors of classes", "A built-in data type that represents a single value", "A method that initializes class properties", "An entity that has properties and behaviors"],
      correctAnswer: "An entity that has properties and behaviors",
    },
    {
      id: 3,
      question: "  How do you create an object of a class in Java:",
      options: [
        "Using the \"new\" keyword followed by the class constructor",
        "By calling the class's \"createObject\" method",
        "By using the \"Object\" class",
        "By declaring it as a variable",
      ],
      correctAnswer: "Using the \"new\" keyword followed by the class constructor",
    },
    {
      id: 4,
      question: "  What is the purpose of the dot operator (.) in Java:",
      options: ["It is used to define class properties", "It is used to create objects", "It is used to access properties and methods of an object", "It is used to access class-level variables"],
      correctAnswer: "It is used to access properties and methods of an object",
    },
    {
        id: 5,
        question: "  In Java, where should the name of a class start:",
        options: ["With a lowercase letter", "With an uppercase letter", "With a number", "With a special character"],
        correctAnswer: "With an uppercase letter",
      },
      {
        id: 6,
        question: "  In Java, what is the primary reason for using the \"final\" keyword with a variable:",
        options: ["To prevent the variable from being accessed using the dot operator", "To make the variable accessible throughout the program", "To indicate that the variable cannot be changed", "To allow the variable to be modified without restrictions"],
        correctAnswer: "To indicate that the variable cannot be changed",
      },
      {
        id: 7,
        question: "  What is the primary role of the constructor in a class:",
        options: ["To define class-level variables", "To create objects", "To initialize the state of the object", "To declare methods"],
        correctAnswer: "To initialize the state of the object",
      },
  ];
  const handleAnswerChange = (questionId, selectedAnswer) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: selectedAnswer,
    }));
  };

  const calculateScore = () => {
    let correctCount = 0;
    let incorrectCount = 0;

    questions.forEach((question) => {
      const userAnswer = answers[question.id];
      if (userAnswer === question.correctAnswer) {
        correctCount++;
      } else {
        incorrectCount++;
      }
    });

    return { correctCount, incorrectCount };
  };

  const { correctCount, incorrectCount } = calculateScore();
  return (
    <>
      <Sidebar>
        <div class="container">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/quizes/java_quiz20">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                  }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/quizes/java_quiz22">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              </h2>
              <h1 class="start content ui left floated header">
                Classes and Objects
              </h1>
              <div className="ui clearing divider"></div>
            

            <div class="main-body">
              <h2 class="ui left floated header">
                Quiz # 21
              </h2>
              <span class="tut-btn"><Link to="/java/classes_objects">
                  <button class="ui secondary button">Read Tutorial</button>
                </Link></span>
              <div class="ui clearing divider"></div>
              <div>
                {questions.map((question, i) => (
                  <div key={question.id}>
                    <div class="con">
                    <div class="que">Q{i+1}:<span class="one-per-left-margin">{question.question}</span></div>
                      {question.options.map((option) => (
                        <label class="label" key={option}>
                          <input
                            type="radio"
                            name={`question_${question.id}`}
                            value={option}
                            checked={answers[question.id] === option}
                            onChange={(e) =>
                              handleAnswerChange(question.id, e.target.value)
                            }
                          />
                          <span className="one-per-left-margin">{option}</span>
                          <br />
                        </label>
                      ))}
                    </div>
                    <hr />
                  </div>
                ))}<br/>
                <center><button class="ui primary button" onClick={handleQuizSubmit}>Submit Quiz</button></center>

                {/* Modal for displaying results */}
                <Modal class="modal" isOpen={isModalOpen} onRequestClose={closeModal}>
                  <b style={{fontSize:'20px'}}>Quiz Results</b>
                  <b><p>Your score is {correctCount}/{questions.length} </p></b>
                  {questions.map((question,i) => (
                    <div key={question.id}>
                      <p>Q{i+1}: {question.question}</p>
                      <p><b>Correct Answer:</b> {question.correctAnswer}</p>
                      <p className={`${question.correctAnswer}` === `${answers[question.id]}` ? "correct" : "wrong"}>
                        <b>Your Answer:</b> {answers[question.id] || "Not answered"}
                      </p>
                      <hr />
                    </div>
                  ))}
                  <button class="ui secondary button" onClick={closeModal}>Close</button><br /><br />
                </Modal>
              </div>

              <br />
            </div>
            <Link to="/quizes/java_quiz20">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                  }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/quizes/java_quiz22">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
            <br />
          </div>
        </div>
      </Sidebar>

      <div class="sideBarBody">
        <Footer />
      </div>
    </>
  );
};
export default JQuiz21;
