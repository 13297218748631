import { useState, useEffect } from "react";
import Sidebar from "./SideBar";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
// import "./Quiz.css";
import "../../Components/Chapters/Chapters.css";
import Modal from "react-modal";

const PQuiz23 = () => {
  const [answers, setAnswers] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const handleQuizSubmit = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const questions = [
    {
        id: 1,
        question: "  What does the scope of a variable in Python refer to:",
        options: ["The data type of the variable", "The value of the variable", "The size of the variable", "The region of the program where the variable is accessible"],
        correctAnswer: "The region of the program where the variable is accessible",
      },
      {
        id: 2,
        question: "  Where are global variables defined in Python accessible:",
        options: ["Only within the function where they are defined", "Only within a specific class", "Anywhere in the script or module", "Only within the code block where they are defined"],
        correctAnswer: "Anywhere in the script or module",
      },
      {
        id: 3,
        question: "  What type of variables have global scope in Python:",
        options: ["Variables declared within a function", "Variables declared within a code block", "Variables defined within a class", "Variables defined at the top level of a Python script or module"],
        correctAnswer: "Variables defined at the top level of a Python script or module",
      },
      {
        id: 4,
        question: "  What is the scope of local variables in Python:",
        options: ["They can be accessed from anywhere in the script or module", "They are temporary and exist only within the function or code block where they are defined", "They can be accessed from within any function", "They are accessible from the global scope"],
        correctAnswer: "They are temporary and exist only within the function or code block where they are defined",
      },
      {
        id: 5,
        question: "  In Python, what happens if a variable with the same name exists in both local and global scopes:",
        options: ["The local variable takes precedence over the global variable", "The global variable takes precedence over the local variable", "Python raises an error, and you must rename one of the variables", "Python combines the values of both variables"],
        correctAnswer: "The local variable takes precedence over the global variable",
      },
      {
        id: 6,
        question: "  What is the LEGB rule in Python for variable lookup:",
        options: ["A rule that defines the data type of a variable", "A rule that specifies how variables are assigned values", "A rule that determines the order in which Python looks for a variable name in different scopes", "A rule for defining functions within a class"],
        correctAnswer: "A rule that determines the order in which Python looks for a variable name in different scopes",
      },
      {
        id: 7,
        question: "  What is the global keyword used for in Python:",
        options: ["To declare a variable as an integer", "To indicate that a variable declared inside a function should have local scope", "To specify the data type of a variable", "To indicate that a variable declared inside a function should have global scope"],
        correctAnswer: "To indicate that a variable declared inside a function should have global scope",
      },
      {
        id: 8,
        question: "  In Python, which scope does the global keyword affect:",
        options: ["Global scope", "Local scope", "Enclosing scope", "Built-in scope"],
        correctAnswer: "Global scope",
      },
      {
        id: 9,
        question: "  In Python, which scope takes precedence when accessing a variable with the same name:",
        options: ["Global scope", "Local scope", "Built-in scope", "Enclosing scope"],
        correctAnswer: "Local scope",
      },
  ];
  const handleAnswerChange = (questionId, selectedAnswer) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: selectedAnswer,
    }));
  };

  const calculateScore = () => {
    let correctCount = 0;
    let incorrectCount = 0;

    questions.forEach((question) => {
      const userAnswer = answers[question.id];
      if (userAnswer === question.correctAnswer) {
        correctCount++;
      } else {
        incorrectCount++;
      }
    });

    return { correctCount, incorrectCount };
  };

  const { correctCount, incorrectCount } = calculateScore();
  return (
    <>
      <Sidebar>
        <div class="container">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/quizes/python_quiz22">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                  }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/quizes/python_quiz24">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              </h2>
              <h1 class="start content ui left floated header">
                Variable Scope
              </h1>
              <div className="ui clearing divider"></div>
            

            <div class="main-body">
              <h2 class="ui left floated header">
                Quiz # 23
              </h2>
              <span class="tut-btn"><Link to="/python/scope">
                  <button class="ui secondary button">Read Tutorial</button>
                </Link></span>
              <div class="ui clearing divider"></div>
              <div>
                {questions.map((question, i) => (
                  <div key={question.id}>
                    <div class="con">
                    <div class="que">Q{i+1}:<span class="one-per-left-margin">{question.question}</span></div>
                      {question.options.map((option) => (
                        <label class="label" key={option}>
                          <input
                            type="radio"
                            name={`question_${question.id}`}
                            value={option}
                            checked={answers[question.id] === option}
                            onChange={(e) =>
                              handleAnswerChange(question.id, e.target.value)
                            }
                          />
                          <span className="one-per-left-margin">{option}</span>
                          <br />
                        </label>
                      ))}
                    </div>
                    <hr />
                  </div>
                ))}<br/>
                <center><button class="ui primary button" onClick={handleQuizSubmit}>Submit Quiz</button></center>

                {/* Modal for displaying results */}
                <Modal class="modal" isOpen={isModalOpen} onRequestClose={closeModal}>
                  <b style={{fontSize:'20px'}}>Quiz Results</b>
                  <b><p>Your score is {correctCount}/{questions.length} </p></b>
                  {questions.map((question,i) => (
                    <div key={question.id}>
                      <p>Q{i+1}: {question.question}</p>
                      <p><b>Correct Answer:</b> {question.correctAnswer}</p>
                      <p className={`${question.correctAnswer}` === `${answers[question.id]}` ? "correct" : "wrong"}>
                        <b>Your Answer:</b> {answers[question.id] || "Not answered"}
                      </p>
                      <hr />
                    </div>
                  ))}
                  <button class="ui secondary button" onClick={closeModal}>Close</button><br /><br />
                </Modal>
              </div>

              <br />
            </div>
            <Link to="/quizes/python_quiz22">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                  }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/quizes/python_quiz24">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
            <br />
          </div>
        </div>
      </Sidebar>

      <div class="sideBarBody">
        <Footer />
      </div>
    </>
  );
};
export default PQuiz23;
