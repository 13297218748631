import { useRef, useEffect } from "react";
import "./Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Q31 from "../Questions/Q31";
import Sidebar from "../SideBar/Sidebar";
import Footer from "../Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const Ch31 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };

  const code1 = `#include <iostream>
using namespace std;

template <typename T>
void max(T &a, T &b) {
    if(a > b){
    	cout<<a<<endl;
	}
	
	else{
		cout<<b<<endl;
	}
	
}
int main() {
	int a = 10, b = 12;
	max(a, b);
	
	float c = 12.12, d = 34.21;
	max(c, d);
	
	char e = 'A', f = 'Z';
	max(e, f);
    return 0;
}`;
  const code2 = `#include <iostream>
using namespace std;

template <typename T1, typename T2>
class Pair {
	private:
    	T1 first;
    	T2 second;
	public:
    	Pair(T1 first, T2 second){
			this->first = first;
			this->second = second;
		}

    	T1 getFirst() const { 
			return first; 
		}
    	T2 getSecond() const {
		 return second; 
		}


};
int main() {
    Pair<int, double> myPair(42, 3.14);
    cout << "First value: "<<myPair.getFirst()<< ", "
     << "Second value: "<<myPair.getSecond();
    cout<<endl;

    Pair<string, char> pair("Hello", 'X');
    cout << "First value: "<<pair.getFirst()<< ", "
     << "Second value: "<<pair.getSecond();
    cout<<endl;

    
    return 0;
}`;

  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/Cpp/operator_overloading">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/Cpp/stl">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Templates
              </h2>
              <div class="ui clearing divider"></div>
              <p>
                Template is a powerful feature in any language which enables us
                to write generic code. Generic code means that it enables us to
                define functions and classes that can work with different data
                types with same block of code / same logic. You do not need to
                write code for different data types explicitly. In this way,
                your code become reusable. Therefore, we can say that template
                is a feature in any language that gives that particular language
                more flexibility.
                <br />
                <br />
                <b>For example, </b>
                
                You are using a function that add two numbers and it is a
                template function. This function will operate for addition of
                any datatype. Like, sum(2, 3) will give 5 and sum(2.5, 2.1) will
                give 4.6. A single function is doin that. We do not need to
                define two separate sum functions for int and float.
                <br />
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Types of Templates:
                </h2>
                <div class="ui clearing divider"></div>
                Templates are of two kinds in C++:
                <ul class="ui unordered list">
                  <li>Function Templates</li>
                  <li>Class Templates</li>
                </ul>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Function Templates:
                </h2>
                <div class="ui clearing divider"></div>
                As we have discussed that function template is a function that
                is used with different data types. <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>funcTemplate.cpp</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="cpp" style={darcula}>
                      {code1}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <h1>In this example,</h1>
                <ul className="ui unordered list">
                  <li>We have made a template using template keyword and then write
                our type name.</li>
                <li>After type name, we write "T" - which is our
                generic data type.</li>
                <li>Then we have written our function simply but
                changed our data type everywhere to "T".</li>
                <li>Now whenever we call
                our template function, the data type "T" will be treated as the
                data type of the arguments. In this way, we can call our
                function with any data type we want.</li>
                </ul>
                   
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Class Templates:
                </h2>
                <div class="ui clearing divider"></div>
                Using class templates, we can define generic classes to work
                with different data types. Class templates are also defined
                using template keyword. <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>funcTemplate.cpp</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="cpp" style={darcula}>
                      {code2}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <h1>In this example,</h1>
                <ul className="ui unordered list">
                  <li>We have made a class template using the "template" keyword and then
                write our type name.</li>
                <li>Now we are using two parameters, we write
                "T1" as first type name and "T2" as second type name - which are
                our generic data types.</li>
                <li>Then we have written our class simply
                but changed the data types.</li>
                <li>In main function, after our class
                name, we specified the data types of arguments we are passing.</li>
                </ul>
                   
                
              </p>
             
            </div><br />
            <Link to="/Cpp/operator_overloading">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/Cpp/stl">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default Ch31;
