import { useRef, useEffect } from "react";
import "../../Components/Chapters/Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Sidebar from "../JavaSideBar";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const JavaC32 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };

  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };
  const jc32code1 = `interface Shape {
    double calculateArea();
    double calculatePerimeter();
}`;
  const jc32code2 = `class Circle implements Shape {
    private double radius;

    public Circle(double radius) {
        this.radius = radius;
    }

    @Override
    public double calculateArea() {
        return Math.PI * radius * radius;
    }

    @Override
    public double calculatePerimeter() {
        return 2 * Math.PI * radius;
    }
}`;
  const jc32code3 = `class Triangle implements Shape, Drawable {
    // Implement Shape methods
    // Implement Drawable methods
}`;
  const jc32code4 = `interface MyInterface {
  // Abstract method
  void myMethod();

  // Default method with implementation
  default void defaultMethod() {
    System.out.println("Default implementation");
  }

  // Static method with implementation
  static void staticMethod() {
    System.out.println("Static implementation");
  }
}`;
  const jc32code5 = `// Define an interface
interface Shape {
    double calculateArea();
}

// Define another interface
interface Drawable {
    void draw();
}

// Create a parent class
class Parent {
    void display() {
        System.out.println("Parent class.");
    }
}

class Child extends 
  Parent implements Shape, Drawable {
    private double radius;

    public Child(double radius) {
        this.radius = radius;
    }

    @Override
    public double calculateArea() {
        return Math.PI * radius * radius;
    }

    @Override
    public void draw() {
        System.out.println("Drawing a shape.");
    }
}

public class InheritanceWithInterfaces {
    public static void main(String[] args) {
        Child child = new Child(5.0);

        // Call methods from interfaces
        double area = child.calculateArea();
        System.out.println("Area: " + area);

        child.draw();

        // Call a method from the parent class
        child.display();
    }
}`;

  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/java/inheritance">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/java/abstraction">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body ">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Interfaces in Java:
              </h2>
              <div class="ui clearing divider"></div>
              <p style={{ backgroundColor: "", padding: "" }}>
                An interface is a blueprint of a class, containing a
                collection of abstract methods (methods without a body) and
                constant variables. It defines a contract that classes must
                follow when they implement the interface.
                <br />
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Declaring an Interface:
                </h2>
                <div class="ui clearing divider"></div>
                <span>
                  We use the "interface" keyword to declare an interface;
                </span>
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>interfaces.java</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="java" style={darcula}>
                      {jc32code1}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
              </p>
              <span>
                Any class with interface "Shape"must have implementation of
                calculatePArea() and calculatePerimeter() methods.
              </span>
              <br />
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Implementing an Interface:
              </h2>
              <div class="ui clearing divider"></div>
              <span>We use "implements" keyword to implement interface.</span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>interfaces.java</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="java" style={darcula}>
                    {jc32code2}
                  </SyntaxHighlighter>
                </div>
              </div>

              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Multiple Inheritance and Interfaces:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                Java supports multiple inheritance through interfaces. A class
                can implement multiple interfaces.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>interfaces.java</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="java" style={darcula}>
                    {jc32code3}
                  </SyntaxHighlighter>
                </div>
              </div>

              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Default and Static Methods in Interfaces:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                Beginning with Java 8, interfaces have the capability to include
                default and static methods with predefined implementations.
                Default methods enable the addition of new methods to an
                interface without causing disruption to existing
                implementations.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>interfaces.java</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="java" style={darcula}>
                    {jc32code4}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />

              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Inheritance with Interfaces:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                A class can extend another class and implement multiple
                interfaces simultaneously. When extending a class and
                implementing interfaces, the extends keyword comes before the
                implements keyword.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>interfaces.java</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="java" style={darcula}>
                    {jc32code5}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />

              <h1>In this example;</h1>
              <ul className="ui unordered list">
                <li>
                  We define two interfaces, Shape and Drawable, each with one or
                  more methods.
                </li>
                <li>We create a parent class Parent with a display method.</li>
                <li>
                  The Child class extends the Parent (inheritance from a class) and
                  implements both Drawable and Shape interfaces. It provides
                  concrete implementations for the methods defined in the
                  interfaces.
                </li>
                <li>
                  In the main method, we create an instance of the Child class
                  and demonstrate how to call methods from both interfaces and
                  the parent class.
                </li>
              </ul>
            </div>
            

            <br />
            <Link to="/java/inheritance">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/java/abstraction">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default JavaC32;
