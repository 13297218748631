import React, { useState } from "react";
import {
  FaTh,
  FaBars,
  FaUserAlt,
  FaRegChartBar,
  FaCommentAlt,
  FaShoppingBag,
  FaThList,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import "../Components/SideBar/App.css";

const Sidebar = ({ children }) => {
  const [isOpenn, setisOpenn] = useState(true);
  const toggle = () => setisOpenn(!isOpenn);
  const menuItem = [
    {
      path: "/python/introduction",
      name: "Python Introduction",
    },
    {
      path: "/python/getting_started",
      name: "Python Getting Started",
    },
    {
      path: "/python/syntax",
      name: "Python Basic Syntax",
    },
    {
      path: "/python/output",
      name: "Python Printing Output",
    },
    {
      path: "/python/comments",
      name: "Python Comments",
    },
    {
      path: "/python/variables",
      name: "Python Variables",
    },
    {
      path: "/python/input",
      name: "Python Taking Input",
    },
    {
      path: "/python/data_types",
      name: "Python Data types",
    },
    {
      path: "/python/numbers",
      name: "Python Numbers",
    },
    {
      path: "/python/strings",
      name: "Python Strings",
    },
    {
      path: "/python/operators",
      name: "Python Operators",
    },
    {
      path: "/python/maths",
      name: "Python Maths",
    },
    {
      path: "/python/lists",
      name: "Python Lists",
    },
    {
      path: "/python/tuples",
      name: "Python Tuples",
    },
    {
      path: "/python/sets",
      name: "Python Sets",
    },
    {
      path: "/python/dictionaries",
      name: "Python Dictionaries",
    },
    {
      path: "/python/if_else",
      name: "Python Conditional Statements",
    },
    {
      path: "/python/for_loop",
      name: "Python For Loop",
    },
    {
      path: "/python/while_loop",
      name: "Python While Loops",
    },

    {
      path: "/python/booleans",
      name: "Python Booleans",
    },
    {
      path: "/python/control_statements",
      name: "Python Control Statements",
    },
    {
      path: "/python/functions",
      name: "Python Functions",
    },
    {
      path: "/python/oop",
      name: "Python OOP",
    },
    {
      path: "/python/classes_objects",
      name: "Python Classes And Objects",
    },
    {
      path: "/python/scope",
      name: "Python Variable Scope",
    },
    {
      path: "/python/decorators",
      name: "Python Decorators",
    },
    {
      path: "/python/inheritance",
      name: "Python Inheritance",
    },
    {
      path: "/python/polymorphism",
      name: "Python Polymorphism",
    },
    {
      path: "/python/modules",
      name: "Python Modules",
    },
    {
      path: "/python/json",
      name: "Python JSON",
    },
    {
      path: "/python/exceptions",
      name: "Python Try-Except",
    },
    {
      path: "/python/file_handling",
      name: "Python File Handling",
    },
    {
      path: "/python/iterators",
      name: "Python Iterators",
    },
  ];
  const handleClick = (e) => {
    e.preventDefault();
    setisOpenn(!isOpenn);
  };
  const handleClicked = () => {
    setisOpenn(!isOpenn);
  };
  return (
    <div className={`${isOpenn === true ? "flex" : "sidebar_"}`}>
      <input type="checkbox" id="click" />
      <label htmlFor="click" className="menu-btn" style={{ marginTop: "16%" }}><br/>
        <i
          onClick={handleClick}
          className="fas fa-bars"
          style={{ marginTop: "0%" }}
        ></i>
      </label>
      <div
        style={{ display: "block", width: "200px" }}
        className={`${isOpenn === true ? "sidebar" : "nosidebar"}`}
      >
        <div className="top_section">
          <h1 style={{ display: "block" }} className="logo">
            <span style={{ color: "black", fontSize: "22px" }}>
              PythonTutorial
            </span>
          </h1>
        </div>
        {menuItem.map((item, index) => (
          <ul>
            <li>
              <Link
                to={item.path}
                key={index}
                className="link"
                activeclassName="active"
              >
                {/* here */}
                <div
                  onClick={handleClicked}
                  style={{
                    display: isOpenn ? "block" : "block",
                    color: "black",
                    fontSize: "14px",
                  }}
                  className="link_text"
                >
                  {item.name}
                </div>
              </Link>
            </li>
          </ul>
        ))}
      </div>
      <div className={`${isOpenn === true ? "" : "closemain"}`}>
        <main>{children}</main>
      </div>
    </div>
  );
};

export default Sidebar;
