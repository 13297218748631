import React, { useState, useRef, useEffect } from "react";
import Sidebar from "./SideBar";
import "../../Components/SideBar/App.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const CodeAccordion = ({ questions, code, toggleAccordion, changeCodeLanguage }) => {
    const divRef = useRef(null);
    const copyContent = () => {
        const divContent = divRef.current.innerText;
        navigator.clipboard
          .writeText(divContent)
          .then(() => {
            toast.success("Content copied!", { position: "top-center" });
          })
          .catch((error) => {
            toast.error("Error copying content:", error, {
              position: "top-center",
            });
          });
      };
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };

  return (
    <div>
      {questions.map((item, index) => (
        <div key={index} className="border p-2 rounded-md">
          <div className="flex justify-between">
            <span className="text-lg font-semibold">
              {item.question}
            </span>
            <button
              className="bg-gray-200 px-2 py-1 rounded-md"
              onClick={() => toggleAccordion(index)}
            >
              {item.isOpen ? "-" : "+"}
            </button>
          </div>
          <div className={`mt-2 ${item.isOpen ? "block" : "hidden"}`}>
            <div className="mt-2 flex space-x-4">
              {Object.keys(code).map((language) => (
                <button
                  key={language}
                  onClick={() => changeCodeLanguage(index, language)}
                  className={`${
                    item.codeLanguage === language
                      ? "bg-blue-500 hover:bg-blue-600 text-white"
                      : "bg-gray-200 hover:bg-gray-300 text-gray-700"
                  } px-3 py-2 rounded-md transition duration-300 ease-in-out transform hover:scale-105`}
                >
                  {language}
                </button>
              ))}
            </div>
            <p className="text-gray-800">
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>output.cpp</b>
                  <i
                    className="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language={`${item.codeLanguage}`} style={darcula}>
                    {code[item.codeLanguage]}
                  </SyntaxHighlighter>
                </div>
              </div>
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

const Question11 = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
    const code = {
        c: `// C code
#include <stdio.h>

int main() {
    int arr[] = {1, 2, 3, 4, 5};
    int n = sizeof(arr) / sizeof(arr[0]);
    int sum = 0;

    for (int i = 0; i < n; i++) {
        sum += arr[i];
    }

    printf("Sum of elements: %d\\n", sum);

    return 0;
}`,
        cpp: `// C++ code
#include <iostream>
using namespace std;

int main() {
    int arr[] = {1, 2, 3, 4, 5};
    int n = sizeof(arr) / sizeof(arr[0]);
    int sum = 0;

    for (int i = 0; i < n; i++) {
        sum += arr[i];
    }

    cout << "Sum of elements: " << sum << endl;

    return 0;
}`,
        java: `// Java code
public class ArraySum {
    public static void main(String[] args) {
        int[] arr = {1, 2, 3, 4, 5};
        int sum = 0;

        for (int num : arr) {
            sum += num;
        }

        System.out.println("Sum of elements: " + sum);
    }
}`,
        
      };
      const code1 = {
        c: `// C code
#include <stdio.h>

int main() {
    int arr[] = {1, 2, 3, 4, 5};
    int n = sizeof(arr) / sizeof(arr[0]);
    int sum = 0;
    double average;

    for (int i = 0; i < n; i++) {
        sum += arr[i];
    }

    average = (double)sum / n;
    printf("Average of elements: %.2lf\\n", average);

    return 0;
}`,
        cpp: `// C++ code
#include <iostream>
using namespace std;

int main() {
    int arr[] = {1, 2, 3, 4, 5};
    int n = sizeof(arr) / sizeof(arr[0]);
    int sum = 0;
    double average;

    for (int i = 0; i < n; i++) {
        sum += arr[i];
    }

    average = static_cast<double>(sum) / n;
    cout << "Average of elements: " << average << endl;

    return 0;
}`,
        java: `// Java code
public class ArrayAverage {
    public static void main(String[] args) {
        int[] arr = {1, 2, 3, 4, 5};
        int sum = 0;

        for (int num : arr) {
            sum += num;
        }

        double average = (double) sum / arr.length;
        System.out.println("Average of elements: " + average);
    }
}`,
        
      };
      const code2 = {
        c: `// C code
#include <stdio.h>

int main() {
    int arr[] = {12, 45, 78, 33, 95, 56, 23, 67};
    int n = sizeof(arr) / sizeof(arr[0]);
    int largest = arr[0];

    for (int i = 1; i < n; i++) {
        if (arr[i] > largest) {
            largest = arr[i];
        }
    }

    printf("Largest element in the array: %d\\n", largest);

    return 0;
}`,
        cpp: `// C++ code
#include <iostream>
using namespace std;

int main() {
    int arr[] = {12, 45, 78, 33, 95, 56, 23, 67};
    int n = sizeof(arr) / sizeof(arr[0]);
    int largest = arr[0];

    for (int i = 1; i < n; i++) {
        if (arr[i] > largest) {
            largest = arr[i];
        }
    }

    cout << "Largest element in the array: " << largest << endl;

    return 0;
}`,
        java: `// Java code
public class LargestElement {
    public static void main(String[] args) {
        int[] arr = {12, 45, 78, 33, 95, 56, 23, 67};
        int largest = arr[0];

        for (int i = 1; i < arr.length; i++) {
            if (arr[i] > largest) {
                largest = arr[i];
            }
        }

        System.out.println("Largest element in the array: " + largest);
    }
}`,
        
      };
      const code3 = {
        c: `// C code
#include <stdio.h>

int main() {
    int arr[] = {1, 2, 3, 4, 5};
    int n = sizeof(arr) / sizeof(arr[0]);

    // Reverse the array in-place
    for (int i = 0; i < n / 2; i++) {
        int temp = arr[i];
        arr[i] = arr[n - i - 1];
        arr[n - i - 1] = temp;
    }

    printf("Reversed array: ");
    for (int i = 0; i < n; i++) {
        printf("%d ", arr[i]);
    }
    printf("\\n");

    return 0;
}`,
        cpp: `// C++ code
#include <iostream>
using namespace std;

int main() {
    int arr[] = {1, 2, 3, 4, 5};
    int n = sizeof(arr) / sizeof(arr[0]);

    // Reverse the array in-place
    for (int i = 0; i < n / 2; i++) {
        int temp = arr[i];
        arr[i] = arr[n - i - 1];
        arr[n - i - 1] = temp;
    }

    cout << "Reversed array: ";
    for (int i = 0; i < n; i++) {
        cout << arr[i] << " ";
    }
    cout << endl;

    return 0;
}`,
        java: `// Java code
public class ReverseArray {
    public static void main(String[] args) {
        int[] arr = {1, 2, 3, 4, 5};
        int n = arr.length;

        // Reverse the array in-place
        for (int i = 0; i < n / 2; i++) {
            int temp = arr[i];
            arr[i] = arr[n - i - 1];
            arr[n - i - 1] = temp;
        }

        System.out.print("Reversed array: ");
        for (int i = 0; i < n; i++) {
            System.out.print(arr[i] + " ");
        }
        System.out.println();
    }
}`,
        
      };
      const code4 = {
        c: `// C code
#include <stdio.h>

void bubbleSort(int arr[], int n) {
    for (int i = 0; i < n - 1; i++) {
        for (int j = 0; j < n - i - 1; j++) {
            if (arr[j] > arr[j + 1]) {
                int temp = arr[j];
                arr[j] = arr[j + 1];
                arr[j + 1] = temp;
            }
        }
    }
}

int main() {
    int arr[] = {64, 34, 25, 12, 22, 11, 90};
    int n = sizeof(arr) / sizeof(arr[0]);

    bubbleSort(arr, n);

    printf("Sorted array in ascending order: ");
    for (int i = 0; i < n; i++) {
        printf("%d ", arr[i]);
    }
    printf("\\n");

    return 0;
}`,
        cpp: `// C++ code
#include <iostream>
using namespace std;

void bubbleSort(int arr[], int n) {
    for (int i = 0; i < n - 1; i++) {
        for (int j = 0; j < n - i - 1; j++) {
            if (arr[j] > arr[j + 1]) {
                int temp = arr[j];
                arr[j] = arr[j + 1];
                arr[j + 1] = temp;
            }
        }
    }
}

int main() {
    int arr[] = {64, 34, 25, 12, 22, 11, 90};
    int n = sizeof(arr) / sizeof(arr[0]);

    bubbleSort(arr, n);

    cout << "Sorted array in ascending order: ";
    for (int i = 0; i < n; i++) {
        cout << arr[i] << " ";
    }
    cout << endl;

    return 0;
}`,
        java: `// Java code
public class BubbleSort {
    public static void bubbleSort(int arr[]) {
        int n = arr.length;
        for (int i = 0; i < n - 1; i++) {
            for (int j = 0; j < n - i - 1; j++) {
                if (arr[j] > arr[j + 1]) {
                    int temp = arr[j];
                    arr[j] = arr[j + 1];
                    arr[j + 1] = temp;
                }
            }
        }
    }

    public static void main(String[] args) {
        int arr[] = {64, 34, 25, 12, 22, 11, 90};
        bubbleSort(arr);

        System.out.print("Sorted array in ascending order: ");
        for (int i : arr) {
            System.out.print(i + " ");
        }
        System.out.println();
    }
}`,
        
      };
      const code5 = {
        c: `// C code
#include <stdio.h>

void removeDuplicates(int arr[], int *n) {
    if (*n <= 1) {
        return; // No duplicates to remove
    }

    int uniqueIndex = 1;

    for (int i = 1; i < *n; i++) {
        int isDuplicate = 0;

        for (int j = 0; j < uniqueIndex; j++) {
            if (arr[i] == arr[j]) {
                isDuplicate = 1;
                break;
            }
        }

        if (!isDuplicate) {
            arr[uniqueIndex] = arr[i];
            uniqueIndex++;
        }
    }

    *n = uniqueIndex;
}

int main() {
    int arr[] = {1, 2, 2, 3, 4, 4, 5, 6};
    int n = sizeof(arr) / sizeof(arr[0]);

    removeDuplicates(arr, &n);

    printf("Array with duplicates removed: ");
    for (int i = 0; i < n; i++) {
        printf("%d ", arr[i]);
    }
    printf("\\n");

    return 0;
}`,
        cpp: `// C++ code
#include <iostream>
using namespace std;

void removeDuplicates(int arr[], int &n) {
    if (n <= 1) {
        return; // No duplicates to remove
    }

    int uniqueIndex = 1;

    for (int i = 1; i < n; i++) {
        bool isDuplicate = false;

        for (int j = 0; j < uniqueIndex; j++) {
            if (arr[i] == arr[j]) {
                isDuplicate = true;
                break;
            }
        }

        if (!isDuplicate) {
            arr[uniqueIndex] = arr[i];
            uniqueIndex++;
        }
    }

    n = uniqueIndex;
}

int main() {
    int arr[] = {1, 2, 2, 3, 4, 4, 5, 6};
    int n = sizeof(arr) / sizeof(arr[0]);

    removeDuplicates(arr, n);

    cout << "Array with duplicates removed: ";
    for (int i = 0; i < n; i++) {
        cout << arr[i] << " ";
    }
    cout << endl;

    return 0;
}`,
        java: `// Java code
import java.util.Arrays;

public class RemoveDuplicates {
    public static void removeDuplicates(int[] arr) {
        int n = arr.length;
        if (n <= 1) {
            return; // No duplicates to remove
        }

        int uniqueIndex = 1;

        for (int i = 1; i < n; i++) {
            boolean isDuplicate = false;

            for (int j = 0; j < uniqueIndex; j++) {
                if (arr[i] == arr[j]) {
                    isDuplicate = true;
                    break;
                }
            }

            if (!isDuplicate) {
                arr[uniqueIndex] = arr[i];
                uniqueIndex++;
            }
        }

        // Resize the array to contain only unique elements
        arr = Arrays.copyOf(arr, uniqueIndex);
    }

    public static void main(String[] args) {
        int[] arr = {1, 2, 2, 3, 4, 4, 5, 6};
        removeDuplicates(arr);

        System.out.print("Array with duplicates removed: ");
        for (int num : arr) {
            System.out.print(num + " ");
        }
        System.out.println();
    }
}`,
        
      };
      const code6 = {
        c: `// C code
#include <stdio.h>

int main() {
    int arr[] = {1, 2, 3, 4, 2, 5, 2, 6, 2};
    int n = sizeof(arr) / sizeof(arr[0]);
    int target = 2;

    int occurrenceCount = 0;

    printf("Indices of element %d: ", target);
    for (int i = 0; i < n; i++) {
        if (arr[i] == target) {
            printf("%d ", i);
            occurrenceCount++;
        }
    }
    printf("\\n");

    printf("Total occurrences of element %d: %d\\n", target, occurrenceCount);

    return 0;
}`,
        cpp: `// C++ code
#include <iostream>
using namespace std;

int main() {
    int arr[] = {1, 2, 3, 4, 2, 5, 2, 6, 2};
    int n = sizeof(arr) / sizeof(arr[0]);
    int target = 2;

    int occurrenceCount = 0;

    cout << "Indices of element " << target << ": ";
    for (int i = 0; i < n; i++) {
        if (arr[i] == target) {
            cout << i << " ";
            occurrenceCount++;
        }
    }
    cout << endl;

    cout << "Total occurrences of element " << target << ": " << occurrenceCount << endl;

    return 0;
}`,
        java: `// Java code
public class SearchArrayElement {
    public static void main(String[] args) {
        int arr[] = {1, 2, 3, 4, 2, 5, 2, 6, 2};
        int target = 2;

        int occurrenceCount = 0;

        System.out.print("Indices of element " + target + ": ");
        for (int i = 0; i < arr.length; i++) {
            if (arr[i] == target) {
                System.out.print(i + " ");
                occurrenceCount++;
            }
        }
        System.out.println();

        System.out.println("Total occurrences of element " + target + ": " + occurrenceCount);
    }
}`,
        
      };
      const code7 = {
        c: `// C code
#include <stdio.h>

int main() {
    int arr[] = {2, 7, 11, 15};
    int n = sizeof(arr) / sizeof(arr[0]);
    int target = 9;

    for (int i = 0; i < n - 1; i++) {
        for (int j = i + 1; j < n; j++) {
            if (arr[i] + arr[j] == target) {
                printf("Numbers that add up to %d: %d and %d\\n", target, arr[i], arr[j]);
                return 0;
            }
        }
    }

    printf("No two numbers found that add up to %d.\\n", target);

    return 0;
}`,
        cpp: `// C++ code
#include <iostream>
using namespace std;

int main() {
    int arr[] = {2, 7, 11, 15};
    int n = sizeof(arr) / sizeof(arr[0]);
    int target = 9;

    for (int i = 0; i < n - 1; i++) {
        for (int j = i + 1; j < n; j++) {
            if (arr[i] + arr[j] == target) {
                cout << "Numbers that add up to " << target << ": " << arr[i] << " and " << arr[j] << endl;
                return 0;
            }
        }
    }

    cout << "No two numbers found that add up to " << target << "." << endl;

    return 0;
}`,
        java: `// Java code
public class TwoSum {
    public static void main(String[] args) {
        int[] arr = {2, 7, 11, 15};
        int target = 9;

        for (int i = 0; i < arr.length - 1; i++) {
            for (int j = i + 1; j < arr.length; j++) {
                if (arr[i] + arr[j] == target) {
                    System.out.println("Numbers that add up to " + target + ": " + arr[i] + " and " + arr[j]);
                    return;
                }
            }
        }

        System.out.println("No two numbers found that add up to " + target + ".");
    }
}`,
        
      };
      const code8 = {
        c: `// C code
#include <stdio.h>

int main() {
    int arr1[] = {1, 2, 3, 4, 5};
    int arr2[] = {3, 4, 5, 6, 7};
    int n1 = sizeof(arr1) / sizeof(arr1[0]);
    int n2 = sizeof(arr2) / sizeof(arr2[0]);

    int set[1000] = {0};  // Assuming a maximum element value of 1000

    printf("Common elements in the two arrays: ");
    for (int i = 0; i < n1; i++) {
        set[arr1[i]] = 1;
    }
    for (int i = 0; i < n2; i++) {
        if (set[arr2[i]] == 1) {
            printf("%d ", arr2[i]);
            set[arr2[i]] = 0;  // Mark as printed
        }
    }
    printf("\\n");

    return 0;
}`,
        cpp: `// C++ code
#include <iostream>
#include <unordered_set>
using namespace std;

int main() {
    int arr1[] = {1, 2, 3, 4, 5};
    int arr2[] = {3, 4, 5, 6, 7};
    int n1 = sizeof(arr1) / sizeof(arr1[0]);
    int n2 = sizeof(arr2) / sizeof(arr2[0]);
    unordered_set<int> set;

    cout << "Common elements in the two arrays: ";
    for (int i = 0; i < n1; i++) {
        set.insert(arr1[i]);
    }
    for (int i = 0; i < n2; i++) {
        if (set.find(arr2[i]) != set.end()) {
            cout << arr2[i] << " ";
            set.erase(arr2[i]);  // Remove to avoid duplicates
        }
    }
    cout << endl;

    return 0;
}`,
        java: `// Java code
import java.util.HashSet;
import java.util.Set;

public class CommonElements {
    public static void main(String[] args) {
        int arr1[] = {1, 2, 3, 4, 5};
        int arr2[] = {3, 4, 5, 6, 7};
        int n1 = arr1.length;
        int n2 = arr2.length;
        Set<Integer> set = new HashSet<>();

        System.out.print("Common elements in the two arrays: ");
        for (int i = 0; i < n1; i++) {
            set.add(arr1[i]);
        }
        for (int i = 0; i < n2; i++) {
            if (set.contains(arr2[i])) {
                System.out.print(arr2[i] + " ");
                set.remove(arr2[i]);  // Remove to avoid duplicates
            }
        }
        System.out.println();
    }
}`,
        
      };
     
      const code9 = {
        c: `// C code
#include <stdio.h>
#include <stdbool.h>

void printUnion(int arr1[], int arr2[], int m, int n) {
    bool hash[1000] = {false};  // Assuming a maximum element value of 1000

    printf("Union of the two arrays: ");

    for (int i = 0; i < m; i++) {
        if (!hash[arr1[i]]) {
            printf("%d ", arr1[i]);
            hash[arr1[i]] = true;
        }
    }

    for (int i = 0; i < n; i++) {
        if (!hash[arr2[i]]) {
            printf("%d ", arr2[i]);
            hash[arr2[i]] = true;
        }
    }

    printf("\\n");
}

int main() {
    int arr1[] = {1, 2, 3, 4, 5};
    int arr2[] = {3, 4, 5, 6, 7};
    int m = sizeof(arr1) / sizeof(arr1[0]);
    int n = sizeof(arr2) / sizeof(arr2[0]);

    printUnion(arr1, arr2, m, n);

    return 0;
}`,
        cpp: `// C++ code
#include <iostream>
#include <unordered_set>
using namespace std;

void printUnion(int arr1[], int arr2[], int m, int n) {
    unordered_set<int> uniqueElements;

    cout << "Union of the two arrays: ";

    for (int i = 0; i < m; i++) {
        uniqueElements.insert(arr1[i]);
    }

    for (int i = 0; i < n; i++) {
        uniqueElements.insert(arr2[i]);
    }

    for (const int& element : uniqueElements) {
        cout << element << " ";
    }

    cout << endl;
}

int main() {
    int arr1[] = {1, 2, 3, 4, 5};
    int arr2[] = {3, 4, 5, 6, 7};
    int m = sizeof(arr1) / sizeof(arr1[0]);
    int n = sizeof(arr2) / sizeof(arr2[0]);

    printUnion(arr1, arr2, m, n);

    return 0;
}`,
        java: `// Java code
import java.util.HashSet;
import java.util.Set;

public class ArrayUnion {
    public static void main(String[] args) {
        int arr1[] = {1, 2, 3, 4, 5};
        int arr2[] = {3, 4, 5, 6, 7};
        int m = arr1.length;
        int n = arr2.length;

        Set<Integer> uniqueElements = new HashSet<>();

        System.out.print("Union of the two arrays: ");

        for (int i = 0; i < m; i++) {
            uniqueElements.add(arr1[i]);
        }

        for (int i = 0; i < n; i++) {
            uniqueElements.add(arr2[i]);
        }

        for (int element : uniqueElements) {
            System.out.print(element + " ");
        }

        System.out.println();
    }
}`
};
      const code10 = {
        c: `// C code
#include <stdio.h>

int main() {
    int multiArray[3][3] = {{1, 2, 3}, {4, 5, 6}, {7, 8, 9}};
    int flattenedArray[9];
    int index = 0;

    for (int i = 0; i < 3; i++) {
        for (int j = 0; j < 3; j++) {
            flattenedArray[index] = multiArray[i][j];
            index++;
        }
    }

    printf("Flattened array: ");
    for (int i = 0; i < 9; i++) {
        printf("%d ", flattenedArray[i]);
    }
    printf("\\n");

    return 0;
}`,
        cpp: `// C++ code
#include <iostream>
using namespace std;

int main() {
    int multiArray[3][3] = {{1, 2, 3}, {4, 5, 6}, {7, 8, 9}};
    int flattenedArray[9];
    int index = 0;

    for (int i = 0; i < 3; i++) {
        for (int j = 0; j < 3; j++) {
            flattenedArray[index] = multiArray[i][j];
            index++;
        }
    }

    cout << "Flattened array: ";
    for (int i = 0; i < 9; i++) {
        cout << flattenedArray[i] << " ";
    }
    cout << endl;

    return 0;
}`,
        java: `// Java code
public class FlattenArray {
    public static void main(String[] args) {
        int[][] multiArray = {{1, 2, 3}, {4, 5, 6}, {7, 8, 9}};
        int[] flattenedArray = new int[9];
        int index = 0;

        for (int i = 0; i < 3; i++) {
            for (int j = 0; j < 3; j++) {
                flattenedArray[index] = multiArray[i][j];
                index++;
            }
        }

        System.out.print("Flattened array: ");
        for (int i = 0; i < 9; i++) {
            System.out.print(flattenedArray[i] + " ");
        }
        System.out.println();
    }
}`,};
const code11 = {
    c: `// C code
#include <stdio.h>

int main() {
    int n;
    printf("Enter the number of Fibonacci terms to generate: ");
    scanf("%d", &n);

    if (n <= 0) {
        printf("Invalid input. Please enter a positive number.\\n");
        return 1;
    }

    unsigned long long fib[n];
    fib[0] = 0;
    fib[1] = 1;

    printf("Fibonacci sequence up to %d terms: ", n);
    printf("%llu %llu ", fib[0], fib[1]);

    for (int i = 2; i < n; i++) {
        fib[i] = fib[i - 1] + fib[i - 2];
        printf("%llu ", fib[i]);
    }
    printf("\\n");

    return 0;
}`,
    cpp: `// C++ code
#include <iostream>
using namespace std;

int main() {
    int n;
    cout << "Enter the number of Fibonacci terms to generate: ";
    cin >> n;

    if (n <= 0) {
        cout << "Invalid input. Please enter a positive number." << endl;
        return 1;
    }

    unsigned long long fib[n];
    fib[0] = 0;
    fib[1] = 1;

    cout << "Fibonacci sequence up to " << n << " terms: ";
    cout << fib[0] << " " << fib[1] << " ";

    for (int i = 2; i < n; i++) {
        fib[i] = fib[i - 1] + fib[i - 2];
        cout << fib[i] << " ";
    }
    cout << endl;

    return 0;
}`,
    java: `// Java code
import java.util.Scanner;

public class FibonacciArray {
    public static void main(String[] args) {
        Scanner scanner = new Scanner(System.in);
        System.out.print("Enter the number of Fibonacci terms to generate: ");
        int n = scanner.nextInt();

        if (n <= 0) {
            System.out.println("Invalid input. Please enter a positive number.");
            return;
        }

        long[] fib = new long[n];
        fib[0] = 0;
        fib[1] = 1;

        System.out.print("Fibonacci sequence up to " + n + " terms: ");
        System.out.print(fib[0] + " " + fib[1] + " ");

        for (int i = 2; i < n; i++) {
            fib[i] = fib[i - 1] + fib[i - 2];
            System.out.print(fib[i] + " ");
        }
        System.out.println();
    }
}`
  };
      
      

  const [questions, setQuestions] = useState([
    {
      question: "Q1: Write a program to find the sum of elements in an array.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions1, setQuestions1] = useState([
    {
      question: "Q2: Write a program to find the average of elements in an array.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions2, setQuestions2] = useState([
    {
      question: "Q3: Write a program to find the largest element in an array.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions3, setQuestions3] = useState([
    {
      question: "Q4: Reverse the order of elements in an array.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions4, setQuestions4] = useState([
    {
      question: "Q5: Implement sorting algorithm to sort an array in ascending or descending order.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions5, setQuestions5] = useState([
    {
      question: "Q6: Remove duplicate elements from an array, leaving only unique elements.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions6, setQuestions6] = useState([
    {
      question: "Q7: Write a program to search for a specific element in an array and print its index or occurrence count on screen. (Hint: You can use for loop and conditional statement for searching).",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions7, setQuestions7] = useState([
    {
      question: "Q8: Given an array and a target sum, find two numbers that add up to the target sum.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions8, setQuestions8] = useState([
    {
      question: "Q9: Find and print common elements in two arrays.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions9, setQuestions9] = useState([
    {
      question: "Q10: Find the union of two arrays",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions10, setQuestions10] = useState([
    {
      question: "Q11: Flatten a multi-dimensional array into a single-dimensional array.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions11, setQuestions11] = useState([
    {
      question: "Q12: Generate the Fibonacci series up to a given number using an array.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  

  const toggleAccordion = (index, questions, setQuestions) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].isOpen = !updatedQuestions[index].isOpen;
    setQuestions(updatedQuestions);
  };

  const changeCodeLanguage = (index, language, questions, setQuestions) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].codeLanguage = language;
    setQuestions(updatedQuestions);
  };

  

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };
  const divRef = useRef(null);
  return (
    <div className="testing">
      <Sidebar>
        <div className="containera">
          <div className="ch-content">
            <h2 className="ui header">
            <Link to="/ques10">
              <button
                className="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
              </Link>
              <Link to="/ques12">
                <button
                  className="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>
            <div className="main-body ui">
              <h2 className="ui left floated header" style={{ color: "#001C30" }}>
                 Arrays:
              </h2>
              <div className="ui clearing divider"></div>
              <CodeAccordion
                questions={questions}
                code={code}
                toggleAccordion={(index) => toggleAccordion(index, questions, setQuestions)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions, setQuestions)
                }
              /><br/>
              <CodeAccordion
                questions={questions1}
                code={code1}
                toggleAccordion={(index) => toggleAccordion(index, questions1, setQuestions1)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions1, setQuestions1)
                }
              /><br/>
              <CodeAccordion
                questions={questions2}
                code={code2}
                toggleAccordion={(index) => toggleAccordion(index, questions2, setQuestions2)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions2, setQuestions2)
                }
              /><br/>
              <CodeAccordion
                questions={questions3}
                code={code3}
                toggleAccordion={(index) => toggleAccordion(index, questions3, setQuestions3)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions3, setQuestions3)
                }
              /><br/>
              <CodeAccordion
                questions={questions4}
                code={code4}
                toggleAccordion={(index) => toggleAccordion(index, questions4, setQuestions4)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions4, setQuestions4)
                }
              /><br/>
              <CodeAccordion
                questions={questions5}
                code={code5}
                toggleAccordion={(index) => toggleAccordion(index, questions5, setQuestions5)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions5, setQuestions5)
                }
              /><br/>
              <CodeAccordion
                questions={questions6}
                code={code6}
                toggleAccordion={(index) => toggleAccordion(index, questions6, setQuestions6)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions6, setQuestions6)
                }
              /><br/>
              <CodeAccordion
                questions={questions7}
                code={code7}
                toggleAccordion={(index) => toggleAccordion(index, questions7, setQuestions7)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions7, setQuestions7)
                }
              /><br/>
              <CodeAccordion
                questions={questions8}
                code={code8}
                toggleAccordion={(index) => toggleAccordion(index, questions8, setQuestions8)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions8, setQuestions8)
                }
              /><br/>
              <CodeAccordion
                questions={questions9}
                code={code9}
                toggleAccordion={(index) => toggleAccordion(index, questions9, setQuestions9)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions9, setQuestions9)
                }
              /><br/>
              <CodeAccordion
                questions={questions10}
                code={code10}
                toggleAccordion={(index) => toggleAccordion(index, questions10, setQuestions10)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions10, setQuestions10)
                }
              /><br/>
              <CodeAccordion
                questions={questions11}
                code={code11}
                toggleAccordion={(index) => toggleAccordion(index, questions11, setQuestions11)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions11, setQuestions11)
                }
              /><br/>
              
            </div>
            <Link to="/ques10">
              <button
                className="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
              </Link>
              <Link to="/ques12">
                <button
                  className="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default Question11;
