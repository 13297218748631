import { useEffect } from "react";
// import './styles.css';
import Footer from "./Components/Home/Footer.jsx";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  return (
    <>
      <div className="bg-gray-100 pt-24 md:pt-28 pl-5 md:pl-28 pr-5 md:pr-28 pb-5 md:pb-24">
        <h1 className="text-4xl font-bold  mb-8 justify-items text-left underline">
          Privacy Policy
        </h1>
        <h1>Privacy Policy</h1>
        <p>Last updated: January 15, 2024</p>
        <p>
          This Privacy Policy describes Our policies and procedures on the
          collection, disclosure and use of Your information when You use the
          Service and tells You about your privacy rights and how the law
          protects You.
        </p>
        <p>
          We use Your Personal data to improve and provide the Service. By using
          the Service, you agree to our Privacy Policy.
        </p>
        <br />
        <h2 className="text-3xl font-bold  mb-8 justify-items text-left underline">
          Interpretation and Definitions
        </h2>
        <h3 className="text-2xl font-bold  mb-8 justify-items text-left underline">
          Interpretation
        </h3>
        <p>
          The words of which the first letter is capital have meanings defined
          under the following conditions.
        </p>
        <br />
        <h3 className="text-2xl font-bold  mb-8 justify-items text-left underline">
          Definitions
        </h3>
        <p>For the purposes of this Privacy Policy:</p>
        <ul>
          <li>
            <p>
              <strong>Affiliate</strong> means an entity which controls, is
              controlled by or is under common control with a party, where
              &quot;control&quot; means ownership of 50% or more of the shares,
              equity interest or other securities entitled to vote for election
              of directors or the other managing authority.
            </p>
          </li>
          <li>
            <p>
              <strong>Company</strong> (referred to as either &quot;the
              Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot;
              in this Agreement) refers to Start Coding Bootcamp Now!.
            </p>
          </li>
          <li>
            <p>
              <strong>Cookies</strong> are the small files that a website place
              on Your computer, mobile device or any other device, containing
              the details of Your browsing history on that website among its
              many uses.
            </p>
          </li>
          <li>
            <p>
              <strong>Country</strong> refers to: Pakistan
            </p>
          </li>
          <li>
            <p>
              <strong>Device</strong> means that any device that can access the
              Service such as a computer or a smart phone.
            </p>
          </li>
          <li>
            <p>
              <strong>Personal Data</strong> refers to any information relating
              to an identifiable or identified person.
            </p>
          </li>
          <li>
            <p>
              <strong>Service</strong> refers to the Website.
            </p>
          </li>
          <li>
            <p>
              <strong>Service Provider</strong> means any natural or legal
              individual who processes the data on behalf of the Company. It
              refers to the third-party companies or individuals employed by the
              Company to facilitate the Services, to provide the Services on
              behalf of the Company, to perform services related to the Service
              or help the Company in analyzing how is the Service used.
            </p>
          </li>
          <li>
            <p>
              <strong>Usage Data</strong> refers to the data collected
              automatically, either generated by the use of the Service or from
              the Service infrastructure itself (for example, the duration of a
              page visit).
            </p>
          </li>
          <li>
            <p>
              <strong>Website</strong> refers to Start Coding Bootcamp Now!,
              accessible from{" "}
              <a
                href="https://www.codeprogs.com"
                rel="external nofollow noopener"
                target="_blank"
              >
                https://www.codeprogs.com
              </a>
            </p>
          </li>
          <li>
            <p>
              <strong>You</strong> means that the individual accessing or using
              the Service, or the company, or other legal entity on behalf of
              which such individual is accessing or using the Service, as
              applicable.
            </p>
          </li>
        </ul>
        <br />
        <h2 className="text-3xl font-bold  mb-8 justify-items text-left underline">
          Collecting and Using Your Personal Data
        </h2>
        <h3 className="text-2xl font-bold  mb-8 justify-items text-left underline">
          Types of Data Collected
        </h3>
        <h4>Personal Data</h4>
        <p>
          While using Our Services, We may ask You to provide Us certain
          personal information that can be used to contact or identify You.
          Personally identifiable information may include, but it is not limited
          to:
        </p>
        <ul>
          <li>Usage Data</li>
        </ul>
        <h4>Usage Data</h4>
        <p>Usage Data is collected automatically when using the Service.</p>
        <p>
          Usage Data may include information such as Internet Protocol address
          (e.g. IP address), browser type, browser version of Your device, the
          pages of our Service that You visit, the time and date of Your visit,
          the time spent on those pages, unique device identifiers and other
          diagnostic data.
        </p>
        <p>
          When You access the Service using a mobile device, We may collect
          certain information automatically, including, but not limited to, the
          type of mobile device You use, Your mobile device the IP address of
          Your mobile device, unique ID, Your mobile operating system, the type
          of mobile Internet browser You use, unique device identifiers and
          other diagnostic data.
        </p>

        {/* <ul>
          
          <li>
            <strong>Web Beacons.</strong> Certain sections of our Service and
            our emails may contain small electronic files known as web beacons
            (also referred to as clear gifs, pixel tags, and single-pixel gifs)
            that permit the Company, for example, to count users who have
            visited those pages or opened an email and for other related website
            statistics (for example, recording the popularity of a certain
            section and verifying system and server integrity).
          </li>
        </ul> */}

        <ul>
          <li>
            <p>
              <strong>Necessary / Essential Cookies</strong>
            </p>
            <p>Type: Session Cookies</p>
            <p>Administered by: Us</p>
            <p>
              Purpose: These Cookies are essential to enable You to use some of
              its features and provide You with the services available through the
              Website. They help to authenticate users and prevent the
              fraudulent use of user accounts. Without these Cookies, we can not
              provide the services You have asked for and We only use these
              Cookies to provide You with those services.
            </p>
          </li>
          <li>
            <p>
              <strong>Cookies Policy / Notice Acceptance Cookies</strong>
            </p>
            <p>Type: Persistent Cookies</p>
            <p>Administered by: Us</p>
            <p>
              Purpose: These Cookies checks if users have accepted the use of
              cookies on the Website.
            </p>
          </li>
          <li>
            <p>
              <strong>Functionality Cookies</strong>
            </p>
            <p>Type: Persistent Cookies</p>
            <p>Administered by: Us</p>
            
          </li>
        </ul>
        
        <br />
       
        
        <h2 className="text-3xl font-bold  mb-8 justify-items text-left underline">
          Changes to this Privacy Policy
        </h2>
        <p>
          We may update Our Privacy Policy from time to time. We will notify You
          of any changes by posting the new Privacy Policy on this page.
        </p>
        <p>
          We will let You know via a prominent notice on Our
          Service or email, prior to the change becoming effective and update the
          &quot;Last updated&quot; date at the top of this Privacy Policy.
        </p>
        <p>
          We advise you to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy will be effective only when they are
          posted on this page.
        </p>
        <br />
        <h2 className="text-3xl font-bold  mb-8 justify-items text-left underline">
          Advertisements
        </h2>
        <p>
          We run ads on our website and generate revenue. Ads appearing on our
          website may be delivered to the usersby advertising partners.We does
          not cover the use of cookies by any advertiser.
        </p>
        <br />
        <h2 className="text-3xl font-bold  mb-8 justify-items text-left underline">
          Contact Us
        </h2>
        <p>
          If you have any questions about this Privacy Policy, You can contact
          us:
        </p>
        <ul>
          <li>By email: sajeelahmad@codeprogs.com</li>
        </ul>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
