import { useRef, useEffect } from "react";
// import "./Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Sidebar from "../CSideBar";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const CC13 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };
  const code1 = `#include <stdio.h>
#include <stdbool.h>

int main() {

  // Declaring boolean type variables
  bool isTrue = true;
  bool isFalse = false;
    
  // Printing the values stored in the variables
  printf("%d\\n", isTrue);
  printf("%d\\n", isFalse);

  return 0;
}`;

  const code2 = `#include <stdio.h>
#include <stdbool.h>

int main() {

  // Printing the results directly from the expressions 
  printf("%d\\n", 10>5); // It is true
  printf("%d\\n", 1>5);   //It is false
  return 0;
}`;

  const code3 = `#include <stdio.h>
#include <stdbool.h>

int main() {

  // Boolean expressions
  bool check1 = (10 == 10);    
  printf("%d\\n",check1);

  // Boolean expressions
  bool check2 = (10 < 4);    
  printf("%d\\n",check2);
  
  return 0;
}`;
  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/C/maths">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/C/if_else">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body-color">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Booleans in C:
              </h2>
              <div class="ui clearing divider"></div>
              <p>
                In C, boolean is not a distinct data type like in some other
                programming languages (e.g., C++, or Python). We use integers to
                represent boolean values in C. 1 represents true and 0
                represents false.
                <br />
                <br />
                Booleans can store one of the two values:
                <ul className="ui unordered list">
                  <li>Yes / No</li>
                  <li>True / False</li>
                  <li>1 / 0</li>
                </ul>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  stdbool.h Header:
                </h2>
                <div class="ui clearing divider"></div>
                <span>
                  In modern C (C99 and later), the stdbool.h header file is
                  available introducing a bool type in which there are two
                  constant values;
                  <ul className="ui unordered list">
                    <li>true</li>
                    <li>false</li>
                  </ul>
                  <p style={{ backgroundColor: "#FBEEAC", padding: "10px" }}>
                    This header file improves the clarity and reduce the chance
                    of errors related to using other data types like integers to
                    represent boolean values. If you are working in the
                    environment that supports C99 or later standards, it is
                    recommended to use stdbool.h library. But if you are working
                    in old environments, you can go with traditional integer
                    convention for boolean values.
                  </p>
                </span>
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>booleans.c</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="c" style={darcula}>
                      {code1}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
              </p>

              <span>
                Booleans are used in to apply certain checks. For example, we
                can say that if age &gt; 18, then print "You are adult". On the
                other hand if age &lt; 18 then print "You are under age". We
                will use them in control statements.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>booleans.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code2}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Booleans and Comparison Operators:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                Comparison operators are used to compare the data values stored
                in two variables. We can use booleans with comparison operators
                as comparison operators return true or false, we can store the
                result of comparison operation in a boolean variable.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>booleans.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code3}
                  </SyntaxHighlighter>
                </div>
              </div>
            </div>
            <br />
            <Link to="/C/maths">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/C/if_else">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default CC13;
