import { useRef, useEffect } from "react";
import "./Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Q17 from "../Questions/Q17";
import Sidebar from "../SideBar/Sidebar";
import Footer from "../Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const Ch17 = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  const divRef = useRef(null);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };
  const code1 = `datatype * pointerName = &variableName`;
  const code2 = `int a = 10;
cout<< &a;
//Sample output: 0x61febc.`;
  const code3 = `int a = 10;
cout<<*(&a)<<endl;
//output: 10
`;
  const code4 = `int a=10;
int *ptr = &a; 
`;
  const code5 = `#include<iostream>
using namespace std;

int main(){
    int a = 10;
    int *ptr = &a;
    cout<<ptr<<endl; 
    cout<<*(ptr) 
    //We can also change values at address
    *ptr = 20;
    cout<<*(ptr)<<endl; //20 will be output
}`;
  const code6 = `int arr[3] = { 1,2,3 };
int *ptr = &arr;
`;
  const code7 = `#include<iostream>
using namespace std;

int main(){
    int arr[3] = { 1,2,3 };
    int *ptr = &arr; 
    cout<<*ptr; 
    cout<<*(ptr + 1); //output will be 2
    cout<<*(ptr + 2);//output will be 3
    return 0;
}`;
  const code8 = `int a = 10;
int *ptr1 = &a;
int **ptr2 = &ptr1;
`;

  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/Cpp/struct">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/Cpp/functions">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Pointers:
              </h2>
              <div class="ui clearing divider"></div>
              <p>
                Pointers are the variables which store the address of other
                variables. Data type of pointer is same as that of the variable
                of which address it is storing.
              </p>
              <SyntaxHighlighter language="cpp" style={darcula}>
                {code1}
              </SyntaxHighlighter>
              <br />
              Before exploring examples, we need to know about “&” and “*”.{" "}
              <br />
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Address Of Operator:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                & is called the address of operator. It creates <b>reference</b>{" "}
                to a variable. When we put &-sign before a variable name, it
                gives us the address/reference of that variable in computer
                memory. The result we get is in hexadecimal form. As there is
                only one operand (variable), it is called unary operator.
              </span>{" "}
              <br />
              <SyntaxHighlighter language="cpp" style={darcula}>
                {code2}
              </SyntaxHighlighter>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Dereference Operator:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                "*" is called dereference operator. When we put * sign before a
                pointer variable, it dereferences it or we can say it gives us
                the data value at that address pointer is storing. struct is
                declared outside the main function.
              </span>
              <br />
              <br />
              <SyntaxHighlighter language="cpp" style={darcula}>
                {code3}
              </SyntaxHighlighter>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Declaring a pointer:
              </h2>
              <div class="ui clearing divider"></div>
              <SyntaxHighlighter language="cpp" style={darcula}>
                {code4}
              </SyntaxHighlighter>
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>pointer.cpp</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="cpp" style={darcula}>
                    {code5}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Pointers with Arrays:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                Syntax for declaring array pointer is same as int or any other
                pointer.
              </span>{" "}
              <br />
              <SyntaxHighlighter language="cpp" style={darcula}>
                {code6}
              </SyntaxHighlighter>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Accessing array members using pointers:
              </h2>
              <div class="ui clearing divider"></div>
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>pointer.cpp</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="cpp" style={darcula}>
                    {code7}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Pointer to Pointer:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                Pointers are variables which store the address of another
                variable with the same data type. Pointers can also store the
                address of another pointer (variable). These are called pointer
                to pointer.
              </span>
              <br />
              <SyntaxHighlighter language="cpp" style={darcula}>
                {code8}
              </SyntaxHighlighter>
              <br />
              <p style={{ backgroundColor: "#FBEEAC", padding: "10px" }}>
                <b>Note : </b>You can declare variables of type void which store
                address of variable with any data type.
              </p>
            </div>
            <br />
            <Link to="/Cpp/struct">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/Cpp/functions">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default Ch17;
