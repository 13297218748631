import { useRef, useEffect } from "react";
// import "./Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Sidebar from "../CSideBar";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const CC18 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };
  const code1 = `#include <stdio.h>
  
int main() {
  int i;
  
  for (i = 0; i < 5; i++) {
    if (i == 2) {
      break; // Exit the loop when i is 2
    }
    printf("%d\\n", i);
  }
    
  return 0;
}`;

  const code2 = `#include <stdio.h>
  
int main() {
  int i;
  
  for (i = 0; i < 5; i++) {
    if (i == 2) {
      continue; // Skip iteration when i is 2
    }
    printf("%d\\n", i);
  }
    
  return 0;
}`;

  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/C/while_loop">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/C/arrays">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Break Statement:
              </h2>
              <div class="ui clearing divider"></div>
              <p>
                The break statement is a control inflow statement which is used
                to terminate the prosecution of a loop if the provided condition
                remains true. Most commonly, it is used with loops and switch
                statements. When the break statement is encountered inside a
                loop, the program immediately exits that block, and the control
                moves out of the loop.
              </p>
              <br />
              <p style={{ backgroundColor: "#FBEEAC", padding: "15px" }}>
                <b>Note: </b>We have also used break statement in{" "}
                <Link to="/C/switch">Switch Statement</Link>. It helps us to
                move out of a current iteration in a loop.
              </p>
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>break.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code1}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Output:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                The output of this code will be 0 1. The integer type variable
                is declared and initialized with 0. Then the condition is
                checked, if i is less than 5, the loop will be executed. But
                when i becomes 2, the condition of if statement becomes true and
                control moves inside the if statement. Inside if statement break
                statement is encountered which cause the control to move out of
                the loop.
              </span>

              <br />
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Continue Statement:
              </h2>
              <div class="ui clearing divider"></div>
              <p>
                The continue statement is a control inflow statement which is
                used to skip the current iteration of a loop and continue with
                the next iteration if the provided condition is true. When the
                continue statement is encountered inside a loop, it will jump to
                the next iteration, ignoring the code within the loop for the
                current iteration.
              </p>
              <br />

              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>continue.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code2}
                  </SyntaxHighlighter>
                </div>
              </div>
            </div>
            <br />
            <h2 class="ui left floated header" style={{ color: "#001C30" }}>
              Output:
            </h2>
            <div class="ui clearing divider"></div>
            <span>
              The output of this code will be 0 1 3 4. The integer type variable
              is declared and initialized with 0. Then the condition is checked,
              if i is less than 5, the loop will be executed. But when i becomes
              2, the condition of if statement becomes true and control moves
              inside the if statement. Inside if statement continue statement is
              encountered which skip the current iteration of the loop. Then i
              becomes 3 and then 4. When it becomes 5 condition of for loop
              becomes false and loop is terminated.
            </span>
            <br />
            <br />
            <Link to="/C/while_loop">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/C/arrays">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default CC18;
