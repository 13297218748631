import React, { useState, useRef, useEffect } from "react";
import Sidebar from "./SideBar";
import "../../Components/SideBar/App.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const CodeAccordion = ({ questions, code, toggleAccordion, changeCodeLanguage }) => {
    const divRef = useRef(null);
    const copyContent = () => {
        const divContent = divRef.current.innerText;
        navigator.clipboard
          .writeText(divContent)
          .then(() => {
            toast.success("Content copied!", { position: "top-center" });
          })
          .catch((error) => {
            toast.error("Error copying content:", error, {
              position: "top-center",
            });
          });
      };
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };

  return (
    <div>
      {questions.map((item, index) => (
        <div key={index} className="border p-2 rounded-md">
          <div className="flex justify-between">
            <span className="text-lg font-semibold">
              {item.question}
            </span>
            <button
              className="bg-gray-200 px-2 py-1 rounded-md"
              onClick={() => toggleAccordion(index)}
            >
              {item.isOpen ? "-" : "+"}
            </button>
          </div>
          <div className={`mt-2 ${item.isOpen ? "block" : "hidden"}`}>
            <div className="mt-2 flex space-x-4">
              {Object.keys(code).map((language) => (
                <button
                  key={language}
                  onClick={() => changeCodeLanguage(index, language)}
                  className={`${
                    item.codeLanguage === language
                      ? "bg-blue-500 hover:bg-blue-600 text-white"
                      : "bg-gray-200 hover:bg-gray-300 text-gray-700"
                  } px-3 py-2 rounded-md transition duration-300 ease-in-out transform hover:scale-105`}
                >
                  {language}
                </button>
              ))}
            </div>
            <p className="text-gray-800">
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>output.cpp</b>
                  <i
                    className="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language={`${item.codeLanguage}`} style={darcula}>
                    {code[item.codeLanguage]}
                  </SyntaxHighlighter>
                </div>
              </div>
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

const Question13 = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
    const code = {
        c: `// C code
#include <stdio.h>

int factorial(int n) {
    if (n == 0) {
        return 1;
    } else {
        return n * factorial(n - 1);
    }
}

int main() {
    int number = 5;
    int result = factorial(number);
    printf("The factorial of %d is %d\\n", number, result);
    return 0;
}`,
        cpp: `// C++ code
#include <iostream>
using namespace std;

int factorial(int n) {
    if (n == 0) {
        return 1;
    } else {
        return n * factorial(n - 1);
    }
}

int main() {
    int number = 5;
    int result = factorial(number);
    cout << "The factorial of " << number << " is " << result << endl;
    return 0;
}`,
        java: `// Java code
public class Factorial {
    public static int factorial(int n) {
        if (n == 0) {
            return 1;
        } else {
            return n * factorial(n - 1);
        }
    }

    public static void main(String[] args) {
        int number = 5;
        int result = factorial(number);
        System.out.println("The factorial of " + number + " is " + result);
    }
}`,
        python: `# Python code
def factorial(n):
    if n == 0:
        return 1
    else:
        return n * factorial(n - 1)

number = 5
result = factorial(number)
print(f"The factorial of {number} is {result}")`,
    };
    const code1 = {
        c: `// C code
#include <stdio.h>
#include <stdbool.h>

bool isPrime(int n) {
    if (n <= 1) {
        return false;
    }
    if (n <= 3) {
        return true;
    }
    if (n % 2 == 0 || n % 3 == 0) {
        return false;
    }
    for (int i = 5; i * i <= n; i += 6) {
        if (n % i == 0 || n % (i + 2) == 0) {
            return false;
        }
    }
    return true;
}

int main() {
    int number = 17;
    if (isPrime(number)) {
        printf("%d is a prime number.\\n", number);
    } else {
        printf("%d is not a prime number.\\n", number);
    }
    return 0;
}`,
        cpp: `// C++ code
#include <iostream>
using namespace std;

bool isPrime(int n) {
    if (n <= 1) {
        return false;
    }
    if (n <= 3) {
        return true;
    }
    if (n % 2 == 0 || n % 3 == 0) {
        return false;
    }
    for (int i = 5; i * i <= n; i += 6) {
        if (n % i == 0 || n % (i + 2) == 0) {
            return false;
        }
    }
    return true;
}

int main() {
    int number = 17;
    if (isPrime(number)) {
        cout << number << " is a prime number." << endl;
    } else {
        cout << number << " is not a prime number." << endl;
    }
    return 0;
}`,
        java: `// Java code
public class PrimeChecker {
    public static boolean isPrime(int n) {
        if (n <= 1) {
            return false;
        }
        if (n <= 3) {
            return true;
        }
        if (n % 2 == 0 || n % 3 == 0) {
            return false;
        }
        for (int i = 5; i * i <= n; i += 6) {
            if (n % i == 0 || n % (i + 2) == 0) {
                return false;
            }
        }
        return true;
    }

    public static void main(String[] args) {
        int number = 17;
        if (isPrime(number)) {
            System.out.println(number + " is a prime number.");
        } else {
            System.out.println(number + " is not a prime number.");
        }
    }
}`,
        python: `# Python code
def is_prime(n):
    if n <= 1:
        return False
    if n <= 3:
        return True
    if n % 2 == 0 or n % 3 == 0:
        return False
    i = 5
    while i * i <= n:
        if n % i == 0 or n % (i + 2) == 0:
            return False
        i += 6
    return True

number = 17
if is_prime(number):
    print(f"{number} is a prime number.")
else:
    print(f"{number} is not a prime number.")`,
    };
    const code2 = {
        c: `// C code
#include <stdio.h>

int sumOfDigits(int n) {
    int sum = 0;
    while (n != 0) {
        sum += n % 10;
        n /= 10;
    }
    return sum;
}

int main() {
    int number = 12345;
    int result = sumOfDigits(number);
    printf("Sum of digits of %d is %d\\n", number, result);
    return 0;
}`,
        cpp: `// C++ code
#include <iostream>
using namespace std;

int sumOfDigits(int n) {
    int sum = 0;
    while (n != 0) {
        sum += n % 10;
        n /= 10;
    }
    return sum;
}

int main() {
    int number = 12345;
    int result = sumOfDigits(number);
    cout << "Sum of digits of " << number << " is " << result << endl;
    return 0;
}`,
        java: `// Java code
public class SumOfDigits {
    public static int sumOfDigits(int n) {
        int sum = 0;
        while (n != 0) {
            sum += n % 10;
            n /= 10;
        }
        return sum;
    }

    public static void main(String[] args) {
        int number = 12345;
        int result = sumOfDigits(number);
        System.out.println("Sum of digits of " + number + " is " + result);
    }
}`,
        python: `# Python code
def sum_of_digits(n):
    sum = 0
    while n != 0:
        sum += n % 10
        n //= 10
    return sum

number = 12345
result = sum_of_digits(number)
print(f"Sum of digits of {number} is {result}")`,
    };
    const code3 = {
        c: `// C code
#include <stdio.h>
#include <stdbool.h>
#include <string.h>

bool isPalindrome(const char *str) {
    int left = 0;
    int right = strlen(str) - 1;

    while (left < right) {
        if (str[left] != str[right]) {
            return false;
        }
        left++;
        right--;
    }
    return true;
}

int main() {
    const char *text = "racecar";
    if (isPalindrome(text)) {
        printf("%s is a palindrome.\\n", text);
    } else {
        printf("%s is not a palindrome.\\n", text);
    }
    return 0;
}`,
        cpp: `// C++ code
#include <iostream>
#include <string>
using namespace std;

bool isPalindrome(const string &str) {
    int left = 0;
    int right = str.length() - 1;

    while (left < right) {
        if (str[left] != str[right]) {
            return false;
        }
        left++;
        right--;
    }
    return true;
}

int main() {
    string text = "racecar";
    if (isPalindrome(text)) {
        cout << text << " is a palindrome." << endl;
    } else {
        cout << text << " is not a palindrome." << endl;
    }
    return 0;
}`,
        java: `// Java code
public class PalindromeChecker {
    public static boolean isPalindrome(String str) {
        int left = 0;
        int right = str.length() - 1;

        while (left < right) {
            if (str.charAt(left) != str.charAt(right)) {
                return false;
            }
            left++;
            right--;
        }
        return true;
    }

    public static void main(String[] args) {
        String text = "racecar";
        if (isPalindrome(text)) {
            System.out.println(text + " is a palindrome.");
        } else {
            System.out.println(text + " is not a palindrome.");
        }
    }
}`,
        python: `# Python code
def is_palindrome(text):
    left = 0
    right = len(text) - 1

    while left < right:
        if text[left] != text[right]:
            return False
        left += 1
        right -= 1
    return True

text = "racecar"
if is_palindrome(text):
    print(f"{text} is a palindrome.")
else:
    print(f"{text} is not a palindrome.")`,
    };
    const code4 = {
        c: `// C code
#include <stdio.h>

int findLargest(int arr[], int size) {
    int largest = arr[0];
    for (int i = 1; i < size; i++) {
        if (arr[i] > largest) {
            largest = arr[i];
        }
    }
    return largest;
}

int main() {
    int arr[] = {3, 8, 1, 6, 12, 4};
    int size = sizeof(arr) / sizeof(arr[0]);

    int largest = findLargest(arr, size);
    printf("The largest element in the array is: %d\\n", largest);

    return 0;
}`,
        cpp: `// C++ code
#include <iostream>
using namespace std;

int findLargest(int arr[], int size) {
    int largest = arr[0];
    for (int i = 1; i < size; i++) {
        if (arr[i] > largest) {
            largest = arr[i];
        }
    }
    return largest;
}

int main() {
    int arr[] = {3, 8, 1, 6, 12, 4};
    int size = sizeof(arr) / sizeof(arr[0]);

    int largest = findLargest(arr, size);
    cout << "The largest element in the array is: " << largest << endl;

    return 0;
}`,
        java: `// Java code
public class LargestElementFinder {
    public static int findLargest(int[] arr) {
        int largest = arr[0];
        for (int i = 1; i < arr.length; i++) {
            if (arr[i] > largest) {
                largest = arr[i];
            }
        }
        return largest;
    }

    public static void main(String[] args) {
        int[] arr = {3, 8, 1, 6, 12, 4};
        int largest = findLargest(arr);
        System.out.println("The largest element in the array is: " + largest);
    }
}`,
        python: `# Python code
def find_largest(arr):
    largest = arr[0]
    for num in arr:
        if num > largest:
            largest = num
    return largest

arr = [3, 8, 1, 6, 12, 4]
largest = find_largest(arr)
print("The largest element in the list is:", largest)`,
    };
    const code5 = {
        c: `// C code
#include <stdio.h>
#include <string.h>

void reverseString(char *str) {
    int left = 0;
    int right = strlen(str) - 1;
    while (left < right) {
        char temp = str[left];
        str[left] = str[right];
        str[right] = temp;
        left++;
        right--;
    }
}

int main() {
    char str[] = "Hello, World!";
    reverseString(str);
    printf("Reversed string: %s\\n", str);
    return 0;
}`,
        cpp: `// C++ code
#include <iostream>
#include <string>
using namespace std;

void reverseString(string &str) {
    int left = 0;
    int right = str.length() - 1;
    while (left < right) {
        char temp = str[left];
        str[left] = str[right];
        str[right] = temp;
        left++;
        right--;
    }
}

int main() {
    string str = "Hello, World!";
    reverseString(str);
    cout << "Reversed string: " << str << endl;
    return 0;
}`,
        java: `// Java code
public class StringReverser {
    public static String reverseString(String str) {
        StringBuilder reversed = new StringBuilder();
        for (int i = str.length() - 1; i >= 0; i--) {
            reversed.append(str.charAt(i));
        }
        return reversed.toString();
    }

    public static void main(String[] args) {
        String str = "Hello, World!";
        String reversedStr = reverseString(str);
        System.out.println("Reversed string: " + reversedStr);
    }
}`,
        python: `# Python code
def reverse_string(s):
    return s[::-1]

string = "Hello, World!"
reversed_string = reverse_string(string)
print("Reversed string:", reversed_string)`,
    };
    const code6 = {
        c: `// C code
#include <stdio.h>

double celsiusToFahrenheit(double celsius) {
    return (celsius * 9/5) + 32;
}

double fahrenheitToCelsius(double fahrenheit) {
    return (fahrenheit - 32) * 5/9;
}

int main() {
    double celsius = 25.0;
    double fahrenheit = celsiusToFahrenheit(celsius);
    printf("%.2f°C is equal to %.2f°F\\n", celsius, fahrenheit);

    fahrenheit = 77.0;
    celsius = fahrenheitToCelsius(fahrenheit);
    printf("%.2f°F is equal to %.2f°C\\n", fahrenheit, celsius);

    return 0;
}`,
        cpp: `// C++ code
#include <iostream>
using namespace std;

double celsiusToFahrenheit(double celsius) {
    return (celsius * 9/5) + 32;
}

double fahrenheitToCelsius(double fahrenheit) {
    return (fahrenheit - 32) * 5/9;
}

int main() {
    double celsius = 25.0;
    double fahrenheit = celsiusToFahrenheit(celsius);
    cout << celsius << "°C is equal to " << fahrenheit << "°F" << endl;

    fahrenheit = 77.0;
    celsius = fahrenheitToCelsius(fahrenheit);
    cout << fahrenheit << "°F is equal to " << celsius << "°C" << endl;

    return 0;
}`,
        java: `// Java code
public class TemperatureConverter {
    public static double celsiusToFahrenheit(double celsius) {
        return (celsius * 9/5) + 32;
    }

    public static double fahrenheitToCelsius(double fahrenheit) {
        return (fahrenheit - 32) * 5/9;
    }

    public static void main(String[] args) {
        double celsius = 25.0;
        double fahrenheit = celsiusToFahrenheit(celsius);
        System.out.println(celsius + "°C is equal to " + fahrenheit + "°F");

        fahrenheit = 77.0;
        celsius = fahrenheitToCelsius(fahrenheit);
        System.out.println(fahrenheit + "°F is equal to " + celsius + "°C");
    }
}`,
        python: `# Python code
def celsius_to_fahrenheit(celsius):
    return (celsius * 9/5) + 32

def fahrenheit_to_celsius(fahrenheit):
    return (fahrenheit - 32) * 5/9

celsius = 25.0
fahrenheit = celsius_to_fahrenheit(celsius)
print(f"{celsius}°C is equal to {fahrenheit}°F")

fahrenheit = 77.0
celsius = fahrenheit_to_celsius(fahrenheit)
print(f"{fahrenheit}°F is equal to {celsius}°C")`,
    };
    const code7 = {
        c: `// C code
#include <stdio.h>

double power(double base, int exponent) {
    double result = 1.0;
    while (exponent > 0) {
        result *= base;
        exponent--;
    }
    return result;
}

int main() {
    double base = 2.0;
    int exponent = 3;
    double result = power(base, exponent);
    printf("%.2f^%d = %.2f\\n", base, exponent, result);
    return 0;
}`,
        cpp: `// C++ code
#include <iostream>
using namespace std;

double power(double base, int exponent) {
    double result = 1.0;
    while (exponent > 0) {
        result *= base;
        exponent--;
    }
    return result;
}

int main() {
    double base = 2.0;
    int exponent = 3;
    double result = power(base, exponent);
    cout << base << "^" << exponent << " = " << result << endl;
    return 0;
}`,
        java: `// Java code
public class PowerCalculator {
    public static double power(double base, int exponent) {
        double result = 1.0;
        while (exponent > 0) {
            result *= base;
            exponent--;
        }
        return result;
    }

    public static void main(String[] args) {
        double base = 2.0;
        int exponent = 3;
        double result = power(base, exponent);
        System.out.println(base + "^" + exponent + " = " + result);
    }
}`,
        python: `# Python code
def power(base, exponent):
    result = 1.0
    while exponent > 0:
        result *= base
        exponent -= 1
    return result

base = 2.0
exponent = 3
result = power(base, exponent)
print(f"{base}^{exponent} = {result}")`,
    };
    const code8 = {
        c: `// C code
#include <stdio.h>

int gcd(int a, int b) {
    if (b == 0) {
        return a;
    }
    return gcd(b, a % b);
}

int main() {
    int num1 = 48;
    int num2 = 18;
    int result = gcd(num1, num2);
    printf("GCD of %d and %d is %d\\n", num1, num2, result);
    return 0;
}`,
        cpp: `// C++ code
#include <iostream>
using namespace std;

int gcd(int a, int b) {
    if (b == 0) {
        return a;
    }
    return gcd(b, a % b);
}

int main() {
    int num1 = 48;
    int num2 = 18;
    int result = gcd(num1, num2);
    cout << "GCD of " << num1 << " and " << num2 << " is " << result << endl;
    return 0;
}`,
        java: `// Java code
public class GCDCalculator {
    public static int gcd(int a, int b) {
        if (b == 0) {
            return a;
        }
        return gcd(b, a % b);
    }

    public static void main(String[] args) {
        int num1 = 48;
        int num2 = 18;
        int result = gcd(num1, num2);
        System.out.println("GCD of " + num1 + " and " + num2 + " is " + result);
    }
}`,
        python: `# Python code
def gcd(a, b):
    if b == 0:
        return a
    return gcd(b, a % b)

num1 = 48
num2 = 18
result = gcd(num1, num2)
print(f"GCD of {num1} and {num2} is {result}")`,
    };
    const code9 = {
        c: `// C code
#include <stdio.h>
#include <stdbool.h>
#include <string.h>

int countWords(const char *str) {
    int wordCount = 0;
    bool inWord = false;

    for (int i = 0; str[i] != '\\0'; i++) {
        if (str[i] == ' ' || str[i] == '\\t' || str[i] == '\\n') {
            inWord = false;
        } else if (!inWord) {
            inWord = true;
            wordCount++;
        }
    }

    return wordCount;
}

int main() {
    const char *text = "This is a sample sentence.";
    int result = countWords(text);
    printf("Number of words: %d\\n", result);
    return 0;
}`,
        cpp: `// C++ code
#include <iostream>
#include <string>
#include <sstream>
using namespace std;

int countWords(const string &str) {
    istringstream stream(str);
    int wordCount = 0;
    string word;

    while (stream >> word) {
        wordCount++;
    }

    return wordCount;
}

int main() {
    string text = "This is a sample sentence.";
    int result = countWords(text);
    cout << "Number of words: " << result << endl;
    return 0;
}`,
        java: `// Java code
public class WordCounter {
    public static int countWords(String str) {
        String[] words = str.split("\\s+");
        return words.length;
    }

    public static void main(String[] args) {
        String text = "This is a sample sentence.";
        int result = countWords(text);
        System.out.println("Number of words: " + result);
    }
}`,
        python: `# Python code
def count_words(text):
    words = text.split()
    return len(words)

text = "This is a sample sentence."
result = count_words(text)
print("Number of words:", result)`,
    };


  const [questions, setQuestions] = useState([
    {
      question: "Q1: Write a function to find the factorial of the given number using loop outside the main function. (You have to call the function inside the main function).",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions1, setQuestions1] = useState([
    {
      question: "Q2: Create a function to check if a given number is prime.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions2, setQuestions2] = useState([
    {
      question: "Q3: Implement a function to calculate the sum of digits of a number.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions3, setQuestions3] = useState([
    {
      question: "Q4: Write a function to check if a string is a palindrome.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions4, setQuestions4] = useState([
    {
      question: "Q5: Develop a function to find the largest element in a list.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions5, setQuestions5] = useState([
    {
      question: "Q6: Create a function that returns the reverse of a string.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions6, setQuestions6] = useState([
    {
      question: "Q7: Implement a function to convert Celsius to Fahrenheit and vice versa.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions7, setQuestions7] = useState([
    {
      question: "Q8: Write a function to calculate the power of a number.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions8, setQuestions8] = useState([
    {
      question: "Q9: Develop a function to find the GCD (Greatest Common Divisor) of two numbers.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions9, setQuestions9] = useState([
    {
      question: "Q10: Create a function to count the number of words in a string.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  

  const toggleAccordion = (index, questions, setQuestions) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].isOpen = !updatedQuestions[index].isOpen;
    setQuestions(updatedQuestions);
  };

  const changeCodeLanguage = (index, language, questions, setQuestions) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].codeLanguage = language;
    setQuestions(updatedQuestions);
  };

  

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };
  const divRef = useRef(null);
  return (
    <div className="testing">
      <Sidebar>
        <div className="containera">
          <div className="ch-content">
            <h2 className="ui header">
            <Link to="/ques12">
              <button
                className="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
              </Link>
              <Link to="/ques14">
                <button
                  className="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>
            <div className="main-body ui">
              <h2 className="ui left floated header" style={{ color: "#001C30" }}>
                Functions / Methods:
              </h2>
              <div className="ui clearing divider"></div>
              <CodeAccordion
                questions={questions}
                code={code}
                toggleAccordion={(index) => toggleAccordion(index, questions, setQuestions)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions, setQuestions)
                }
              /><br/>
              <CodeAccordion
                questions={questions1}
                code={code1}
                toggleAccordion={(index) => toggleAccordion(index, questions1, setQuestions1)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions1, setQuestions1)
                }
              /><br/>
              <CodeAccordion
                questions={questions2}
                code={code2}
                toggleAccordion={(index) => toggleAccordion(index, questions2, setQuestions2)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions2, setQuestions2)
                }
              /><br/>
              <CodeAccordion
                questions={questions3}
                code={code3}
                toggleAccordion={(index) => toggleAccordion(index, questions3, setQuestions3)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions3, setQuestions3)
                }
              /><br/>
              <CodeAccordion
                questions={questions4}
                code={code4}
                toggleAccordion={(index) => toggleAccordion(index, questions4, setQuestions4)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions4, setQuestions4)
                }
              /><br/>
              <CodeAccordion
                questions={questions5}
                code={code5}
                toggleAccordion={(index) => toggleAccordion(index, questions5, setQuestions5)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions5, setQuestions5)
                }
              /><br/>
              <CodeAccordion
                questions={questions6}
                code={code6}
                toggleAccordion={(index) => toggleAccordion(index, questions6, setQuestions6)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions6, setQuestions6)
                }
              /><br/>
              <CodeAccordion
                questions={questions7}
                code={code7}
                toggleAccordion={(index) => toggleAccordion(index, questions7, setQuestions7)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions7, setQuestions7)
                }
              /><br/>
              <CodeAccordion
                questions={questions8}
                code={code8}
                toggleAccordion={(index) => toggleAccordion(index, questions8, setQuestions8)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions8, setQuestions8)
                }
              /><br/>
              <CodeAccordion
                questions={questions9}
                code={code9}
                toggleAccordion={(index) => toggleAccordion(index, questions9, setQuestions9)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions9, setQuestions9)
                }
              /><br/>
              
            </div>
            <Link to="/ques12">
              <button
                className="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
              </Link>
              <Link to="/ques14">
                <button
                  className="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default Question13;
