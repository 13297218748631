import {useEffect} from 'react'
import ReactGA from "react-ga4"

import { Route, Routes } from 'react-router-dom';
import Ch1 from './Components/Chapters/Ch1';
import Ch2 from './Components/Chapters/Ch2.jsx'
import Ch3 from './Components/Chapters/Ch3.jsx'
import Ch4 from './Components/Chapters/Ch4.jsx'
import Ch5 from './Components/Chapters/Ch5.jsx'
import Ch6 from './Components/Chapters/Ch6.jsx'
import Ch7 from './Components/Chapters/Ch7.jsx'
import Ch8 from './Components/Chapters/Ch8.jsx'
import Ch9 from './Components/Chapters/Ch9.jsx'
import Ch10 from './Components/Chapters/Ch10.jsx'
import Ch11 from './Components/Chapters/Ch11.jsx';
import Ch12 from './Components/Chapters/Ch12.jsx';
import Ch13 from './Components/Chapters/Ch13.jsx';
import Ch14 from './Components/Chapters/Ch14.jsx';
import Ch15 from './Components/Chapters/Ch15.jsx';
import Ch16 from './Components/Chapters/Ch16.jsx';
import Ch17 from './Components/Chapters/Ch17.jsx';
import Ch18 from './Components/Chapters/Ch18.jsx';
import Ch19 from './Components/Chapters/Ch19.jsx';
import Ch20 from './Components/Chapters/Ch20.jsx';
import Ch21 from './Components/Chapters/Ch21.jsx';
import Ch22 from './Components/Chapters/Ch22.jsx'
import Ch23 from './Components/Chapters/Ch23.jsx'
import Ch24 from './Components/Chapters/Ch24.jsx'
import Ch25 from './Components/Chapters/Ch25.jsx'
import Ch26 from './Components/Chapters/Ch26.jsx'
import Ch27 from './Components/Chapters/Ch27.jsx'
import Ch28 from './Components/Chapters/Ch28.jsx'
import Ch29 from './Components/Chapters/Ch29.jsx'
import Ch30 from './Components/Chapters/Ch30.jsx'
import Ch31 from './Components/Chapters/Ch31.jsx'
import Ch32 from './Components/Chapters/Ch32.jsx'
import Ch33 from './Components/Chapters/Ch33.jsx'

import Quiz1 from './Components/Quizes/Quiz1.jsx'
import Quiz2 from './Components/Quizes/Quiz2.jsx'
import Quiz3 from './Components/Quizes/Quiz3.jsx'
import Quiz4 from './Components/Quizes/Quiz4.jsx'
import Quiz5 from './Components/Quizes/Quiz5.jsx'
import Quiz6 from './Components/Quizes/Quiz6.jsx'
import Quiz7 from './Components/Quizes/Quiz7.jsx'
import Quiz8 from './Components/Quizes/Quiz8.jsx'
import Quiz9 from './Components/Quizes/Quiz9.jsx'
import Quiz10 from './Components/Quizes/Quiz10.jsx'
import Quiz11 from './Components/Quizes/Quiz11.jsx'
import Quiz12 from './Components/Quizes/Quiz12.jsx'
import Quiz13 from './Components/Quizes/Quiz13.jsx'
import Quiz14 from './Components/Quizes/Quiz14.jsx'
import Quiz15 from './Components/Quizes/Quiz15.jsx'
import Quiz16 from './Components/Quizes/Quiz16.jsx'
import Quiz17 from './Components/Quizes/Quiz17.jsx'
import Quiz18 from './Components/Quizes/Quiz18.jsx'
import Quiz19 from './Components/Quizes/Quiz19.jsx'
import Quiz20 from './Components/Quizes/Quiz20.jsx'
import Quiz21 from './Components/Quizes/Quiz21.jsx'
import Quiz22 from './Components/Quizes/Quiz22.jsx'
import Quiz23 from './Components/Quizes/Quiz23.jsx'
import Quiz24 from './Components/Quizes/Quiz24.jsx'
import Quiz25 from './Components/Quizes/Quiz25.jsx'
import Quiz26 from './Components/Quizes/Quiz26.jsx'
import Quiz27 from './Components/Quizes/Quiz27.jsx'
import Quiz28 from './Components/Quizes/Quiz28.jsx'
import Quiz29 from './Components/Quizes/Quiz29.jsx'
import Quiz30 from './Components/Quizes/Quiz30.jsx'
import Quiz31 from './Components/Quizes/Quiz31.jsx'
import Quiz32 from './Components/Quizes/Quiz32.jsx'
import Quiz33 from './Components/Quizes/Quiz33.jsx'

import CppatGlance from './Components/CppatGlance/CppatGlance.jsx';
import Ch14b from './Components/Chapters/Ch14b.jsx'
import Ch18b from './Components/Chapters/Ch18b.jsx'
import Tutorials from './Components/Tutorials.jsx';
import ContactUs from './ContactUs.jsx';
import Disclaimer from './Disclaimer.jsx';
import AboutUs from './AboutUs.jsx';
import Cheatsheets from './Components/Cheatsheets.jsx';
import Quizes from './Quizes/Quizes.jsx';
import Home from './Components/Home/Home';


import CC1 from './C/Chapters/CC1.jsx';
import CC2 from './C/Chapters/CC2';
import CC3 from './C/Chapters/CC3';
import CC4 from './C/Chapters/CC4';
import CC5 from './C/Chapters/CC5';
import CC6 from './C/Chapters/CC6';
import CC7 from './C/Chapters/CC7';
import CC8 from './C/Chapters/CC8';
import CC9 from './C/Chapters/CC9';
import CC10 from './C/Chapters/CC10';
import CC11 from './C/Chapters/CC11';
import CC12 from './C/Chapters/CC12';
import CC13 from './C/Chapters/CC13';
import CC14 from './C/Chapters/CC14';
import CC15 from './C/Chapters/CC15';
import CC16 from './C/Chapters/CC16';
import CC17 from './C/Chapters/CC17';
import CC18 from './C/Chapters/CC18';
import CC19 from './C/Chapters/CC19';
import CC20 from './C/Chapters/CC20';
import CC21 from './C/Chapters/CC21';
import CC22 from './C/Chapters/CC22';
import CC23 from './C/Chapters/CC23';
import CatGlance from './C/CatGlance/CatGlance';

import CQuiz1 from './C/Quizes/Quiz1';
import CQuiz2 from './C/Quizes/Quiz2';
import CQuiz3 from './C/Quizes/Quiz3';
import CQuiz4 from './C/Quizes/Quiz4';
import CQuiz5 from './C/Quizes/Quiz5';
import CQuiz6 from './C/Quizes/Quiz6';
import CQuiz7 from './C/Quizes/Quiz7';
import CQuiz8 from './C/Quizes/Quiz8';
import CQuiz9 from './C/Quizes/Quiz9';
import CQuiz10 from './C/Quizes/Quiz10';
import CQuiz11 from './C/Quizes/Quiz11';
import CQuiz12 from './C/Quizes/Quiz12';
import CQuiz13 from './C/Quizes/Quiz13';
import CQuiz14 from './C/Quizes/Quiz14';
import CQuiz15 from './C/Quizes/Quiz15';
import CQuiz16 from './C/Quizes/Quiz16';
import CQuiz17 from './C/Quizes/Quiz17';
import CQuiz18 from './C/Quizes/Quiz18';
import CQuiz19 from './C/Quizes/Quiz19';
import CQuiz20 from './C/Quizes/Quiz20';
import CQuiz21 from './C/Quizes/Quiz21';
import CQuiz22 from './C/Quizes/Quiz22';
import CQuiz23 from './C/Quizes/Quiz23';

import Question1 from './C/Questions/Question1';
import Question2 from './C/Questions/Question2';
import Question3 from './C/Questions/Question3';
import Question4 from './C/Questions/Question4';
import Question5 from './C/Questions/Question5';
import Question6 from './C/Questions/Question6';
import Question7 from './C/Questions/Question7';
import Question8 from './C/Questions/Question8';
import Question9 from './C/Questions/Question9';
import Question10 from './C/Questions/Question10';
import Question11 from './C/Questions/Question11';
import Question12 from './C/Questions/Question12';
import Question13 from './C/Questions/Question13';
import Question14 from './C/Questions/Question14';
import Question15 from './C/Questions/Question15';
import Question16 from './C/Questions/Question16';
import Question17 from './C/Questions/Question17';
import Question18 from './C/Questions/Question18';
import Question19 from './C/Questions/Question19';
import Question20 from './C/Questions/Question20';
import Question21 from './C/Questions/Question21';
import Question22 from './C/Questions/Question22';

import JavaC1 from './Java/Chapters/JavaC1.jsx';
import JavaC2 from './Java/Chapters/JavaC2';
import JavaC3 from './Java/Chapters/JavaC3';
import JavaC4 from './Java/Chapters/JavaC4';
import JavaC5 from './Java/Chapters/JavaC5';
import JavaC6 from './Java/Chapters/JavaC6';
import JavaC7 from './Java/Chapters/JavaC7';
import JavaC8 from './Java/Chapters/JavaC8';
import JavaC9 from './Java/Chapters/JavaC9';
import JavaC10 from './Java/Chapters/JavaC10';
import JavaC11 from './Java/Chapters/JavaC11';
import JavaC12 from './Java/Chapters/JavaC12';
import JavaC13 from './Java/Chapters/JavaC13';
import JavaC14 from './Java/Chapters/JavaC14';
import JavaC15 from './Java/Chapters/JavaC15';
import JavaC16 from './Java/Chapters/JavaC16';
import JavaC17 from './Java/Chapters/JavaC17';
import JavaC18 from './Java/Chapters/JavaC18';
import JavaC19 from './Java/Chapters/JavaC19';
import JavaC20 from './Java/Chapters/JavaC20';
import JavaC21 from './Java/Chapters/JavaC21';
import JavaC22 from './Java/Chapters/JavaC22';
import JavaC23 from './Java/Chapters/JavaC23';
import JavaC24 from './Java/Chapters/JavaC24';
import JavaC25 from './Java/Chapters/JavaC25';
import JavaC26 from './Java/Chapters/JavaC26';
import JavaC27 from './Java/Chapters/JavaC27';
import JavaC28 from './Java/Chapters/JavaC28';
import JavaC29 from './Java/Chapters/JavaC29';
import JavaC30 from './Java/Chapters/JavaC30';
import JavaC31 from './Java/Chapters/JavaC31';
import JavaC32 from './Java/Chapters/JavaC32';
import JavaatGlance from './Java/JavaatGlance/JavaatGlance';

import JQuiz1 from './Java/Quizes/Quiz1';
import JQuiz2 from './Java/Quizes/Quiz2';
import JQuiz3 from './Java/Quizes/Quiz3';
import JQuiz4 from './Java/Quizes/Quiz4';
import JQuiz5 from './Java/Quizes/Quiz5';
import JQuiz6 from './Java/Quizes/Quiz6';
import JQuiz7 from './Java/Quizes/Quiz7';
import JQuiz8 from './Java/Quizes/Quiz8';
import JQuiz9 from './Java/Quizes/Quiz9';
import JQuiz10 from './Java/Quizes/Quiz10';
import JQuiz11 from './Java/Quizes/Quiz11';
import JQuiz12 from './Java/Quizes/Quiz12';
import JQuiz13 from './Java/Quizes/Quiz13';
import JQuiz14 from './Java/Quizes/Quiz14';
import JQuiz15 from './Java/Quizes/Quiz15';
import JQuiz16 from './Java/Quizes/Quiz16';
import JQuiz17 from './Java/Quizes/Quiz17';
import JQuiz18 from './Java/Quizes/Quiz18';
import JQuiz19 from './Java/Quizes/Quiz19';
import JQuiz20 from './Java/Quizes/Quiz20';
import JQuiz21 from './Java/Quizes/Quiz21';
import JQuiz22 from './Java/Quizes/Quiz22';
import JQuiz23 from './Java/Quizes/Quiz23';
import JQuiz24 from './Java/Quizes/Quiz24';
import JQuiz25 from './Java/Quizes/Quiz25';
import JQuiz26 from './Java/Quizes/Quiz26';
import JQuiz27 from './Java/Quizes/Quiz27';
import JQuiz28 from './Java/Quizes/Quiz28';
import JQuiz29 from './Java/Quizes/Quiz29';
import JQuiz30 from './Java/Quizes/Quiz30';






import PythonC1 from './Python/Chapters/PythonC1';
import PythonC2 from './Python/Chapters/PythonC2';
import PythonC3 from './Python/Chapters/PythonC3';
import PythonC4 from './Python/Chapters/PythonC4';
import PythonC5 from './Python/Chapters/PythonC5';
import PythonC6 from './Python/Chapters/PythonC6';
import PythonC7 from './Python/Chapters/PythonC7';
import PythonC8 from './Python/Chapters/PythonC8';
import PythonC9 from './Python/Chapters/PythonC9';
import PythonC10 from './Python/Chapters/PythonC10';
import PythonC11 from './Python/Chapters/PythonC11';
import PythonC12 from './Python/Chapters/PythonC12';
import PythonC13 from './Python/Chapters/PythonC13';
import PythonC14 from './Python/Chapters/PythonC14';
import PythonC15 from './Python/Chapters/PythonC15';
import PythonC16a from './Python/Chapters/PythonC16a';
import PythonC16 from './Python/Chapters/PythonC16';
import PythonC17 from './Python/Chapters/PythonC17';
import PythonC18 from './Python/Chapters/PythonC18';
import PythonC19 from './Python/Chapters/PythonC19';
import PythonC20 from './Python/Chapters/PythonC20';
import PythonC21 from './Python/Chapters/PythonC21';
import PythonC22 from './Python/Chapters/PythonC22';
import PythonC23 from './Python/Chapters/PythonC23';
import PythonC24 from './Python/Chapters/PythonC24';
import PythonC25 from './Python/Chapters/PythonC25';
import PythonC26 from './Python/Chapters/PythonC26';
import PythonC27 from './Python/Chapters/PythonC27';
import PythonC28 from './Python/Chapters/PythonC28';
import PythonC29 from './Python/Chapters/PythonC29';
import PythonC30 from './Python/Chapters/PythonC30';
import PythonC31 from './Python/Chapters/PythonC31';
import PythonC32 from './Python/Chapters/PythonC32';
import PythonatGlance from './Python//PythonatGlance/PythonatGlance';

import PQuiz1 from './Python/Quizes/Quiz1';
import PQuiz2 from './Python/Quizes/Quiz2';
import PQuiz3 from './Python/Quizes/Quiz3';
import PQuiz4 from './Python/Quizes/Quiz4';
import PQuiz5 from './Python/Quizes/Quiz5';
import PQuiz6 from './Python/Quizes/Quiz6';
import PQuiz7 from './Python/Quizes/Quiz7';
import PQuiz8 from './Python/Quizes/Quiz8';
import PQuiz9 from './Python/Quizes/Quiz9';
import PQuiz10 from './Python/Quizes/Quiz10';
import PQuiz11 from './Python/Quizes/Quiz11';
import PQuiz12 from './Python/Quizes/Quiz12';
import PQuiz13 from './Python/Quizes/Quiz13';
import PQuiz14 from './Python/Quizes/Quiz14';
import PQuiz15 from './Python/Quizes/Quiz15';
import PQuiz16 from './Python/Quizes/Quiz16';
import PQuiz17 from './Python/Quizes/Quiz17';
import PQuiz18 from './Python/Quizes/Quiz18';
import PQuiz19 from './Python/Quizes/Quiz19';
import PQuiz20 from './Python/Quizes/Quiz20';
import PQuiz21 from './Python/Quizes/Quiz21';
import PQuiz22 from './Python/Quizes/Quiz22';
import PQuiz23 from './Python/Quizes/Quiz23';
import PQuiz24 from './Python/Quizes/Quiz24';
import PQuiz25 from './Python/Quizes/Quiz25';
import PQuiz26 from './Python/Quizes/Quiz26';
import PQuiz27 from './Python/Quizes/Quiz27';
import PQuiz28 from './Python/Quizes/Quiz28';
import PQuiz29 from './Python/Quizes/Quiz29';
import PQuiz30 from './Python/Quizes/Quiz30';
import PQuiz31 from './Python/Quizes/Quiz31';
import Error from './Components/Error'
import PrivacyPolicy from './PrivacyPolicy.jsx';

const TRACKING_ID = 'G-GY5MTSZEPL'
ReactGA.initialize(TRACKING_ID)
 const App = () => {
  useEffect(() => {
    window.scrollTo(0, 0); 
  }, []);
  useEffect(() => {
    ReactGA.send({hitType: "pageview", page: window.location.pathname + window.location.search, title: "Home Page"});
  }, []);
  return (
    <div>
      <Routes>
      <Route path="/Cpp/intro" element={<Ch1 />} />
      <Route path="/Cpp/getting_started" element={<Ch2 />} />
      <Route path="/Cpp/syntax" element={<Ch3 />} />
      <Route path="/Cpp/output" element={<Ch4 />} />
      <Route path="/Cpp/comments" element={<Ch5 />} />
      <Route path="/Cpp/variables" element={<Ch6 />} />
      <Route path="/Cpp/input" element={<Ch7 />} />
      <Route path="/Cpp/data_types" element={<Ch8 />} />
      <Route path="/Cpp/operators" element={<Ch9 />} />
      <Route path="/Cpp/strings" element={<Ch10 />} />
      <Route path="/Cpp/if_else" element={<Ch11 />} />
      <Route path="/Cpp/switch" element={<Ch12 />} />
      <Route path="/Cpp/for_loop" element={<Ch13 />} />
      <Route path="/Cpp/while_loop" element={<Ch14 />} />
      <Route path="/Cpp/continue_break" element={<Ch14b />} />
      <Route path="/Cpp/arrays" element={<Ch15 />} />
      <Route path="/Cpp/struct" element={<Ch16 />} />
      <Route path="/Cpp/pointers" element={<Ch17 />} />
      <Route path="/Cpp/functions" element={<Ch18 />} />
      <Route path="/Cpp/builtin_functions" element={<Ch18b />} />
      <Route path="/Cpp/oop" element={<Ch19 />} />
      <Route path="/Cpp/classes_objects" element={<Ch20 />} />
      <Route path="/Cpp/constructors" element={<Ch21 />} />
       <Route path="/Cpp/access_specifiers" element={<Ch22 />} />
      <Route path="/Cpp/friend_functions_classes" element={<Ch23 />} />
      <Route path="/Cpp/encapsulation" element={<Ch24 />} />
      <Route path="/Cpp/inheritance" element={<Ch25 />} />
      <Route path="/Cpp/polymorphism" element={<Ch26 />} />
      <Route path="/Cpp/abstraction" element={<Ch27 />} />
      <Route path="/Cpp/exceptions" element={<Ch28 />} />
      <Route path="/Cpp/file_handling" element={<Ch29 />} />
      <Route path="/Cpp/operator_overloading" element={<Ch30 />} />
      <Route path="/Cpp/templates" element={<Ch31 />} />
      <Route path="/Cpp/stl" element={<Ch32 />} />
      <Route path="/Cpp/containers" element={<Ch33 />} />

      <Route path="/Components/Quizes/cpp_quiz1" element={<Quiz1 />} />
      <Route path="/Components/Quizes/cpp_quiz2" element={<Quiz2 />} />
      <Route path="/Components/Quizes/cpp_quiz3" element={<Quiz3 />} />
      <Route path="/Components/Quizes/cpp_quiz4" element={<Quiz4 />} />
      <Route path="/Components/Quizes/cpp_quiz5" element={<Quiz5 />} />
      <Route path="/Components/Quizes/cpp_quiz6" element={<Quiz6 />} />
      <Route path="/Components/Quizes/cpp_quiz7" element={<Quiz7 />} />
      <Route path="/Components/Quizes/cpp_quiz8" element={<Quiz8 />} />
      <Route path="/Components/Quizes/cpp_quiz9" element={<Quiz9 />} />
      <Route path="/Components/Quizes/cpp_quiz10" element={<Quiz10 />} />
      <Route path="/Components/Quizes/cpp_quiz11" element={<Quiz11 />} />
      <Route path="/Components/Quizes/cpp_quiz12" element={<Quiz12 />} />
      <Route path="/Components/Quizes/cpp_quiz13" element={<Quiz13 />} />
      <Route path="/Components/Quizes/cpp_quiz14" element={<Quiz14 />} />
      <Route path="/Components/Quizes/cpp_quiz15" element={<Quiz15 />} />
      <Route path="/Components/Quizes/cpp_quiz16" element={<Quiz16 />} />
      <Route path="/Components/Quizes/cpp_quiz17" element={<Quiz17 />} />
      <Route path="/Components/Quizes/cpp_quiz18" element={<Quiz18 />} />
      <Route path="/Components/Quizes/cpp_quiz19" element={<Quiz19 />} />
      <Route path="/Components/Quizes/cpp_quiz20" element={<Quiz20 />} />
      <Route path="/Components/Quizes/cpp_quiz21" element={<Quiz21 />} />
      <Route path="/Components/Quizes/cpp_quiz22" element={<Quiz22 />} />
      <Route path="/Components/Quizes/cpp_quiz23" element={<Quiz23 />} />
      <Route path="/Components/Quizes/cpp_quiz24" element={<Quiz24 />} />
      <Route path="/Components/Quizes/cpp_quiz25" element={<Quiz25 />} />
      <Route path="/Components/Quizes/cpp_quiz26" element={<Quiz26 />} />
      <Route path="/Components/Quizes/cpp_quiz27" element={<Quiz27 />} />
      <Route path="/Components/Quizes/cpp_quiz28" element={<Quiz28 />} />
      <Route path="/Components/Quizes/cpp_quiz29" element={<Quiz29 />} />
      <Route path="/Components/Quizes/cpp_quiz30" element={<Quiz30 />} />
      <Route path="/Components/Quizes/cpp_quiz31" element={<Quiz31 />} />
      <Route path="/Components/Quizes/cpp_quiz32" element={<Quiz32 />} />
      <Route path="/Components/Quizes/cpp_quiz33" element={<Quiz33 />} />

      <Route path='/' element={<Home/>} />
      <Route path="/cheatsheets" element={<Cheatsheets/>}/>
      <Route path="/cheatsheets/cppatglance" element={<CppatGlance/>}/>
      <Route path='/tutorials' element={<Tutorials/>}/>
      <Route path='/contact' element={<ContactUs/>}/>
      <Route path='/disclaimer' element={<Disclaimer/>}/>
      <Route path='/aboutus' element={<AboutUs/>}/>
      <Route path='/Components/Quizes' element={<Quizes/>}/>


      <Route path='/C/introduction' element={<CC1/>}/>
        <Route path='/C/getting_started' element={<CC2/>}/>
        <Route path='/C/syntax' element={<CC3/>}/>
        <Route path='/C/output' element={<CC4/>}/>
        <Route path='/C/comments' element={<CC5/>}/>
        <Route path='/C/variables' element={<CC6/>}/>
        <Route path='/C/input' element={<CC7/>}/>
        <Route path='/C/data_types' element={<CC8/>}/>
        <Route path='/C/type_casting' element={<CC9/>}/>
        <Route path='/C/strings' element={<CC10/>}/>
        <Route path='/C/operators' element={<CC11/>}/>
        <Route path='/C/maths' element={<CC12/>}/>
        <Route path='/C/booleans' element={<CC13/>}/>
        <Route path='/C/if_else' element={<CC14/>}/>
        <Route path='/C/switch' element={<CC15/>}/>
        <Route path='/C/for_loop' element={<CC16/>}/>
        <Route path='/C/while_loop' element={<CC17/>}/>
        <Route path='/C/continue_break' element={<CC18/>}/>
        <Route path='/C/arrays' element={<CC19/>}/>
        <Route path='/C/pointers' element={<CC20/>}/>
        <Route path='/C/functions' element={<CC21/>}/>
        <Route path='/C/functions' element={<CC21/>}/>
        <Route path='/C/struct' element={<CC22/>}/>
        <Route path='/C/file_handling' element={<CC23/>}/>
        <Route path='/cheatsheets/catglance' element={<CatGlance/>}/>
        <Route path='/quizes/c_quiz1' element={<CQuiz1/>}/>
        <Route path='/quizes/c_quiz2' element={<CQuiz2/>}/>
        <Route path='/quizes/c_quiz3' element={<CQuiz3/>}/>
        <Route path='/quizes/c_quiz4' element={<CQuiz4/>}/>
        <Route path='/quizes/c_quiz5' element={<CQuiz5/>}/>
        <Route path='/quizes/c_quiz6' element={<CQuiz6/>}/>
        <Route path='/quizes/c_quiz7' element={<CQuiz7/>}/>
        <Route path='/quizes/c_quiz8' element={<CQuiz8/>}/>
        <Route path='/quizes/c_quiz9' element={<CQuiz9/>}/>
        <Route path='/quizes/c_quiz10' element={<CQuiz10/>}/>
        <Route path='/quizes/c_quiz11' element={<CQuiz11/>}/>
        <Route path='/quizes/c_quiz12' element={<CQuiz12/>}/>
        <Route path='/quizes/c_quiz13' element={<CQuiz13/>}/>
        <Route path='/quizes/c_quiz14' element={<CQuiz14/>}/>
        <Route path='/quizes/c_quiz15' element={<CQuiz15/>}/>
        <Route path='/quizes/c_quiz16' element={<CQuiz16/>}/>
        <Route path='/quizes/c_quiz17' element={<CQuiz17/>}/>
        <Route path='/quizes/c_quiz18' element={<CQuiz18/>}/>
        <Route path='/quizes/c_quiz19' element={<CQuiz19/>}/>
        <Route path='/quizes/c_quiz20' element={<CQuiz20/>}/>
        <Route path='/quizes/c_quiz21' element={<CQuiz21/>}/>
        <Route path='/quizes/c_quiz22' element={<CQuiz22/>}/>
        <Route path='/quizes/c_quiz23' element={<CQuiz23/>}/>

        <Route path='/ques1' element={<Question1/>}/>
        <Route path='/ques2' element={<Question2/>}/>
        <Route path='/ques3' element={<Question3/>}/>
        <Route path='/ques4' element={<Question4/>}/>
        <Route path='/ques5' element={<Question5/>}/>
        <Route path='/ques6' element={<Question6/>}/>
        <Route path='/ques7' element={<Question7/>}/>
        <Route path='/ques8' element={<Question8/>}/>
        <Route path='/ques9' element={<Question9/>}/>
        <Route path='/ques10' element={<Question10/>}/>
        <Route path='/ques11' element={<Question11/>}/>
        <Route path='/ques12' element={<Question12/>}/>
        <Route path='/ques13' element={<Question13/>}/>
        <Route path='/ques14' element={<Question14/>}/>
        <Route path='/ques15' element={<Question15/>}/>
        <Route path='/ques16' element={<Question16/>}/>
        <Route path='/ques17' element={<Question17/>}/>
        <Route path='/ques18' element={<Question18/>}/>
        <Route path='/ques19' element={<Question19/>}/>
        <Route path='/ques20' element={<Question20/>}/>
        <Route path='/ques21' element={<Question21/>}/>
        <Route path='/ques22' element={<Question22/>}/>  


        <Route path='/java/introduction' element={<JavaC1/>}/>
        <Route path='/java/getting_started' element={<JavaC2/>}/>
        <Route path='/java/syntax' element={<JavaC3/>}/>
        <Route path='/java/output' element={<JavaC4/>}/>
        <Route path='/java/comments' element={<JavaC5/>}/>
        <Route path='/java/variables' element={<JavaC6/>}/>
        <Route path='/java/input' element={<JavaC7/>}/>
        <Route path='/java/data_types' element={<JavaC8/>}/>
        <Route path='/java/type_casting' element={<JavaC9/>}/>
        <Route path='/java/operators' element={<JavaC10/>}/>
        <Route path='/java/strings' element={<JavaC11/>}/>
        <Route path='/java/maths' element={<JavaC12/>}/>
        <Route path='/java/booleans' element={<JavaC13/>}/>
        <Route path='/java/if_else' element={<JavaC14/>}/>
        <Route path='/java/switch' element={<JavaC15/>}/>
        <Route path='/java/while_loop' element={<JavaC16/>}/>
        <Route path='/java/for_loop' element={<JavaC17/>}/>
        <Route path='/java/continue_break' element={<JavaC18/>}/>
        <Route path='/java/arrays' element={<JavaC19/>}/>
        <Route path='/java/methods' element={<JavaC20/>}/>
        <Route path='/java/scope' element={<JavaC21/>}/>
        <Route path='/java/oop' element={<JavaC22/>}/>
        <Route path='/java/classes_objects' element={<JavaC23/>}/>
        <Route path='/java/constructors' element={<JavaC24/>}/>
        <Route path='/java/modifiers' element={<JavaC25/>}/>
        <Route path='/java/encapsulation' element={<JavaC26/>}/>
        <Route path='/java/inheritance' element={<JavaC27/>}/>
        <Route path='/java/abstraction' element={<JavaC28/>}/>
        <Route path='/java/polymorphism' element={<JavaC29/>}/>
        <Route path='/java/exceptions' element={<JavaC30/>}/>
        <Route path='/java/file_handling' element={<JavaC31/>}/>
        <Route path='/java/interfaces' element={<JavaC32/>}/>
        <Route path='/cheatsheets/javaatglance' element={<JavaatGlance/>}/>
        <Route path='/quizes/java_quiz1' element={<JQuiz1/>}/>
        <Route path='/quizes/java_quiz2' element={<JQuiz2/>}/>
        <Route path='/quizes/java_quiz3' element={<JQuiz3/>}/>
        <Route path='/quizes/java_quiz4' element={<JQuiz4/>}/>
        <Route path='/quizes/java_quiz5' element={<JQuiz5/>}/>
        <Route path='/quizes/java_quiz6' element={<JQuiz6/>}/>
        <Route path='/quizes/java_quiz7' element={<JQuiz7/>}/>
        <Route path='/quizes/java_quiz8' element={<JQuiz8/>}/>
        <Route path='/quizes/java_quiz9' element={<JQuiz9/>}/>
        <Route path='/quizes/java_quiz10' element={<JQuiz10/>}/>
        <Route path='/quizes/java_quiz11' element={<JQuiz11/>}/>
        <Route path='/quizes/java_quiz12' element={<JQuiz12/>}/>
        <Route path='/quizes/java_quiz13' element={<JQuiz13/>}/>
        <Route path='/quizes/java_quiz14' element={<JQuiz14/>}/>
        <Route path='/quizes/java_quiz15' element={<JQuiz15/>}/>
        <Route path='/quizes/java_quiz16' element={<JQuiz16/>}/>
        <Route path='/quizes/java_quiz17' element={<JQuiz17/>}/>
        <Route path='/quizes/java_quiz18' element={<JQuiz18/>}/>
        <Route path='/quizes/java_quiz19' element={<JQuiz19/>}/>
        <Route path='/quizes/java_quiz20' element={<JQuiz20/>}/>
        <Route path='/quizes/java_quiz21' element={<JQuiz21/>}/>
        <Route path='/quizes/java_quiz22' element={<JQuiz22/>}/>
        <Route path='/quizes/java_quiz23' element={<JQuiz23/>}/>
        <Route path='/quizes/java_quiz24' element={<JQuiz24/>}/>
        <Route path='/quizes/java_quiz25' element={<JQuiz25/>}/>
        <Route path='/quizes/java_quiz26' element={<JQuiz26/>}/>
        <Route path='/quizes/java_quiz27' element={<JQuiz27/>}/>
        <Route path='/quizes/java_quiz28' element={<JQuiz28/>}/>
        <Route path='/quizes/java_quiz29' element={<JQuiz29/>}/>
        <Route path='/quizes/java_quiz30' element={<JQuiz30/>}/>




        <Route path='/python/introduction' element={<PythonC1/>}/>
        <Route path='/python/getting_started' element={<PythonC2/>}/>
        <Route path='/python/syntax' element={<PythonC3/>}/>
        <Route path='/python/output' element={<PythonC4/>}/>
        <Route path='/python/comments' element={<PythonC5/>}/>
        <Route path='/python/variables' element={<PythonC6/>}/>
        <Route path='/python/input' element={<PythonC7/>}/>
        <Route path='/python/data_types' element={<PythonC8/>}/>
        <Route path='/python/numbers' element={<PythonC9/>}/>
        <Route path='/python/strings' element={<PythonC10/>}/>
        <Route path='/python/operators' element={<PythonC11/>}/>
        <Route path='/python/lists' element={<PythonC12/>}/>
        <Route path='/python/tuples' element={<PythonC13/>}/>
        <Route path='/python/sets' element={<PythonC14/>}/>
        <Route path='/python/dictionaries' element={<PythonC15/>}/>
        <Route path='/python/booleans' element={<PythonC16a/>}/>
        <Route path='/python/if_else' element={<PythonC16/>}/>
        <Route path='/python/while_loop' element={<PythonC17/>}/>
        <Route path='/python/for_loop' element={<PythonC18/>}/>
        <Route path='/python/control_statements' element={<PythonC19/>}/>
        <Route path='/python/functions' element={<PythonC20/>}/>
        <Route path='/python/oop' element={<PythonC21/>}/>
        <Route path='/python/classes_objects' element={<PythonC22/>}/>
        <Route path='/python/scope' element={<PythonC23/>}/>
        <Route path='/python/decorators' element={<PythonC24/>}/>
        <Route path='/python/inheritance' element={<PythonC25/>}/>
        <Route path='/python/polymorphism' element={<PythonC26/>}/>
        <Route path='/python/maths' element={<PythonC27/>}/>
        <Route path='/python/modules' element={<PythonC28/>}/>
        <Route path='/python/json' element={<PythonC29/>}/>
        <Route path='/python/exceptions' element={<PythonC30/>}/>
        <Route path='/python/file_handling' element={<PythonC31/>}/>
        <Route path='/python/iterators' element={<PythonC32/>}/>
        <Route path='/cheatsheets/pythonatglance' element={<PythonatGlance/>}/>
        <Route path='/quizes/python_quiz1' element={<PQuiz1/>}/>
        <Route path='/quizes/python_quiz2' element={<PQuiz2/>}/>
        <Route path='/quizes/python_quiz3' element={<PQuiz3/>}/>
        <Route path='/quizes/python_quiz4' element={<PQuiz4/>}/>
        <Route path='/quizes/python_quiz5' element={<PQuiz5/>}/>
        <Route path='/quizes/python_quiz6' element={<PQuiz6/>}/>
        <Route path='/quizes/python_quiz7' element={<PQuiz7/>}/>
        <Route path='/quizes/python_quiz8' element={<PQuiz8/>}/>
        <Route path='/quizes/python_quiz9' element={<PQuiz9/>}/>
        <Route path='/quizes/python_quiz10' element={<PQuiz10/>}/>
        <Route path='/quizes/python_quiz11' element={<PQuiz11/>}/>
        <Route path='/quizes/python_quiz12' element={<PQuiz12/>}/>
        <Route path='/quizes/python_quiz13' element={<PQuiz13/>}/>
        <Route path='/quizes/python_quiz14' element={<PQuiz14/>}/>
        <Route path='/quizes/python_quiz15' element={<PQuiz15/>}/>
        <Route path='/quizes/python_quiz16' element={<PQuiz16/>}/>        
        <Route path='/quizes/python_quiz17' element={<PQuiz17/>}/>        
        <Route path='/quizes/python_quiz18' element={<PQuiz18/>}/>        
        <Route path='/quizes/python_quiz19' element={<PQuiz19/>}/>        
        <Route path='/quizes/python_quiz20' element={<PQuiz20/>}/>        
        <Route path='/quizes/python_quiz21' element={<PQuiz21/>}/>        
        <Route path='/quizes/python_quiz22' element={<PQuiz22/>}/>        
        <Route path='/quizes/python_quiz23' element={<PQuiz23/>}/>        
        <Route path='/quizes/python_quiz24' element={<PQuiz24/>}/>        
        <Route path='/quizes/python_quiz25' element={<PQuiz25/>}/>        
        <Route path='/quizes/python_quiz26' element={<PQuiz26/>}/>        
        <Route path='/quizes/python_quiz27' element={<PQuiz27/>}/>        
        <Route path='/quizes/python_quiz28' element={<PQuiz28/>}/>        
        <Route path='/quizes/python_quiz29' element={<PQuiz29/>}/>        
        <Route path='/quizes/python_quiz30' element={<PQuiz30/>}/>        
        <Route path='/quizes/python_quiz31' element={<PQuiz31/>}/>
        <Route path='/privacypolicy' element={<PrivacyPolicy/>}/>
        <Route path='*' element={<Error/>}/>
      </Routes>




        
    </div>
  )
}
export default App