import { useState, useEffect } from "react";
import Sidebar from "./SideBar";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
// import "./Quiz.css";
import "../../Components/Chapters/Chapters.css";
import Modal from "react-modal";

const PQuiz21 = () => {
  const [answers, setAnswers] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const handleQuizSubmit = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const questions = [
    {
        id: 1,
        question: "  What is a function in Python:",
        options: ["A block of code that executes automatically", "A data type in Python", "A reserved keyword in Python", "A named block of code that performs a specific task when called"],
        correctAnswer: "A named block of code that performs a specific task when called",
      },
      {
        id: 2,
        question: "  Which of the following is NOT a type of function in Python:",
        options: ["Built-in functions", "User-defined functions", "Pre-defined functions", "All of the above"],
        correctAnswer: "Pre-defined functions",
      },
      {
        id: 3,
        question: "  How do you declare a function in Python:",
        options: ["Using the \"function\" keyword", "Using the \"call\" keyword", "Using the \"return\" keyword", "Using the \"def\" keyword"],
        correctAnswer: "Using the \"def\" keyword",
      },
      {
        id: 4,
        question: "  What is the purpose of the \"parameters\" in a function definition:",
        options: ["To pass data to the function when it is called", "To store the values returned by the function", "To declare variables within the function", "To specify the data type of the function"],
        correctAnswer: "To pass data to the function when it is called",
      },
      {
        id: 5,
        question: "  In Python, what is the symbol used to define arbitrary arguments in a function definition:",
        options: ["@", "*", "$", "#"],
        correctAnswer: "*",
      },
      {
        id: 6,
        question: "  What type of arguments are passed to a function along with a key and can be accessed using the key within the function:",
        options: ["Default arguments", "Keyword arguments", "Positional arguments", "Arbitrary arguments"],
        correctAnswer: "Keyword arguments",
      },
      {
        id: 7,
        question: "  When defining a function, how can you set a default value for a parameter:",
        options: ["Use the \"define\" keyword", "Use the \"value\" keyword", "Use the \"default\" keyword", "Simply assign a value to the parameter"],
        correctAnswer: "Simply assign a value to the parameter",
      },
      {
        id: 8,
        question: "  What is recursion in Python:",
        options: ["A loop that repeats the same code block", "A keyword for declaring functions", "A function that calls itself to solve a problem by breaking it down into smaller subproblems", "A way to define arbitrary arguments"],
        correctAnswer: "A function that calls itself to solve a problem by breaking it down into smaller subproblems",
      },
      {
        id: 9,
        question: "  When can you use the \"pass\" statement in a function:",
        options: ["To declare a function without any code", "To skip the current iteration in a loop", "To store values returned by the function", "To exit the program"],
        correctAnswer: "To declare a function without any code",
      },
  ];
  const handleAnswerChange = (questionId, selectedAnswer) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: selectedAnswer,
    }));
  };

  const calculateScore = () => {
    let correctCount = 0;
    let incorrectCount = 0;

    questions.forEach((question) => {
      const userAnswer = answers[question.id];
      if (userAnswer === question.correctAnswer) {
        correctCount++;
      } else {
        incorrectCount++;
      }
    });

    return { correctCount, incorrectCount };
  };

  const { correctCount, incorrectCount } = calculateScore();
  return (
    <>
      <Sidebar>
        <div class="container">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/quizes/python_quiz20">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                  }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/quizes/python_quiz22">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              </h2>
              <h1 class="start content ui left floated header">
                Functions
              </h1>
              <div className="ui clearing divider"></div>
            

            <div class="main-body">
              <h2 class="ui left floated header">
                Quiz # 21
              </h2>
              <span class="tut-btn"><Link to="/python/functions">
                  <button class="ui secondary button">Read Tutorial</button>
                </Link></span>
              <div class="ui clearing divider"></div>
              <div>
                {questions.map((question, i) => (
                  <div key={question.id}>
                    <div class="con">
                    <div class="que">Q{i+1}:<span class="one-per-left-margin">{question.question}</span></div>
                      {question.options.map((option) => (
                        <label class="label" key={option}>
                          <input
                            type="radio"
                            name={`question_${question.id}`}
                            value={option}
                            checked={answers[question.id] === option}
                            onChange={(e) =>
                              handleAnswerChange(question.id, e.target.value)
                            }
                          />
                          <span className="one-per-left-margin">{option}</span>
                          <br />
                        </label>
                      ))}
                    </div>
                    <hr />
                  </div>
                ))}<br/>
                <center><button class="ui primary button" onClick={handleQuizSubmit}>Submit Quiz</button></center>

                {/* Modal for displaying results */}
                <Modal class="modal" isOpen={isModalOpen} onRequestClose={closeModal}>
                  <b style={{fontSize:'20px'}}>Quiz Results</b>
                  <b><p>Your score is {correctCount}/{questions.length} </p></b>
                  {questions.map((question,i) => (
                    <div key={question.id}>
                      <p>Q{i+1}: {question.question}</p>
                      <p><b>Correct Answer:</b> {question.correctAnswer}</p>
                      <p className={`${question.correctAnswer}` === `${answers[question.id]}` ? "correct" : "wrong"}>
                        <b>Your Answer:</b> {answers[question.id] || "Not answered"}
                      </p>
                      <hr />
                    </div>
                  ))}
                  <button class="ui secondary button" onClick={closeModal}>Close</button><br /><br />
                </Modal>
              </div>

              <br />
            </div>
            <Link to="/quizes/python_quiz20">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                  }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/quizes/python_quiz22">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
            <br />
          </div>
        </div>
      </Sidebar>

      <div class="sideBarBody">
        <Footer />
      </div>
    </>
  );
};
export default PQuiz21;
