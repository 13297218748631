import React, { useState, useRef, useEffect } from "react";
import Sidebar from "./SideBar";
import "../../Components/SideBar/App.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const CodeAccordion = ({ questions, code, toggleAccordion, changeCodeLanguage }) => {
    const divRef = useRef(null);
    const copyContent = () => {
        const divContent = divRef.current.innerText;
        navigator.clipboard
          .writeText(divContent)
          .then(() => {
            toast.success("Content copied!", { position: "top-center" });
          })
          .catch((error) => {
            toast.error("Error copying content:", error, {
              position: "top-center",
            });
          });
      };
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };

  return (
    <div>
      {questions.map((item, index) => (
        <div key={index} className="border p-2 rounded-md">
          <div className="flex justify-between">
            <span className="text-lg font-semibold">
              {item.question}
            </span>
            <button
              className="bg-gray-200 px-2 py-1 rounded-md"
              onClick={() => toggleAccordion(index)}
            >
              {item.isOpen ? "-" : "+"}
            </button>
          </div>
          <div className={`mt-2 ${item.isOpen ? "block" : "hidden"}`}>
            <div className="mt-2 flex space-x-4">
              {Object.keys(code).map((language) => (
                <button
                  key={language}
                  onClick={() => changeCodeLanguage(index, language)}
                  className={`${
                    item.codeLanguage === language
                      ? "bg-blue-500 hover:bg-blue-600 text-white"
                      : "bg-gray-200 hover:bg-gray-300 text-gray-700"
                  } px-3 py-2 rounded-md transition duration-300 ease-in-out transform hover:scale-105`}
                >
                  {language}
                </button>
              ))}
            </div>
            <p className="text-gray-800">
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>output.cpp</b>
                  <i
                    className="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language={`${item.codeLanguage}`} style={darcula}>
                    {code[item.codeLanguage]}
                  </SyntaxHighlighter>
                </div>
              </div>
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

const Question18 = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
    const code = {
        python: `# Python code
items_prices = {
    "apple": 1.00,
    "banana": 0.50,
    "cherry": 1.50,
    "date": 2.00,
    "fig": 1.20
}

# Accessing prices for items
apple_price = items_prices["apple"]
banana_price = items_prices["banana"]

print("Item Prices:")
for item, price in items_prices.items():
    print(f"{item}: \${price:.2f}")

print(f"Price of an apple: \${apple_price:.2f}")
print(f"Price of a banana: \${banana_price:.2f}")`,
    };
    const code1 = {
        python: `# Python code
items_prices = {
    "apple": 1.00,
    "banana": 0.50,
    "cherry": 1.50,
    "date": 2.00,
    "fig": 1.20
}

# Retrieve values using keys
apple_price = items_prices["apple"]
banana_price = items_prices["banana"]
cherry_price = items_prices["cherry"]

print(f"Price of an apple: \${apple_price:.2f}")
print(f"Price of a banana: \${banana_price:.2f}")
print(f"Price of a cherry: \${cherry_price:.2f}")`,
    };
    const code2 = {
        python: `# Python code
items_prices = {
    "apple": 1.00,
    "banana": 0.50,
    "cherry": 1.50,
    "date": 2.00,
    "fig": 1.20
}

# Add new items to the dictionary
items_prices["grape"] = 2.50
items_prices["kiwi"] = 1.80

# Print the updated dictionary
print("Updated Item Prices:")
for item, price in items_prices.items():
    print(f"{item}: \${price:.2f}")`,
    };
    const code3 = {
        python: `# Python code
items_prices = {
    "apple": 1.00,
    "banana": 0.50,
    "cherry": 1.50,
    "date": 2.00,
    "fig": 1.20
}

# Update the prices of items in the dictionary
items_prices["apple"] = 1.20
items_prices["cherry"] = 1.75

# Print the updated dictionary
print("Updated Item Prices:")
for item, price in items_prices.items():
    print(f"{item}: \${price:.2f}")`,
    };
    const code4 = {
        python: `# Python code
items_prices = {
    "apple": 1.00,
    "banana": 0.50,
    "cherry": 1.50,
    "date": 2.00,
    "fig": 1.20
}

# Remove an item from the dictionary using del
del items_prices["banana"]

# Print the updated dictionary
print("Updated Item Prices (banana removed):")
for item, price in items_prices.items():
    print(f"{item}: \${price:.2f}")`,
    };
    const code5 = {
        python: `# Python code
items_prices = {
    "apple": 1.00,
    "banana": 0.50,
    "cherry": 1.50,
    "date": 2.00,
    "fig": 1.20
}

# Iterate through the dictionary and print key-value pairs
for item, price in items_prices.items():
    print(f"{item}: \${price:.2f}")`,
    };
    const code6 = {
        python: `# Python code
items_prices = {
    "apple": 1.00,
    "banana": 0.50,
    "cherry": 1.50,
    "date": 2.00,
    "fig": 1.20
}

# Find the key with the highest value
max_key = max(items_prices, key=lambda key: items_prices[key])

# Find the key with the lowest value
min_key = min(items_prices, key=lambda key: items_prices[key])

print("Item Prices:")
for item, price in items_prices.items():
    print(f"{item}: \${price:.2f}")

print(f"Key with Highest Price: {max_key} (Price: \${items_prices[max_key]:.2f})")
print(f"Key with Lowest Price: {min_key} (Price: \${items_prices[min_key]:.2f})")`,
    };
    const code7 = {
        python: `# Python code
shopping_cart = {
    "apple": 2,
    "banana": 3,
    "cherry": 1,
    "date": 4,
    "fig": 2
}

items_prices = {
    "apple": 1.00,
    "banana": 0.50,
    "cherry": 1.50,
    "date": 2.00,
    "fig": 1.20
}

# Calculate the total value of items in the shopping cart
total_value = sum(items_prices[item] * quantity for item, quantity in shopping_cart.items())

print("Shopping Cart:")
for item, quantity in shopping_cart.items():
    print(f"{item} x{quantity}")

print(f"Total Value of Items in Cart: \${total_value:.2f}")`,
    };
    const code8 = {
        python: `# Python code
dict1 = {"apple": 1, "banana": 2}
dict2 = {"cherry": 3, "date": 4}

# Merge dict2 into dict1 using dictionary unpacking
merged_dict = {**dict1, **dict2}

# Print the merged dictionary
print("Merged Dictionary (using dictionary unpacking):", merged_dict)`,
    };
    const code9 = {
        python: `# Python code
# Create a dictionary with keys as numbers and values as their squares using dictionary comprehension
squares_dict = {num: num**2 for num in range(1, 11)}

# Print the resulting dictionary
print("Dictionary with Keys as Numbers and Values as Their Squares:")
print(squares_dict)`,
    };
    const code10 = {
        python: `# Python code
def invert_dictionary(input_dict):
    inverted_dict = {value: key for key, value in input_dict.items()}
    return inverted_dict

# Original dictionary
original_dict = {
    "apple": "red",
    "banana": "yellow",
    "cherry": "red",
    "date": "brown"
}

# Invert the dictionary
inverted_dict = invert_dictionary(original_dict)

# Print the original and inverted dictionaries
print("Original Dictionary:")
print(original_dict)
print("\\nInverted Dictionary (Keys and Values Swapped):")
print(inverted_dict)`,
    };
    const code11 = {
        python: `# Python code
def find_common_keys(dict1, dict2):
    common_keys = set(dict1.keys()) & set(dict2.keys())
    return common_keys

# Two dictionaries
dict1 = {"apple": 1, "banana": 2, "cherry": 3}
dict2 = {"banana": 4, "date": 5, "cherry": 6}

# Find common keys between the two dictionaries
common_keys = find_common_keys(dict1, dict2)

# Print the common keys
print("Common Keys in Both Dictionaries:", common_keys)`,
    };
    const code12 = {
        python: `# Python code
def filter_items_by_price(original_dict, price_threshold):
    filtered_dict = {item: price for item, price in original_dict.items() if price > price_threshold}
    return filtered_dict

# Original dictionary
items_prices = {
    "apple": 1.00,
    "banana": 0.50,
    "cherry": 1.50,
    "date": 2.00,
    "fig": 1.20
}

# Price threshold for filtering
threshold = 1.00

# Filter the dictionary
filtered_items = filter_items_by_price(items_prices, threshold)

# Print the original and filtered dictionaries
print("Original Item Prices:")
print(items_prices)
print(f"\\nFiltered Item Prices (Above \${threshold:.2f}):")
print(filtered_items)`,
    };
    const code13 = {
        python: `# Python code
def filter_items_by_keyword(original_dict, keyword):
    filtered_dict = {item: price for item, price in original_dict.items() if keyword in item}
    return filtered_dict

# Original dictionary
items_prices = {
    "apple": 1.00,
    "banana": 0.50,
    "cherry": 1.50,
    "date": 2.00,
    "fig": 1.20
}

# Keyword for filtering
keyword = "an"

# Filter the dictionary
filtered_items = filter_items_by_keyword(items_prices, keyword)

# Print the original and filtered dictionaries
print("Original Item Prices:")
print(items_prices)
print(f"\\nFiltered Item Prices (Containing '{keyword}' in the Name):")
print(filtered_items)`,
    };
    const code14 = {
        python: `# Python code
catalog = {
    "Electronics": {
        "Laptop": 999.99,
        "Smartphone": 699.99,
        "Headphones": 79.99,
    },
    "Clothing": {
        "T-Shirt": 19.99,
        "Jeans": 49.99,
        "Shoes": 79.99,
    },
    "Books": {
        "Python Programming": 29.99,
        "Data Science for Beginners": 24.99,
        "The Great Gatsby": 12.99,
    }
}

# Print the catalog
print("Product Catalog:")
for category, products in catalog.items():
    print(f"{category}:")
    for product, price in products.items():
        print(f"  {product}: \${price:.2f}")`,
    };
    const code15 = {
        python: `# Python code
def flatten_dict(nested_dict, parent_key='', separator='_'):
    flat_dict = {}
    for key, value in nested_dict.items():
        new_key = f"{parent_key}{separator}{key}" if parent_key else key
        if isinstance(value, dict):
            flat_dict.update(flatten_dict(value, new_key, separator=separator))
        else:
            flat_dict[new_key] = value
    return flat_dict

# Nested dictionary
nested_dict = {
    'a': 1,
    'b': {
        'c': 2,
        'd': {
            'e': 3,
            'f': 4
        }
    },
    'g': 5
}

# Flatten the nested dictionary
flat_dict = flatten_dict(nested_dict)

# Print the flattened dictionary
print("Nested Dictionary:")
print(nested_dict)
print("\\nFlattened Dictionary:")
print(flat_dict)`,
    };


  const [questions, setQuestions] = useState([
    {
      question: "Q1: Write a program to create a dictionary with key-value pairing representing items and their prices and print their values on screen.",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  const [questions1, setQuestions1] = useState([
    {
      question: "Q2: Write a program to retrieve the values from a dictionary using keys.",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  const [questions2, setQuestions2] = useState([
    {
      question: "Q3: Write a program to add new items to a dictionary and print the values on screen.",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  const [questions3, setQuestions3] = useState([
    {
      question: "Q4: Write a program to update the prices of items in a dictionary.",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  const [questions4, setQuestions4] = useState([
    {
      question: "Q5: Write a program to remove items from a dictionary.",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  const [questions5, setQuestions5] = useState([
    {
      question: "Q6: Write a program to iterate through a dictionary and print all the key-value pairs on screen.",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  const [questions6, setQuestions6] = useState([
    {
      question: "Q7: Write a program to find the keys with the highest and lowest values in a dictionary and print on screen.",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  const [questions7, setQuestions7] = useState([
    {
      question: "Q8: Write a program to calculate the total value of items in a shopping cart dictionary.",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  const [questions8, setQuestions8] = useState([
    {
      question: "Q9: Write a program to merge two dictionaries into one.",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  const [questions9, setQuestions9] = useState([
    {
      question: "Q10: Write a program to create a dictionary with keys as numbers and values as their squares using dictionary comprehension. Also print the results on screen.",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  const [questions10, setQuestions10] = useState([
    {
      question: "Q11: Create a function to invert a dictionary (swap keys and values).",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  const [questions11, setQuestions11] = useState([
    {
      question: "Q12: Create a function to find common keys in two dictionaries.",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  const [questions12, setQuestions12] = useState([
    {
      question: "Q13: Filter a dictionary to include only items with prices above a certain threshold.",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  const [questions13, setQuestions13] = useState([
    {
      question: "Q14: Write a program to filter a dictionary to include only items that contain a specific keyword in their names.",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  const [questions14, setQuestions14] = useState([
    {
      question: "Q15: Write a program to create a dictionary that represents a catalog of products with categories.",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  const [questions15, setQuestions15] = useState([
    {
      question: "Q16: Write a program to create a function to recursively flatten a nested dictionary into a flat dictionary.",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  

  const toggleAccordion = (index, questions, setQuestions) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].isOpen = !updatedQuestions[index].isOpen;
    setQuestions(updatedQuestions);
  };

  const changeCodeLanguage = (index, language, questions, setQuestions) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].codeLanguage = language;
    setQuestions(updatedQuestions);
  };

  

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };
  const divRef = useRef(null);
  return (
    <div className="testing">
      <Sidebar>
        <div className="containera">
          <div className="ch-content">
            <h2 className="ui header">
            <Link to="/ques17">
              <button
                className="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
              </Link>
              <Link to="/ques19">
                <button
                  className="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>
            <div className="main-body ui">
              <h2 className="ui left floated header" style={{ color: "#001C30" }}>
                Dictionary:
              </h2>
              <div className="ui clearing divider"></div>
              <CodeAccordion
                questions={questions}
                code={code}
                toggleAccordion={(index) => toggleAccordion(index, questions, setQuestions)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions, setQuestions)
                }
              /><br/>
              <CodeAccordion
                questions={questions1}
                code={code1}
                toggleAccordion={(index) => toggleAccordion(index, questions1, setQuestions1)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions1, setQuestions1)
                }
              /><br/>
              <CodeAccordion
                questions={questions2}
                code={code2}
                toggleAccordion={(index) => toggleAccordion(index, questions2, setQuestions2)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions2, setQuestions2)
                }
              /><br/>
              <CodeAccordion
                questions={questions3}
                code={code3}
                toggleAccordion={(index) => toggleAccordion(index, questions3, setQuestions3)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions3, setQuestions3)
                }
              /><br/>
              <CodeAccordion
                questions={questions4}
                code={code4}
                toggleAccordion={(index) => toggleAccordion(index, questions4, setQuestions4)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions4, setQuestions4)
                }
              /><br/>
              <CodeAccordion
                questions={questions5}
                code={code5}
                toggleAccordion={(index) => toggleAccordion(index, questions5, setQuestions5)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions5, setQuestions5)
                }
              /><br/>
              <CodeAccordion
                questions={questions6}
                code={code6}
                toggleAccordion={(index) => toggleAccordion(index, questions6, setQuestions6)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions6, setQuestions6)
                }
              /><br/>
              <CodeAccordion
                questions={questions7}
                code={code7}
                toggleAccordion={(index) => toggleAccordion(index, questions7, setQuestions7)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions7, setQuestions7)
                }
              /><br/>
              <CodeAccordion
                questions={questions8}
                code={code8}
                toggleAccordion={(index) => toggleAccordion(index, questions8, setQuestions8)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions8, setQuestions8)
                }
              /><br/>
              <CodeAccordion
                questions={questions9}
                code={code9}
                toggleAccordion={(index) => toggleAccordion(index, questions9, setQuestions9)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions9, setQuestions9)
                }
              /><br/>
              <CodeAccordion
                questions={questions10}
                code={code10}
                toggleAccordion={(index) => toggleAccordion(index, questions10, setQuestions10)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions10, setQuestions10)
                }
              /><br/>
              <CodeAccordion
                questions={questions11}
                code={code11}
                toggleAccordion={(index) => toggleAccordion(index, questions11, setQuestions11)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions11, setQuestions11)
                }
              /><br/>
              <CodeAccordion
                questions={questions12}
                code={code12}
                toggleAccordion={(index) => toggleAccordion(index, questions12, setQuestions12)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions12, setQuestions12)
                }
              /><br/>
              <CodeAccordion
                questions={questions13}
                code={code13}
                toggleAccordion={(index) => toggleAccordion(index, questions13, setQuestions13)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions13, setQuestions13)
                }
              /><br/>
              <CodeAccordion
                questions={questions14}
                code={code14}
                toggleAccordion={(index) => toggleAccordion(index, questions14, setQuestions14)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions14, setQuestions14)
                }
              /><br/>
              <CodeAccordion
                questions={questions15}
                code={code15}
                toggleAccordion={(index) => toggleAccordion(index, questions15, setQuestions15)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions15, setQuestions15)
                }
              /><br/>
              
            </div>
            <Link to="/ques17">
              <button
                className="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
              </Link>
              <Link to="/ques19">
                <button
                  className="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default Question18;
