import { useRef, useEffect } from "react";
import "../../Components/Chapters/Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "../PythonSideBar";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

const PythonC8 = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  const divRef = useRef(null);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };

  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor:'#D8D9DA'
  };
  const pc8code1 = `name = "John"
age = 20
"""You have stored some values in two 
variables and have not assigned data 
types. You can check data type using 
type(variable_name)"""
print(type(name))   
print(type(age))`;
  const pc8code2 = `num1 = input("Enter first number: ")
num2 = input("Enter second number: ")
#We can not add num1 and num2.
#First change their type.
numb1 = int(num1)
numb2 = int(num2)
print(numb1 + numb2)`;
  const pc8code3 = `inp = float(input("Enter a number: " ))
print(inp)
#display the data type of inp
print(type(inp))`;
  const pc8code4 = `cars = list(("Volvo", "Audi", "Rolls Royce"))
print(cars)
#display the data type of cars
print(type(cars)) `;
  return (
    <div class="testing">
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/python/input">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/python/numbers">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Data Types:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                Data type refers to the type of data we are storing in the
                variable. Python is a versatile and dynamically typed
                programming language and offers a wide range of built-in data types.
              </span>
              <br />
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Numeric Types:
              </h2>
              <div className="ui clearing divider"></div>
              <b>int </b>represents integers or whole numbers without decimals
              e.g, 10, 20, 30 etc.
              <br />
              <b>float </b>represents numbers with decimal point e.g, 1.2, -4.3,
              5.0 etc. <br />
              <b>complex </b>represents complex numbers with real and imaginary parts.
              <br />
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Sequence Types:
              </h2>
              <div className="ui clearing divider"></div>
              <b>str </b>represents text e.g, "john", "smith" etc.
              <br />
              <b>list </b>represents ordered, mutable collection of items.
              <br />
              <b>tuple </b>represents ordered, immutable collection of items.
              <br />
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Mapping Type:
              </h2>
              <div className="ui clearing divider"></div>
              <b>dict </b>represents dictionaries which store key value pairing
              for efficient retrieval of data.
              <br />
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Set Types:
              </h2>
              <div className="ui clearing divider"></div>
              <b>set </b>represents unordered collection of unique items.
              <br />
              <b>frozenset </b>represents immutable set.
              <br />
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Boolean Type:
              </h2>
              <div className="ui clearing divider"></div>
              <b>bool </b>represents boolean values e.g, True or False.
              <br />
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Binary Type:
              </h2>
              <div className="ui clearing divider"></div>
              <b>bytes </b>represents immutable sequences of bytes.
              <br />
              <b>bytearray </b>represents mutable sequences of bytes.
              <br />
              <b>memoryview </b>represents memory view objects allowing access
              to internal buffers.
              <br />
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Range Type:
              </h2>
              <div className="ui clearing divider"></div>
              <b>range </b>represents an immutable sequence of numbers.
              <br />
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                None Type:
              </h2>
              <div className="ui clearing divider"></div>
              <b>none </b>represents a null value.
              <br />
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Dynamic Typing:
              </h2>
              <div className="ui clearing divider"></div>
              <span>
                Python is a dynamically typed language. It means that you do not
                need to specify the type of data storing in a variable. Python
                interpreter automatically detects the type of data which is
                stored in the variables and assign them specific data types.
              </span>
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>datatype.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc8code1}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Type Casting:
              </h2>
              <div className="ui clearing divider"></div>
              <span>
                When we take the input, and store it in a variable, its data
                type is string by default. When we have to perform mathematical
                operations, we can not do operations on string. So, we have to
                convert it into integer type. This is called type casting. Let
                see an example code:
              </span><br /><br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>datatype.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc8code2}
                  </SyntaxHighlighter>
                </div>
              </div><br />
              <span>
                In this way we can convert any variable to any other data type.
                We can also do type casting at the time of taking input.
              </span><br /><br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>datatype.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc8code3}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <span>
                We can also set the data type of a variable at the time of declaration.
              </span><br /><br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>datatype.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc8code4}
                  </SyntaxHighlighter>
                </div>
              </div>
              
            </div>
<br />
            <Link to="/python/input">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/python/numbers">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </div>
  );
};
export default PythonC8;
