import { useRef, useEffect } from "react";
import "../../Components/Chapters/Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "../PythonSideBar";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

const PythonC6 = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  
  const divRef = useRef(null);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };

  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };
  const pc6code1 = `age = 10`;
  const pc6code2 = `name = "John" #string type variable
age = 20 #integer type variable
working = true #boolean type variable`;
  const pc6code3 = `name = "Daniel"
print(name)`;
  const pc6code4 = `name = "Daniel" 
#if we print name Daniel will be printed
print(name)

name = "Smith" 
#Now if we print name Smith will be printed
print(name)`;
  const pc6code5 = `myName = "John" #global variable

#defining a function
def printName():
    #function starts here
    hisName = "Smith" #local variable
    print("My name is " + myName)
    print("His name is " + hisName)
    #function ends here

# calling function
printName()
print("My name is " + myName)`;

  return (
    <div class="testing">
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/python/comments">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/python/input">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Variables:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                <ul className="ui unordered list">
                <li>
                Variables are used to store data in memory which can be used
                later for doing any computation.
                </li>
                
                <li>
                In this python tutorial, you are going to learn about variables
                which are one of the most fundamental concepts in programming.
                </li>
                <li>
                Variables are not only specifically used in python. They exist
                in every programming language.
                </li>
                </ul>
              </span>
              
              <br />{" "}
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Syntax:
              </h2>
              <div className="ui clearing divider"></div>
              <span>
                You do not need to specify the data type of variable in python.
                You just need to write the name of the variable and assign it a value unlike other programming 
                languages (C, C++, Java).
              </span><br /><br />
              <SyntaxHighlighter language="python" style={darcula}>
                {pc6code1}
              </SyntaxHighlighter>
              <br />
              <span>
                Python interpreter allocates some memory to variable named <b>age</b>. 20
                is stored at some place in the memory with the label age.
                Whenever we want to use this 20, we can use this using label
                age.
              </span>
              <br />
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Different Types of Variables:
              </h2>
              <div className="ui clearing divider"></div>
              <span>
                There are different types of data which we store in variables. It can be number, text, or 
                boolean etc. On the basis of different types of data in variables, there are different types 
                of variables.
              </span><br /><br />
              



              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>variables.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc6code2}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Printing Variable Values on Screen:
              </h2>
              <div className="ui clearing divider"></div>
              <span>
                When you need to print the value of a variable on screen, you
                need to write the name inside print(variable_name). Remember,
                when you print text, it is written in double quotation marks.
                But variable names are written without double quotation marks.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>variables.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc6code3}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Rules for assigning variable names:
              </h2>
              <div className="ui clearing divider"></div>
              <ul className="ui unordered list">
                <li>
                  Variable name must start a with letter or an underscore.
                </li>
                <li>
                  Variable name must contain only letters, alphabets or
                  underscore. It starts only with a letter or underscore.
                </li>
                <li>It can not be a reserved keyword. </li>
                <li>
                  It should be a single word. No spaces are allowed e.g, name,
                  myAge, my_name etc.
                </li>
                <li>
                  For further information, you can follow Python Enhancement
                  Proposal 8 (PEP 8) guidelines.
                </li>
              </ul>
              <br />
              <p style={{ backgroundColor: "#FBEEAC", padding: "15px" }}>
               
                <ul className="ui unordered list">
                  <li>
                    Python is a case sensitive language. The variable named
                    "name" and an other variable named "Name" are not same.
                  </li>
                  <li>Keep the names of variables descriptive.</li>
                </ul>
              </p>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Naming Conventions:
              </h2>
              <br />
              <div className="ui clearing divider"></div>
              We use the following naming conventions when our variable name
              consists of more than one word.
              <span><br /><br />
                <b>Camel Case: </b>
                
                Each word starts with capital letter but first word starts with
                small letter. For example, myName, myAge etc.
              </span>
              <br />
              <span>
                <b>Pascal Case: </b>
                
                All words start with capital letter. For example, MyName, MyAge
                etc.
              </span>
              <br />
              <span>
                <b>Snake Case: </b>
                
                Each word is separated by an underscore. For example, my_name,
                my_age etc.
              </span>
              <br />
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Changing the value of a Variable:
              </h2>
              <div className="ui clearing divider"></div>
              <span>
                We can change the value of our variable or we can say, we can
                reassign the value of our variable.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>variables.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc6code4}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Scope of a Variable:
              </h2>
              <div className="ui clearing divider"></div>
              <span>
                The scope of a variable refers to the area of a program where
                that variable can be accessed and modified. A variable can have
                local or global scope.
              </span>
              <br />
              <br />
              <b>Local Scope:</b>
              
              <br />
              <span>
                A variable declared within a specific function, method, or block
                is said to have local scope. It is only accessible within that
                function, method, or block and can not be accessed outside.
              </span>
              <br />
              <br />
              <b>Global Scope:</b>
              <br />
              <span>
                A variable declared within the main body, not inside any
                block of code, function, or methos is said to have global scope.
                It can be accessed anywhere in the code.
              </span>
              <br />
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Example Code:
              </h2>
              <div className="ui clearing divider"></div>
              
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>variables.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc6code5}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h1>In this example;</h1>
              <span>
                <ul className="ui unordered list">
                  <li>
                    We can see that myName is a global variable because it is
                    declared inside the main body.
                  </li>
                  <li>
                    On the other hand, hisName is declared inside the function
                    printName().
                  </li>
                  <li>
                    {" "}
                    So we know that we can access the global variables anywhere
                    in the program and we are accessing myName in printName()
                    function and after tht in main body.
                  </li>
                  <li>
                    But we can not access local variables outside the block in
                    which it is declared and you can see that we can not access
                    hisName outside the printName() function.
                  </li>
                </ul>
              </span>
              
            </div><br />
            <Link to="/python/comments">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/python/input">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </div>
  );
};
export default PythonC6;
