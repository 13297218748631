import { useRef, useEffect } from "react";
import "../../Components/Chapters/Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Sidebar from "../JavaSideBar";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const JavaC6 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };

  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor:'#D8D9DA'
  };

  const jc5code0 = `public class Main {
    public static void main(String[] args) {

        int age = 20;     // initializing a variable

    }
}`;
  const jc5code1 = `public class Main {
    public static void main(String[] args) {

        int age = 20;     
        System.out.println(age);
    }
}`;
  const jc5code2 = `public class Main {
    public static void main(String[] args) {

        int age;            // declariation
        age = 20;       // assignment

        System.out.println(age);
    }
}`;
  const jc5code3 = `public class Main {
    public static void main(String[] args) {

        int age = 20;      
        age = 30;            

        System.out.println(age);
    }
}`;
  const jc5code4 = `public class Main {
    public static void main(String[] args) {

        final int age = 20;
        // age = 30;  not possible

        System.out.println(age);
    }
}`;
  const jc5code5 = `public class Main {
    public static void main(String[] args) {

        string name = "John";

        System.out.println(name);
    }
}`;
  const jc5code6 = `public class Main {
    public static void main(String[] args) {

        String fname = "John";
        String lname = "Smith";

        System.out.println(fname + lname);
    }
}`;
const jc5code7 = `public class Main {
    public static void main(String[] args) {

        int a = 10, b = 2, c = 30;

        System.out.println(a);
        System.out.println(b);
        System.out.println(c);
    }
}`
const jc5code8 = `public class Main {
    public static void main(String[] args) {

        int a = b = c = 20;

        System.out.println(a);
        System.out.println(b);
        System.out.println(c);
    }
}`
  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/java/comments">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/java/input">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body ">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Variables:
              </h2>
              <div class="ui clearing divider"></div>
              <p style={{ backgroundColor: "", padding: "" }}>
                Variables are containers which are used to store some value.
                There are different types of variables which store data on the
                basis of type their type. For example, int type variable will
                store intergers.
                <br />
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Types:
                </h2>
                <div class="ui clearing divider"></div>
                <div className="margin">
                  <ul class="ui list">
                    <li>
                      <b>int -</b> stores numbers without decimal point.
                    </li>
                    <li>
                      <b>double -</b> stores numbers with decimal point.
                    </li>
                    <li>
                      <b>float -</b> stores numbers with decimal point but
                      precision is higher.
                    </li>

                    <li>
                      <b>char -</b> stores single character.
                    </li>
                    <li>
                      <b>boolean -</b> stores either true or false.
                    </li>
                  </ul>
                </div>
                <br />

                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Rules for assigning variables names:
                </h2>
                <div class="ui clearing divider"></div>
                  <ul className="ui unordered list">
                    <li>Variable names can only consist of letters, digits, underscores (_), and dollar signs ($). </li>
                    <li> Variable name should start with a letter, underscore, or dollar sign.</li>
                    <li>They are case-sensitive, meaning that myName and myname are treated as different variables.</li>
                    <li>You cannot use Java keywords (e.g., int, if, main, class) as variable names.</li>
                    <li>Variable names cannot contain spaces.</li>
                    
                  </ul>
                  <p style={{ backgroundColor: "#FBEEAC", padding: "15px" }}>
                  <b>Note : </b>There is no strict limit on the length of a variable name, but it's a good practice to keep variable names reasonably short and meaningful.
                  </p><br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Declaring Variables:
                </h2>
                <div class="ui clearing divider"></div>
                <span>
                  Specify the data type of variable and then write its name and
                  finally assign it some value.
                </span>
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>variable.java</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="java" style={darcula}>
                      {jc5code0}
                    </SyntaxHighlighter>
                  </div>
                </div><br />
                <p style={{ backgroundColor: "#FBEEAC", padding: "15px" }}>
                  <b>Note : </b>Names of variables must be unique. The variables are identified by these unique names. 
                  These names are called identifiers. For example, in the above program, age is an identifier.
                  </p><br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Printing value of a Variable:
                </h2>
                <div class="ui clearing divider"></div>
                <span>
                  Specify the data type of variable and then write its name and
                  finally assign it some value.
                </span>
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>variable.java</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="java" style={darcula}>
                      {jc5code1}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <span>
                  We can also declare a variable first and then assign it some
                  value later.
                </span>
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>variable.java</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="java" style={darcula}>
                      {jc5code2}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Changing the value of a Variable:
                </h2>
                <div class="ui clearing divider"></div>
                <span>We can also change the value of a variable.</span>
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>variable.java</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="java" style={darcula}>
                      {jc5code3}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Final Variables:
                </h2>
                <div class="ui clearing divider"></div>
                <span>
                  Final variables are the variables whose values can not be
                  changed once assigned.
                </span>
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>variable.java</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="java" style={darcula}>
                      {jc5code4}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Dealing with String type Variables:
                </h2>
                <div class="ui clearing divider"></div>
                <span>
                  String type variables store text which is enclosed in double
                  quotation marks.
                </span>
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>variable.java</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="java" style={darcula}>
                      {jc5code5}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  String Concatenation:
                </h2>
                <div class="ui clearing divider"></div>
                <span>
                  String type variables can be joined / concatenated using "+" operator.
                </span>
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>variable.java</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="java" style={darcula}>
                      {jc5code6}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />


                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Declaring many variables:
                </h2>
                <div class="ui clearing divider"></div>
                <span>
                  We can declare many variables in one line, if their data type is same.
                </span>
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>variable.java</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="java" style={darcula}>
                      {jc5code7}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />


                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Assigning same value to many variables:
                </h2>
                <div class="ui clearing divider"></div>
                <span>
                  We can assign same value to many variables.
                </span>
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>variable.java</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="java" style={darcula}>
                      {jc5code8}
                    </SyntaxHighlighter>
                  </div>
                </div>
              




              </p>

              
            </div>
            
            <br />
            <Link to="/java/comments">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/java/input">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default JavaC6;
