import { useState, useEffect } from "react";
import Sidebar from "./SideBar";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
// import "./Quiz.css";
import "../../Components/Chapters/Chapters.css";
import Modal from "react-modal";

const JQuiz1 = () => {
  const [answers, setAnswers] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const handleQuizSubmit = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const questions = [
    {
      id: 1,
      question: "  In which of the following domains is Java most widely used:",
      options: ["Game Development", "Embedded Systems", "Web Development with PHP", "Android App Development"],
      correctAnswer: "Android App Development",
    },
    {
      id: 2,
      question: " What does the term \"write once, run anywhere\" mean in the context of Java:",
      options: ["Code can only be written in one place and executed anywhere.", "Code can run only on Windows platforms.", "Code can be written once and executed on multiple platforms without modification.", "Code must be rewritten for each platform."],
      correctAnswer: "Code can be written once and executed on multiple platforms without modification.",
    },
    {
      id: 3,
      question: "  Java is considered a strongly typed language. What does this mean:",
      options: [
        "Variables can change their data type at runtime.",
        "Variable types do not need to be declared explicitly.",
        "Variable types must be declared explicitly, and type-related errors are caught at compile-time.",
        "Variables can have multiple data types simultaneously.",
      ],
      correctAnswer: "Variable types must be declared explicitly, and type-related errors are caught at compile-time.",
    },
    {
      id: 4,
      question: "  Which Java feature provides pre-built classes and methods for common tasks:",
      options: ["Data Types", "Multi-Threading", "Java Standard Library (Java API)", "Inheritance"],
      correctAnswer: "Java Standard Library (Java API)",
    },
    {
        id: 5,
        question: "  What is the term for Java's approach to structuring code using classes and objects:",
        options: ["Object-Oriented Programming (OOP)", "Multi-Threading", "Platform Independence", "Strong Typing"],
        correctAnswer: "Object-Oriented Programming (OOP)",
      },
      {
        id: 6,
        question: "  Which of the following is not a part of Java's security features:",
        options: ["Bytecode Verification", "Strongly Typed Variables", "Security Manager", "Protection against malicious code"],
        correctAnswer: "Strongly Typed Variables",
      },
  ];
  const handleAnswerChange = (questionId, selectedAnswer) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: selectedAnswer,
    }));
  };

  const calculateScore = () => {
    let correctCount = 0;
    let incorrectCount = 0;

    questions.forEach((question) => {
      const userAnswer = answers[question.id];
      if (userAnswer === question.correctAnswer) {
        correctCount++;
      } else {
        incorrectCount++;
      }
    });

    return { correctCount, incorrectCount };
  };

  const { correctCount, incorrectCount } = calculateScore();
  return (
    <>
      <Sidebar>
        <div class="container">
          <div className="ch-content">
            <h2 class="ui header">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                  }}
                >
                  Previous
                </button>
              <Link to="/quizes/java_quiz2">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              </h2>
              <h1 class="start content ui left floated header">
                Introduction to Java
              </h1>
              <div className="ui clearing divider"></div>
            

            <div class="main-body">
              <h2 class="ui left floated header">
                Quiz # 1
              </h2>
              <span class="tut-btn"><Link to="/java/introduction">
                  <button class="ui secondary button">Read Tutorial</button>
                </Link></span>
              <div class="ui clearing divider"></div>
              <div>
                {questions.map((question, i) => (
                  <div key={question.id}>
                    <div class="con">
                    <div class="que">Q{i+1}:<span class="one-per-left-margin">{question.question}</span></div>
                      {question.options.map((option) => (
                        <label class="label" key={option}>
                          <input
                            type="radio"
                            name={`question_${question.id}`}
                            value={option}
                            checked={answers[question.id] === option}
                            onChange={(e) =>
                              handleAnswerChange(question.id, e.target.value)
                            }
                          />
                          <span className="one-per-left-margin">{option}</span>
                          <br />
                        </label>
                      ))}
                    </div>
                    <hr />
                  </div>
                ))}<br/>
                <center><button class="ui primary button" onClick={handleQuizSubmit}>Submit Quiz</button></center>

                {/* Modal for displaying results */}
                <Modal class="modal" isOpen={isModalOpen} onRequestClose={closeModal}>
                  <b style={{fontSize:'20px'}}>Quiz Results</b>
                  <b><p>Your score is {correctCount}/{questions.length} </p></b>
                  {questions.map((question,i) => (
                    <div key={question.id}>
                      <p>Q{i+1}: {question.question}</p>
                      <p><b>Correct Answer:</b> {question.correctAnswer}</p>
                      <p className={`${question.correctAnswer}` === `${answers[question.id]}` ? "correct" : "wrong"}>
                        <b>Your Answer:</b> {answers[question.id] || "Not answered"}
                      </p>
                      <hr />
                    </div>
                  ))}
                  <button class="ui secondary button" onClick={closeModal}>Close</button><br /><br />
                </Modal>
              </div>

              <br />
            </div>
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                  }}
                >
                  Previous
                </button>
              <Link to="/quizes/java_quiz2">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
            <br />
          </div>
        </div>
      </Sidebar>

      <div class="sideBarBody">
        <Footer />
      </div>
    </>
  );
};
export default JQuiz1;
