import React, { useState, useRef, useEffect } from "react";
import Sidebar from "./SideBar";
import "../../Components/SideBar/App.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const CodeAccordion = ({ questions, code, toggleAccordion, changeCodeLanguage }) => {
    const divRef = useRef(null);
    const copyContent = () => {
        const divContent = divRef.current.innerText;
        navigator.clipboard
          .writeText(divContent)
          .then(() => {
            toast.success("Content copied!", { position: "top-center" });
          })
          .catch((error) => {
            toast.error("Error copying content:", error, {
              position: "top-center",
            });
          });
      };
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };

  return (
    <div>
      {questions.map((item, index) => (
        <div key={index} className="border p-2 rounded-md">
          <div className="flex justify-between">
            <span className="text-lg font-semibold">
              {item.question}
            </span>
            <button
              className="bg-gray-200 px-2 py-1 rounded-md"
              onClick={() => toggleAccordion(index)}
            >
              {item.isOpen ? "-" : "+"}
            </button>
          </div>
          <div className={`mt-2 ${item.isOpen ? "block" : "hidden"}`}>
            <div className="mt-2 flex space-x-4">
              {Object.keys(code).map((language) => (
                <button
                  key={language}
                  onClick={() => changeCodeLanguage(index, language)}
                  className={`${
                    item.codeLanguage === language
                      ? "bg-blue-500 hover:bg-blue-600 text-white"
                      : "bg-gray-200 hover:bg-gray-300 text-gray-700"
                  } px-3 py-2 rounded-md transition duration-300 ease-in-out transform hover:scale-105`}
                >
                  {language}
                </button>
              ))}
            </div>
            <p className="text-gray-800">
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>output.cpp</b>
                  <i
                    className="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language={`${item.codeLanguage}`} style={darcula}>
                    {code[item.codeLanguage]}
                  </SyntaxHighlighter>
                </div>
              </div>
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

const Question7 = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  const code = {
    c: `// C code
#include <stdio.h>

int main() {
    int N, sum = 0;
    printf("Enter a positive integer N: ");
    scanf("%d", &N);

    for (int i = 1; i <= N; i++) {
        sum += i;
    }

    printf("The sum of numbers from 1 to %d is: %d\\n", N, sum);

    return 0;
}`,
    cpp: `// C++ code
#include <iostream>
using namespace std;

int main() {
    int N, sum = 0;
    cout << "Enter a positive integer N: ";
    cin >> N;

    for (int i = 1; i <= N; i++) {
        sum += i;
    }

    cout << "The sum of numbers from 1 to " << N << " is: " << sum << endl;

    return 0;
}`,
    java: `// Java code
import java.util.Scanner;

public class SumOfNumbers {
    public static void main(String[] args) {
        Scanner scanner = new Scanner(System.in);
        int N, sum = 0;

        System.out.print("Enter a positive integer N: ");
        N = scanner.nextInt();

        for (int i = 1; i <= N; i++) {
            sum += i;
        }

        System.out.println("The sum of numbers from 1 to " + N + " is: " + sum);
    }
}`,
    python: `# Python code
N = int(input("Enter a positive integer N: "))
sum = 0

for i in range(1, N + 1):
    sum += i

print(f"The sum of numbers from 1 to {N} is: {sum}")`,
  };
  const code1 = {
    c: `// C code
#include <stdio.h>

int main() {
    int number;
    long long factorial = 1;

    printf("Enter a non-negative integer: ");
    scanf("%d", &number);

    if (number < 0) {
        printf("Factorial is not defined for negative numbers.\\n");
    } else {
        for (int i = 1; i <= number; i++) {
            factorial *= i;
        }
        printf("Factorial of %d is %lld\\n", number, factorial);
    }

    return 0;
}`,
    cpp: `// C++ code
#include <iostream>
using namespace std;

int main() {
    int number;
    long long factorial = 1;

    cout << "Enter a non-negative integer: ";
    cin >> number;

    if (number < 0) {
        cout << "Factorial is not defined for negative numbers." << endl;
    } else {
        for (int i = 1; i <= number; i++) {
            factorial *= i;
        }
        cout << "Factorial of " << number << " is " << factorial << endl;
    }

    return 0;
}`,
    java: `// Java code
import java.util.Scanner;

public class FactorialCalculator {
    public static void main(String[] args) {
        Scanner scanner = new Scanner(System.in);
        int number;
        long factorial = 1;

        System.out.print("Enter a non-negative integer: ");
        number = scanner.nextInt();

        if (number < 0) {
            System.out.println("Factorial is not defined for negative numbers.");
        } else {
            for (int i = 1; i <= number; i++) {
                factorial *= i;
            }
            System.out.println("Factorial of " + number + " is " + factorial);
        }
    }
}`,
    python: `# Python code
number = int(input("Enter a non-negative integer: "))
factorial = 1

if number < 0:
    print("Factorial is not defined for negative numbers.")
else:
    for i in range(1, number + 1):
        factorial *= i
    print(f"Factorial of {number} is {factorial}")`,
  };
  const code2 = {
    c: `// C code
#include <stdio.h>

int main() {
    int N;

    printf("Enter a positive integer N: ");
    scanf("%d", &N);

    printf("Even numbers from 1 to %d are: ", N);
    for (int i = 2; i <= N; i += 2) {
        printf("%d ", i);
    }

    printf("\\n");

    return 0;
}`,
    cpp: `// C++ code
#include <iostream>
using namespace std;

int main() {
    int N;

    cout << "Enter a positive integer N: ";
    cin >> N;

    cout << "Even numbers from 1 to " << N << " are: ";
    for (int i = 2; i <= N; i += 2) {
        cout << i << " ";
    }

    cout << endl;

    return 0;
}`,
    java: `// Java code
import java.util.Scanner;

public class EvenNumbers {
    public static void main(String[] args) {
        Scanner scanner = new Scanner(System.in);
        int N;

        System.out.print("Enter a positive integer N: ");
        N = scanner.nextInt();

        System.out.print("Even numbers from 1 to " + N + " are: ");
        for (int i = 2; i <= N; i += 2) {
            System.out.print(i + " ");
        }

        System.out.println();
    }
}`,
    python: `# Python code
N = int(input("Enter a positive integer N: "))

print("Even numbers from 1 to", N, "are:", end=" ")
for i in range(2, N + 1, 2):
    print(i, end=" ")

print()`,
  };
  const code3 = {
    c: `// C code
#include <stdio.h>

int main() {
    int number;

    printf("Enter a number for the multiplication table: ");
    scanf("%d", &number);

    printf("Multiplication table for %d:\\n", number);
    for (int i = 1; i <= 10; i++) {
        printf("%d x %d = %d\\n", number, i, number * i);
    }

    return 0;
}`,
    cpp: `// C++ code
#include <iostream>
using namespace std;

int main() {
    int number;

    cout << "Enter a number for the multiplication table: ";
    cin >> number;

    cout << "Multiplication table for " << number << ":" << endl;
    for (int i = 1; i <= 10; i++) {
        cout << number << " x " << i << " = " << number * i << endl;
    }

    return 0;
}`,
    java: `// Java code
import java.util.Scanner;

public class MultiplicationTable {
    public static void main(String[] args) {
        Scanner scanner = new Scanner(System.in);
        int number;

        System.out.print("Enter a number for the multiplication table: ");
        number = scanner.nextInt();

        System.out.println("Multiplication table for " + number + ":");
        for (int i = 1; i <= 10; i++) {
            System.out.println(number + " x " + i + " = " + (number * i));
        }
    }
}`,
    python: `# Python code
number = int(input("Enter a number for the multiplication table: "))

print(f"Multiplication table for {number}:")
for i in range(1, 11):
    print(f"{number} x {i} = {number * i}")`,
  };
  const code4 = {
    c: `// C code
#include <stdio.h>

int main() {
    int number, isPrime = 1; // Assume the number is prime initially

    printf("Enter a positive integer: ");
    scanf("%d", &number);

    if (number <= 1) {
        isPrime = 0; // Numbers less than or equal to 1 are not prime
    } else {
        for (int i = 2; i <= number / 2; i++) {
            if (number % i == 0) {
                isPrime = 0; // Number is not prime
                break;
            }
        }
    }

    if (isPrime) {
        printf("%d is a prime number.\\n", number);
    } else {
        printf("%d is not a prime number.\\n", number);
    }

    return 0;
}`,
    cpp: `// C++ code
#include <iostream>
using namespace std;

int main() {
    int number;
    bool isPrime = true; // Assume the number is prime initially

    cout << "Enter a positive integer: ";
    cin >> number;

    if (number <= 1) {
        isPrime = false; // Numbers less than or equal to 1 are not prime
    } else {
        for (int i = 2; i <= number / 2; i++) {
            if (number % i == 0) {
                isPrime = false; // Number is not prime
                break;
            }
        }
    }

    if (isPrime) {
        cout << number << " is a prime number." << endl;
    } else {
        cout << number << " is not a prime number." << endl;
    }

    return 0;
}`,
    java: `// Java code
import java.util.Scanner;

public class PrimeNumberChecker {
    public static void main(String[] args) {
        Scanner scanner = new Scanner(System.in);
        int number;
        boolean isPrime = true; // Assume the number is prime initially

        System.out.print("Enter a positive integer: ");
        number = scanner.nextInt();

        if (number <= 1) {
            isPrime = false; // Numbers less than or equal to 1 are not prime
        } else {
            for (int i = 2; i <= number / 2; i++) {
                if (number % i == 0) {
                    isPrime = false; // Number is not prime
                    break;
                }
            }
        }

        if (isPrime) {
            System.out.println(number + " is a prime number.");
        } else {
            System.out.println(number + " is not a prime number.");
        }
    }
}`,
    python: `# Python code
number = int(input("Enter a positive integer: "))
is_prime = True  # Assume the number is prime initially

if number <= 1:
    is_prime = False  # Numbers less than or equal to 1 are not prime
else:
    for i in range(2, number // 2 + 1):
        if number % i == 0:
            is_prime = False  # Number is not prime
            break

if is_prime:
    print(number, "is a prime number.")
else:
    print(number, "is not a prime number.")`,
  };
  const code5 = {
    c: `// C code
#include <stdio.h>

int main() {
    int N, i;
    long long first = 0, second = 1, next;

    printf("Enter the value of N: ");
    scanf("%d", &N);

    printf("Fibonacci Sequence for first %d numbers:\\n", N);

    for (i = 1; i <= N; i++) {
        if (i == 1) {
            printf("%lld, ", first);
        } else if (i == 2) {
            printf("%lld, ", second);
        } else {
            next = first + second;
            printf("%lld, ", next);
            first = second;
            second = next;
        }
    }

    printf("\\n");

    return 0;
}`,
    cpp: `// C++ code
#include <iostream>
using namespace std;

int main() {
    int N, i;
    long long first = 0, second = 1, next;

    cout << "Enter the value of N: ";
    cin >> N;

    cout << "Fibonacci Sequence for first " << N << " numbers:" << endl;

    for (i = 1; i <= N; i++) {
        if (i == 1) {
            cout << first << ", ";
        } else if (i == 2) {
            cout << second << ", ";
        } else {
            next = first + second;
            cout << next << ", ";
            first = second;
            second = next;
        }
    }

    cout << endl;

    return 0;
}`,
    java: `// Java code
import java.util.Scanner;

public class FibonacciSequence {
    public static void main(String[] args) {
        Scanner scanner = new Scanner(System.in);
        int N, i;
        long first = 0, second = 1, next;

        System.out.print("Enter the value of N: ");
        N = scanner.nextInt();

        System.out.println("Fibonacci Sequence for first " + N + " numbers:");

        for (i = 1; i <= N; i++) {
            if (i == 1) {
                System.out.print(first + ", ");
            } else if (i == 2) {
                System.out.print(second + ", ");
            } else {
                next = first + second;
                System.out.print(next + ", ");
                first = second;
                second = next;
            }
        }

        System.out.println();
    }
}`,
    python: `# Python code
N = int(input("Enter the value of N: "))
first = 0
second = 1

print("Fibonacci Sequence for first", N, "numbers:")
for i in range(N):
    if i == 0:
        print(first, end=", ")
    elif i == 1:
        print(second, end=", ")
    else:
        next = first + second
        print(next, end=", ")
        first = second
        second = next

print()`,
  };
  const code6 = {
    c: `// C code
#include <stdio.h>

int main() {
    int number, originalNumber, reverseNumber = 0, remainder;

    printf("Enter a number: ");
    scanf("%d", &number);

    originalNumber = number;

    while (number != 0) {
        remainder = number % 10;
        reverseNumber = reverseNumber * 10 + remainder;
        number /= 10;
    }

    if (originalNumber == reverseNumber) {
        printf("%d is a palindrome.\\n", originalNumber);
    } else {
        printf("%d is not a palindrome.\\n", originalNumber);
    }

    return 0;
}`,
    cpp: `// C++ code
#include <iostream>
using namespace std;

int main() {
    int number, originalNumber, reverseNumber = 0, remainder;

    cout << "Enter a number: ";
    cin >> number;

    originalNumber = number;

    while (number != 0) {
        remainder = number % 10;
        reverseNumber = reverseNumber * 10 + remainder;
        number /= 10;
    }

    if (originalNumber == reverseNumber) {
        cout << originalNumber << " is a palindrome." << endl;
    } else {
        cout << originalNumber << " is not a palindrome." << endl;
    }

    return 0;
}`,
    java: `// Java code
import java.util.Scanner;

public class PalindromeChecker {
    public static void main(String[] args) {
        Scanner scanner = new Scanner(System.in);
        int number, originalNumber, reverseNumber = 0, remainder;

        System.out.print("Enter a number: ");
        number = scanner.nextInt();

        originalNumber = number;

        while (number != 0) {
            remainder = number % 10;
            reverseNumber = reverseNumber * 10 + remainder;
            number /= 10;
        }

        if (originalNumber == reverseNumber) {
            System.out.println(originalNumber + " is a palindrome.");
        } else {
            System.out.println(originalNumber + " is not a palindrome.");
        }
    }
}`,
    python: `# Python code
number = int(input("Enter a number: "))
originalNumber = number
reverseNumber = 0

while number != 0:
    remainder = number % 10
    reverseNumber = reverseNumber * 10 + remainder
    number //= 10

if originalNumber == reverseNumber:
    print(originalNumber, "is a palindrome.")
else:
    print(originalNumber, "is not a palindrome.")`,
  };
  const code7 = {
    c: `// C code
#include <stdio.h>

int main() {
    int number;

    printf("Enter a positive integer: ");
    scanf("%d", &number);

    printf("Prime factorization of %d is: ", number);

    for (int i = 2; i <= number; i++) {
        while (number % i == 0) {
            printf("%d ", i);
            number /= i;
        }
    }

    printf("\\n");

    return 0;
}`,
    cpp: `// C++ code
#include <iostream>
using namespace std;

int main() {
    int number;

    cout << "Enter a positive integer: ";
    cin >> number;

    cout << "Prime factorization of " << number << " is: ";

    for (int i = 2; i <= number; i++) {
        while (number % i == 0) {
            cout << i << " ";
            number /= i;
        }
    }

    cout << endl;

    return 0;
}`,
    java: `// Java code
import java.util.Scanner;

public class PrimeFactorization {
    public static void main(String[] args) {
        Scanner scanner = new Scanner(System.in);
        int number;

        System.out.print("Enter a positive integer: ");
        number = scanner.nextInt();

        System.out.print("Prime factorization of " + number + " is: ");

        for (int i = 2; i <= number; i++) {
            while (number % i == 0) {
                System.out.print(i + " ");
                number /= i;
            }
        }

        System.out.println();
    }
}`,
    python: `# Python code
number = int(input("Enter a positive integer: "))

print(f"Prime factorization of {number} is:", end=" ")

for i in range(2, number + 1):
    while number % i == 0:
        print(i, end=" ")
        number //= i

print()`,
  };
  const code8 = {
    c: `// C code
#include <stdio.h>

int main() {
    int base, exponent;
    long long result = 1;

    printf("Enter the base: ");
    scanf("%d", &base);
    printf("Enter the exponent: ");
    scanf("%d", &exponent);

    for (int i = 1; i <= exponent; i++) {
        result *= base;
    }

    printf("%d^%d = %lld\\n", base, exponent, result);

    return 0;
}`,
    cpp: `// C++ code
#include <iostream>
using namespace std;

int main() {
    int base, exponent;
    long long result = 1;

    cout << "Enter the base: ";
    cin >> base;
    cout << "Enter the exponent: ";
    cin >> exponent;

    for (int i = 1; i <= exponent; i++) {
        result *= base;
    }

    cout << base << "^" << exponent << " = " << result << endl;

    return 0;
}`,
    java: `// Java code
import java.util.Scanner;

public class PowerCalculator {
    public static void main(String[] args) {
        Scanner scanner = new Scanner(System.in);
        int base, exponent;
        long result = 1;

        System.out.print("Enter the base: ");
        base = scanner.nextInt();
        System.out.print("Enter the exponent: ");
        exponent = scanner.nextInt();

        for (int i = 1; i <= exponent; i++) {
            result *= base;
        }

        System.out.println(base + "^" + exponent + " = " + result);
    }
}`,
    python: `# Python code
base = int(input("Enter the base: "))
exponent = int(input("Enter the exponent: "))
result = 1

for i in range(exponent):
    result *= base

print(f"{base}^{exponent} = {result}")`,
  };
  const code9 = {
    c: `// C code
#include <stdio.h>

int main() {
    int num1, num2;

    printf("Enter the first number: ");
    scanf("%d", &num1);
    printf("Enter the second number: ");
    scanf("%d", &num2);

    int a = num1, b = num2;
    
    while (a != b) {
        if (a > b) {
            a -= b;
        } else {
            b -= a;
        }
    }
    
    int gcd = a;
    int lcm = (num1 * num2) / gcd;

    printf("GCD of %d and %d is %d\\n", num1, num2, gcd);
    printf("LCM of %d and %d is %d\\n", num1, num2, lcm);

    return 0;
}`,
    cpp: `// C++ code
#include <iostream>
using namespace std;

int main() {
    int num1, num2;

    cout << "Enter the first number: ";
    cin >> num1;
    cout << "Enter the second number: ";
    cin >> num2;

    int a = num1, b = num2;
    
    while (a != b) {
        if (a > b) {
            a -= b;
        } else {
            b -= a;
        }
    }
    
    int gcd = a;
    int lcm = (num1 * num2) / gcd;

    cout << "GCD of " << num1 << " and " << num2 << " is " << gcd << endl;
    cout << "LCM of " << num1 << " and " << num2 << " is " << lcm << endl;

    return 0;
}`,
    java: `// Java code
import java.util.Scanner;

public class GCDLCMCalculator {
    public static void main(String[] args) {
        Scanner scanner = new Scanner(System.in);
        int num1, num2;

        System.out.print("Enter the first number: ");
        num1 = scanner.nextInt();
        System.out.print("Enter the second number: ");
        num2 = scanner.nextInt();

        int a = num1, b = num2;

        while (a != b) {
            if (a > b) {
                a -= b;
            } else {
                b -= a;
            }
        }

        int gcd = a;
        int lcm = (num1 * num2) / gcd;

        System.out.println("GCD of " + num1 + " and " + num2 + " is " + gcd);
        System.out.println("LCM of " + num1 + " and " + num2 + " is " + lcm);
    }
}`,
    python: `# Python code
num1 = int(input("Enter the first number: "))
num2 = int(input("Enter the second number: "))

a, b = num1, num2

while a != b:
    if a > b:
        a -= b
    else:
        b -= a

gcd = a
lcm = (num1 * num2) // gcd

print(f"GCD of {num1} and {num2} is {gcd}")
print(f"LCM of {num1} and {num2} is {lcm}")`,
  };

  

  const [questions, setQuestions] = useState([
    {
      question: "Q1: Write a program that calculates the sum of all numbers from 1 to N using loops.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions1, setQuestions1] = useState([
    {
      question: "Q2: Write a program to calculate the factorial of a given number using loops.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions2, setQuestions2] = useState([
    {
      question: "Q3: Print all even numbers from 1 to N using a loop.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions3, setQuestions3] = useState([
    {
      question: "Q4: Generate a multiplication table for a given number from 1 to 10 using loops.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions4, setQuestions4] = useState([
    {
      question: "Q5: Write a program to check if a given number is prime or not using loops.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions5, setQuestions5] = useState([
    {
      question: "Q6: Generate the first N numbers of the Fibonacci sequence using loops.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions6, setQuestions6] = useState([
    {
      question: "Q7: Write a program to check if a given string or number is a palindrome using loops.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions7, setQuestions7] = useState([
    {
      question: "Q8: Find the prime factorization of a given number using loops.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions8, setQuestions8] = useState([
    {
      question: "Q9: Write a program to calculate the power of a number (e.g., a^b) using loops.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions9, setQuestions9] = useState([
    {
      question: "Q10: Take two numbers as input from the user. Find the greatest common divisor (GCD) and least common multiple (LCM) of the two numbers. Use for loop for this purpose.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);

  const toggleAccordion = (index, questions, setQuestions) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].isOpen = !updatedQuestions[index].isOpen;
    setQuestions(updatedQuestions);
  };

  const changeCodeLanguage = (index, language, questions, setQuestions) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].codeLanguage = language;
    setQuestions(updatedQuestions);
  };

  

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };
  const divRef = useRef(null);
  return (
    <div className="testing">
      <Sidebar>
        <div className="containera">
          <div className="ch-content">
            <h2 className="ui header">
            <Link to="/ques6">
              <button
                className="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
              </Link>
              <Link to="/ques8">
                <button
                  className="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>
            <div className="main-body ui ">
              <h2 className="ui left floated header" style={{ color: "#001C30" }}>
                For Loop:
              </h2>
              <div className="ui clearing divider"></div>
              <CodeAccordion
                questions={questions}
                code={code}
                toggleAccordion={(index) => toggleAccordion(index, questions, setQuestions)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions, setQuestions)
                }
              /><br/>
              <CodeAccordion
                questions={questions1}
                code={code1}
                toggleAccordion={(index) => toggleAccordion(index, questions1, setQuestions1)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions1, setQuestions1)
                }
              />
              <br/>
              <CodeAccordion
                questions={questions2}
                code={code2}
                toggleAccordion={(index) => toggleAccordion(index, questions2, setQuestions2)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions2, setQuestions2)
                }
              />
              <br/>
              <CodeAccordion
                questions={questions3}
                code={code3}
                toggleAccordion={(index) => toggleAccordion(index, questions3, setQuestions3)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions3, setQuestions3)
                }
              />
              <br/>
              <CodeAccordion
                questions={questions4}
                code={code4}
                toggleAccordion={(index) => toggleAccordion(index, questions4, setQuestions4)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions4, setQuestions4)
                }
              />
              <br/>
              <CodeAccordion
                questions={questions5}
                code={code5}
                toggleAccordion={(index) => toggleAccordion(index, questions5, setQuestions5)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions5, setQuestions5)
                }
              />
              <br/>
              <CodeAccordion
                questions={questions6}
                code={code6}
                toggleAccordion={(index) => toggleAccordion(index, questions6, setQuestions6)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions6, setQuestions6)
                }
              />
              <br/>
              <CodeAccordion
                questions={questions7}
                code={code7}
                toggleAccordion={(index) => toggleAccordion(index, questions7, setQuestions7)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions7, setQuestions7)
                }
              />
              <br/>
              <CodeAccordion
                questions={questions8}
                code={code8}
                toggleAccordion={(index) => toggleAccordion(index, questions8, setQuestions8)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions8, setQuestions8)
                }
              />
              <br/>
              <CodeAccordion
                questions={questions9}
                code={code9}
                toggleAccordion={(index) => toggleAccordion(index, questions9, setQuestions9)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions9, setQuestions9)
                }
              />
            </div><br />
            <Link to="/ques6">
              <button
                className="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
              </Link>
              <Link to="/ques8">
                <button
                  className="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default Question7;
