import { useRef, useEffect } from "react";
import "../../Components/Chapters/Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Sidebar from "../JavaSideBar";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const JavaC17 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };

  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };
  const jc17code1 = `for (initialization; condition; update) {
    // Code to be executed repeatedly
}
`;
  const jc17code2 = `public class Main {
  public static void main(String[] args) {
      
    for (int i = 1; i <= 5; i++) {
        System.out.println("Count is: " + i);
    }
 
  }
}`;
  const jc17code3 = `public class Main {
    public static void main(String[] args) {
        
      for (;;) {
          System.out.println("Hi there!");
      }
   
    }
  }`;
  const jc17code4 = `public class Main {
    public static void main(String[] args) {
        
        for (int i = 1; i <= 3; i++) {
          System.out.println("Outer: " + i); 
          
          for (int j = 1; j <= 3; j++) {
            System.out.println("\\nInner: " + j); 
          }
        } 
   
    }
  }`;
  const jc17code5 = `public class Main {
    public static void main(String[] args) {
        
      String[] fruits = {"Apple", "Mango", "Banana"};
      for (String fruit : fruits) {
          System.out.println(fruit);
      }
   
    }
}`;
  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/java/switch">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/java/while_loop">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body ">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                For Loop:
              </h2>
              <div class="ui clearing divider"></div>
              <p style={{ backgroundColor: "", padding: "" }}>
              In Java, the for loop is a control inflow statement that allows you to repeatedly execute a block of code a specified number of times. It is particularly useful when you already know the number of iterations.
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>forLoop.java</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="java" style={darcula}>
                      {jc17code1}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <b>initialization - </b>assign value to a variable. <br />
                <b>condition - </b>check condition. <br />
                <b>update - </b>change the value of variable. <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>forLoop.java</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="java" style={darcula}>
                      {jc17code2}
                    </SyntaxHighlighter>
                  </div>
                </div>
              </p>
              <h1>
              In this Example:
              </h1>
              <ul className="ui unordered list">
                <li>Statement-1 shows i=0.</li>
                <li>Statement-2 checks condition, i is less than 5(true), move inside loop and print count.</li>
                <li>Statement-3 will increment i, now i=1. Statement-2 checks condition, i is less than 5(true), move inside loop and print count.</li>
                <li>Statement-3 will increment i, now i=2. Statement-2 checks condition i is less than 5(true), move inside loop and print count.</li>
                <li>Statement-3 will increment i, now i=3. Statement-2 checks condition i is less than 5(true), move inside loop and print count.</li>
                <li>Statement-3 will increment i, now i=4. Statement-2 checks condition i is less than 5(true), move inside loop and print count.</li>
                <li>Statement-3 will increment i, now i=5. Statement-2 checks condition i is less than 5(false), loop will terminate and control moves to next line.</li>
              </ul>

              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Infinite For Loop:
              </h2>
              <div class="ui clearing divider"></div>
              <span>If you put the double semicolon inside paranthesis, it will become infinite for loop.</span>
              <p style={{ backgroundColor: "", padding: "" }}>
                
                <br />

                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>forLoop.java</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="java" style={darcula}>
                      {jc17code3}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
              </p>

              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Nested For Loop:
              </h2>
              <div class="ui clearing divider"></div>
              <span>Nested loops are loops within loops. When we have a nested loops then, when outer loop runs one-time, inner loops complete its all iterations. After that control moves to the outer loop. Again, the outer loop runs a second time and inner loop completes its all iterations. This process will continue until the outer loop completes its all iterations.</span>
              <p style={{ backgroundColor: "", padding: "" }}>
               
                <br />

                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>forLoop.java</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="java" style={darcula}>
                      {jc17code4}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
              </p>

              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                For each Loop:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
              In Java, the "for-each" loop, also known as the enhanced for loop, provides a convenient way to iterate through elements in an array or collection (such as ArrayList, List, Set, or Map) without the need for explicit index-based iteration.
              </span>
              <p style={{ backgroundColor: "", padding: "" }}>
               
                <br />

                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>forLoop.java</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="java" style={darcula}>
                      {jc17code5}
                    </SyntaxHighlighter>
                  </div>
                </div>
              </p>
            </div>

            <br />
            <Link to="/java/switch">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/java/while_loop">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default JavaC17;
