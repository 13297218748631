import { useRef, useEffect } from "react";
import Footer from "../../Components/Footer";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./style.css";

const JavaatGlance = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    padding: "20px",
    borderRadius: "3px",
    marginLeft: "0%",
  };
  const code1 = `public class Main {
    public static void main(String[] args) {
        //code goes here
    }
}`;
  const code2 = `System.out.println("Hello World");`;
  const code3 = `import java.util.Scanner;

  public class Main {
      public static void main(String[] args) {
          Scanner scanner = new Scanner(System.in);
  
          System.out.print("Enter your name: ");
          String name = scanner.nextLine();
  
          System.out.print("Enter your age: ");
          int age = scanner.nextInt();
  
          scanner.close();
  
          System.out.println("Hello, " + name);
          System.out.println("Your age is: " + age);
      }
  }`     
  const code4 = `//this is a single lined comment.

/*this is a 
multi-lined comment*/`;
  const code5 = `int sum = 10;`;
  const code6 = `int sum = 10; //stores integer
float average = 90.33; //stores numbers with decimal
double percentage = 80.3452; //stores numbers with decimal but precision is double as compared to float
char letter = 'A'; //stores single character
bool isTrue = true; //stores true or false
string intro = "My name is Sajeel"; //stores sequence of characters
void myFunc();//it represent the absence of data type. Mostly used with functions`;
  const code7 = `//declaring string
char fname[] = "John";
char lname[] = "Doe";

//concatenating strings
String name = fname + lname; // Using the + operator
// or
String name = fname.concat(lname); // Using the concat method

//finding length of string
String myString = "Hello, World!";
int length = myString.length();
System.out.println("Length: " + length);

//accessing characters of string
String myString = "Hello, World!";
char firstChar = myString.charAt(0);
System.out.println(firstChar);

//Changing characters
String myString = "Hello, World!";
myString = myString.replace('H', 'A'); // Replace 'H' with 'A'
System.out.println(myString);

//taking string input ignoring spaces
import java.util.Scanner;

public class Main {
    public static void main(String[] args) {
        Scanner scanner = new Scanner(System.in);
        System.out.print("Enter a string: ");
        String myString = scanner.next();
        System.out.println("You entered: " + myString);
        scanner.close();
    }
}`;
  const code8 = `Arithmetic Operators
+ //addition
- //subtraction
* //multiplication
/ //division
% //modulus
++ //increment
-- //decrement
Assignment Operators
= //assign value
+= //add and assign
-= //subtract and assign
/= //divide and assign
*= //multiply and assign
%= //taking modulus and assign
Comparison Operators
== //checks equality
!= //checks unequality
> //checks greater than
>= //checks greater than or equal to
< //check less than
<= //checks less than or equal to
Logical Operators
&& //returns true if all are expressions true
|| //returns true if only one expression is true
! //returns true if false and vice versa
Bitwise Operators
& //bitwise AND
| //bitwise OR
~ //bitwise NOT
>> //shift right 
<< //shift left
`;
  const code9 = `//if statement
if(condition){
    //code
}

//if-else statement
if(condition){
    //code
}
else{
    //code
}

//else-if statement
if(condition){
    //code
}
else if(condition){
    //code
}
else{
    //code
}

//goto statement
int main(){
  //code
  label:
  // code
  goto label;
  // code
}

//Ternary Operator
variable = condition ? ifTrue : ifFalse;
`;
  const code10 = `switch(expression){
    case const-exp:
        //code
        break;
    case const-exp:
        //code
        break;
    .
    .
    .
    default:
        //code
        break;
}`;

  const code11 = `//for Loop:
for( initialization ; condition ; change-variable-value ){
    //code
}

//for each loop
for (int i : arrayName) {
    System.out.println(i);
}


//while Loop:
initialize variable
while(condition){
    //code
    //change-variable-value
}

//do-while Loop:
initialize variable
do{
    //code
    //change-variable-value
}while(condition);`;

  const code12 = `// Initializing arrays
int[] arrayName = {data1, data2, ...};
  
// Printing array
for (int i = 0; i < arrayName.length; i++) {
    System.out.printf("%d%n", arrayName[i]);
}
  
// Taking input in array
for (int i = 0; i < arrayName.length; i++) {
    System.out.printf("Enter data at %d index: ", i + 1);
    Scanner scanner = new Scanner(System.in);
    arrayName[i] = scanner.nextInt();
}
  
// Changing array elements at specified index
int[] myArray = {1, 2, 3};
myArray[0] = 5; // Now the array has 5 at the first index `;


  const code15 = `public class HelloWorld {
    static int add(int a, int b) {
        return a + b;
    }

    public static void main(String[] args) {
        int sum = add(5, 3);
        System.out.println("Sum: " + sum);
    }
}
`;
  const code16 = `//break statement
for( initialization ; condition ; change-variable-value ){
  if(condition){
    break;  //moves out of the loop when condition is true
  }
}

//continue statement
for( initialization ; condition ; change-variable-value ){
  if(condition){
    continue;  //skip the iteration when condition is true
  }
}`;
  const code17 = `//escape sequences
\\n            //adds a new line
\\t             //adds four spaces
\\b            //backspace
\\a            //beep sound
\\f             //form feed
\\r            //carriage return
\\\\           //adds backslash
\\'            //adds single quote
\\?           //adds question mark
\\0          //null character`;

  const code18 = `import java.util.Scanner;  // Import the Scanner class for input
import java.util.Date;     // Import the Date class for date/time
  
public class Main {
    public static void main(String[] args) {
        // Input and Output functions
        System.out.printf("%s", "Hello, World");   // Formatted output
        Scanner scanner = new Scanner(System.in);
        String input = scanner.nextLine();  // Formatted input
        char character = scanner.next().charAt(0);  // Character input
        System.out.print(character);  // Character output
        String str = "Hello, World";
        System.out.println(str);  // Printing strings
 
        // String functions
        String str1 = "Hello";
        String str2 = "World";
        int length = str1.length();  // Length of a string
        boolean equal = str1.equals(str2);  // String comparison
        boolean equalIgnoreCase = str1.equalsIgnoreCase(str2);  // Case-insensitive comparison
        boolean startsWith = str1.startsWith("Hel");  // Starts with
        boolean endsWith = str2.endsWith("rld");  // Ends with
        String concatenated = str1.concat(str2);  // String concatenation
        String substring = str1.substring(1, 4);  // Substring
        String replaced = str1.replace('l', 'z');  // Replace
        String upper = str1.toUpperCase();  // Uppercase
        String lower = str2.toLowerCase();  // Lowercase
        String reversed = new StringBuilder(str1).reverse().toString();  // Reverse

        // Math functions
        double x = 4.5;
        double y = 2.0;
        double power = Math.pow(x, y);  // Exponentiation
        double sqrt = Math.sqrt(x);  // Square root
        double floorValue = Math.floor(x);  // Floor
        double ceilValue = Math.ceil(x);  // Ceiling
        long rounded = Math.round(x);  // Round
        double remainder = Math.IEEEremainder(x, y);  // Remainder
        double cosine = Math.cos(x);  // Cosine
        double sine = Math.sin(x);  // Sine
        double tangent = Math.tan(x);  // Tangent
        double logarithm = Math.log(x);  // Natural logarithm
        double logarithm10 = Math.log10(x);  // Base-10 logarithm

        // Date and time functions
        Date startTime = new Date();  // Get the current time
        // Perform some task or wait
        Date endTime = new Date();
        long difference = endTime.getTime() - startTime.getTime();
        double differenceInSeconds = difference / 1000.0;
        System.out.printf("Time elapsed: %.2f seconds\\n", differenceInSeconds);
    }
}`;
  
  const code27 = `try {
    // Code that may throw an exception
} catch (ExceptionType e) {
    // Exception handling code
} finally {
    // Cleanup code
}`;


const code29 = `import java.io.File;
import java.io.FileNotFoundException;
import java.util.Scanner;

public class FileHandling {
    public static void main(String[] args) {
        try {
            File file = new File("example.txt");
            Scanner scanner = new Scanner(file);

            while (scanner.hasNextLine()) {
                String data = scanner.nextLine();
                System.out.println(data);
            }

            scanner.close();
        } catch (FileNotFoundException e) {
            System.out.println("An error occurred.");
            e.printStackTrace();
        }
    }
}`
const code31 = `public class HelloWorld {
    public static void main(String[] args) {
        byte age = 18;
        System.out.println(age);

        long var = 900;
        System.out.println(var);

        float price = 100.05;
        System.out.println(price);

        char letter = 'A';
        System.out.println(letter);

        int var1 = 256;
        System.out.println(var1);

        short var2 = 5666;
        System.out.println(var2);
    }
}`
const code32 = `public class Declaration {
    final double PI = 3.14;

    public static void main(String[] args) {
        System.out.println("Value of PI: " + PI);
    }
}`
const code33 = `//type casting
int x = 45;
double var_name = x;
System.out.println(var_name);`

const code34 = `public class MathOperations {
    // Method to add two integers
    public int add(int a, int b) {
        return a + b;
    }

    // Method to add three integers
    public int add(int a, int b, int c) {
        return a + b + c;
    }

    // Method to add two double values
    public double add(double a, double b) {
        return a + b;
    }

    // Method to concatenate two strings
    public String add(String str1, String str2) {
        return str1 + str2;
    }

    public static void main(String[] args) {
        MathOperations math = new MathOperations();

        System.out.println("Sum of two integers: " + math.add(5, 7));
        System.out.println("Sum of three integers: " + math.add(2, 4, 6));
        System.out.println("Sum of two doubles: " + math.add(3.5, 2.5));
        System.out.println("Concatenated strings: " + math.add("Hello, ", "World!"));
    }
}`

const code35 = `class ClassName {
    // code goes here
}`

const code36 = `className object = new className();`

const code37 = `public class Vehicle
{ 
  private int price; // using private access modifier 

  // Getter 
  public int getPrice() 
  { 
   return price; 
  } 

  // Setter  
  public void setPrice(int price) 
  { 
    this.price = price; 
  } 
}`

const code38 = `class Child extends Parent { 
    //class implementation
}`

const code39 = `// A class with multiple methods with the same name 
public class Adder 
{ 
// method 1 
  public void add(int a, int b) 
  { 
    System.out.println(a + b); 
  } 

  // method 2 
  public void add(int a, int b, int c)
  { 
    System.out.println(a + b + c); 
  } 

  // method 3 
  public void add(String a, String b) 
  { 
    System.out.println(a + " + " + b); 
  }  
} 

// Main class 
class Main 
{ 
  public static void main(String[] args) 
  { 
    Adder adder = new Adder(); // create a Adder object 
    adder.add(5, 4); // invoke method 1 
    adder.add(5, 4, 3); // invoke method 2 
    adder.add("5", "4"); // invoke method 3 
  } 
}`

  return (
    <>
      
      <br />
      <br />
      <br />
      <br />

      <div>
        <div
          class=""
          style={{
            width: "80%",
            marginLeft: "10%",
            marginBottom: "5%"
          }}
        >
          <h2 class="ui left floated header">Java at Glance</h2>
          <div class="ui clearing divider"></div>

          <p>
            <span class="h1">Basic Syntax</span>
            <div class="ui clearing divider"></div>
            <div>Basic syntax for a Java code: </div>

            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="java" style={darcula}>
                  {code1}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

          <p>
            <span class="h1">System.out.println</span>
            <div class="ui clearing divider"></div>
            <div>
              System.out.println() is used to print on console.{" "}
            </div>
            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="java" style={darcula}>
                  {code2}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

          <p>
            <span class="h1">scanner</span>
            <div class="ui clearing divider"></div>
            <div>
              scanner is used to take input from
              user.{" "}
            </div>
            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="java" style={darcula}>
                  {code3}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />


          <p>
            <span class="h1">Comments</span>
            <div class="ui clearing divider"></div>
            <div>
              Comments are used to make your code more readable. Everything in
              comments are ignored by the compiler.
            </div>
            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="java" style={darcula}>
                  {code4}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

          <p>
            <span class="h1">Java Constants</span>
            <div class="ui clearing divider"></div>
            
            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="java" style={darcula}>
                  {code32}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

          <p>
            <span class="h1">Escape Sequences</span>
            <div class="ui clearing divider"></div>
            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="java" style={darcula}>
                  {code17}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

          <p>
            <span class="h1">Variables</span>
            <div class="ui clearing divider"></div>
            <div>Variables are used to store data of specified data type.</div>
            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="java" style={darcula}>
                  {code5}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

          <p>
            <span class="h1">Data Types</span>
            <div class="ui clearing divider"></div>
            <div>
              Data type specifies the type of data, stored in a variable.
            </div>
            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="java" style={darcula}>
                  {code6}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

          <p>
            <span class="h1">Type Casting</span>
            <div class="ui clearing divider"></div>
            <div>
              Type casting refers to changing data type of a variable.
            </div>
            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="java" style={darcula}>
                  {code33}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

          <p>
            <span class="h1">String and its methods</span>
            <div class="ui clearing divider"></div>
            <div>Strings are sequence of characters.</div>
            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="java" style={darcula}>
                  {code7}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

          <p>
            <span class="h1">Operators</span>
            <div class="ui clearing divider"></div>

            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="java" style={darcula}>
                  {code8}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

          <p>
            <span class="h1">Conditional statements</span>
            <div class="ui clearing divider"></div>
            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="java" style={darcula}>
                  {code9}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

          <p>
            <span class="h1">Switch Statements</span>
            <div class="ui clearing divider"></div>
            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="java" style={darcula}>
                  {code10}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

          <p>
            <span class="h1">Loops</span>
            <div class="ui clearing divider"></div>

            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="java" style={darcula}>
                  {code11}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

          <p>
            <span class="h1">Continue And Break Statements</span>
            <div class="ui clearing divider"></div>

            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="java" style={darcula}>
                  {code16}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

          <p>
            <span class="h1">Arrays</span>
            <div class="ui clearing divider"></div>
            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="java" style={darcula}>
                  {code12}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />


          <p>
            <span class="h1">Methods</span>
            <div class="ui clearing divider"></div>

            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="java" style={darcula}>
                  {code15}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />


          <p>
            <span class="h1">Method Overloading</span>
            <div class="ui clearing divider"></div>

            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="java" style={darcula}>
                  {code34}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

          <p>
            <span class="h1">Built-in Functions</span>
            <div class="ui clearing divider"></div>

            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="java" style={darcula}>
                  {code18}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

          <p>
            <span class="h1">Exceptions</span>
            <div class="ui clearing divider"></div>

            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="java" style={darcula}>
                  {code27}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />


          <p>
            <span class="h1">Class</span>
            <div class="ui clearing divider"></div>

            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="java" style={darcula}>
                  {code35}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

          <p>
            <span class="h1">Object</span>
            <div class="ui clearing divider"></div>

            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="java" style={darcula}>
                  {code36}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

          <p>
            <span class="h1">Encapsulation</span>
            <div class="ui clearing divider"></div>

            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="java" style={darcula}>
                  {code37}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />


          <p>
            <span class="h1">Inheritance</span>
            <div class="ui clearing divider"></div>

            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="java" style={darcula}>
                  {code38}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

          <p>
            <span class="h1">Polymorphism</span>
            <div class="ui clearing divider"></div>

            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="java" style={darcula}>
                  {code39}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

          <p>
            <span class="h1">File Handling</span>
            <div class="ui clearing divider"></div>

            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="java" style={darcula}>
                  {code29}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

         
        </div>
      </div>
      <Footer />
    </>
  );
};
export default JavaatGlance;
