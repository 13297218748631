import { useRef, useEffect } from "react";
// import "./Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Sidebar from "../CSideBar";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const CC19 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor:'#D8D9DA'
  };
  const code1 = `int marks[5] = {88, 89, 80, 90, 98};`;
  const code2 = `#include <stdio.h>

int main(){
  int marks [5] = { 76, 55, 92, 65 };
  printf("%d\\n", marks[0])
  printf("%d\\n", marks[1])
  printf("%d\\n", marks[2])
  printf("%d\\n", marks[3])
  printf("%d\\n", marks[4])
  return 0;
}`;
  const code3 = `#include <stdio.h>

int main(){
  int marks [5] = { 76, 55, 92, 65 };
     
  for(int i = 0; i < 5; i++){
    printf("%d\\n", marks[i]);
  }
  return 0;
}`;
  const code4 = `#include <stdio.h>
  
int main(){
  int marks [5] ;
  for(int i = 0; i < 5;  i++){ 
    printf("Enter data at %d index ", i+1);
    scanf("%d",&marks[i]);
  }
  for(int i = 0; i < 5;  i++){ 
    printf("Marks : %d\\n", marks[i]);
  }
}`;

  const code5 = `#include <stdio.h>

int main(){
  int marks [5] = { 76, 55, 92, 65 };
  printf("%d\\n", marks[0]);
  marks[0] = 100;
  printf("%d\\n", marks[0]);
  return 0;
}`;
  const code6 = `#include <stdio.h>

int main(){
  int marks [] = { 76, 55, 92, 65, 90 };
  printf("%d\\n", marks[0]);
  printf("%d\\n", marks[1]);
  printf("%d\\n", marks[2]);
  printf("%d\\n", marks[3]);
  printf("%d\\n", marks[4]);
  return 0;
}`;
  const code7 = `int items = sizeof(array) / sizeof(datatype)`;

  const code8 = `#include <stdio.h>

int main(){
  int marks [] = { 21, 89, 76 };
  int size = sizeof(marks)/sizeof(int);
  for(int i = 0; i < size;  i++){ 
    printf("%d\\n",marks[i]);
  }
}`;

  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/C/continue_break">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/C/pointers">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Arrays:
              </h2>
              <div class="ui clearing divider"></div>
              <p>
                Array is a data type which is used to store multiple values of
                same data type in a single variable.
              </p>
              <br />
              In C, if you want to store marks of ten students, you will have to
              declare ten variables. But if you use arrays, you do not have to
              declare ten variables. As data type of all these variables is
              same, you can use only one array to store them. You can set the
              number of elements in a<br />
              <br />
                <SyntaxHighlighter language="c" style={darcula}>
                  {code1}
                </SyntaxHighlighter>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Accessing Elements of Array:
              </h2>
              <div class="ui clearing divider"></div>
              We can use indexes to access elements of array. <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>array.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code2}
                  </SyntaxHighlighter>
              </div>
              <br />
              <b>Problem:</b>
              <br />
              It is easy to print when size of array is 10, 20, 30 or 100 items. But
              imagine if the size of array is 1 million items, is it good to print
              individual indexes like this? What we are doing here? We are printing
              the items over and over again. And we have seen that when we
              need to repeat our same block of code, we use loops.
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>array.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code3}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Taking input in arrays:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                We can take input in arrays with the help of cin object and extraction in the same way 
                we take input in out int or other variables. We can manually take input in arrays at 
                specified indexes or we can use loop to take input in arrays. <br /><br />
              </span>
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>array.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code4}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Changing elements of array:
              </h2>
              <div class="ui clearing divider"></div>
              We can change the elements of array at any index we want. <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>array.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code5}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <p style={{ backgroundColor: "#FBEEAC", padding: "10px" }}>
                <b>Note: </b>If you do not specify the size of array, it is ok.
              </p>
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>array.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code6}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <p style={{ backgroundColor: "#FBEEAC", padding: "10px" }}>
                <b>Note: </b>Point: If we do not know the size of array, to which number
                we will run the loop?
              </p>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Find size of array:
              </h2>
              <div class="ui clearing divider"></div>
              sizeof() operator as discussed earlier will give you the size of
              array. Size of array does not gives you the number of elements in
              array. As size of int is 4 bytes and we have three integers, so
              size of array will be 12 bytes. For this purpose, we will
              divide the size of array with the size of datatype. <br />
                <SyntaxHighlighter language="c" style={darcula}>
                  {code7}
                </SyntaxHighlighter>
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>array.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code8}
                  </SyntaxHighlighter>
                </div>
              </div>
              
            </div><br />
            <Link to="/C/continue_break">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/C/pointers">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default CC19;
