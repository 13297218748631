import { useRef, useEffect } from "react";
import "../../Components/Chapters/Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Sidebar from "../JavaSideBar";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const JavaC29 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };

  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };
  const jc29code1 = `class Animal {
  void makeSound() {
    System.out.println("Animal makes a sound.");
  }
}

class Dog extends Animal {
  @Override
  void makeSound() {
    System.out.println("Dog barks.");
  }
}

class Cat extends Animal {
  @Override
  void makeSound() {
    System.out.println("Cat meows.");
  }
}

public class Main {
  public static void main(String[] args) {
    Animal myAnimal1 = new Dog();
    Animal myAnimal2 = new Cat();

    myAnimal1.makeSound();
    myAnimal2.makeSound(); 
  }
}`;
  const jc5code2 = `interface Sound {
  void makeSound();
}

class Dog implements Sound {
  @Override
  public void makeSound() {
    System.out.println("Dog barks.");
  }
}

class Cat implements Sound {
  @Override
  public void makeSound() {
    System.out.println("Cat meows.");
  }
}

public class Main {
  public static void main(String[] args) {
    Sound mySound1 = new Dog();
    Sound mySound2 = new Cat();

    mySound1.makeSound();
    mySound2.makeSound(); 
  }
}`;
  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/java/abstraction">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/java/exceptions">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body ">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Polymorphism:
              </h2>
              <div class="ui clearing divider"></div>
              <p style={{ backgroundColor: "", padding: "" }}>
                Polymorphism means "many shapes" and refers to the ability of
                objects to take on multiple forms. <br />
                <br />
                Polymorphism is one of the fundamental concepts of
                Object Oriented Programming (OOP). It allows objects of
                different classes to be treated as the objects of a common Base
                Class. In Java, polymorphism is achieved through;
                <ul className="ui unordered list">
                  <li>
                    <b>Method Overriding</b>
                  </li>
                  <li>
                    <b>Interfaces</b>
                  </li>
                </ul>
                
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Polymorphism with Method Overriding:
                </h2>
                <div class="ui clearing divider"></div>
                <span>
                  {" "}
                  It occurs when a subclass provides a specific implementation
                  for a method that is already defined in its superclass. To
                  override a method, you use the @Override annotation to
                  indicate that a method in the subclass is intended to override
                  a method in the superclass.
                  <br />
                  <br />
                  In this example, we'll create a superclass Animal with a
                  method makeSound(), and then we'll create two subclasses, Dog
                  and Cat, that override the makeSound() method.
                </span>
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>polymorphism.java</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="java" style={darcula}>
                      {jc29code1}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                
                <h1>In this example;</h1>
                <ul className="ui unordered list">
                  <li>Animal is the superclass with a makeSound() method.</li>
                  <li>
                    Dog and Cat are subclasses of Animal that override the
                    makeSound() method with their specific implementations.
                  </li>
                  <li>
                    In the Main class, we create objects of both Dog and Cat and
                    assign them to references of type Animal. When we call
                    makeSound() on these references, it invokes the overridden
                    methods in the respective subclasses, demonstrating
                    polymorphic behavior.
                  </li>
                </ul>
                
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Polymorphism with Interfaces:
                </h2>
                <div class="ui clearing divider"></div>
                <span>
                  In this example, we'll define an interface Sound with a
                  makeSound() method, and then we'll create classes Dog and Cat
                  that implement the Sound interface.
                </span>
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>polymorphism.java</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="java" style={darcula}>
                      {jc29code1}
                    </SyntaxHighlighter>
                  </div>
                </div>
                
                <br />
                <h1>In this example;</h1>
                <ul className="ui unordered list">
                  <li>Sound is an interface with a makeSound() method.</li>
                  <li>
                    Dog and Cat are classes that implement the Sound interface
                    and provide their own implementations of the makeSound()
                    method.
                  </li>
                  <li>
                    In the Main class, we create objects of both Dog and Cat and
                    assign them to references of type Sound. When we call
                    makeSound() on these references, it invokes the methods
                    implemented in the respective classes, demonstrating
                    polymorphism through interfaces.
                  </li>
                </ul>
              </p>
            </div>

            <br />
            <Link to="/java/abstraction">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/java/exceptions">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default JavaC29;
