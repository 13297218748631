import { useState, useEffect } from "react";
import Sidebar from "./SideBar";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
// import "./Quiz.css";
import "../../Components/Chapters/Chapters.css";
import Modal from "react-modal";

const PQuiz12 = () => {
  const [answers, setAnswers] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const handleQuizSubmit = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const questions = [
    {
      id: 1,
      question: "  What is the key characteristic of a Python list:",
      options: ["Immutable", "Unordered", "Fixed size", "Automatically sorted"],
      correctAnswer: "Unordered",
    },
    {
      id: 2,
      question: "  How do you access the first item in a Python list:",
      options: ["Using \"list.get(0)\"", "Using \"list.first()\"", "Using \"list[0]\"", "Using \"list.getitem(1)\""],
      correctAnswer: "Using \"list[0]\"",
    },
    {
      id: 3,
      question: "  What is the result of \"len(['apple', 'banana', 'cherry'])\":",
      options: [
        "3",
        "4",
        "2",
        "0",
      ],
      correctAnswer: "3",
    },
    {
        id: 4,
        question: "  Which of the following is true about list elements in Python:",
        options: [
          "All elements must be of the same data type",
          "Elements cannot be repeated in a list",
          "Elements can have different data types",
          "List elements can only be integers",
        ],
        correctAnswer: "Elements can have different data types",
      },
      {
        id: 5,
        question: "  How can you change the element at the 2nd index in a Python list:",
        options: [
          "list.modify(2, \"new_item\")",
          "list[2] = \"new_item\"",
          "list.insert(2, \"new_item\")",
          "list.replace(2, \"new_item\")",
        ],
        correctAnswer: "list[2] = \"new_item\"",
      },
      {
        id: 6,
        question: "  What Python feature allows you to create a new list based on an existing list using a shorter syntax:",
        options: [
          "List extension",
          "List concatenation",
          "List comprehension",
          "List modification",
        ],
        correctAnswer: "List comprehension",
      },
      {
        id: 7,
        question: "  How do you join two lists in Python using the \"+\" operator:",
        options: [
          "list1 - list2",
          "list1.add(list2)",
          "list1 + list2",
          "list1.join(list2)",
        ],
        correctAnswer: "list1 + list2",
      },
      {
        id: 8,
        question: "  What method is used to remove a specific item from a list in Python:",
        options: [
          "list.delete(item)",
          "list.remove(item)",
          "list.pop(item)",
          "list.exclude(item)",
        ],
        correctAnswer: "list.remove(item)",
      },
      {
        id: 9,
        question: "  Which method empties a Python list, leaving it with no elements:",
        options: [
          "list.clear()",
          "list.empty()",
          "list.remove_all()",
          "list.empty_list()",
        ],
        correctAnswer: "list.clear()",
      },
      {
        id: 10,
        question: "  What is the result of \"sorted([3, 1, 2, 4, 5])\" in Python:",
        options: [
          "[1, 2, 3, 4, 5]",
          "[5, 4, 3, 2, 1]",
          "[3, 1, 2, 4, 5]",
          "[1, 4, 2, 3, 5]",
        ],
        correctAnswer: "[1, 2, 3, 4, 5]",
      }
  ];
  const handleAnswerChange = (questionId, selectedAnswer) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: selectedAnswer,
    }));
  };

  const calculateScore = () => {
    let correctCount = 0;
    let incorrectCount = 0;

    questions.forEach((question) => {
      const userAnswer = answers[question.id];
      if (userAnswer === question.correctAnswer) {
        correctCount++;
      } else {
        incorrectCount++;
      }
    });

    return { correctCount, incorrectCount };
  };

  const { correctCount, incorrectCount } = calculateScore();
  return (
    <>
      <Sidebar>
        <div class="container">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/quizes/python_quiz11">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                  }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/quizes/python_quiz13">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              </h2>
              <h1 class="start content ui left floated header">
                List
              </h1>
              <div className="ui clearing divider"></div>
            

            <div class="main-body">
              <h2 class="ui left floated header">
                Quiz # 12
              </h2>
              <span class="tut-btn"><Link to="/python/lists">
                  <button class="ui secondary button">Read Tutorial</button>
                </Link></span>
              <div class="ui clearing divider"></div>
              <div>
                {questions.map((question, i) => (
                  <div key={question.id}>
                    <div class="con">
                    <div class="que">Q{i+1}:<span class="one-per-left-margin">{question.question}</span></div>
                      {question.options.map((option) => (
                        <label class="label" key={option}>
                          <input
                            type="radio"
                            name={`question_${question.id}`}
                            value={option}
                            checked={answers[question.id] === option}
                            onChange={(e) =>
                              handleAnswerChange(question.id, e.target.value)
                            }
                          />
                          <span className="one-per-left-margin">{option}</span>
                          <br />
                        </label>
                      ))}
                    </div>
                    <hr />
                  </div>
                ))}<br/>
                <center><button class="ui primary button" onClick={handleQuizSubmit}>Submit Quiz</button></center>

                {/* Modal for displaying results */}
                <Modal class="modal" isOpen={isModalOpen} onRequestClose={closeModal}>
                  <b style={{fontSize:'20px'}}>Quiz Results</b>
                  <b><p>Your score is {correctCount}/{questions.length} </p></b>
                  {questions.map((question,i) => (
                    <div key={question.id}>
                      <p>Q{i+1}: {question.question}</p>
                      <p><b>Correct Answer:</b> {question.correctAnswer}</p>
                      <p className={`${question.correctAnswer}` === `${answers[question.id]}` ? "correct" : "wrong"}>
                        <b>Your Answer:</b> {answers[question.id] || "Not answered"}
                      </p>
                      <hr />
                    </div>
                  ))}
                  <button class="ui secondary button" onClick={closeModal}>Close</button><br /><br />
                </Modal>
              </div>

              <br />
            </div>
            <Link to="/quizes/python_quiz11">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                  }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/quizes/python_quiz13">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
            <br />
          </div>
        </div>
      </Sidebar>

      <div class="sideBarBody">
        <Footer />
      </div>
    </>
  );
};
export default PQuiz12;
