import { useRef, useEffect } from "react";
import "../../Components/Chapters/Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "../PythonSideBar";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

const PythonC13 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };

  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor:'#D8D9DA'
  };
  const pc13code1 = `cars = ("Ferrari", "Gazoo", "Mustang")
print(cars)`;
  const pc13code2 = `names = ("Sarah", "Leah", "Ammy")
print(names)

#calculating number of items in a tuple
print("Length of tuple : ",len(names))`;
  const pc13code3 = `#with same data types
cars = ("Volvo", "Ferrari", "Gazoo")
print(cars)

#with different data types
person = (1, "Andrew", "age : 38", "True", 5.8)
print(person)`;
  const pc13code4 = `names = ("Leah", "Amy", "Leah", "Gotti")
print(names)`;
  const pc13code5 = `fruit = ("apple")
print(type(fruit))

fruits = ("apple",)
print(type(fruits))`;
  const pc13code6 = `countries = ("Pakistan", "India", "Bangladesh")
print(countries[0])  
print(countries[1])  
print(countries[2])`;
  const pc13code6_ = `countries = ("Pakistan", "India", "Bangladesh")

#range of indexes
print(countries[0:2])  

#it will start from start index
print(countries[:3])

#it will end to last index
print(countries[1:])`;

  const pc13code7 = `countries = ("Pakistan", "India", "Bangladesh")
print(countries[-1])
print(countries[-2])
print(countries[-3])`;

  const pc13code7_ = `countries = ("Pakistan", "India", "Bangladesh")

#range of negative indexes
print(countries[-3:-1])

#it will start from start index
print(countries[:-1])

#it will end to last index
print(countries[-2:])`;
  const pc13code8 = `names = ("Sarah", "Leah", "Amy")
new_names = list(names)
new_names[0] = "Lauren"
names = tuple(new_names)
print(names)`;
const pc13code9 = `animals = ("Lion", "Monkey", "Zebra")    
print(animals)`
const pc13code10 = `animals = ("Lion", "Monkey", "Zebra")
(Meat, Banana, Grass) = animals    
print(Meat)
print(Banana)
print(Grass)`
const pc13code11 = `animals = ("Lion", "Monkey", "Zebra", "Dog")
(Meat, *Banana, Grass) = animals    
print(Meat)
print(Banana)
print(Grass)`
const pc13code12 = `fruits = ("Apple", "Banana", "Grapes")
dryFruits = ("Almonds", "Apricots", "Cashew")
print(fruits + dryFruits)`
const pc13code13 = `names = ("Sarah", "Leah", "Sarah", "Sarah")
print(names.count("Sarah"))`
const pc13code14 = `names = ("Sarah", "Leah", "Amy")
print(names.index("Sarah"))`

  return (
    <div class="testing">
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/python/lists">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/python/sets">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Tuples:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                Tuples are the data structures in Python which are used to store
                multiple items within a single variable. These multiple items
                can be of same or different data type.
                <br />
                
                
                <ul className="ui unordered list">
                  <li>
                    <b>Unchangeable</b> - we can not change, add or remove the
                    items in a list.
                  </li>
                  <li>
                    <b>Ordered</b> - items in a list have a definite order which
                    is unchanged.
                  </li>
                  <li>
                    <b>Indexing</b> - tuple items have indexes. First element
                    has 0 index, second has 1 and so on.
                  </li>
                  <li>
                    <b>Redundant Data</b> - items of a tuple can be repeated.
                  </li>
                </ul>
              </span>
              <br />
              <br />
              

                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>input.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc13code1}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Members of a List:
                </h2>
                <div className="ui clearing divider"></div>
                <span>
                  Members of a tuple are unchangeable. We can also calculate the
                  number of items in a tuple.
                </span><br /><br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>tuple.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc13code2}
                    </SyntaxHighlighter>
                  </div>
                </div><br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Data types of members of tuple:
                </h2>
                <div className="ui clearing divider"></div>
                <span>
                  Members of a tuple can be of any data type. Their data types can be same or different.
                </span><br /><br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>tuple.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc13code3}
                    </SyntaxHighlighter>
                  </div>
                </div>

                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Repetition:
                </h2>
                <div className="ui clearing divider"></div>
                <span>Members of a tuple can be repeated multiple times.</span><br /><br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>tuple.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc13code4}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Tuple with one member:
                </h2>
                <div className="ui clearing divider"></div>
                <span>
                  Tuple with one member is a string. You can check the data type
                  using type() function.
                </span><br /><br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>tuple.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc13code5}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Access Tuple Items:
                </h2>
                <div className="ui clearing divider"></div>
                <span>We can access the items of a tuple using indexes.</span><br /><br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>tuple.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc13code6}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Range of Indexes:
                </h2>
                <div className="ui clearing divider"></div>
                <span>
                  We can access the range of items in a tuple. We have to
                  specify the starting and the ending index.
                </span><br /><br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>tuple.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc13code6_}
                    </SyntaxHighlighter>
                  </div>
                </div>

                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Negative Indexes:
                </h2>
                <div className="ui clearing divider"></div>
                <span>
                  Negative indexes start from the end of the tuple. index -1
                  corresponds to the last element. -2 index corresponds to the
                  second last index. -3 index corresponds to the third index from the last.
                  The element at last index is not printed.
                </span><br /><br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>tuple.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc13code7}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Range of Negative Indexes:
                </h2>
                <div className="ui clearing divider"></div>
                <span>
                  We have to specify the range of negative indexes. We have to
                  specify starting and the ending index. The element at last
                  index is not printed.
                </span><br /><br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>tuple.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc13code7_}
                    </SyntaxHighlighter>
                  </div>
                </div>

                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Update items of Tuple:
                </h2>
                <div className="ui clearing divider"></div>
                <span>
                  Tuples are unchangeable. It means that you can not update the
                  items of tuple once it is created. But as we can convert tuple
                  to list, update items and convert list back to tuple.
                </span>
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>tuple.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc13code8}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />

                <p style={{ backgroundColor: "#FBEEAC", padding: "15px" }}>
                  <b>Note : </b>
                  We can convert tuple to list and can apply list methods and
                  can convert lists back to tuple. You can study about list
                  methods in previous section.
                </p>

                <br />
                
                
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Packing a Tuple:
                </h2>
                <div className="ui clearing divider"></div>
                <span>
                  When we assign values to a tuple, it is called packing a tuple.
                </span><br /><br />
                <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>tuple.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc13code9}
                  </SyntaxHighlighter>
                </div>
              </div><br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Unpacking a Tuple:
                </h2>
                <div className="ui clearing divider"></div>
                <span>
                  Assigning values of tuple back to variables is called unpacking a tuple.
                </span><br /><br />
                <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>tuple.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc13code10}
                  </SyntaxHighlighter>
                </div>
              </div><br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Using Asterisk:
                </h2>
                <div className="ui clearing divider"></div>
                <span>
                  If the number of items of tuple is more than the number of variables, we use asterisks to assign all 
                  the remaining values to the variable with asterisks.
                </span><br /><br />
                <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>tuple.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc13code11}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Joining Tuples:
                </h2>
                <div className="ui clearing divider"></div>
                <span>
                  We can join two tuples using "+" operator.
                </span><br /><br />
                <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>tuple.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc13code12}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Count method:
                </h2>
                <div className="ui clearing divider"></div>
                <span>
                  We can count items in a tuple using count method.
                </span><br /><br />
                <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>tuple.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc13code13}
                  </SyntaxHighlighter>
                </div>
              </div>



                <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Index method:
                </h2>
                <div className="ui clearing divider"></div>
                <span>
                  We can get the index of item using index method.
                </span><br /><br />
                <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>tuple.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc13code14}
                  </SyntaxHighlighter>
                </div>
              </div>

              
            </div>
            <br />
            <Link to="/python/lists">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/python/sets">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </div>
  );
};
export default PythonC13;
