import { useRef, useEffect } from "react";
import "../../Components/Chapters/Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Sidebar from "../JavaSideBar";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const JavaC8 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };

  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor:'#D8D9DA'
  };

  const jc8code1 = `public class Main {
    public static void main(String[] args) {
        int age = 20;
        float = 3.14f;
        String name = "John";
        char grade = 'A';
        boolean isTrue = true;
    }
}`;
const jc8code2 = `public class Main {
    public static void main(String[] args) {
        float num = 35e3f;
        
        System.out.println(num);
        
    }
}`
  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/java/input">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/java/type_casting">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body ">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Java Data Types:
              </h2>
              <div class="ui clearing divider"></div>
              <p style={{ backgroundColor: "", padding: "" }}>
                As discussed earlier, data type specifies the type of data value which
                we are storing in a variable.
                <br />
                <br />
                In Java, we can divide data types in two groups: <br />
                <ul className="ui unordered list">
                  <li>Primitive data types </li>
                  <li>Reference data types </li>
                </ul>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Primitive Data Types:
                </h2>
                <div class="ui clearing divider"></div>
                <table class="ui celled table">
                  <thead>
                    <tr>
                      <th>Data Type</th>
                      <th>Size</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>byte </td>
                      <td>8 bits</td>
                      <td>
                        Represents a signed 8-bit integer value. Range: -128 to
                        127.
                      </td>
                    </tr>

                    <tr>
                      <td>short </td>
                      <td>16 bits</td>
                      <td>
                        Represents a signed 16-bit integer value. Range: -32,768
                        to 32,767.
                      </td>
                    </tr>

                    <tr>
                      <td>int </td>
                      <td>32 bits</td>
                      <td>
                        Represents a signed 32-bit integer value. Range: -2^31
                        to 2^31-1.
                      </td>
                    </tr>

                    <tr>
                      <td>long </td>
                      <td>64 bits</td>
                      <td>
                        Represents a signed 64-bit integer value. Range: -2^63
                        to 2^63-1.
                      </td>
                    </tr>

                    <tr>
                      <td>float </td>
                      <td>32 bits</td>
                      <td>
                        Represents a single-precision floating-point number.
                        It's used for decimal values. Example: 3.14159f.
                      </td>
                    </tr>

                    <tr>
                      <td>double </td>
                      <td>64 bits</td>
                      <td>
                        Represents a double-precision floating-point number.
                        It's used for decimal values. Example: 3.14159.
                      </td>
                    </tr>

                    <tr>
                      <td>char </td>
                      <td>16 bits</td>
                      <td>
                        Represents a single Unicode character. Example: 'A',
                        '1'.
                      </td>
                    </tr>
                    <tr>
                      <td>boolean </td>
                      <td>1 bit</td>
                      <td>Represents a true or false value.</td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Reference Data Types:
                </h2>
                <div class="ui clearing divider"></div>
                <table class="ui celled table">
                  <thead>
                    <tr>
                      <th>Data Type</th>

                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>class </td>

                      <td>
                        Represents user-defined types. Classes define objects
                        and their behavior in Java.
                      </td>
                    </tr>

                    <tr>
                      <td>interface </td>

                      <td>
                      Represents a blueprint for classes. Interface defines a set of methods that classes must implement.
                      </td>
                    </tr>

                    <tr>
                      <td>enum </td>

                      <td>
                        Represents a special type used to define a set of
                        constants. Enum is often used for defining a limited
                        set of values.
                      </td>
                    </tr>

                    <tr>
                      <td>array </td>

                      <td>
                        Represents a collection of items of the same data
                        type. Arrays can be of primitive or reference types.
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
                
                <span>
                    We will not focus on the details of reference data types here.
                </span>
                <br /><br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  String:
                </h2>
                <div class="ui clearing divider"></div>
                <span>
                  Although it is not a primitive data type, String is widely used
                  to represent text in Java. It is a class that provides methods
                  for manipulating the string. We will discuss more about 
                  strings
                  in <Link to="/java/strings" style={{color:'red'}}>strings</Link> section.
                </span>
                <br />
                <br />
                

                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Variables with different Data Types:
                </h2>
                <div class="ui clearing divider"></div>
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>dataType.java</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="java" style={darcula}>
                      {jc8code1}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Scientific Numbers in Java:
                </h2>
                <div class="ui clearing divider"></div>
                <span>
                    A floating point number can be used to store a scientific number with "e" corresponding to the power of 10.

                </span><br /><br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>dataType.java</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="java" style={darcula}>
                      {jc8code2}
                    </SyntaxHighlighter>
                  </div>
                </div>
              </p>

              
            </div>
            <br />
            <Link to="/java/input">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/java/type_casting">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default JavaC8;
