import { useRef, useEffect } from "react";
import "../../Components/Chapters/Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Sidebar from "../JavaSideBar";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const JavaC19 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };

  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };
  const jc19code1 = `public class Main {
  public static void main(String[] args) {
    int[] numbers = {10, 33, 25, 12, 29};    
  }
}`;
  const jc19code2 = `public class Main {
  public static void main(String[] args) {
        
    int[] numbers = {90, 36, 45, 77, 32};    
    System.out.println(numbers[0]);
    System.out.println(numbers[1]);
    System.out.println(numbers[2]);
    System.out.println(numbers[3]);
    System.out.println(numbers[4]);
  }
}`;
  const jc19code3 = `public class Main {
    public static void main(String[] args) {
        
      int[] numbers = {33, 65, 34, 66, 90};    
      numbers[0] = 100;
      System.out.println(numbers[0]);
      
    }
  }`;
  const jc19code4 = `public class Main {
    public static void main(String[] args) {
        
      int[] numbers = {23, 76, 44, 20, 89};    
      System.out.println(numbers.length);
      
    }
  }`;

  const jc19code5 = `public class Main {
    public static void main(String[] args) {
        
      String[] fruits = {"Apple", "Banana", "Fig"};    
      for(int i = 0; i < fruits.length; i++){
        System.out.println(fruits[i]);
      }
      
    }
  }`;
  const jc19code6 = `public class Main {
    public static void main(String[] args) {
        
      String[] fruits = {"Apple", "Banana", "Fig"};    
      for(String fruit : fruits){
        System.out.println(fruit);
      }
      
    }
  }`;
  const jc19code7 = `public class TwoDArrayExample {
  public static void main(String[] args) {

    int[][] twoDArray = {
      {1, 2, 3, 4},
      {5, 6, 7, 8},
      {9, 10, 11, 12}
    };

    // Accessing elements 
    int element = twoDArray[1][2]; 
    System.out.println("Item[1][2]: " + element);

    System.out.println("Array items:");
    for (int i = 0; i < 3; i++) {
      for (int j = 0; j < 4; j++) {
        System.out.print(twoDArray[i][j] + " ");
        }
        System.out.println();
    }
  }
}`;
  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/java/continue_break">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/java/methods">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body ">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Arrays in Java:
              </h2>
              <div class="ui clearing divider"></div>
              <p style={{ backgroundColor: "", padding: "" }}>
                In Java, an array is a data structure that allows you to store
                multiple values of the same data type in a single variable.{" "}
                <br />
                Arrays are widely used to work with collections of data. Arrays
                is a fundamental concept in Java programming.
                <br />
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Advantages of Arrays:
                </h2>
                <div class="ui clearing divider"></div>
                <span>
                  <ul className="ui unordered list">
                    <li>
                      Represent multiple data items of the same type in a single
                      variable.
                    </li>
                    <li>
                      Arrays have a fixed size that is defined when they are
                      initialized.{" "}
                    </li>
                    <li>
                      Arrays can easily be iterated through using loops, making
                      it convenient to perform operations on all elements in the
                      array sequentially.
                    </li>
                  </ul>
                </span>
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>helloworld.java</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="java" style={darcula}>
                      {jc19code1}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
              </p>

              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Accessing elements in Arrays:
              </h2>
              <div class="ui clearing divider"></div>
              <p style={{ backgroundColor: "", padding: "" }}>
                We can access elements in Arrays using indexes. Indexes start
                from zero. Zeroth element is considered the first element and n-1th element
                is the last element.
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>helloworld.java</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="java" style={darcula}>
                      {jc19code2}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
              </p>

              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Changing elements in Arrays:
              </h2>
              <div class="ui clearing divider"></div>
              <p style={{ backgroundColor: "", padding: "" }}>
                We can reassign value to elements in arrays.
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>helloworld.java</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="java" style={darcula}>
                      {jc19code3}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
              </p>

              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Length of Arrays:
              </h2>
              <div class="ui clearing divider"></div>
              <p style={{ backgroundColor: "", padding: "" }}>
                We can find the length of an array using length function.
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>helloworld.java</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="java" style={darcula}>
                      {jc19code4}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
              </p>

              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Iterating through Arrays:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                We can iterate through the elements of arrays using for loop and
                for each loop.
              </span>
              <br />
              <br />

              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                For loop:
              </h2>
              <div class="ui clearing divider"></div>
              <p style={{ backgroundColor: "", padding: "" }}>
                We can iterate through elements of array using for loop.
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>helloworld.java</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="java" style={darcula}>
                      {jc19code5}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
              </p>

              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                For each loop:
              </h2>
              <div class="ui clearing divider"></div>
              <p style={{ backgroundColor: "", padding: "" }}>
                We can also iterate through elements of array using for each
                loop.
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>helloworld.java</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="java" style={darcula}>
                      {jc19code6}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
              </p>

              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Two Dimensional Arrays:
              </h2>
              <div class="ui clearing divider"></div>
              <p style={{ backgroundColor: "", padding: "" }}>
                A 2-Dimensional array in Java is essentially an array of arrays, where each
                element of the main array is itself an array. This creates a
                grid-like structure with rows and columns. You can think of a 2D
                array as a table or matrix.
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>helloworld.java</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="java" style={darcula}>
                      {jc19code7}
                    </SyntaxHighlighter>
                  </div>
                </div>
              </p>
            </div>

            <br />
            <Link to="/java/continue_break">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/java/methods">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default JavaC19;
