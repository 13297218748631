import { useRef, useEffect } from "react";
import "../../Components/Chapters/Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "../PythonSideBar";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

const PythonC4 = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  const divRef = useRef(null);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };

  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };
  const pc4code1 = `print("Hello World!")`;
  const pc4code2 = `# We will multiply the text with a number
# It will cause the text to print number times on screen
print("Hi" * 5)`;
  const pc4code3 = `name = "Father's \\n name."
print(name)`;
  const pc4code4 = `greetings = "Hi\\tthere!"
print(greetings) `;
  const pc4code5 = `name = "John \\bSmith"
print(name) `;
  const pc4code6 = `text = "Hello\\fWorld"
print(text)`;
  const pc4code7 = `text = "Hello\\rWorld"
print(text)`;
  const pc4code8 = `txt = "\\110\\145\\154\\154\\157"
print(txt)`;
  const pc4code9 = `character = "\\x48\\x65\\x6c\\x6c\\x6f"  
print(character)`;
  const pc4code10 = `name = "John\\'s Car"
print(name)`;
  const pc4code11 = `txt = "\\\\"
print(txt)`;
  const pc4code12 = `name = "\\"John Smith\\""
print(name)`;
const pc4code13 = `print(10)
print(1.96)
print(3.6523465)`

const pc4code14 = `# Declaring two variable name and age
name = "John"
age = 30

# Printing the variables on console
print(name)
print(age)`

  return (
    <div class="testing">
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/python/syntax">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/python/comments">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Printing Output:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                To print something on screen, we have to write a simple
                expression.
              </span><br /><br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>output.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc4code1}
                  </SyntaxHighlighter>
                </div>
              </div>
              <span>
                This is the simple code which will print "Hello World!" on screen.
              </span><br />
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Expressions:
              </h2>
              <div className="ui clearing divider"></div>
              <span>
                An expression is a piece of code that produces some value. When
                interpreter executes an expression, it will evaluate the code
                that we have written in python file. <br />
                <br />
                For example, print("Hello World!") will produce Hello World! and
                print it on screen.
              </span>
              <br />
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Printing multiple times:
              </h2>
              <div className="ui clearing divider"></div>
                    <span>
                      When we want to print the same content multiple times on console, we use asterisk <b>*</b><br /><br />
                    </span>


              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>output.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc4code2}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <span>This expression will print "Hi" five times on screen.</span>
              <br />
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Escape Sequences:
              </h2>
              <div className="ui clearing divider"></div>

              <table class="ui celled table">
                <thead>
                  <tr>
                    <th>Escape Sequence</th>
                    <th>Description</th>
                    <th>Code</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>\n</td>
                    <td>New line</td>
                    <td>
                      <div style={containerStyle}>
                        <div className="ml-3 mr-3">
                          <b>indentation.py</b>

                          <i
                            class="copy icon"
                            onClick={copyContent}
                            style={{
                              cursor: "pointer",
                              float: "right",
                              fontSize: "20px",
                            }}
                          ></i>
                        </div>
                        <div ref={divRef}>
                          <SyntaxHighlighter language="python" style={darcula}>
                            {pc4code3}
                          </SyntaxHighlighter>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>\t</td>
                    <td>Tab - prints four spaces</td>
                    <td>
                      <div style={containerStyle}>
                        <div className="ml-3 mr-3">
                          <b>indentation.py</b>

                          <i
                            class="copy icon"
                            onClick={copyContent}
                            style={{
                              cursor: "pointer",
                              float: "right",
                              fontSize: "20px",
                            }}
                          ></i>
                        </div>
                        <div ref={divRef}>
                          <SyntaxHighlighter language="python" style={darcula}>
                            {pc4code4}
                          </SyntaxHighlighter>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>\b</td>
                    <td>Back space</td>
                    <td>
                      <div style={containerStyle}>
                        <div className="ml-3 mr-3">
                          <b>indentation.py</b>

                          <i
                            class="copy icon"
                            onClick={copyContent}
                            style={{
                              cursor: "pointer",
                              float: "right",
                              fontSize: "20px",
                            }}
                          ></i>
                        </div>
                        <div ref={divRef}>
                          <SyntaxHighlighter language="python" style={darcula}>
                            {pc4code5}
                          </SyntaxHighlighter>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>\f</td>
                    <td>Form feed</td>
                    <td>
                      <div style={containerStyle}>
                        <div className="ml-3 mr-3">
                          <b>indentation.py</b>

                          <i
                            class="copy icon"
                            onClick={copyContent}
                            style={{
                              cursor: "pointer",
                              float: "right",
                              fontSize: "20px",
                            }}
                          ></i>
                        </div>
                        <div ref={divRef}>
                          <SyntaxHighlighter language="python" style={darcula}>
                            {pc4code6}
                          </SyntaxHighlighter>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>\r</td>
                    <td>Carriage return</td>
                    <td>
                      <div style={containerStyle}>
                        <div className="ml-3 mr-3">
                          <b>indentation.py</b>

                          <i
                            class="copy icon"
                            onClick={copyContent}
                            style={{
                              cursor: "pointer",
                              float: "right",
                              fontSize: "20px",
                            }}
                          ></i>
                        </div>
                        <div ref={divRef}>
                          <SyntaxHighlighter language="python" style={darcula}>
                            {pc4code7}
                          </SyntaxHighlighter>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>\ooo</td>
                    <td>Character in Octal</td>
                    <td>
                      <div style={containerStyle}>
                        <div className="ml-3 mr-3">
                          <b>indentation.py</b>

                          <i
                            class="copy icon"
                            onClick={copyContent}
                            style={{
                              cursor: "pointer",
                              float: "right",
                              fontSize: "20px",
                            }}
                          ></i>
                        </div>
                        <div ref={divRef}>
                          <SyntaxHighlighter language="python" style={darcula}>
                            {pc4code8}
                          </SyntaxHighlighter>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>\xhh</td>
                    <td>Character in Hexa-decimal</td>
                    <td>
                      <div style={containerStyle}>
                        <div className="ml-3 mr-3">
                          <b>indentation.py</b>

                          <i
                            class="copy icon"
                            onClick={copyContent}
                            style={{
                              cursor: "pointer",
                              float: "right",
                              fontSize: "20px",
                            }}
                          ></i>
                        </div>
                        <div ref={divRef}>
                          <SyntaxHighlighter language="python" style={darcula}>
                            {pc4code9}
                          </SyntaxHighlighter>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>\'</td>
                    <td>Single quote</td>
                    <td>
                      <div style={containerStyle}>
                        <div className="ml-3 mr-3">
                          <b>indentation.py</b>

                          <i
                            class="copy icon"
                            onClick={copyContent}
                            style={{
                              cursor: "pointer",
                              float: "right",
                              fontSize: "20px",
                            }}
                          ></i>
                        </div>
                        <div ref={divRef}>
                          <SyntaxHighlighter language="python" style={darcula}>
                            {pc4code10}
                          </SyntaxHighlighter>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>\"</td>
                    <td>Double quote</td>
                    <td>
                      <div style={containerStyle}>
                        <div className="ml-3 mr-3">
                          <b>indentation.py</b>

                          <i
                            class="copy icon"
                            onClick={copyContent}
                            style={{
                              cursor: "pointer",
                              float: "right",
                              fontSize: "20px",
                            }}
                          ></i>
                        </div>
                        <div ref={divRef}>
                          <SyntaxHighlighter language="python" style={darcula}>
                            {pc4code12}
                          </SyntaxHighlighter>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>\\</td>
                    <td>Backslah Character</td>
                    <td>
                      <div style={containerStyle}>
                        <div className="ml-3 mr-3">
                          <b>indentation.py</b>

                          <i
                            class="copy icon"
                            onClick={copyContent}
                            style={{
                              cursor: "pointer",
                              float: "right",
                              fontSize: "20px",
                            }}
                          ></i>
                        </div>
                        <div ref={divRef}>
                          <SyntaxHighlighter language="python" style={darcula}>
                            {pc4code11}
                          </SyntaxHighlighter>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <br /><br />
            <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Printing Numbers on Screen:
              </h2>
              <div className="ui clearing divider"></div>
              <span>
                When we want to print numbers on screen, we write numbers in print() function 
                without double quotation marks. The code for printing numbers is given in the following.<br /><br />
              </span>
              <div style={containerStyle}>
                        <div className="ml-3 mr-3">
                          <b>indentation.py</b>

                          <i
                            class="copy icon"
                            onClick={copyContent}
                            style={{
                              cursor: "pointer",
                              float: "right",
                              fontSize: "20px",
                            }}
                          ></i>
                        </div>
                        <div ref={divRef}>
                          <SyntaxHighlighter language="python" style={darcula}>
                            {pc4code13}
                          </SyntaxHighlighter>
                        </div>
                      </div>
                      <br />




                      <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Printing Variables on Screen:
              </h2>
              <div className="ui clearing divider"></div>
              <span>
                When we want to print variables on screen, we write them in print() function 
                without double quotation marks. The code for printing variables is given in the following. <br /><br />
              </span>
              <div style={containerStyle}>
                        <div className="ml-3 mr-3">
                          <b>indentation.py</b>

                          <i
                            class="copy icon"
                            onClick={copyContent}
                            style={{
                              cursor: "pointer",
                              float: "right",
                              fontSize: "20px",
                            }}
                          ></i>
                        </div>
                        <div ref={divRef}>
                          <SyntaxHighlighter language="python" style={darcula}>
                            {pc4code14}
                          </SyntaxHighlighter>
                        </div>
                      </div>




            <p style={{ backgroundColor: "#FBEEAC", padding: "10px" }}>
              <b>Note : </b>In this section, we have discussed that how we can
              print text, numbers and variables on screen. We can apply operations on strings,
              numbers and variables which is provided in detail in respective sections.
            </p>
            <br />
            <Link to="/python/syntax">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/python/comments">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </div>
  );
};
export default PythonC4;
