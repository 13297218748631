import React, { useState, useRef, useEffect } from "react";
import Sidebar from "./SideBar";
import "../../Components/SideBar/App.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const CodeAccordion = ({ questions, code, toggleAccordion, changeCodeLanguage }) => {
    const divRef = useRef(null);
    const copyContent = () => {
        const divContent = divRef.current.innerText;
        navigator.clipboard
          .writeText(divContent)
          .then(() => {
            toast.success("Content copied!", { position: "top-center" });
          })
          .catch((error) => {
            toast.error("Error copying content:", error, {
              position: "top-center",
            });
          });
      };
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };

  return (
    <div>
      {questions.map((item, index) => (
        <div key={index} className="border p-2 rounded-md">
          <div className="flex justify-between">
            <span className="text-lg font-semibold">
              {item.question}
            </span>
            <button
              className="bg-gray-200 px-2 py-1 rounded-md"
              onClick={() => toggleAccordion(index)}
            >
              {item.isOpen ? "-" : "+"}
            </button>
          </div>
          <div className={`mt-2 ${item.isOpen ? "block" : "hidden"}`}>
            <div className="mt-2 flex space-x-4">
              {Object.keys(code).map((language) => (
                <button
                  key={language}
                  onClick={() => changeCodeLanguage(index, language)}
                  className={`${
                    item.codeLanguage === language
                      ? "bg-blue-500 hover:bg-blue-600 text-white"
                      : "bg-gray-200 hover:bg-gray-300 text-gray-700"
                  } px-3 py-2 rounded-md transition duration-300 ease-in-out transform hover:scale-105`}
                >
                  {language}
                </button>
              ))}
            </div>
            <p className="text-gray-800">
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>output.cpp</b>
                  <i
                    className="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language={`${item.codeLanguage}`} style={darcula}>
                    {code[item.codeLanguage]}
                  </SyntaxHighlighter>
                </div>
              </div>
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

const Question15 = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
    const code = {
        python: `# Python code
def calculate_sum(input_list):
    return sum(input_list)

my_list = [1, 2, 3, 4, 5]
result = calculate_sum(my_list)

print("Sum of the elements in the list:", result)`,
    };
    const code1 = {
        python: `# Python code
def find_max_min(lst):
    if not lst:
        return None, None  # Handle an empty list

    max_value = lst[0]
    min_value = lst[0]

    for item in lst:
        if item > max_value:
            max_value = item
        elif item < min_value:
            min_value = item

    return max_value, min_value

# Example usage:
my_list = [5, 2, 9, 1, 7, 3]
max_val, min_val = find_max_min(my_list)
print("Maximum value:", max_val)
print("Minimum value:", min_val)`,
    };
    const code2 = {
        python: `# Python code
def reverse_list(lst):
    reversed_lst = []
    for item in lst:
        reversed_lst.insert(0, item)
    return reversed_lst

# Example usage:
my_list = [1, 2, 3, 4, 5]
reversed_list = reverse_list(my_list)
print(reversed_list)`,
    };
    const code3 = {
        python: `# Python code
original_list = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

even_numbers = [x for x in original_list if x % 2 == 0]

print(even_numbers)`,
    };
    const code4 = {
        python: `# Python code
def filter_divisible_elements(lst, divisor):
    filtered_list = [x for x in lst if x % divisor != 0]
    return filtered_list

# Example usage:
my_list = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
divisor = 2
filtered_result = filter_divisible_elements(my_list, divisor)
print(filtered_result)`,
    };
    const code5 = {
        python: `# Python code
def flatten_list(list_of_lists):
    flattened_list = [item for sublist in list_of_lists for item in sublist]
    return flattened_list

# Example usage:
list_of_lists = [[1, 2], [3, 4], [5, 6]]
flattened_result = flatten_list(list_of_lists)
print(flattened_result)`,
    };
    const code6 = {
        python: `# Python code
original_list = [1, 2, 3, 4, 5, 6, 7, 8, 9]
sub_list = original_list[2:6]
print(sub_list)  # Output: [3, 4, 5, 6]

original_list = [1, 2, 3, 4, 5, 6, 7, 8, 9]
reversed_sub_list = original_list[2:6][::-1]
print(reversed_sub_list)  # Output: [6, 5, 4, 3]

original_list = [1, 2, 3, 4, 5, 6, 7, 8, 9]
elements_in_range = original_list[2:6]
print(elements_in_range)  # Output: [3, 4, 5, 6`,
    };
    const code7 = {
        python: `# Python code
def sort_list(input_list, ascending=True):
    # Sort the list in ascending order by default
    sorted_list = sorted(input_list) if ascending else sorted(input_list, reverse=True)
    return sorted_list

# Example usage:
my_list = [5, 2, 9, 1, 7, 3]

ascending_result = sort_list(my_list, ascending=True)
descending_result = sort_list(my_list, ascending=False)

print("Ascending order:", ascending_result)
print("Descending order:", descending_result)`,
    };
    const code8 = {
        python: `# Python code
def remove_duplicates(lst):
    unique_list = []
    for item in lst:
        if item not in unique_list:
            unique_list.append(item)
    return unique_list

# Example usage:
my_list = [1, 2, 2, 3, 4, 4, 5, 6, 6]
result = remove_duplicates(my_list)
print(result)`,
    };
    const code9 = {
        python: `# Python code
def find_common_elements(list1, list2):
    set1 = set(list1)
    set2 = set(list2)
    common_elements = list(set1.intersection(set2))
    return common_elements

# Example usage:
list1 = [1, 2, 3, 4, 5]
list2 = [3, 4, 5, 6, 7]
result = find_common_elements(list1, list2)
print(result)`,
    };
    const code10 = {
        python: `# Python code
def count_occurrences(lst, target):
    count = 0
    for item in lst:
        if item == target:
            count += 1
    return count
my_list = [1, 2, 2, 3, 4, 2, 5, 2]
element_to_count = 2
result = count_occurrences(my_list, element_to_count)
print(f"The element {element_to_count} appears {result} times in the list."`,
    };
    const code11 = {
        python: `# Python code
def split_list(input_list, sublist_size):
    if sublist_size <= 0:
        return "Sublist size must be greater than 0."
    
    sublists = []
    for i in range(0, len(input_list), sublist_size):
        sublist = input_list[i:i + sublist_size]
        sublists.append(sublist)
    return sublists
my_list = [1, 2, 3, 4, 5, 6, 7, 8, 9]
sublist_size = 3

result = split_list(my_list, sublist_size)
print(result)`,
    };
    const code12 = {
        python: `# Python code
def is_palindrome(input_list):
    reversed_list = input_list[::-1]
    return input_list == reversed_list
my_list = [1, 2, 3, 2, 1]
result = is_palindrome(my_list)
print(result)  # True

my_list = [1, 2, 3, 4, 5]
result = is_palindrome(my_list)
print(result)  # False`,
    };
    const code13 = {
        python: `# Python code
def merge_sorted_lists(list1, list2):
    merged_list = []
    i = j = 0

    while i < len(list1) and j < len(list2):
        if list1[i] < list2[j]:
            merged_list.append(list1[i])
            i += 1
        else:
            merged_list.append(list2[j])
            j += 1

    # If there are remaining elements in list1 or list2, add them to the merged list
    merged_list.extend(list1[i:])
    merged_list.extend(list2[j:])

    return merged_list
list1 = [1, 3, 5, 7]
list2 = [2, 4, 6, 8]

result = merge_sorted_lists(list1, list2)
print(result)  # [1, 2, 3, 4, 5, 6, 7, 8]`,
    };
    const code14 = {
        python: `# Python code
def find_median(input_list):
    sorted_list = sorted(input_list)
    n = len(sorted_list)
    
    if n % 2 == 1:  # Odd number of elements
        median = sorted_list[n // 2]
    else:  # Even number of elements
        middle1 = sorted_list[n // 2 - 1]
        middle2 = sorted_list[n // 2]
        median = (middle1 + middle2) / 2.0
    
    return median
my_list = [5, 2, 9, 1, 5, 6]
result = find_median(my_list)
print(result)  # 5.0`,
    };
    const code15 = {
        python: `# Python code
import random

def shuffle_list(input_list):
    shuffled_list = input_list.copy()  # Create a copy to avoid modifying the original list
    random.shuffle(shuffled_list)
    return shuffled_list
my_list = [1, 2, 3, 4, 5]
shuffled = shuffle_list(my_list)
print(shuffled)`,
    };
    const code16 = {
        python: `# Python code
def partition_list(input_list, condition):
    true_list = [x for x in input_list if condition(x)]
    false_list = [x for x in input_list if not condition(x)]
    return true_list, false_list
def is_even(x):
    return x % 2 == 0

my_list = [1, 2, 3, 4, 5, 6, 7, 8, 9]

even_numbers, odd_numbers = partition_list(my_list, is_even)

print("Even numbers:", even_numbers)
print("Odd numbers:", odd_numbers)`,
    };
    const code17 = {
        python: `# Python code
def main():
    my_list = []  # Initialize an empty list

    while True:
        print("Current list:", my_list)
        print("Menu:")
        print("1. Add an element")
        print("2. Remove an element")
        print("3. Update an element")
        print("4. Exit")
        
        choice = input("Enter your choice (1/2/3/4): ")

        if choice == '1':
            element = input("Enter the element to add: ")
            my_list.append(element)
            print(f"'{element}' has been added to the list.")

        elif choice == '2':
            element = input("Enter the element to remove: ")
            if element in my_list:
                my_list.remove(element)
                print(f"'{element}' has been removed from the list.")
            else:
                print(f"'{element}' not found in the list.")

        elif choice == '3':
            element = input("Enter the element to update: ")
            if element in my_list:
                index = my_list.index(element)
                new_element = input("Enter the new value: ")
                my_list[index] = new_element
                print(f"'{element}' has been updated to '{new_element}'.")
            else:
                print(f"'{element}' not found in the list.")

        elif choice == '4':
            print("Exiting the program.")
            break

        else:
            print("Invalid choice. Please enter a valid option (1/2/3/4).")

if __name__ == "__main__":
    main()`,
    };


  const [questions, setQuestions] = useState([
    {
      question: "Q1: Write a Python function to calculate the sum of all elements in a list.",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  const [questions1, setQuestions1] = useState([
    {
      question: "Q2: Write a function to find the maximum and minimum values in a list without using built-in functions like max() or min() and print their values on screen.",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  const [questions2, setQuestions2] = useState([
    {
      question: "Q3: Create a function that reverses a list without using built-in functions like reverse().",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  const [questions3, setQuestions3] = useState([
    {
      question: "Q4: Given a list of numbers, create a new list that contains only the even numbers using list comprehension.",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  const [questions4, setQuestions4] = useState([
    {
      question: "Q5: Write a function that filters out all the elements of a list that are divisible by a given number.",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  const [questions5, setQuestions5] = useState([
    {
      question: "Q6: Given a list of lists, flatten it into a single list. For example, [[1, 2], [3, 4], [5, 6]] should become [1, 2, 3, 4, 5, 6].",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  const [questions6, setQuestions6] = useState([
    {
      question: "Q7: Given a list, perform various slicing operations, such as extracting a sub-list, reversing a sub-list, and finding elements within a specific range.",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  const [questions7, setQuestions7] = useState([
    {
      question: "Q8: Write a function to sort a list in ascending and descending order.",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  const [questions8, setQuestions8] = useState([
    {
      question: "Q9: Write a function to remove duplicates from a list while maintaining the order of the remaining elements.",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  const [questions9, setQuestions9] = useState([
    {
      question: "Q10: Write a function that takes two lists as input and returns a new list containing elements that are common in both lists.",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  const [questions10, setQuestions10] = useState([
    {
      question: "Q11: Write a function to count the occurrences of a specific element in a list.",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  const [questions11, setQuestions11] = useState([
    {
      question: "Q12: Create a function that splits a list into smaller sublists of a fixed size.",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  const [questions12, setQuestions12] = useState([
    {
      question: "Q13: Write a function to check if a list is a palindrome (reads the same backward as forward).",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  const [questions13, setQuestions13] = useState([
    {
      question: "Q14: Write a function to merge two sorted lists into a single sorted list.",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  const [questions14, setQuestions14] = useState([
    {
      question: "Q15: Find the median value of a list (middle value when sorted).",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  const [questions15, setQuestions15] = useState([
    {
      question: "Q16: Implement a function that shuffles the elements of a list randomly.",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  const [questions16, setQuestions16] = useState([
    {
      question: "Q17: Create a function that partitions a list into two sublists based on a given condition.",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  const [questions17, setQuestions17] = useState([
    {
      question: "Q18: Perform basic list operations like adding, removing, and updating elements based on user input.",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  

  const toggleAccordion = (index, questions, setQuestions) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].isOpen = !updatedQuestions[index].isOpen;
    setQuestions(updatedQuestions);
  };

  const changeCodeLanguage = (index, language, questions, setQuestions) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].codeLanguage = language;
    setQuestions(updatedQuestions);
  };

  

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };
  const divRef = useRef(null);
  return (
    <div className="testing">
      <Sidebar>
        <div className="containera">
          <div className="ch-content">
            <h2 className="ui header">
            <Link to="/ques14">
              <button
                className="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
              </Link>
              <Link to="/ques16">
                <button
                  className="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>
            <div className="main-body ui">
              <h2 className="ui left floated header" style={{ color: "#001C30" }}>
                List:
              </h2>
              <div className="ui clearing divider"></div>
              <CodeAccordion
                questions={questions}
                code={code}
                toggleAccordion={(index) => toggleAccordion(index, questions, setQuestions)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions, setQuestions)
                }
              /><br/>
              <CodeAccordion
                questions={questions1}
                code={code1}
                toggleAccordion={(index) => toggleAccordion(index, questions1, setQuestions1)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions1, setQuestions1)
                }
              /><br/>
              <CodeAccordion
                questions={questions2}
                code={code2}
                toggleAccordion={(index) => toggleAccordion(index, questions2, setQuestions2)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions2, setQuestions2)
                }
              /><br/>
              <CodeAccordion
                questions={questions3}
                code={code3}
                toggleAccordion={(index) => toggleAccordion(index, questions3, setQuestions3)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions3, setQuestions3)
                }
              /><br/>
              <CodeAccordion
                questions={questions4}
                code={code4}
                toggleAccordion={(index) => toggleAccordion(index, questions4, setQuestions4)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions4, setQuestions4)
                }
              /><br/>
              <CodeAccordion
                questions={questions5}
                code={code5}
                toggleAccordion={(index) => toggleAccordion(index, questions5, setQuestions5)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions5, setQuestions5)
                }
              /><br/>
              <CodeAccordion
                questions={questions6}
                code={code6}
                toggleAccordion={(index) => toggleAccordion(index, questions6, setQuestions6)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions6, setQuestions6)
                }
              /><br/>
              <CodeAccordion
                questions={questions7}
                code={code7}
                toggleAccordion={(index) => toggleAccordion(index, questions7, setQuestions7)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions7, setQuestions7)
                }
              /><br/>
              <CodeAccordion
                questions={questions8}
                code={code8}
                toggleAccordion={(index) => toggleAccordion(index, questions8, setQuestions8)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions8, setQuestions8)
                }
              /><br/>
              <CodeAccordion
                questions={questions9}
                code={code9}
                toggleAccordion={(index) => toggleAccordion(index, questions9, setQuestions9)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions9, setQuestions9)
                }
              /><br/>
              <CodeAccordion
                questions={questions10}
                code={code10}
                toggleAccordion={(index) => toggleAccordion(index, questions10, setQuestions10)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions10, setQuestions10)
                }
              /><br/>
              <CodeAccordion
                questions={questions11}
                code={code11}
                toggleAccordion={(index) => toggleAccordion(index, questions11, setQuestions11)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions11, setQuestions11)
                }
              /><br/>
              <CodeAccordion
                questions={questions12}
                code={code12}
                toggleAccordion={(index) => toggleAccordion(index, questions12, setQuestions12)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions12, setQuestions12)
                }
              /><br/>
              <CodeAccordion
                questions={questions13}
                code={code13}
                toggleAccordion={(index) => toggleAccordion(index, questions13, setQuestions13)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions13, setQuestions13)
                }
              /><br/>
              <CodeAccordion
                questions={questions14}
                code={code14}
                toggleAccordion={(index) => toggleAccordion(index, questions14, setQuestions14)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions14, setQuestions14)
                }
              /><br/>
              <CodeAccordion
                questions={questions15}
                code={code15}
                toggleAccordion={(index) => toggleAccordion(index, questions15, setQuestions15)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions15, setQuestions15)
                }
              /><br/>
              <CodeAccordion
                questions={questions16}
                code={code16}
                toggleAccordion={(index) => toggleAccordion(index, questions16, setQuestions16)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions16, setQuestions16)
                }
              /><br/>
              <CodeAccordion
                questions={questions17}
                code={code17}
                toggleAccordion={(index) => toggleAccordion(index, questions17, setQuestions17)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions17, setQuestions17)
                }
              /><br/>
              
            </div>
            <Link to="/ques14">
              <button
                className="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
              </Link>
              <Link to="/ques16">
                <button
                  className="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default Question15;
