import { useRef, useEffect } from "react";
import "../../Components/Chapters/Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Sidebar from "../JavaSideBar";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const JavaC13 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };

  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };
  const jc13code1 = `public class Main {
    public static void main(String[] args) {
        
        boolean isTrue = true;
 
        System.out.println(isTrue);

        boolean isFalse = false;
 
        System.out.println(isFalse);
    
    }
}`;
  const jc13code2 = `public class Main {
    public static void main(String[] args) {
        
        boolean a = true;
        boolean b = false;

        boolean resultAnd = a && b;
        System.out.println(resultAnd) 

        boolean resultOr = a || b;
        System.out.println(resultOr)  

        boolean resultNot = !a;
        System.out.println(resultNot)     
    
    }
}`;
  const jc13code3 = `public class Main {
    public static void main(String[] args) {
        
        boolean isTrue = true;

        if (isTrue) {
            System.out.println("Condition is true");
        } else {
            System.out.println("Condition is false");
        }    
    
    }
}`;
  const jc13code4 = `public class Main {
    public static void main(String[] args) {
        
        int x = 5;
        int y = 10;
        boolean isEqual = (x == y);  
        System.out.println(isEqual);

        boolean isNotEqual = (x != y); 
        System.out.println(isNotEqual);
    
    }
}`;
  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/java/maths">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/java/if_else">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body ">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Booleans:
              </h2>
              <div class="ui clearing divider"></div>
              <p style={{ backgroundColor: "", padding: "" }}>
                In Java, boolean is a distinct data type unlike in some other
                programming languages like C language, in which we use integers
                to represent boolean values.
                <br /><br />
                Booleans store one of the two values:
                <ul className="ui unordered list">
                  <li>True / False</li>
                  <li>On / Off</li>
                  <li>Yes / No</li>
                </ul>
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>booleans.java</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="java" style={darcula}>
                      {jc13code1}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Booleans and Logical Operators:
                </h2>
                <div class="ui clearing divider"></div>
                <span>
                  We use booleans usually with logical operators to perform
                  logical operations.
                </span>
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>booleans.java</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="java" style={darcula}>
                      {jc13code2}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Booleans and Conditional Statements:
                </h2>
                <div class="ui clearing divider"></div>
                <span>
                  Booleans are commonly used in conditional statements like if,
                  if else, while and do while loops to control the flow of the
                  program based on the condition.
                </span>
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>booleans.java</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="java" style={darcula}>
                      {jc13code3}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Booleans and Comparison Operators:
                </h2>
                <div class="ui clearing divider"></div>
                <span>Comparison operators always return boolean values.</span>
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>booleans.java</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="java" style={darcula}>
                      {jc13code4}
                    </SyntaxHighlighter>
                  </div>
                </div>
              </p>
            </div>
            <p style={{ backgroundColor: "#FBEEAC", padding: "15px" }}>
              <b>Note : </b>Boolean data type in Java is used for storing
              values that can be either true or false. Booleans often go
              hand-in-hand with logical operators to perform logical operations.
            </p>
            <br />

            <Link to="/java/maths">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/java/if_else">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default JavaC13;
