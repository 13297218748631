import { useRef, useEffect } from "react";
import "../../Components/Chapters/Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "../JavaSideBar";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

const JavaC1 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };

  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor:'#D8D9DA'
  };
  const jc1code1 = ``;
  return (
    <div class="testing">
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
              <Link to="/java/getting_started">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body ui ">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Introduction to Java:
              </h2>
              <div class="ui clearing divider"></div>
              <p style={{ backgroundColor: "#FBEEAC", padding: "15px" }}>
                <ol class="ui ordered list">
                  <li>
                    Java is a high-level, versatile, and widely used programming
                    language that was developed by Sun Microsystems (now owned
                    by Oracle Corporation) in the mid-1990s.
                  </li>

                  <li>Java is an object oriented programming language.</li>
                </ol>
              </p>
              <br />
              <h2
                className="ui left floated header"
                style={{ color: "#001C30" }}
              >
                Applications
              </h2>
              <div class="ui clearing divider"></div>
              <p>
                Java is most widely used in :<br />
                <div className="margin">
                  <ul class="ui list">
                    <li>Android App Development.</li>
                    <li>
                      Java has been used for game development, particularly in
                      the creation of 2D games.
                    </li>
                    <li>
                      Building dynamic web applications using Servlets and
                      JavaServer Pages (JSP).
                    </li>
                    <li>
                      Java Swing is a GUI (Graphical User Interface) library for
                      building desktop applications.{" "}
                    </li>
                    <li>
                      Java can be used in embedded systems, such as smart
                      appliances and industrial control systems.
                    </li>
                    <li>
                      Java is often used in cloud-based applications and
                      services.
                    </li>
                  </ul>
                </div>
              </p>
              <br />

              <h2
                className="ui left floated header"
                style={{ color: "#001C30" }}
              >
                Features / Why to learn Java?
              </h2>
              <div class="ui clearing divider"></div>
              <p>
                <div className="margin">
                  <ul class="ui list">
                    <li>
                      <b>Platform Independence:</b>
                      <span>
                        Java is often referred to as a "write once, run
                        anywhere" language because of its platform independence.
                        It can run on any other platform (e.g., macOS or Linux)
                        without modification.
                      </span>
                    </li>
                    <li>
                      <b>Object-Oriented Programming (OOP): </b>
                      <span>
                        Java 
                        emphasizes the use of classes and objects to structure
                        code.
                      </span>
                    </li>
                    
                    <li>
                      <b>Strongly Typed: </b>
                      <span>
                        Java is a strongly typed language, which means that
                        variable types must be declared explicitly. This will help
                        catch type-related errors at compile-time rather than
                        runtime.
                      </span>
                    </li>
                    <li>
                      <b>Large Standard Library: </b>
                      <span>
                        Java comes with a vast standard library (Java Standard
                        Library or Java API) that provides pre-built classes and
                        methods for common tasks.
                      </span>
                    </li>
                    <li>
                      <b>Multi-Threading: </b>
                      <span>
                        Java has built-in support for multithreading, allowing
                        developers to create applications that can perform
                        multiple tasks concurrently.
                      </span>
                    </li>
                    <li>
                      <b>Security: </b>
                      <span>
                        Java places a strong emphasis on security. The JVM
                        includes features like bytecode verification and a
                        security manager to protect against malicious code.
                      </span>
                    </li>
                  </ul>
                </div>
              </p>
              <br />

              <h2
                className="ui left floated header"
                style={{ color: "#001C30" }}
              >
                Syntax of Java:
              </h2>
              <div class="ui clearing divider"></div>
              <p>
                The syntax of Java is designed to be relatively easy to read and
                write, making it accessible to both beginners and experienced
                programmers.
              </p>


              <ul className="ui unordered list">
                <li><b>Case Sensitivity: </b>Java is case-sensitive. For example, myVariable and myvariable are treated as different identifiers.</li>
                <li><b>Semicolons: </b>Statements in Java are terminated with semicolons (;).</li>
                <li><b>Comments: </b> Java supports both single-lined (//) and multi-lined (/* ... */) comments. </li>
                <li><b>Data Types:  </b>There is a variety of data types in Java. Variables need to be declared with their data type before use.</li>
                <li><b>Variables: </b>Variables are used to store data. They must be declared with a data type and an optional initialization value.</li>
              </ul>
              <br />
              <span>
                There are many other things about the syntax of Java.
                We will study about the syntax of Java in next java.
              </span>
              <br /><br />

              <p style={{ backgroundColor: "#FBEEAC", padding: "10px" }}>
                You will learn all the basics of Java in this tutorial.
                <br />
                Don't worry, if you have never ever touched computer programming.
              </p>
              
            </div><br />
            <button
              class="ui primary button"
              style={{ flex: 1, minWidth: "100px", height: "40px" }}
            >
              Previous
            </button>
            <Link to="/java/getting_started">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
            <br />
          </div>
        </div>
      </Sidebar>

      <Footer />
    </div>
  );
};
export default JavaC1;
