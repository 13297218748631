import { useRef, useEffect } from "react";
import "../../Components/Chapters/Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "../PythonSideBar";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

const PythonC18 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };

  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor:'#D8D9DA'
  };
  const pc18code1 = `fruits = ['apple', 'banana', 'cherry']
for fruit in fruits:
    print(fruit)`;
  const pc18code2 = `for x in range(5):
    print("Value of x is: ", x)`;
  const pc18code3 = `for x in range(3, 9):      
    print("Value of x is: ", x)`;
  const pc18code4 = `for x in range(0, 3, 9):     
print("Value of x is: ", x)`;
  const pc18code5 = `for x in range(5):
    print("Value of x is: ", x)
else: 
    print("Value of x is: 5")`;
  
    const pc18code7 = `for i in range(3):
    for j in range(3):
        print(i, j)`
  return (
    <div class="testing">
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/python/if_else">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/python/while_loop">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                For Loop:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                In Python, a for loop is used to iterate over a sequence of
                items, such as a list, tuple, string, or other iterable objects.
                The loop iterates over each item in the sequence and executes a
                block of code for each item.
              </span>
              <br />
              <br />
              

                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>forLoop.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc18code1}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <p style={{ backgroundColor: "#FBEEAC", padding: "15px" }}>
                  <b>Note: </b>In Python, we do not need counter variable in
                  for-loop. The for loop starts and iterates over the sequence.
                  It executes the code of loop for every single item for
                  one time.
                </p>
                <br />
               
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  The range function in for loop:
                </h2>
                <div className="ui clearing divider"></div>
                <span>
                  In Python, the range() function is employed to create a
                  numerical sequence within a defined range. This function
                  yields an iterable entity that signifies the progression of
                  numbers. It is frequently employed in conjunction with loops
                  to facilitate iteration over a designated range of numerical
                  values.
                </span>
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>forLoop.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc18code2}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                
                <span>
                  We can specify not only the start and end of range function
                  but also the gaps/jumps.
                </span>
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>forLoop.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc18code3}
                    </SyntaxHighlighter>
                  </div>
                </div>
               
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>forLoop.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc18code4}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Else Statement in for loop:
                </h2>
                <div className="ui clearing divider"></div>
                <span>
                  We can also use else statement in for-loop. The code in else
                  statement will be executed when the condition becomes false.
                </span>
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>forLoop.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc18code5}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <p style={{ backgroundColor: "#FBEEAC", padding: "15px" }}>
                  <b>Note: </b>The code in the else block is not executed if the
                  loop is stopped by a break statement.
                </p>
               
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Nested Loops:
                </h2>
                <div className="ui clearing divider"></div>
                <span>
                  In Python, a nested loop is when one loop is contained within
                  another loop. This structure enables the execution of a group
                  of statements repeatedly, with each repetition of the outer
                  loop's iteration. The nested loops are particularly crucial for
                  tasks that involve performing repetitive actions on various
                  levels or dimensions of data.
                </span> <br /> <br />
                <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>forLoop.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc18code7}
                  </SyntaxHighlighter>
                </div>
              </div>
              
            </div> <br />
            <Link to="/python/if_else">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/python/while_loop">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </div>
  );
};
export default PythonC18;
