import { useState, useEffect } from "react";
import Sidebar from "./SideBar";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
// import "./Quiz.css";
import "../../Components/Chapters/Chapters.css";
import Modal from "react-modal";

const JQuiz7 = () => {
  const [answers, setAnswers] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const handleQuizSubmit = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const questions = [
    {
      id: 1,
      question: "  What is the most common way to take input from the user in Java:",
      options: ["Using the System.in stream", "Using the Scanner class", "Using the InputReader class", "Using the java.util.Input class"],
      correctAnswer: "Using the Scanner class",
    },
    {
      id: 2,
      question: " Which package does the Scanner class belong to in Java:",
      options: ["java.input", "java.io", "java.io", "java.util"],
      correctAnswer: "java.util",
    },
    {
      id: 3,
      question: "  What is the final step in taking input using the Scanner class in Java:",
      options: [
        "Display the user's input",
        "Close the System.in stream",
        "Close the Scanner object",
        "Import the java.io package",
      ],
      correctAnswer: "Close the Scanner object",
    },
    {
      id: 4,
      question: "  When working with the Scanner class, why is it important to close the scanner after reading input:",
      options: ["To save memory", "To free up system resources", "It is not necessary to close the scanner", "To prevent memory leaks"],
      correctAnswer: "To prevent memory leaks",
    }
  ];
  const handleAnswerChange = (questionId, selectedAnswer) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: selectedAnswer,
    }));
  };

  const calculateScore = () => {
    let correctCount = 0;
    let incorrectCount = 0;

    questions.forEach((question) => {
      const userAnswer = answers[question.id];
      if (userAnswer === question.correctAnswer) {
        correctCount++;
      } else {
        incorrectCount++;
      }
    });

    return { correctCount, incorrectCount };
  };

  const { correctCount, incorrectCount } = calculateScore();
  return (
    <>
      <Sidebar>
        <div class="container">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/quizes/java_quiz6">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                  }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/quizes/java_quiz8">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              </h2>
              <h1 class="start content ui left floated header">
                Taking Input
              </h1>
              <div className="ui clearing divider"></div>
            

            <div class="main-body">
              <h2 class="ui left floated header">
                Quiz # 7
              </h2>
              <span class="tut-btn"><Link to="/java/input">
                  <button class="ui secondary button">Read Tutorial</button>
                </Link></span>
              <div class="ui clearing divider"></div>
              <div>
                {questions.map((question, i) => (
                  <div key={question.id}>
                    <div class="con">
                    <div class="que">Q{i+1}:<span class="one-per-left-margin">{question.question}</span></div>
                      {question.options.map((option) => (
                        <label class="label" key={option}>
                          <input
                            type="radio"
                            name={`question_${question.id}`}
                            value={option}
                            checked={answers[question.id] === option}
                            onChange={(e) =>
                              handleAnswerChange(question.id, e.target.value)
                            }
                          />
                          <span className="one-per-left-margin">{option}</span>
                          <br />
                        </label>
                      ))}
                    </div>
                    <hr />
                  </div>
                ))}<br/>
                <center><button class="ui primary button" onClick={handleQuizSubmit}>Submit Quiz</button></center>

                {/* Modal for displaying results */}
                <Modal class="modal" isOpen={isModalOpen} onRequestClose={closeModal}>
                  <b style={{fontSize:'20px'}}>Quiz Results</b>
                  <b><p>Your score is {correctCount}/{questions.length} </p></b>
                  {questions.map((question,i) => (
                    <div key={question.id}>
                      <p>Q{i+1}: {question.question}</p>
                      <p><b>Correct Answer:</b> {question.correctAnswer}</p>
                      <p className={`${question.correctAnswer}` === `${answers[question.id]}` ? "correct" : "wrong"}>
                        <b>Your Answer:</b> {answers[question.id] || "Not answered"}
                      </p>
                      <hr />
                    </div>
                  ))}
                  <button class="ui secondary button" onClick={closeModal}>Close</button><br /><br />
                </Modal>
              </div>

              <br />
            </div>
            <Link to="/quizes/java_quiz6">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                  }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/quizes/java_quiz8">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
            <br />
          </div>
        </div>
      </Sidebar>

      <div class="sideBarBody">
        <Footer />
      </div>
    </>
  );
};
export default JQuiz7;
