import { useRef, useEffect } from "react";
import "../../Components/Chapters/Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "../PythonSideBar";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

const PythonC3 = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  const divRef = useRef(null);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };

  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };
  const c3code1 = `print("Hello World!")`;
  const c3code2 = `x = 10
if x > 0:
    print("x is positive")`;
  const c3code3 = `x = 10
if x > 0:
    print("x is positive")
        print("Hi")`;
  const c3code4 = `x = 10
if x > 0:
    print("x is positive")
print("Hi")`;
  return (
    <div class="testing">
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/python/getting_started">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/python/output">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Basic Syntax:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                In programming, syntax refers to the set of rules and
                conventions that dictate how programs written in a programming
                language should be structured. Python has the most easy and
                readable syntax. It closely resembles English Language. Because of its easy syntax, it is an
                excellent choice for beginners.
              </span>
              <div class="ui clearing divider"></div>
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>indentation.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {c3code1}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <p style={{ backgroundColor: "#FBEEAC", padding: "10px" }}>
                <b>Note : </b> Whenever you write your python program, always
                save it with the extension .py, like myFirstProgram.py
              </p>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Indentation and spaces:
              </h2>
              <div className="ui clearing divider"></div>
              <span>
                Unlike other programming languages, which uses braces and other
                symbols to separate different blocks of code like function,
                loops etc, python uses indentation.
              </span>
              <br /> <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>indentation.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {c3code2}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Explanation:
              </h2>
              <div className="ui clearing divider"></div>
              <span>
                In this code, we can see that in the third line, there is a
                space in the begining. This is the indentation. In the example
                above, the lines of code indented under the if statement form a
                block of code that is executed if the condition is true. All the
                lines which have same space in starting as third line, those
                lines will be the part of if statement. The line that is not
                indented is not part of the if block and is executed regardless
                of the condition. Indentation is
                necessary in python. If your code is not indented correctly, you
                will get error.
              </span>
              <br />
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Indentation Error and Example:
              </h2>
              <div className="ui clearing divider"></div>
              <div
                style={{
                  backgroundColor: "#CF0A0A",
                  paddingTop: "10px",
                  borderRadius: "3px",
                  marginLeft: "0%",
                  width: "100%",
                }}
              >
                <div className="ml-3 mr-3">
                  <b>error.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {c3code3}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <span>
                In the above example, you will get an error because there is a
                space in the start of fourth line. Third line is not the part of
                if statement. All the lines in the same block are intended
                at the same level.
              </span>
              <br />
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Example:
              </h2>
              <div className="ui clearing divider"></div>
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>indentation.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {c3code4}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <span>
              Now in this example, we see that the fourth line is at the same level of indentation as that of if statement. It will be executed separately as it is not the part of if statement.
              </span>
            </div>
            <br />
            <Link to="/python/getting_started">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/python/output">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </div>
  );
};
export default PythonC3;
