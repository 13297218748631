import React, { useState, useRef, useEffect } from "react";
import Sidebar from "./SideBar";
import "../../Components/SideBar/App.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const CodeAccordion = ({ questions, code, toggleAccordion, changeCodeLanguage }) => {
    const divRef = useRef(null);
    const copyContent = () => {
        const divContent = divRef.current.innerText;
        navigator.clipboard
          .writeText(divContent)
          .then(() => {
            toast.success("Content copied!", { position: "top-center" });
          })
          .catch((error) => {
            toast.error("Error copying content:", error, {
              position: "top-center",
            });
          });
      };
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };

  return (
    <div>
      {questions.map((item, index) => (
        <div key={index} className="border p-2 rounded-md">
          <div className="flex justify-between">
            <span className="text-lg font-semibold">
              {item.question}
            </span>
            <button
              className="bg-gray-200 px-2 py-1 rounded-md"
              onClick={() => toggleAccordion(index)}
            >
              {item.isOpen ? "-" : "+"}
            </button>
          </div>
          <div className={`mt-2 ${item.isOpen ? "block" : "hidden"}`}>
            <div className="mt-2 flex space-x-4">
              {Object.keys(code).map((language) => (
                <button
                  key={language}
                  onClick={() => changeCodeLanguage(index, language)}
                  className={`${
                    item.codeLanguage === language
                      ? "bg-blue-500 hover:bg-blue-600 text-white"
                      : "bg-gray-200 hover:bg-gray-300 text-gray-700"
                  } px-3 py-2 rounded-md transition duration-300 ease-in-out transform hover:scale-105`}
                >
                  {language}
                </button>
              ))}
            </div>
            <p className="text-gray-800">
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>output.cpp</b>
                  <i
                    className="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language={`${item.codeLanguage}`} style={darcula}>
                    {code[item.codeLanguage]}
                  </SyntaxHighlighter>
                </div>
              </div>
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

const Question22 = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
    const code = {
        cpp: `// C++ code
#include <iostream>
#include <string>
using namespace std;

class Student {
private:
    string name;
    int age;

public:
    // Getter for name
    string getName() {
        return name;
    }

    // Setter for name
    void setName(const string& newName) {
        name = newName;
    }

    // Getter for age
    int getAge() {
        return age;
    }

    // Setter for age
    void setAge(int newAge) {
        age = newAge;
    }
};

int main() {
  Student student;
    
  // Set student attributes using setters
  student.setName("John");
  student.setAge(20);

  cout<<"Name: "<<student.getName()<<endl;
  cout<<"Age: "<<student.getAge()<<endl;

  return 0;
}`,
        java: `// Java code
public class Student {
    private String name;
    private int age;

    // Getter for name
    public String getName() {
        return name;
    }

    // Setter for name
    public void setName(String newName) {
        name = newName;
    }

    // Getter for age
    public int getAge() {
        return age;
    }

    // Setter for age
    public void setAge(int newAge) {
        age = newAge;
    }

    public static void main(String[] args) {
        Student student = new Student();

        // Set student attributes using setters
        student.setName("John");
        student.setAge(20);

        // Get and print student attributes using getters
        System.out.println("Student Name: " + student.getName());
        System.out.println("Student Age: " + student.getAge());
    }
}`,
        python: `# Python code
class Student:
    def __init__(self):
        self.__name = ""
        self.__age = 0

    # Getter for name
    def get_name(self):
        return self.__name

    # Setter for name
    def set_name(self, new_name):
        self.__name = new_name

    # Getter for age
    def get_age(self):
        return self.__age

    # Setter for age
    def set_age(self, new_age):
        self.__age = new_age

student = Student()

# Set student attributes using setters
student.set_name("John")
student.set_age(20)

# Get and print student attributes using getters
print("Student Name:", student.get_name())
print("Student Age:", student.get_age())`,
    };
    const code1 = {
        cpp: `// C++ code
#include <iostream>
using namespace std;

class BankAccount {
private:
    double balance;

public:
    BankAccount(double initialBalance) : balance(initialBalance) {}

    void deposit(double amount) {
        if (amount > 0) {
            balance += amount;
            cout << "Deposited: " << amount << " Balance: " << balance << endl;
        }
    }

    void withdraw(double amount) {
        if (amount > 0 && amount <= balance) {
            balance -= amount;
            cout << "Withdrawn: " << amount << " Balance: " << balance << endl;
        } else {
            cout << "Withdrawal failed. Insufficient balance." << endl;
        }
    }

    double getBalance() {
        return balance;
    }
};

int main() {
    BankAccount account(1000.0);
    account.deposit(500.0);
    account.withdraw(300.0);
    account.withdraw(1300.0);

    return 0;
}`,
        java: `// Java code
public class BankAccount {
    private double balance;

    public BankAccount(double initialBalance) {
        balance = initialBalance;
    }

    public void deposit(double amount) {
        if (amount > 0) {
            balance += amount;
            System.out.println("Deposited: " + amount + " Balance: " + balance);
        }
    }

    public void withdraw(double amount) {
        if (amount > 0 && amount <= balance) {
            balance -= amount;
            System.out.println("Withdrawn: " + amount + " Balance: " + balance);
        } else {
            System.out.println("Withdrawal failed. Insufficient balance.");
        }
    }

    public double getBalance() {
        return balance;
    }

    public static void main(String[] args) {
        BankAccount account = new BankAccount(1000.0);
        account.deposit(500.0);
        account.withdraw(300.0);
        account.withdraw(1300.0);
    }
}`,
        python: `# Python code
class BankAccount:
    def __init__(self, initial_balance):
        self.__balance = initial_balance

    def deposit(self, amount):
        if amount > 0:
            self.__balance += amount
            print(f"Deposited: {amount} Balance: {self.__balance}")

    def withdraw(self, amount):
        if amount > 0 and amount <= self.__balance:
            self.__balance -= amount
            print(f"Withdrawn: {amount} Balance: {self.__balance}")
        else:
            print("Withdrawal failed. Insufficient balance.")

    def get_balance(self):
        return self.__balance

account = BankAccount(1000.0)
account.deposit(500.0)
account.withdraw(300.0)
account.withdraw(1300.0)`,
    };
    const code2 = {
        cpp: `// C++ code
#include <iostream>
#include <string>
using namespace std;

class Employee {
private:
    string name;
    double salary;
    int ID;

public:
    Employee(const string& empName, double empSalary, int empID)
        : name(empName), salary(empSalary), ID(empID) {}

    string getName() {
        return name;
    }

    double getSalary() {
        return salary;
    }

    int getID() {
        return ID;
    }
};

int main() {
    Employee employee("John Doe", 50000.0, 12345);
    cout << "Name: " << employee.getName() << endl;
    cout << "Salary: " << employee.getSalary() << endl;
    cout << "ID: " << employee.getID() << endl;

    return 0;
}`,
        java: `// Java code
public class Employee {
    private String name;
    private double salary;
    private int ID;

    public Employee(String empName, double empSalary, int empID) {
        name = empName;
        salary = empSalary;
        ID = empID;
    }

    public String getName() {
        return name;
    }

    public double getSalary() {
        return salary;
    }

    public int getID() {
        return ID;
    }

    public static void main(String[] args) {
        Employee employee = new Employee("John Doe", 50000.0, 12345);
        System.out.println("Name: " + employee.getName());
        System.out.println("Salary: " + employee.getSalary());
        System.out.println("ID: " + employee.getID());
    }
}`,
        python: `# Python code
class Employee:
    def __init__(self, emp_name, emp_salary, emp_ID):
        self.__name = emp_name
        self.__salary = emp_salary
        self.__ID = emp_ID

    def get_name(self):
        return self.__name

    def get_salary(self):
        return self.__salary

    def get_ID(self):
        return self.__ID

employee = Employee("John Doe", 50000.0, 12345)
print("Name:", employee.get_name())
print("Salary:", employee.get_salary())
print("ID:", employee.get_ID())`,
    };
    const code3 = {
        cpp: `// C++ code
#include <iostream>
using namespace std;

class BankAccount {
private:
    double balance;

public:
    BankAccount(double initialBalance) : balance(initialBalance) {}

    void deposit(double amount) {
        if (amount > 0) {
            balance += amount;
            cout << "Deposited: " << amount << " Balance: " << balance << endl;
        }
    }

    void withdraw(double amount) {
        if (amount > 0 && amount <= balance) {
            balance -= amount;
            cout << "Withdrawn: " << amount << " Balance: " << balance << endl;
        } else {
            cout << "Withdrawal failed. Insufficient balance." << endl;
        }
    }

    double getBalance() {
        return balance;
    }
};

int main() {
    BankAccount account(1000.0);
    account.deposit(500.0);
    account.withdraw(300.0);
    account.withdraw(2000.0);

    return 0;
}`,
        java: `// Java code
public class BankAccount {
    private double balance;

    public BankAccount(double initialBalance) {
        balance = initialBalance;
    }

    public void deposit(double amount) {
        if (amount > 0) {
            balance += amount;
            System.out.println("Deposited: " + amount + " Balance: " + balance);
        }
    }

    public void withdraw(double amount) {
        if (amount > 0 && amount <= balance) {
            balance -= amount;
            System.out.println("Withdrawn: " + amount + " Balance: " + balance);
        } else {
            System.out.println("Withdrawal failed. Insufficient balance.");
        }
    }

    public double getBalance() {
        return balance;
    }

    public static void main(String[] args) {
        BankAccount account = new BankAccount(1000.0);
        account.deposit(500.0);
        account.withdraw(300.0);
        account.withdraw(2000.0);
    }
}`,
        python: `# Python code
class BankAccount:
    def __init__(self, initial_balance):
        self.__balance = initial_balance

    def deposit(self, amount):
        if amount > 0:
            self.__balance += amount
            print(f"Deposited: {amount} Balance: {self.__balance}")
        else:
            print("Invalid deposit amount.")

    def withdraw(self, amount):
        if amount > 0 and amount <= self.__balance:
            self.__balance -= amount
            print(f"Withdrawn: {amount} Balance: {self.__balance}")
        else:
            print("Withdrawal failed. Insufficient balance.")

    def get_balance(self):
        return self.__balance

account = BankAccount(1000.0)
account.deposit(500.0)
account.withdraw(300.0)
account.withdraw(2000.0)`,
    };
    const code4 = {
        cpp: `// C++ code
#include <iostream>
#include <string>
using namespace std;

class Person {
private:
    string phoneNumber;
    string email;

public:
    // Constructor to initialize contact information
    Person(const string& initialPhoneNumber, const string& initialEmail)
        : phoneNumber(initialPhoneNumber), email(initialEmail) {}

    // Setter for updating phone number
    void setPhoneNumber(const string& newPhoneNumber) {
        phoneNumber = newPhoneNumber;
    }

    // Setter for updating email
    void setEmail(const string& newEmail) {
        email = newEmail;
    }

    // Getter for retrieving phone number
    string getPhoneNumber() {
        return phoneNumber;
    }

    // Getter for retrieving email
    string getEmail() {
        return email;
    }
};

int main() {
    Person person("123-456-7890", "john@example.com");

    // Update contact information using setters
    person.setPhoneNumber("987-654-3210");
    person.setEmail("jane@example.com");

    // Retrieve and print updated contact information using getters
    cout << "Phone Number: " << person.getPhoneNumber() << endl;
    cout << "Email: " << person.getEmail() << endl;

    return 0;
}`,
        java: `// Java code
public class Person {
    private String phoneNumber;
    private String email;

    public Person(String initialPhoneNumber, String initialEmail) {
        phoneNumber = initialPhoneNumber;
        email = initialEmail;
    }

    // Setter for updating phone number
    public void setPhoneNumber(String newPhoneNumber) {
        phoneNumber = newPhoneNumber;
    }

    // Setter for updating email
    public void setEmail(String newEmail) {
        email = newEmail;
    }

    // Getter for retrieving phone number
    public String getPhoneNumber() {
        return phoneNumber;
    }

    // Getter for retrieving email
    public String getEmail() {
        return email;
    }

    public static void main(String[] args) {
        Person person = new Person("123-456-7890", "john@example.com");

        // Update contact information using setters
        person.setPhoneNumber("987-654-3210");
        person.setEmail("jane@example.com");

        // Retrieve and print updated contact information using getters
        System.out.println("Phone Number: " + person.getPhoneNumber());
        System.out.println("Email: " + person.getEmail());
    }
}`,
        python: `# Python code
class Person:
    def __init__(self, initial_phone_number, initial_email):
        self.__phone_number = initial_phone_number
        self.__email = initial_email

    # Setter for updating phone number
    def set_phone_number(self, new_phone_number):
        self.__phone_number = new_phone_number

    # Setter for updating email
    def set_email(self, new_email):
        self.__email = new_email

    # Getter for retrieving phone number
    def get_phone_number(self):
        return self.__phone_number

    # Getter for retrieving email
    def get_email(self):
        return self.__email

person = Person("123-456-7890", "john@example.com")

# Update contact information using setters
person.set_phone_number("987-654-3210")
person.set_email("jane@example.com")

# Retrieve and print updated contact information using getters
print("Phone Number:", person.get_phone_number())
print("Email:", person.get_email())`,
    };
    const code5 = {
        cpp: `// C++ code
#include <iostream>
#include <string>
#include <cstring>
using namespace std;

class User {
private:
    string username;
    string password;

public:
    User(const string& initialUsername, const string& initialPassword)
        : username(initialUsername), password(initialPassword) {}

    // Securely change the password
    void changePassword(const string& currentPassword, const string& newPassword) {
        if (password == currentPassword) {
            password = newPassword;
            cout << "Password changed successfully." << endl;
        } else {
            cout << "Password change failed. Current password is incorrect." << endl;
        }
    }

    // Other methods for user-related functionality

    string getUsername() {
        return username;
    }
};

int main() {
    User user("johndoe", "old_password");

    // Attempt to change the password securely
    user.changePassword("wrong_password", "new_password");
    user.changePassword("old_password", "new_password");

    return 0;
}`,
        java: `// Java code
public class User {
    private String username;
    private String password;

    public User(String initialUsername, String initialPassword) {
        username = initialUsername;
        password = initialPassword;
    }

    // Securely change the password
    public void changePassword(String currentPassword, String newPassword) {
        if (password.equals(currentPassword)) {
            password = newPassword;
            System.out.println("Password changed successfully.");
        } else {
            System.out.println("Password change failed. Current password is incorrect.");
        }
    }

    // Other methods for user-related functionality

    public String getUsername() {
        return username;
    }
    
    public static void main(String[] args) {
        User user = new User("johndoe", "old_password");

        // Attempt to change the password securely
        user.changePassword("wrong_password", "new_password");
        user.changePassword("old_password", "new_password");
    }
}`,
        python: `# Python code
class User:
    def __init__(self, initial_username, initial_password):
        self.__username = initial_username
        self.__password = initial_password

    # Securely change the password
    def change_password(self, current_password, new_password):
        if self.__password == current_password:
            self.__password = new_password
            print("Password changed successfully.")
        else:
            print("Password change failed. Current password is incorrect.")

    # Other methods for user-related functionality

    def get_username(self):
        return self.__username

user = User("johndoe", "old_password")

# Attempt to change the password securely
user.change_password("wrong_password", "new_password")
user.change_password("old_password", "new_password")`,
    };
    const code6 = {
        cpp: `// C++ code
#include <iostream>
#include <unordered_map>
#include <string>
using namespace std;

class SecuritySystem {
private:
    unordered_map<string, bool> accessControl;

public:
    SecuritySystem() {
        // Initialize access control rules
        accessControl["user1"] = true;   // Grant access to user1
        accessControl["user2"] = false;  // Deny access to user2
    }

    // Method to grant access
    void grantAccess(const string& username) {
        accessControl[username] = true;
    }

    // Method to deny access
    void denyAccess(const string& username) {
        accessControl[username] = false;
    }

    // Method to check access
    bool hasAccess(const string& username) {
        if (accessControl.find(username) != accessControl.end()) {
            return accessControl[username];
        }
        return false; // Default to deny access for unknown users
    }
};

int main() {
    SecuritySystem security;

    // Check access for users
    cout << "User1 Access: " << security.hasAccess("user1") << endl;
    cout << "User2 Access: " << security.hasAccess("user2") << endl;

    // Grant access to user2
    security.grantAccess("user2");

    // Check access again
    cout << "User2 Access: " << security.hasAccess("user2") << endl;

    return 0;
}`,
        java: `// Java code
import java.util.HashMap;
import java.util.Map;

public class SecuritySystem {
    private Map<String, Boolean> accessControl;

    public SecuritySystem() {
        // Initialize access control rules
        accessControl = new HashMap<>();
        accessControl.put("user1", true);   // Grant access to user1
        accessControl.put("user2", false);  // Deny access to user2
    }

    // Method to grant access
    public void grantAccess(String username) {
        accessControl.put(username, true);
    }

    // Method to deny access
    public void denyAccess(String username) {
        accessControl.put(username, false);
    }

    // Method to check access
    public boolean hasAccess(String username) {
        return accessControl.getOrDefault(username, false); // Default to deny access for unknown users
    }

    public static void main(String[] args) {
        SecuritySystem security = new SecuritySystem();

        // Check access for users
        System.out.println("User1 Access: " + security.hasAccess("user1"));
        System.out.println("User2 Access: " + security.hasAccess("user2"));

        // Grant access to user2
        security.grantAccess("user2");

        // Check access again
        System.out.println("User2 Access: " + security.hasAccess("user2"));
    }
}`,
        python: `# Python code
class SecuritySystem:
    def __init__(self):
        # Initialize access control rules
        self.access_control = {
            "user1": True,  # Grant access to user1
            "user2": False,  # Deny access to user2
        }

    # Method to grant access
    def grant_access(self, username):
        self.access_control[username] = True

    # Method to deny access
    def deny_access(self, username):
        self.access_control[username] = False

    # Method to check access
    def has_access(self, username):
        return self.access_control.get(username, False)  # Default to deny access for unknown users

security = SecuritySystem()

# Check access for users
print("User1 Access:", security.has_access("user1"))
print("User2 Access:", security.has_access("user2"))

# Grant access to user2
security.grant_access("user2")

# Check access again
print("User2 Access:", security.has_access("user2"))`,
    };
    const code7 = {
        cpp: `// C++ code
#include <iostream>
#include <string>
using namespace std;

class SmartHome {
private:
    bool lightsOn;
    int thermostatTemperature;

public:
    SmartHome() : lightsOn(false), thermostatTemperature(72) {}

    // Method to turn on the lights
    void turnOnLights() {
        lightsOn = true;
        cout << "Lights are on." << endl;
    }

    // Method to turn off the lights
    void turnOffLights() {
        lightsOn = false;
        cout << "Lights are off." << endl;
    }

    // Method to set the thermostat temperature
    void setThermostatTemperature(int temperature) {
        thermostatTemperature = temperature;
        cout << "Thermostat temperature set to " << thermostatTemperature << "°F." << endl;
    }

    // Method to get the thermostat temperature
    int getThermostatTemperature() {
        return thermostatTemperature;
    }
};

int main() {
    SmartHome myHome;

    myHome.turnOnLights();
    myHome.setThermostatTemperature(75);

    myHome.turnOffLights();
    int currentTemperature = myHome.getThermostatTemperature();
    cout << "Current Thermostat Temperature: " << currentTemperature << "°F" << endl;

    return 0;
}`,
        java: `// Java code
public class SmartHome {
    private boolean lightsOn;
    private int thermostatTemperature;

    public SmartHome() {
        lightsOn = false;
        thermostatTemperature = 72;
    }

    // Method to turn on the lights
    public void turnOnLights() {
        lightsOn = true;
        System.out.println("Lights are on.");
    }

    // Method to turn off the lights
    public void turnOffLights() {
        lightsOn = false;
        System.out.println("Lights are off.");
    }

    // Method to set the thermostat temperature
    public void setThermostatTemperature(int temperature) {
        thermostatTemperature = temperature;
        System.out.println("Thermostat temperature set to " + thermostatTemperature + "°F.");
    }

    // Method to get the thermostat temperature
    public int getThermostatTemperature() {
        return thermostatTemperature;
    }

    public static void main(String[] args) {
        SmartHome myHome = new SmartHome();

        myHome.turnOnLights();
        myHome.setThermostatTemperature(75);

        myHome.turnOffLights();
        int currentTemperature = myHome.getThermostatTemperature();
        System.out.println("Current Thermostat Temperature: " + currentTemperature + "°F");
    }
}`,
        python: `# Python code
class SmartHome:
    def __init__(self):
        self.__lights_on = False
        self.__thermostat_temperature = 72

    # Method to turn on the lights
    def turn_on_lights(self):
        self.__lights_on = True
        print("Lights are on.")

    # Method to turn off the lights
    def turn_off_lights(self):
        self.__lights_on = False
        print("Lights are off.")

    # Method to set the thermostat temperature
    def set_thermostat_temperature(self, temperature):
        self.__thermostat_temperature = temperature
        print(f"Thermostat temperature set to {self.__thermostat_temperature}°F.")

    # Method to get the thermostat temperature
    def get_thermostat_temperature(self):
        return self.__thermostat_temperature

my_home = SmartHome()

my_home.turn_on_lights()
my_home.set_thermostat_temperature(75)

my_home.turn_off_lights()
current_temperature = my_home.get_thermostat_temperature()
print(f"Current Thermostat Temperature: {current_temperature}°F")`,
    };
    const code8 = {
        cpp: `// C++ code
#include <iostream>

class MyClass {
private:
    int myPrivateValue;

public:
    MyClass(int value) : myPrivateValue(value) {}

    void setPrivateValue(int newValue) {
        myPrivateValue = newValue;
    }

    int* getPrivateValuePointer() {
        return &myPrivateValue;
    }

    void printValue() {
        std::cout << "Private Value: " << myPrivateValue << std::endl;
    }
};

int main() {
    MyClass obj(42);

    // Access the private member using a pointer
    int* privateValuePointer = obj.getPrivateValuePointer();
    *privateValuePointer = 100;

    obj.printValue(); // Now, the private value is changed to 100

    return 0;
}`,
}

  const [questions, setQuestions] = useState([
    {
      question: "Q1: Create a class \"Student\" with private attributes for name and age, and implement getter and setter methods.",
      codeLanguage: "cpp",
      isOpen: false,
    },
  ]);
  const [questions1, setQuestions1] = useState([
    {
      question: "Q2: Build a \"BankAccount\" class with a private balance attribute and methods to deposit and withdraw funds.",
      codeLanguage: "cpp",
      isOpen: false,
    },
  ]);
  const [questions2, setQuestions2] = useState([
    {
      question: "Q3: Implement a class \"Employee\" with private attributes like name, salary, and ID, and provide methods to access them.",
      codeLanguage: "cpp",
      isOpen: false,
    },
  ]);
  const [questions3, setQuestions3] = useState([
    {
      question: "Q4: Enhance the \"BankAccount\" class with encapsulated methods to prevent negative balances.",
      codeLanguage: "cpp",
      isOpen: false,
    },
  ]);
  const [questions4, setQuestions4] = useState([
    {
      question: "Q5: Write a program to develop a \"Person\" class with private attributes for contact information (phone number, email) and methods to update this information.",
      codeLanguage: "cpp",
      isOpen: false,
    },
  ]);
  const [questions5, setQuestions5] = useState([
    {
      question: "Q6: Implement a \"User\" class with private credentials (username and password) and methods to change the password securely.",
      codeLanguage: "cpp",
      isOpen: false,
    },
  ]);
  const [questions6, setQuestions6] = useState([
    {
      question: "Q7: Design a \"SecuritySystem\" class with private access control rules and methods to grant or deny access to certain resources.",
      codeLanguage: "cpp",
      isOpen: false,
    },
  ]);
  const [questions7, setQuestions7] = useState([
    {
      question: "Q8: Build a \"SmartHome\" class with private attributes for devices (lights, thermostat) and encapsulated methods to control them.",
      codeLanguage: "cpp",
      isOpen: false,
    },
  ]);
  const [questions8, setQuestions8] = useState([
    {
      question: "Q9: Change the value of private data members outside the class without using setter function.",
      codeLanguage: "cpp",
      isOpen: false,
    },
  ]);
  

  const toggleAccordion = (index, questions, setQuestions) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].isOpen = !updatedQuestions[index].isOpen;
    setQuestions(updatedQuestions);
  };

  const changeCodeLanguage = (index, language, questions, setQuestions) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].codeLanguage = language;
    setQuestions(updatedQuestions);
  };

  

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };
  const divRef = useRef(null);
  return (
    <div className="testing">
      <Sidebar>
        <div className="containera">
          <div className="ch-content">
            <h2 className="ui header">
            <Link to="/ques21">
              <button
                className="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
              </Link>
                <button
                  className="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              
              <br />
            </h2>
            <div className="main-body ui">
              <h2 className="ui left floated header" style={{ color: "#001C30" }}>
                Encapsulation:
              </h2>
              <div className="ui clearing divider"></div>
              <CodeAccordion
                questions={questions}
                code={code}
                toggleAccordion={(index) => toggleAccordion(index, questions, setQuestions)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions, setQuestions)
                }
              /><br/>
              <CodeAccordion
                questions={questions1}
                code={code1}
                toggleAccordion={(index) => toggleAccordion(index, questions1, setQuestions1)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions1, setQuestions1)
                }
              /><br/>
              <CodeAccordion
                questions={questions2}
                code={code2}
                toggleAccordion={(index) => toggleAccordion(index, questions2, setQuestions2)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions2, setQuestions2)
                }
              /><br/>
              <CodeAccordion
                questions={questions3}
                code={code3}
                toggleAccordion={(index) => toggleAccordion(index, questions3, setQuestions3)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions3, setQuestions3)
                }
              /><br/>
              <CodeAccordion
                questions={questions4}
                code={code4}
                toggleAccordion={(index) => toggleAccordion(index, questions4, setQuestions4)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions4, setQuestions4)
                }
              /><br/>
              <CodeAccordion
                questions={questions5}
                code={code5}
                toggleAccordion={(index) => toggleAccordion(index, questions5, setQuestions5)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions5, setQuestions5)
                }
              /><br/>
              <CodeAccordion
                questions={questions6}
                code={code6}
                toggleAccordion={(index) => toggleAccordion(index, questions6, setQuestions6)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions6, setQuestions6)
                }
              /><br/>
              <CodeAccordion
                questions={questions7}
                code={code7}
                toggleAccordion={(index) => toggleAccordion(index, questions7, setQuestions7)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions7, setQuestions7)
                }
              /><br/>
              <CodeAccordion
                questions={questions8}
                code={code8}
                toggleAccordion={(index) => toggleAccordion(index, questions8, setQuestions8)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions8, setQuestions8)
                }
              /><br/>
              
            </div>
            <Link to="/ques21">
              <button
                className="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
              </Link>
                <button
                  className="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
            <br />
          </div>
        </div>
      </Sidebar>
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default Question22;
