import {useEffect} from "react";
import "../../Components/Chapters/Chapters.css";
import Sidebar from "../PythonSideBar";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";

const PythonC1 = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  return (
    <div class="testing">
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
              <Link to="/python/getting_started">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body ui ">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Introduction to Python
              </h2>
              <div class="ui clearing divider"></div>
              <p style={{ backgroundColor: "#FBEEAC", padding: "15px" }}>
                <ol class="ui ordered list">
                  <li>
                    Python was created by a Dutch programmer Guido van Rossum.
                    He began working on the language in the late 1980s, and the
                    first version of Python, version 0.9.0, was released in
                    February 1991. And in 2023, 3.13.0 version of python has
                    been released.
                  </li>

                  <li>Python is object oriented programming language.</li>

                  <li>Python is a high level programming language.</li>
                </ol>
              </p>
              <br />
              <h2
                className="ui left floated header"
                style={{ color: "#001C30" }}
              >
                Applications
              </h2>
              <div class="ui clearing divider"></div>
              <p>
                Python is multi-purpose programming language:
                <br />
                <div className="margin">
                  <ul class="ui list">
                    <li>Machine Learning and Artificial Intelligence.</li>
                    <li>Scripting.</li>
                    <li>Data Analysis and Data Science.</li>
                    <li>Web Development.</li>
                    <li>Game Development.</li>
                    <li>Robotics.</li>
                    <li>Automation.</li>
                  </ul>
                </div><br />
                We will cover all the basic concepts which are necessary to
                learn to enter any domain.
              </p>
              <br />

              <h2
                className="ui left floated header"
                style={{ color: "#001C30" }}
              >
                Why to learn Python?
              </h2>
              <div class="ui clearing divider"></div>
              <p>
                <div className="margin">
                  <ul class="ui list">
                    <li>
                      <b>Easy to learn: </b>
                      <span>
                        Syntax of python is simple and readable. Its syntax
                        resembles English Language. It is an excellent choice
                        for beginners. It is easier to learn and understand as compared to other languages.
                      </span>
                    </li>
                    <li>
                      <b>Versatility: </b>
                      <span>
                        Python is a versatile programming language and can be
                        used for various purposes, such as, web development,
                        data analysis, artificial intelligence, automation,
                        machine learning. To sum up, python is not restricted to
                        a specific domain. It is used in various industries.
                      </span>
                    </li>
                    <li>
                      <b>Libraries and Frameworks: </b>
                      <span>
                        Python has many libraries which makes the development
                        process more easy. We can use Django for Web
                        Development, OpenCV for Computer Vision, and TensorFlow
                        for Machine Learning.
                      </span>
                    </li>
                    <li>
                      <b>Career Opportunities: </b>
                      <span>
                        Python is one of the most in-demand programming
                        languages in the market. There are various jobs related
                        to python are availble in the market, like Machine Learning Engineer, 
                        Data Scientist, Artificial Intelligence Engineer, Game Developer, 
                        Automation Engineer and many more. <br /><br />
                        Python is being used by many companies:
                        <ul>
                          <li>Google</li>
                          <li>NASA</li>
                          <li>Facebook</li>
                          <li>Amazon</li>
                          <li>Netflix</li>
                          <li>Youtube</li>
                          <li>Instagram</li>
                        </ul>
                      </span>
                    </li>
                    <li>
                      <b>Large and Active Community: </b>
                      <span>
                        Python has a massive and active community of developers,
                        which means there are plenty of resources, libraries,
                        and frameworks available. If you run into issues, you're
                        likely to find solutions quickly through online forums,
                        tutorials, and documentation.
                      </span>
                    </li>
                    
                  </ul>
                </div>
              </p>
              <br />

              <h2
                className="ui left floated header"
                style={{ color: "#001C30" }}
              >
                Syntax of Python
              </h2>
              <div class="ui clearing divider"></div>
              <p>
                Syntax of python resembles English Language. Python has the easiest syntax and it is
                 the most in-demand programming language. For example, to print "Hello" on screen, you 
                have to write <b>print ("Hello")</b>. 
              </p>

              <br />

              <p style={{ backgroundColor: "#FBEEAC", padding: "10px" }}>
                This tutorial teachs you the basics of Python.
                <br />
                
                If you have never ever learned a programming language, do not worry. This tutorial is for everyone. 
              </p>
              
            </div><br />
            <button
              class="ui primary button"
              style={{ flex: 1, minWidth: "100px", height: "40px" }}
            >
              Previous
            </button>
            <Link to="/python/getting_started">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
            <br />
          </div>
        </div>
      </Sidebar>

      <Footer />
    </div>
  );
};
export default PythonC1;
