import { useRef, useEffect } from "react";
import "../../Components/Chapters/Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "../PythonSideBar";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

const PythonC9 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };

  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };
  const pc9code1 = `x = 10
y = -5
print(x)
print(type(x))
print(y)
print(type(y))

# Performing mathematical operations on integers
sum = x + y
diff = x - y
mul = x * y
div = x / y

# Printing answers
print("\\nAnswers of mathematical operations")
print(sum)
print(diff)
print(mul)
print(div)`;
  const pc9code2 = `x = 10.1
y = -5.0
z = 1e5      
#scientific number to indicate power of 10.
#1e5 represents 1 x 10 to power 5.
print(x)
print(type(x))
print(y)
print(type(y))
print(z)
print(type(z))

# Performing mathematical operations on integers
sum = x + y
diff = x - y
mul = x * y
div = x / y

# Printing answers
print("\\nAnswers of mathematical operations")
print(sum)
print(diff)
print(mul)
print(div)`;
  const pc9code3 = `cmp1 = 1 + 2j
cmp2 = -(2 + 9j)
cmp3 = 8j
print(cmp1)
print(cmp2)
print(cmp3)

# Performing mathematical operations on integers
sum = cmp1 + cmp2
diff = cmp1 - cmp2
mul = cmp1 * cmp2
div = cmp1 / cmp2

# Printing answers
print("\\nAnswers of mathematical operations")
print(sum)
print(diff)
print(mul)
print(div)`;
  const pc9code4 = `import random           

print(random.randrange(1, 10))`;

  return (
    <div class="testing">
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/python/data_types">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/python/strings">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Numbers:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                Numbers is a fundamental data type to represent numeric values in Python. We have three formats
                of numbers in python.
                <ul class="ui unordered list">
                  <li>int</li>
                  <li>float</li>
                  <li>complex</li>
                </ul>
              </span>
              <br />

              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Integer type:
              </h2>
              <div className="ui clearing divider"></div>
              <span>Integers are positive or negative whole numbers without decimal points. In Python, we can perform various operations on numbers such 
                as addition, subtraction, multiplication and division etc.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>numbers.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc9code1}
                  </SyntaxHighlighter>
                </div>
              </div>

              <br />

              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Float type:
              </h2>
              <div className="ui clearing divider"></div>
              <span>
                Floating point numbers are prositive or negative numbers with decimal points or fractions. It also includes numbers in scientific notation.
                Like integers, we can also perform dfferent types of mathematical operations on floating point numbers.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>numbers.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc9code2}
                  </SyntaxHighlighter>
                </div>
              </div>

              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Complex type:
              </h2>
              <div className="ui clearing divider"></div>
              <span>
                Complex numbers are the numbers with real part and <b>j</b> in imaginary
                part in the form a + bj.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>numbers.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc9code3}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <p style={{ backgroundColor: "#FBEEAC", padding: "15px" }}>
                <b>Note: </b>You can not apply type casting on complex numbers.
              </p>
            </div>
            <br />
            <h2 class="ui left floated header" style={{ color: "#001C30" }}>
              Generating Random Number:
            </h2>
            <div className="ui clearing divider"></div>
            <span>
              We can generate a number in a given range using a function given
              below;
            </span>
            <br />
            <br />
            <div style={containerStyle}>
              <div className="ml-3 mr-3">
                <b style={{ color: "black" }}>numbers.py</b>

                <i
                  class="copy icon"
                  onClick={copyContent}
                  style={{
                    cursor: "pointer",
                    float: "right",
                    fontSize: "20px",
                  }}
                ></i>
              </div>
              <div ref={divRef}>
                <SyntaxHighlighter language="python" style={darcula}>
                  {pc9code4}
                </SyntaxHighlighter>
              </div>
            </div>
            <p style={{ backgroundColor: "#FBEEAC", padding: "15px" }}>
              <b>Note: </b>
              In Python, numbers are a built-in data type used to represent
              numeric values. Mainly, there are three types of numbers in Python.
              <b>Integer type</b> represents positive or negative whole
              numbers without decimal point.
              <b>Floating Point type</b> represents positive or negative
              numbers with decimal point or fractions.
              <b>Complex type</b> represents complex numbers with a real part and an
              imaginary part (in the form a + bj).
            </p>
            <br />

            <Link to="/python/data_types">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/python/strings">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </div>
  );
};
export default PythonC9;
