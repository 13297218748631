import "./Home.css";
import { Link } from "react-router-dom";
import Tutintro from "./Tutintro";
import Quesintro from "./Quesintro";
import Quizintro from "./Quizintro";

const Intro = () => {
  return (
    <section class="text-gray-600 body-font">
      <div class="container px-5 py-4 mx-auto flex flex-wrap items-center">
        <div class="lg:w-3/5 md:w-1/2 md:pr-16 lg:pr-0 pr-0">
          <h1 class="title-font font-medium text-3xl h1">
            Kickstart your programming career with us!
          </h1>
          <br />
          <p>
            Do not know where to start? Check out our coding tutorials now. Do
            practice question exercises to check your knowledge and unleash your
            imagination, learn cutting-edge techniques, and leave your mark on
            the digital landscape with our diverse range of inspiring coding
            projects.
          </p>
          <br />

          <Link to="tutorials">
            <button class="inline-flex text-white bg-indigo-600 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-700 rounded text-lg homepg-btn">
              <div className="btn-content">Tutorials</div>
            </button>
          </Link>
          <Link to="/ques1">
            <button class="ml-0 md:ml-4 inline-flex text-white bg-red-500 border-0 py-2 px-6 focus:outline-none hover:bg-red-600 rounded text-lg homepg-btn">
              <div className="btn-content">Practice Questions</div>
            </button>
          </Link>
        </div>
        <div className="web">
          <div className="row">
            <div className="col-1">
              <img src="images/web1.png" alt="img" />
              <img src="images/bulb.jpg" alt="img" class="element2" />
            </div>
          </div>
        </div>
      </div>

      <section
        class="text-gray-600 body-font"
        style={{ width: "80%", margin: "auto" }}
      >
        <Tutintro />
        <Quesintro />
        <Quizintro />
      </section>
    </section>
  );
};
export default Intro;
