import { useState, useEffect } from "react";
import Sidebar from "./SideBar";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
// import "./Quiz.css";
import "../../Components/Chapters/Chapters.css";
import Modal from "react-modal";

const PQuiz30 = () => {
  const [answers, setAnswers] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const handleQuizSubmit = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const questions = [
    {
        id: 1,
        question: "  What is the primary purpose of file handling in Python:",
        options: ["To perform complex mathematical calculations", "To manipulate strings and characters", "To work with files, including reading, writing, and other operations", "To create graphical user interfaces"],
        correctAnswer: "To work with files, including reading, writing, and other operations",
      },
      {
        id: 2,
        question: "  Before performing any file operations, what is the recommended practice in Python:",
        options: ["Use the input() function to specify the file path", "Open the file using the open() function", "Execute the file using the execute() function", "Use the print() function to read the file"],
        correctAnswer: "Open the file using the open() function",
      },
      {
        id: 3,
        question: "  In file handling, what is the purpose of the \"r\" mode when opening a file:",
        options: ["Read-only mode", "Read-write mode", "Remove mode", "Read and execute mode"],
        correctAnswer: "Read-only mode",
      },
      {
        id: 4,
        question: "  What is the purpose of the \"w\" mode when opening a file:",
        options: ["Write-only mode", "Write and execute mode", "Read and write mode", "Read-only mode"],
        correctAnswer: "Write-only mode",
      },
      {
        id: 5,
        question: "  Which mode in file handling opens the file for writing and appends new data to the end of the file:",
        options: ["\"r\" mode", "\"w\" mode", "\"a\" mode", "\"x\" mode"],
        correctAnswer: "\"a\" mode",
      },
      {
        id: 6,
        question: "  What is the purpose of the \"with\" statement in file handling:",
        options: ["To execute code without opening a file", "To manually close the file after operations", "To ensure that the file is properly closed even if an exception occurs", "To rename the file"],
        correctAnswer: "To ensure that the file is properly closed even if an exception occurs",
      },
      {
        id: 7,
        question: "  Which function from the \"os\" module is used to delete a file in Python:",
        options: ["os.remove()", "os.open()", "os.rename()", "os.mkdir()"],
        correctAnswer: "os.remove()",
      }
  ];
  const handleAnswerChange = (questionId, selectedAnswer) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: selectedAnswer,
    }));
  };

  const calculateScore = () => {
    let correctCount = 0;
    let incorrectCount = 0;

    questions.forEach((question) => {
      const userAnswer = answers[question.id];
      if (userAnswer === question.correctAnswer) {
        correctCount++;
      } else {
        incorrectCount++;
      }
    });

    return { correctCount, incorrectCount };
  };

  const { correctCount, incorrectCount } = calculateScore();
  return (
    <>
      <Sidebar>
        <div class="container">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/quizes/python_quiz29">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                  }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/quizes/python_quiz31">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              </h2>
              <h1 class="start content ui left floated header">
                File Handling
              </h1>
              <div className="ui clearing divider"></div>
            

            <div class="main-body">
              <h2 class="ui left floated header">
                Quiz # 30
              </h2>
              <span class="tut-btn"><Link to="/python/file_handling">
                  <button class="ui secondary button">Read Tutorial</button>
                </Link></span>
              <div class="ui clearing divider"></div>
              <div>
                {questions.map((question, i) => (
                  <div key={question.id}>
                    <div class="con">
                    <div class="que">Q{i+1}:<span class="one-per-left-margin">{question.question}</span></div>
                      {question.options.map((option) => (
                        <label class="label" key={option}>
                          <input
                            type="radio"
                            name={`question_${question.id}`}
                            value={option}
                            checked={answers[question.id] === option}
                            onChange={(e) =>
                              handleAnswerChange(question.id, e.target.value)
                            }
                          />
                          <span className="one-per-left-margin">{option}</span>
                          <br />
                        </label>
                      ))}
                    </div>
                    <hr />
                  </div>
                ))}<br/>
                <center><button class="ui primary button" onClick={handleQuizSubmit}>Submit Quiz</button></center>

                {/* Modal for displaying results */}
                <Modal class="modal" isOpen={isModalOpen} onRequestClose={closeModal}>
                  <b style={{fontSize:'20px'}}>Quiz Results</b>
                  <b><p>Your score is {correctCount}/{questions.length} </p></b>
                  {questions.map((question,i) => (
                    <div key={question.id}>
                      <p>Q{i+1}: {question.question}</p>
                      <p><b>Correct Answer:</b> {question.correctAnswer}</p>
                      <p className={`${question.correctAnswer}` === `${answers[question.id]}` ? "correct" : "wrong"}>
                        <b>Your Answer:</b> {answers[question.id] || "Not answered"}
                      </p>
                      <hr />
                    </div>
                  ))}
                  <button class="ui secondary button" onClick={closeModal}>Close</button><br /><br />
                </Modal>
              </div>

              <br />
            </div>
            <Link to="/quizes/python_quiz29">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                  }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/quizes/python_quiz31">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
            <br />
          </div>
        </div>
      </Sidebar>

      <div class="sideBarBody">
        <Footer />
      </div>
    </>
  );
};
export default PQuiz30;
