import { useRef, useEffect } from "react";
import "./Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Q20 from "../Questions/Q20";
import Sidebar from "../SideBar/Sidebar";
import Footer from "../Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const Ch20 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor:'#D8D9DA'
  };
  const code1 = `class className{
    public:
    //data members
    int weight;
    string name;

    //member functions
    void sayHi(){
        cout<<"Hi"<<endl;
    }
};

int main(){
  //Object initialization
  className objName; 
}`;
  const code2 = `#include<iostream>
using namespace std;

class Vehicles{
	  public:
	  string color;
	  int weight;
	  int num;
	
};
int main(){
	Vehicles supra;  
	
	supra.color = "White";
	supra.weight = 200;
	supra.num = 514;

	//accessing public properties
	cout<<"Color = "<<supra.color<<endl;
	cout<<"Weight = "<<supra.weight<<endl;
	cout<<"Number = "<<supra.num<<endl;
}`;
  const code3 = `#include<iostream>
using namespace std;

class Brothers{
	  public:
	  void sayHello(){
		    cout<<"Hello"<<endl;
	  }
};

int main(){
	  Brothers Bro;
	  Bro.sayHello();
}`;
  const code4 = `#include<iostream>
using namespace std;

class Calculator{
	  public:
	  int a,b;
	  int sum(){
		  return a+b;
	  }
	  int diff(){
		  return a-b;
	  }
	  int mul(){
		  return a*b;
	  }
	  int div(){
		  return a/b;
	  }
};
int main(){
  //declaring object1
	Calculator Calc1;
	
	//initialize data members for Calc1
	Calc1.a = 10;
	Calc1.b = 20;
	
	//Behavior of Object Calc1
	cout<<"Sum = "<<Calc1.sum()<<endl;
	cout<<"Difference = "<<Calc1.diff()<<endl;
	cout<<"Multiplication = "<<Calc1.mul()<<endl;
	cout<<"Division = "<<Calc1.div()<<endl;
	
	
	cout<<endl<<endl;
	
  //declaring object 2
	Calculator Calc2;
	
	//initialize data members for Calc2
	Calc2.a = 20;
	Calc2.b = 30;
	
	//Behavior of Object Calc2
	cout<<"Sum = "<<Calc2.sum()<<endl;
	cout<<"Difference = "<<Calc2.diff()<<endl;
	cout<<"Multiplication = "<<Calc2.mul()<<endl;
	cout<<"Division = "<<Calc2.div()<<endl;
}`;
const code5 = `#include <iostream>
using namespace std;

class Sum{
	  private:
		  int a, b;
	  public:
		  Sum(int a, int b){
			  this->a = a;
			  this->b = b;
		  }
		  int sum();
};

//defining method of class outside the class

Sum :: sum(){
	return a+b;
}
int main(){
	  Sum sum1(10, 20);
	  cout<<sum1.sum();
}`
  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/Cpp/oop">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/Cpp/constructors">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body">
              
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Classes:
              </h2>
              <div class="ui clearing divider"></div>

              
              <p>
                Class is a template / blueprint which stores the
                propeties/attributes and behaviors of objects. <br />
                Class is a user-defined data type. <br /> <br />
                
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
              Objects:
              </h2>
              <div class="ui clearing divider"></div>

                
                Object is an entity which has its properties and behavior. For
                example, car is an object. It has properties like its color,
                weight, number etc. and behavior like it can stop or it can
                accelerate etc.
                <ul className="ui unordered list">
                  <li>
                    <b>Properties of objects: </b>data members declared inside
                    the class.
                  </li>
                  <li>
                    <b>Behavior of objects: </b>members functions declared
                    inside the class.{" "}
                  </li>
                </ul>
                So, properties and behaviors are members of class and declared
                inside the class. <br />
                <br />
                
                Now we will practically implement the concept of classes and
                objects. <br />
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Class Layout:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                Class can have data members or members functions. They can be public, private 
                or protcted which we will discuss later. Keep in mind that public members can be accessed 
                everywhere.
              </span>
                
                  <div ref={divRef}>
                    <SyntaxHighlighter language="cpp" style={darcula}>
                      {code1}
                    </SyntaxHighlighter>
                  </div>
                <br />
                <p style={{ backgroundColor: "#FBEEAC", padding: "15px" }}>
                  <b>Note: </b>
                  <br /> Do not forget to put semi-colon at the end. <br />
                  Have you noticed that the syntax of class is almost same as
                  that of struct.
                </p>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Exploring a simple basic example:
              </h2>
              <div class="ui clearing divider"></div>
    
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>class.cpp</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="cpp" style={darcula}>
                      {code2}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Member Functions:
              </h2>
              <div class="ui clearing divider"></div>
                
                Members functions are defined inside the class and they show the
                behavior of objects. They are also called <b>methods.</b><br />
                If I say, "My brother can say hello". This is the behavior of my
                brother. <br /><br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>class.cpp</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="cpp" style={darcula}>
                      {code3}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                Now we will make objects which can do addition, subtraction,
                multiplication and division. <br /><br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>class.cpp</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="cpp" style={darcula}>
                      {code4}
                    </SyntaxHighlighter>
                  </div>
                </div><br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Scope Resolution Operator:
              </h2>
              <div class="ui clearing divider"></div>
                If we want to keep the code inside the class clean, we can declare functions inside class and define them 
                outside the class using scope resolution operator(::). <br />
<br />                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>class.cpp</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="cpp" style={darcula}>
                      {code5}
                    </SyntaxHighlighter>
                  </div>
                </div><br />
                <br />
                <p style={{ backgroundColor: "#FBEEAC", padding: "15px" }}>
                  Have you noticed? the behavior of objects changes as data
                  members changes. If data members of two objects are same,
                  their behavior is same.
                </p>
                <br />
                <b>Local Variables: </b><br />
                Local Variables are declared and initialized inside the methods, constructors or blocks. They will be destroyed 
                when method has completed. <br />
                <b>Instance Variables: </b><br />
                Instance variables are defined within a class but outside methods. They are initialized when we instantiate the class. <br />
                <b>Class Variables: </b><br />
                These are declared within a class outside methods with static keyword. <br />
              </p>
             
              
            </div><br />
            <Link to="/Cpp/oop">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/Cpp/constructors">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default Ch20;
