import React, { useState } from "react";
import {
  FaTh,
  FaBars,
  FaUserAlt,
  FaRegChartBar,
  FaCommentAlt,
  FaShoppingBag,
  FaThList,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import "../../Components/SideBar/App.css";

const Sidebar = ({ children }) => {
  const [clicked, setclicked] = useState(false);
  const [isOpenn, setisOpenn] = useState(true);
  const toggle = () => setisOpenn(!isOpenn);
  const menuItem = [
    {
      path: "/ques1",
      name: "Printing Output",
    },
    {
      path: "/ques2",
      name: "Variables",
    },
    {
      path: "/ques3",
      name: "Taking Input",
    },
    {
      path: "/ques4",
      name: "Operators",
    },
    {
      path: "/ques5",
      name: "Strings",
    },
    {
      path: "/ques6",
      name: "Conditional Statements",
    },
    {
      path: "/ques7",
      name: "For Loop",
    },
    {
      path: "/ques8",
      name: "While Loop",
    },
    {
      path: "/ques9",
      name: "Do While Loop",
    },
    {
      path: "/ques10",
      name: "For Each Loop",
    },
    {
      path: "/ques11",
      name: "Arrays",
    },
    {
      path: "/ques12",
      name: "Structures and Unions",
    },
    {
      path: "/ques13",
      name: "Functions / Methods",
    },
    {
      path: "/ques14",
      name: "Pointers",
    },
    {
      path: "/ques15",
      name: "List",
    },
    {
      path: "/ques16",
      name: "Tuple",
    },
    {
      path: "/ques17",
      name: "Set",
    },
    {
      path: "/ques18",
      name: "Dictionary",
    },
    {
      path: "/ques19",
      name: "Classes and Objects",
    },
    {
      path: "/ques20",
      name: "Inheritance",
    },
    {
      path: "/ques21",
      name: "Polymorphism",
    },
    {
      path: "/ques22",
      name: "Encapsulation",
    },
  ];
  const handleClick = (e) =>{
    e.preventDefault()
    setisOpenn(!isOpenn)
  }
  const handleClicked = () =>{
    setisOpenn(!isOpenn)
  }
  return (
    <div className={`${isOpenn === true ? "flex" : "sidebar_"}`}>
      <input type="checkbox" id="click" />
      <label htmlFor="click" className="menu-btn" style={{ marginTop: "20%" }}>
        <i
          onClick={handleClick}
          className="fas fa-bars"
          style={{ marginTop: "20%" }}
        ></i>
      </label>
      <div
        style={{ display: "block", width: "200px" }}
        className={`${isOpenn === true ? "sidebar" : "nosidebar"}`}
      >
        <div className="top_section">
          <h1 style={{ display: "block" }} className="logo">
            <span style={{ color: "black", fontSize: "22px" }}>Questions</span>
          </h1>
        </div>
        {menuItem.map((item, index) => (
          <ul>
            <li>
              <Link
                to={item.path}
                key={index}
                className="link"
                activeclassName="active"
              >
                {/* here */}
                <div
                  onClick={handleClicked}
                  style={{
                    display: isOpenn ? "block" : "block",
                    color: "black",
                    fontSize: "14px",
                  }}
                  className="link_text"
                >
                  {item.name}
                </div>
              </Link>
            </li>
          </ul>
        ))}
      </div>
      <div className={`${isOpenn === true ? "" : "closemain"}`}>
        <main>{children}</main>
      </div>
    </div>
  );
};

export default Sidebar;
