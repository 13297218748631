import { useRef, useEffect } from "react";
import "../../Components/Chapters/Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "../PythonSideBar";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

const PythonC14 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };

  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };
  const pc14code1 = `cars = {"Mercedes", "Gazoo", "Mustang"}
print(cars)`;
  const pc14code2 = `fruits = {"Apple", "Banana", "Mango"}
print(fruits)`;
  const pc14code3 = `cars = {"Mercedes", "Gazoo", "Mustang"}
cars.add("Volvo")
print(cars)

#adding set in set
fruits = {"Mango", "Apple", "Banana"}
cars.update(fruits)
print(cars)

#adding set in list
dryFruits = ["Apricot", "Cashew", "Almonds"]
fruits.update(dryFruits)
print(fruits)`;

  const pc14code3_ = `cars = {"Mercedes", "Gazoo", "Mustang"}

#removing item 
cars.remove("Mercedes")    
#if item does not exist, error will occur
print(cars)

#using discard method
cars.discard("Mercedes")   
#if item does not exist, no error will occur
print(cars)
`;

  const pc14code4 = `cars = {"Mercedes", "Mustang", "Mustang"}    
print(cars)`;
  const pc14code5 = `data = {1,"John", 3.5}
print(data)`;
  const pc14codel = `cars = {"Mercedes", "Gazoo", "Mustang"}
print(len(cars))`;
  const pc14code6 = `fruits = {"apple", "banana", "cherry"}

for x in fruits:
  print(x)

new_fruits = list(fruits)
index = 0
while index < len(new_fruits):
    item = new_fruits[index]
    print(item)
    index += 1`;
  const pc14code7 = `fruits = {"apple", "banana", "cherry"}

#using "in"
print("apple" in fruits)

#using "not in"
print("apricot" in fruits)`;
  const pc14code8 = `fruits = {"Apple", "Mango", "Banana"}
dryFruits = {"Apricots", "Almonds", "Cashew"}

#joining two sets using union
union = fruits.union(dryFruits)
print(union)

#joining two sets 
fruits.update(dryFruits)
print(fruits)`;
  const pc14code9 = `animals = {"Lion", "Zebra", "Monkey"}
fav_animals = {"Lion", "Cheetah", "Panther"}

animals.intersection_update(fav_animals)
print(animals)`;
  const pc14code10 = `cars = {"Mustang", "Ferrari", "Volvo"}
fav_cars = {"Gazoo", "Audi", "Mustang"}

car = cars.intersection(fav_cars)
print(car)`;
  const pc14code11 = `cars = {"Mustang", "Ferrari", "Volvo"}
fav_cars = {"Gazoo", "Audi", "Mustang"}

cars.symmetric_difference_update(fav_cars)
print(cars)`;
  const pc14code12 = `cars = {"Mustang", "Ferrari", "Volvo"}
fav_cars = {"Gazoo", "Audi", "Mustang"}

unique_cars = cars.symmetric_difference(fav_cars)
print(unique_cars)
`;
  const pc14code13 = `nums = {1, 2, 3, 4}
nums.remove(3)  
#if the item does not exist, 
#error will occur
print(nums) `;
  const pc14code14 = `nums = {1, 2, 3, 4}
nums.discard(3)  
#if the item does not exist, 
#error will not occur
print(nums) `;
  const pc14code15 = `nums = {1, 2, 3, 4, 5}

#Removing and returning arbitrary element
removed_element = nums.pop() 

print(removed_element)  

print(nums)`;
  const pc14code16 = `my_set = {1, 2, 3, 4, 5}
my_set.clear()   
print(my_set)`;
  const pc14code17 = `cars = {"Ferrari", "Mustang", "Rolls Royce"}
copied_cars = cars.copy()

print(copied_cars)`;
  const pc14code18 = `set1 = {1, 2, 3, 4, 5}
set2 = {3, 4, 5, 6, 7}

difference = set1.difference(set2)
print(difference)  

difference = set2.difference(set1)
print(difference)`;
  const pc14code19 = `set1 = {1, 2, 3, 4, 5}
set2 = {3, 4, 5, 6, 7}

set1.difference_update(set2)
print(set1)`;
  const pc14code20 = `set1 = {1, 2, 3}
set2 = {4, 5, 6}

result = set1.isdisjoint(set2)
print(result)

set3 = {3, 4, 5}

result = set1.isdisjoint(set3)
print(result)`;
  const pc14code21 = `set1 = {1, 2}
set2 = {1, 2, 3, 4}

result = set1.issubset(set2)
print(result)

result = set2.issubset(set1)
print(result)`;
  const pc14code22 = `set1 = {1, 2, 3, 4}
set2 = {1, 2}

result = set1.issuperset(set2)
print(result)

result = set2.issuperset(set1)
print(result)`;
  const pc14code23 = `set1 = {1, 2, 3, 4}
set2 = {3, 4, 5, 6}

sym_diff = set1.sym_difference(set2)
print(sym_diff)`;
  const pc14code24 = `set1 = {1, 2, 3, 4}
set2 = {3, 4, 5, 6}

set1.symmetric_difference_update(set2)
print(set1)`;
  const pc14code25 = `set1 = {1, 2, 3}
set2 = {3, 4, 5}

unionSet = set1.union(set2)
print(unionSet)`;
  const pc14code26 = `set1 = {1, 2, 3}
set2 = {3, 4, 5}

set1.update(set2)
print(set1)`;

  return (
    <div class="testing">
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/python/tuples">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/python/dictionaries">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Sets:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                Sets are unordered collection of data items that store multiple
                items in a single variable.
                <br />
                <ul className="ui unordered list">
                  <li>
                    <b>Unchangeable</b> - we can not change items of a set.
                  </li>
                  <li>
                    <b>Unordered</b> - items in a set do not have a definite order.
                  </li>
                  <li>
                    <b>No index</b> - set items do not have indexes.
                  </li>
                  <li>
                    <b>Unique</b> - items of a set are unique.
                  </li>
                </ul>
              </span>
              <br />

              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>input.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc14code1}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <span>
                Whatever input you give will be stored in variable "name" and we
                can use this variable when we have to print the name. <br />
                We can also perform some mathematical operation on our input. By
                default our input has string data type and for doing
                mathematical operations, we have to convert it into number. We
                will discus this in string section.
              </span>

              <br />
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Unordered and unindexed:
              </h2>
              <div className="ui clearing divider"></div>
              <span>
                Items in a set are unordered and do not have specified index.
                When you compile your code multiple times, order of the items may
                change. Items are not at the same index every time.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>set.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc14code2}
                  </SyntaxHighlighter>
                </div>
              </div>

              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Unchangeable:
              </h2>
              <div className="ui clearing divider"></div>
              <span>
                Items in a set are unchangeable. Once we create a set, we can
                not update its values. However we can add or remove (can not change) items from
                a set. We can check add and remove methods in method section.
              </span>
              <br />

              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Duplicates:
              </h2>
              <div className="ui clearing divider"></div>
              <span>
                Duplicate items are not allowed in a set. If you add duplicate
                items in a set, duplicates will be discarded.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>set.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc14code4}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Length of Set:
              </h2>
              <div className="ui clearing divider"></div>
              <span>
                We can calculate the length of sets using length method. Length
                of a set is equal to the number of unique items in a set. The
                duplicate items are counted once.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>set.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc14codel}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Data types of items of Sets:
              </h2>
              <div className="ui clearing divider"></div>
              <span>
                Data type of items of a set may or may not be same. Sets can
                store data with items having different data types.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>set.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc14code5}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />

              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Accessing items in a Set:
              </h2>
              <div className="ui clearing divider"></div>
              <span>
                We can not access items in list using indexes. We have to use
                loop to access items in a set.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>set.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc14code6}
                  </SyntaxHighlighter>
                </div>
              </div>

              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Using "in" and "not in" to check presence of items:
              </h2>
              <div className="ui clearing divider"></div>
              <span>
                We can not access items in list using indexes. We have to use
                loop to access items in a set.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>set.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc14code7}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />

              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Joining two sets:
              </h2>
              <div className="ui clearing divider"></div>
              <span>
                We can join two sets using union and duplicate methods. If the two
                sets have repeated items, they will exclude the duplicate items.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b style={{ color: "black" }}>set.py</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="python" style={darcula}>
                    {pc14code8}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />

              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Set Methods:
              </h2>
              <div className="ui clearing divider"></div>
              <table class="ui very padded table">
                <thead>
                  <tr>
                    <th>Operator</th>
                    <th>Example</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>.add()</td>
                    <td>
                      <div style={containerStyle}>
                        <div className="ml-3 mr-3">
                          <b style={{ color: "black" }}>set.py</b>

                          <i
                            class="copy icon"
                            onClick={copyContent}
                            style={{
                              cursor: "pointer",
                              float: "right",
                              fontSize: "20px",
                            }}
                          ></i>
                        </div>
                        <div ref={divRef}>
                          <SyntaxHighlighter language="python" style={darcula}>
                            {pc14code3}
                          </SyntaxHighlighter>
                        </div>
                      </div>
                    </td>
                    <td>adds a single element to a set.</td>
                  </tr>
                  <tr>
                    <td>.clear()</td>
                    <td>
                      <div style={containerStyle}>
                        <div className="ml-3 mr-3">
                          <b style={{ color: "black" }}>set.py</b>

                          <i
                            class="copy icon"
                            onClick={copyContent}
                            style={{
                              cursor: "pointer",
                              float: "right",
                              fontSize: "20px",
                            }}
                          ></i>
                        </div>
                        <div ref={divRef}>
                          <SyntaxHighlighter language="python" style={darcula}>
                            {pc14code16}
                          </SyntaxHighlighter>
                        </div>
                      </div>
                    </td>
                    <td>removes all the elements from a set.</td>
                  </tr>
                  <tr>
                    <td>.copy()</td>
                    <td>
                      <div style={containerStyle}>
                        <div className="ml-3 mr-3">
                          <b style={{ color: "black" }}>set.py</b>

                          <i
                            class="copy icon"
                            onClick={copyContent}
                            style={{
                              cursor: "pointer",
                              float: "right",
                              fontSize: "20px",
                            }}
                          ></i>
                        </div>
                        <div ref={divRef}>
                          <SyntaxHighlighter language="python" style={darcula}>
                            {pc14code17}
                          </SyntaxHighlighter>
                        </div>
                      </div>
                    </td>
                    <td>creates shallow copy of orignal set.</td>
                  </tr>
                  <tr>
                    <td>.difference()</td>
                    <td>
                      <div style={containerStyle}>
                        <div className="ml-3 mr-3">
                          <b style={{ color: "black" }}>set.py</b>

                          <i
                            class="copy icon"
                            onClick={copyContent}
                            style={{
                              cursor: "pointer",
                              float: "right",
                              fontSize: "20px",
                            }}
                          ></i>
                        </div>
                        <div ref={divRef}>
                          <SyntaxHighlighter language="python" style={darcula}>
                            {pc14code18}
                          </SyntaxHighlighter>
                        </div>
                      </div>
                    </td>
                    <td>finds the difference between two sets.</td>
                  </tr>
                  <tr>
                    <td>.difference_update()</td>
                    <td>
                      <div style={containerStyle}>
                        <div className="ml-3 mr-3">
                          <b style={{ color: "black" }}>set.py</b>

                          <i
                            class="copy icon"
                            onClick={copyContent}
                            style={{
                              cursor: "pointer",
                              float: "right",
                              fontSize: "20px",
                            }}
                          ></i>
                        </div>
                        <div ref={divRef}>
                          <SyntaxHighlighter language="python" style={darcula}>
                            {pc14code19}
                          </SyntaxHighlighter>
                        </div>
                      </div>
                    </td>
                    <td>
                      updates the calling set by removing elements that are also
                      present in one or more specified sets.
                    </td>
                  </tr>
                  <tr>
                    <td>.discard()</td>
                    <td>
                      <div style={containerStyle}>
                        <div className="ml-3 mr-3">
                          <b style={{ color: "black" }}>set.py</b>

                          <i
                            class="copy icon"
                            onClick={copyContent}
                            style={{
                              cursor: "pointer",
                              float: "right",
                              fontSize: "20px",
                            }}
                          ></i>
                        </div>
                        <div ref={divRef}>
                          <SyntaxHighlighter language="python" style={darcula}>
                            {pc14code14}
                          </SyntaxHighlighter>
                        </div>
                      </div>
                    </td>
                    <td>
                      removes a specified element from the set if it exists.
                    </td>
                  </tr>
                  <tr>
                    <td>.intersection()</td>
                    <td>
                      <div style={containerStyle}>
                        <div className="ml-3 mr-3">
                          <b style={{ color: "black" }}>set.py</b>

                          <i
                            class="copy icon"
                            onClick={copyContent}
                            style={{
                              cursor: "pointer",
                              float: "right",
                              fontSize: "20px",
                            }}
                          ></i>
                        </div>
                        <div ref={divRef}>
                          <SyntaxHighlighter language="python" style={darcula}>
                            {pc14code10}
                          </SyntaxHighlighter>
                        </div>
                      </div>
                    </td>
                    <td>finds the intersection of sets (two or more).</td>
                  </tr>
                  <tr>
                    <td>.intersection_update()</td>
                    <td>
                      <div style={containerStyle}>
                        <div className="ml-3 mr-3">
                          <b style={{ color: "black" }}>set.py</b>

                          <i
                            class="copy icon"
                            onClick={copyContent}
                            style={{
                              cursor: "pointer",
                              float: "right",
                              fontSize: "20px",
                            }}
                          ></i>
                        </div>
                        <div ref={divRef}>
                          <SyntaxHighlighter language="python" style={darcula}>
                            {pc14code9}
                          </SyntaxHighlighter>
                        </div>
                      </div>
                    </td>
                    <td>
                      updates the calling set by keeping only the elements that
                      are also present in one or more specified sets.
                    </td>
                  </tr>
                  <tr>
                    <td>.isdisjoint()</td>
                    <td>
                      <div style={containerStyle}>
                        <div className="ml-3 mr-3">
                          <b style={{ color: "black" }}>set.py</b>

                          <i
                            class="copy icon"
                            onClick={copyContent}
                            style={{
                              cursor: "pointer",
                              float: "right",
                              fontSize: "20px",
                            }}
                          ></i>
                        </div>
                        <div ref={divRef}>
                          <SyntaxHighlighter language="python" style={darcula}>
                            {pc14code20}
                          </SyntaxHighlighter>
                        </div>
                      </div>
                    </td>
                    <td>checks if two sets have no common elements or not.</td>
                  </tr>
                  <tr>
                    <td>.issubset()</td>
                    <td>
                      <div style={containerStyle}>
                        <div className="ml-3 mr-3">
                          <b style={{ color: "black" }}>set.py</b>

                          <i
                            class="copy icon"
                            onClick={copyContent}
                            style={{
                              cursor: "pointer",
                              float: "right",
                              fontSize: "20px",
                            }}
                          ></i>
                        </div>
                        <div ref={divRef}>
                          <SyntaxHighlighter language="python" style={darcula}>
                            {pc14code21}
                          </SyntaxHighlighter>
                        </div>
                      </div>
                    </td>
                    <td>checks whether a set is a subset of another set or not.</td>
                  </tr>
                  <tr>
                    <td>.issuperset()</td>
                    <td>
                      <div style={containerStyle}>
                        <div className="ml-3 mr-3">
                          <b style={{ color: "black" }}>set.py</b>

                          <i
                            class="copy icon"
                            onClick={copyContent}
                            style={{
                              cursor: "pointer",
                              float: "right",
                              fontSize: "20px",
                            }}
                          ></i>
                        </div>
                        <div ref={divRef}>
                          <SyntaxHighlighter language="python" style={darcula}>
                            {pc14code22}
                          </SyntaxHighlighter>
                        </div>
                      </div>
                    </td>
                    <td>
                      checks whether one set is a superset of another set.
                    </td>
                  </tr>
                  <tr>
                    <td>.pop()</td>
                    <td>
                      <div style={containerStyle}>
                        <div className="ml-3 mr-3">
                          <b style={{ color: "black" }}>set.py</b>

                          <i
                            class="copy icon"
                            onClick={copyContent}
                            style={{
                              cursor: "pointer",
                              float: "right",
                              fontSize: "20px",
                            }}
                          ></i>
                        </div>
                        <div ref={divRef}>
                          <SyntaxHighlighter language="python" style={darcula}>
                            {pc14code15}
                          </SyntaxHighlighter>
                        </div>
                      </div>
                    </td>
                    <td>
                      removes and return an arbitrary (random) element from the
                      set.
                    </td>
                  </tr>
                  <tr>
                    <td>.remove()</td>
                    <td>
                      <div style={containerStyle}>
                        <div className="ml-3 mr-3">
                          <b style={{ color: "black" }}>set.py</b>

                          <i
                            class="copy icon"
                            onClick={copyContent}
                            style={{
                              cursor: "pointer",
                              float: "right",
                              fontSize: "20px",
                            }}
                          ></i>
                        </div>
                        <div ref={divRef}>
                          <SyntaxHighlighter language="python" style={darcula}>
                            {pc14code13}
                          </SyntaxHighlighter>
                        </div>
                      </div>
                    </td>
                    <td>removes a specific element from the set.</td>
                  </tr>
                  <tr>
                    <td>.symmetric_difference()</td>
                    <td>
                      <div style={containerStyle}>
                        <div className="ml-3 mr-3">
                          <b style={{ color: "black" }}>set.py</b>

                          <i
                            class="copy icon"
                            onClick={copyContent}
                            style={{
                              cursor: "pointer",
                              float: "right",
                              fontSize: "20px",
                            }}
                          ></i>
                        </div>
                        <div ref={divRef}>
                          <SyntaxHighlighter language="python" style={darcula}>
                            {pc14code23}
                          </SyntaxHighlighter>
                        </div>
                      </div>
                    </td>
                    <td>
                      finds the symmetric difference between two sets and
                      returns a new set.
                    </td>
                  </tr>
                  <tr>
                    <td>.symmetric_difference_update()</td>
                    <td>
                      <div style={containerStyle}>
                        <div className="ml-3 mr-3">
                          <b style={{ color: "black" }}>set.py</b>

                          <i
                            class="copy icon"
                            onClick={copyContent}
                            style={{
                              cursor: "pointer",
                              float: "right",
                              fontSize: "20px",
                            }}
                          ></i>
                        </div>
                        <div ref={divRef}>
                          <SyntaxHighlighter language="python" style={darcula}>
                            {pc14code24}
                          </SyntaxHighlighter>
                        </div>
                      </div>
                    </td>
                    <td>
                      updates the calling set with the symmetric difference
                      between the calling set and another set.
                    </td>
                  </tr>
                  <tr>
                    <td>.union()</td>
                    <td>
                      <div style={containerStyle}>
                        <div className="ml-3 mr-3">
                          <b style={{ color: "black" }}>set.py</b>

                          <i
                            class="copy icon"
                            onClick={copyContent}
                            style={{
                              cursor: "pointer",
                              float: "right",
                              fontSize: "20px",
                            }}
                          ></i>
                        </div>
                        <div ref={divRef}>
                          <SyntaxHighlighter language="python" style={darcula}>
                            {pc14code25}
                          </SyntaxHighlighter>
                        </div>
                      </div>
                    </td>
                    <td>finds the union of two or more sets</td>
                  </tr>
                  <tr>
                    <td>.update()</td>
                    <td>
                      <div style={containerStyle}>
                        <div className="ml-3 mr-3">
                          <b style={{ color: "black" }}>set.py</b>

                          <i
                            class="copy icon"
                            onClick={copyContent}
                            style={{
                              cursor: "pointer",
                              float: "right",
                              fontSize: "20px",
                            }}
                          ></i>
                        </div>
                        <div ref={divRef}>
                          <SyntaxHighlighter language="python" style={darcula}>
                            {pc14code26}
                          </SyntaxHighlighter>
                        </div>
                      </div>
                    </td>
                    <td>
                      updates the calling set by adding elements from another
                      set or an iterable (like a list, tuple, or another set).
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <br />
            <Link to="/python/tuples">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/python/dictionaries">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </div>
  );
};
export default PythonC14;
