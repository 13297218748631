import { useState, useEffect } from "react";
import Sidebar from "./SideBar";
import Footer from "../Home/Footer";
import { Link } from "react-router-dom";
import "./Quiz.css";
import "../Chapters/Chapters.css";
import Modal from "react-modal";

const Quiz31 = () => {
  const [answers, setAnswers] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const handleQuizSubmit = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const questions = [
    {
      id: 1,
      question:
        "  Which header file is required to work with file handling in C++:",
      options: ["<iostream>", "<fstream>", "<string>", "<iomanip>"],
      correctAnswer: "<fstream>",
    },
    {
      id: 2,
      question:
        "  What does the ifstream class from the fstream header file allow you to do:",
      options: [
        "Write data to a file",
        "Read data from a file",
        "Append data to a file",
        "Create a new file",
      ],
      correctAnswer: "Read data from a file",
    },
    {
      id: 3,
      question: "  Which function is used to open a file for writing in C++:",
      options: ["open()", "write()", "append()", "read()"],
      correctAnswer: "open()",
    },
    {
      id: 4,
      question:
        "  How do you append data to an existing file using ofstream with the append function:",
      options: [
        "Create a new file",
        "Use the ios::app flag while opening the file",
        "Close the file before appending",
        "Use the ios::ate flag while opening the file",
      ],
      correctAnswer: "Use the ios::app flag while opening the file",
    },
    {
      id: 5,
      question:
        "  What is the purpose of the following code: outputFile.close(); in file handling:",
      options: [
        "It opens the file for writing",
        "It appends data to the file",
        "It closes the file after writing data",
        "It reads data from the file",
      ],
      correctAnswer: "It closes the file after writing data",
    },
    {
      id: 6,
      question:
        "  In the given code, where is the input saved when the user enters data in the console:",
      options: [
        'It\'s saved in the "output.txt" file',
        'It\'s saved in the "input.txt" file',
        "It's not saved in any file",
        'It\'s saved in the "inp.txt" file',
      ],
      correctAnswer: 'It\'s saved in the "inp.txt" file',
    },
    {
      id: 7,
      question:
        '  What does the following line of code do: "while (std::getline(inputFile, line))":',
      options: [
        "Appends a line to the file",
        "Reads a line from the file",
        "Opens a file for reading",
        "Closes the file after reading",
      ],
      correctAnswer: "Reads a line from the file",
    },
  ];
  const handleAnswerChange = (questionId, selectedAnswer) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: selectedAnswer,
    }));
  };

  const calculateScore = () => {
    let correctCount = 0;
    let incorrectCount = 0;

    questions.forEach((question) => {
      const userAnswer = answers[question.id];
      if (userAnswer === question.correctAnswer) {
        correctCount++;
      } else {
        incorrectCount++;
      }
    });

    return { correctCount, incorrectCount };
  };

  const { correctCount, incorrectCount } = calculateScore();
  return (
    <>
      <Sidebar>
        <div class="container">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/quizes/cpp_quiz30">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                  }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/quizes/cpp_quiz32">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
            </h2>
            <h1 class="start content ui left floated header">File Handling</h1>
            <div className="ui clearing divider"></div>

            <div class="main-body ">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Quiz # 31
              </h2>
              <span class="tut-btn">
                <Link to="/tutorials/Chapter2">
                  <button class="ui secondary button">Read Tutorial</button>
                </Link>
              </span>
              <div class="ui clearing divider"></div>
              <div>
                {questions.map((question, i) => (
                  <div key={question.id}>
                    <div class="con">
                      <div class="que">
                        Q{i + 1}:
                        <span class="one-per-left-margin">
                          {question.question}
                        </span>
                      </div>
                      {question.options.map((option) => (
                        <label class="label" key={option}>
                          <input
                            type="radio"
                            name={`question_${question.id}`}
                            value={option}
                            checked={answers[question.id] === option}
                            onChange={(e) =>
                              handleAnswerChange(question.id, e.target.value)
                            }
                          />
                          <span className="one-per-left-margin">{option}</span>
                          <br />
                        </label>
                      ))}
                    </div>
                    <hr />
                  </div>
                ))}
                <br />
                <center>
                  <button class="ui primary button" onClick={handleQuizSubmit}>
                    Submit Quiz
                  </button>
                </center>

                {/* Modal for displaying results */}
                <Modal
                  class="modal"
                  isOpen={isModalOpen}
                  onRequestClose={closeModal}
                >
                  <b style={{ fontSize: "20px" }}>Quiz Results</b>
                  <b>
                    <p>
                      Your score is {correctCount}/{questions.length}{" "}
                    </p>
                  </b>
                  {questions.map((question, i) => (
                    <div key={question.id}>
                      <p>
                        Q{i + 1}: {question.question}
                      </p>
                      <p>
                        <b>Correct Answer:</b> {question.correctAnswer}
                      </p>
                      <p
                        className={
                          `${question.correctAnswer}` ===
                          `${answers[question.id]}`
                            ? "correct"
                            : "wrong"
                        }
                      >
                        <b>Your Answer:</b>{" "}
                        {answers[question.id] || "Not answered"}
                      </p>
                      <hr />
                    </div>
                  ))}
                  <button class="ui secondary button" onClick={closeModal}>
                    Close
                  </button>
                  <br />
                  <br />
                </Modal>
              </div>

              <br />
            </div>
            <Link to="/quizes/cpp_quiz30">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                  }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/quizes/cpp_quiz32">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
            <br />
          </div>
        </div>
      </Sidebar>

      <div class="sideBarBody">
        <Footer />
      </div>
    </>
  );
};
export default Quiz31;
