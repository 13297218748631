import { useRef, useEffect } from "react";
// import "./Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Sidebar from "../CSideBar";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const CC9 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };
  const code00 = `int x = 10;
float y = x;`;
  const code0 = `int x;
y = float(x);`;
  const code1 = `#include <stdio.h>

int main() {
  float num1 = 10.3;
  float num2 = 3.1;
  // The result is a float, convert it to int
  int sum = (int) 10 + 3;

  printf("%d", sum);
  return 0;
}`;
  const code2 = `#include <stdio.h>

int main() {
  int num1 = 10;
  int num2 = atoi ("3");
  int sum = num1 + num2;

  printf("%d", sum);
  return 0;
}`;

  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/C/data_types">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/C/strings">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
            </h2>
            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Type Casting in C:
              </h2>
              <div class="ui clearing divider"></div>
              Type casting allows you to change the data type of a variable to
              other data type. <br />
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Types:
              </h2>
              <div class="ui clearing divider"></div>
              <ul className="ui unordered list">
                <li>Implicit Type Casting</li>
                <li>Explicit Type Casting</li>
              </ul>
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Implicit Type Casting:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                In case of implicit type casting, we do not need a type casting
                function to do type casting like in case of explicit type
                casting. It occurs automatically by the programming language
                without any explicit instruction by the programmer.
              </span>
              <br />
              <SyntaxHighlighter language="c" style={darcula}>
                {code00}
              </SyntaxHighlighter>
              <br />
              <p style={{ backgroundColor: "#FBEEAC", padding: "10px" }}>
                <b>Note: </b>Implicit type casting is also called Automatic Type
                Conversion.
              </p>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Explicit Type Casting:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                In case of explicit type casting, programmer has to specify the
                conversion from one data type to another explicitly. We perform
                explicit type casting using different type casting functions
              </span>
              <SyntaxHighlighter language="c" style={darcula}>
                {code0}
              </SyntaxHighlighter>
              <br />
              <p style={{ backgroundColor: "#FBEEAC", padding: "10px" }}>
                <b>Note: </b>Explicit type casting is also called Manual Type
                Conversion.
              </p>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                More Examples of Explicit Type Casting:
              </h2>
              <div class="ui clearing divider"></div>
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>typecasting.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code1}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <span>
                When we store data in variables, they are stored in memory. We
                can perform different types of computations or operations on
                variables. For this purpose, the variables must have compatible
                data types. This is the reason why we do type casting. In type
                casting, we assign the value of one data type to another. <br />
                <br />
              </span>
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>typecasting.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code2}
                  </SyntaxHighlighter>
                </div>
              </div>
            </div>
            <br />
            <h2 class="ui left floated header" style={{ color: "#001C30" }}>
              Different Type Casting Functions in C:
            </h2>
            <div class="ui clearing divider"></div>
            <span>
              In C language, there are five built-in type casting functions
              which are given below: <br />
              <ul className="ui unordered list">
                <li>
                  <b>atoi(): </b>It converts string data tyoe to int data type.
                </li>
                <li>
                  <b>atbol(): </b>It converts string data type into long data type.
                </li>
                <li>
                  <b>ltoa(): </b>It converts long data type to string data type.
                </li>
                <li>
                  <b>itoba(): </b>It converts int data type to sring data type.
                </li>
                <li>
                  <b>atof(): </b>It converts string data type to float data type.
                </li>
              </ul>
            </span>
            <br />
            <Link to="/C/data_types">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/C/strings">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default CC9;
