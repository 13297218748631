import { useRef, useEffect } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Sidebar from "../CSideBar";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const CC23 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };
  const code1 = `FILE* filePointer;`;
  const code2 = `filePointer = fopen("filename.txt", "mode");`;
  const code3 = `#include <stdio.h>

int main() {
  // Declare a file pointer
  FILE *file;
  
  // Open the file for reading
  file = fopen("example.txt", "r");
  
  // Check if the file opened successfully
  if (file == NULL) {
    perror("Error opening file");
    return 1; 
  }
  
  // Reading and printing the contents of the file
  char character;
  while ((character = fgetc(file)) != EOF) {
    putchar(character);
  }
  
  // Close the file
  fclose(file);
  
  return 0; 
}`;

  const code4 = `#include <stdio.h>

int main() {
  // Declare a file pointer
  FILE *file;
  
  // Open the file for writing
  file = fopen("output.txt", "w");
  
  // Check if the file opened successfully
  if (file == NULL) {
    perror("Error opening file");
    return 1; 
  }
  
  // Write data to the file
  const char *data = "Hello, World!\\n";
  fputs(data, file);
  
  // Close the file
  fclose(file);
  
  return 0; 
}`;
  const code5 = `#include <stdio.h>

int main() {
  // Declare a file pointer
  FILE *file;

  // Open the file for appending
  file = fopen("output.txt", "a");

  // Check if the file opened successfully
  if (file == NULL) {
    perror("Error opening file");
    return 1; 
  }

  // Write data to the file
  const char *data = "Appended.\\n";
  fputs(data, file);

  // Close the file
  fclose(file);

  return 0; 
}`;
  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/C/struct">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              {/* <Link to="/C/Chapter14b"> */}
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
              {/* </Link> */}
              <br />
            </h2>

            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                File Handling:
              </h2>
              <div class="ui clearing divider"></div>
              <p>
                In C, file handling allows us to work with files on our computer
                system. We can use functions to perform various operations on
                files in C, such as:
                <br />
                <ul className="ui unordered list">
                  <li>Open a file</li>
                  <li>Reading from files</li>
                  <li>Writing to files</li>
                  <li>Creating new files</li>
                </ul>
              </p>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                File Pointers:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                To interact with files, we need file pointers in C. We have to
                declare a file pointer to interact with a file.
              </span>
              <br />
              <br />
              <div ref={divRef}>
                <SyntaxHighlighter language="c" style={darcula}>
                  {code1}
                </SyntaxHighlighter>
              </div>

              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Opening a File:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                We can use "fopen()" function to open a file for reading and
                writing. It returns a pointer to the targeted file.
              </span>

              <div ref={divRef}>
                <SyntaxHighlighter language="c" style={darcula}>
                  {code2}
                </SyntaxHighlighter>
              </div>

              <br />
              <h1>In this example : </h1>
              <ul className="ui unordered list">
                <li>"filename.txt" is the name of the file.</li>
                <li>
                  "mode" specifies the mode in which we open the file; <br />
                  <ol className="ui ordered list">
                    <li> "r" for reading.</li>
                    <li> "w" for writing.</li>
                    <li> "a" for appending.</li>
                  </ol>
                </li>
              </ul>

              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Reading from a File:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                Reading a file in C can be done using the standard input/output
                library functions. "fscanf()" or "fgets()" functions are used to
                read data from a file.
              </span>
              <br />
              <br />

              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>file.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code3}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h1>In this example :</h1>
              <ul className="ui unordered list">
                <li>
                  "stdio.h" library is used to access file related functions.
                </li>
                <li>
                  We declare a pointer of FILE type to interact with the file.
                </li>
                <li>
                  Now, we have opened the file using "fopen()" function in read
                  mode.
                </li>
                <li>
                  We use a while loop to read the file character by character
                  using fgetc. The loop will continue until the end of the file
                  (EOF) is reached.
                </li>
                <li>
                  We printed each character in while loop using "putchar".
                </li>
                <li>
                  At the end, we close the file using "fclose()" function.
                </li>
              </ul>

              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Writing to a File:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                To write data to a file, you can use functions like fprintf() or
                fputs().
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>file.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code4}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h1>In this example : </h1>
              <br />
              <span>
                <ul className="ui unordered list">
                  <li>
                    "stdio.h" is used to access the file related operations.
                  </li>
                  <li>We declare FILE type pointer to interact with files.</li>
                  <li>We opened the file using "fopen()" in write mode.</li>
                  <li>Now we write data in file using "fputs()" function.</li>
                  <li>Closing the file using "fclose()" function.</li>
                </ul>
              </span>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Appending to a File:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                Appending to a file is similar to writing to a file but we open
                file in append mode. In append mode, we add data to the end of
                the file, without overwriting the existing content.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>file.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code5}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h1>In this example : </h1>
              <ul className="ui unordered list">
                <li>stdio.h is used to access the file-related functions.</li>
                <li>We declare a FILE type pointer to interact with files.</li>
                <li>We open a file using "fopen()" function.</li>
                <li>We write data to the file using "fputs()" function.</li>
                <li>Finslly, we close the file using "fclose()" function.</li>
              </ul>

              <p style={{ backgroundColor: "#FBEEAC", padding: "10px" }}>
                <b>Note : </b>File handling in C allows seamless interaction
                with files on your computer's file system. The fundamental
                operations include creating new files, opening existing files,
                reading from files, and writing to files.
              </p>
            </div>
            <br />
            <Link to="/C/struct">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            {/* <Link to="/C/Chapter14b"> */}
            <button
              class="ui primary button"
              style={{
                flex: 1,
                minWidth: "100px",
                height: "40px",
                float: "right",
              }}
            >
              <span> Next</span>
            </button>
            {/* </Link> */}
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default CC23;
