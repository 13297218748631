import { useRef, useEffect } from "react";
import "./Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Sidebar from "../SideBar/Sidebar";
import Footer from "../Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const Ch5 = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  const divRef = useRef(null);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };
  const code1 = `#include <iostream>
using namespace std;  

int main() {
    //I am printing hello world
    cout << "Hello World!";
    return 0;
}
  `;
  const code2 = `#include <iostream>
using namespace std;

int main() {
    /*I am printing hello world
    This is a multi-lined comment*/
    cout << "Hello World!";
    return 0;
}
  `;

  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/Cpp/output">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/Cpp/variables">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Comments:
              </h2>
              <div class="ui clearing divider"></div>
              <p>
                Comments are used to explain your code and they have nothing to
                do with the compiler. Compiler ignores the comments and do not
                consider them as the part of your code. They just make the code
                readable. The comments do not affect the output of our code.<br />
              </p>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Types:
              </h2>
              <div class="ui clearing divider"></div>
              
              <span>There are two types of comments:
                <ul class="ui unordered list">
                  <li>Single Lined Coments</li>
                  <li>Multi Lined Comments</li>
                </ul>
              </span>
              <br />

              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Single Lined Comment:
              </h2>
              <div class="ui clearing divider"></div>

              <span>
                If we want to comment a single line in our C++ code, we use single
                lined comments. To add a single line comment put two forward
                slashes (//) before that line.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>singlelinedcmt.cpp</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="cpp" style={darcula}>
                    {code1}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Multi Lined Comment:
              </h2>
              <div class="ui clearing divider"></div>

              <span>
                If we want to comment multiple lines in our C++ code, we use multi
                lined comments. To add a multi line comment put /* at the start
                and */ at the end.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>multilinedcmt.cpp</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="cpp" style={darcula}>
                    {code2}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Why do we use Comments:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                <ul className="ui unordered list">
                  <li>
                    <b>Documentation: </b>The comments can be used for the
                    purpose of documentation. They can explain the purpose,
                    usage and functioanlity of your code.
                  </li>

                  <li>
                    <b>Divide Code into sections: </b>If your code has different
                    sections and each section is performing a duty, then add
                    comments above each section. This makes your code readable.
                  </li>
                  <li>
                    <b>Temporary Code Removal: </b>If you do not want some lines
                    of code to compile then comment those lines. The commented
                    lines will be ignored by the compiler. If you want the lines
                    back, uncomment them and now you can compile these lines.
                  </li>
                </ul>
              </span>
              <br />

              <p style={{ backgroundColor: "#FBEEAC", padding: "10px" }}>
                <ul class="ui unordered list">
                  <li>
                    If you do not want some lines of your code to execute, put
                    them in comments.
                  </li>
                  <li>
                    To comment a line, click on that line to place cursor on
                    that line and then press "Ctrl + /".
                  </li>
                  <li>
                    Comments are just used to increase the readability of your
                    code.
                  </li>
                </ul>
              </p>
            </div>
            <br />
            <Link to="/Cpp/output">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/Cpp/variables">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default Ch5;
