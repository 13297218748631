import { useRef, useEffect } from "react";
import "../../Components/Chapters/Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Sidebar from "../JavaSideBar";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const JavaC5 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };

  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor:'#D8D9DA'
  };
  const jc5code1 = `public class Main {
    public static void main(String[] args) {
        // I am printing Hello World
        System.out.println("Hello World");
    }
}`;
const jc5code2 =  `public class Main {
    public static void main(String[] args) {
        /* I am printing Hello World
        and this is a comment */
        System.out.println("Hello World");
    }
}`
  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/java/output">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/java/variables">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body ">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Comments:
              </h2>
              <div class="ui clearing divider"></div>
              <p style={{ backgroundColor: "", padding: "" }}>
                Comments are used to explain your code and they have nothing to
                do with the compiler. Compiler ignores your comment and do not
                consider them the part of your code. They just make your code
                more readable.
                <br /><br />
                There are two <b>types</b> of comments:
                <div className="margin">
                  <ul class="ui list">
                    <li>Single Lined Comments</li>
                    <li>Multi Lined Comments</li>
                  </ul>
                </div>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Single Lined Comments:
                </h2>
                <div class="ui clearing divider"></div>
                <span>Put two forward slashes before a line.</span><br /><br />
                <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>comment.java</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="java" style={darcula}>
                    {jc5code1}
                  </SyntaxHighlighter>
                </div>
              </div>


              <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Multi Lined Comments:
                </h2>
                <div class="ui clearing divider"></div>
                <span>Put /* at the start and */ at the end.</span><br /><br />
                <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>comment.java</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="java" style={darcula}>
                    {jc5code2}
                  </SyntaxHighlighter>
                </div>
              </div><br />
              <p style={{ backgroundColor: "#FBEEAC", padding: "10px" }}>
                <b>Note: </b>
                <br />
                <ul class="ui unordered list">
                  <li>
                    If you do not want some lines of your code to execute, put
                    them in comments.
                  </li>
                  <li>
                    To comment a line, click on that line to place cursor on
                    that line and then press "Ctrl + /".
                  </li>
                  <li>
                    Comments are just used to increase the readability of your
                    code.
                  </li>
                </ul>
              </p>
              </p>

              
              
            </div>
            <br />
            <Link to="/java/output">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/java/variables">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default JavaC5;
