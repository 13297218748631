import { useState, useEffect } from "react";
import Sidebar from "./SideBar";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
// import "./Quiz.css";
import "../../Components/Chapters/Chapters.css";
import Modal from "react-modal";

const JQuiz27 = () => {
  const [answers, setAnswers] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const handleQuizSubmit = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const questions = [
    {
      id: 1,
      question: "  What is the primary goal of abstraction in Java:",
      options: ["To reveal complex implementation details to developers", "To provide concrete classes with complete implementations", "To hide complex details and reveal only necessary information", "To create abstract methods with no implementations"],
      correctAnswer: "To hide complex details and reveal only necessary information",
    },
    {
      id: 2,
      question: "  In the context of abstraction, what is the key difference between an abstract class and an interface in Java:",
      options: ["Abstract classes can have both abstract and concrete methods, while interfaces can only have abstract methods", "Abstract classes can be instantiated, but interfaces cannot", "Abstract classes can extend other classes, while interfaces cannot", "Interfaces can have fields and constructors, while abstract classes cannot"],
      correctAnswer: "Abstract classes can have both abstract and concrete methods, while interfaces can only have abstract methods",
    },
    {
      id: 3,
      question: "  Which keyword is used to declare an abstract class in Java:",
      options: [
        "abstract",
        "class",
        "interface",
        "extends",
      ],
      correctAnswer: "abstract",
    },
    {
      id: 4,
      question: "  Which of the following Java constructs are used to implement abstraction:",
      options: ["Arrays and Strings", "Abstract classes and interfaces", "Conditional statements and loops", "Static methods and variables"],
      correctAnswer: "Abstract classes and interfaces",
    },
    {
        id: 5,
        question: "  Which of the following is NOT a benefit of abstraction in Java:",
        options: ["Code reusability", "Modularity", "Encapsulation", "Increased code complexity"],
        correctAnswer: "Increased code complexity",
      }
  ];
  const handleAnswerChange = (questionId, selectedAnswer) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: selectedAnswer,
    }));
  };

  const calculateScore = () => {
    let correctCount = 0;
    let incorrectCount = 0;

    questions.forEach((question) => {
      const userAnswer = answers[question.id];
      if (userAnswer === question.correctAnswer) {
        correctCount++;
      } else {
        incorrectCount++;
      }
    });

    return { correctCount, incorrectCount };
  };

  const { correctCount, incorrectCount } = calculateScore();
  return (
    <>
      <Sidebar>
        <div class="container">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/quizes/java_quiz26">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                  }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/quizes/java_quiz28">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              </h2>
              <h1 class="start content ui left floated header">
                Abstraction
              </h1>
              <div className="ui clearing divider"></div>
            

            <div class="main-body">
              <h2 class="ui left floated header">
                Quiz # 27
              </h2>
              <span class="tut-btn"><Link to="/java/abstraction">
                  <button class="ui secondary button">Read Tutorial</button>
                </Link></span>
              <div class="ui clearing divider"></div>
              <div>
                {questions.map((question, i) => (
                  <div key={question.id}>
                    <div class="con">
                    <div class="que">Q{i+1}:<span class="one-per-left-margin">{question.question}</span></div>
                      {question.options.map((option) => (
                        <label class="label" key={option}>
                          <input
                            type="radio"
                            name={`question_${question.id}`}
                            value={option}
                            checked={answers[question.id] === option}
                            onChange={(e) =>
                              handleAnswerChange(question.id, e.target.value)
                            }
                          />
                          <span className="one-per-left-margin">{option}</span>
                          <br />
                        </label>
                      ))}
                    </div>
                    <hr />
                  </div>
                ))}<br/>
                <center><button class="ui primary button" onClick={handleQuizSubmit}>Submit Quiz</button></center>

                {/* Modal for displaying results */}
                <Modal class="modal" isOpen={isModalOpen} onRequestClose={closeModal}>
                  <b style={{fontSize:'20px'}}>Quiz Results</b>
                  <b><p>Your score is {correctCount}/{questions.length} </p></b>
                  {questions.map((question,i) => (
                    <div key={question.id}>
                      <p>Q{i+1}: {question.question}</p>
                      <p><b>Correct Answer:</b> {question.correctAnswer}</p>
                      <p className={`${question.correctAnswer}` === `${answers[question.id]}` ? "correct" : "wrong"}>
                        <b>Your Answer:</b> {answers[question.id] || "Not answered"}
                      </p>
                      <hr />
                    </div>
                  ))}
                  <button class="ui secondary button" onClick={closeModal}>Close</button><br /><br />
                </Modal>
              </div>

              <br />
            </div>
            <Link to="/quizes/java_quiz26">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                  }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/quizes/java_quiz28">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
            <br />
          </div>
        </div>
      </Sidebar>

      <div class="sideBarBody">
        <Footer />
      </div>
    </>
  );
};
export default JQuiz27;
