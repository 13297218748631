import { useRef, useEffect } from "react";
import "./Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Q14 from "../Questions/Q14";
import Sidebar from "../SideBar/Sidebar";
import Footer from "../Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const Ch14 = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  const divRef = useRef(null);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };
  const code1 = `//initialize variable
while(check condition){
    //code
    //change value of variable
}`;
  const code2 = `#include <iostream>
using namespace std;

int main(){
    int a = 10;
    while(i>0){
        cout<<"My name is John"<<endl;
        a--;
    }
    return 0;
}`;
  const code3 = `#include <iostream>
using namespace std;

int main(){
    int i = 0;
    do{
        cout<<i<<endl;
        i++;
    }
    while(i < 5);
    return 0;
}`;
  const code4 = `#include <iostream>
using namespace std;

int main(){
    int a = 5;
    do{
        cout<<"My name is John\\n";
        a--;
    }while(a>0);
    return 0;
}`;

  const code5 = `#include <iostream>
using namespace std;

int main(){
    int i = 0;
    do{
        cout<<"I am 20 years old."<<endl;
        i++;  //if you do not increment value, infinite loop will run and never be terminated.
    }
    while(i<10);
    return 0;
}`;
  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/Cpp/for_loop">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/Cpp/continue_break">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                While Loop:
              </h2>
              <div class="ui clearing divider"></div>
              <p>
                Loop executes the same block of code again and again until the
                given condition remains true. We use while loop when we do not
                know the number of iterations. We know the condition and variable value and 
                in some cases variable value is given by the user.
              </p><br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Steps:
              </h2>
              <div class="ui clearing divider"></div>
              <ol className="ui ordered list">
                <li>Initialize a variable.</li>
                <li>Check the condition.</li>
                <li>Change the value of the variable.</li>
              </ol>

              <SyntaxHighlighter language="cpp" style={darcula}>
                {code1}
              </SyntaxHighlighter>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Example:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                The following code is using while loop to print name ten times.
              </span>
<br /><br />


              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>while.cpp</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="cpp" style={darcula}>
                    {code2}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <span>This will print name 10 times.</span>
              <br />
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Program to print couting from 1 to 5:
              </h2>
              <div class="ui clearing divider"></div>

              <span>
                In the following code, we will print the numbers from one to five times.
              </span><br /><br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>while.cpp</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="cpp" style={darcula}>
                    {code3}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <p style={{ backgroundColor: "#FBEEAC", padding: "15px" }}>
                <b>Note : </b> If we write true in parenthesis, the while loop
                will become infinite. You can use ctrl + c on your keyboard to
                stop an infinite loop.{" "}
              </p>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Do-While Loop:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                The is one major difference between 
                while and do while loop. In do while loop, first iteration is done without checking the
                condition. After first iteration, condition is checked and if it
                is true second iteration is done and this continues until the
                condition is true.
              </span>{" "}
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>do-while.cpp</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="cpp" style={darcula}>
                    {code4}
                  </SyntaxHighlighter>
                </div>
              </div>
            </div>
            
            <br />
              <span>
                This code will execute one time, no matter the condition is
                true or false. But after the first iteration, it will check the condition.
                If the condition is true, the loop will run. If the given
                condition is false, the loop will be terminated and the control
                will move out of the loop. You must make sure that after each iteration 
                variable value must be changed.
              </span><br /><br />
            <Link to="/Cpp/for_loop">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/Cpp/continue_break">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default Ch14;
