import { useRef, useEffect } from "react";
import "../../Components/Chapters/Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Sidebar from "../JavaSideBar";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const JavaC21 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };

  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };
  const jc21code1 = `public void exampleMethod() {
    int x = 10; // x is in block scope
    {
        int y = 20; // y is in block scope
    }
    // x is still accessible here, but y is not
}`;

  const jc21code2 = `public void exampleMethod(int a) {
    int b = 20; // b has method scope
    // Both a and b are accessible here
}`;
  const jc21code3 = `public class MyClass {
    int instanceVar = 30; 
}`;
  const jc21code4 = `public class MyClass {
    static int staticVar = 40; 
}`;
  const jc21code5 = `public class GlobalScopeExample {
    public static int globalVar = 50; 
}`;

  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/java/methods">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/java/oop">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body ">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Variable Scope in Java:
              </h2>
              <div class="ui clearing divider"></div>
              <p style={{ backgroundColor: "", padding: "" }}>
                Scope refers to the region or context within which a variable is
                accessible.
                <br />
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Block Scope:
                </h2>
                <div class="ui clearing divider"></div>
                <span>
                  <ul className="ui unordered list">
                    <li>
                      Block scope is the area within a pair of curly
                      braces {}.
                    </li>
                    <li>
                      Variables declared inside a block are accessible
                      within that block only.
                    </li>
                    <li>
                      Block scope is often associated with local variables.
                    </li>
                  </ul>
                </span>
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>scope.java</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="java" style={darcula}>
                      {jc21code1}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Method Scope:
                </h2>
                <div class="ui clearing divider"></div>
                <span>
                  <ul className="ui unordered list">
                    <li>
                      Variables declared as method parameters or local variables
                      inside a method have method scope.
                    </li>
                    <li>
                      They are only accessible within the method where they are
                      declared.
                    </li>
                  </ul>
                </span>
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>scope.java</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="java" style={darcula}>
                      {jc21code2}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Class Scope (Instance Variables):
                </h2>
                <div class="ui clearing divider"></div>
                <span>
                  <ul className="ui unordered list">
                    <li>
                      Instance variables (fields) have class
                      scope.
                    </li>
                    <li>
                      They are declared within a class but outside of any
                      method.
                    </li>
                    <li>
                      Instance variables are accessible throughout the class and
                      have the same lifetime as the class's object.
                    </li>
                  </ul>
                </span>
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>scope.java</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="java" style={darcula}>
                      {jc21code3}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Class Scope (Static Variables):
                </h2>
                <div class="ui clearing divider"></div>
                <span>
                  <ul className="ui unordered list">
                    <li>
                      Static variables are shared among all instances of a class
                      and also have class scope.
                    </li>
                    <li>They are declared as static within a class.</li>
                    <li>
                      Static variables exist for the entire lifetime of the
                      program.
                    </li>
                  </ul>
                </span>
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>scope.java</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="java" style={darcula}>
                      {jc21code4}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Global Scope:
                </h2>
                <div class="ui clearing divider"></div>
                <span>
                  <ul className="ui unordered list">
                    <li>
                      Java doesn't have true global variables (variables
                      accessible everywhere in the program).
                    </li>
                    <li>
                      The closest thing to a global scope in Java is a public
                      static variable within a class.
                    </li>
                  </ul>
                </span>
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>scope.java</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="java" style={darcula}>
                      {jc21code5}
                    </SyntaxHighlighter>
                  </div>
                </div>
              </p>
            </div>
            <p style={{ backgroundColor: "#FBEEAC", padding: "15px" }}>
              <b>Note : </b>Scope defines the region or context within which a variable is accessible. Understanding the different scopes is crucial for writing clean and efficient code.
              Java does not have true global variables accessible everywhere in the program.
            </p>

            <br />
            <Link to="/java/methods">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/java/oop">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default JavaC21;
