import { useRef, useEffect } from "react";
import "../../Components/Chapters/Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Sidebar from "../JavaSideBar";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const JavaC11 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };

  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };
  const jc11code1 = `public class Main {
      public static void main(String[] args) {
          
        String name = "John";
  
        System.out.println(name);
      }
  }`;
  const jc11code2 = `public class Main {
    public static void main(String[] args) {
    
        String fname = "John";
        String lname = "Smith";
  
        System.out.println(fname + lname);

    }
}`;
  const jc11code3 = `String text = "Java Programming";
int length = text.length();`;
  const jc11code4 = `String word = "Java";
char firstChar = word.charAt(0); 
char secondChar = word.charAt(1);`;
  const jc11code5 = `String str1 = "hello";
String str2 = "Hello";
boolean isEqual = str1.equals(str2); `;
  const jc11code6 = `String str1 = "hello";
String str2 = "Hello";
boolean ans = str1.equalsIgnoreCase(str2);`;
  const jc11code7 = `String text = "  Java is fun!  ";
String trimmedText = text.trim();`;
  const jc11code8 = `String text = "  Java is fun!  ";
String replacedText = text.replace("Java", "Go");`;
  const jc11code9 = `String text = "  Java is fun!  ";
String[] words = text.split(" ");`;
  const jc11code10 = `String str1 = "Hello";
String str2 = "World";
String result = str1.concat(" ").concat(str2);`;
  const jc11code11 = `String text = "Hello, World!";
String sub2 = text.substring(0, 5);`;
  const jc11code12 = `String text = "Hello, World!";
String upperCaseText = text.toUpperCase();`;
  const jc11code13 = `String text = "Hello, World!";
String lowerCaseText = text.toLowerCase();`;

  return (
    <>
      <Sidebar>
        <div class="containera" style={{ marginLeft: "0%" }}>
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/java/type_casting">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/java/operators">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body ">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Strings in Java:
              </h2>
              <div class="ui clearing divider"></div>
              <p style={{ backgroundColor: "", padding: "" }}>
                Strings are used to store textual data. <br /> The text to be
                stored in a string variable is enclosed in double quotation
                marks.
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>string.java</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="cpp" style={darcula}>
                      {jc11code1}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Strings Concatenation:
                </h2>
                <div class="ui clearing divider"></div>
                <span>
                  String concatenation is the process of joing two strings with
                  "+" operator or by using concat() method.
                </span>
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>string.java</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="cpp" style={darcula}>
                      {jc11code2}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  String Methods:
                </h2>
                <div class="ui clearing divider"></div>
                <table class="ui very padded table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Example</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <b>length()</b>
                      </td>
                      <td>
                        <SyntaxHighlighter
                          language="cpp"
                          style={{ backgroundColor: "#fff" }}
                        >
                          {jc11code3}
                        </SyntaxHighlighter>
                      </td>
                      <td>finds the length of string</td>
                    </tr>

                    <tr>
                      <td>
                        <b>indexing()</b>
                      </td>
                      <td>
                        <SyntaxHighlighter
                          language="cpp"
                          style={{ backgroundColor: "#fff" }}
                        >
                          {jc11code4}
                        </SyntaxHighlighter>
                      </td>
                      <td>accesses characters of string by index.</td>
                    </tr>

                    <tr>
                      <td>
                        <b>equals()</b>
                      </td>
                      <td>
                        <SyntaxHighlighter
                          language="cpp"
                          style={{ backgroundColor: "#fff" }}
                        >
                          {jc11code5}
                        </SyntaxHighlighter>
                      </td>
                      <td>compares strings for equality.</td>
                    </tr>
                    <tr>
                      <td>
                        <b>equalsIgnoreCase()</b>
                      </td>
                      <td>
                        <SyntaxHighlighter
                          language="cpp"
                          style={{ backgroundColor: "#fff" }}
                        >
                          {jc11code6}
                        </SyntaxHighlighter>
                      </td>
                      <td>for case-insensitive comparison.</td>
                    </tr>
                    <tr>
                      <td>
                        <b>trim()</b>
                      </td>
                      <td>
                        <SyntaxHighlighter
                          language="cpp"
                          style={{ backgroundColor: "#fff" }}
                        >
                          {jc11code7}
                        </SyntaxHighlighter>
                      </td>
                      <td>removes leading and trailing spaces.</td>
                    </tr>
                    <tr>
                      <td>
                        <b>replace()</b>
                      </td>
                      <td>
                        <SyntaxHighlighter
                          language="cpp"
                          style={{ backgroundColor: "#fff" }}
                        >
                          {jc11code8}
                        </SyntaxHighlighter>
                      </td>
                      <td>replaces one text with other.</td>
                    </tr>
                    <tr>
                      <td>
                        <b>split()</b>
                      </td>
                      <td>
                        <SyntaxHighlighter
                          language="cpp"
                          style={{ backgroundColor: "#fff" }}
                        >
                          {jc11code9}
                        </SyntaxHighlighter>
                      </td>
                      <td>splits the string into an array of words</td>
                    </tr>
                    <tr>
                      <td>
                        <b>concat()</b>
                      </td>
                      <td>
                        <SyntaxHighlighter
                          language="cpp"
                          style={{ backgroundColor: "#fff" }}
                        >
                          {jc11code10}
                        </SyntaxHighlighter>
                      </td>
                      <td>joins two or more strings.</td>
                    </tr>
                    <tr>
                      <td>
                        <b>substring()</b>
                      </td>
                      <td>
                        <SyntaxHighlighter
                          language="cpp"
                          style={{ backgroundColor: "#fff" }}
                        >
                          {jc11code11}
                        </SyntaxHighlighter>
                      </td>
                      <td>extracts portion of a strings.</td>
                    </tr>
                    <tr>
                      <td>
                        <b>toUpperCase()</b>
                      </td>
                      <td>
                        <SyntaxHighlighter
                          language="cpp"
                          style={{ backgroundColor: "#fff" }}
                        >
                          {jc11code12}
                        </SyntaxHighlighter>
                      </td>
                      <td>converts string to upper case.</td>
                    </tr>
                    <tr>
                      <td>
                        <b>toUpperCase()</b>
                      </td>
                      <td>
                        <SyntaxHighlighter
                          language="cpp"
                          style={{ backgroundColor: "#fff" }}
                        >
                          {jc11code13}
                        </SyntaxHighlighter>
                      </td>
                      <td>converts string to upper case.</td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <span>
                  There are different escape characters in Java, which are used
                  alonwith strings. They are listed in printing output section.
                </span>
              </p>
              <br />
              <p style={{ backgroundColor: "#FBEEAC", padding: "15px" }}>
                <b>Note: </b>
                In Java, a string is defined as an object of the String class.
                The String class is part of the Java Standard Library and
                provides a convenient way to work with sequences of characters.
              </p>
            </div>
            <br />
            <Link to="/java/type_casting">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/java/operators">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default JavaC11;
