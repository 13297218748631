import { useRef, useEffect } from "react";
import Footer from "../../Components/Footer";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./style.css";

const CatGlance = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    padding: "20px",
    borderRadius: "3px",
    marginLeft: "0%",
  };
  const code1 = `#include <stdio.h>

int main() {
  //code goes here
  return 0;
}`;
  const code2 = `printf("Hello World!)`;
  const code3 = `scanf("formatSpecifier", &varName)
//for int 
scanf("%d", &varName)
//for float 
scanf("%f", &varName)
//for string 
scanf("%s", &varName)
//for single character 
scanf("%c", &varName)`     
  const code4 = `//this is a single lined comment.

/*this is a 
multi-lined comment*/`;
  const code5 = `int sum = 10;`;
  const code6 = `int sum = 10; //stores integer
float average = 90.33; //stores numbers with decimal
double percentage = 80.3452; //stores numbers with decimal but precision is double as compared to float
char letter = 'A'; //stores single character
bool isTrue = true; //stores true or false
string intro = "My name is Sajeel"; //stores sequence of characters
void myFunc();//it represent the absence of data type. Mostly used with functions and pointers`;
  const code7 = `//declaring string
char fname[] = "John";
char lname[] = "Doe";

//concatenating strings
char name[100];
strcpy(name, fname);
strcat(name, lname);

//finding length of string
#include <string.h>
size_t length = strlen(myString);
printf("Length: %zu\\n", length);

//accessing characters of string
printf("%c\\n", myString[0]);

//Changing characters
myString[0] = 'A'; // Change the first character to 'A'
printf("%c\\n", myString[0]);

//taking string input ignoring spaces
char myString[100]; 
scanf("%s", myString)`;
  const code8 = `Arithmetic Operators
+ //addition
- //subtraction
* //multiplication
/ //division
% //modulus
++ //increment
-- //decrement
Assignment Operators
= //assign value
+= //add and assign
-= //subtract and assign
/= //divide and assign
*= //multiply and assign
%= //taking modulus and assign
Comparison Operators
== //checks equality
!= //checks unequality
> //checks greater than
>= //checks greater than or equal to
< //check less than
<= //checks less than or equal to
Logical Operators
&& //returns true if all are expressions true
|| //returns true if only one expression is true
! //returns true if false and vice versa
Bitwise Operators
& //bitwise AND
| //bitwise OR
~ //bitwise NOT
>> //shift right 
<< //shift left
`;
  const code9 = `//if statement
if(condition){
    //code
}

//if-else statement
if(condition){
    //code
}
else{
    //code
}

//else-if statement
if(condition){
    //code
}
else if(condition){
    //code
}
else{
    //code
}

//goto statement
int main(){
  //code
  label:
  // code
  goto label;
  // code
}

//Ternary Operator
variable = condition ? ifTrue : ifFalse;
`;
  const code10 = `switch(expression){
    case const-exp:
        //code
        break;
    case const-exp:
        //code
        break;
    .
    .
    .
    default:
        //code
        break;
}`;

  const code11 = `//for Loop:
for( initialization ; condition ; change-variable-value ){
    //code
}

//while Loop:
initialize variable
while(condition){
    //code
    //change-variable-value
}

//do-while Loop:
initialize variable
do{
    //code
    //change-variable-value
}while(condition);`;

  const code12 = `//initializing arrays
int arrayName[size] = {data1, data2,.....}
//printing array
for(int i=0 ; i < ( sizeof(array)/ sizeof(int) ) ; i++){
    printf("%d\\n", array[i]);
}

//taking input in array
for(int i=0 ; i < ( sizeof(array)/ sizeof(int) ) ; i++){
    printf("Enter data at %d index ", i+1);
    scanf("%d",&array[i]);
}

//changing array elements at specified index
int myArray [3] = {1, 2, 3};
myArray[0] = 5; //now array has 5 at first index
`;
  const code13 = `//initializing struct
struct struct_name{
    //data members and member functions
};


//initializing union
union union_name{
    //data members 
};`;
  const code14 = `int var = 10;
//initialization of pointer
int *ptr = &var;
// &var is the reference to variable named var`;

  const code15 = `//declaring a function
returnType functionName(dataType parameters){
    //code
}

//calling a function
functionName(arguments);

//recursion
returnType functionName(dataType parameters){
    //code
    functionName(arguments);
    //code
}

//Function Overloading
int sum(int a, int b){
  return a+b;
}
float sum(float a, float b, int c){
  return a+b;
}
int main(){
  printf("%d", sum(5, 10));  
  printf("%f", sum(5.0, 10.0, 20));  
}`;
  const code16 = `//break statement
for( initialization ; condition ; change-variable-value ){
  if(condition){
    break;  //moves out of the loop when condition is true
  }
}

//continue statement
for( initialization ; condition ; change-variable-value ){
  if(condition){
    continue;  //skip the iteration when condition is true
  }
}`;
  const code17 = `//escape sequences
\\n            //adds a new line
\\t             //adds four spaces
\\b            //backspace
\\a            //beep sound
\\f             //form feed
\\r            //carriage return
\\\\           //adds backslash
\\'            //adds single quote
\\?           //adds question mark
\\0          //null character`;

  const code18 = `// Input and Output functions
printf()    //used for formatted output
scanf()    //used for formatted input
getchar()    //used for character input
putchar()    //used for character output
fgets()    //used for reading strings
fputs()    //used for writing stings

//string functions
strlen(string);
strcmp(string1 , string2 );
strncmp (string1, string2, n);
strcpy(string1, string2);
strncpy(string1, srting2, n);
strcat(string1,string2);
strncat(string1, string2, n);
strlwr(string_name);
strupr(string_name);
strrev(string_name);
char *strchr(const char *str, int character);
char *strrchr(const char *str, int character);

//math functions
pow(x , y);
sqrt(x);
floor(x);
ceil(x);
round(x);
fmod(x, y);
cos(x);
sin(x);
tan(x);
log(x);
log10(x);

//time and date functions
#include <stdio.h>
#include <time.h>

int main() {
    time_t start_time, end_time;
    time(&start_time);

    // Perform some task or wait

    time(&end_time);

    double difference = difftime(end_time, start_time);

    printf("Time elapsed: %.2f seconds\\n", difference);

    return 0;
}
`;
  
  const code27 = `try {
  // here goes the code and if there is an error throw exception
  throw exception; 
}
catch () {
  // Block of code to handle errors
}`;


const code29 = `//file pointer
FILE* filePointer

//Opening files
FILE* file = fopen("example.txt", "w");    //opened in write mode

//reading from files
int character = fgetc(file); // Read a character
char buffer[100];
fgets(buffer, sizeof(buffer), file); // Read a line
fscanf(file, "%d", &some_variable); // Read using fscanf

//writing to files
fputc('A', file); // Write a character
fputs("Hello, World!", file); // Write a string
fprintf(file, "Formatted data: %d", some_variable); // Write formatted data

//closing files
fclose(file);
`
const code30 = `// Allocate memory for an integer
int* intPtr = (int*)malloc(sizeof(int));

// Allocate memory for an array of integers
int* intArray = (int*)calloc(10, sizeof(int));

//deallocate memory
free(intPtr);
free(intArray);

//reallocate memory using realloc
int* resizedArray = (int*)realloc(intArray, 20 * sizeof(int));
if (resizedArray != NULL) {
    intArray = resizedArray; // Update the pointer
}
`
  return (
    <>
      
      <br />
      <br />
      <br />
      <br />

      <div>
        <div
          class=""
          style={{
            width: "80%",
            marginLeft: "10%",
            marginBottom: "5%"
          }}
        >
          <h2 class="ui left floated header">C at Glance</h2>
          <div class="ui clearing divider"></div>

          <p>
            <span class="h1">Basic Syntax</span>
            <div class="ui clearing divider"></div>
            <div>Basic syntax for a C code: </div>

            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="c" style={darcula}>
                  {code1}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

          <p>
            <span class="h1">printf</span>
            <div class="ui clearing divider"></div>
            <div>
              printf() is used to print on console.{" "}
            </div>
            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="c" style={darcula}>
                  {code2}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

          <p>
            <span class="h1">scanf</span>
            <div class="ui clearing divider"></div>
            <div>
              scanf() is used to take input from
              user.{" "}
            </div>
            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="c" style={darcula}>
                  {code3}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

          <p>
            <span class="h1">Comments</span>
            <div class="ui clearing divider"></div>
            <div>
              Comments are used to make your code more readable. Everything in
              comments are ignored by the compiler.
            </div>
            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="c" style={darcula}>
                  {code4}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

          <p>
            <span class="h1">Escape Sequences</span>
            <div class="ui clearing divider"></div>
            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="c" style={darcula}>
                  {code17}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

          <p>
            <span class="h1">Variables</span>
            <div class="ui clearing divider"></div>
            <div>Variables are used to store data of specified data type.</div>
            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="c" style={darcula}>
                  {code5}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

          <p>
            <span class="h1">Data Types</span>
            <div class="ui clearing divider"></div>
            <div>
              Data type specifies the type of data, stored in a variable.
            </div>
            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="c" style={darcula}>
                  {code6}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

          <p>
            <span class="h1">String and its methods</span>
            <div class="ui clearing divider"></div>
            <div>Strings are sequence of characters.</div>
            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="c" style={darcula}>
                  {code7}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

          <p>
            <span class="h1">Operators</span>
            <div class="ui clearing divider"></div>

            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="c" style={darcula}>
                  {code8}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

          <p>
            <span class="h1">Conditional statements</span>
            <div class="ui clearing divider"></div>
            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="c" style={darcula}>
                  {code9}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

          <p>
            <span class="h1">Switch Statements</span>
            <div class="ui clearing divider"></div>
            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="c" style={darcula}>
                  {code10}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

          <p>
            <span class="h1">Loops</span>
            <div class="ui clearing divider"></div>

            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="c" style={darcula}>
                  {code11}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

          <p>
            <span class="h1">Continue And Break Statements</span>
            <div class="ui clearing divider"></div>

            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="c" style={darcula}>
                  {code16}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

          <p>
            <span class="h1">Arrays</span>
            <div class="ui clearing divider"></div>
            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="c" style={darcula}>
                  {code12}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

          <p>
            <span class="h1">Structures and Unions</span>
            <div class="ui clearing divider"></div>

            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="c" style={darcula}>
                  {code13}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

          <p>
            <span class="h1">Pointers</span>
            <div class="ui clearing divider"></div>

            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="c" style={darcula}>
                  {code14}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

          <p>
            <span class="h1">Functions</span>
            <div class="ui clearing divider"></div>

            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="c" style={darcula}>
                  {code15}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

          <p>
            <span class="h1">Built-in Functions</span>
            <div class="ui clearing divider"></div>

            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="c" style={darcula}>
                  {code18}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

          <p>
            <span class="h1">Exceptions</span>
            <div class="ui clearing divider"></div>

            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="c" style={darcula}>
                  {code27}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

          <p>
            <span class="h1">File Handling</span>
            <div class="ui clearing divider"></div>

            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="c" style={darcula}>
                  {code29}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

          <p>
            <span class="h1">Memory Allocation</span>
            <div class="ui clearing divider"></div>

            <div style={containerStyle}>
              <div style={{ fontSize: "25px" }}></div>
              <div>
                <SyntaxHighlighter language="c" style={darcula}>
                  {code30}
                </SyntaxHighlighter>
              </div>
            </div>
          </p>
          <br />
          <br />

          
          
        </div>
      </div>
      <Footer />
    </>
  );
};
export default CatGlance;
