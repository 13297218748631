import { useRef, useEffect } from "react";
import "../../Components/Chapters/Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Sidebar from "../JavaSideBar";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const JavaC12 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };

  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };
  const jc12code1 = `int absoluteValue = Math.abs(-5);`;
  const jc12code2 = `int result = Math.addExact(x, y);`;
  const jc12code3 = `double powerResult = Math.pow(2, 3);`;
  const jc12code4 = `double exponentialResult = Math.exp(1);`;
  const jc12code5 = `double logResult = Math.log(10);`;
  const jc12code6 = `double log10Result = Math.log10(100);`;
  const jc12code7 = `double sine = Math.sin(Math.PI / 2);`;
  const jc12code8 = `double cosine = Math.cos(Math.PI);`;
  const jc12code9 = `double tangent = Math.tan(Math.PI / 4);`;
  const jc12code10 = `double roundedValue = Math.round(3.75);`;
  const jc12code11 = `double ceilingValue = Math.ceil(4.2);`;
  const jc12code12 = `double floorValue = Math.floor(4.7);`;
  const jc12code13 = `double randomValue = Math.random();`;
  const jc12code14 = `int min = Math.min(10, 15);`;
  const jc12code15 = `int max = Math.max(30, 20);`;
  const jc12code16 = `double squareRoot = Math.sqrt(25);`;
  const jc12code17 = `double cubeRoot = Math.cbrt(8);`;
  const jc12code18 = `double degrees = Math.toDegrees(Math.PI / 2);`;
  const jc12code19 = `double radians = Math.toRadians(90);`;

  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/java/operators">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/java/booleans">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body ">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Java Maths:
              </h2>
              <div class="ui clearing divider"></div>
              <p style={{ backgroundColor: "", padding: "" }}>
                In Java, the "java.lang.Math" class is a part of the standard
                library and provides a set of static methods for performing
                various mathematical operations. Here are some of the commonly
                used functions in the Math library in Java:
                <br />
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Basic Arithmetic Functions:
                </h2>
                <div class="ui clearing divider"></div>
                <table className="ui very padded table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Code</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <b>Math.abs(x)</b>
                      </td>
                      <td>
                        <SyntaxHighlighter
                          language="java"
                          style={{ backgroundColor: "#ffff" }}
                        >
                          {jc12code1}
                        </SyntaxHighlighter>{" "}
                      </td>
                      <td>Returns the absolute value of x.</td>
                    </tr>

                    <tr>
                      <td>
                        <b>Math.addExact(int x, int y)</b>
                      </td>
                      <td>
                        <SyntaxHighlighter
                          language="java"
                          style={{ backgroundColor: "#ffff" }}
                        >
                          {jc12code2}
                        </SyntaxHighlighter>{" "}
                      </td>
                      <td>
                        Adds two integers or long values, throwing an exception
                        if the result overflows.
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Exponentiation and Logarithmic Functions:
                </h2>
                <div class="ui clearing divider"></div>
                <table className="ui very padded table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Code</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <b>Math.pow(x, y)</b>
                      </td>
                      <td>
                        <SyntaxHighlighter
                          language="java"
                          style={{ backgroundColor: "#ffff" }}
                        >
                          {jc12code3}
                        </SyntaxHighlighter>{" "}
                      </td>
                      <td>
                        Returns the first number raised to power of the second
                        number.
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <b>Math.exp(x)</b>
                      </td>
                      <td>
                        <SyntaxHighlighter
                          language="java"
                          style={{ backgroundColor: "#ffff" }}
                        >
                          {jc12code4}
                        </SyntaxHighlighter>{" "}
                      </td>
                      <td>Returns the exponential value of x.</td>
                    </tr>

                    <tr>
                      <td>
                        <b>Math.log(x)</b>
                      </td>
                      <td>
                        <SyntaxHighlighter
                          language="java"
                          style={{ backgroundColor: "#ffff" }}
                        >
                          {jc12code5}
                        </SyntaxHighlighter>{" "}
                      </td>
                      <td>Returns the natural log (base e) of x.</td>
                    </tr>

                    <tr>
                      <td>
                        <b>Math.log10(x)</b>
                      </td>
                      <td>
                        <SyntaxHighlighter
                          language="java"
                          style={{ backgroundColor: "#ffff" }}
                        >
                          {jc12code6}
                        </SyntaxHighlighter>{" "}
                      </td>
                      <td>Returns the base 10 logarithm of x.</td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Trigonometric Functions:
                </h2>
                <div class="ui clearing divider"></div>
                <table className="ui very padded table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Code</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <b>Math.sin(x)</b>
                      </td>
                      <td>
                        <SyntaxHighlighter
                          language="java"
                          style={{ backgroundColor: "#ffff" }}
                        >
                          {jc12code7}
                        </SyntaxHighlighter>{" "}
                      </td>
                      <td>calculates sin of a number.</td>
                    </tr>

                    <tr>
                      <td>
                        <b>Math.cos(x)</b>
                      </td>
                      <td>
                        <SyntaxHighlighter
                          language="java"
                          style={{ backgroundColor: "#ffff" }}
                        >
                          {jc12code8}
                        </SyntaxHighlighter>{" "}
                      </td>
                      <td>calculates cos of a number.</td>
                    </tr>

                    <tr>
                      <td>
                        <b>Math.tan(x)</b>
                      </td>
                      <td>
                        <SyntaxHighlighter
                          language="java"
                          style={{ backgroundColor: "#ffff" }}
                        >
                          {jc12code9}
                        </SyntaxHighlighter>{" "}
                      </td>
                      <td>calculates tan of a number.</td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Floor Functions:
                </h2>
                <div class="ui clearing divider"></div>
                <table className="ui very padded table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Code</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <b>Math.round(x)</b>
                      </td>
                      <td>
                        <SyntaxHighlighter
                          language="java"
                          style={{ backgroundColor: "#ffff" }}
                        >
                          {jc12code10}
                        </SyntaxHighlighter>{" "}
                      </td>
                      <td>Rounds x to the nearest integer.</td>
                    </tr>

                    <tr>
                      <td>
                        <b>Math.ceil(x)</b>
                      </td>
                      <td>
                        <SyntaxHighlighter
                          language="java"
                          style={{ backgroundColor: "#ffff" }}
                        >
                          {jc12code11}
                        </SyntaxHighlighter>{" "}
                      </td>
                      <td>
                        Returns the smallest integer number greater than or
                        equal to x.
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <b>Math.floor(x)</b>
                      </td>
                      <td>
                        <SyntaxHighlighter
                          language="java"
                          style={{ backgroundColor: "#ffff" }}
                        >
                          {jc12code12}
                        </SyntaxHighlighter>{" "}
                      </td>
                      <td>
                        Returns the largest number less than or equal to the
                        given number.
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Random Number Generation:
                </h2>
                <div class="ui clearing divider"></div>
                <table className="ui very padded table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Code</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <b>Math.random()</b>
                      </td>
                      <td>
                        <SyntaxHighlighter
                          language="java"
                          style={{ backgroundColor: "#ffff" }}
                        >
                          {jc12code13}
                        </SyntaxHighlighter>{" "}
                      </td>
                      <td>
                        Returns a random number with decimal point between 0.0 (inclusive)
                        and 1.0 (exclusive).
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Min and Max Functions:
                </h2>
                <div class="ui clearing divider"></div>
                <table className="ui very padded table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Code</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <b>Math.min(a, b)</b>
                      </td>
                      <td>
                        <SyntaxHighlighter
                          language="java"
                          style={{ backgroundColor: "#ffff" }}
                        >
                          {jc12code14}
                        </SyntaxHighlighter>{" "}
                      </td>
                      <td>Returns the smaller of the two given numbers a and b.</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Math.max(a, b)</b>
                      </td>
                      <td>
                        <SyntaxHighlighter
                          language="java"
                          style={{ backgroundColor: "#ffff" }}
                        >
                          {jc12code15}
                        </SyntaxHighlighter>{" "}
                      </td>
                      <td>Returns the maximum of two numbers a and b.</td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Square Root and Exponents:
                </h2>
                <div class="ui clearing divider"></div>
                <table className="ui very padded table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Code</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <b>Math.sqrt(x)</b>
                      </td>
                      <td>
                        <SyntaxHighlighter
                          language="java"
                          style={{ backgroundColor: "#ffff" }}
                        >
                          {jc12code16}
                        </SyntaxHighlighter>{" "}
                      </td>
                      <td>Returns the square root of x.</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Math.cbrt(x)</b>
                      </td>
                      <td>
                        <SyntaxHighlighter
                          language="java"
                          style={{ backgroundColor: "#ffff" }}
                        >
                          {jc12code17}
                        </SyntaxHighlighter>{" "}
                      </td>
                      <td>Returns the cube root of x.</td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Conversion Functions:
                </h2>
                <div class="ui clearing divider"></div>
                <table className="ui very padded table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Code</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <b>Math.toDegrees(x)</b>
                      </td>
                      <td>
                        <SyntaxHighlighter
                          language="java"
                          style={{ backgroundColor: "#ffff" }}
                        >
                          {jc12code18}
                        </SyntaxHighlighter>{" "}
                      </td>
                      <td>Converts an angle measured in radians to degrees.</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Math.toRadians(x)</b>
                      </td>
                      <td>
                        <SyntaxHighlighter
                          language="java"
                          style={{ backgroundColor: "#ffff" }}
                        >
                          {jc12code19}
                        </SyntaxHighlighter>{" "}
                      </td>
                      <td>Converts an angle measured in degrees to radians.</td>
                    </tr>
                  </tbody>
                </table>
              </p>
            </div>
            <br />
            <Link to="/java/operators">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/java/booleans">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default JavaC12;
