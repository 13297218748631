import React, { useState } from "react";
import {
  FaTh,
  FaBars,
  FaUserAlt,
  FaRegChartBar,
  FaCommentAlt,
  FaShoppingBag,
  FaThList,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import "../Components/SideBar/App.css";

const Sidebar = ({ children }) => {
  const [isOpenn, setisOpenn] = useState(true);
  const toggle = () => setisOpenn(!isOpenn);

  const menuItem = [
    {
      path: "/C/introduction",
      name: "C Introduction",
    },
    {
      path: "/C/getting_started",
      name: "C Getting Started",
    },
    {
      path: "/C/syntax",
      name: "C Basic Syntax",
    },
    {
      path: "/C/output",
      name: "C Printing Output",
    },
    {
      path: "/C/comments",
      name: "C Comments",
    },
    {
      path: "/C/variables",
      name: "C Variables",
    },
    {
      path: "/C/input",
      name: "C Taking Input",
    },
    {
      path: "/C/data_types",
      name: "C Data types",
    },
    {
      path: "/C/type_casting",
      name: "C Type Casting",
    },
    {
      path: "/C/strings",
      name: "C Strings",
    },
    {
      path: "/C/operators",
      name: "C Operators",
    },
    {
      path: "/C/maths",
      name: "C Maths",
    },
    {
      path: "/C/booleans",
      name: "C Booleans",
    },

    {
      path: "/C/if_else",
      name: "C Conditional Statements",
    },
    {
      path: "/C/switch",
      name: "C Switch Statements",
    },
    {
      path: "/C/for_loop",
      name: "C For Loop",
    },
    {
      path: "/C/while_loop",
      name: "C While Loops",
    },

    {
      path: "/C/continue_break",
      name: "C Break and Continue Statements",
    },
    {
      path: "/C/arrays",
      name: "C Arrays",
    },
    {
      path: "/C/pointers",
      name: "C Pointers",
    },
    {
      path: "/C/functions",
      name: "C Functions",
    },

    {
      path: "/C/struct",
      name: "C Struct",
    },

    {
      path: "/C/file_handling",
      name: "C File Handling",
    },
  ];
  const handleClick = (e) => {
    e.preventDefault();
    setisOpenn(!isOpenn);
  };
  const handleClicked = () => {
    setisOpenn(!isOpenn);
  };
  return (
    <div className={`${isOpenn === true ? "flex" : "sidebar_"}`}>
      <input type="checkbox" id="click" />
      <label htmlFor="click" className="menu-btn" style={{ marginTop: "16%" }}><br/>
        <i
          onClick={handleClick}
          className="fas fa-bars"
          style={{ marginTop: "0%" }}
        ></i>
      </label>
      <div
        style={{ display: "block", width: "200px" }}
        className={`${isOpenn === true ? "sidebar" : "nosidebar"}`}
      >
        <div className="top_section">
          <h1 style={{ display: "block" }} className="logo">
            <span style={{ color: "black", fontSize: "22px" }}>CTutorial</span>
          </h1>
        </div>
        {menuItem.map((item, index) => (
          <ul>
            <li>
              <Link
                to={item.path}
                key={index}
                className="link"
                activeclassName="active"
              >
                {/* here */}
                <div
                  onClick={handleClicked}
                  style={{
                    display: isOpenn ? "block" : "block",
                    color: "black",
                    fontSize: "14px",
                  }}
                  className="link_text"
                >
                  {item.name}
                </div>
              </Link>
            </li>
          </ul>
        ))}
      </div>
      <div className={`${isOpenn === true ? "" : "closemain"}`}>
        <main>{children}</main>
      </div>
    </div>
  );
};

export default Sidebar;
