import { useRef, useEffect } from "react";
import "../../Components/Chapters/Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Sidebar from "../JavaSideBar";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const JavaC28 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };

  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };
  const jc28code1 = `// Abstract class representing a shape
abstract class Shape {
  // Abstract method for calculating the area
  public abstract double Area();
}
  
// Concrete subclass representing a circle
class Circle extends Shape {
    private double radius;
  
    public Circle(double radius) {
      this.radius = radius;
    }
  
    // Implementing the abstract method
    public double Area() {
    return Math.PI * radius * radius;
  }
}
  
// Concrete subclass representing a rectangle
class Rectangle extends Shape {
    private double length;
    private double width;
  
    public Rectangle(double l, double w) {
      this.length = l;
      this.width = w;
    }
  
    // Implementing the abstract method
    public double Area() {
    return length * width;
  }
}
  
public class Main {
  public static void main(String[] args) {
    // Creating objects of Circle and Rectangle Class
    Circle c = new Circle(5.0);
    Rectangle r = new Rectangle(4.0, 6.0);
  
    // Calculate and display areas
    System.out.println("Area: " + c.Area());
    System.out.println("Area: " + r.Area());
  }
}`;
  const jc28code2 = `// Interface representing a sound
  interface Sound {
      void makeSound();
  }
  
  // Class representing a dog
  class Dog implements Sound {
      public void makeSound() {
          System.out.println("Dog barks.");
      }
  }
  
  // Class representing a cat
  class Cat implements Sound {
      public void makeSound() {
          System.out.println("Cat meows.");
      }
  }
  
  public class Main {
      public static void main(String[] args) {
          // Create objects of Dog and Cat
          Dog dog = new Dog();
          Cat cat = new Cat();
  
          // Make sounds
          dog.makeSound();
          cat.makeSound();
      }
  }`;
  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/java/interfaces">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/java/polymorphism">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body ">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Abstraction:
              </h2>
              <div class="ui clearing divider"></div>
              <p style={{ backgroundColor: "", padding: "" }}>
                Abstraction refers to the hiding of the complex details and showing
                only necessary information. For example, when you turn your
                lights on, the room lights up. You just turn the light on. You
                do not need to know how did the electrons flow? What is going on
                in the wiring?
                <br />
                <br />
                <span>Abstraction can be achieved by two ways:</span>
                <ul className="ui unordered list">
                  <li>Abstract Classes.</li>
                  <li>Interfaces.</li>
                </ul>
                
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Abstraction with Abstract Class:
                </h2>
                <div class="ui clearing divider"></div>
                <span>
                  Abstract class is declared with abstract keyword. We will
                  create an abstract class "Shape" with an abstract method
                  "calculateArea()". We'll also create two concrete subclasses,
                  Circle and Rectangle, that inherit from the Shape class and
                  provide their own implementations of the calculateArea()
                  method.
                </span>
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>abstraction.java</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="java" style={darcula}>
                      {jc28code1}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Abstraction with Interfaces:
                </h2>
                <div class="ui clearing divider"></div>
                <span>
                  In this example, we'll use an interface called Sound to define
                  a contract specifying a makeSound() method. We'll create two
                  classes, Dog and Cat, that implement the Sound interface and
                  provide their own makeSound() implementations.
                </span>
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>abstraction.java</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="java" style={darcula}>
                      {jc28code2}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
              </p>

              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Benefits of Abstraction:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                <ul className="ui unordered list">
                  <li>
                    <b>Code Reusability : </b>Abstraction promotes code
                    reusability by allowing multiple classes to implement the
                    same interface or inherit from the same abstract class.
                  </li>
                  <li>
                    <b>Modularity : </b>It helps in breaking down complex
                    systems into manageable and modular parts.
                  </li>
                  <li>
                    <b>Encapsulation : </b>Abstraction supports encapsulation by
                    hiding implementation details and exposing a well-defined
                    interface.
                  </li>
                  <li>
                    <b>Maintainability : </b> It enhances code maintainability
                    by making it easier to modify or extend the system without
                    affecting other parts of the code.
                  </li>
                </ul>
              </span>
              
              <br />
              <span>
                Abstraction is a fundamental concept in Java that helps to write
                organized and structured code for designing classes and
                interfaces. It enables us to creation of clean and understandable
                code, making it easier to work with complex systems.
              </span>
            </div>

            <br />
            <Link to="/java/interfaces">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/java/polymorphism">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default JavaC28;
