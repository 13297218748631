import { useRef, useEffect } from "react";
import "./Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Q18b from "../Questions/Q18b";
import Sidebar from "../SideBar/Sidebar";
import Footer from "../Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const Ch18b = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };

  const code1 = `var = getchar();  //use of var is optional`;
  const code2 = `int putchar(int c);`;
  const code3 = `gets(var_name); `;
  const code4 = `puts(var_name); `;
  const code5 = `int strlen(string);`;
  const code6 = `int strcmp(string1 , string2 );`;
  const code7 = `int strncmp (string1, string2, n);`;
  const code8 = `strcpy(string1, string2);`;
  const code9 = `strncpy(string1, srting2, n);`;
  const code10 = `strcat(string1,string2);`;
  const code11 = `strncat(string1, string2, n);`;
  const code12 = `pow(x , y);`;
  const code13 = `sqrt(x);`;
  const code14 = `floor(x);`;
  const code15 = `ceil(x);`;
  const code16 = `fmod(x, y);`;
  const code17 = `cos(x);`;
  const code18 = `sin(x);`;
  const code19 = `tan(x);`;
  const code20 = `log(x);`;
  const code21 = `log10(x);`;

  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/Cpp/functions">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/Cpp/oop">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Built-in Functions
              </h2>
              <div class="ui clearing divider"></div>
              <span>
              Built-in functions are the functions that are provided by the
               standard library. These
              functions are readily available for use without the need for
              additional coding or defining them yourself. We just need to import 
              the library in which they are written and call them.
              </span><br /><br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Built-in Functions in stdio:
              </h2>
              <div class="ui clearing divider"></div>
              <p>
                The "stdio" stands for standard input/output. This header file
                contain a large number of standard input and output functions
                that are used to get input from the input devices and also to
                print result on the output device. The commonly used functions
                of this header file and their description are given below.
              </p>
              <br />
              <b>The "getchar" Function:-</b>
              <br /><br />
              This function is used to get a single character from a standard
              input device i.e. from the keyboard during program execution. The
              key features of this function are: 1. When a character is entered
              it is displayed on the screen. 2. Enter key must be pressed to
              complete the input.
              
              <div ref={divRef}>
                <SyntaxHighlighter language="cpp" style={darcula}>
                  {code1}
                </SyntaxHighlighter>
              </div>
              <br />
              <br />
              <b>The "putchar" Function:-</b>
              <br /><br />
              The putchar function is used to put single character on screen.
              
              <div ref={divRef}>
                <SyntaxHighlighter language="cpp" style={darcula}>
                  {code2}
                </SyntaxHighlighter>
              </div>
              <br />
              <br />
              <b>The gets Function:-</b>
              <br />
              The gets function is used to take input in string type variable.
              
              <div ref={divRef}>
                <SyntaxHighlighter language="cpp" style={darcula}>
                  {code3}
                </SyntaxHighlighter>
              </div>
              <br />
              <br />
              <b>The puts Function:-</b>
              <br /><br />
              The puts function is used to print string type variable on screen.
              
              <div ref={divRef}>
                <SyntaxHighlighter language="cpp" style={darcula}>
                  {code4}
                </SyntaxHighlighter>
              </div><br />
              <b style={{ fontSize: "21px" }}>Built-in functions of string:</b>
              <p>
                The "string.h" header file contains the functions that are used
                to process strings. The commonly used functions of this header
                file are given below.
              </p>
              <br />
              <b>The "strlen" Function:-</b>
              <br /><br />
              This function is used to find the number of characters in string. Is counts
              the total number of characters including spaces. Null characters
              are excluded.
              
              <div ref={divRef}>
                <SyntaxHighlighter language="cpp" style={darcula}>
                  {code5}
                </SyntaxHighlighter>
              </div>
              <br />
              <br />
              <b>The "strcmp" Function:-</b>
              <br /><br />
              This function is used to compare two strings. It compares first
              string with the second string character by character.
              
              <div ref={divRef}>
                <SyntaxHighlighter language="cpp" style={darcula}>
                  {code6}
                </SyntaxHighlighter>
              </div>
              <br />
              <br />
              <b>The "strncmp" Function:-</b>
              <br /><br />
              This function is similar to the "strcmp" function but it compares
              specified number of characters of the two strings.
              
              <div ref={divRef}>
                <SyntaxHighlighter language="cpp" style={darcula}>
                  {code7}
                </SyntaxHighlighter>
              </div>
              <br />
              <br />
              <b>The "strcpy" Function:-</b>
              <br /><br />
              It is used to copy the contents of one string variable including
              the null character (‘\0’).
              
              <div ref={divRef}>
                <SyntaxHighlighter language="cpp" style={darcula}>
                  {code8}
                </SyntaxHighlighter>
              </div>
              <br />
              <br />
              <b>The "strncpy" Function:-</b>
              <br /><br />
              It is similar to strcpy function but it is used to copy a
              specified number of characters from one string to another string
              variable.
              
              <div ref={divRef}>
                <SyntaxHighlighter language="cpp" style={darcula}>
                  {code9}
                </SyntaxHighlighter>
              </div>
              <br />
              <br />
              <b>The "strcat" Function:-</b>
              <br /><br />
              It is used to append or combine the contents of one string to
              another string variable including the null character.
              
              <div ref={divRef}>
                <SyntaxHighlighter language="cpp" style={darcula}>
                  {code10}
                </SyntaxHighlighter>
              </div>
              <br />
              <br />
              <b>The "strncat" Function:-</b>
              <br /><br />
              It is similar to strcat function but it is used to append a
              specified number of characters of one string to another string
              variable.
              
              <div ref={divRef}>
                <SyntaxHighlighter language="cpp" style={darcula}>
                  {code11}
                </SyntaxHighlighter>
              </div>{" "}
              <br />
              <br />
              <b style={{ fontSize: "21px" }}>Built-in functions in math.h:</b>
              <p>
                The functions that are need to perform the mathematical
                calculations are defined in "math.h" header file.
              </p>
              <br />
              <b>The pow Function:-</b>
              <br /><br />
              It is used to calculate the exponential power of a given integer
              number.
              
              <div ref={divRef}>
                <SyntaxHighlighter language="cpp" style={darcula}>
                  {code12}
                </SyntaxHighlighter>
              </div>
              <br />
              <br />
              <b>The sqrt Function:-</b>
              <br /><br />
              It is used to calculate the square root of a given positive
              number.
              
              
              <div ref={divRef}>
                <SyntaxHighlighter language="cpp" style={darcula}>
                  {code13}
                </SyntaxHighlighter>
              </div>
              <br />
              <br />
              <b>The floor Function:-</b>
              <br /><br />
              It is used to round a given float value to the integer value. The
              given float value is converted into largest integer value that is
              not greater than the given float value.
              
              
              <div ref={divRef}>
                <SyntaxHighlighter language="cpp" style={darcula}>
                  {code14}
                </SyntaxHighlighter>
              </div>
              <br />
              <br />
              <b>The ceil Function:-</b>
              <br /><br />
              It is similar to the floor function but it returns the rounded
              integer value greater than the given float or double number.
              
              
              <div ref={divRef}>
                <SyntaxHighlighter language="cpp" style={darcula}>
                  {code15}
                </SyntaxHighlighter>
              </div>
              <br />
              <br />
              <b>The fmod Function:-</b>
              <br /><br />
              It is used to calculate the remainder by dividing one floating
              point number by another floating point number.
              
              
              <div ref={divRef}>
                <SyntaxHighlighter language="cpp" style={darcula}>
                  {code16}
                </SyntaxHighlighter>
              </div>
              <br />
              <br />
              <b>The cos Function:-</b>
              <br /><br />
              It is used to calculate the trigonometric cosine of a given angle.
              The angle is given in radius as a floating number.
             
              <div ref={divRef}>
                <SyntaxHighlighter language="cpp" style={darcula}>
                  {code17}
                </SyntaxHighlighter>
              </div>
              <br />
              <br />
              <b>The sin Function:-</b>
              <br /><br />
              It is used to calculate the trigonometric sine of a given floating
              number. The angle is given in radians as a floating number.
              
              <div ref={divRef}>
                <SyntaxHighlighter language="cpp" style={darcula}>
                  {code18}
                </SyntaxHighlighter>
              </div>
              <br />
              <br />
              <b>The tan Function:-</b>
              <br /><br />
              It is used to calculate the trigonometric tangent of a given
              floating number. The angle is given in radians as a floating
              number.
              
              <div ref={divRef}>
                <SyntaxHighlighter language="cpp" style={darcula}>
                  {code19}
                </SyntaxHighlighter>
              </div>
              <br />
              <br />
              <b>The log Function:-</b>
              <br /><br />
              It is used to calculate the natural logarithm (base e) of a given
              floating number.
              
              <div ref={divRef}>
                <SyntaxHighlighter language="cpp" style={darcula}>
                  {code20}
                </SyntaxHighlighter>
              </div>
              <br />
              <br />
              <b>The log10 Function:-</b>
              <br /><br />
              It is used to calculate the logarithm (base 10) of a given
              floating number.
              
              <div ref={divRef}>
                <SyntaxHighlighter language="cpp" style={darcula}>
                  {code21}
                </SyntaxHighlighter>
              </div>
              
            </div><br />
            <Link to="/Cpp/functions">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/Cpp/oop">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default Ch18b;
