import { useRef, useEffect } from "react";
import "../../Components/Chapters/Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "../PythonSideBar";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

const PythonC25 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };

  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor:'#D8D9DA'
  };
  const pc25code1 = `class ParentClass:
  pass
    
class ChildClass(ParentClass):  
  pass
      
c1 = ChildClass()`;
  const pc25code2 = `class Animals:
    def sleep(self):
        print("Animals can sleep")
    
class Lion(Animals):  
    def eat(self):
        print("Lion eats meat")

class Zebra(Animals): 
    def eat(self):
        print("Zebra eats grass")

Lion1 = Lion()
Lion1.sleep()
Lion1.eat()

Zebra1 = Zebra()
Zebra1.sleep()
Zebra1.eat()`;
  const pc25code3 = `class Employee:
def __init__(self, fname, lname):
  self.firstname = fname
  self.lastname = lname

def printname(self):
  print(self.firstname, self.lastname)

class Employee1(Employee):
def __init__(self, fname, lname):
  Person.__init__(self, fname, lname)

E1 = Employee("Leah", "Gotti")
E1.printname()`;
  const pc25code4 = `class Parent:
def __init__(self, name):
    self.name = name

def display_info(self):
    print(f"Name: {self.name}")

class Child(Parent):
def __init__(self, name, age):
    super().__init__(name)  
    self.age = age

def display_info(self):
    super().display_info() 
    print(f"Age: {self.age}")

child_obj = Child("Mike", 20)
child_obj.display_info()`;
  const pc25code5 = `class Parent:
def parent_method(self):
    print("This is a parent method.")

class Child(Parent):
def child_method(self):
    print("This is a child method.")

child_obj = Child()
child_obj.parent_method()
child_obj.child_method()`;
  const pc25code6 = `class Parent1:
def method1(self):
    print("Method from Parent1")

class Parent2:
def method2(self):
    print("Method from Parent2")

class Child(Parent1, Parent2):
def child_method(self):
    print("This is a child method.")

child_obj = Child()
child_obj.method1()
child_obj.method2()
child_obj.child_method()`;
  const pc25code7 = `class Grandparent:
def grandparent_method(self):
    print("Method from Grandparent")

class Parent(Grandparent):
def parent_method(self):
    print("Method from Parent")

class Child(Parent):
def child_method(self):
    print("Method from Child")

child_obj = Child()
child_obj.grandparent_method()
child_obj.parent_method()
child_obj.child_method()`;
  return (
    <div class="testing">
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/python/decorators">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/python/polymorphism">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Inheritance:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                Inheritance allows you to create a new class that is a modified
                version of an existing class, inheriting the attributes and
                methods of the parent class (also known as the base or
                superclass).
              </span>
              <br />
              <br />
              

                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>inheritance.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc25code1}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Example:
                </h2>
                <div class="ui clearing divider"></div>
                <span>
                  All the data members and member functions of the parent class
                  are inherited by child class. It means that they are also
                  members of child class and we can access them using object of
                  child class.
                </span>
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>inheritance.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc25code2}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <span>
                  All animals can sleep, therefore sleep method is present in
                  parent class. But lion eats meat and zebra eats grass.
                  Therefore these methods are separately defined in their
                  respective classes. Now, we can access sleep method using
                  objects of both child classes but eat method can be accessed
                  only by objects of respective classes.
                </span>
                <br />
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  __init__() in inheritance:
                </h2>
                <div class="ui clearing divider"></div>
                <span>
                  When we inherit a child class from a parent class, we can use
                  the __init__() function of parent class to initialize data
                  members of child class. This is called overriding.
                </span>
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>inheritance.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc25code3}
                    </SyntaxHighlighter>
                  </div>
                </div>
                
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  super() function:
                </h2>
                <div class="ui clearing divider"></div>
                <span>
                  In Python, the super() function is used to call a method from
                  a parent or superclass within a child class. It allows us to
                  access and invoke the methods and constructors of the parent
                  class, enabling us to extend and customize the behavior of
                  the parent class' methods in the child class.
                </span><br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>inheritance.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc25code4}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Levels of Inheritance:
                </h2>
                <div class="ui clearing divider"></div>
                <span>
                  The three levels of inheritance in Python are as follows:{" "}
                  <br />
                  <ul className="ui unordered list">
                    <li>Single Inheritance</li>
                    <li>Multiple Inheritance</li>
                    <li>Multilevel Inheritance</li>
                  </ul>
                </span>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Single Inheritance:
                </h2>
                <div class="ui clearing divider"></div>
                <span>
                  Single inheritance is the simplest form of inheritance in
                  Python. In this form, a class can inherit from only one parent
                  class.
                </span><br /><br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>inheritance.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc25code5}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />

                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Multiple Inheritance:
                </h2>
                <div class="ui clearing divider"></div>
                <span>
                  Multiple inheritance occurs when a class inherits from more
                  than one parent class. In this case, the child class can
                  inherit variables and methods from multiple parent classes.
                </span>
                <br /><br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>inheritance.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc25code6}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />

                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Multilevel Inheritance:
                </h2>
                <div class="ui clearing divider"></div>
                <span>
                  Multilevel inheritance occurs when a class inherits from
                  another class, which in turn inherits from another class. In
                  other words, it forms a chain of inheritance from parent to child and then to 
                  next child and so on. This allows you
                  to create a hierarchy of classes.
                </span><br /><br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b style={{ color: "black" }}>inheritance.py</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="python" style={darcula}>
                      {pc25code7}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
              
            </div>
            <Link to="/python/decorators">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/python/polymorphism">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </div>
  );
};
export default PythonC25;
