import { useRef, useEffect } from "react";
import "./Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Q18 from "../Questions/Q18";
import Sidebar from "../SideBar/Sidebar";
import Footer from "../Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const Ch18 = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  const divRef = useRef(null);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };
  const code1 = `datatype func_name(params);`;
  const code2 = `void print(){
    cout<<"Hi there!";
}`;
  const code3 = `-void print(){
    cout<<"Hi there!";//definition
}
`;
  const code4 = `#include<iostream>
using namespace std;

void print(){
    cout<<"Hi there!";
}
int main(){
    print();//calling a function
    //you can also call function again
    print();
    return 0;
}
  
`;
  const code5 = `#include<iostream>
using namespace std;

void print(string g1){      
    cout<<g1;
}

int main(){
    string greetings = "Hi there!";
    print(greetings);      
    return 0;
}`;
  const code6 = `#include<iostream>
using namespace std;

int sum(int a, int b){
    int sum = a + b;
    return sum;
}
int main(){
    int add = sum(2, 3);
    cout<<add<<endl;
    //following line will print the same result
    cout<<sum(2,3)<<endl;
    return 0;
}`;
  const code7 = `#include<iostream>
using namespace std;

int sum(int &a, int &b){
    int sum = a+b;
    return sum;
}
int main(){
    int add = sum(2,3);
    cout<<add<<endl;
}`;
  const code8 = `#include<iostream>
using namespace std;

int sum(int *a, int *b){
    int sum = *a+*b;
    return sum;
}
int main(){
    int num1=2, num2=3;
    int add = sum( &num1, &num2 );
    cout<<add<<endl;
    return 0;
}`;
  const code9 = `#include<iostream>
using namespace std;

void printMarks(int marks[5]){
    for(int i=0; i<5; i++){
    cout<<marks[i];
    }
    cout<<endl;
}
int main(){
    int marks[5] = {10,20,30,40,50};
    printMarks(marks); 
    return 0;
}`;

  const code10 = `#include <iostream>
using namespace std;

// Recursive function to calculate factorial
int factorial(int n) {
    // Base case: if n is 0 or 1, factorial is 1
    if (n == 0 || n == 1) {
      return 1;
    } else {
      // Recursive case: n! = n * (n-1)!
      return n * factorial(n - 1);
    }
}

int main() {
    int num;
    cout << "Enter a positive integer: ";
    cin >> num;

    if (num < 0) {
        cout << "Number is negative" << endl;
    } else {
        int result = factorial(num);
        cout << "Factorial: " << result << endl;
    }

    return 0;
}`;
  const code11 = `#include <iostream>
using namespace std;

int sum(int a, int b){
    return a+b;
}
float sum(float a, float b, int c){
    return a+b;
}
int main(){
  //funtion with data type int is invoked
  sum(5, 10);  
    
  //funtion with data type float is invoked
  sum(5.0, 10.0, 20);  
  //sum has all three features of overloading
}`;

  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/Cpp/pointers">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/Cpp/builtin_functions">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Functions:
              </h2>
              <div class="ui clearing divider"></div>
              <p>
                <ul className="ui unordered list">
                  <li>
                    A function is a block of code which we separate from the
                    main function.{" "}
                  </li>
                  <li>Functions are used to perform specific tasks.</li>
                  <li>
                    When we need to do that task, we call it in main function
                    because we know that in our C++ code, execution starts from
                    main function and ends when main function ends.
                  </li>
                  <li>
                    If we do not call function in main function, it will never
                    be executed.{" "}
                  </li>
                </ul>
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Why Functions?
                </h2>
                <div class="ui clearing divider"></div>
                As we can achieve the same functionality without
                using functions but it is necessary to use functions because;
                <ul className="ui unordered list">
                  <li>They make out code more readable.</li>
                  <li>Reduce redundancy of your code. </li>
                  <li>Easy to find errors.</li>
                </ul>
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Real Life Example:
                </h2>
                <div class="ui clearing divider"></div>
                If we make a calculator in our main function and we need
                calculator at 10 places. We will have to write code for it 10
                times. If we declare a function and write code for calculator
                there. And then if we need calculator at 10 places, then we
                just need to call calculator function, which will be a single
                line of code.
              </p>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Steps to write a function:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                <ul className="ui unordered list">
                  <li>
                    Declare the function. Declaration of function is also called
                    prototype of the function.
                    <SyntaxHighlighter language="cpp" style={darcula}>
                      {code1}
                    </SyntaxHighlighter>
                  </li>
                  <li>
                    Define the function. Function definition is the body of the
                    function which defines its behavior.
                    <SyntaxHighlighter language="cpp" style={darcula}>
                      {code2}
                    </SyntaxHighlighter>
                  </li>
                </ul>
              </span>
              <br />
              <span>
                Now, we will declare a function which will print "Hi, there!" on
                screen. Then we will call it in main function.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>function.cpp</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="cpp" style={darcula}>
                    {code4}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <span>
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Description:
                </h2>
                <div class="ui clearing divider"></div>

                <span>
                  <ul className="ui unordered list">
                    <li>
                      <b>void print() </b> is the declaration of the
                      function.Return type of function is same as the value it
                      is returning. As our function is returning nothing
                      therefore its type is void.
                    </li>
                    <li>
                      The code written inside the curly braces is the body of the
                      function.
                    </li>
                  </ul>
                </span>
              </span>
              <br />
              <p style={{ backgroundColor: "#FBEEAC", padding: "10px" }}>
                <b>Note:</b>
                <br />
                We have to declare the function before the main function and we can
                define/write code in body of the function anywhere outside the
                main function. <br />
                Rules for naming functions are the same as rules for naming the 
                variables and we can not name our user defined function "main"
                as we can have only one main function in our code.
              </p>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Parameters / Arguments:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                When there is a variable in the main function and we need it in
                the other function then we pass it to other function when we
                call it(inside parentheses). We can pass multiple arguments to the function. <br />
                <br />
                <b>Parameters:</b> variables in parenthesis when declaring the
                function <br />
                <b>Arguments:</b> variables in parenthesis when calling the function{" "}
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>function.cpp</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="cpp" style={darcula}>
                    {code5}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Functions which return some value:
              </h2>
              <div class="ui clearing divider"></div>
              When functions are returning some value then we can assign it to a
              variable. <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>function.cpp</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="cpp" style={darcula}>
                    {code6}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
              Passing values to functions:
              </h2>
              <div class="ui clearing divider"></div>
             
              <span>
              We have studied that how can we pass data value to a function.
              This is called pass by value method. <br />
              We are actually passing the address of our variable, where our
              data is stored. </span><br /><br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
              Pass by reference:
              </h2>
              <div class="ui clearing divider"></div>
             <span>
             When you pass a parameter by reference, you are essentially passing a reference to the original data.
             If you change the data value of the variable in the function, it will be reflected in the 
             main function either.
             </span><br /><br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>function.cpp</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="cpp" style={darcula}>
                    {code7}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
              Pass by Pointer:
              </h2>
              <div class="ui clearing divider"></div>
             <span>
             When you pass a parameter by pointer, you are passing a pointer to the original data. This allows you to access and modify the original data indirectly through the pointer.
             </span><br /><br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>function.cpp</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="cpp" style={darcula}>
                    {code8}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
              Functions and arrays:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
              Functions can accept arrays as arguments, allowing you to perform operations on the array's elements.
              </span><br /><br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>function.cpp</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="cpp" style={darcula}>
                    {code9}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
              Recursion:
              </h2>
              <div class="ui clearing divider"></div>
             
              <p>
                Recursive functions are the functions that call themselves directly
                or indirectly to solve a problem.{" "}
              </p>
              <br />
              
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>recursion.cpp</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="cpp" style={darcula}>
                    {code10}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
              Function Overloading:
              </h2>
              <div class="ui clearing divider"></div>
              
              <p>
                Function overloading allows you to define different functions
                with the same name but, <br />
                <ul className="ui unordered list">
                  <li>Overloading functions must have parameters with different
                data types, OR</li>
                <li>Number of parameters must be different, OR</li>
                <li>If number of parameters is same, their sequence must be
                different.</li>
                <li>Return type of overloading functions may or may not be
                different (not necessary).</li>
                </ul>
                
                Funtions with atleast one feature given above or having all the
                three features are said to be overloaded.{" "}
                <br />When function is called, compiler determines which version of
              function to invoke.
              </p>
              
              <br />
              
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>overloading.cpp</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="cpp" style={darcula}>
                    {code11}
                  </SyntaxHighlighter>
                </div>
              </div>
              
            </div><br />
            <Link to="/Cpp/pointers">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/Cpp/builtin_functions">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default Ch18;
