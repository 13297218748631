import React, { useState, useRef, useEffect } from "react";
import Sidebar from "./SideBar";
import "../../Components/SideBar/App.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const CodeAccordion = ({
  questions,
  code,
  toggleAccordion,
  changeCodeLanguage,
}) => {
  const divRef = useRef(null);
  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };

  return (
    <div>
      {questions.map((item, index) => (
        <div key={index} className="border p-2 rounded-md">
          <div className="flex justify-between">
            <span className="text-lg font-semibold">{item.question}</span>
            <button
              className="bg-gray-200 px-2 py-1 rounded-md"
              onClick={() => toggleAccordion(index)}
            >
              {item.isOpen ? "-" : "+"}
            </button>
          </div>
          <div className={`mt-2 ${item.isOpen ? "block" : "hidden"}`}>
            <div className="mt-2 flex space-x-4">
              {Object.keys(code).map((language) => (
                <button
                  key={language}
                  onClick={() => changeCodeLanguage(index, language)}
                  className={`${
                    item.codeLanguage === language
                      ? "bg-blue-500 hover:bg-blue-600 text-white"
                      : "bg-gray-200 hover:bg-gray-300 text-gray-700"
                  } px-3 py-2 rounded-md transition duration-300 ease-in-out transform hover:scale-105`}
                >
                  {language}
                </button>
              ))}
            </div>
            <p className="text-gray-800">
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>output.cpp</b>
                  <i
                    className="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter
                    language={`${item.codeLanguage}`}
                    style={darcula}
                  >
                    {code[item.codeLanguage]}
                  </SyntaxHighlighter>
                </div>
              </div>
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

const Question9 = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  const code = {
    c: `// C code
#include <stdio.h>

int main() {
    int age;
    do {
        printf("Enter your age (1-100): ");
        scanf("%d", &age);
    } while (age < 1 || age > 100);

    printf("Valid age entered: %d\\n", age);

    return 0;
}`,
    cpp: `// C++ code
#include <iostream>
using namespace std;

int main() {
    int age;
    do {
        cout << "Enter your age (1-100): ";
        cin >> age;
    } while (age < 1 || age > 100);

    cout << "Valid age entered: " << age << endl;

    return 0;
}`,
    java: `// Java code
import java.util.Scanner;

public class AgeValidation {
    public static void main(String[] args) {
        int age;
        Scanner scanner = new Scanner(System.in);

        do {
            System.out.print("Enter your age (1-100): ");
            age = scanner.nextInt();
        } while (age < 1 || age > 100);

        System.out.println("Valid age entered: " + age);
    }
}`,
  };
  const code1 = {
    c: `// C code
#include <stdio.h>
#include <stdlib.h>
#include <time.h>

int main() {
    int desiredNumber = 3;
    int roll, attempts = 0;

    srand(time(0)); // Seed the random number generator

    do {
        roll = (rand() % 6) + 1; // Simulate rolling a six-sided die
        attempts++;
        printf("Roll %d: %d\\n", attempts, roll);
    } while (roll != desiredNumber);

    printf("It took %d attempts to roll a %d.\\n", attempts, desiredNumber);

    return 0;
}`,
    cpp: `// C++ code
#include <iostream>
#include <cstdlib>
#include <ctime>
using namespace std;

int main() {
    int desiredNumber = 3;
    int roll, attempts = 0;

    srand(time(0)); // Seed the random number generator

    do {
        roll = (rand() % 6) + 1; // Simulate rolling a six-sided die
        attempts++;
        cout << "Roll " << attempts << ": " << roll << endl;
    } while (roll != desiredNumber);

    cout << "It took " << attempts << " attempts to roll a " << desiredNumber << "." << endl;

    return 0;
}`,
    java: `// Java code
import java.util.Random;

public class DieRollSimulation {
    public static void main(String[] args) {
        int desiredNumber = 3;
        int roll, attempts = 0;
        Random rand = new Random();

        do {
            roll = rand.nextInt(6) + 1; // Simulate rolling a six-sided die
            attempts++;
            System.out.println("Roll " + attempts + ": " + roll);
        } while (roll != desiredNumber);

        System.out.println("It took " + attempts + " attempts to roll a " + desiredNumber + ".");
    }
}`,
  };
  const code2 = {
    c: `// C code
#include <stdio.h>

int main() {
    int num, sum = 0;

    do {
        printf("Enter a number (0 to quit): ");
        scanf("%d", &num);
        sum += num;
    } while (num != 0);

    printf("Sum of the numbers entered: %d\\n", sum);

    return 0;
}`,
    cpp: `// C++ code
#include <iostream>
using namespace std;

int main() {
    int num, sum = 0;

    do {
        cout << "Enter a number (0 to quit): ";
        cin >> num;
        sum += num;
    } while (num != 0);

    cout << "Sum of the numbers entered: " << sum << endl;

    return 0;
}`,
    java: `// Java code
import java.util.Scanner;

public class SumOfNumbers {
    public static void main(String[] args) {
        int num, sum = 0;
        Scanner scanner = new Scanner(System.in);

        do {
            System.out.print("Enter a number (0 to quit): ");
            num = scanner.nextInt();
            sum += num;
        } while (num != 0);

        System.out.println("Sum of the numbers entered: " + sum);
    }
}`,
  };
  const code3 = {
    c: `// C code
#include <stdio.h>
#include <string.h>

int main() {
    char correctPassword[] = "secret";
    char inputPassword[100];
    int passwordMatch = 0;

    do {
        printf("Enter the password: ");
        scanf("%s", inputPassword);

        if (strcmp(inputPassword, correctPassword) == 0) {
            printf("Correct password! Access granted.\\n");
            passwordMatch = 1;
        } else {
            printf("Incorrect password. Try again.\\n");
        }
    } while (!passwordMatch);

    return 0;
}`,
    cpp: `// C++ code
#include <iostream>
#include <string>
using namespace std;

int main() {
    string correctPassword = "secret";
    string inputPassword;
    bool passwordMatch = false;

    do {
        cout << "Enter the password: ";
        cin >> inputPassword;

        if (inputPassword == correctPassword) {
            cout << "Correct password! Access granted." << endl;
            passwordMatch = true;
        } else {
            cout << "Incorrect password. Try again." << endl;
        }
    } while (!passwordMatch);

    return 0;
}`,
    java: `// Java code
import java.util.Scanner;

public class PasswordValidator {
    public static void main(String[] args) {
        Scanner scanner = new Scanner(System.in);
        String correctPassword = "secret";
        String inputPassword;
        boolean passwordMatch = false;

        do {
            System.out.print("Enter the password: ");
            inputPassword = scanner.nextLine();

            if (inputPassword.equals(correctPassword)) {
                System.out.println("Correct password! Access granted.");
                passwordMatch = true;
            } else {
                System.out.println("Incorrect password. Try again.");
            }
        } while (!passwordMatch);
    }
}`,
  };
  const code4 = {
    c: `// C code
#include <stdio.h>

int main() {
    int number, i = 1;

    printf("Enter a number: ");
    scanf("%d", &number);

    printf("Multiplication table for %d:\\n", number);
    do {
        printf("%d x %d = %d\\n", number, i, number * i);
        i++;
    } while (i <= 10);

    return 0;
}`,
    cpp: `// C++ code
#include <iostream>
using namespace std;

int main() {
    int number, i = 1;

    cout << "Enter a number: ";
    cin >> number;

    cout << "Multiplication table for " << number << ":\\n";
    do {
        cout << number << " x " << i << " = " << number * i << endl;
        i++;
    } while (i <= 10);

    return 0;
}`,
    java: `// Java code
import java.util.Scanner;

public class MultiplicationTable {
    public static void main(String[] args) {
        Scanner scanner = new Scanner(System.in);
        int number, i = 1;

        System.out.print("Enter a number: ");
        number = scanner.nextInt();

        System.out.println("Multiplication table for " + number + ":");
        do {
            System.out.println(number + " x " + i + " = " + (number * i));
            i++;
        } while (i <= 10);
    }
}`,
  };

  const [questions, setQuestions] = useState([
    {
      question:
        "Q1: Write a program to prompt the user to enter his age. Run the program again and again until he enters a valid age (between 1 and 100) using a do-while loop.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions1, setQuestions1] = useState([
    {
      question:
        "Q2: Implement a program that simulates rolling a six-sided die until a specific number is rolled using a do-while loop.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions2, setQuestions2] = useState([
    {
      question:
        "Q3: Develop a program to calculate the sum of numbers entered by the user until they enter 0 using a do-while loop.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions3, setQuestions3] = useState([
    {
      question:
        "Q4: Write a program that asks the user for a password again and again until the correct password is entered using a do-while loop.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);
  const [questions4, setQuestions4] = useState([
    {
      question:
        "Q5: Write a program to take input from the user and print the multiplication table for the given number using a do-while loop.",
      codeLanguage: "c",
      isOpen: false,
    },
  ]);

  const toggleAccordion = (index, questions, setQuestions) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].isOpen = !updatedQuestions[index].isOpen;
    setQuestions(updatedQuestions);
  };

  const changeCodeLanguage = (index, language, questions, setQuestions) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].codeLanguage = language;
    setQuestions(updatedQuestions);
  };

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };
  const divRef = useRef(null);
  return (
    <div className="testing">
      <Sidebar>
        <div className="containera">
          <div className="ch-content">
            <h2 className="ui header">
            <Link to="/ques8">
              <button
                className="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
              </Link>
              <Link to="/ques10">
                <button
                  className="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>
            <div className="main-body ui">
              <h2
                className="ui left floated header"
                style={{ color: "#001C30" }}
              >
                Do While Loop:
              </h2>
              <div className="ui clearing divider"></div>
              <CodeAccordion
                questions={questions}
                code={code}
                toggleAccordion={(index) =>
                  toggleAccordion(index, questions, setQuestions)
                }
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions, setQuestions)
                }
              />
              <br />
              <CodeAccordion
                questions={questions1}
                code={code1}
                toggleAccordion={(index) =>
                  toggleAccordion(index, questions1, setQuestions1)
                }
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions1, setQuestions1)
                }
              />
              <br />
              <CodeAccordion
                questions={questions2}
                code={code2}
                toggleAccordion={(index) =>
                  toggleAccordion(index, questions2, setQuestions2)
                }
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions2, setQuestions2)
                }
              />
              <br />
              <CodeAccordion
                questions={questions3}
                code={code3}
                toggleAccordion={(index) =>
                  toggleAccordion(index, questions3, setQuestions3)
                }
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions3, setQuestions3)
                }
              />
              <br />
              <CodeAccordion
                questions={questions4}
                code={code4}
                toggleAccordion={(index) =>
                  toggleAccordion(index, questions4, setQuestions4)
                }
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions4, setQuestions4)
                }
              />
              <br />
            </div>
            <Link to="/ques8">
              <button
                className="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
              </Link>
              <Link to="/ques10">
                <button
                  className="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default Question9;
