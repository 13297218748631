import { useRef, useEffect } from "react";
import "../../Components/Chapters/Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Sidebar from "../JavaSideBar";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const JavaC15 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };

  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };
  const jc15code0 = `switch(expression) {
    case x:
      // code block
      break;
    case y:
      // code block
      break;
    default:
      // code block
  }`;
  const jc15code1 = `import java.util.Scanner;

public class Main {
  public static void main(String[] args) {
    Scanner scanner = new Scanner(System.in);
  
    System.out.print("Enter number : ");
    int num = scanner.nextInt();
    
    scanner.close();
    
    switch (num) {
        case 1:
          System.out.println("Number is 1");
          break;
        case 2:
          System.out.println("Number is 2");
          break;
        case 3:
          System.out.println("Number is 3");
          break;
        case 4:
          System.out.println("Number is 4");
          break;
        case 5:
          System.out.println("Number is 5");
            break;
        default:
          System.out.println("Invalid Input.");
    }
  }
}`;

  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/java/if_else">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/java/for_loop">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body ">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Switch Statements:
              </h2>
              <div class="ui clearing divider"></div>
              <p style={{ backgroundColor: "", padding: "" }}>
                In Java, the switch statement is a control flow construct that
                allows you to select one of many code blocks to be executed
                based on the value of an expression. It provides an alternative
                to a long series of if-else statements when you have multiple
                conditions to check.
                <br />
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Syntax:
                </h2>
                <div class="ui clearing divider"></div>
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>switch.java</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="java" style={darcula}>
                      {jc15code0}
                    </SyntaxHighlighter>
                  </div>
                </div>
                <br />
                <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                  Description:
                </h2>
                <div class="ui clearing divider"></div>
                <span>
                  1. The switch statement begins with the keyword switch,
                  followed by an expression enclosed in parentheses. This
                  expression is often referred to as the "selector" or "switch
                  variable.". <br />
                  2. After the opening curly brace. you define individual cases
                  using the case keyword, followed by a constant value or
                  expression that represents a specific case to be matched.{" "}
                  <br />
                  3. Each case block consists of one or more statements that
                  will be executed when the corresponding case matches the value
                  of the selector expression. The statements within each case
                  block are typically indented for readability. <br />
                  4. At the end of each case block, it is necessary to include
                  the break statement to exit the switch block. Without the
                  break statement, the execution will continue to the next case,
                  which may not be the desired behavior. <br />
                  5. Optionally, you can include a default case, which is
                  executed when none of the previous cases match the value of
                  the selector expression. It acts as a fallback option when no
                  specific case matches. <br />
                  6. The closing curly brace marks the end of the switch
                  statement.{" "}
                </span>
                <br />
                <br />
                <div style={containerStyle}>
                  <div className="ml-3 mr-3">
                    <b>switch.java</b>

                    <i
                      class="copy icon"
                      onClick={copyContent}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "20px",
                      }}
                    ></i>
                  </div>
                  <div ref={divRef}>
                    <SyntaxHighlighter language="java" style={darcula}>
                      {jc15code1}
                    </SyntaxHighlighter>
                  </div>
                </div>
              </p>
            </div>

            <br />
            <Link to="/java/if_else">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/java/for_loop">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default JavaC15;
