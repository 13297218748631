import React, { useState, useRef, useEffect } from "react";
import Sidebar from "./SideBar";
import "../../Components/SideBar/App.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const CodeAccordion = ({ questions, code, toggleAccordion, changeCodeLanguage }) => {
    const divRef = useRef(null);
    const copyContent = () => {
        const divContent = divRef.current.innerText;
        navigator.clipboard
          .writeText(divContent)
          .then(() => {
            toast.success("Content copied!", { position: "top-center" });
          })
          .catch((error) => {
            toast.error("Error copying content:", error, {
              position: "top-center",
            });
          });
      };
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };

  return (
    <div>
      {questions.map((item, index) => (
        <div key={index} className="border p-2 rounded-md">
          <div className="flex justify-between">
            <span className="text-lg font-semibold">
              {item.question}
            </span>
            <button
              className="bg-gray-200 px-2 py-1 rounded-md"
              onClick={() => toggleAccordion(index)}
            >
              {item.isOpen ? "-" : "+"}
            </button>
          </div>
          <div className={`mt-2 ${item.isOpen ? "block" : "hidden"}`}>
            <div className="mt-2 flex space-x-4">
              {Object.keys(code).map((language) => (
                <button
                  key={language}
                  onClick={() => changeCodeLanguage(index, language)}
                  className={`${
                    item.codeLanguage === language
                      ? "bg-blue-500 hover:bg-blue-600 text-white"
                      : "bg-gray-200 hover:bg-gray-300 text-gray-700"
                  } px-3 py-2 rounded-md transition duration-300 ease-in-out transform hover:scale-105`}
                >
                  {language}
                </button>
              ))}
            </div>
            <p className="text-gray-800">
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>output.cpp</b>
                  <i
                    className="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language={`${item.codeLanguage}`} style={darcula}>
                    {code[item.codeLanguage]}
                  </SyntaxHighlighter>
                </div>
              </div>
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

const Question16 = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
    const code = {
        python: `// python code
my_tuple = (42, "Hello, World", 3.14)

# Accessing elements of the tuple
first_element = my_tuple[0]  # Access the first element (an integer)
second_element = my_tuple[1]  # Access the second element (a string)
third_element = my_tuple[2]  # Access the third element (a float)

print("First Element:", first_element)
print("Second Element:", second_element)
print("Third Element:", third_element)`
    };
    const code1 = {
        python: `// python code
tuple1 = (1, 2, 3)
tuple2 = (4, 5, 6)

concatenated_tuple = tuple1 + tuple2

print("Concatenated Tuple:", concatenated_tuple)`
    };
    const code2 = {
        python: `// python code
my_tuple = (1, 2, 3, 4, 5)

length = len(my_tuple)

print("Length of the tuple:", length)`
    };
    const code3 = {
        python: `// python code
person_info = ("John Doe", 30)

name, age = person_info

print("Name:", name)
print("Age:", age)`
    };
    const code4 = {
        python: `// python code
def min_max_from_list(input_list):
    if not input_list:  # Check if the list is empty
        return None, None
    
    min_val = min(input_list)
    max_val = max(input_list)
    
    return min_val, max_val

# Example usage:
my_list = [5, 2, 9, 1, 5, 6]
min_value, max_value = min_max_from_list(my_list)
print("Minimum:", min_value)
print("Maximum:", max_value)`
    };
    const code5 = {
        python: `// python code
my_tuple = (1, 2, 3, 4, 5, 6, 7, 8, 9)

# Extract a slice of elements from index 2 to 5 (inclusive) and print them
sliced_elements = my_tuple[2:6]

print("Sliced Elements:", sliced_elements)`
    };
    const code6 = {
        python: `// python code
my_tuple = (1, 2, 3, 4, 5)

# Reverse the tuple using slicing
reversed_tuple = my_tuple[::-1]

print("Reversed Tuple:", reversed_tuple)`
    };
    const code7 = {
        python: `// python code
my_tuple = (1, 2, 3, 4, 5)
my_list = list(my_tuple)
print(my_list)

my_list = [1, 2, 3, 4, 5]
my_tuple = tuple(my_list)
print(my_tuple)`
    };
    const code8 = {
        python: `// python code
def tuple_lengths(tuple_list):
    # Use a list comprehension to calculate the length of each tuple
    lengths = [len(t) for t in tuple_list]
    return lengths

# Example usage:
my_list_of_tuples = [(1, 2, 3), (4, 5), (6, 7, 8, 9, 10)]

lengths = tuple_lengths(my_list_of_tuples)
print("Lengths of Tuples:", lengths)`
    };
    const code9 = {
        python: `// python code
coordinate_dict = {
    (1, 2): "Value 1",
    (3, 4): "Value 2",
    (5, 6): "Value 3"
}

# Accessing values using coordinates
value_at_1_2 = coordinate_dict[(1, 2)]
value_at_3_4 = coordinate_dict[(3, 4)]

print("Value at (1, 2):", value_at_1_2)
print("Value at (3, 4):", value_at_3_4)`
    };
    const code10 = {
        python: `// python code
def find_value_at_coordinate(coord_dict, coordinate):
    if coordinate in coord_dict:
        return coord_dict[coordinate]
    else:
        return "Coordinate not found in the dictionary."

# Example usage:
coordinate_dict = {
    (1, 2): "Value 1",
    (3, 4): "Value 2",
    (5, 6): "Value 3"
}

given_coordinate = (3, 4)
value = find_value_at_coordinate(coordinate_dict, given_coordinate)
print(f"Value at {given_coordinate}: {value}")`
    };
    const code11 = {
        python: `// python code
def tuple_elementwise_sum(tuple1, tuple2):
    if len(tuple1) != len(tuple2):
        raise ValueError("Input tuples must have the same length.")
    
    result = tuple(x + y for x, y in zip(tuple1, tuple2))
    return result

# Example usage:
tuple1 = (1, 2, 3)
tuple2 = (4, 5, 6)

result = tuple_elementwise_sum(tuple1, tuple2)
print("Element-wise Sum:", result)`
    };
    const code12 = {
        python: `// python code
def sort_by_age(person):
    return person[1]  # Return the age (index 1) as the key for sorting

people = [("Alice", 25), ("Bob", 32), ("Charlie", 21), ("David", 28)]

sorted_people = sorted(people, key=sort_by_age)

print("Sorted by Age:", sorted_people)
sorted_people_descending = sorted(people, key=sort_by_age, reverse=True)
print("Sorted by Age (Descending):", sorted_people_descending)`
    };
    const code13 = {
        python: `// python code
from collections import namedtuple

# Define a named tuple 'Point' with fields 'x' and 'y'
Point = namedtuple('Point', ['x', 'y'])

# Create instances of the Point named tuple
point1 = Point(3, 4)
point2 = Point(1, 2)

# Access attributes of the Point instances
print("Point 1 - x:", point1.x)
print("Point 1 - y:", point1.y)

print("Point 2 - x:", point2.x)
print("Point 2 - y:", point2.y)`
    };
    const code14 = {
        python: `// python code
people = [("Alice", 25), ("Bob", 32), ("Charlie", 21), ("David", 28)]

# Define a custom key function to extract the age (index 1) from each tuple
def get_age(person):
    return person[1]

# Sort the list of people based on their ages using the custom key function
sorted_people = sorted(people, key=get_age)

# Print the sorted list
for person in sorted_people:
    print(person)`
    };


  const [questions, setQuestions] = useState([
    {
      question: "Q1: Create a tuple with various data types (integers, strings, floats) and access its elements.",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  const [questions1, setQuestions1] = useState([
    {
      question: "Q2: Concatenate two tuples and print the result.",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  const [questions2, setQuestions2] = useState([
    {
      question: "Q3: Find the length of a tuple.",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  const [questions3, setQuestions3] = useState([
    {
      question: "Q4: Given a tuple with the name and age of a person, unpack the tuple and print the name and age separately.",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  const [questions4, setQuestions4] = useState([
    {
      question: "Q5: Create a function that returns a tuple containing the minimum and maximum values from a list.",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  const [questions5, setQuestions5] = useState([
    {
      question: "Q6: Given a tuple of numbers, extract a slice of elements and print them.",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  const [questions6, setQuestions6] = useState([
    {
      question: "Q7: Reverse a tuple using slicing.",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  const [questions7, setQuestions7] = useState([
    {
      question: "Q8: Convert a tuple to a list and vice versa.",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  const [questions8, setQuestions8] = useState([
    {
      question: "Q9: Create a function that takes a list of tuples and returns a list of their lengths.",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  const [questions9, setQuestions9] = useState([
    {
      question: "Q10: Create a dictionary where the keys are tuples (e.g., (x, y)) representing coordinates and the values are corresponding values. Also print values on screen",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  const [questions10, setQuestions10] = useState([
    {
      question: "Q11: Write a function to find the value associated with a given coordinate.",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  const [questions11, setQuestions11] = useState([
    {
      question: "Q12: Create a function that takes two tuples and returns a new tuple that is the element-wise sum of the input tuples.",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  const [questions12, setQuestions12] = useState([
    {
      question: "Q13: Given a list of tuples containing names and ages, sort the list based on ages.",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  const [questions13, setQuestions13] = useState([
    {
      question: "Q14: Define a named tuple representing a Point with x and y coordinates. Create instances of the Point and access its attributes.",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  const [questions14, setQuestions14] = useState([
    {
      question: "Q15: Sort a list of tuples based on a custom key function.",
      codeLanguage: "python",
      isOpen: false,
    },
  ]);
  

  const toggleAccordion = (index, questions, setQuestions) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].isOpen = !updatedQuestions[index].isOpen;
    setQuestions(updatedQuestions);
  };

  const changeCodeLanguage = (index, language, questions, setQuestions) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].codeLanguage = language;
    setQuestions(updatedQuestions);
  };

  

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };
  const divRef = useRef(null);
  return (
    <div className="testing">
      <Sidebar>
        <div className="containera">
          <div className="ch-content">
            <h2 className="ui header">
            <Link to="/ques15">
              <button
                className="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
              </Link>
              <Link to="/ques17">
                <button
                  className="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>
            <div className="main-body ui">
              <h2 className="ui left floated header" style={{ color: "#001C30" }}>
                Tuple:
              </h2>
              <div className="ui clearing divider"></div>
              <CodeAccordion
                questions={questions}
                code={code}
                toggleAccordion={(index) => toggleAccordion(index, questions, setQuestions)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions, setQuestions)
                }
              /><br/>
              <CodeAccordion
                questions={questions1}
                code={code1}
                toggleAccordion={(index) => toggleAccordion(index, questions1, setQuestions1)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions1, setQuestions1)
                }
              /><br/>
              <CodeAccordion
                questions={questions2}
                code={code2}
                toggleAccordion={(index) => toggleAccordion(index, questions2, setQuestions2)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions2, setQuestions2)
                }
              /><br/>
              <CodeAccordion
                questions={questions3}
                code={code3}
                toggleAccordion={(index) => toggleAccordion(index, questions3, setQuestions3)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions3, setQuestions3)
                }
              /><br/>
              <CodeAccordion
                questions={questions4}
                code={code4}
                toggleAccordion={(index) => toggleAccordion(index, questions4, setQuestions4)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions4, setQuestions4)
                }
              /><br/>
              <CodeAccordion
                questions={questions5}
                code={code5}
                toggleAccordion={(index) => toggleAccordion(index, questions5, setQuestions5)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions5, setQuestions5)
                }
              /><br/>
              <CodeAccordion
                questions={questions6}
                code={code6}
                toggleAccordion={(index) => toggleAccordion(index, questions6, setQuestions6)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions6, setQuestions6)
                }
              /><br/>
              <CodeAccordion
                questions={questions7}
                code={code7}
                toggleAccordion={(index) => toggleAccordion(index, questions7, setQuestions7)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions7, setQuestions7)
                }
              /><br/>
              <CodeAccordion
                questions={questions8}
                code={code8}
                toggleAccordion={(index) => toggleAccordion(index, questions8, setQuestions8)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions8, setQuestions8)
                }
              /><br/>
              <CodeAccordion
                questions={questions9}
                code={code9}
                toggleAccordion={(index) => toggleAccordion(index, questions9, setQuestions9)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions9, setQuestions9)
                }
              /><br/>
              <CodeAccordion
                questions={questions10}
                code={code10}
                toggleAccordion={(index) => toggleAccordion(index, questions10, setQuestions10)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions10, setQuestions10)
                }
              /><br/>
              <CodeAccordion
                questions={questions11}
                code={code11}
                toggleAccordion={(index) => toggleAccordion(index, questions11, setQuestions11)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions11, setQuestions11)
                }
              /><br/>
              <CodeAccordion
                questions={questions12}
                code={code12}
                toggleAccordion={(index) => toggleAccordion(index, questions12, setQuestions12)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions12, setQuestions12)
                }
              /><br/>
              <CodeAccordion
                questions={questions13}
                code={code13}
                toggleAccordion={(index) => toggleAccordion(index, questions13, setQuestions13)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions13, setQuestions13)
                }
              /><br/>
              <CodeAccordion
                questions={questions14}
                code={code14}
                toggleAccordion={(index) => toggleAccordion(index, questions14, setQuestions14)}
                changeCodeLanguage={(index, language) =>
                  changeCodeLanguage(index, language, questions14, setQuestions14)
                }
              /><br/>
              
              
            </div>
            <Link to="/ques15">
              <button
                className="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
              </Link>
              <Link to="/ques17">
                <button
                  className="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
            <br />
          </div>
        </div>
      </Sidebar>
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default Question16;
