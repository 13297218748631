import { useRef, useEffect } from "react";
// import "./Chapters.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import Sidebar from "../CSideBar";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const CC20 = () => {
  const divRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const copyContent = () => {
    const divContent = divRef.current.innerText;
    navigator.clipboard
      .writeText(divContent)
      .then(() => {
        toast.success("Content copied!", { position: "top-center" });
      })
      .catch((error) => {
        toast.error("Error copying content:", error, {
          position: "top-center",
        });
      });
  };
  const containerStyle = {
    width: "100%",
    backgroundColor: "#A9A9A9",
    paddingTop: "10px",
    borderRadius: "3px",
    marginLeft: "0%",
    backgroundColor: "#D8D9DA",
  };
  const code1 = `#include <stdio.h>

int main() {
  int num = 20;
    
  printf("%d\\n", num);
  printf("%p\\n", &age);
  return 0;
}`;
  const code2 = `#include <stdio.h>

int main() {
  int num = 22;  
  int* numPtr = &age;  
  
  printf("%d\\n", num);
  printf("%p\\n", numPtr);
  
  return 0;
}`;
  const code3 = `#include <stdio.h>

int main() {
  int num = 22;  
  int* numPtr = &age;  
  
  printf("%d\\n", *agePtr);
  
  return 0;
}`;
  const code4 = `#include <stdio.h>

int main() {
  int age = 42;
  int *agePtr = &age; ptr
  int **doublePtr = &agePtr; 
    
  printf("%p\\n", &age);
    
  // printing address of age
  printf("%p\\n", agePtr);
   
  // printing address of agePtr
  printf("%p\\n", *doublePtr);

  return 0;
}`;

  const code5 = `#include <stdio.h>
 
int main() {
  int ages[5] = {37, 60, 55, 95, 78};
  int *agePtr = ages;

  for (int j = 0; j < 5; j++) {
    printf("%p\\n", (agePtr + j));
  }
  return 0;
}`;

  return (
    <>
      <Sidebar>
        <div class="containera">
          <div className="ch-content">
            <h2 class="ui header">
              <Link to="/C/arrays">
                <button
                  class="ui primary button"
                  style={{ flex: 1, minWidth: "100px", height: "40px" }}
                >
                  Previous
                </button>
              </Link>
              <Link to="/C/functions">
                <button
                  class="ui primary button"
                  style={{
                    flex: 1,
                    minWidth: "100px",
                    height: "40px",
                    float: "right",
                  }}
                >
                  <span> Next</span>
                </button>
              </Link>
              <br />
            </h2>

            <div class="main-body">
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Pointers in C:
              </h2>
              <div class="ui clearing divider"></div>
              <p>
                When we declare a variable, it is stored in the memory at
                some address. Pointers are variables that store memory
                addresses of the other variables. They allow us to manipulate data
                indirectly by referring to the memory location where the data is
                stored. Pointers store data in hexadecimal form.
              </p>
              <br />
              <span>
                First we will see how can we get the address of a variable with
                the help of "&" operator. We store the address in pointer
                variable.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>pointers.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code1}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Declaring Pointers:
              </h2>
              <div class="ui clearing divider"></div>
              We declare pointers using * after the data type of the variable to
              which pointer is pointing. It is declared like a simple variable
              but we put * after the data type of the variable to which pointer
              is pointing. <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>pointers.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code2}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <p style={{ backgroundColor: "#FBEEAC", padding: "10px" }}>
                There are two ways to declare a pointer variable; <br />
                <ul className="ui unordered list">
                  <li>int* ptr;</li>
                  <li>int *ptr;</li>
                </ul>
              </p>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Dereferencing:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                To access the value pointed to by a pointer, we use the asterisk
                (*) symbol.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>pointers.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <SyntaxHighlighter language="c" style={darcula}>
                  {code3}
                </SyntaxHighlighter>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Data type of Pointer:
              </h2>
              <div class="ui clearing divider"></div>
              Data type of pointers refers to the data type of variable to which
              pointer is pointing.
              <ul className="ui unordered list">
                <li>int *ptr means ptr is pointing to int type variable.</li>
                <li>float *ptr means ptr is pointing to float type data.</li>
              </ul>
              <p style={{ backgroundColor: "#FBEEAC", padding: "10px" }}>
              If we do not want to specify the data type of pointer or if we do not know what kind of data pointer is pointing to, we can set the data type of pointer "void".
              </p>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Pointers to pointers:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                Pointers can also point to other pointers. These are called
                "double pointers" or "pointers to pointers."
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>pointers.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code4}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Pointers and Arrays:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                Pointers and arrays in C are closely related concepts. In fact,
                in C, arrays are implemented as a contiguous block of memory,
                and the array name acts as a pointer to the first element of the
                array.
              </span>
              <br />
              <br />
              <div style={containerStyle}>
                <div className="ml-3 mr-3">
                  <b>pointers.c</b>

                  <i
                    class="copy icon"
                    onClick={copyContent}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      fontSize: "20px",
                    }}
                  ></i>
                </div>
                <div ref={divRef}>
                  <SyntaxHighlighter language="c" style={darcula}>
                    {code5}
                  </SyntaxHighlighter>
                </div>
              </div>
              <br />
              <h1>In this example;</h1>
              <br />
              <span>
                <ul className="ui unordered list">
                  <li>Initializing an int type array of size 5.</li>
                  <li>
                    Initializing a pointer to array. marks atore the base
                    address of array. It points to the first element of the
                    array (90).
                  </li>
                  <li>
                    Loop starts and ptr has address of array[0]. i = 0. So, it
                    will point to 90.
                  </li>
                  <li>
                    In the next iteration, i = 1. So it will print ptr + 1 which
                    is 80.
                  </li>
                  <li>
                    In the next iteration, i = 2. So it will print ptr + 2 which
                    is 88.
                  </li>
                  <li>
                    In the next iteration, i = 3. So it will print ptr + 3 which
                    is 95.
                  </li>
                  <li>
                    In the next iteration, i = 4. So it will print ptr + 4 which
                    is 78.
                  </li>
                  <li>
                    In the next iteration, i = 5. condition becomes false. The
                    control will move out of the loop.
                  </li>
                </ul>
              </span>
              <br />
              <h2 class="ui left floated header" style={{ color: "#001C30" }}>
                Importance of Pointers:
              </h2>
              <div class="ui clearing divider"></div>
              <span>
                <ul className="ui unordered list">
                  <li>
                    Pointers allow you to allocate memory dynamically at
                    runtime, which is essential for tasks like creating data
                    structures (e.g., linked lists, trees, and dynamic arrays)
                    and working with variable-sized data.
                  </li>
                  <li>
                    C is mainly used to build system-level and embedded
                    applications that interact closely with the operating
                    systems. Pointers enable interaction with system calls and
                    kernel-level programming.
                  </li>
                  <li>
                    Unlike Java and some C++ libraries, C does not have
                    automatic garbage collection.{" "}
                  </li>

                  <li>
                    Pointers in C can be used to directly access memory
                    addresses, making it possible to interface with hardware,
                    memory-mapped I/O, and perform low-level system programming.{" "}
                  </li>
                </ul>
              </span>
            </div>
            <br />
            <Link to="/C/arrays">
              <button
                class="ui primary button"
                style={{ flex: 1, minWidth: "100px", height: "40px" }}
              >
                Previous
              </button>
            </Link>
            <Link to="/C/functions">
              <button
                class="ui primary button"
                style={{
                  flex: 1,
                  minWidth: "100px",
                  height: "40px",
                  float: "right",
                }}
              >
                <span> Next</span>
              </button>
            </Link>
          </div>
        </div>
      </Sidebar>
      <div class="sideBarBody">
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};
export default CC20;
